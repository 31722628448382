import { LoginType, useRemoveLoginMutation } from 'apollo/generted';
import useMeQuery from 'helpers/useMeQuery';
import classNames from 'classnames/bind';
import Modal, { ModalBody, ModalHeader, ModalProps, useModalContext } from 'components/UIKit/Modal';
import ModalErrorScreen from 'components/UIKit/Modal/ErrorScreen';
import { URL_MAP } from 'constant';
import Button from 'components/UIKit/Button';
import { getErrorData, getErrorI18nText } from 'helpers';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { useToast } from 'helpers/hooks';
const cx = classNames.bind(styles);

export type Props = {
    type: LoginType;
};

const ModalRemoveLogin = ({ type, ...modalProps }: ModalProps & Props) => {
    return (
        <Modal {...modalProps}>
            <Main type={type} />
        </Modal>
    );
};

const Main = ({ type }: Props) => {
    const { close } = useModalContext();
    const [formError, setFormError] = useState('');
    const [t] = useTranslation();
    const [removeLoginMutation, { loading }] = useRemoveLoginMutation();
    const hasPassword = useMeQuery().data?.me?.hasPassword;
    const toast = useToast();

    const onSubmit = () => {
        setFormError('');

        removeLoginMutation({
            variables: {
                input: {
                    loginType: type
                }
            }
        })
            .then(({ data }) => {
                const removeLogin = data?.removeLogin!;
                if (removeLogin.__typename === 'RemoveLoginSuccess') {
                    toast.success(t(`modalRemoveLogin.successMsg`, { context: type }));
                    close();
                } else {
                    setFormError(
                        t([
                            `modalRemoveLogin.error.${removeLogin?.__typename}`,
                            getErrorI18nText(t, removeLogin?.errorMessage)
                        ])
                    );
                }
            })
            .catch((e) => {
                setFormError(getErrorI18nText(t, getErrorData(e).message));
            });
    };

    const isAddPasswordRequired = type === LoginType.EosAccount && !hasPassword;

    return (
        <Fragment>
            <ModalHeader>{t('modalRemoveLogin.title', { context: type })}</ModalHeader>
            <ModalBody>
                <div className={cx('Content')}>
                    <p className={cx('Text')}>
                        {t(`modalRemoveLogin.description`, {
                            context: isAddPasswordRequired ? 'password' : type
                        })}
                    </p>
                </div>
                <div className={cx('BtnGroup')}>
                    <Button onClick={close} type="button" color="tertiary">
                        {t('modalRemoveLogin.btnCancel')}
                    </Button>
                    {isAddPasswordRequired ? (
                        <Button to={URL_MAP.profile.safety} onClick={close} color="primary" tag="link">
                            {t('modalRemoveLogin.btnSubmit', { context: 'password' })}
                        </Button>
                    ) : (
                        <Button loading={loading} onClick={onSubmit} type="button" color="primary">
                            {t('modalRemoveLogin.btnSubmit')}
                        </Button>
                    )}
                </div>
            </ModalBody>

            <ModalErrorScreen text={formError} resetError={() => setFormError('')} />
        </Fragment>
    );
};

export default ModalRemoveLogin;
