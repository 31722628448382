import { LOCAL_STORAGE_KEY_TOKEN_AUTH } from 'constant';
import store from 'store/';
import { setAuth } from 'store/Base/actions';

// JWT token, that we use in each request to API
export const getToken = () => window.localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN_AUTH);

export const signOut = (withUnsetAuth = true) => {
    window.localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN_AUTH);
    if (withUnsetAuth) store.dispatch(setAuth(false));
};

export const signIn = (token: string) => {
    window.localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN_AUTH, token);
    store.dispatch(setAuth(true));
};
