import { ApartmentNode, AuctionNode, BuyAptRequestNode, LockNode, Maybe, RentalNode } from 'apollo/generted';
import classNames from 'classnames/bind';
import { IconCalendar, IconDocument, IconTimer, IconTrophy, IconUsers } from 'components/Icons';
import Timer from 'components/Timer';
import ProgressBar from 'components/ProgressBar';
import { useEffect, useState } from 'react';
import { useStore } from 'helpers/hooks';
import Button from 'components/UIKit/Button';
import { URL_MAP } from 'constant';
import ModalAuctionParticipants from 'containers/Modals/AuctionParticipants';
import { useModal } from 'helpers';
import formatDate from 'helpers/formatDate';
import { Data as ConstructApartmentStatusData, useConstructApartmentStatus } from 'helpers/useConstructApartmentStatus';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import Param from './Param';

const cx = classNames.bind(styles);

type Props = {
    className?: string;
    isOwner?: boolean;
    apartment: ConstructApartmentStatusData &
        Pick<ApartmentNode, 'isTokenized' | 'id'> & {
            latestAuction?: Maybe<
                Pick<
                    AuctionNode,
                    | 'isWinnerSelectionInProgress'
                    | 'winnerAccount'
                    | 'isIAmWinner'
                    | 'isWinnerAgree'
                    | 'startDate'
                    | 'endOfActivePhase'
                    | 'bidsNumber'
                    | 'deadlineForWinner'
                    | 'deadlineToPayCommunal'
                >
            >;
            actualRental?: Maybe<Pick<RentalNode, 'isIAmTenant' | 'expirationDate' | 'isExpired'>>;
            buyRequest?: Maybe<Pick<BuyAptRequestNode, 'buyerAccount' | 'endTime'>>;
            lock?: Maybe<Pick<LockNode, 'lockEndDate' | 'lockedBySystem'>>;
        };
};

export const ParamWinner = ({
    isWinnerSelectionInProgress,
    winnerAccount,
    isIAmWinner
}: Pick<AuctionNode, 'isWinnerSelectionInProgress' | 'winnerAccount' | 'isIAmWinner'>) => {
    const [t] = useTranslation();
    return (
        <Param
            icon={<IconTrophy />}
            label={t('apartmentStats.winner.label')}
            value={
                isWinnerSelectionInProgress
                    ? t('apartmentStats.winner.waiting')
                    : winnerAccount
                    ? `${winnerAccount}${isIAmWinner ? ` (${t('apartmentStats.winner.you')})` : ''}`
                    : t('apartmentStats.winner.refusedAll')
            }
        />
    );
};

const ParamProgressBar = ({
    startDate,
    endDate
}: {
    startDate: AuctionNode['startDate'];
    endDate: AuctionNode['endOfActivePhase'];
}) => {
    const realtimeOffset = useStore((s) => s.Base.realtimeOffset);
    const [progress, setProgress] = useState(0);

    const updateProgress = () => {
        const now = +new Date() + realtimeOffset;
        const start = +new Date(startDate + 'Z');
        const end = +new Date(endDate + 'Z');
        const duration = end - start;
        setProgress(1 - (now - start) / duration);
    };
    // TODO: Fix exhaustive deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(updateProgress, []);

    useEffect(() => {
        if (progress <= 0) return;

        const tid = setTimeout(updateProgress, 500);
        return () => {
            clearInterval(tid);
        };
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [progress]);

    return <ProgressBar size={34} strokeWidth={2} progress={progress} />;
};

export const ParamContestTimer = ({
    endOfActivePhase,
    startDate
}: {
    endOfActivePhase: AuctionNode['endOfActivePhase'];
    startDate: AuctionNode['startDate'];
}) => {
    const [t] = useTranslation();

    return (
        <Param
            icon={
                <>
                    <IconTimer />
                    <span className={cx('TimerProgressBar')}>
                        <ParamProgressBar endDate={endOfActivePhase} startDate={startDate} />
                    </span>
                </>
            }
            label={t('apartmentStats.timer.label')}
            value={endOfActivePhase !== null ? <Timer endTime={endOfActivePhase} /> : '3д 00ч 00м 00c'}
        />
    );
};

export const ParamContestParticipants = ({ bidsNumber }: { bidsNumber: AuctionNode['bidsNumber'] }) => {
    const [t] = useTranslation();

    return (
        <Param
            icon={<IconUsers />}
            value={t('apartmentStats.participants.value', {
                count: bidsNumber?.active!
            })}
            subValue={t('apartmentStats.cancellations.value', {
                count: bidsNumber?.cancelled
            })}
        />
    );
};

export const ParamContestParticipantsWithButton = ({
    bidsNumber,
    id
}: {
    id: ApartmentNode['id'];
    bidsNumber: AuctionNode['bidsNumber'];
}) => {
    const modal = useModal();
    const [t] = useTranslation();

    return (
        <div className={cx('ParamRow')}>
            <ParamContestParticipants bidsNumber={bidsNumber} />

            <Button onClick={modal.open} type="button" round size="small" color="tertiary-grey">
                {t('pageApartment.objectActions.links.participantList')}
            </Button>

            <ModalAuctionParticipants {...modal} id={id} />
        </div>
    );
};

const StatusParams = ({ className, apartment, isOwner }: Props) => {
    const [t] = useTranslation();
    const { inContest, isContestFinished, isRented, isEviction, isLocked, isSold } =
        useConstructApartmentStatus(apartment);

    const { latestAuction, actualRental, isTokenized, id, lock } = apartment ?? {};
    const { isIAmTenant, expirationDate, isExpired } = actualRental ?? {};
    const {
        isWinnerSelectionInProgress,
        winnerAccount,
        isIAmWinner,
        isWinnerAgree,
        startDate,
        endOfActivePhase,
        bidsNumber,
        deadlineForWinner,
        deadlineToPayCommunal
    } = latestAuction ?? {};

    const isLockedBySystem = isLocked && lock?.lockedBySystem;

    const showContestParticipants =
        inContest || isContestFinished || isRented || isEviction || (!isOwner && isLockedBySystem);

    const showContestTimer = inContest;

    const showLockEndDate = isLocked;

    const showLicenseExpirationDate = isRented || isEviction || (isSold && isIAmTenant && !isExpired);

    const showWinner = (!isOwner && isLockedBySystem) || isContestFinished || isRented || isEviction;

    const showWinnerDeadlineTimer = isContestFinished && isIAmWinner && (!isWinnerAgree || isTokenized);

    const showBuyRequestEndTime = isSold;
    const showBuyRequestBuyerAccount = isSold;

    return (
        <div className={className}>
            {showLicenseExpirationDate && (
                <Param
                    icon={<IconDocument />}
                    label={t('apartmentStats.license.label')}
                    value={`${formatDate(expirationDate, true)}${
                        isIAmTenant
                            ? isEviction
                                ? ` (${t('apartmentStats.license.evictionStarted')})`
                                : isExpired
                                ? ` (${t('apartmentStats.license.expired')})`
                                : ''
                            : ''
                    }`}
                />
            )}

            {showWinner && (
                <div className={cx('ParamRow')}>
                    <ParamWinner
                        winnerAccount={winnerAccount}
                        isIAmWinner={isIAmWinner!}
                        isWinnerSelectionInProgress={isWinnerSelectionInProgress}
                    />

                    {!isWinnerSelectionInProgress && isContestFinished && (
                        <Button
                            type="button"
                            round
                            size="small"
                            color="tertiary-grey"
                            tag="link"
                            to={URL_MAP.validateContest(id)}
                        >
                            {t('pageApartment.objectActions.links.validateContets')}
                        </Button>
                    )}
                </div>
            )}

            {showContestTimer && <ParamContestTimer endOfActivePhase={endOfActivePhase} startDate={startDate} />}

            {showWinnerDeadlineTimer && (
                <Param
                    icon={<IconTimer />}
                    label={t('apartmentStats.timer.label', {
                        context: isWinnerAgree ? 'PAYMENT' : 'AGREEMENT'
                    })}
                    value={<Timer endTime={isWinnerAgree ? deadlineToPayCommunal : deadlineForWinner} />}
                />
            )}

            {showContestParticipants && <ParamContestParticipantsWithButton id={id} bidsNumber={bidsNumber} />}

            {showLockEndDate && (
                <Param
                    icon={<IconTimer />}
                    label={t('apartmentStats.lock.lockEndDate.label')}
                    value={
                        apartment.lock?.lockEndDate === null
                            ? t('apartmentStats.lock.lockEndDate.FOREVER')
                            : formatDate(apartment.lock?.lockEndDate)
                    }
                />
            )}
            {showBuyRequestEndTime && (
                <Param
                    icon={<IconCalendar />}
                    label={t('apartmentStats.buyRequest.endTime')}
                    value={formatDate(apartment.buyRequest?.endTime)}
                />
            )}
            {showBuyRequestBuyerAccount && (
                <Param
                    icon={<IconUsers />}
                    label={t('apartmentStats.buyRequest.buyerAccount')}
                    value={apartment.buyRequest?.buyerAccount}
                />
            )}
        </div>
    );
};

export default StatusParams;
