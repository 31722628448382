import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'store/reducers';
import { Dispatch } from 'redux';
import { setErrorStatus } from 'store/Base/actions';
import PageError from 'containers/Pages/Error';
import { captureException } from '@sentry/browser';

const mapState = ({ Base: { error } }: ApplicationState) => ({ error });

const mapDispatch = (d: Dispatch) => ({
    setError: () => {
        d(setErrorStatus(500));
    }
});

type Props = any;
// type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>
class ErrorBoundary extends PureComponent<Props> {
    componentDidCatch(e: any) {
        captureException(e);
        this.props.setError();
    }

    render() {
        if (this.props.error) return <PageError />;

        return this.props.children;
    }
}

export default connect(mapState, mapDispatch)(ErrorBoundary);
