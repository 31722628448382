import { CitiesManagedByMeQuery } from 'apollo/generted';
import { createContext, useContext } from 'react';

type CityList = NonNullable<NonNullable<CitiesManagedByMeQuery['me']>['citiesManagedByMe']>;
type City = CityList[number];
type ManageContextType = {
    city: City;
    cityList: CityList;
    changeCity(cityId: NonNullable<City>['id']): void;
};
export const ManageContext = createContext({} as ManageContextType);
export const useManageContext = () => useContext(ManageContext);
