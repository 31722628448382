import { useSignupMutation } from 'apollo/generted';
import classNames from 'classnames/bind';
import { IconAccount } from 'components/Icons';
import PasswordField from 'components/UIKit/PasswordField';
import TextField from 'components/UIKit/TextField';
import FormCodeVerification from 'containers/Forms/CodeVerification';
import Button from 'components/UIKit/Button';
import { getErrorData, getErrorI18nText } from 'helpers';
import { useFormField } from 'helpers/hooks';
import validatePassword from 'helpers/validatePassword';
import React, { FormEvent, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OnAuth } from '..';
import styles from './index.module.scss';
import { LocalUIState } from '.';

const cx = classNames.bind(styles);

type Props = {
    onAuth: OnAuth;
    onError(v: string): void;
    setLocalUIState: (state: LocalUIState) => void;
};

const FormSignup = memo(({ onAuth, onError, setLocalUIState }: Props) => {
    const [t] = useTranslation();
    const [verifyToken, setVerifyToken] = useState('');
    const [signUpMutation, { loading: loadingSignUp }] = useSignupMutation();
    const [isVerify, setIsVerify] = useState(false);
    const passwordField = useFormField('');
    const repeatPasswordField = useFormField('');
    const loginField = useFormField('');
    const validationPassword = validatePassword(passwordField.value);

    const closeVerifyForm = () => {
        setLocalUIState({});
        setIsVerify(false);
    };

    const onBlock = (errorMessage = '') => {
        closeVerifyForm();
        onError(t(['modalSign.formSignup.errors.NoResendAttemptsError', errorMessage]));
    };

    const signup = (e?: FormEvent<HTMLFormElement>) => {
        e?.preventDefault();
        loginField.changeError('');
        const isEmailLoginType = loginField.value.includes('@');

        if (passwordField.value !== repeatPasswordField.value) {
            return repeatPasswordField.changeError(t('modalSign.formSignup.errors.PasswordsDoesnotMatch'));
        }

        signUpMutation({
            variables: {
                input: {
                    phone: isEmailLoginType ? undefined : loginField.value,
                    email: isEmailLoginType ? loginField.value : undefined,
                    password: passwordField.value
                }
            }
        })
            .then(({ data }) => {
                const signup = data?.signup!;
                if (signup.__typename === 'SignupSuccess') {
                    // Hide useless ui in parent element
                    setLocalUIState({
                        onClickBack: closeVerifyForm,
                        hideTransitionsControls: true
                    });
                    setVerifyToken(signup.verifyToken);
                    setIsVerify(true);
                } else {
                    const { __typename, errorMessage = '' } = signup ?? {};
                    if (
                        __typename === 'InvalidEmailError' ||
                        __typename === 'InvalidPhoneError' ||
                        __typename === 'UserAlreadyExistsError' ||
                        __typename === 'LoginIsEmptyError'
                    ) {
                        loginField.changeError(t([`modalSign.formSignup.errors.${__typename}`, errorMessage]));
                    } else if (__typename === 'InvalidPasswordError') {
                        passwordField.changeError(t([`modalSign.formSignup.errors.${__typename}`, errorMessage]));
                    } else if (__typename === 'NoResendAttemptsError') {
                        onBlock(errorMessage);
                    } else {
                        onError(t([`modalSign.formSignup.errors.${__typename}`, getErrorI18nText(t, errorMessage)]));
                    }
                }
            })
            .catch((e) => {
                onError(getErrorI18nText(t, getErrorData(e).message));
            });
    };

    const disabled = !validationPassword.isValid || !loginField.value || !repeatPasswordField.value;

    if (isVerify)
        return (
            <FormCodeVerification
                verifyToken={verifyToken}
                onSuccessResendCode={setVerifyToken}
                onUnexpectedError={onError}
                onSuccess={onAuth}
                onBlock={onBlock}
                description={t('modalSign.formSignup.code.description', {
                    address: loginField.value,
                    context: loginField.value.includes('@') ? 'email' : 'phone'
                })}
            />
        );

    return (
        <form onSubmit={signup}>
            <TextField
                leftControl={<IconAccount />}
                value={loginField.value}
                onChange={(e) => loginField.change(e.target.value)}
                error={loginField.error}
                label={t('modalSign.formSignup.signup.fieldLogin')}
            />
            <PasswordField
                rules={validationPassword}
                value={passwordField.value}
                onChange={(e) => passwordField.change(e.target.value)}
                error={passwordField.error}
                label={t('modalSign.formSignup.signup.fieldPassword')}
            />
            <PasswordField
                value={repeatPasswordField.value}
                onChange={(e) => repeatPasswordField.change(e.target.value)}
                error={repeatPasswordField.error}
                label={t('modalSign.formSignup.signup.fieldRepeatPassword')}
            />
            <div className={cx('BtnGroup')}>
                <Button disabled={disabled} loading={loadingSignUp} type="submit" color="primary">
                    {t('modalSign.formSignup.signup.btnSubmit')}
                </Button>
            </div>
        </form>
    );
});

export default FormSignup;
