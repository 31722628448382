import React, { useEffect } from 'react';
import { useModal } from 'helpers';
import { useTranslation } from 'react-i18next';
import {
    ApartmentNode,
    AptStatus,
    AuctionNode,
    CityNode,
    Maybe,
    RentalNode,
    TokenizationStruct
} from 'apollo/generted';
import { ConstructAddressVariables, useConstructAddress } from 'helpers/useParsedApartmentData';
import { URL_MAP } from 'constant';
import ModalApartmentContest from 'containers/Modals/ApartmentContest';
import ModalApartmentRenewalLicense from 'containers/Modals/ApartmentRenewalLicense';
import ModalApartmentSignTokenization from 'containers/Modals/ApartmentSignTokenization/ApartmentSignTokenization';
import ModalWithdrawFromContest from 'containers/Modals/WithdrawFromContest';
import ModalCancelRequest from 'containers/Modals/CancelRequest';
import ModalContacts from 'containers/Modals/Contacts';
import ModalContestWinnerAgreement from 'containers/Modals/ContestWinnerAgreement';
import ModalEditRealId from 'containers/Modals/EditRealId';
import ModalRefuseToWin from 'containers/Modals/RefuseToWin';
import ModalRemoveApartment from 'containers/Modals/RemoveApartment';
import ModalStartEvictionAsTenant from 'containers/Modals/StartEvictionAsTenant';
import Button from 'components/UIKit/Button';

/**
 * Sharable typedefs
 */

type ApartmentLatestAuctionWithMyBid = {
    latestAuction?: Maybe<Pick<AuctionNode, 'hasMyBid'>>;
};

type ApartmentSharableParams = Pick<ApartmentNode, 'status' | 'id'>;

type ApartmentCityId = {
    city?: Maybe<Pick<CityNode, 'id'>>;
};

/**
 * AuctionMakeBid
 */
type ActionMakeBidProps = {
    apartment: ApartmentSharableParams & ApartmentLatestAuctionWithMyBid;
};

const ActionMakeBid = ({ apartment }: ActionMakeBidProps) => {
    const [t] = useTranslation();
    const modal = useModal(false);
    const isActionAvailable = AptStatus.Contest === apartment.status && !apartment.latestAuction?.hasMyBid;

    /** Cleanup popup  */
    useEffect(() => {
        if (!isActionAvailable) modal.close();
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActionAvailable]);

    return (
        <>
            {isActionAvailable && (
                <Button onClick={modal.open} color="primary">
                    {t('apartmentCard.actions.contest')}
                </Button>
            )}

            <ModalApartmentContest isOpen={modal.isOpen} close={modal.close} id={apartment.id} />
        </>
    );
};

/**
 * ActionCancelBid
 */
type ActionCancelBidProps = {
    apartment: ApartmentSharableParams & ConstructAddressVariables & ApartmentLatestAuctionWithMyBid;
};
const ActionCancelBid = ({ apartment }: ActionCancelBidProps) => {
    const [t] = useTranslation();
    const modal = useModal(false);
    const constructAddress = useConstructAddress();
    const isActionAvailable = AptStatus.Contest === apartment.status && apartment.latestAuction?.hasMyBid;
    /** Cleanup popup  */
    useEffect(() => {
        if (!isActionAvailable) modal.close();
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActionAvailable]);

    return (
        <>
            {isActionAvailable && (
                <Button onClick={modal.open} color="secondary">
                    {t('apartmentCard.actions.refund')}
                </Button>
            )}

            <ModalWithdrawFromContest {...modal} id={apartment.id} address={constructAddress(apartment)} />
        </>
    );
};

/**
 * ActionOwnerContacts
 */
type ActionOwnerContactsProps = {
    apartment: ApartmentSharableParams & ApartmentLatestAuctionWithMyBid;
};
const ActionOwnerContacts = ({ apartment }: ActionOwnerContactsProps) => {
    const [t] = useTranslation();
    const modal = useModal();
    const isActionAvailable = AptStatus.Contest === apartment.status && apartment.latestAuction?.hasMyBid;

    /** Cleanup popup  */
    useEffect(() => {
        if (!isActionAvailable) modal.close();
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActionAvailable]);

    return (
        <>
            {isActionAvailable && (
                <Button onClick={modal.open} color="primary">
                    {t('apartmentCard.actions.contacts')}
                </Button>
            )}
            <ModalContacts isOpen={modal.isOpen} close={modal.close} type="owner" aptId={apartment.id} />
        </>
    );
};

/**
 * ActionWinnerManagerContacts
 */
type ActionWinnerManagerContactsProps = {
    apartment: ApartmentSharableParams &
        ApartmentCityId & {
            latestAuction?: Maybe<Pick<AuctionNode, 'isIAmWinner'>>;
            actualRental?: Maybe<Pick<RentalNode, 'isIAmTenant' | 'isExpired'>>;
        };
};
const ActionWinnerManagerContacts = ({ apartment }: ActionWinnerManagerContactsProps) => {
    const [t] = useTranslation();
    const modal = useModal();
    const isActionAvailable =
        (apartment.status === AptStatus.ContestFinished && apartment.latestAuction?.isIAmWinner) ||
        ((apartment.status === AptStatus.Rented || apartment.status === AptStatus.Eviction) &&
            apartment.actualRental?.isIAmTenant) ||
        (apartment.status === AptStatus.Sold &&
            apartment.actualRental?.isIAmTenant &&
            !apartment.actualRental?.isExpired);

    /** Cleanup popup  */
    useEffect(() => {
        if (!isActionAvailable) modal.close();
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActionAvailable]);

    return (
        <>
            {isActionAvailable && (
                <Button onClick={modal.open} color="tertiary">
                    {t('apartmentCard.actions.help')}
                </Button>
            )}
            <ModalContacts isOpen={modal.isOpen} close={modal.close} type="manager" cityId={apartment.city?.id!} />
        </>
    );
};

/**
 * ActionSignTokenization
 */
type ActionWinnerContestAgreementProps = {
    apartment: ApartmentSharableParams & {
        latestAuction?: Maybe<Pick<AuctionNode, 'isIAmWinner' | 'isWinnerAgree'>>;
    };
};
const ActionWinnerContestAgreement = ({ apartment }: ActionWinnerContestAgreementProps) => {
    const [t] = useTranslation();
    const modal = useModal();
    const isActionAvailable =
        apartment.status === AptStatus.ContestFinished &&
        apartment.latestAuction?.isIAmWinner &&
        !apartment.latestAuction?.isWinnerAgree;

    /** Cleanup popup  */
    useEffect(() => {
        if (!isActionAvailable) modal.close();
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActionAvailable]);

    return (
        <>
            {isActionAvailable && (
                <Button onClick={modal.open} color="primary">
                    {t('apartmentCard.actions.winnerAgreement')}
                </Button>
            )}
            <ModalContestWinnerAgreement isOpen={modal.isOpen} close={modal.close} aptId={apartment.id} />
        </>
    );
};

/**
 * ActionRefuseToWin
 */
type ActionRefuseToWinProps = {
    apartment: ApartmentSharableParams &
        ConstructAddressVariables & {
            latestAuction?: Maybe<Pick<AuctionNode, 'isIAmWinner' | 'isWinnerAgree'>>;
        };
};
const ActionRefuseToWin = ({ apartment }: ActionRefuseToWinProps) => {
    const [t] = useTranslation();
    const modal = useModal();
    const constructAddress = useConstructAddress();
    const isActionAvailable =
        apartment.status === AptStatus.ContestFinished &&
        apartment.latestAuction?.isIAmWinner &&
        !apartment.latestAuction?.isWinnerAgree;
    /** Cleanup popup  */
    useEffect(() => {
        if (!isActionAvailable) modal.close();
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActionAvailable]);

    return (
        <>
            {isActionAvailable && (
                <Button onClick={modal.open} color="secondary">
                    {t('apartmentCard.actions.giveUpVictory')}
                </Button>
            )}

            <ModalRefuseToWin
                isOpen={modal.isOpen}
                close={modal.close}
                id={apartment.id}
                isWinnerAgree={!!apartment.latestAuction?.isWinnerAgree}
                address={constructAddress(apartment)}
            />
        </>
    );
};

/**
 * ActionCancelRental
 */
type ActionCancelRentalProps = {
    apartment: ApartmentSharableParams &
        ConstructAddressVariables & {
            actualRental?: Maybe<Pick<RentalNode, 'isIAmTenant'>>;
        };
};
const ActionCancelRental = ({ apartment }: ActionCancelRentalProps) => {
    const [t] = useTranslation();
    const modal = useModal();
    const constructAddress = useConstructAddress();
    const isActionAvailable = apartment.actualRental?.isIAmTenant && apartment.status === AptStatus.Rented;

    /** Cleanup popup  */
    useEffect(() => {
        if (!isActionAvailable) modal.close();
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActionAvailable]);

    return (
        <>
            {isActionAvailable && (
                <Button onClick={modal.open} color="tertiary">
                    {t('apartmentCard.actions.leave')}
                </Button>
            )}

            <ModalStartEvictionAsTenant
                isOpen={modal.isOpen}
                close={modal.close}
                id={apartment.id}
                address={constructAddress(apartment)}
            />
        </>
    );
};

/**
 * ActionRenewalLicense
 */
type ActionRenewalLicenseProps = {
    apartment: ApartmentSharableParams &
        ApartmentCityId & {
            actualRental?: Maybe<Pick<RentalNode, 'isIAmTenant' | 'canBeExtended' | 'isExpired'>>;
        };
};
const ActionRenewalLicense = ({ apartment }: ActionRenewalLicenseProps) => {
    const [t] = useTranslation();
    const modalRenewal = useModal();
    const modalContacts = useModal();
    const isActionAvailable =
        apartment.status === AptStatus.Rented &&
        apartment.actualRental?.isIAmTenant &&
        !apartment.actualRental.isExpired;
    /** Cleanup popup  */
    useEffect(() => {
        if (!isActionAvailable) {
            modalRenewal.close();
            modalContacts.close();
        }
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActionAvailable]);

    return (
        <>
            {isActionAvailable && (
                <Button onClick={modalRenewal.open} disabled={!apartment.actualRental?.canBeExtended} color="primary">
                    {t('apartmentCard.actions.renewLicense')}
                </Button>
            )}
            <ModalApartmentRenewalLicense
                isOpen={modalRenewal.isOpen}
                aptId={apartment.id}
                onSubmit={() => {
                    modalContacts.open();
                    modalRenewal.close();
                }}
                close={modalRenewal.close}
            />
            <ModalContacts
                isOpen={modalContacts.isOpen}
                cityId={apartment.city?.id!}
                type="manager"
                close={modalContacts.close}
            />
        </>
    );
};

/**
 * ActionEditApartment
 */
type ActionEditApartmentProps = {
    apartment: ApartmentSharableParams;
};

const ActionEditApartment = ({ apartment }: ActionEditApartmentProps) => {
    const [t] = useTranslation();

    const isActionAvailable = apartment.status === AptStatus.Draft || apartment.status === AptStatus.Rework;

    return (
        <>
            {isActionAvailable && (
                <Button color="primary" tag="link" to={URL_MAP.editApartment(apartment.id)}>
                    {t('apartmentCard.actions.edit')}
                </Button>
            )}
        </>
    );
};

/**
 * ActionRemoveApartment
 */
type ActionRemoveApartmentProps = {
    apartment: ApartmentSharableParams;
};

const ActionRemoveApartment = ({ apartment }: ActionRemoveApartmentProps) => {
    const modal = useModal();
    const [t] = useTranslation();

    const isActionAvailable =
        apartment.status === AptStatus.Draft ||
        apartment.status === AptStatus.Rework ||
        apartment.status === AptStatus.Locked;

    /** Cleanup popup  */
    useEffect(() => {
        if (!isActionAvailable) modal.close();
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActionAvailable]);

    return (
        <>
            {isActionAvailable && (
                <Button onClick={modal.open} color="tertiary">
                    {t('apartmentCard.actions.remove')}
                </Button>
            )}
            <ModalRemoveApartment {...modal} id={apartment.id} />
        </>
    );
};

/**
 * ActionCancelRequest
 */
type ActionCancelRequestProps = {
    apartment: ApartmentSharableParams & {
        latestAuction?: Maybe<Pick<AuctionNode, 'isWinnerSelectionInProgress'>>;
    };
};

const ActionCancelRequest = ({ apartment }: ActionCancelRequestProps) => {
    const modal = useModal();
    const [t] = useTranslation();

    const isActionAvailable =
        apartment.status === AptStatus.Moderation ||
        apartment.status === AptStatus.Contest ||
        (apartment.status === AptStatus.ContestFinished && !apartment.latestAuction?.isWinnerSelectionInProgress);

    /** Cleanup popup  */
    useEffect(() => {
        if (!isActionAvailable) modal.close();
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActionAvailable]);

    return (
        <>
            {isActionAvailable && (
                <Button onClick={modal.open} color="secondary">
                    {t('apartmentCard.actions.cancelRequest')}
                </Button>
            )}
            <ModalCancelRequest {...modal} id={apartment.id} status={apartment.status!} />
        </>
    );
};

/**
 * ActionWinnerManagerContacts
 */
type ActionOwnerManagerContactsProps = {
    apartment: ApartmentSharableParams &
        ApartmentCityId & {
            latestAuction?: Maybe<Pick<AuctionNode, 'isIAmWinner'>>;
        };
};
const ActionOwnerManagerContacts = ({ apartment }: ActionOwnerManagerContactsProps) => {
    const [t] = useTranslation();
    const modal = useModal();
    const isActionAvailable =
        apartment.status === AptStatus.ContestFinished ||
        apartment.status === AptStatus.Contest ||
        apartment.status === AptStatus.Locked ||
        apartment.status === AptStatus.Rework ||
        apartment.status === AptStatus.Moderation;

    /** Cleanup popup  */
    useEffect(() => {
        if (!isActionAvailable) modal.close();
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActionAvailable]);

    return (
        <>
            {isActionAvailable && (
                <Button onClick={modal.open} color="tertiary">
                    {t('apartmentCard.actions.help')}
                </Button>
            )}
            <ModalContacts isOpen={modal.isOpen} close={modal.close} type="manager" cityId={apartment.city?.id!} />
        </>
    );
};

/**
 * ActionOwnerEditRealId
 */
type ActionOwnerEditRealIdProps = {
    apartment: ApartmentSharableParams;
};
const ActionOwnerEditRealId = ({ apartment }: ActionOwnerEditRealIdProps) => {
    const [t] = useTranslation();
    const modal = useModal();
    const isActionAvailable = apartment.status === AptStatus.Contest;
    /** Cleanup popup  */
    useEffect(() => {
        if (!isActionAvailable) modal.close();
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActionAvailable]);

    return (
        <>
            {isActionAvailable && (
                <Button onClick={modal.open} color="tertiary">
                    {t('apartmentCard.actions.editRealId')}
                </Button>
            )}
            <ModalEditRealId id={apartment.id} isOpen={modal.isOpen} close={modal.close} />
        </>
    );
};

/**
 * ActionSignTokenization
 */
type ActionOwnerSignTokenizationProps = {
    apartment: ApartmentSharableParams & {
        latestAuction?: Maybe<Pick<AuctionNode, 'isWinnerAgree'>>;
        tokenizationInfo?: Maybe<Pick<TokenizationStruct, 'isSignedByOwner'>>;
    };
};
const ActionOwnerSignTokenization = ({ apartment }: ActionOwnerSignTokenizationProps) => {
    const [t] = useTranslation();
    const modal = useModal();
    const isActionAvailable = apartment.status === AptStatus.ContestFinished && apartment.latestAuction?.isWinnerAgree;
    const isSigned = apartment.tokenizationInfo?.isSignedByOwner;

    /** Cleanup popup  */
    useEffect(() => {
        if (!isActionAvailable) modal.close();
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActionAvailable]);

    return (
        <>
            {isActionAvailable && (
                <Button onClick={modal.open} color="primary">
                    {t('apartmentCard.actions.tokenize', {
                        context: isSigned ? 'done' : undefined
                    })}
                </Button>
            )}
            <ModalApartmentSignTokenization
                isOpen={modal.isOpen}
                close={modal.close}
                type="owner"
                aptId={apartment.id}
            />
        </>
    );
};

/**
 * PublicApartmentActions
 */
type PublicApartmentActionsProps = ActionMakeBidProps &
    ActionCancelBidProps &
    ActionOwnerContactsProps &
    ActionWinnerManagerContactsProps &
    ActionWinnerContestAgreementProps &
    ActionRefuseToWinProps &
    ActionCancelRentalProps &
    ActionRenewalLicenseProps;

export const PublicApartmentActions = ({ apartment }: PublicApartmentActionsProps) => {
    return (
        <>
            {/** auction running, no bid */}
            <ActionMakeBid apartment={apartment} />

            {/** auction running, has bid */}
            <ActionOwnerContacts apartment={apartment} />
            <ActionCancelBid apartment={apartment} />

            {/** auction finished, winner */}
            <ActionWinnerContestAgreement apartment={apartment} />
            <ActionRefuseToWin apartment={apartment} />

            {/** rented, tenant */}
            <ActionRenewalLicense apartment={apartment} />
            <ActionCancelRental apartment={apartment} />

            <ActionWinnerManagerContacts apartment={apartment} />
        </>
    );
};

/**
 * OwnerApartmentActions
 */
type OwnerApartmentActionsProps = ActionEditApartmentProps &
    ActionRemoveApartmentProps &
    ActionCancelRequestProps &
    ActionOwnerManagerContactsProps &
    ActionOwnerSignTokenizationProps &
    ActionOwnerEditRealIdProps;

export const OwnerApartmentActions = ({ apartment }: OwnerApartmentActionsProps) => {
    return (
        <>
            <ActionEditApartment apartment={apartment} />

            <ActionCancelRequest apartment={apartment} />
            <ActionRemoveApartment apartment={apartment} />

            <ActionOwnerSignTokenization apartment={apartment} />
            <ActionOwnerManagerContacts apartment={apartment} />
            <ActionOwnerEditRealId apartment={apartment} />
        </>
    );
};
