import classNames from 'classnames/bind';
import QRCodeStyling, { DrawType, Options } from 'qr-code-styling';
import React, { memo, useEffect, useMemo, useRef } from 'react';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

export type Props = {
    data?: string | number | null;
    className?: string;
};

let counter = 0;

export class QRCode extends QRCodeStyling {
    count: number;

    constructor(options?: Partial<Options> | undefined) {
        super(options);
        this.count = counter++;
    }

    update(options?: Partial<Options> | undefined): void {
        super.update(options);

        if (this._svgDrawingPromise) {
            this._svgDrawingPromise.then(() => {
                if (!(this._svg instanceof SVGElement)) {
                    return;
                }

                const clipPath = this._svg.querySelector<SVGClipPathElement>('#clip-path-dot-color');
                const rect = this._svg.querySelector<SVGRectElement>(`rect[clip-path="url('#clip-path-dot-color')"]`);

                if (clipPath && rect) {
                    clipPath.setAttribute('id', `clip-path-dot-color-${this.count}`);
                    rect.setAttribute('clip-path', `url('#clip-path-dot-color-${this.count}')`);
                }
            });
        }
    }
}

const QR = memo(({ data, className }: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const qrCode = useRef(new QRCode()).current;

    const options: Options = useMemo(
        () => ({
            width: 200,
            height: 200,
            type: 'svg' as DrawType,
            data: data?.toString(),
            margin: 0,
            qrOptions: {
                errorCorrectionLevel: 'L'
            },
            dotsOptions: {
                color: '#4A3F3F',
                type: 'extra-rounded'
            },
            backgroundOptions: {
                color: 'transparent'
            },
            cornersSquareOptions: {
                type: 'extra-rounded'
            },
            cornersDotOptions: {
                type: 'dot'
            }
        }),
        [data]
    );

    useEffect(() => {
        if (ref.current) qrCode.append(ref.current);
    }, [qrCode]);

    useEffect(() => {
        if (!qrCode) return;

        qrCode.update(options);
    }, [data, options, qrCode]);

    return <div className={cx('Component', className)} ref={ref} />;
});

export default QR;
