import { AptStatus, RentalSortEnum, useManagerRentalsQuery, useManagerRentalsWsSubscription } from 'apollo/generted';
import classNames from 'classnames/bind';
import { IconFolderWithLabel } from 'components/Icons';
import Button from 'components/UIKit/Button';
import Counter from 'components/UIKit/Counter';
import Status from 'components/UIKit/Status';
import Tooltip, { useTooltip } from 'components/UIKit/Tooltip/Tooltip';
import { URL_MAP } from 'constant';
import ModalAddRental, { Props as ModalAddRentalProps } from 'containers/Modals/AddRental';
import ModalContacts, { Props as ModalContactsProps } from 'containers/Modals/Contacts';
import ModalStartEvictionAsManager, {
    Props as ModalStartEvictionAsManagerProps
} from 'containers/Modals/StartEvictionAsManager';
import { addSpacesForThousands, getPassedDays, useModal } from 'helpers';
import formatDate from 'helpers/formatDate';
import { useIsTouchableDevice } from 'helpers/hooks';
import { useConstructAddress } from 'helpers/useParsedApartmentData';
import update from 'immutability-helper';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Apartments from '../components/Apartments';
import Card from '../components/Card';
import { Section, SectionHeader, SectionMain, SectionTitle } from '../components/Section';
import SectionSort from '../components/SectionSort';
import { useManageContext } from '../context';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

const Rentals = memo(() => {
    const [t] = useTranslation();
    const [sort, setSort] = useState(RentalSortEnum.ExpirationDateDesc);
    const { rentalsNum } = useManageContext()?.city?.notaryUpdates ?? {};
    return (
        <Section className={cx('Section')}>
            <SectionHeader>
                <SectionTitle>
                    {t('pageManage.rentals.title')}
                    {!!rentalsNum && <Counter>{rentalsNum}</Counter>}
                </SectionTitle>

                <SectionSort
                    controlClassName={cx('SortControl')}
                    options={[
                        {
                            isActive: sort === RentalSortEnum.ExpirationDateDesc,
                            label: t(`pageManage.rentals.sort.${RentalSortEnum.ExpirationDateDesc}`),
                            onClick: () => setSort(RentalSortEnum.ExpirationDateDesc)
                        },
                        {
                            isActive: sort === RentalSortEnum.ExpirationDate,
                            label: t(`pageManage.rentals.sort.${RentalSortEnum.ExpirationDate}`),
                            onClick: () => setSort(RentalSortEnum.ExpirationDate)
                        }
                    ]}
                />
            </SectionHeader>

            <SectionMain>
                <Main sort={sort} />
            </SectionMain>
        </Section>
    );
});

const Main = ({ sort }: { sort: RentalSortEnum }) => {
    const cityId = useManageContext().city?.id!;
    const [offset, setOffset] = useState(0);
    const modalStartEviction = useModal<ModalStartEvictionAsManagerProps>();
    const modalContact = useModal<ModalContactsProps>();
    const modalAddRental = useModal<ModalAddRentalProps>();
    const [t] = useTranslation();
    const constructAddress = useConstructAddress();
    const STEP = 10;

    const {
        data: apartmentsData,
        loading,
        error,
        ...apartmentsQuery
    } = useManagerRentalsQuery({
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        variables: {
            first: STEP,
            offset,
            sort: [sort],
            cityId
        }
    });

    /** is any loading now */
    const apartments = apartmentsData?.rentals?.apartments;
    const total = apartmentsData?.rentals?.pageInfo.total || 0;
    const page = offset! / STEP;

    const onChangePage = (page: number) => {
        window.scrollTo({ top: 0 });
        setOffset(page * STEP);
    };

    const removeApt = (aptId: number) => {
        apartmentsQuery.updateQuery((currentData) => {
            return update(currentData, {
                rentals: {
                    apartments: (arr) => arr.filter((item) => item?.id !== aptId)
                }
            });
        });
    };

    useManagerRentalsWsSubscription({
        skip: !!error,
        variables: {
            cityId
        },
        onSubscriptionData: ({ subscriptionData: { data } }) => {
            apartmentsQuery.updateQuery((queryData) => {
                const { apt: target, oldStatus } = data?.RDUpdates ?? {};
                if (!target) return queryData;

                const targetId = target.id;
                const needToRemove = target.status !== AptStatus.Rented;
                const isNewApartment = target.status === AptStatus.Rented && oldStatus !== AptStatus.Rented;
                const apartments = queryData.rentals?.apartments ?? [];

                if (needToRemove) {
                    /**
                     * Close all modals for this apartment
                     */
                    if (modalStartEviction.payload?.id === targetId) {
                        modalStartEviction.close();
                    }
                    if (modalAddRental.payload?.aptId === targetId) {
                        modalAddRental.close();
                    }
                    if (modalContact.payload?.type === 'tenant' && modalContact.payload.aptId === targetId) {
                        modalContact.close();
                    }

                    const filteredApartments = apartments.filter((a) => a?.id !== targetId);
                    if (!filteredApartments.length) {
                        if (page === 0) {
                            apartmentsQuery.refetch();
                        } else {
                            onChangePage((page ?? 1) - 1);
                        }
                        return queryData;
                    }

                    return update(queryData, {
                        rentals: {
                            apartments: () => filteredApartments,
                            pageInfo: {
                                total: (total) => total - 1
                            }
                        }
                    });
                } else if (isNewApartment && page === 0 && apartments.every((apt) => apt?.id !== targetId)) {
                    return update(queryData, {
                        rentals: {
                            apartments: () => {
                                const newApartments = [target, ...apartments];
                                if (newApartments.length > STEP) newApartments.length = STEP;
                                return newApartments;
                            },
                            pageInfo: {
                                total: (total) => total + 1
                            }
                        }
                    });
                }

                return queryData;
            });
        }
    });

    return (
        <Apartments
            loading={loading}
            onClickReload={() => apartmentsQuery.refetch()}
            error={error?.message}
            isEmpty={!apartments?.length}
            page={page}
            pageCount={Math.ceil(total / STEP)}
            empty={{
                icon: <IconFolderWithLabel />,
                title: t('pageManage.rentals.empty.title'),
                text: t('pageManage.rentals.empty.text')
            }}
            onChangePage={onChangePage}
        >
            {apartments?.map((apartment) => {
                if (!apartment) return null;
                const { expirationDate, price, tenantAccount, canBeExtended, isExpired } = apartment.actualRental ?? {};

                return (
                    <Card
                        data={apartment}
                        key={apartment.id}
                        to={URL_MAP.apartment(apartment.id)}
                        status={
                            isExpired && <Status color="red">{t('pageManage.rentals.card.params.isExpired')}</Status>
                        }
                        params={[
                            {
                                label: t('pageManage.rentals.card.params.expirationDate'),
                                value: formatDate(expirationDate, true)
                            },
                            isExpired
                                ? {
                                      label: t('pageManage.rentals.card.params.expiredPeriod'),
                                      value: t('global.days', {
                                          count: getPassedDays(new Date(expirationDate + 'z'))
                                      })
                                  }
                                : null,
                            {
                                label: t('pageManage.rentals.card.params.price'),
                                value: `${price ? addSpacesForThousands(price) : '-'} ${
                                    apartment.communalCurrency ?? ''
                                }`
                            },
                            {
                                label: t('pageManage.rentals.card.params.tenantAccount'),
                                value: tenantAccount
                            }
                        ]}
                        actions={
                            <>
                                {!isExpired && (
                                    <ButtonAddRental
                                        onClick={() =>
                                            modalAddRental.open({
                                                aptId: apartment.id
                                            })
                                        }
                                        canBeExtended={canBeExtended}
                                    />
                                )}
                                <Button
                                    onClick={() =>
                                        modalStartEviction.open({
                                            id: apartment.id,
                                            tenantAccount: tenantAccount!,
                                            address: constructAddress(apartment),
                                            onSuccess: () => removeApt(apartment.id)
                                        })
                                    }
                                    color="tertiary"
                                    type="button"
                                >
                                    {t('pageManage.rentals.card.actions.startEviction')}
                                </Button>
                                <Button
                                    onClick={() => modalContact.open({ aptId: apartment.id, type: 'tenant' })}
                                    color="tertiary"
                                    type="button"
                                >
                                    {t('pageManage.rentals.card.actions.contact')}
                                </Button>
                            </>
                        }
                    />
                );
            })}

            <ModalAddRental {...modalAddRental} {...modalAddRental.payload!} />
            <ModalContacts {...modalContact} {...modalContact.payload!} />
            <ModalStartEvictionAsManager {...modalStartEviction} {...modalStartEviction.payload!} />
        </Apartments>
    );
};

const ButtonAddRental = ({ onClick, canBeExtended }: { onClick(): void; canBeExtended?: boolean | null }) => {
    const [t] = useTranslation();
    const tooltip = useTooltip();
    const isTouchable = useIsTouchableDevice();

    return (
        <div
            ref={tooltip.setPopperReference}
            onMouseEnter={!isTouchable && !canBeExtended ? tooltip.show : undefined}
            onMouseLeave={!isTouchable && !canBeExtended ? tooltip.hide : undefined}
            onClick={isTouchable && !canBeExtended ? () => tooltip.setShow(!tooltip.isActive) : undefined}
        >
            <Button onClick={onClick} disabled={!canBeExtended} color="primary" type="button">
                {t('pageManage.rentals.card.actions.addRental')}
            </Button>
            <Tooltip {...tooltip}>
                {t('pageManage.rentals.card.actions.addRental', {
                    context: 'canNotBeExtended'
                })}
            </Tooltip>
        </div>
    );
};

export default Rentals;
