import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { useWithdrawFromContestMutation } from 'apollo/generted';
import { getErrorData, getErrorI18nText } from 'helpers';
import { useToast } from 'helpers/hooks';
import Button from 'components/UIKit/Button';
import Modal, { ModalHeader, ModalProps, ModalBody } from 'components/UIKit/Modal';
import ModalErrorScreen from 'components/UIKit/Modal/ErrorScreen';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

type Props = {
    id: number;
    address: string;
};

const ModalWithdrawFromContest = ({ address, id, ...props }: ModalProps & Props) => {
    const [t] = useTranslation();
    const [formError, setFormError] = useState('');
    const toast = useToast();
    const [withdrawFromContestMutation, { loading }] = useWithdrawFromContestMutation();

    const cancelBid = () => {
        withdrawFromContestMutation({ variables: { input: { aptId: id } } })
            .then(({ data }) => {
                if (data?.withdrawFromContest?.__typename === 'WithdrawFromContestSuccess') {
                    toast.success(t('modalWithdrawFromContest.successMsg'));
                    props.close?.();
                } else {
                    setFormError(
                        t([
                            `modalWithdrawFromContest.error.${data?.withdrawFromContest?.__typename}`,
                            getErrorI18nText(t, data?.withdrawFromContest?.errorMessage)
                        ])
                    );
                }
            })
            .catch((e) => {
                setFormError(getErrorI18nText(t, getErrorData(e).message));
            });
    };

    return (
        <Modal {...props}>
            <ModalHeader>{t('modalWithdrawFromContest.title')}</ModalHeader>
            <ModalBody>
                <div className={cx('Content')}>
                    <p className={cx('Text')}>{t('modalWithdrawFromContest.description')}</p>
                    <p className={cx('Param')}>
                        <b>{t('apartmentStats.address.label')}</b>
                        <span>{address}</span>
                    </p>
                </div>
                <Button loading={loading} color="primary" onClick={cancelBid} type="button">
                    {t('modalWithdrawFromContest.btnSubmit')}
                </Button>
            </ModalBody>
            <ModalErrorScreen resetError={() => setFormError('')} text={formError} />
        </Modal>
    );
};

export default ModalWithdrawFromContest;
