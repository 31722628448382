import React, { ReactNode } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

type Props = {
    icon: ReactNode;
    value: ReactNode;
    label?: ReactNode;
    className?: string;
    iconClassName?: string;
    valueClassName?: string;
    tag?: keyof JSX.IntrinsicElements;
};

const ProfileParam = ({
    icon,
    value,
    label,
    tag: Tag = 'div',
    className = '',
    iconClassName = '',
    valueClassName = ''
}: Props) => {
    return (
        <Tag className={cx('Param', className)}>
            <span className={cx('ParamIcon', iconClassName)}>{icon}</span>

            <div className={cx('ParamMain')}>
                {label && <span className={cx('ParamLabel')}>{label}</span>}
                <b className={cx('ParamValue', valueClassName)}>{value}</b>
            </div>
        </Tag>
    );
};

export default ProfileParam;
