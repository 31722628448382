import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { ApartmentNode, ApartmentQuery, CityNode, EditableApartmentQuery, Maybe, PlaceNode } from 'apollo/generted';

type Apartment_apartment = ApartmentQuery['apartment'];
type EditableApartment_apartment = EditableApartmentQuery['apartment'];

export type ConstructAddressVariables =
    | (Pick<ApartmentNode, 'number'> & {
          place?: Maybe<Pick<PlaceNode, 'address'>>;
          city?: Maybe<Pick<CityNode, 'name'>>;
      })
    | null
    | undefined;

export const useConstructAddress = () => {
    const [t] = useTranslation();
    return (param: ConstructAddressVariables, { hideCityName = false } = {}) => {
        return [
            !hideCityName && param?.city?.name,
            param?.place?.address,
            param?.number && `${t('apartmentStats.apartmentNumber.unit')} ${param?.number}`
        ]
            .filter(Boolean)
            .join(', ');
    };
};

/** helper to get translated apartment params */
// TODO: Change export
// eslint-disable-next-line import/no-anonymous-default-export
export default (apartment?: null | Partial<Apartment_apartment | EditableApartment_apartment>) => {
    const [t] = useTranslation();
    return useMemo(() => {
        const elevators = [
            apartment?.hasElevator && t(`apartmentStats.elevator.hasElevator`),
            apartment?.hasCargoElevator && t(`apartmentStats.elevator.hasCargoElevator`)
        ].filter(Boolean);

        const parking = [
            apartment?.hasGroundParking && t(`apartmentStats.parking.hasGroundParking`),
            apartment?.hasMultilevelParking && t(`apartmentStats.parking.hasMultilevelParking`),
            apartment?.hasUndergroundParking && t(`apartmentStats.parking.hasUndergroundParking`)
        ].filter(Boolean);

        const rooms = apartment?.isStudio ? t('apartmentStats.rooms.isStudio') : apartment?.rooms ?? '';

        const infra = [
            apartment?.hasGarbageChute && t('apartmentStats.garbageChute.label'),
            apartment?.hasSecurity && t('apartmentStats.security.label'),
            apartment?.hasConcierge && t('apartmentStats.concierge.label'),
            apartment?.hasIntercom && t('apartmentStats.intercom.label'),
            apartment?.hasGas && t('apartmentStats.gas.label'),
            apartment?.hasVideoSurveillance && t('apartmentStats.videoSurveillance.label')
        ].filter(Boolean);

        const windows = [
            apartment?.hasYardWindows && t('apartmentStats.window.hasYardWindows'),
            apartment?.hasStreetWindows && t('apartmentStats.window.hasStreetWindows')
        ].filter(Boolean);

        const balconies = [
            apartment?.balconies && t(`apartmentStats.balconies.${apartment?.balconies}`),
            apartment?.hasLoggia && t('apartmentStats.balconies.hasLoggia')
        ].filter(Boolean);

        const bathroom = [
            apartment?.dividedBaths && t('apartmentStats.bathroom.DIVIDED'),
            apartment?.combinedBaths && t('apartmentStats.bathroom.COMBINED')
        ].filter(Boolean);

        const photos = apartment?.photos ?? [];

        return {
            elevators,
            parking,
            rooms,
            balconies,
            windows,
            bathroom,
            infra,
            photos
        };
    }, [t, apartment]);
};
