import React, { Fragment, ReactNode, useState } from 'react';
import { WalletName } from 'apollo/generted';
import TabSwitcher from 'components/UIKit/TabSwitcher';
import WalletAuthScreen from '.';
import { OnAuth } from '../../Modals/Sign';
import { ModalBody } from 'components/UIKit/Modal';
import { EOS_WALLET_LIST } from 'constant';

const DesktopWalletAuthForm = ({
    onAuth,
    onError,
    description
}: {
    onAuth: OnAuth;
    onError(v: string): void;
    description: ReactNode;
}) => {
    const [wallet, setWallet] = useState(WalletName.Paycash);

    return (
        <Fragment>
            <TabSwitcher<WalletName>
                onChange={setWallet}
                activeType={wallet}
                options={EOS_WALLET_LIST.map(({ label, logo, type }) => {
                    return {
                        type,
                        children: (
                            <Fragment>
                                <i>
                                    <img src={logo} alt="" />
                                </i>
                                {label}
                            </Fragment>
                        )
                    };
                })}
            />
            <ModalBody>
                <WalletAuthScreen
                    key={wallet}
                    wallet={wallet}
                    description={description}
                    onAuth={onAuth}
                    onError={onError}
                />
            </ModalBody>
        </Fragment>
    );
};

export default DesktopWalletAuthForm;
