import {
    BuyAptRequestNode,
    BuyStatus,
    Event,
    useMyBuyRequestsQuery,
    useMyBuyRequestsWsSubscription
} from 'apollo/generted';
import classNames from 'classnames/bind';
import { IconHome, IconPlus, IconWarningNew } from 'components/Icons';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination';
import Button from 'components/UIKit/Button';
import Status from 'components/UIKit/Status';
import { URL_MAP } from 'constant';
import ModalAddBuyoutRequest from 'containers/Modals/AddBuyoutRequest';
import ModalConfirmBuyout, { Props as ModalConfirmBuyoutProps } from 'containers/Modals/ConfirmBuyout';
import ModalContacts, { Props as ModalContactsProps } from 'containers/Modals/Contacts';
import ModalCancelBuyout, { Props as ModalCancelBuyoutProps } from 'containers/Modals/CancelBuyout';
import update from 'immutability-helper';
import { Section, SectionHeader, SectionMain, SectionTitle } from 'containers/Pages/Manage/components/Section';
import { getErrorData, getErrorI18nText, useModal } from 'helpers';
import formatDate from 'helpers/formatDate';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from './components/Card';
import styles from './index.module.scss';
const cx = classNames.bind(styles);
const STEP = 10;

const Buyout = () => {
    const [t] = useTranslation();
    const [offset, setOffset] = useState(0);
    const modalAddBuyoutRequest = useModal(false);
    const modalConfirmBuyout = useModal<ModalConfirmBuyoutProps>(false);
    const modalCancelBuyout = useModal<ModalCancelBuyoutProps>(false);
    const modalContacts = useModal<ModalContactsProps & { buyRequestId: BuyAptRequestNode['id'] }>(false);

    const {
        data: apartmentsData,
        loading,
        error,
        ...query
    } = useMyBuyRequestsQuery({
        fetchPolicy: 'network-only',
        variables: {
            first: STEP,
            offset
        }
    });

    useMyBuyRequestsWsSubscription({
        skip: !!error,
        onSubscriptionData: ({ subscriptionData: { data } }) => {
            query.updateQuery((queryData) => {
                const { buyRequest: target, event } = data?.myBuyRequests ?? {};
                if (!target) return queryData;

                const targetId = target.id;
                const isNew = event === Event.Add;
                const buyRequests = queryData.myBuyRequests?.buyRequests ?? [];

                if (event === Event.Add) {
                    modalAddBuyoutRequest.close();
                }

                if (event === Event.Update) {
                    const isFinalStatus = target.status !== BuyStatus.InProgress;
                    if (isFinalStatus && modalContacts.payload?.buyRequestId === targetId) {
                        modalContacts.close();
                    }

                    if (isFinalStatus && modalCancelBuyout.payload?.buyRequestId === targetId) {
                        modalCancelBuyout.close();
                    }

                    if (
                        (isFinalStatus || target.isApprovedByBuyer) &&
                        modalConfirmBuyout.payload?.buyRequestId === targetId
                    ) {
                        modalConfirmBuyout.close();
                    }
                }

                if (isNew && page === 0 && buyRequests.every((apt) => apt?.id !== targetId)) {
                    return update(queryData, {
                        myBuyRequests: {
                            buyRequests: () => {
                                const newBuyRequests = [target, ...buyRequests];
                                if (newBuyRequests.length > STEP) newBuyRequests.length = STEP;
                                return newBuyRequests;
                            },
                            pageInfo: {
                                total: (total) => total + 1
                            }
                        }
                    });
                }

                return queryData;
            });
        }
    });

    const buyRequests = apartmentsData?.myBuyRequests?.buyRequests;
    const total = apartmentsData?.myBuyRequests?.pageInfo?.total || 0;
    const isEmpty = !buyRequests?.length;

    const page = offset! / STEP;
    const pageCount = Math.ceil(total / STEP);
    const onChangePage = (page: number) => {
        window.scrollTo({ top: 0 });
        setOffset(page * STEP);
    };

    return (
        <Section className={cx('Component')}>
            <SectionHeader>
                <SectionTitle to={URL_MAP.profile.index}>{t('pageProfile.buyout.title')}</SectionTitle>

                {!isEmpty && (
                    <Button
                        color="tertiary-grey"
                        size="small"
                        onClick={modalAddBuyoutRequest.open}
                        iconLeft={<IconPlus />}
                    >
                        {t('pageProfile.buyout.emptyScreen.btn')}
                    </Button>
                )}
            </SectionHeader>

            <SectionMain>
                {loading ? (
                    <div className={cx('Loader')}>
                        <Loader size={46} />
                    </div>
                ) : error ? (
                    <div className={cx('ErrorScreen')}>
                        <IconWarningNew />
                        <h6>{error && getErrorI18nText(t, getErrorData(error).message)}</h6>
                        <Button onClick={() => query.refetch()} color="secondary">
                            {t('pageProfile.buyout.errorScreen.btn')}
                        </Button>
                    </div>
                ) : !isEmpty ? (
                    <Fragment>
                        <div className={cx('ApartmentsList')}>
                            {buyRequests!.map((buyRequest) => {
                                if (!buyRequest) return null;

                                const isCancelled = buyRequest.status === BuyStatus.Cancelled;
                                const isFailed = buyRequest.status === BuyStatus.Failed;
                                const isFinished = buyRequest.status === BuyStatus.Finished;
                                const isFinalStatus = isCancelled || isFinished || isFailed;
                                const isSelecting = !buyRequest.apt;
                                return (
                                    <Card
                                        data={buyRequest.apt}
                                        key={buyRequest.id}
                                        to={buyRequest.apt ? URL_MAP.apartment(buyRequest.apt?.id) : undefined}
                                        alert={
                                            isFinished &&
                                            buyRequest.apt?.actualRental &&
                                            !buyRequest.apt.actualRental.isExpired &&
                                            t('pageProfile.buyout.card.alert', {
                                                context: 'actualRental',
                                                tenant: buyRequest.apt?.actualRental?.tenantAccount,
                                                date: formatDate(buyRequest.apt?.actualRental?.expirationDate, true)
                                            })
                                        }
                                        status={
                                            <Status
                                                color={
                                                    isFinalStatus
                                                        ? isCancelled || isFailed
                                                            ? 'red'
                                                            : 'green'
                                                        : isSelecting
                                                        ? 'yellow-light'
                                                        : buyRequest.isApprovedByBuyer
                                                        ? 'orange'
                                                        : 'yellow'
                                                }
                                            >
                                                {t(
                                                    `pageProfile.buyout.card.status.${
                                                        isFinalStatus
                                                            ? buyRequest.status
                                                            : isSelecting
                                                            ? 'SELECTION'
                                                            : buyRequest.isApprovedByBuyer
                                                            ? 'WAITING_MANAGER'
                                                            : 'WAITING_BUYER'
                                                    }`
                                                )}
                                            </Status>
                                        }
                                        params={[
                                            {
                                                label: t('pageProfile.buyout.card.params.transferTime'),
                                                value: formatDate(buyRequest.transferTime)
                                            },
                                            isFinalStatus
                                                ? {
                                                      label: t('pageProfile.buyout.card.params.endTime', {
                                                          context: buyRequest.status
                                                      }),
                                                      value: formatDate(buyRequest.endTime)
                                                  }
                                                : null,
                                            {
                                                label: t('pageProfile.buyout.card.params.transferAmount'),
                                                value: `${buyRequest.transferAmount} ${buyRequest.tokenId}`
                                            },
                                            isSelecting
                                                ? null
                                                : {
                                                      label: t('pageProfile.buyout.card.params.feeAmount'),
                                                      value: `${buyRequest.feeAmount} ${buyRequest.tokenId}`
                                                  },
                                            isSelecting
                                                ? null
                                                : {
                                                      label: t('pageProfile.buyout.card.params.returnedAmount'),
                                                      value: `${buyRequest.returnedAmount} ${buyRequest.tokenId}`
                                                  }
                                        ]}
                                        actions={
                                            isFinalStatus || isSelecting ? null : (
                                                <Fragment>
                                                    {!buyRequest.isApprovedByBuyer && (
                                                        <Button
                                                            onClick={() =>
                                                                modalConfirmBuyout.open({
                                                                    buyRequestId: buyRequest.id
                                                                })
                                                            }
                                                            color="primary"
                                                        >
                                                            {t('pageProfile.buyout.card.actions.confirm')}
                                                        </Button>
                                                    )}
                                                    <Button
                                                        onClick={() =>
                                                            modalCancelBuyout.open({
                                                                buyRequestId: buyRequest.id
                                                            })
                                                        }
                                                        color="secondary"
                                                    >
                                                        {t('pageProfile.buyout.card.actions.refuse')}
                                                    </Button>
                                                    <Button
                                                        onClick={() =>
                                                            modalContacts.open({
                                                                cityId: buyRequest.apt?.city.id!,
                                                                type: 'manager',
                                                                buyRequestId: buyRequest.id
                                                            })
                                                        }
                                                        color="tertiary"
                                                    >
                                                        {t('pageProfile.buyout.card.actions.contact')}
                                                    </Button>
                                                </Fragment>
                                            )
                                        }
                                    />
                                );
                            })}
                        </div>

                        <Pagination
                            className={cx('Pagination')}
                            page={page}
                            pageCount={pageCount}
                            onChangePage={onChangePage}
                        />
                    </Fragment>
                ) : (
                    <div className={cx('EmptyScreen')}>
                        <IconHome />
                        <h6>{t('pageProfile.buyout.emptyScreen.title')}</h6>
                        <p>{t('pageProfile.buyout.emptyScreen.text')}</p>

                        <Button onClick={modalAddBuyoutRequest.open} color="primary">
                            {t('pageProfile.buyout.emptyScreen.btn')}
                        </Button>
                    </div>
                )}
            </SectionMain>
            <ModalAddBuyoutRequest {...modalAddBuyoutRequest} {...modalAddBuyoutRequest.payload!} />
            <ModalConfirmBuyout {...modalConfirmBuyout} {...modalConfirmBuyout.payload!} />
            <ModalCancelBuyout {...modalCancelBuyout} {...modalCancelBuyout.payload!} />
            <ModalContacts {...modalContacts} {...modalContacts.payload!} />
        </Section>
    );
};

export default Buyout;
