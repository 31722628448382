import { DeepPartial } from 'helpers';

type City = {
    country: {
        name?: string | null;
    } | null;
    name?: string | null;
};
export const getRegionName = (city?: DeepPartial<City> | null) =>
    [city?.country?.name, city?.name].filter(Boolean).join(', ');

export default getRegionName;
