import { useConnectByWalletMutation, useConnectByWalletWsSubscription, WalletName } from 'apollo/generted';
import classNames from 'classnames/bind';
import { IconRepeat, IconWarningNew } from 'components/Icons';
import Loader from 'components/Loader';
import ScanQRSection from 'components/ScanQRSection';
import Button from 'components/UIKit/Button';
import { EOS_WALLET_LIST } from 'constant';
import { getErrorData, getErrorI18nText } from 'helpers';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OnAuth } from '../../Modals/Sign';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

const WalletAuthScreen = ({
    wallet,
    onAuth,
    description,
    onError
}: {
    wallet: WalletName;
    description: ReactNode;
    onAuth: OnAuth;
    onError(v: string): void;
}) => {
    const [t] = useTranslation();

    const [localError, setLocalError] = useState('');

    const actualApp = EOS_WALLET_LIST.find((a) => a.type === wallet)!;

    const [connectByWalletMutation, { loading, data }] = useConnectByWalletMutation({
        variables: { input: { walletName: wallet } }
    });

    const { qrData = '', verificationCode = '' } =
        data?.connectByWallet?.__typename === 'ConnectByWalletSuccess' ? data.connectByWallet : {};

    /**
     * After generating QR code and verificationCode
     * awaiting response from server
     */
    useConnectByWalletWsSubscription({
        skip: !verificationCode,
        variables: { verificationCode },
        onSubscriptionData: ({ subscriptionData: { data } }) => {
            const connectByWallet = data?.connectByWallet! ?? {};
            if (connectByWallet.__typename === 'WalletConnectionSuccess') {
                onAuth(connectByWallet.token!);
            } else {
                const { errorMessage = '', __typename } = connectByWallet;
                onError(t([`formEosAuth.error.${__typename}`, getErrorI18nText(t, errorMessage)]));
            }
        }
    });

    /**
     * Generate QR code and verificationCode for selected `wallet` type
     */
    const conntectByWallet = () => {
        setLocalError('');
        connectByWalletMutation()
            .then(({ data }) => {
                const connectByWallet = data?.connectByWallet! ?? {};
                if (connectByWallet.__typename !== 'ConnectByWalletSuccess') {
                    setLocalError(
                        t([
                            `formEosAuth.error.${connectByWallet?.__typename}`,
                            getErrorI18nText(t, connectByWallet?.errorMessage)
                        ])
                    );
                }
            })
            .catch((e) => setLocalError(getErrorData(e).message));
    };
    // TODO: Fix exhaustive deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(conntectByWallet, [wallet]);

    return (
        <div className={cx('Component')}>
            <ScanQRSection qrData={qrData ?? ''} text={description} noAppLink={actualApp.homeLink} />
            <div className={cx('Loader', { show: !qrData && !localError })}>
                <Loader size={40} />
            </div>
            {!loading && localError && (
                <div className={cx('QRError')}>
                    <IconWarningNew />
                    <p>{localError}</p>
                    <Button
                        round
                        size="small"
                        color="white-shadow"
                        type="button"
                        onClick={conntectByWallet}
                        iconLeft={<IconRepeat />}
                    >
                        {t('formEosAuth.btnReload')}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default WalletAuthScreen;
