import classNames from 'classnames/bind';
import React, { FC, memo, ReactNode } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

export type ButtonProps = {
    /** @default button*/
    tag?: 'button';
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export type ButtonPropsNavLink = { tag: 'navlink' } & NavLinkProps;

export type Props = (ButtonProps | ButtonPropsNavLink) & {
    isActive?: boolean;
    label: ReactNode;
    icon?: ReactNode;
    rightControl?: ReactNode;
};

export const NavBarLink = memo(
    ({ className, tag = 'button', children, isActive, icon, label, rightControl, ...rest }: Props) => {
        const props = {
            className: cx('NavBarLink', className, isActive && 'active'),
            children: (
                <span tabIndex={-1}>
                    {icon && <span className={cx('NavBarLinkIcon')}>{icon}</span>}
                    <span className={cx('NavBarLinkLabel')}>{label}</span>
                    {rightControl}
                </span>
            )
        };

        if (tag === 'navlink') {
            return <NavLink {...props} activeClassName={cx('active')} {...(rest as ButtonPropsNavLink)} />;
        } else {
            return <button {...props} {...(rest as ButtonProps)} />;
        }
    }
);

const NavBar: FC<{ className?: string }> = ({ children, className = '' }) => {
    return <nav className={cx('NavBar', className)}>{children}</nav>;
};

export default NavBar;
