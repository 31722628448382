import classNames from 'classnames/bind';
import Button from 'components/UIKit/Button';
import TextField from 'components/UIKit/TextField';
import Modal, { ModalBody, ModalHeader, ModalProps, useModalContext } from 'components/UIKit/Modal';
import React, { FormEvent, Fragment, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { useFormField, useToast } from 'helpers/hooks';
import validator from 'helpers/validator';
import { ApartmentNode, useApartmentRealIdQuery, useUpdateAptRealIdMutation } from 'apollo/generted';
import ModalLoadingScreen from 'components/UIKit/Modal/LoadingScreen';
import { getErrorData, getErrorI18nText } from 'helpers';
import ModalErrorScreen from 'components/UIKit/Modal/ErrorScreen';
const cx = classNames.bind(styles);

type Props = {
    id: ApartmentNode['id'];
};

const ModalEditRealId = memo(({ isOpen, close, ...props }: Props & ModalProps) => {
    return (
        <Modal close={close} isOpen={isOpen}>
            <Main {...props} />
        </Modal>
    );
});

const Main = ({ id }: Props) => {
    const [t] = useTranslation();
    const [formError, setFormError] = useState('');
    const field = useFormField('');
    const { close } = useModalContext();
    const toast = useToast();
    const apartmentRealIdQuery = useApartmentRealIdQuery({ variables: { id } });
    const [updateAptRealIdMutation, { loading }] = useUpdateAptRealIdMutation();

    const queryError =
        apartmentRealIdQuery.error && getErrorI18nText(t, getErrorData(apartmentRealIdQuery.error).message);

    useEffect(() => {
        if (apartmentRealIdQuery.data?.apartment) {
            field.change(apartmentRealIdQuery.data?.apartment.realId ?? '');
        }
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apartmentRealIdQuery.data]);

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        updateAptRealIdMutation({
            variables: {
                input: {
                    aptId: id,
                    realId: field.value
                }
            }
        })
            .then(({ data }) => {
                const updateAptRealId = data?.updateAptRealId! ?? {};
                if (updateAptRealId.__typename === 'UpdateAptRealIdSuccess') {
                    toast.success(t('modalEditRealId.successMsg'));
                    close();
                } else {
                    setFormError(
                        t([
                            `modalEditRealId.error.${updateAptRealId.__typename}`,
                            getErrorI18nText(t, updateAptRealId.errorMessage)
                        ])
                    );
                }
            })
            .catch((e) => {
                setFormError(getErrorI18nText(t, getErrorData(e).message));
            });
    };

    return (
        <Fragment>
            <ModalHeader>{t(`apartmentStats.realId.label`, { context: 'short' })}</ModalHeader>
            <ModalBody>
                {apartmentRealIdQuery.loading ? (
                    <ModalLoadingScreen />
                ) : queryError ? (
                    <ModalErrorScreen isStatic text={queryError} resetError={() => apartmentRealIdQuery.refetch()} />
                ) : (
                    <Fragment>
                        <form className={cx('Form')} onSubmit={onSubmit}>
                            <TextField
                                label={t(`apartmentStats.realId.label`, { context: 'short' })}
                                error={field.error}
                                onChange={(e) => field.change(validator(e, field.value))}
                                value={field.value}
                            />

                            <Button loading={loading} disabled={!field.value} color="primary" type="submit">
                                {t('modalEditRealId.btnSubmit')}
                            </Button>
                        </form>
                    </Fragment>
                )}
            </ModalBody>
            <ModalErrorScreen text={formError} resetError={() => setFormError('')} />
        </Fragment>
    );
};

export default ModalEditRealId;
