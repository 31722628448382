import {
    ApartmentNode,
    useAddRentalMutation,
    useApartmentAddRentalParamsQuery,
    useRentalExpirationAfterRenewQuery
} from 'apollo/generted';
import classNames from 'classnames/bind';
import { IconAngleDown } from 'components/Icons';
import Loader from 'components/Loader';
import Button from 'components/UIKit/Button';
import CheckboxField from 'components/UIKit/CheckboxField';
import DatePicker from 'components/UIKit/DatePicker';
import Modal, {
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalProps,
    ModalText,
    useModalContext
} from 'components/UIKit/Modal';
import ModalErrorScreen from 'components/UIKit/Modal/ErrorScreen';
import ModalLoadingScreen from 'components/UIKit/Modal/LoadingScreen';
import TextField from 'components/UIKit/TextField';
import { PATTERN_EXPENSES } from 'constant';
import { getErrorData, getErrorI18nText } from 'helpers';
import formatDate from 'helpers/formatDate';
import { useFormField, useToast } from 'helpers/hooks';
import { useConstructAddress } from 'helpers/useParsedApartmentData';
import validator from 'helpers/validator';
import { DateTime } from 'luxon';
import React, { FormEvent, Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

export type Props = {
    onSuccess?: () => void;
    aptId: ApartmentNode['id'];
};

const ModalAddRental = ({ isOpen, close, ...props }: ModalProps & Props) => {
    const modalRef = useRef<HTMLDivElement>(null);
    return (
        <Modal modalRef={modalRef} isOpen={isOpen} close={close}>
            <Main {...props} modalRef={modalRef} />
        </Modal>
    );
};

const Main = ({
    aptId,
    onSuccess,
    modalRef
}: Props & {
    modalRef: React.RefObject<HTMLDivElement>;
}) => {
    const [t] = useTranslation();
    const { close } = useModalContext();
    const [agreeContact, setAgreeContact] = useState(false);
    const [agreePayment, setAgreePayment] = useState(false);
    const startDate = useFormField(DateTime.local());
    const price = useFormField('');
    const [showExpenses, setShowExpenses] = useState(false);
    const [formError, setFormError] = useState('');
    const constructAddress = useConstructAddress();

    const query = useApartmentAddRentalParamsQuery({ variables: { id: aptId! } });
    const [addRentalMutation, { loading }] = useAddRentalMutation();
    const toast = useToast();

    const { apartment, aptExpenses } = query.data ?? {};
    const startDateTimeISO = startDate.value?.setZone('utc').toISO({ includeOffset: false });

    const rentalExpirationAfterRenewQuery = useRentalExpirationAfterRenewQuery({
        fetchPolicy: 'network-only',
        variables: {
            curentExpiration: startDateTimeISO ?? apartment?.actualRental?.expirationDate ?? undefined
        }
    });

    if (query.loading)
        return (
            <ModalBody>
                <ModalLoadingScreen />
            </ModalBody>
        );

    const error = query.error && getErrorI18nText(t, getErrorData(query.error).message);

    if (error)
        return (
            <ModalBody>
                <ModalErrorScreen isStatic text={error} resetError={() => query.refetch()} />
            </ModalBody>
        );

    const isRenewal = !!apartment?.actualRental;
    const translateContext = isRenewal ? 'renewal' : 'new';

    const eosAccount = apartment?.actualRental?.tenantAccount || apartment?.latestAuction?.winnerAccount;

    const currency = apartment?.communalCurrency ?? '';

    const licenseEndDate = rentalExpirationAfterRenewQuery?.data?.rentalExpirationAfterRenew;

    const disabled = !price.value || !agreeContact || !agreePayment;

    const onSubmit = (e?: FormEvent<HTMLFormElement>) => {
        e?.preventDefault();

        addRentalMutation({
            variables: {
                input: {
                    aptId: aptId!,
                    price: price.value,
                    startDate: startDateTimeISO
                }
            }
        })
            .then(({ data }) => {
                if (data?.addRental?.__typename === 'AddRentalSuccess') {
                    toast.success(t('modalAddRental.successMsg'));
                    onSuccess?.();
                    close();
                } else {
                    setFormError(
                        t([
                            `modalAddRental.error.${data?.addRental?.__typename}`,
                            getErrorI18nText(t, data?.addRental?.errorMessage)
                        ])
                    );
                }
            })
            .catch((e) => {
                setFormError(getErrorI18nText(t, getErrorData(e).message));
            });
    };

    return (
        <Fragment>
            <ModalHeader>
                {t('modalAddRental.title', {
                    context: translateContext
                })}
            </ModalHeader>
            <ModalBody>
                <form onSubmit={onSubmit} className={cx('Form')}>
                    <ModalContent>
                        <ModalText>
                            {t('modalAddRental.description', {
                                context: translateContext
                            })}{' '}
                            <b>{eosAccount}</b>
                        </ModalText>
                        <ul className={cx('ApartmentStats')}>
                            <li>
                                <b>{t('apartmentStats.address.label')}</b>
                                <span>{constructAddress(apartment)}</span>
                            </li>
                            {isRenewal && (
                                <li>
                                    <b>{t('modalAddRental.lastPayment')}</b>
                                    <span>
                                        {apartment!.actualRental!.price || '-'} {currency}
                                    </span>
                                </li>
                            )}
                        </ul>

                        <div className={cx('FieldWrapper')}>
                            <DatePicker
                                containerClassName={cx('Field')}
                                label={t('modalAddRental.licenseStartDateField.label')}
                                value={startDate.value}
                                // readOnly={isRenewal}
                                onChange={(v) => startDate.change(v || DateTime.local())}
                                calendarPortal={modalRef.current}
                                minDate={apartment?.latestAuction?.endDate}
                            />
                            <ul className={cx('FieldDetails')}>
                                <li>
                                    <span>{t('modalAddRental.licenseEndDate')}</span>
                                    <span>
                                        {rentalExpirationAfterRenewQuery.loading ? (
                                            <Loader size={12} />
                                        ) : (
                                            formatDate(licenseEndDate, true)
                                        )}
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className={cx('FieldWrapper')}>
                            <TextField
                                containerClassName={cx('Field')}
                                pattern={PATTERN_EXPENSES}
                                value={price.value}
                                error={price.error}
                                rightControl={currency}
                                onChange={(e) => price.change(validator(e, price.value))}
                                label={t('modalAddRental.paymentField.label')}
                            />

                            <ul className={cx('FieldDetails')}>
                                {apartment?.totalExpenses && (
                                    <li>
                                        <span>{t('modalAddRental.totalToPay')}</span>
                                        <span role="button" onClick={() => setShowExpenses(!showExpenses)}>
                                            <span>
                                                {apartment?.totalExpenses ?? '-'} {currency}
                                            </span>

                                            <IconAngleDown className={cx('Icon', showExpenses && 'rotate')} />
                                        </span>
                                    </li>
                                )}

                                {showExpenses && (
                                    <Fragment>
                                        {apartment?.communalPayment && (
                                            <li>
                                                <span>{t('apartmentStats.communalPayment.label')}</span>
                                                <span>
                                                    {apartment?.communalPayment ?? '-'} {currency}
                                                </span>
                                            </li>
                                        )}

                                        {aptExpenses?.map((aptExpense) => {
                                            if (!aptExpense) return null;

                                            return (
                                                <li key={aptExpense.expense.id}>
                                                    <span>{aptExpense.expense.name}</span>
                                                    <span>
                                                        {aptExpense.value} {currency}
                                                    </span>
                                                </li>
                                            );
                                        })}
                                    </Fragment>
                                )}
                            </ul>
                        </div>

                        <div>
                            <CheckboxField
                                label={t('modalAddRental.agree', { context: translateContext })}
                                checked={agreeContact}
                                onChange={(e) => setAgreeContact(e.target.checked)}
                            />
                            <CheckboxField
                                label={t('modalAddRental.agree', { context: 'payment' })}
                                checked={agreePayment}
                                onChange={(e) => setAgreePayment(e.target.checked)}
                            />
                        </div>
                    </ModalContent>
                    <Button loading={loading} color="primary" disabled={disabled}>
                        {t('modalAddRental.btnSubmit', {
                            context: translateContext
                        })}
                    </Button>
                </form>
            </ModalBody>

            <ModalErrorScreen text={formError} resetError={() => setFormError('')} />
        </Fragment>
    );
};

export default ModalAddRental;
