import { createAction } from 'helpers/createAction';
import ActionTypes from './actionTypes';
import { ErrorStatus } from './reducer';

export const setErrorStatus = (payload: ErrorStatus = null) => createAction(ActionTypes.SET_ERROR_STATUS, payload);

export const setAuth = (payload: boolean) => createAction(ActionTypes.SET_AUTH, payload);

export const setRealtimeOffset = (payload: number) => createAction(ActionTypes.SET_REALTIME_OFFSET, payload);

export type Actions =
    | ReturnType<typeof setErrorStatus>
    | ReturnType<typeof setAuth>
    | ReturnType<typeof setRealtimeOffset>;
