import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { useHistory } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { URL_MAP } from 'constant';
import { useIsTouchableDevice } from 'helpers/hooks';
import {
    NotifyHistoryNodeFragment,
    NotificationType,
    useMarkNotificationAsReadMutation,
    useNotificationWsSubscription,
    useMeQuery
} from 'apollo/generted';
import { IconGavel, IconHome, IconKey } from 'components/Icons';
import { useTranslation } from 'react-i18next';
import { getErrorData, useFormatDate, useGlobalError } from 'helpers';

const cx = classNames.bind(styles);

const NotificationCard = ({ onClick, ...data }: NotifyHistoryNodeFragment & { onClick(): void }) => {
    const {
        t,
        i18n: { language }
    } = useTranslation();
    const [date, setDate] = useState('');
    const { id: meId, countNewNotifications } = useMeQuery().data?.me ?? {};
    const onGlobalError = useGlobalError();
    const freezedData = useRef<null | NotifyHistoryNodeFragment>(null);
    const { text, id, notificationType, isNew, created, ...payload } = freezedData.current ?? data;

    const formatDate = useFormatDate();
    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true
    });

    const isTouchableDevice = useIsTouchableDevice();
    const history = useHistory();

    const [markNotificationAsReadMutation, { loading: loadingMarkNotificationAsRead }] =
        useMarkNotificationAsReadMutation();

    const markAsRead = () => {
        if (!isNew || loadingMarkNotificationAsRead) return;

        const nextCounter = (countNewNotifications ?? 0) - 1;

        markNotificationAsReadMutation({
            variables: {
                input: {
                    id: id!
                }
            },
            optimisticResponse: {
                __typename: 'Mutations',
                markNotificationAsRead: {
                    __typename: 'MarkNotificationAsReadSuccess',
                    notification: {
                        id,
                        __typename: 'NotifyHistoryNode',
                        isNew: false
                    },
                    me: {
                        id: meId,
                        countNewNotifications: nextCounter < 0 ? 0 : nextCounter,
                        __typename: 'UserNode'
                    }
                }
            }
        })
            .then(({ data }) => {
                if (data?.markNotificationAsRead?.__typename !== 'MarkNotificationAsReadSuccess') {
                    onGlobalError(
                        t([
                            `markNotificationAsRead.error.${data?.markNotificationAsRead?.__typename}`,
                            data?.markNotificationAsRead?.errorMessage ?? ''
                        ])
                    );
                }
            })
            .catch((e) => {
                onGlobalError(getErrorData(e).message);
            });
    };

    const formatCreatedDate = () => {
        const NOW = +new Date();
        const MINUTE = 1000 * 60;
        const HOUR = MINUTE * 60;
        const DAY = HOUR * 24;
        const diff = NOW - +new Date(created + 'Z');
        if (diff < MINUTE) {
            setDate(t('notifications.timeUnits.now'));
        } else if (diff < HOUR) {
            setDate(
                t('notifications.timeUnits.minute', {
                    count: Math.floor(diff / MINUTE)
                })
            );
        } else if (diff < DAY) {
            setDate(
                t('notifications.timeUnits.hour', {
                    count: Math.floor(diff / HOUR)
                })
            );
        } else {
            return setDate(formatDate(created, 'DD'));
        }
    };
    // TODO: Fix exhaustive deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(formatCreatedDate, []);

    useEffect(() => {
        const tid = setTimeout(formatCreatedDate, 60000);
        return () => {
            clearTimeout(tid);
        };
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language, date, created]);

    useNotificationWsSubscription({
        skip: !isNew,
        variables: {
            id: id!
        }
    });

    const needToMarkAsRead = inView && isTouchableDevice && isNew;

    useEffect(() => {
        if (needToMarkAsRead) {
            freezedData.current = data;
            markAsRead();
        }
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToMarkAsRead]);

    const Icon = useMemo(() => {
        switch (notificationType!) {
            case NotificationType.AuctionBidCancelled:
            case NotificationType.AllRefusedToWin:
            case NotificationType.AuctionFinishedUser:
            case NotificationType.AuctionStarted:
            case NotificationType.ReviewAuctionStageStartedUser:
            case NotificationType.WinnerReElected:
            case NotificationType.AuctionBidAccepted:
                return IconGavel;

            case NotificationType.RentalEnded:
            case NotificationType.RentalIsAboutEnd:
            case NotificationType.WinnerInactive:
            case NotificationType.RentalExtended:
            case NotificationType.RentalStarted:
                return IconKey;

            case NotificationType.AuctionFinishedWinner:
            case NotificationType.IssuedTokensForApt:
            case NotificationType.ReviewAuctionStageStartedOwner:
            case NotificationType.AuctionFinishedOwner:
            case NotificationType.ModerationSuccessed:
            case NotificationType.ModerationFailed:
                return IconHome;
            default:
                return IconHome;
        }
    }, [notificationType]);

    const _onClickAction = () => {
        onClick();
        switch (notificationType) {
            case NotificationType.IssuedTokensForApt:
                window.open(`https://bloks.io/transaction/${payload.emmissionTxid}`);
                break;
            case NotificationType.ModerationFailed:
            case NotificationType.AllRefusedToWin:
                history.push(URL_MAP.editApartment(payload.aptId));
                break;
            default:
                history.push(URL_MAP.apartment(payload.aptId));
        }
    };

    return (
        <div
            ref={ref}
            onMouseEnter={isTouchableDevice ? undefined : markAsRead}
            onClick={_onClickAction}
            className={cx('Component', isNew && 'isNew')}
            role="link"
        >
            <span className={cx('Icon')}>
                <Icon />
            </span>
            <div className={cx('Details')}>
                <p className={cx('Msg')}>{text}</p>
                <p className={cx('DateTime')}>
                    <time>{date}</time>
                </p>
            </div>
        </div>
    );
};

export default NotificationCard;
