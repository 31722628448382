import React, { useState, useEffect, memo } from 'react';
import { useStore } from 'helpers/hooks';
import { useTranslation } from 'react-i18next';

export type Props = {
    // ISO datetime string
    endTime: null | string;
};

const TIMESTAMP_MINUTE = 60;
const TIMESTAMP_HOUR = 60 * TIMESTAMP_MINUTE;
const TIMESTAMP_DAY = 24 * TIMESTAMP_HOUR;

/** @todo remove 'Z' fix */
const Timer = memo(({ endTime }: Props) => {
    const [t] = useTranslation();
    const realtimeOffset = useStore((s) => s.Base.realtimeOffset);
    const [time, setTime] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    });
    let intervalId: NodeJS.Timeout | undefined;
    let endpoint: null | number = null;

    const stopInterval = () => {
        clearInterval(intervalId as number | undefined);
    };

    const stop = () => {
        stopInterval();
        setTime({
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        });
    };

    const updateTimer = () => {
        if (!endTime) return stop();

        if (endpoint === null) endpoint = +new Date(endTime + 'Z');

        const now = +new Date() + realtimeOffset;

        let delta = ((endpoint as number) - now) / 1000;

        if (delta <= 1) return stop();

        const days = parseInt(delta / TIMESTAMP_DAY + '');
        delta -= days * TIMESTAMP_DAY;

        const hours = parseInt(delta / TIMESTAMP_HOUR + '');
        delta -= hours * TIMESTAMP_HOUR;

        const minutes = parseInt(delta / TIMESTAMP_MINUTE + '');
        delta -= minutes * TIMESTAMP_MINUTE;

        const seconds = parseInt(delta + '');

        setTime({ days, hours, minutes, seconds });
    };

    useEffect(() => {
        if (endTime) {
            updateTimer();
            // TODO: Fix exhaustive deps
            // eslint-disable-next-line react-hooks/exhaustive-deps
            intervalId = setInterval(updateTimer, 500);
        }
        return stopInterval;
    }, [endTime]);

    const { days, hours, minutes, seconds } = time;

    const text = t('global.timer', {
        days,
        hours: hours.toString().padStart(2, '0'),
        minutes: minutes.toString().padStart(2, '0'),
        seconds: seconds.toString().padStart(2, '0')
    });

    return <span style={{ minWidth: `${text.length}ch`, display: 'inline-block' }}>{text}</span>;
});

export default Timer;
