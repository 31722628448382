import React, { memo, useCallback } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { ErrorStatus } from 'store/Base/reducer';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setErrorStatus } from 'store/Base/actions';
import Button from 'components/UIKit/Button';
import { URL_MAP } from 'constant';
import { useHistory } from 'react-router-dom';
import { useStore } from 'helpers/hooks';

const cx = classNames.bind(styles);

const PageError = memo((props: { code?: ErrorStatus }) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const storeErrorCode = useStore((s) => s.Base.error);
    const errorCode = props.code ?? storeErrorCode ?? 404;

    const history = useHistory();
    const resetError = useCallback(() => dispatch(setErrorStatus()), [dispatch]);

    const onClick = () => {
        resetError();
        if (errorCode === 404) history.push(URL_MAP.search);
    };

    return (
        <main className={cx('Component', 'container')}>
            <div className={cx('Main')}>
                <h1>{t('pageError.title', { errorCode })}</h1>
                <p className={cx('BigText')}>{t('pageError.text')}</p>
                <p className={cx('StatusDescription')}>
                    {t(`pageError.description`, { context: errorCode.toString() })}
                </p>
                <Button size="big" color="secondary" data-testid="btn" onClick={onClick}>
                    {t(`pageError.btn`, { context: errorCode.toString() })}
                </Button>
            </div>

            <div className={cx('ImgContainer')}>
                <div className={cx('ImgBase')}>
                    <img src={require('assets/images/error/errorBg.svg').default} alt="" />
                </div>
                <div className={cx('ImgStatus')}>
                    <img src={require('assets/images/error/error.svg').default} alt="" />
                    <span>{errorCode}</span>
                </div>
            </div>
        </main>
    );
});

export default PageError;
