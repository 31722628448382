import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { IconMoney, IconPen, IconPlus, IconTrash } from 'components/Icons';
import ModalRegionAdditionalPayments, {
    Props as ModalRegionAdditionalPaymentsProps
} from 'containers/Modals/RegionAdditionalPayments';
import { getErrorData, useGlobalError, useModal } from 'helpers';
import {
    CityNode,
    ManagerExpenseFragment,
    ManagerRegionSettingsQuery,
    useDeleteExpenseMutation
} from 'apollo/generted';
import { useToast, useWidthCondition } from 'helpers/hooks';
import { BREAKPOINT_LANDSCAPE } from 'constant';
import Button from 'components/UIKit/Button';
const cx = classNames.bind(styles);

type Props = Pick<ManagerRegionSettingsQuery, 'regionExpenses'> & {
    city: Pick<CityNode, 'id' | 'locales'>;
    onRemove(id: number): void;
    onAdd(item: ManagerExpenseFragment): void;
};

const AdditionalPayments = ({ regionExpenses, city, onRemove, onAdd }: Props) => {
    const [t] = useTranslation();
    const modalRegioinAdditionalPayment = useModal<Pick<ModalRegionAdditionalPaymentsProps, 'expense'>>();

    return (
        <div className={cx('Block')}>
            <div className={cx('BlockHeader')}>
                <h3 className={cx('BlockTitle')}>{t('pageManage.regionSettings.additionalPayments.title')}</h3>

                <Button
                    size="small"
                    round
                    iconLeft={<IconPlus />}
                    onClick={() => modalRegioinAdditionalPayment.open()}
                    color="secondary-grey"
                >
                    {t('pageManage.regionSettings.additionalPayments.btnAdd')}
                </Button>
            </div>

            <p className={cx('BlockDescription')}>{t('pageManage.regionSettings.additionalPayments.description')}</p>

            {(regionExpenses?.length ?? 0) > 0 && (
                <ul className={cx('ExpenseList')}>
                    {regionExpenses?.map(
                        (expense) =>
                            expense && (
                                <ExpenseItem
                                    onRemove={() => onRemove(expense.id)}
                                    onClickEdit={() => modalRegioinAdditionalPayment.open({ expense })}
                                    expense={expense}
                                    key={expense.id}
                                />
                            )
                    )}
                </ul>
            )}

            <ModalRegionAdditionalPayments
                {...modalRegioinAdditionalPayment}
                {...modalRegioinAdditionalPayment.payload}
                onSuccess={onAdd}
                city={city}
            />
        </div>
    );
};

type ExpenseItemProps = {
    onRemove(): void;
    expense: ManagerExpenseFragment;
    onClickEdit(): void;
};

const ExpenseItem = ({ expense, onRemove, onClickEdit }: ExpenseItemProps) => {
    const isLanscapeOrLess = useWidthCondition((w) => w < BREAKPOINT_LANDSCAPE);
    const [t] = useTranslation();
    const [deleteExpenseMutation, { loading: loadingDeleteExpense }] = useDeleteExpenseMutation();
    const onGlobalError = useGlobalError();
    const toast = useToast();

    const deleteExpense = () => {
        deleteExpenseMutation({ variables: { input: { expenseId: expense.id } } })
            .then(({ data }) => {
                if (data?.deleteExpense?.__typename === 'DeleteExpenseSuccess') {
                    toast.success(t('pageManage.regionSettings.additionalPayments.deleteExpense.successMsg'));

                    onRemove();
                } else {
                    onGlobalError(
                        t([
                            `pageManage.regionSettings.additionalPayments.deleteExpense.error.${data?.deleteExpense?.__typename}`,
                            data?.deleteExpense?.errorMessage ?? ''
                        ])
                    );
                }
            })
            .catch((e) => {
                onGlobalError(getErrorData(e).message);
            });
    };

    const Icon = (
        <span className={cx('ExpenseIcon')}>
            <IconMoney />
        </span>
    );

    return (
        <li className={cx('Expense')} key={expense.id}>
            {isLanscapeOrLess && Icon}

            <div className={cx('ExpenseMain')}>
                {!isLanscapeOrLess && Icon}
                <ul className={cx('ExpenseNameList')}>
                    {expense.locales?.map(
                        (locale) =>
                            locale && (
                                <li key={locale.lang} className={cx('ExpenseName')}>
                                    <span>{locale.lang}</span>
                                    <b>{locale.name}</b>
                                </li>
                            )
                    )}
                </ul>
            </div>

            <div className={cx('ExpenseActions')}>
                <button type="button" disabled={loadingDeleteExpense} onClick={onClickEdit}>
                    <IconPen />
                </button>
                <button type="button" disabled={loadingDeleteExpense} onClick={deleteExpense}>
                    <IconTrash />
                </button>
            </div>
        </li>
    );
};

export default AdditionalPayments;
