import { ApartmentNode, AptStatus, useAuctionParticipantsQuery } from 'apollo/generted';
import classNames from 'classnames/bind';
import { ParamContestParticipants, ParamContestTimer, ParamWinner } from 'components/Apartment/StatusParams';
import Param from 'components/Apartment/StatusParams/Param';
import { IconTimer, IconUsers } from 'components/Icons';
import Modal, { ModalBody, ModalHeader, ModalProps } from 'components/UIKit/Modal';
import ModalErrorScreen from 'components/UIKit/Modal/ErrorScreen';
import ModalLoadingScreen from 'components/UIKit/Modal/LoadingScreen';
import { getErrorData, getErrorI18nText } from 'helpers';
import formatDate from 'helpers/formatDate';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

export type ModalAuctionParticipantsProps = Pick<ApartmentNode, 'id'>;

const ModalAuctionParticipants = ({ isOpen, close, ...props }: ModalProps & ModalAuctionParticipantsProps) => {
    const [t] = useTranslation();
    return (
        <Modal isOpen={isOpen} close={close}>
            <ModalHeader>{t('modalAuctionParticipants.title')}</ModalHeader>
            <Main {...props} />
        </Modal>
    );
};

const Main = ({ id }: ModalAuctionParticipantsProps) => {
    const [t] = useTranslation();

    const { loading, error, data, refetch } = useAuctionParticipantsQuery({
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        variables: {
            id
        }
    });
    const errorText = error && getErrorI18nText(t, getErrorData(error).message);

    const { latestAuction, status } = data?.apartment ?? {};
    const hasData = !!latestAuction;
    const {
        isWinnerSelectionInProgress,
        winnerAccount: winner,
        isIAmWinner,
        transactions = [],
        endOfActivePhase,
        startDate,
        bidsNumber
    } = latestAuction ?? {};
    const winnerRank = latestAuction?.winnerRank ?? -1;

    const hasToBeLoaded = useRef(false);
    const lastHasUnconfirmed = useRef(isWinnerSelectionInProgress ?? null);
    const lastWinner = useRef(winner ?? null);

    /**
     * Refetch data ONLY after full load query and change of any
     * primary value from outside (for example from WS message on apartment card)
     */
    useEffect(() => {
        if (hasData) {
            if (hasToBeLoaded.current) {
                if ((lastHasUnconfirmed.current && !isWinnerSelectionInProgress) || lastWinner.current !== winner) {
                    refetch();
                    hasToBeLoaded.current = false;
                }
            } else {
                hasToBeLoaded.current = true;
            }

            lastHasUnconfirmed.current = isWinnerSelectionInProgress ?? null;
            lastWinner.current = winner ?? null;
        }
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWinnerSelectionInProgress, winner, hasData]);

    if (loading)
        return (
            <ModalBody>
                <ModalLoadingScreen />
            </ModalBody>
        );

    if (errorText)
        return (
            <ModalBody>
                <ModalErrorScreen text={errorText} isStatic resetError={() => refetch()} />
            </ModalBody>
        );

    const isContest = status === AptStatus.Contest;
    const notIsContest = !isContest;
    const winnerHasBeenSelected = notIsContest && !isWinnerSelectionInProgress;

    const showContestTimer = isContest;
    const showContestEndDate =
        status === AptStatus.Locked ||
        status === AptStatus.ContestFinished ||
        status === AptStatus.Rented ||
        status === AptStatus.Eviction;

    const showContestWinner = showContestEndDate;

    return (
        <ModalBody className={cx('ModalBody')}>
            <div className={cx('ParamContainer')}>
                {showContestTimer && <ParamContestTimer startDate={startDate} endOfActivePhase={endOfActivePhase} />}

                {showContestEndDate && (
                    <Param
                        icon={<IconTimer />}
                        label={t('apartmentStats.endOfActivePhase.label')}
                        value={formatDate(endOfActivePhase, false, true)}
                    />
                )}

                <ParamContestParticipants bidsNumber={bidsNumber} />

                {showContestWinner && (
                    <ParamWinner
                        winnerAccount={winner}
                        isIAmWinner={isIAmWinner!}
                        isWinnerSelectionInProgress={isWinnerSelectionInProgress}
                    />
                )}
            </div>
            <div className={cx('ListWrapper')}>
                {transactions?.length ? (
                    <ul className={cx('List')}>
                        {transactions?.map((participant, i) => {
                            if (!participant) return null;
                            const isWinner = participant.isWinner;
                            const isRefused =
                                winnerHasBeenSelected &&
                                (i < winnerRank || (i === winnerRank && !participant.isWinner));
                            return (
                                <li key={participant.id}>
                                    <b className={cx('ListItemNumber', isWinner && 'highlight')}>{i + 1}</b>
                                    <span className={cx('ListItemName', isRefused && 'refused')}>
                                        {participant.sender}
                                    </span>
                                    {isWinner ? (
                                        <span>{t('apartmentStats.winner.label')}</span>
                                    ) : !isWinnerSelectionInProgress && isRefused ? (
                                        <span>{t('apartmentStats.winner.refused')}</span>
                                    ) : null}
                                </li>
                            );
                        })}
                    </ul>
                ) : (
                    <div className={cx('EmptyScreen')}>
                        <IconUsers />
                        <p>{t('modalAuctionParticipants.empty')}</p>
                    </div>
                )}
            </div>
        </ModalBody>
    );
};

export default ModalAuctionParticipants;
