import ActionTypes from './actionTypes';
import { Actions } from './actions';

export const initialState = {
    isOpen: false,
    closable: true
};

const reducer = (state = initialState, action: Actions) => {
    switch (action.type) {
        case ActionTypes.CLOSE:
            return { ...initialState, isOpen: false };
        case ActionTypes.OPEN:
            return { ...state, ...action.payload, isOpen: true };
        case ActionTypes.RESET:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
