import useMeQuery from 'helpers/useMeQuery';
import classNames from 'classnames/bind';
import { IconLock } from 'components/Icons';
import { useModal } from 'helpers';
import ModalChangePassword from 'containers/Modals/ChangePassword';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Info from '../components/Info';
import Setting from '../components/Setting';
import styles from './index.module.scss';
import { URL_MAP } from 'constant';
import { Section, SectionHeader, SectionTitle, SectionMain } from 'containers/Pages/Manage/components/Section';

const cx = classNames.bind(styles);

export type ActionType = 'change' | 'add' | 'delete';

const SecureSettings = () => {
    const [t] = useTranslation();
    const modalChangePassword = useModal(false);
    const me = useMeQuery()?.data?.me;
    const hasPassword = me?.hasPassword;

    return (
        <Section className={cx('Component')}>
            <SectionHeader>
                <SectionTitle to={URL_MAP.profile.index}>{t('pageProfile.secure.title')}</SectionTitle>
            </SectionHeader>

            <SectionMain>
                <Setting
                    icon={<IconLock />}
                    label={t('pageProfile.secure.passwordSettings.label')}
                    labelBtnAdd={t('pageProfile.secure.passwordSettings.btn', {
                        context: 'add'
                    })}
                    labelBtnEdit={t('pageProfile.secure.passwordSettings.btn', {
                        context: 'edit'
                    })}
                    value={hasPassword ? t('pageProfile.secure.passwordSettings.has') : null}
                    hideRemoveBtn
                    onClickEdit={modalChangePassword.open}
                    onClickAdd={modalChangePassword.open}
                />
            </SectionMain>

            <Info title={t('pageProfile.secure.info.title')}>
                <p>{t('pageProfile.secure.info.description')}</p>
            </Info>

            <ModalChangePassword {...modalChangePassword} />
        </Section>
    );
};

export default SecureSettings;
