import classNames from 'classnames/bind';
import { IconAccount, IconAngleRight } from 'components/Icons';
import Button from 'components/UIKit/Button';
import Counter from 'components/UIKit/Counter';
import { BREAKPOINT_PHONE, URL_MAP } from 'constant';
import { NAV as MANAGE_NAV } from 'containers/Pages/Manage';
import { NAV as PROFILE_NAV } from 'containers/Pages/Profile';
import { useDropdown, useStore, useWidthCondition } from 'helpers/hooks';
import { signOut } from 'helpers/sign';
import useMeQuery from 'helpers/useMeQuery';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { open } from 'store/SignModal/actions';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

const UserLink = ({ label = '', count = 0, to = '', exact = false, onClick = () => {} }) => {
    const [t] = useTranslation();
    return (
        <NavLink
            key={to}
            onClick={onClick}
            activeClassName={cx('active')}
            className={cx('NavLink')}
            to={to}
            exact={exact}
        >
            <span tabIndex={-1}>
                <span className={cx('NavLinkLabel')}>{t(label)}</span>
                {!!count && <Counter>{count}</Counter>}
                <IconAngleRight className={cx('NavLinkArrow')} />
            </span>
        </NavLink>
    );
};

const UserDropdown = () => {
    const dispatch = useDispatch();
    const isFixedPosition = useWidthCondition((w) => w < BREAKPOINT_PHONE);
    const [t] = useTranslation();
    const { isAuth } = useStore((s) => s.Base);
    const dropdown = useDropdown({});
    const openSignModal = useCallback(() => dispatch(open()), [dispatch]);
    const { eosAccount, phone, email, isNotary, rdUpdates } = useMeQuery()?.data?.me ?? {};
    const login = eosAccount || phone || email;
    const totalRdUpdates = useMemo(() => {
        if (!rdUpdates) return 0;

        return MANAGE_NAV.reduce(
            (total, { getNotificationCounter }) => total + (getNotificationCounter?.(rdUpdates) ?? 0),
            0
        );
    }, [rdUpdates]);

    if (!isAuth)
        return (
            <Button
                size="small"
                color="tertiary-grey"
                iconRight={<IconAccount />}
                type="button"
                onClick={openSignModal}
            />
        );

    return (
        <div ref={dropdown.setRef}>
            <Button
                size="small"
                color="tertiary-grey"
                iconRight={<IconAccount />}
                type="button"
                isActive={dropdown.open}
                className={cx('Control', totalRdUpdates && !dropdown.open && 'hasNew')}
                onClick={dropdown.toggle}
            />

            <div
                ref={dropdown.setPopperRef}
                style={isFixedPosition ? undefined : dropdown.popper.styles.popper}
                {...dropdown.popper.attributes.popper}
                className={cx('Content', dropdown.open && 'open')}
            >
                <div className={cx('ContentMain')}>
                    <div className={cx('Box')}>
                        <p className={cx('Account')}>
                            <span className={cx('AccountIcon')}>
                                <IconAccount />
                            </span>
                            <span>
                                <span className={cx('AccountLabel')}>{t('header.userDropdown.account')}</span>
                                <b className={cx('AccountValue')}>{login}</b>
                            </span>
                        </p>
                    </div>

                    <nav className={cx('Nav')}>
                        {!isNotary && (
                            <>
                                {PROFILE_NAV.map(({ url, label, icon: Icon, exact }) => {
                                    if (url === URL_MAP.profile.myFavorites) return null;

                                    return (
                                        <UserLink
                                            label={label}
                                            key={url}
                                            to={url}
                                            exact={exact}
                                            onClick={dropdown.toggle}
                                        />
                                    );
                                })}
                            </>
                        )}

                        {isNotary && (
                            <>
                                <UserLink
                                    label="pageProfile.title"
                                    to={URL_MAP.profile.index}
                                    onClick={dropdown.toggle}
                                />
                                <UserLink
                                    label="pageManage.title"
                                    to={URL_MAP.manage.index}
                                    onClick={dropdown.toggle}
                                    count={totalRdUpdates}
                                />
                            </>
                        )}
                    </nav>

                    <div className={cx('Box')}>
                        <Button
                            onClick={() => {
                                dropdown.toggle();
                                signOut();
                            }}
                            color="secondary-grey"
                        >
                            {t('header.userDropdown.logout')}
                        </Button>
                    </div>
                </div>

                <div className={cx('ContentOverlay')} onClick={dropdown.toggle} />
            </div>
        </div>
    );
};

export default UserDropdown;
