import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { IconError, IconRepeat } from 'components/Icons';
import Button from 'components/UIKit/Button';
import { useTranslation } from 'react-i18next';
import { ModalHeader } from '..';
const cx = classNames.bind(styles);

const ModalErrorScreen = ({
    resetError,
    text,
    className,
    isStatic
}: {
    resetError(): void;
    text: string;
    /**
     * Set `true`, when renders inside body
     */
    isStatic?: boolean;
    className?: string;
}) => {
    const [t] = useTranslation();

    if (!text) return null;

    return (
        <div className={cx('Component', className, { isStatic })}>
            {!isStatic && <ModalHeader />}
            <div className={cx('Content')}>
                <IconError />
                <span>{text}</span>
                <Button iconLeft={<IconRepeat />} color="tertiary-grey" size="small" round onClick={resetError}>
                    {t('global.tryAgain')}
                </Button>
            </div>
        </div>
    );
};

export default ModalErrorScreen;
