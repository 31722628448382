import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

export const COLORS = [
    'green',
    'grue',
    'orange',
    'orange-light',
    'yellow',
    'yellow-light',
    'blue',
    'blue-light',
    'red',
    'red-light',
    'grey-light',
    'grey-very-light',
    'white'
] as const;

type Props = JSX.IntrinsicElements['p'] & {
    color: typeof COLORS[number];
    tag?: 'p' | 'span';
};

const Status = ({ color, tag: Tag = 'p', className, ...props }: Props) => {
    return <Tag className={cx('Component', color, className)} {...props} />;
};

export default Status;
