import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import Button from 'components/UIKit/Button';
import { useTranslation } from 'react-i18next';
import { IconRefresh } from 'components/Icons';
const cx = classNames.bind(styles);

/** Support all browsers, that supports `grid` and Safari 11 and more */
const BrowserValidator = ({ children }: PropsWithChildren<{}>) => {
    const [t] = useTranslation();
    const [showContent, setShowContent] = useState(false);
    const isBrowserSupport = useMemo(() => {
        const isUnsupportedSafari = function () {
            const userAgent = window.navigator.userAgent.toLowerCase() || '';
            const key = 'version/';
            return /safari/.test(userAgent) && parseInt(userAgent.substr(userAgent.indexOf(key) + key.length, 3)) < 11;
        };

        return window.CSS && window.CSS.supports('display', 'grid') && !isUnsupportedSafari();
    }, []);

    const canRender = showContent || isBrowserSupport;

    useEffect(() => {
        const root = document.getElementById('root');
        if (canRender) {
            root?.classList.remove(cx('Root'));
        } else {
            root?.classList.add(cx('Root'));
        }
    }, [canRender]);

    if (canRender) return children as JSX.Element;

    return (
        <div className={cx('Component')}>
            <IconRefresh />
            <h1>{t('pageUnsupported.title')}</h1>
            <p>{t('pageUnsupported.text')}</p>
            <Button color="primary" onClick={() => setShowContent(true)}>
                {t('pageUnsupported.btn')}
            </Button>
        </div>
    );
};

export default BrowserValidator;
