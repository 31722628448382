import { ApartmentQuery, PublicApartmentListNodeFragment } from 'apollo/generted';
import classNames from 'classnames/bind';
import BtnFavorite from 'components/Apartment/UserActions/BtnFavorite';
import { IconClose } from 'components/Icons';
import { useOverflowController } from 'helpers';
import getRegionName from 'helpers/getRegionName';
import { useConstructAddress } from 'helpers/useParsedApartmentData';
import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import Slider from './Slider';
import Thumbs from './Thumbs';
const cx = classNames.bind(styles);

export type PhotosType = NonNullable<NonNullable<ApartmentQuery['apartment']>['photos']>;

const Gallery = ({
    close,
    apartment,
    initialSlide,
    showFavoriteControl = true
}: {
    initialSlide: number;
    showFavoriteControl?: boolean;
    close(): void;
    apartment: Pick<PublicApartmentListNodeFragment, 'id' | 'photos' | 'place' | 'isFavorite' | 'city'>;
}) => {
    const overflowController = useOverflowController();
    const ref = useRef<HTMLDivElement>(null);
    const [activeSlide, setActiveSlide] = useState(initialSlide);
    const constructAddress = useConstructAddress();

    const hide = () => {
        close();
        overflowController.unblockScroll();
    };

    useEffect(() => {
        overflowController.blockScroll(ref.current!);
        const onPressEsc = (e: KeyboardEvent) => {
            if (e.key === 'Escape') hide();
        };

        window.addEventListener('keydown', onPressEsc);

        return () => {
            window.removeEventListener('keydown', onPressEsc);
        };
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <aside ref={ref} className={cx('Component')}>
            <header className={cx('Header')}>
                <button type="button" onClick={hide} className={cx('BtnClose')}>
                    <IconClose />
                </button>

                <h3 className={cx('Title')}>
                    <address>
                        <small>{getRegionName(apartment.city)}</small>
                        {constructAddress(apartment, { hideCityName: true })}
                    </address>
                </h3>

                {showFavoriteControl && (
                    <BtnFavorite id={apartment.id} isFavorite={apartment.isFavorite} className={cx('BtnFavorite')} />
                )}
            </header>
            <main className={cx('Main')}>
                <Slider
                    sliderClassName={cx('Slider')}
                    slideClassName={cx('SliderSlide')}
                    photos={apartment.photos ?? []}
                    activeSlide={activeSlide}
                    setActiveSlide={setActiveSlide}
                />
            </main>
            <footer className={cx('Footer')}>
                <Thumbs
                    className={cx('SliderThumbs')}
                    photos={apartment.photos ?? []}
                    activeSlide={activeSlide}
                    setActiveSlide={setActiveSlide}
                />
            </footer>
        </aside>
    );
};

export default Gallery;
