import ButtonLink from 'components/UIKit/ButtonLink';
import { ModalHeader, ModalBody, ModalFooter } from 'components/UIKit/Modal';
import TabSwitcher from 'components/UIKit/TabSwitcher';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OnAuth } from '..';
import FormForgotPassword from './FormForgotPassword';
import FormLogin from './FormLogin';
import FormSignup from './FormSignup';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { isMobilePlatform } from 'helpers';
const cx = classNames.bind(styles);

type Props = {
    onClickBtnAlternate(): void;
    onAuth: OnAuth;
    onError(v: string): void;
};
export type LocalUIState = {
    onClickBack?: undefined | (() => void);
    hideTransitionsControls?: boolean;
};

type Tab = 'login' | 'signup' | 'forgotPassword';

const LoginAuthScreen = ({ onClickBtnAlternate, onAuth, onError }: Props) => {
    const [t] = useTranslation();
    const [tab, setTab] = useState<Tab>('login');
    const [localUIState, setLocalUIState] = useState<LocalUIState>({});
    const isMobile = useMemo(isMobilePlatform, []);

    useEffect(() => setLocalUIState({}), []);

    if (tab === 'forgotPassword')
        return <FormForgotPassword onClickBack={() => setTab('login')} onAuth={onAuth} onError={onError} />;

    return (
        <Fragment>
            <ModalHeader onClickBack={localUIState.onClickBack || (isMobile ? onClickBtnAlternate : undefined)}>
                {tab === 'signup' ? t('modalSign.formSignup.title') : t('modalSign.formLogin.title')}
            </ModalHeader>

            {!localUIState.hideTransitionsControls && (
                <TabSwitcher<Tab>
                    className={cx('Switcher')}
                    onChange={setTab}
                    activeType={tab}
                    options={[
                        {
                            type: 'login',
                            children: t('modalSign.formLogin.title')
                        },
                        {
                            type: 'signup',
                            children: t('modalSign.formSignup.title')
                        }
                    ]}
                />
            )}
            <ModalBody>
                {tab === 'login' && (
                    <FormLogin onClickForgotBtn={() => setTab('forgotPassword')} onAuth={onAuth} onError={onError} />
                )}
                {tab === 'signup' && <FormSignup onAuth={onAuth} onError={onError} setLocalUIState={setLocalUIState} />}
            </ModalBody>

            {!isMobile && !localUIState.hideTransitionsControls && (
                <ModalFooter className={cx('ModalFooter')}>
                    <ButtonLink fontSize="big" fontWeight="bold" onClick={onClickBtnAlternate} type="button">
                        {t('modalSign.loginAuthScreen.btnAlternate')}
                    </ButtonLink>
                </ModalFooter>
            )}
        </Fragment>
    );
};

export default LoginAuthScreen;
