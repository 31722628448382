import { LoginType } from 'apollo/generted';
import useMeQuery from 'helpers/useMeQuery';
import classNames from 'classnames/bind';
import { IconEos, IconMail, IconMobile } from 'components/Icons';
import { useModal } from 'helpers';
import ModalChangeEosAccount from 'containers/Modals/ChangeEosAccount';
import ModalEditLogin, { Props as ModalEditLoginProps } from 'containers/Modals/EditLogin';
import ModalRemoveLogin, { Props as ModalRemoveLoginProps } from 'containers/Modals/RemoveLogin';
import ModalRemoveProfile from 'containers/Modals/RemoveProfile';
import Button from 'components/UIKit/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Alert from 'components/UIKit/Alert';
import Info from '../components/Info';
import Setting from '../components/Setting';
import styles from './index.module.scss';
import { URL_MAP } from 'constant';
import { Section, SectionHeader, SectionTitle, SectionMain } from 'containers/Pages/Manage/components/Section';

const cx = classNames.bind(styles);

export type ActionType = 'change' | 'add' | 'delete';

const ProfileSettings = () => {
    const [t] = useTranslation();
    const modalEditLogin = useModal<ModalEditLoginProps>(false);
    const modalRemoveLogin = useModal<ModalRemoveLoginProps>(false);
    const modalRemoveProfile = useModal(false);
    const modalChangeEosAccount = useModal(false);
    const me = useMeQuery()?.data?.me;
    const phone = me?.phone ?? '';
    const eosAccount = me?.eosAccount ?? '';
    const email = me?.email ?? '';

    const hasEmail = !!email;
    const hasPhone = !!phone;
    const hasLogin = hasEmail || hasPhone;

    return (
        <Section className={cx('Component')}>
            <SectionHeader>
                <SectionTitle to={URL_MAP.profile.index}>{t('pageProfile.profile.title')}</SectionTitle>
            </SectionHeader>

            <SectionMain>
                <div className={cx('LoginSettingGroup')}>
                    <Setting
                        label={t('pageProfile.profile.profileSettings.eosAccount')}
                        icon={<IconEos />}
                        value={eosAccount}
                        hideRemoveBtn={!hasLogin}
                        onClickEdit={modalChangeEosAccount.open}
                        onClickRemove={() =>
                            modalRemoveLogin.open({
                                type: LoginType.EosAccount
                            })
                        }
                        onClickAdd={modalChangeEosAccount.open}
                    />

                    <Setting
                        icon={<IconMobile />}
                        label={t('pageProfile.profile.profileSettings.phone')}
                        value={phone}
                        hideRemoveBtn={!hasEmail && !eosAccount}
                        onClickEdit={() =>
                            modalEditLogin.open({
                                type: LoginType.Phone
                            })
                        }
                        onClickRemove={() =>
                            modalRemoveLogin.open({
                                type: LoginType.Phone
                            })
                        }
                        onClickAdd={() =>
                            modalEditLogin.open({
                                type: LoginType.Phone
                            })
                        }
                    />
                    <Setting
                        label={t('pageProfile.profile.profileSettings.email')}
                        icon={<IconMail />}
                        value={email}
                        hideRemoveBtn={!hasPhone && !eosAccount}
                        onClickEdit={() =>
                            modalEditLogin.open({
                                type: LoginType.Email
                            })
                        }
                        onClickRemove={() =>
                            modalRemoveLogin.open({
                                type: LoginType.Email
                            })
                        }
                        onClickAdd={() =>
                            modalEditLogin.open({
                                type: LoginType.Email
                            })
                        }
                    />
                </div>
                <div>
                    <Button className={cx('BtnRemoveAccount')} onClick={modalRemoveProfile.open} color="tertiary">
                        {t('pageProfile.profile.btnRemoveAccount')}
                    </Button>
                </div>
            </SectionMain>

            <Info title={t('pageProfile.profile.info.title')} className={cx('Info')}>
                <p>{t('pageProfile.profile.info.description')}</p>

                <Alert className={cx('InfoAlert')}>{t('pageProfile.profile.info.alert')}</Alert>
            </Info>
            <ModalEditLogin {...modalEditLogin} {...modalEditLogin.payload!} />
            <ModalRemoveLogin {...modalRemoveLogin} {...modalRemoveLogin.payload!} />
            <ModalChangeEosAccount {...modalChangeEosAccount} />
            <ModalRemoveProfile {...modalRemoveProfile} />
        </Section>
    );
};

export default ProfileSettings;
