import classNames from 'classnames/bind';
import { IconSort } from 'components/Icons';
import Button from 'components/UIKit/Button';
import { BREAKPOINT_PHONE } from 'constant';
import { useDropdown, useWidthCondition } from 'helpers/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

type Props = {
    className?: string;
    controlClassName?: string;
    options: { label: string; isActive: boolean; onClick(): void }[];
};

const SectionSort = ({ options, className, controlClassName = '' }: Props) => {
    const [t] = useTranslation();
    const isPhone = useWidthCondition((w) => w < BREAKPOINT_PHONE);
    const dropdown = useDropdown({ popperOptions: { placement: 'bottom-end' } });

    return (
        <div ref={dropdown.setRef} className={cx('Component', className)}>
            {isPhone ? (
                <Button
                    onClick={dropdown.toggle}
                    isActive={dropdown.open}
                    className={cx('LandscapeControl', controlClassName)}
                    color="secondary-grey"
                    iconLeft={<IconSort />}
                >
                    {t('pageManage.sort')}
                </Button>
            ) : (
                <button
                    onClick={dropdown.toggle}
                    className={cx('Control', controlClassName, dropdown.open && 'active')}
                >
                    <IconSort />
                    <span>{t('pageManage.sort')}</span>
                </button>
            )}

            <ul
                ref={dropdown.setPopperRef}
                style={dropdown.popper.styles.popper}
                {...dropdown.popper.attributes.popper}
                className={cx('Content', dropdown.open && 'open')}
            >
                {options.map(({ label, isActive, onClick }) => {
                    return (
                        <li key={label}>
                            <button
                                className={cx({ isActive })}
                                onClick={() => {
                                    onClick();
                                    dropdown.toggle();
                                }}
                                type="button"
                            >
                                {label}
                            </button>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default SectionSort;
