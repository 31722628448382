import {
    CityNode,
    ManagerDocumentFragment,
    ManagerRegionSettingsQuery,
    useDeleteRegionDocumentMutation
} from 'apollo/generted';
import classNames from 'classnames/bind';
import { IconDocument, IconPen, IconPlus, IconTrash } from 'components/Icons';
import Button from 'components/UIKit/Button';
import { BREAKPOINT_LANDSCAPE } from 'constant';
import ModalRegionDocuments, { Props as ModalRegionDocumentsProps } from 'containers/Modals/RegionDocuments';
import { getErrorData, useGlobalError, useModal } from 'helpers';
import { useToast, useWidthCondition } from 'helpers/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

type Props = Pick<ManagerRegionSettingsQuery, 'regionDocuments'> & {
    city: Pick<CityNode, 'id' | 'locales'>;
    onRemove(id: number): void;
    onAdd(item: ManagerDocumentFragment): void;
};

const Documents = ({ regionDocuments, city, onRemove, onAdd }: Props) => {
    const [t] = useTranslation();
    const modal = useModal<Pick<ModalRegionDocumentsProps, 'document'>>();

    return (
        <div className={cx('Block')}>
            <div className={cx('BlockHeader')}>
                <h3 className={cx('BlockTitle')}>{t('pageManage.regionSettings.documents.title')}</h3>

                <Button iconLeft={<IconPlus />} onClick={() => modal.open()} color="secondary-grey" round size="small">
                    {t('pageManage.regionSettings.documents.btnAdd')}
                </Button>
            </div>

            <p className={cx('BlockDescription')}>{t('pageManage.regionSettings.documents.description')}</p>

            {(regionDocuments?.length ?? 0) > 0 && (
                <ul className={cx('DocumentList')}>
                    {regionDocuments?.map(
                        (document) =>
                            document && (
                                <DocumentItem
                                    onRemove={() => onRemove(document.id)}
                                    onClickEdit={() => modal.open({ document })}
                                    document={document}
                                    key={document.id}
                                />
                            )
                    )}
                </ul>
            )}

            <ModalRegionDocuments {...modal} {...modal.payload} onSuccess={onAdd} city={city} />
        </div>
    );
};

type DocumentItemProps = {
    onRemove(): void;
    document: ManagerDocumentFragment;
    onClickEdit(): void;
};

const DocumentItem = ({ document, onRemove, onClickEdit }: DocumentItemProps) => {
    const isLanscapeOrLess = useWidthCondition((w) => w < BREAKPOINT_LANDSCAPE);
    const [t] = useTranslation();
    const [deleteRegionDocumentMutation, { loading: loadingDeleteDocument }] = useDeleteRegionDocumentMutation();
    const onGlobalError = useGlobalError();
    const toast = useToast();

    const deleteExpense = () => {
        deleteRegionDocumentMutation({
            variables: { input: { regionDocumentId: document.id } }
        })
            .then(({ data }) => {
                if (data?.deleteRegionDocument?.__typename === 'DeleteRegionDocumentSuccess') {
                    toast.success(t('pageManage.regionSettings.documents.deleteRegionDocument.successMsg'));
                    onRemove();
                } else {
                    onGlobalError(
                        t([
                            `pageManage.regionSettings.documents.deleteRegionDocument.error.${data?.deleteRegionDocument?.__typename}`,
                            data?.deleteRegionDocument?.errorMessage ?? ''
                        ])
                    );
                }
            })
            .catch((e) => {
                onGlobalError(getErrorData(e).message);
            });
    };

    const Icon = (
        <span className={cx('DocumentIcon')}>
            <IconDocument />
        </span>
    );

    return (
        <li className={cx('Document')} key={document.id}>
            {isLanscapeOrLess && Icon}

            <div className={cx('DocumentMain')}>
                {!isLanscapeOrLess && Icon}
                <ul className={cx('DocumentNameList')}>
                    {document.locales?.map(
                        (locale) =>
                            locale && (
                                <li key={locale.lang} className={cx('DocumentName')}>
                                    <span>{locale.lang}</span>
                                    <b>{locale.name}</b>
                                </li>
                            )
                    )}
                </ul>
            </div>

            <div className={cx('DocumentActions')}>
                <button type="button" disabled={loadingDeleteDocument} onClick={onClickEdit}>
                    <IconPen />
                </button>
                <button type="button" disabled={loadingDeleteDocument} onClick={deleteExpense}>
                    <IconTrash />
                </button>
            </div>
        </li>
    );
};

export default Documents;
