import { useStartEvictionAsTenantMutation } from 'apollo/generted';
import Button from 'components/UIKit/Button';
import CheckboxField from 'components/UIKit/CheckboxField';
import Modal, {
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalProps,
    ModalText,
    ModalTextParam,
    useModalContext
} from 'components/UIKit/Modal';
import ModalErrorScreen from 'components/UIKit/Modal/ErrorScreen';
import { getErrorData, getErrorI18nText } from 'helpers';
import { useToast } from 'helpers/hooks';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    id: number;
    address: string;
};

const ModalStartEvictionAsTenant = ({ id, address, ...props }: ModalProps & Props) => {
    return (
        <Modal {...props}>
            <Main address={address} id={id} />
        </Modal>
    );
};

const Main = ({ id, address }: Props) => {
    const [t] = useTranslation();
    const { close } = useModalContext();
    const [formError, setFormError] = useState('');
    const [agree, setAgree] = useState(false);
    const toast = useToast();
    const [startEvictionMutation, { loading }] = useStartEvictionAsTenantMutation();

    const startEviction = () => {
        startEvictionMutation({ variables: { input: { aptId: id } } })
            .then(({ data }) => {
                const startEvictionAsTenant = data?.startEvictionAsTenant! ?? {};
                if (startEvictionAsTenant.__typename === 'StartEvictionSuccess') {
                    toast.success(t('modalStartEvictionAsTenant.successMsg'));
                    close();
                } else {
                    setFormError(
                        t([
                            `modalStartEvictionAsTenant.error.${startEvictionAsTenant.__typename}`,
                            getErrorI18nText(t, startEvictionAsTenant.errorMessage)
                        ])
                    );
                }
            })
            .catch((e) => {
                setFormError(getErrorI18nText(t, getErrorData(e).message));
            });
    };

    return (
        <Fragment>
            <ModalHeader>{t('modalStartEvictionAsTenant.title')}</ModalHeader>
            <ModalBody>
                <ModalContent withBorder>
                    <ModalText>{t('modalStartEvictionAsTenant.description')}</ModalText>
                    <ModalTextParam label={t('apartmentStats.address.label')} value={address} />
                </ModalContent>
                <CheckboxField
                    label={t('modalStartEvictionAsTenant.agree')}
                    checked={agree}
                    onChange={(e) => setAgree(e.target.checked)}
                />

                <Button color="primary" loading={loading} disabled={!agree} onClick={startEviction} type="button">
                    {t('modalStartEvictionAsTenant.btnSubmit')}
                </Button>
            </ModalBody>

            <ModalErrorScreen resetError={() => setFormError('')} text={formError} />
        </Fragment>
    );
};

export default ModalStartEvictionAsTenant;
