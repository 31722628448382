import classNames from 'classnames/bind';
import { IconAngleDown, IconSuccessNew } from 'components/Icons';
import Button from 'components/UIKit/Button';
import { useChangeClientLanguage, useDropdown } from 'helpers/hooks';
import { Languages } from 'i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

export const LANGUAGES_MAP: { [key in Languages]: string } = {
    ru: 'Русский',
    en: 'English'
};

const LanguageSwitcher = () => {
    const changeClientLanguage = useChangeClientLanguage();
    const { i18n } = useTranslation();
    const dropdown = useDropdown({
        popperOptions: { placement: 'bottom-end' }
    });

    return (
        <div ref={dropdown.setRef}>
            <Button
                type="button"
                color="tertiary-grey"
                size="small"
                onClick={dropdown.toggle}
                isActive={dropdown.open}
                className={cx('Control', dropdown.open && 'active')}
                iconRight={<IconAngleDown />}
            >
                {LANGUAGES_MAP[i18n.language as Languages].slice(0, 3)}
            </Button>

            <ul
                ref={dropdown.setPopperRef}
                style={dropdown.popper.styles.popper}
                {...dropdown.popper.attributes.popper}
                className={cx('Content')}
                data-open={dropdown.open || undefined}
            >
                {Object.entries(LANGUAGES_MAP).map(([code, label]) => {
                    const isCurrentLng = i18n.language === code;
                    return (
                        <li key={code}>
                            <button
                                onClick={() => {
                                    changeClientLanguage(code as Languages);
                                    dropdown.toggle();
                                }}
                                data-active={isCurrentLng || undefined}
                            >
                                <span tabIndex={-1}>
                                    <span>{label}</span>
                                    {isCurrentLng && <IconSuccessNew />}
                                </span>
                            </button>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default LanguageSwitcher;
