import classNames from 'classnames/bind';
import { IconAngleLeft, IconClose } from 'components/Icons';
import { useOverflowController, useOverlayClickHandler } from 'helpers';
import React, {
    createContext,
    FC,
    memo,
    PropsWithChildren,
    ReactNode,
    useContext,
    useEffect,
    useRef,
    useState
} from 'react';
import { createPortal } from 'react-dom';
import FocusLock from 'react-focus-lock';
import styles from './index.module.scss';
const cx = classNames.bind(styles);
/**
 * ==============================
 * Typedef section
 * ==============================
 */
export type Props = PropsWithChildren<{
    isOpen: boolean;
    close?: () => void;
    closable?: boolean;
    showCloseBtn?: boolean;
    className?: string;
    onClose?: () => void;
    callClosePropOnly?: boolean;
    modalRef?: React.RefObject<HTMLDivElement>;
    size?: 'default' | 'big';
    'data-testid'?: string;
}>;

/** alias for modalApartmentSignTokenization base props */
export type ModalProps = Props;

/**
 * ==============================
 * Constants
 * ==============================
 */
const ANIM_DURATION_BG = 250;
export const ANIM_DURATION_MODAL = 600;

/**
 * ==============================
 * Context
 * ==============================
 * helpful Modal context, that allow correctly close modalApartmentSignTokenization with initiate close animation firstly
 */
export const ModalContext = createContext({ close: () => {} });
export const useModalContext = () => useContext(ModalContext);

/**
 * ==============================
 * Modal's markup, base component
 * ==============================
 */
export const ModalHeader = ({
    children,
    onClickBack,
    className = ''
}: PropsWithChildren<{
    onClickBack?(): void;
    className?: string;
}>) => {
    const { close } = useModalContext();
    return (
        <header className={cx('ModalHeader', className, onClickBack && 'withBack')}>
            {onClickBack && (
                <ModalHeaderBtn onClick={onClickBack}>
                    <IconAngleLeft />
                </ModalHeaderBtn>
            )}
            <h3 className={cx('ModalTitle')}>{children}</h3>

            <ModalHeaderBtn onClick={close}>
                <IconClose />
            </ModalHeaderBtn>
        </header>
    );
};

export const ModalHeaderBtn: FC<{ onClick(): void; disabled?: boolean }> = ({ onClick, children, disabled }) => {
    return (
        <button tabIndex={0} disabled={disabled} className={cx('BtnClose')} type="button" onClick={onClick}>
            <span tabIndex={-1}>{children}</span>
        </button>
    );
};

/**
 * ==============================
 * Modal's markup, base component
 * ==============================
 */
export const ModalBody = ({
    children,
    className = ''
}: PropsWithChildren<{
    className?: string;
}>) => {
    return <main className={cx('ModalBody', className)}>{children}</main>;
};

/**
 * ==============================
 * Modal's markup, base component
 * ==============================
 */
export const ModalFooter = ({
    children,
    className = ''
}: PropsWithChildren<{
    className?: string;
}>) => {
    return <footer className={cx('ModalFooter', className)}>{children}</footer>;
};

/**
 * ==============================
 * Modal's markup, base component
 * ==============================
 */
export const ModalContent: FC<{
    className?: string;
    withBorder?: boolean;
}> = ({ children, className = '', withBorder = false }) => {
    return <div className={cx('ModalContent', className, { withBorder })}>{children}</div>;
};

/**
 * ==============================
 * Modal's markup, base component
 * ==============================
 */
export const ModalBtnGroup: FC<{
    className?: string;
}> = ({ children, className = '' }) => {
    return <div className={cx('ModalBtnGroup', className)}>{children}</div>;
};

/**
 * ==============================
 * Modal's markup, base component
 * ==============================
 */
export const ModalText: FC<{
    className?: string;
}> = ({ children, className = '' }) => {
    return <p className={cx('ModalText', className)}>{children}</p>;
};

/**
 * ==============================
 * Modal's markup, base component
 * ==============================
 */
export const ModalTextParam: FC<{
    className?: string;
    label: ReactNode;
    value: ReactNode;
}> = ({ label, value, className = '' }) => {
    return (
        <p className={cx('ModalTextParam', className)}>
            <b>{label}</b>
            <span>{value}</span>
        </p>
    );
};

/**
 * Fully controllable Modal component.
 * It divided in pieces to more easelly build modalApartmentSignTokenization components with
 * different markups.
 * Component has some internal logic of displaying:
 * 1) after change `isOpen` to `true` modalApartmentSignTokenization adds to the DOM by React portal, animation starts
 * 2) on the end of animation local flag `modalActive` changes to `true`. Component ready to use
 * 3) on click close button or call `close` func from context - close animation fires by changing
 * `modalActive` flag to `true`
 * 4) on the end of animation `props.close` calls, `props.onClose` calls if it passed,
 * modalApartmentSignTokenization removes from the DOM
 *
 * @important
 * For better animation rendering on close use `close` function from **context**
 * ~~or pass `internalCloseRef` and call it if you need close modalApartmentSignTokenization outside/from upper level in the VDOM~~
 *
 * Additional features:
 * - on click **overlay** start closing modalApartmentSignTokenization
 * - on press `ESC` start closing modalApartmentSignTokenization
 * - uses focus-lock to disable leaving focus from modalApartmentSignTokenization
 */
const Modal = memo(
    ({
        isOpen,
        close,
        onClose,
        closable = true,
        callClosePropOnly = false,
        children,
        size = 'default',
        showCloseBtn,
        className = '',
        modalRef,
        ...props
    }: Props) => {
        const [modalActive, setModalActive] = useState(false);
        const isFirstRenderRef = useRef(true);
        const closeTimeoutId = useRef<any>();
        const overflowController = useOverflowController();

        const closeRef = useRef(close);
        useEffect(() => {
            closeRef.current = close;
        }, [close]);

        const onExternalCloseRef = useRef<() => void>();
        const modalPortal = useRef(document.createElement('div')).current;
        const modalWrapperRef = useRef<HTMLElement>(null);
        const isClosing = (modalActive || isOpen) && !(modalActive && isOpen);
        const showModal = modalActive && isOpen;

        /** create portal container, mount it and set "ready" flag */
        const prepareEnvironment = () => {
            modalPortal && document.body.appendChild(modalPortal);
            return setTimeout(() => {
                setModalActive(true);
                overflowController.blockScroll(modalPortal);
                modalWrapperRef.current?.scrollTo(0, 0);

                window.onkeydown = onPressEsc;
            });
        };

        const unmountEnvironment = (
            onBeforeAnimation = () => setModalActive(false),
            onEndAnimation = closeRef.current,
            forceClose = false
        ) => {
            if (callClosePropOnly) return onEndAnimation?.();

            if (!closable && !forceClose) return;

            // enable body's overflow
            overflowController.unblockScroll();
            // remove ekyboard event listener
            window.onkeydown = null;
            onBeforeAnimation();
            closeTimeoutId.current = setTimeout(() => {
                onExternalCloseRef.current = undefined;
                onClose?.();
                onEndAnimation?.();
                modalPortal && modalPortal.remove();
            }, ANIM_DURATION_MODAL);
        };

        const onPressEsc = (e: KeyboardEvent) => {
            if (e.key === 'Escape') unmountEnvironment();
        };

        const overlayClickHandler = useOverlayClickHandler(unmountEnvironment);

        useEffect(
            () => () => {
                modalPortal && modalPortal.remove();
                overflowController.unblockScroll();
                clearInterval(closeTimeoutId.current);
            },
            // TODO: Fix exhaustive deps
            // eslint-disable-next-line react-hooks/exhaustive-deps
            []
        );

        /**
         * on change `isOpen`
         * if `isOpen` is true - create portal container, render it and set "ready" flag
         * otherwise remove all event listeners and close modalApartmentSignTokenization
         */
        useEffect(() => {
            if (isOpen) {
                const tid = prepareEnvironment();
                onExternalCloseRef.current = () => {
                    unmountEnvironment(
                        closeRef.current,
                        () => {
                            setModalActive(false);
                        },
                        true
                    );
                    onExternalCloseRef.current = undefined;
                };

                return () => clearTimeout(tid);
            } else {
                if (isFirstRenderRef.current) {
                    isFirstRenderRef.current = false;
                    return;
                }
                onExternalCloseRef.current?.();
            }
            // TODO: Fix exhaustive deps
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isOpen]);

        if (!isOpen && !modalActive) return null;

        return createPortal(
            <FocusLock autoFocus={false}>
                <ModalContext.Provider value={{ close: () => unmountEnvironment() }}>
                    <aside
                        {...overlayClickHandler.overlayProps}
                        ref={modalWrapperRef}
                        style={{
                            transitionDuration: `${ANIM_DURATION_BG}ms`,
                            transitionDelay: isClosing ? `${ANIM_DURATION_BG * 0.8}ms` : undefined
                        }}
                        className={cx('Component', modalActive && isOpen && 'show')}
                    >
                        <div
                            ref={modalRef}
                            {...overlayClickHandler.componentProps}
                            style={{
                                transitionDuration: `${ANIM_DURATION_MODAL}ms`,
                                transitionDelay: showModal ? `${ANIM_DURATION_BG * 0.2}ms` : undefined
                            }}
                            className={cx('Modal', size, className)}
                        >
                            {children}
                        </div>
                    </aside>
                </ModalContext.Provider>
            </FocusLock>,
            modalPortal
        );
    }
);

export default Modal;
