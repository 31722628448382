import { LOCAL_STORAGE_SELECTED_REGIONS_LIST } from 'constant/';
import { TFunction } from 'i18next';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from './hooks';
import { DateTime } from 'luxon';
import { ModalProps } from 'components/UIKit/Modal';

export type DeepPartial<T> = T extends Function ? T : T extends object ? { [P in keyof T]?: DeepPartial<T[P]> } : T;

export const getErrorData = (e: any) => {
    return {
        message: e?.networkError?.result?.errors?.[0]?.message || e?.graphQLErrors?.[0]?.message || e?.message,
        code: e?.graphQLErrors?.[0]?.code as undefined | number | null
    };
};

export const getErrorI18nText = (t: TFunction, msg?: string) => {
    return `${t('error.globalError')}${msg ? `: ${msg}` : ''}`;
};

// TODO: Why not used?
// export const AUTH_HEADER = 'Authorization';
export const constructAuthHeader = (token: string) => {
    return {
        Authorization: `JWT ${token}`
    };
};

export const useGlobalError = () => {
    const [t] = useTranslation();
    const toast = useToast();
    return (msg?: string, tst = toast) => {
        tst.error(getErrorI18nText(t, msg));
    };
};

export const usePixelRatio = () => useMemo(() => (window.devicePixelRatio > 1 ? 2 : 1), []);

export const getOffsetTop = (target: HTMLElement): number => {
    return (
        target.offsetTop +
        (target.offsetParent && target.offsetParent !== document.body
            ? getOffsetTop(target.offsetParent as HTMLElement)
            : 0)
    );
};

export const scrollInto = (
    e: React.MouseEvent | undefined,
    id: string,
    offset = 0,
    behavior: ScrollBehavior = 'smooth'
) => {
    e?.preventDefault();

    const target = document.getElementById(id);
    const offsetTop = target ? getOffsetTop(target) : 0;
    const marginTop = target?.style.marginTop ? -parseInt(target.style.marginTop) : 0;
    window.scrollTo({
        left: 0,
        top: offsetTop + marginTop + offset,
        behavior
    });
};

/**
 * Calculate progress of animation with offset
 * @param scrollY - current window.scrollY position
 * @param startPoint - where animation have to be started
 * @param endPoint - where animation have to be ended
 * @returns number in [0,1] range
 */
export const calcProgressByScroll = (scrollY: number, startPoint: number, endPoint: number) => {
    let progress = (scrollY - startPoint) / (endPoint - startPoint);
    if (progress > 1) progress = 1;
    if (progress < 0) progress = 0;

    return progress;
};

export const getStoredSelectedCitiesList = () => {
    return (localStorage.getItem(LOCAL_STORAGE_SELECTED_REGIONS_LIST) || '')
        .split(',')
        .filter((id) => id !== '')
        .map(Number);
};

export const setStoredSelectedCitiesList = (list: number[]) => {
    return localStorage.setItem(LOCAL_STORAGE_SELECTED_REGIONS_LIST, list.join(','));
};

export const useOverflowController = () => {
    const id = useRef(Math.random() + '').current;
    const blockScroll = (el: HTMLElement) => {
        window.overflowController = window.overflowController ?? {};
        window.overflowController[id] = el || true;
        document.body.style.overflow = 'hidden';
    };

    const unblockScroll = () => {
        window.overflowController = window.overflowController ?? {};
        delete window.overflowController[id];

        Object.entries(window.overflowController).forEach(([id, element]) => {
            if (typeof element === 'object' && !element.parentElement) {
                delete window.overflowController[id];
            }
        });

        const hasOverflowElements = Object.values(window.overflowController).some(Boolean);

        if (!hasOverflowElements) {
            document.body.style.overflow = '';
        }
    };

    return {
        blockScroll,
        unblockScroll
    };
};

/**
 * Helper to control need we close sidebar on click/tap overlay or sidebar directly
 * @param close will fire on direct click on overlay
 *
 * @example
 * const overlayClickHandler = useOverlayClickHandler(close)
 *
 * <div {...overlayClickHandler.overlayProps} className='overlay'>
 *     <div {...overlayClickHandler.componentProps}>
 *         content
 *     </div>
 * </div>
 */
export const useOverlayClickHandler = (close?: () => void) => {
    const shouldCloseRef = useRef<null | boolean>(null);

    const setShouldClose = (v: null | boolean) => {
        shouldCloseRef.current = v;
    };

    const handleOverlayOnClick = () => {
        if (shouldCloseRef.current === null) {
            setShouldClose(true);
        }

        if (shouldCloseRef.current) {
            close?.();
        }

        setShouldClose(null);
    };

    return {
        overlayProps: { onClick: handleOverlayOnClick },
        componentProps: {
            // TODO: Why not used?
            // onMouseDown: () => setShouldClose(false),
            // onMouseUp: () => setShouldClose(false),
            onClick: () => setShouldClose(false)
        }
    };
};

export const useFormatDate = () => {
    const {
        i18n: { language }
    } = useTranslation();
    return (date: string, format: string) =>
        DateTime.fromISO(date + 'Z', { zone: 'local' })
            .setLocale(language)
            .toFormat(format);
};

const iOS = () => {
    return (
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
            navigator.platform
        ) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
};

const android = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('android') > -1;
};

export const isMobilePlatform = () => iOS() || android();

/** Set element height equal width */
export const useSquereSize = () => {
    const ref = useRef<HTMLDivElement & HTMLLIElement>(null);
    useEffect(() => {
        const handler = () => {
            if (!ref.current) return;
            ref.current.style.height = ref.current.offsetWidth + 'px';
        };

        handler();

        window.addEventListener('resize', handler);

        return () => {
            window.removeEventListener('resize', handler);
        };
    }, []);

    return ref;
};

export const addSpacesForThousands = (value?: string | null | number) => {
    const splitValue = (value ?? '').toString().split('.');
    splitValue[0] = splitValue[0].replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$& ');
    return splitValue.join('.');
};

type UseModalResult<T> = Pick<ModalProps, 'isOpen' /*  | 'internalCloseRef' */> & {
    close(): void;
    open(v?: T): void;
    payload: T | null;
};

export const useModal = <T extends any>(initialState = false): UseModalResult<T> => {
    const [isOpen, setIsOpen] = useState<boolean>(initialState);
    const [payload, setPayload] = useState<null | T>(null);

    const open = (payload?: T) => {
        setIsOpen(true);
        setPayload(payload ?? null);
    };

    const close = () => {
        setIsOpen(false);
        /**
         * we don't need to clean payload here because on close all data/markup
         * in modalApartmentSignTokenization became broken
         */
        // setPayload(null)
    };

    return { isOpen, open, close, payload };
};

export const getPassedDays = (toDate: Date | null, fromDate = new Date()) => {
    if (!toDate || !fromDate) return 0;
    return Math.abs(parseInt(`${(+toDate - +fromDate) / 1000 / 60 / 60 / 24}`));
};
