import classNames from 'classnames/bind';
import { IconAngleLeft, IconAngleRight } from 'components/Icons';
import { DateTime, Info } from 'luxon';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

enum DAY_OF_WEEK {
    MONDAY = 1,
    SUNDAY = 7
}

const WEEKS_ON_PAGE = 6;

export type Props = {
    selected: null | DateTime;
    setSelected(v: DateTime): void;
    componentProps?: JSX.IntrinsicElements['div'];
    maxDate?: DateTime | null;
    minDate?: DateTime | null;
};

const Calendar = ({ selected, setSelected, componentProps, maxDate, minDate }: Props) => {
    const {
        i18n: { language }
    } = useTranslation();
    const [{ year, month }, setPage] = useState({
        year: selected ? selected.year : DateTime.local().year,
        month: selected ? selected.month : DateTime.local().month
    });

    const weekDays = useMemo(() => Info.weekdays('short', { locale: language }), [language]);

    const currentPageDate = DateTime.local(year, month, 1).setLocale(language);
    const isDisabledPrev = year <= 1;

    const calendarPage = useMemo(() => {
        let startDate = DateTime.local(year, month, 1, 0, 0, 0, 0);
        if (startDate.weekday !== DAY_OF_WEEK.MONDAY) {
            startDate = startDate.minus({ day: startDate.weekday - 1 });
        }

        const days: DateTime[] = [startDate];

        for (let i = 0; i < WEEKS_ON_PAGE * 7 - 1; i++) {
            days.push(days[i].plus({ day: 1 }));
        }
        return days;
    }, [year, month]);

    const Day = ({ day }: { day: DateTime }) => {
        const isSelected = day.toFormat('D') === selected?.toFormat('D');
        const isDiffMonth = day.toFormat('MM') !== currentPageDate.toFormat('MM');

        const disabled = Boolean((maxDate && day > maxDate) || (minDate && day < minDate));

        return (
            <div>
                <button
                    onClick={() => setSelected(day)}
                    type="button"
                    disabled={disabled}
                    className={cx('Day', {
                        isSelected,
                        isDiffMonth
                    })}
                >
                    {day.day}
                </button>
            </div>
        );
    };

    const onClickPrev = () => {
        const target = DateTime.local(year, month).minus({ month: 1 });
        setPage({
            year: target.year,
            month: target.month
        });
    };

    const onClickNext = () => {
        const target = DateTime.local(year, month).plus({ month: 1 });
        setPage({
            year: target.year,
            month: target.month
        });
    };

    return (
        <div className={cx('Component')} {...componentProps}>
            <div className={cx('DatePicker')}>
                <div className={cx('Header')}>
                    <button disabled={isDisabledPrev} onClick={onClickPrev} className={cx('BtnPrev')} type="button">
                        <IconAngleLeft />
                    </button>

                    <div className={cx('HeaderMain')}>
                        {currentPageDate.monthLong} {currentPageDate.year}
                    </div>

                    <button onClick={onClickNext} className={cx('BtnNext')} type="button">
                        <IconAngleRight />
                    </button>
                </div>
                <div className={cx('Calendar')}>
                    {weekDays.map((name) => (
                        <span key={name} className={cx('Weekday')}>
                            {name}
                        </span>
                    ))}
                    {calendarPage.map((day) => {
                        return <Day key={day.toFormat('D')} day={day} />;
                    })}
                </div>
            </div>
        </div>
    );
};

export default Calendar;
