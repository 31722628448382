import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useApartmentEmissionParamsQuery } from 'apollo/generted';
import { useConstructAddress } from 'helpers/useParsedApartmentData';
import { getErrorData, getErrorI18nText } from 'helpers';
import Modal, { ModalBody, ModalContent, ModalHeader, ModalProps, ModalTextParam } from 'components/UIKit/Modal';
import ModalErrorScreen from 'components/UIKit/Modal/ErrorScreen';
import ModalLoadingScreen from 'components/UIKit/Modal/LoadingScreen';
import CheckboxField from 'components/UIKit/CheckboxField';
import Button from 'components/UIKit/Button';
import ScanQRSection from 'components/ScanQRSection';
import { ModalApartmentEmissionProps } from './ApartmentEmission.d';

const Main = ({ aptId }: ModalApartmentEmissionProps) => {
    const [t] = useTranslation();
    const [agree, setAgree] = React.useState(false);
    const [showQr, setShowQr] = React.useState(false);
    const constructAddress = useConstructAddress();

    const query = useApartmentEmissionParamsQuery({
        variables: { id: aptId! },
        fetchPolicy: 'cache-and-network'
    });

    const apartment = query.data?.apartment;
    const deepLink = apartment?.tokenizationInfo?.emissionDeepLink;

    if (showQr)
        return (
            <ModalBody>
                <ScanQRSection
                    qrData={deepLink!}
                    deepLink={deepLink!}
                    deepLinkText={t('modalApartmentEmission.btnEmission')}
                    text={
                        <Trans i18nKey="modalApartmentEmission.qr">
                            <b />
                        </Trans>
                    }
                />
            </ModalBody>
        );

    const error = query.error && getErrorI18nText(t, getErrorData(query.error).message);

    return (
        <ModalBody>
            {query.loading ? (
                <ModalLoadingScreen />
            ) : error ? (
                <ModalErrorScreen isStatic text={error} resetError={() => query.refetch()} />
            ) : (
                <>
                    <ModalContent withBorder>
                        <ModalTextParam label={t('apartmentStats.address.label')} value={constructAddress(apartment)} />
                        <ModalTextParam label={t('apartmentStats.realId.label')} value={apartment?.realId} />
                        <ModalTextParam
                            label={t('apartmentStats.emission.label')}
                            value={`${Number(apartment?.emissionVolume?.amount)} ${apartment?.emissionVolume?.token}`}
                        />
                        <ModalTextParam
                            label={t('apartmentStats.fee.label')}
                            value={`${Number(apartment?.emissionVolume?.fee)} ${apartment?.emissionVolume?.token}`}
                        />
                    </ModalContent>
                    <CheckboxField
                        label={t('modalApartmentEmission.agree')}
                        checked={agree}
                        onChange={(e) => setAgree(e.target.checked)}
                    />
                    <Button type="button" disabled={!agree} onClick={() => setShowQr(true)} color="primary">
                        {t('modalApartmentEmission.btnSubmit')}
                    </Button>
                </>
            )}
        </ModalBody>
    );
};

const ModalApartmentEmission = ({ isOpen, close, aptId }: ModalProps & ModalApartmentEmissionProps) => {
    const [t] = useTranslation();

    return (
        <Modal isOpen={isOpen} close={close}>
            <ModalHeader>{t('modalApartmentEmission.title')}</ModalHeader>
            <Main aptId={aptId} />
        </Modal>
    );
};

export default ModalApartmentEmission;
