import { ChangeEvent } from 'react';

// TODO: Change export
// eslint-disable-next-line import/no-anonymous-default-export
export default (
    {
        target: {
            value,
            validity: { valid }
        }
    }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    currentValue: string
) => {
    return value && !valid ? currentValue : value;
};
