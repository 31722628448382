import { useApolloClient } from '@apollo/client';
import { reconnectWS } from 'apollo';
import { Lang, useSetLanguageMutation, useSystemQuery } from 'apollo/generted';
import Preloader from 'components/Preloader';
import { useDispatch } from 'react-redux';
import { setRealtimeOffset } from 'store/Base/actions';
import { useChangeClientLanguage, useStore } from 'helpers/hooks';
import { Languages } from 'i18n';
import React, { Fragment, memo, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useResetApolloStore = () => {
    const apollo = useApolloClient();

    return async () => {
        try {
            reconnectWS();
            await (apollo as any).queryManager.fetchQueryRejectFns;
            await apollo.resetStore();
        } catch (e) {}
    };
};

const useLanguageController = () => {
    const resetStore = useResetApolloStore();
    const changeClientLanguage = useChangeClientLanguage();
    /** get current client language */
    const { i18n } = useTranslation();
    /** just alias */
    const language = i18n.language as Languages;
    /**
     * @important
     * As because we control change language on server only here
     * we need to store real last client's language
     * For more details see `useEffect` handler
     */
    const lastLanguage = useRef(language);
    /** Store current language as state for trigger useEffects in correct time */
    const [currentServerLanguage, setCurrentServerLanguage] = useState<null | string>(null);
    const isAuth = useStore((s) => s.Base.isAuth);
    const dispatch = useDispatch();
    const setOffset = useCallback(
        (offset: number) => {
            dispatch(setRealtimeOffset(offset));
        },
        [dispatch]
    );

    /**
     * Mutation for change language for ALL api requests
     * @important Note, language can change before app become initialized
     */
    const [setLanguageMutation, { loading: setLanguageMutationLoading }] = useSetLanguageMutation();

    /**
     * Request required data:
     * - real time in the server in order to display correct timeouts on apartments, etc.
     * - language on the server in order to compare it with client language and sync if it needs
     */
    const systemQuery = useSystemQuery({
        fetchPolicy: process.env.NODE_ENV === 'test' ? 'no-cache' : undefined,
        variables: {
            isAuth: Boolean(isAuth)
        }
    });

    const realtime = systemQuery.data?.realtime;

    useEffect(() => {
        if (realtime) {
            /* Date in UTC */
            const realTime = +new Date(realtime + 'Z');
            const userTime = +new Date();

            setOffset(realTime - userTime);
        }
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [realtime]);

    const isLanguageSync = currentServerLanguage === language;

    /**
     * Whenever systemQuery refetch and its data updates --
     * update state too. If we will store `currentServerLang` as
     * simple variable - `isAuth` will affects on value early, then
     * the new data received.
     */
    useEffect(() => {
        if (!systemQuery.data) return;
        setCurrentServerLanguage(
            ((isAuth ? systemQuery.data?.me?.language : systemQuery.data?.currentLanguage) ?? '').toLowerCase()
        );
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [systemQuery.data]);

    /**
     * Control any change of client's and server's language
     */
    useEffect(() => {
        /** If data is not ready or language is sync - do nothing */
        if (isLanguageSync || !systemQuery.data) return;

        /**
         * If user auth in the `currentServerLanguage` we store `me.language`
         * If value exists AND this value is different by client's language
         * AND language variable has real lastLanguage value -- save server's lang
         *
         * Otherwise, update language on the server
         */
        if (
            isAuth &&
            currentServerLanguage &&
            currentServerLanguage !== language &&
            lastLanguage.current === language
        ) {
            /** me.language has more priority */
            changeClientLanguage(currentServerLanguage as Languages);
            lastLanguage.current = currentServerLanguage as Languages;
            resetStore();
        } else {
            /** Map between client and server language names */
            const languageMap: { [key in Languages]: Lang } = {
                ru: Lang.Ru,
                en: Lang.En
            };

            setLanguageMutation({
                variables: {
                    input: {
                        lang: languageMap[language]
                    }
                }
            })
                .then(() => {
                    /** If user `isAuth` and it has not stored lang - we don't need update
                     * all queries, because it is already with correct language
                     */
                    if (isAuth && !currentServerLanguage) {
                        systemQuery.refetch();
                    } else {
                        resetStore();
                    }
                    // TODO add error handling
                })
                .catch(console.error);
            lastLanguage.current = language;
        }
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentServerLanguage, language]);

    const systemQueryReady = !systemQuery.loading && !!systemQuery.data;

    return {
        isLanguageSync,
        systemQueryReady,
        loading: setLanguageMutationLoading || !systemQueryReady
    };
};

const AppInitializer = memo(({ children }: PropsWithChildren<{}>) => {
    const resetStore = useResetApolloStore();
    /** control first time, when app initialize */
    const isAppHasBeenInitialized = useRef(false);
    const isAuth = useStore((s) => s.Base.isAuth);
    const languageController = useLanguageController();

    /** If loading is `true` - display preloader in order to hide all changes */

    const loading = languageController.loading;

    /**
     * render app if
     * - app has been initialized
     * - OR it has all required data and language is sync
     */
    const isAppReady =
        isAppHasBeenInitialized.current || (!languageController.loading && languageController.isLanguageSync);

    /** save first time when the app is ready */
    useEffect(() => {
        if (isAppReady) isAppHasBeenInitialized.current = true;
    }, [isAppReady]);

    useEffect(() => {
        return () => {
            resetStore();
        };
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth]);

    return (
        <Fragment>
            {isAppReady && children}
            {loading && <Preloader />}
        </Fragment>
    );
});

export default AppInitializer;
