import React from 'react';
import classNames from 'classnames/bind';
import { IconWarningNew } from 'components/Icons';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface AlertProps {
    children?: React.ReactNode;
    className?: string;
}

const Alert = ({ children, className = '' }: AlertProps) => {
    return (
        <p className={cx('Component', className)}>
            <IconWarningNew />
            <span>{children}</span>
        </p>
    );
};

export default Alert;
