import { CityNode, useAddBuyoutRequestParamsQuery, useBuyAptParamsLazyQuery } from 'apollo/generted';
import classNames from 'classnames/bind';
import ScanQRSection from 'components/ScanQRSection';
import Alert from 'components/UIKit/Alert';
import Button from 'components/UIKit/Button';
import ButtonLink from 'components/UIKit/ButtonLink';
import CheckboxField from 'components/UIKit/CheckboxField';
import DropdownFieldNew from 'components/UIKit/DropdownField';
import Modal, { ModalBody, ModalContent, ModalHeader, ModalProps, ModalText } from 'components/UIKit/Modal';
import ModalErrorScreen from 'components/UIKit/Modal/ErrorScreen';
import ModalLoadingScreen from 'components/UIKit/Modal/LoadingScreen';
import TextField from 'components/UIKit/TextField';
import { URL_MAP } from 'constant';
import { getErrorData, getErrorI18nText, useModal } from 'helpers';
import { useFormField } from 'helpers/hooks';
import useMeQuery from 'helpers/useMeQuery';
import validator from 'helpers/validator';
import { ChangeEventHandler, Fragment, memo, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ModalChangeEosAccount from '../ChangeEosAccount';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

const ModalAddBuyoutRequest = (props: ModalProps) => {
    return (
        <Modal {...props}>
            <Main />
        </Modal>
    );
};

// 10 ^ 8
const PRECISION_LIST = 1_0000_0000;
// 10 ^ 2
const PRECISION_M2 = 100;
// 10 ^ 6
const PRECISION_DIFF = PRECISION_LIST / PRECISION_M2;

const Main = memo(() => {
    const [t] = useTranslation();
    const amount = useFormField('');
    const [step, setStep] = useState<'request' | 'confirm'>('request');
    const [formError, setFormError] = useState('');
    const [agree, setAgree] = useState(false);
    const modalChangeEosAccount = useModal(false);
    const [cityId, setCityId] = useState<CityNode['id'] | null>(null);

    const eosAccount = useMeQuery().data!.me?.eosAccount;
    const hasEosAccount = !!eosAccount;

    const query = useAddBuyoutRequestParamsQuery({
        skip: !hasEosAccount
    });

    const [getBuyAptParamsQuery, buyAptParamsQuery] = useBuyAptParamsLazyQuery();
    const { deeplink = '', paymentParams = '' } = buyAptParamsQuery.data?.buyAptParams ?? {};

    const errorText = query.error && getErrorI18nText(t, getErrorData(query.error).message);

    const cityList = useMemo(() => query.data?.cities ?? [], [query.data]);

    const selectedCity = useMemo(() => {
        return cityList.find((city) => city?.id === cityId);
    }, [cityId, cityList]);

    const config = useMemo(() => query?.data?.buyRequestConfig, [query.data]);

    const result = useMemo(() => {
        const fee = config?.feePercent ? +config?.feePercent / 100 : null;
        // in sm
        let maxArea = 0;
        // in listik (satoshi)
        let maxFee = 0;

        if (amount.value && fee) {
            const amountInListik = +amount.value * PRECISION_LIST;
            maxArea = Math.floor(amountInListik / (1 + fee) / PRECISION_DIFF);
            maxFee = Math.floor(maxArea * PRECISION_DIFF * fee);
        }

        // convert LISTIK to LIST
        maxArea = maxArea / PRECISION_M2;
        // convert sm to m2
        maxFee = maxFee / PRECISION_LIST;

        return {
            maxArea,
            maxFee
        };
    }, [amount.value, config]);

    const selectedToken = selectedCity?.token?.name;
    const disabled = !agree || !selectedToken || !amount.value || !!amount.error;

    const onSubmit = () => {
        getBuyAptParamsQuery({
            variables: {
                tokenId: selectedToken!,
                // remove zeros
                amountToPay: Number(amount.value).toString()
            }
        });
    };

    const onChangeAmount: ChangeEventHandler<HTMLInputElement> = (e) => {
        const newValue = validator(e, amount.value);

        amount.change(
            newValue,
            +newValue < +config!.minTransferVolume!
                ? t('modalAddBuyoutRequest.error.MinTransferVolume', {
                      amount: config!.minTransferVolume,
                      token: selectedToken
                  })
                : ''
        );
    };

    useEffect(() => {
        if (deeplink) setStep('confirm');
    }, [deeplink]);

    useEffect(() => {
        if (buyAptParamsQuery.error) {
            setFormError(buyAptParamsQuery.error && getErrorI18nText(t, getErrorData(buyAptParamsQuery.error).message));
        }
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buyAptParamsQuery.error]);

    useEffect(() => {
        if (cityList) setCityId(cityList[0]?.id ?? null);
    }, [cityList]);

    useEffect(() => {
        amount.change('');
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityId]);

    if (query.loading)
        return (
            <ModalBody>
                <ModalLoadingScreen />
            </ModalBody>
        );

    if (errorText)
        return (
            <ModalBody>
                <ModalErrorScreen isStatic text={errorText} resetError={() => query.refetch()} />
            </ModalBody>
        );

    return (
        <Fragment>
            {!hasEosAccount ? (
                <Fragment>
                    <ModalHeader>{t('modalAddBuyoutRequest.addEos.title')}</ModalHeader>
                    <ModalBody>
                        <ModalContent>
                            <ModalText>{t('modalAddBuyoutRequest.addEos.description')}</ModalText>
                        </ModalContent>

                        <Button color="primary" onClick={modalChangeEosAccount.open}>
                            {t('modalAddBuyoutRequest.addEos.addEosBtn')}
                        </Button>
                    </ModalBody>
                </Fragment>
            ) : (
                <Fragment>
                    <ModalHeader>{t('modalAddBuyoutRequest.title')}</ModalHeader>
                    <ModalBody>
                        {step === 'request' && (
                            <Fragment>
                                <ModalContent withBorder>
                                    <ModalText>
                                        {t('modalAddBuyoutRequest.request.description', {
                                            min: config?.minTransferVolume,
                                            fee: config?.feePercent
                                        })}
                                    </ModalText>
                                    <ButtonLink
                                        to={URL_MAP.help.buyoutProcess}
                                        tag="link"
                                        target="_blank"
                                        fontWeight="bold"
                                        fontSize="big"
                                        className={cx('DetailedLink')}
                                    >
                                        {t('modalAddBuyoutRequest.request.detailedLink')}
                                    </ButtonLink>

                                    <DropdownFieldNew
                                        value={selectedCity?.name ?? ''}
                                        withAngleIcon
                                        onChange={() => {}}
                                        label={t('apartmentStats.region.label')}
                                        options={cityList.map((city) => {
                                            return {
                                                key: city?.id!,
                                                element: (
                                                    <button type="button" onClick={() => setCityId(city?.id!)}>
                                                        {city?.name}
                                                    </button>
                                                )
                                            };
                                        })}
                                    />

                                    <TextField
                                        label={t('modalAddBuyoutRequest.request.amount')}
                                        pattern="^[0-9]\d{0,7}([\.]\d{0,8})?$"
                                        rightControl={selectedToken}
                                        onChange={onChangeAmount}
                                        value={amount.value}
                                        error={amount.error}
                                    />

                                    <p className={cx('Params')}>
                                        <span>{t('modalAddBuyoutRequest.request.maxM2')}</span>
                                        <span>
                                            {result.maxArea} {t('apartmentStats.area.unit')}
                                        </span>
                                    </p>
                                    <p className={cx('Params')}>
                                        <b>{t('modalAddBuyoutRequest.request.maxFee')}</b>
                                        <span>
                                            {result.maxFee} {selectedToken}
                                        </span>
                                    </p>

                                    <Alert>{t(`modalAddBuyoutRequest.request.alert`)}</Alert>
                                </ModalContent>

                                <CheckboxField
                                    label={t('modalContestWinnerAgreement.agree')}
                                    checked={agree}
                                    onChange={(e) => setAgree(e.target.checked)}
                                />

                                <Button
                                    loading={buyAptParamsQuery.loading}
                                    type="button"
                                    disabled={disabled}
                                    color="primary"
                                    onClick={onSubmit}
                                >
                                    {t('modalAddBuyoutRequest.request.btnSubmit')}
                                </Button>
                            </Fragment>
                        )}

                        {step === 'confirm' && (
                            <ModalContent>
                                <Alert className={cx('InfoAlert')}>
                                    {t(`modalAddBuyoutRequest.request.alert`, {
                                        context: 'account',
                                        account: eosAccount
                                    })}
                                </Alert>

                                <ScanQRSection
                                    qrData={paymentParams}
                                    deepLink={deeplink}
                                    text={
                                        <Trans i18nKey="modalAddBuyoutRequest.request.description_qr">
                                            <b />
                                        </Trans>
                                    }
                                />
                            </ModalContent>
                        )}
                    </ModalBody>
                    <ModalErrorScreen text={formError} resetError={() => setFormError('')} />
                </Fragment>
            )}
            <ModalChangeEosAccount isOpen={modalChangeEosAccount.isOpen} close={modalChangeEosAccount.close} />
        </Fragment>
    );
});

export default ModalAddBuyoutRequest;
