import { AptStatus, BuyAptRequestNode, useConfirmBuyoutParamsQuery } from 'apollo/generted';
import ScanQRSection from 'components/ScanQRSection';
import Alert from 'components/UIKit/Alert';
import Button from 'components/UIKit/Button';
import CheckboxField from 'components/UIKit/CheckboxField';
import Modal, {
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalProps,
    ModalText,
    ModalTextParam
} from 'components/UIKit/Modal';
import ModalErrorScreen from 'components/UIKit/Modal/ErrorScreen';
import ModalLoadingScreen from 'components/UIKit/Modal/LoadingScreen';
import { getErrorData, getErrorI18nText } from 'helpers';
import formatDate from 'helpers/formatDate';
import { useConstructAddress } from 'helpers/useParsedApartmentData';
import { Fragment, memo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export type Props = {
    buyRequestId: BuyAptRequestNode['id'];
};

const ModalConfirmBuyout = ({ isOpen, close, buyRequestId }: ModalProps & Props) => {
    const [t] = useTranslation();
    return (
        <Modal isOpen={isOpen} close={close}>
            <ModalHeader>{t('modalConfirmBuyout.title')}</ModalHeader>
            <Main buyRequestId={buyRequestId} />
        </Modal>
    );
};
const Main = memo<Props>(({ buyRequestId }) => {
    const [t] = useTranslation();
    const constructAddress = useConstructAddress();
    const [showQr, setShowQr] = useState(false);
    const [agree, setAgree] = useState(false);
    const query = useConfirmBuyoutParamsQuery({
        variables: { buyRequestId }
    });

    const errorText = query.error && getErrorI18nText(t, getErrorData(query.error).message);

    const { apt: apartment, buyerApproveDeeplink: deepLink, changeAmount, tokenId } = query.data?.buyRequest ?? {};

    if (query.loading)
        return (
            <ModalBody>
                <ModalLoadingScreen />
            </ModalBody>
        );

    if (errorText)
        return (
            <ModalBody>
                <ModalErrorScreen isStatic text={errorText} resetError={() => query.refetch()} />
            </ModalBody>
        );

    return (
        <ModalBody>
            {!showQr ? (
                <Fragment>
                    <ModalContent withBorder>
                        <ModalText>
                            {t(`modalConfirmBuyout.description`, {
                                amount: changeAmount,
                                token: tokenId
                            })}
                        </ModalText>

                        {apartment?.status === AptStatus.Rented && (
                            <Alert>
                                {t(`modalConfirmBuyout.alert`, {
                                    date: formatDate(apartment.actualRental?.expirationDate)
                                })}
                            </Alert>
                        )}

                        <ModalTextParam label={t('apartmentStats.address.label')} value={constructAddress(apartment)} />
                    </ModalContent>

                    <CheckboxField
                        label={t('modalConfirmBuyout.checkBox')}
                        checked={agree}
                        onChange={(e) => setAgree(e.target.checked)}
                    />

                    <Button type="button" disabled={!agree} color="primary" onClick={() => setShowQr(true)}>
                        {t('modalConfirmBuyout.btnSubmit')}
                    </Button>
                </Fragment>
            ) : (
                <ScanQRSection
                    qrData={deepLink ?? ''}
                    deepLink={deepLink ?? ''}
                    text={
                        <Trans i18nKey="modalConfirmBuyout.qrText">
                            <b />
                        </Trans>
                    }
                />
            )}
        </ModalBody>
    );
});

export default ModalConfirmBuyout;
