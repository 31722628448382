import { ApolloClient, createHttpLink, from, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import Fingerprint2 from 'fingerprintjs2';
import { getToken, signOut } from 'helpers/sign';
import { constructAuthHeader } from 'helpers';
import store from 'store/';
import { setErrorStatus } from 'store/Base/actions';
import result from './generted';

const getFingerPrint = async () => {
    let fingerprint = '';

    await Fingerprint2.getPromise({
        excludes: {
            userAgent: true,
            language: true,
            sessionStorage: true,
            localStorage: true,
            indexedDb: true,
            addBehavior: true,
            openDatabase: true,
            plugins: true,
            canvas: true,
            webgl: true,
            adBlock: true,
            fonts: true,
            audio: true,
            enumerateDevices: true
        }
    }).then((components) => {
        fingerprint = Fingerprint2.x64hash128(components.map((c) => c.value).join(''), 31);
    });

    return fingerprint;
};

const errorCodes = onError((error) => {
    const { networkError } = error;

    if (networkError && (networkError as any).statusCode === 401) {
        signOut(true);
    }

    // display any error or make any signOut only if user is online
    if (networkError && navigator?.onLine) {
        const status = (networkError as any).statusCode;

        if (networkError.toString() === 'TypeError: Failed to fetch') {
            store.dispatch(setErrorStatus(503));
        } else if ([503, 502].includes(status)) {
            store.dispatch(setErrorStatus(503));
        } else if ([500, 504].includes(status)) {
            store.dispatch(setErrorStatus(500));
        }
    }
});

export const createCache = () =>
    new InMemoryCache({
        ...result
    });

export const cache = createCache();

export const getApiUrl = () => process.env.REACT_APP_API_ENDPOINT;

const authLink = setContext(async (_, context) => {
    const token = getToken();

    context.headers = {
        ...(token ? constructAuthHeader(token) : null),
        FINGERPRINT: await getFingerPrint(),
        ...context.headers
    };

    return context;
});

const httpLink = createHttpLink({
    credentials: 'include',
    uri: getApiUrl()
});

const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_WS_ENDPOINT!,
    options: {
        lazy: true,
        reconnect: true,
        connectionParams: async () => {
            const token = getToken();

            return {
                headers: {
                    AUTHORIZATION: token ? `JWT ${token}` : undefined,
                    FINGERPRINT: await getFingerPrint()
                }
            };
        }
    }
});

/**
 * We have to trigger reconnect for all WS sockets,
 * because we need to update JWT token for the connection
 */
export const reconnectWS = () => {
    const client = (wsLink as any).subscriptionClient;
    const operations = {
        ...client.operations
    };

    client.close(true);
    client.connect();

    Object.keys(operations).forEach((id) => {
        client.sendMessage(id, 'start', operations[id].options);
    });

    client.operations = operations;
};

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
    // split based on operation type
    ({ query }) => {
        const definition = getMainDefinition(query);

        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink
);

const client = new ApolloClient({
    link: from([errorCodes, authLink, link]),
    cache
});

export default client;
