import { useApartmentExpensesQuery } from 'apollo/generted';
import classNames from 'classnames/bind';
import Button from 'components/UIKit/Button';
import Modal, { ModalBody, ModalHeader, ModalProps } from 'components/UIKit/Modal';
import ModalErrorScreen from 'components/UIKit/Modal/ErrorScreen';
import ModalLoadingScreen from 'components/UIKit/Modal/LoadingScreen';
import { addSpacesForThousands, getErrorData, getErrorI18nText } from 'helpers';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

type Props = {
    aptId: number;
    onSubmit: () => void;
};

const ModalApartmentRenewalLicense = ({ isOpen, close, aptId, onSubmit }: Props & ModalProps) => {
    const [t] = useTranslation();
    return (
        <Modal isOpen={isOpen} close={close}>
            <ModalHeader>{t('modalApartmentRenewalLicense.title')}</ModalHeader>
            <Main aptId={aptId} onSubmit={onSubmit} />
        </Modal>
    );
};

const Main = ({ aptId, onSubmit }: Props) => {
    const [t] = useTranslation();
    const query = useApartmentExpensesQuery({ variables: { id: aptId! } });

    const error = query.error && getErrorI18nText(t, getErrorData(query.error).message);

    const { apartment, aptExpenses } = query.data ?? {};
    const currency = apartment?.communalCurrency ?? '';
    const description = t('modalApartmentRenewalLicense.description', {
        returnObjects: true
    }) as string[];

    return (
        <ModalBody>
            {query.loading ? (
                <ModalLoadingScreen />
            ) : error ? (
                <ModalErrorScreen isStatic text={error} resetError={() => query.refetch()} />
            ) : (
                <Fragment>
                    <div className={cx('Content')}>
                        {description.map((text, i) => (
                            <p className={cx('Description')} key={i}>
                                {text}
                            </p>
                        ))}
                        <ul className={cx('Details')}>
                            {apartment?.communalPayment && (
                                <li>
                                    <span>{t('apartmentStats.communalPayment.label')}</span>
                                    <span>
                                        {addSpacesForThousands(apartment?.communalPayment)} {currency}
                                    </span>
                                </li>
                            )}

                            {aptExpenses?.map((item) => (
                                <li key={item?.expense.id}>
                                    <span>{item?.expense.name}</span>
                                    <span>
                                        {addSpacesForThousands(item?.value?.toString())} {currency}
                                    </span>
                                </li>
                            ))}
                            {apartment?.totalExpenses && (
                                <li className={cx('big')}>
                                    <span>{t('modalApartmentRenewalLicense.total')}</span>
                                    <span>
                                        {addSpacesForThousands(apartment?.totalExpenses)} {currency}
                                    </span>
                                </li>
                            )}
                        </ul>
                    </div>
                    <Button onClick={onSubmit} type="button" color="primary">
                        {t('modalApartmentRenewalLicense.btnSubmit')}
                    </Button>
                </Fragment>
            )}
        </ModalBody>
    );
};

export default ModalApartmentRenewalLicense;
