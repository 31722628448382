import {
    AptStatus,
    LockedSortEnum,
    LockReason,
    useManagerLockedAptsQuery,
    useManagerLockedAptsWsSubscription
} from 'apollo/generted';
import classNames from 'classnames/bind';
import { IconBlockFlats } from 'components/Icons';
import Button from 'components/UIKit/Button';
import Counter from 'components/UIKit/Counter';
import { URL_MAP } from 'constant';
import ModalUnblockApartment, { Props as ModalUnblockApartmentProps } from 'containers/Modals/UnblockApartment';
import { getPassedDays, useModal } from 'helpers';
import formatDate from 'helpers/formatDate';
import update from 'immutability-helper';
import React, { Fragment, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Apartments from '../components/Apartments';
import Card from '../components/Card';
import { Section, SectionHeader, SectionMain, SectionTitle } from '../components/Section';
import SectionSort from '../components/SectionSort';
import { useManageContext } from '../context';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

const LockedApartments = memo(() => {
    const [t] = useTranslation();
    const [sort, setSort] = useState(LockedSortEnum.LockStartDateDesc);
    const { lockedNum } = useManageContext()?.city?.notaryUpdates ?? {};
    return (
        <Section className={cx('Section')}>
            <SectionHeader>
                <SectionTitle>
                    {t('pageManage.lockedApartments.title')}
                    {!!lockedNum && <Counter>{lockedNum}</Counter>}
                </SectionTitle>

                <SectionSort
                    controlClassName={cx('SortControl')}
                    options={[
                        {
                            isActive: sort === LockedSortEnum.LockStartDateDesc,
                            label: t(`pageManage.lockedApartments.sort.${LockedSortEnum.LockStartDateDesc}`),
                            onClick: () => setSort(LockedSortEnum.LockStartDateDesc)
                        },
                        {
                            isActive: sort === LockedSortEnum.LockStartDate,
                            label: t(`pageManage.lockedApartments.sort.${LockedSortEnum.LockStartDate}`),
                            onClick: () => setSort(LockedSortEnum.LockStartDate)
                        }
                    ]}
                />
            </SectionHeader>

            <SectionMain>
                <Main sort={sort} />
            </SectionMain>
        </Section>
    );
});

const Main = ({ sort }: { sort: LockedSortEnum }) => {
    const cityId = useManageContext().city?.id!;
    const [offset, setOffset] = useState(0);
    const modalUnblockApartment = useModal<Pick<ModalUnblockApartmentProps, 'id'>>();
    const [t] = useTranslation();
    const STEP = 10;

    const {
        data: apartmentsData,
        loading,
        error,
        ...apartmentsQuery
    } = useManagerLockedAptsQuery({
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        variables: {
            first: STEP,
            offset,
            sort: [sort],
            cityId
        }
    });

    /** is any loading now */
    const apartments = apartmentsData?.lockedApts?.apartments;
    const total = apartmentsData?.lockedApts?.pageInfo.total || 0;
    const page = offset! / STEP;

    useManagerLockedAptsWsSubscription({
        skip: !!error,
        variables: {
            cityId
        },
        onSubscriptionData: ({ subscriptionData: { data } }) => {
            apartmentsQuery.updateQuery((queryData) => {
                const { apt: target, oldStatus } = data?.RDUpdates ?? {};
                if (!target) return queryData;

                const targetId = target.id;
                const needToRemove = target.status !== AptStatus.Locked;
                const isNewApartment = target.status === AptStatus.Locked && oldStatus !== AptStatus.Locked;
                const apartments = queryData.lockedApts?.apartments ?? [];

                if (needToRemove) {
                    /**
                     * Close all modals for this apartment
                     */
                    if (modalUnblockApartment.payload?.id === targetId) {
                        modalUnblockApartment.close();
                    }

                    /**
                     * Remove item from list
                     */
                    const filteredApartments = apartments.filter((a) => a?.id !== targetId);
                    if (!filteredApartments.length) {
                        if (page === 0) {
                            apartmentsQuery.refetch();
                        } else {
                            onChangePage((page ?? 1) - 1);
                        }
                        return queryData;
                    }

                    return update(queryData, {
                        lockedApts: {
                            apartments: () => filteredApartments,
                            pageInfo: {
                                total: (total) => total - 1
                            }
                        }
                    });
                } else if (isNewApartment && page === 0 && apartments.every((apt) => apt?.id !== targetId)) {
                    return update(queryData, {
                        lockedApts: {
                            apartments: () => {
                                const newApartments = [target, ...apartments];
                                if (newApartments.length > STEP) newApartments.length = STEP;
                                return newApartments;
                            },
                            pageInfo: {
                                total: (total) => total + 1
                            }
                        }
                    });
                }

                return queryData;
            });
        }
    });

    const onChangePage = (page: number) => {
        window.scrollTo({ top: 0 });
        setOffset(page * STEP);
    };

    const onUnblock = () => {
        apartmentsQuery.updateQuery((currentData) => {
            return update(currentData, {
                lockedApts: {
                    apartments: (arr) => arr.filter((item) => item?.id !== modalUnblockApartment.payload?.id)
                }
            });
        });
    };

    return (
        <Apartments
            loading={loading}
            onClickReload={() => apartmentsQuery.refetch()}
            error={error?.message}
            isEmpty={!apartments?.length}
            page={page}
            pageCount={Math.ceil(total / STEP)}
            empty={{
                icon: <IconBlockFlats />,
                title: t('pageManage.lockedApartments.empty.title'),
                text: t('pageManage.lockedApartments.empty.text')
            }}
            onChangePage={onChangePage}
        >
            {apartments?.map((apartment) => {
                if (!apartment) return null;
                const { lockEndDate, startLockDate, reason, reasonType } = apartment.lock ?? {};

                return (
                    <Card
                        data={apartment}
                        key={apartment.id}
                        to={URL_MAP.apartment(apartment.id)}
                        params={[
                            {
                                label: t('pageManage.lockedApartments.card.params.startLockDate'),
                                value: formatDate(startLockDate)
                            },
                            lockEndDate
                                ? {
                                      label: t('pageManage.lockedApartments.card.params.daysToUnlock'),
                                      value: t('global.days', {
                                          count: getPassedDays(new Date(lockEndDate + 'z'))
                                      })
                                  }
                                : null,
                            {
                                label: t('apartmentStats.lock.lockEndDate.label'),

                                value:
                                    lockEndDate === null
                                        ? t('apartmentStats.lock.lockEndDate.FOREVER')
                                        : formatDate(lockEndDate)
                            },
                            {
                                label: t('apartmentStats.lock.reason.label'),
                                value:
                                    reasonType === LockReason.Custom
                                        ? reason
                                        : t(`apartmentStats.lock.reason.${reasonType}`)
                            }
                        ]}
                        actions={
                            <Fragment>
                                {reasonType === LockReason.Custom && (
                                    <Button
                                        onClick={() => modalUnblockApartment.open({ id: apartment.id })}
                                        color="primary"
                                        type="button"
                                    >
                                        {t('pageManage.lockedApartments.card.actions.unblock')}
                                    </Button>
                                )}
                            </Fragment>
                        }
                    />
                );
            })}

            <ModalUnblockApartment
                {...modalUnblockApartment}
                {...modalUnblockApartment.payload!}
                onUnblock={onUnblock}
            />
        </Apartments>
    );
};

export default LockedApartments;
