import {
    AptStatus,
    useManagerWaitingContestAptsQuery,
    useManagerWaitingContestAptsWsSubscription,
    WaitingContestSortEnum
} from 'apollo/generted';
import classNames from 'classnames/bind';
import { IconRepeat } from 'components/Icons';
import Button from 'components/UIKit/Button';
import Counter from 'components/UIKit/Counter';
import { URL_MAP } from 'constant';
import ModalStartAuction, { Props as ModalStartAuctionProps } from 'containers/Modals/StartAuction';
import { useModal } from 'helpers';
import formatDate from 'helpers/formatDate';
import update from 'immutability-helper';
import React, { Fragment, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Apartments from '../components/Apartments';
import Card from '../components/Card';
import { Section, SectionHeader, SectionMain, SectionTitle } from '../components/Section';
import SectionSort from '../components/SectionSort';
import { useManageContext } from '../context';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

const WaitingContets = memo(() => {
    const [t] = useTranslation();
    const [sort, setSort] = useState(WaitingContestSortEnum.UpdatedForRdDesc);
    const { waitingContestsNum } = useManageContext()?.city?.notaryUpdates ?? {};
    return (
        <Section className={cx('Section')}>
            <SectionHeader>
                <SectionTitle>
                    {t('pageManage.waitingContest.title')}
                    {!!waitingContestsNum && <Counter>{waitingContestsNum}</Counter>}
                </SectionTitle>

                <SectionSort
                    controlClassName={cx('SortControl')}
                    options={[
                        {
                            isActive: sort === WaitingContestSortEnum.UpdatedForRdDesc,
                            label: t(`pageManage.waitingContest.sort.${WaitingContestSortEnum.UpdatedForRdDesc}`),
                            onClick: () => setSort(WaitingContestSortEnum.UpdatedForRdDesc)
                        },
                        {
                            isActive: sort === WaitingContestSortEnum.UpdatedForRd,
                            label: t(`pageManage.waitingContest.sort.${WaitingContestSortEnum.UpdatedForRd}`),
                            onClick: () => setSort(WaitingContestSortEnum.UpdatedForRd)
                        }
                    ]}
                />
            </SectionHeader>

            <SectionMain>
                <Main sort={sort} />
            </SectionMain>
        </Section>
    );
});

const Main = ({ sort }: { sort: WaitingContestSortEnum }) => {
    const cityId = useManageContext().city?.id!;
    const [offset, setOffset] = useState(0);
    const modalStartAuction = useModal<ModalStartAuctionProps>();
    const [t] = useTranslation();
    const STEP = 10;

    const {
        data: apartmentsData,
        loading,
        error,
        ...apartmentsQuery
    } = useManagerWaitingContestAptsQuery({
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        variables: {
            first: STEP,
            offset,
            sort: [sort],
            cityId
        }
    });

    /** is any loading now */
    const apartments = apartmentsData?.waitingContestApts?.apartments;
    const total = apartmentsData?.waitingContestApts?.pageInfo.total || 0;
    const page = offset! / STEP;

    const onChangePage = (page: number) => {
        window.scrollTo({ top: 0 });
        setOffset(page * STEP);
    };

    useManagerWaitingContestAptsWsSubscription({
        skip: !!error,
        variables: {
            cityId
        },
        onSubscriptionData: ({ subscriptionData: { data } }) => {
            apartmentsQuery.updateQuery((queryData) => {
                const { apt: target, oldStatus } = data?.RDUpdates ?? {};
                if (!target) return queryData;

                const targetId = target.id;
                const needToRemove = target.status !== AptStatus.WaitingContest;
                const isNewApartment =
                    target.status === AptStatus.WaitingContest && oldStatus !== AptStatus.WaitingContest;
                const apartments = queryData.waitingContestApts?.apartments ?? [];

                if (needToRemove) {
                    /**
                     * Close all modals for this apartment
                     */
                    if (modalStartAuction.payload?.aptId === target.id) {
                        modalStartAuction.close();
                    }

                    const filteredApartments = apartments.filter((a) => a?.id !== targetId);
                    if (!filteredApartments.length) {
                        if (page === 0) {
                            apartmentsQuery.refetch();
                        } else {
                            onChangePage((page ?? 1) - 1);
                        }
                        return queryData;
                    }

                    return update(queryData, {
                        waitingContestApts: {
                            apartments: () => filteredApartments,
                            pageInfo: {
                                total: (total) => total - 1
                            }
                        }
                    });
                } else if (isNewApartment && page === 0 && apartments.every((apt) => apt?.id !== targetId)) {
                    return update(queryData, {
                        waitingContestApts: {
                            apartments: () => {
                                const newApartments = [target, ...apartments];
                                if (newApartments.length > STEP) newApartments.length = STEP;
                                return newApartments;
                            },
                            pageInfo: {
                                total: (total) => total + 1
                            }
                        }
                    });
                }

                return queryData;
            });
        }
    });

    const removeApt = (aptId: number) => {
        apartmentsQuery.updateQuery((currentData) => {
            return update(currentData, {
                waitingContestApts: {
                    apartments: (arr) => arr.filter((item) => item?.id !== aptId)
                }
            });
        });
    };

    return (
        <Apartments
            loading={loading}
            onClickReload={() => apartmentsQuery.refetch()}
            error={error?.message}
            isEmpty={!apartments?.length}
            page={page}
            pageCount={Math.ceil(total / STEP)}
            empty={{
                icon: <IconRepeat />,
                title: t('pageManage.waitingContest.empty.title'),
                text: t('pageManage.waitingContest.empty.text')
            }}
            onChangePage={onChangePage}
        >
            {apartments?.map((apartment) => {
                if (!apartment) return null;

                return (
                    <Card
                        data={apartment}
                        key={apartment.id}
                        to={URL_MAP.apartment(apartment.id)}
                        params={[
                            {
                                label: t('pageManage.waitingContest.card.params.updatedForRd'),
                                value: formatDate(apartment.updatedForRd, true)
                            }
                        ]}
                        actions={
                            <Fragment>
                                <Button
                                    onClick={() =>
                                        modalStartAuction.open({
                                            cityId,
                                            aptId: apartment.id,
                                            onSuccess: () => removeApt(apartment.id)
                                        })
                                    }
                                    color="primary"
                                    type="button"
                                >
                                    {t('pageManage.waitingContest.card.actions.startAuction')}
                                </Button>
                                <Button to={URL_MAP.editApartment(apartment.id)} tag="link" color="tertiary">
                                    {t('pageManage.waitingContest.card.actions.edit')}
                                </Button>
                            </Fragment>
                        }
                    />
                );
            })}

            <ModalStartAuction {...modalStartAuction} {...modalStartAuction.payload!} />
        </Apartments>
    );
};

export default WaitingContets;
