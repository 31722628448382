import React, { useState, FormEvent } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useFormField } from 'helpers/hooks';
import validatePassword from 'helpers/validatePassword';
import FormCodeVerification from 'containers/Forms/CodeVerification';
import { useConfirmPasswordResetMutation, useResetPasswordMutation } from 'apollo/generted';
import { getErrorI18nText, getErrorData, constructAuthHeader } from 'helpers';
import { ModalHeader, ModalBody } from 'components/UIKit/Modal';
import Button from 'components/UIKit/Button';
import TextField from 'components/UIKit/TextField';
import { IconAccount, IconLock } from 'components/Icons';
import PasswordField from 'components/UIKit/PasswordField';
import { OnAuth } from '..';

const cx = classNames.bind(styles);

type Step = 'login' | 'code' | 'password';

type Props = {
    onClickBack(): void;
    onAuth: OnAuth;
    onError(v: string): void;
};

const FormForgotPassword = ({ onClickBack, onAuth, onError }: Props) => {
    const [verifyToken, setVerifyToken] = useState('');
    const [step, setStep] = useState<Step>('login');
    const loginField = useFormField('');
    const newPasswordField = useFormField('');
    const repeatPasswordField = useFormField('');
    const [t] = useTranslation();

    const [resetPasswordMutation, { loading: loadingResetPassword }] = useResetPasswordMutation();

    const [confirmPasswordResetMutation, { loading: loadingConfirmPasswordReset }] = useConfirmPasswordResetMutation();

    const onBlock = (errorMessage = '') => {
        setStep('login');
        onError(t(['modalSign.forgotPassword.errors.NoResendAttemptsError', errorMessage]));
    };

    const resetPassword = (e?: FormEvent<HTMLFormElement>) => {
        e?.preventDefault();
        loginField.changeError('');
        repeatPasswordField.changeError('');
        const isEmailLoginType = loginField.value.includes('@');

        resetPasswordMutation({
            variables: {
                input: {
                    phone: isEmailLoginType ? undefined : loginField.value,
                    email: isEmailLoginType ? loginField.value : undefined
                }
            }
        })
            .then(({ data }) => {
                const resetPassword = data?.resetPassword!;
                if (resetPassword.__typename === 'ResetPasswordSuccess') {
                    setVerifyToken(resetPassword.verifyToken);
                    setStep('code');
                } else {
                    const { __typename, errorMessage = '' } = resetPassword ?? {};
                    if (
                        __typename === 'UserNotFoundError' ||
                        __typename === 'LoginIsEmptyError' ||
                        __typename === 'InvalidPhoneError' ||
                        __typename === 'InvalidEmailError'
                    ) {
                        loginField.changeError(t([`modalSign.forgotPassword.errors.${__typename}`, errorMessage]));
                    } else if (__typename === 'NoResendAttemptsError') {
                        onBlock(errorMessage);
                    } else {
                        onError(
                            t([`modalSign.forgotPassword.errors.${__typename}`, getErrorI18nText(t, errorMessage)])
                        );
                    }
                }
            })
            .catch((e) => {
                onError(getErrorI18nText(t, getErrorData(e).message));
            });
    };

    const confirmPasswordReset = (e?: FormEvent<HTMLFormElement>) => {
        e?.preventDefault();
        newPasswordField.changeError('');

        if (newPasswordField.value !== repeatPasswordField.value) {
            return repeatPasswordField.changeError(t('modalSign.forgotPassword.errors.PasswordsDoesnotMatch'));
        }

        confirmPasswordResetMutation({
            context: verifyToken ? { headers: constructAuthHeader(verifyToken) } : undefined,
            variables: {
                input: {
                    password: newPasswordField.value
                }
            }
        })
            .then(({ data }) => {
                const confirmPasswordReset = data?.confirmPasswordReset! ?? {};
                if (confirmPasswordReset.__typename === 'ConfirmPasswordResetSuccess') {
                    onAuth(confirmPasswordReset.token);
                } else {
                    const { errorMessage = '', __typename } = confirmPasswordReset;
                    if (__typename === 'InvalidPasswordError') {
                        newPasswordField.changeError(
                            t([`modalSign.forgotPassword.errors.${__typename}`, errorMessage])
                        );
                    } else {
                        onError(
                            t([`modalSign.forgotPassword.errors.${__typename}`, getErrorI18nText(t, errorMessage)])
                        );
                    }
                }
            })
            .catch((e) => {
                onError(getErrorI18nText(t, getErrorData(e).message));
            });
    };

    const passwordValidation = validatePassword(newPasswordField.value);

    return (
        <>
            <ModalHeader onClickBack={onClickBack}>{t('modalSign.forgotPassword.title')}</ModalHeader>
            <ModalBody>
                {step === 'login' && (
                    <form onSubmit={resetPassword}>
                        <p className={cx('Description')}>{t('modalSign.forgotPassword.login.description')}</p>
                        <TextField
                            label={t('modalSign.forgotPassword.login.field')}
                            leftControl={<IconAccount />}
                            value={loginField.value}
                            onChange={(e) => loginField.change(e.target.value)}
                            error={loginField.error}
                        />
                        <div className={cx('BtnGroup')}>
                            <Button
                                loading={loadingResetPassword}
                                disabled={loginField.value.length < 5}
                                color="primary"
                                type="submit"
                            >
                                {t('modalSign.forgotPassword.login.btnSubmit')}
                            </Button>
                        </div>
                    </form>
                )}
                {step === 'code' && (
                    <FormCodeVerification
                        verifyToken={verifyToken}
                        description={t('modalSign.forgotPassword.code.description', {
                            address: loginField.value,
                            context: loginField.value.includes('@') ? 'email' : 'phone'
                        })}
                        onSuccess={() => setStep('password')}
                        onUnexpectedError={onError}
                        onBlock={onBlock}
                        onSuccessResendCode={setVerifyToken}
                    />
                )}
                {step === 'password' && (
                    <form onSubmit={confirmPasswordReset}>
                        <PasswordField
                            label={t('modalSign.forgotPassword.password.newPassword')}
                            leftControl={<IconLock />}
                            value={newPasswordField.value}
                            onChange={(e) => newPasswordField.change(e.target.value)}
                            error={newPasswordField.error}
                            rules={passwordValidation}
                        />
                        <PasswordField
                            label={t('modalSign.forgotPassword.password.repeatPassword')}
                            leftControl={<IconLock />}
                            value={repeatPasswordField.value}
                            onChange={(e) => repeatPasswordField.change(e.target.value)}
                            error={repeatPasswordField.error}
                        />
                        <div className={cx('BtnGroup')}>
                            <Button
                                loading={loadingConfirmPasswordReset}
                                disabled={!passwordValidation.isValid || !repeatPasswordField.value}
                                color="primary"
                                type="submit"
                            >
                                {t('modalSign.forgotPassword.password.btnSubmit')}
                            </Button>
                        </div>
                    </form>
                )}
            </ModalBody>
        </>
    );
};

export default FormForgotPassword;
