import { CityNode, LoginType, useUpdateRdContactsMutation } from 'apollo/generted';
import classNames from 'classnames/bind';
import Button from 'components/UIKit/Button';
import Modal, { ModalBody, ModalHeader, ModalProps, useModalContext } from 'components/UIKit/Modal';
import ModalErrorScreen from 'components/UIKit/Modal/ErrorScreen';
import TextField from 'components/UIKit/TextField';
import { getErrorData, getErrorI18nText } from 'helpers';
import formatPhone from 'helpers/formatPhone';
import { useFormField, useToast } from 'helpers/hooks';
import React, { FormEvent, Fragment, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

export type Props = {
    type: LoginType.Email | LoginType.Phone;
    defaultValue?: string | null;
    cityId: CityNode['id'];
};

const ModalEditRegionContacts = memo(({ isOpen, close, ...props }: Props & ModalProps) => {
    return (
        <Modal close={close} isOpen={isOpen}>
            <Main {...props} />
        </Modal>
    );
});

const Main = memo(({ type, defaultValue, cityId }: Props) => {
    const [t] = useTranslation();
    const { close } = useModalContext();
    const contact = useFormField(defaultValue ?? '');
    const [formError, setFormError] = useState('');
    const toast = useToast();
    const [updateRdContactsMutation, { loading }] = useUpdateRdContactsMutation();

    const onSubmit = (e?: FormEvent<HTMLFormElement>) => {
        e?.preventDefault();
        contact.changeError('');

        updateRdContactsMutation({
            variables: {
                input: {
                    cityId: cityId!,
                    phone: type === LoginType.Phone ? formatPhone(contact.value) : undefined,
                    email: type === LoginType.Email ? contact.value : undefined
                }
            }
        })
            .then(({ data }) => {
                const updateRdContacts = data?.updateRdContacts!;
                if (updateRdContacts.__typename === 'UpdateRdContactsSuccess') {
                    toast.success(t('modalEditRegionContacts.successMsg'));
                    close();
                } else {
                    const { __typename, errorMessage = '' } = updateRdContacts ?? {};
                    if (__typename === 'InvalidEmailError') {
                        contact.changeError(t('modalEditRegionContacts.error.InvalidEmailError'));
                    } else if (__typename === 'InvalidPhoneError') {
                        contact.changeError(t('modalEditRegionContacts.error.InvalidPhoneError'));
                    } else {
                        setFormError(
                            t([`modalEditRegionContacts.error.${__typename}`, getErrorI18nText(t, errorMessage)])
                        );
                    }
                }
            })
            .catch((e) => {
                setFormError(getErrorI18nText(t, getErrorData(e).message));
            });
    };

    return (
        <Fragment>
            <ModalHeader>{t(`modalEditRegionContacts.title`, { context: type })}</ModalHeader>
            <ModalBody>
                <form className={cx('Form')} onSubmit={onSubmit}>
                    <TextField
                        value={contact.value}
                        error={contact.error}
                        label={t(`modalEditRegionContacts.field`, { context: type })}
                        onChange={(e) => contact.change(e.target.value)}
                    />
                    <Button type="submit" color="primary" disabled={!contact.value} loading={loading}>
                        {t('modalEditRegionContacts.btnSubmit')}
                    </Button>
                </form>
            </ModalBody>

            <ModalErrorScreen text={formError} resetError={() => setFormError('')} />
        </Fragment>
    );
});

export default ModalEditRegionContacts;
