import React, { Fragment, ReactNode } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import Alert from 'components/UIKit/Alert';
import { IconPen, IconPlus, IconTrash } from 'components/Icons';
import ProfileParam from 'components/UIKit/ProfileParam';
import Button from 'components/UIKit/Button';
const cx = classNames.bind(styles);

type SettingProps = {
    className?: string;
    label: ReactNode;
    icon?: ReactNode;
    value: ReactNode;
    hideRemoveBtn?: boolean;
    labelBtnRemove?: ReactNode;
    labelBtnAdd?: ReactNode;
    labelBtnEdit?: ReactNode;
    labelEmpty?: ReactNode;
    onClickAdd?(): void;
    onClickEdit?(): void;
    onClickRemove?(): void;
    actions?: ReactNode;
    alert?: ReactNode;
};

const Setting = ({
    className = '',
    label,
    value,
    hideRemoveBtn,
    labelBtnRemove,
    labelBtnAdd,
    labelBtnEdit,
    labelEmpty,
    onClickAdd,
    onClickEdit,
    onClickRemove,
    alert,
    actions,
    icon
}: SettingProps) => {
    const [t] = useTranslation();
    return (
        <div className={cx('Setting', className)}>
            <ProfileParam
                className={cx('Main')}
                icon={icon}
                label={label}
                value={value || labelEmpty || t('pageProfile.empty')}
            />
            <div className={cx('SettingActions')}>
                {actions || (
                    <Fragment>
                        {value ? (
                            <Fragment>
                                {!hideRemoveBtn && (
                                    <Button
                                        round
                                        iconLeft={<IconTrash />}
                                        onClick={onClickRemove}
                                        size="small"
                                        color="tertiary-grey"
                                    >
                                        {labelBtnRemove || t('pageProfile.btnRemove')}
                                    </Button>
                                )}
                                <Button
                                    round
                                    iconLeft={<IconPen />}
                                    onClick={onClickEdit}
                                    size="small"
                                    color="secondary-grey"
                                >
                                    {labelBtnEdit || t('pageProfile.btnEdit')}
                                </Button>
                            </Fragment>
                        ) : (
                            <Button
                                round
                                iconLeft={<IconPlus />}
                                size="small"
                                onClick={onClickAdd}
                                color="secondary-grey"
                            >
                                {labelBtnAdd || t('pageProfile.btnAdd')}
                            </Button>
                        )}
                    </Fragment>
                )}
            </div>

            {alert && <Alert className={cx('Alert')}>{alert}</Alert>}
        </div>
    );
};

export default Setting;
