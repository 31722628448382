import { ApartmentNode, useCancelContestAsNotaryMutation } from 'apollo/generted';
import Modal, {
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalProps,
    ModalText,
    useModalContext
} from 'components/UIKit/Modal';
import ModalErrorScreen from 'components/UIKit/Modal/ErrorScreen';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/UIKit/Button';
import { getErrorData, getErrorI18nText } from 'helpers';
import CheckboxField from 'components/UIKit/CheckboxField';
import { useToast } from 'helpers/hooks';

export type Props = Pick<ApartmentNode, 'id'> & {
    onSuccess(): void;
};

const ModalCancelContestAsNotary = ({ id, onSuccess, ...props }: ModalProps & Props) => {
    return (
        <Modal {...props}>
            <Main onSuccess={onSuccess} id={id} />
        </Modal>
    );
};

const Main = ({ id, onSuccess }: Props) => {
    const [t] = useTranslation();
    const [agree, setAgree] = useState(false);
    const [error, setError] = useState('');
    const toast = useToast();
    const { close } = useModalContext();
    const [cancelContestAsNotaryMutation, { loading }] = useCancelContestAsNotaryMutation();

    const cancelContest = () => {
        cancelContestAsNotaryMutation({
            variables: {
                input: {
                    aptId: id
                }
            }
        })
            .then(({ data }) => {
                if (data?.cancelContestAsNotary?.__typename === 'CancelContestSuccess') {
                    toast.success(t('modalCancelContestAsNotary.successMsg'));
                    onSuccess();
                    close();
                } else {
                    setError(
                        t([
                            `modalCancelContestAsNotary.error.${data?.cancelContestAsNotary?.__typename}`,
                            getErrorI18nText(t, data?.cancelContestAsNotary?.errorMessage)
                        ])
                    );
                }
            })
            .catch((e) => {
                setError(getErrorI18nText(t, getErrorData(e).message));
            });
    };

    return (
        <Fragment>
            <ModalHeader>{t('modalCancelContestAsNotary.title')}</ModalHeader>
            <ModalBody>
                <ModalContent withBorder>
                    <ModalText>{t('modalCancelContestAsNotary.description')}</ModalText>
                </ModalContent>
                <CheckboxField
                    checked={agree}
                    onChange={(e) => setAgree(e.target.checked)}
                    label={t('modalCancelContestAsNotary.agree')}
                />

                <Button color="primary" loading={loading} disabled={!agree} onClick={cancelContest}>
                    {t('modalCancelContestAsNotary.btnSubmit')}
                </Button>
            </ModalBody>
            <ModalErrorScreen text={error} resetError={() => setError('')} />
        </Fragment>
    );
};

export default ModalCancelContestAsNotary;
