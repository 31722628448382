import classNames from 'classnames/bind';
import { IconCheck, IconClose, IconEye, IconEyeCrossed, IconLock } from 'components/Icons';
import { useToggle } from 'helpers/hooks';
import validatePassword from 'helpers/validatePassword';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TextField, { Props as TextFieldProps } from '../TextField';
import Tooltip, { useTooltip } from '../Tooltip/Tooltip';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

type Rules = ReturnType<typeof validatePassword>;

type Props = Omit<TextFieldProps, 'type'> & {
    rules?: Rules;
};

const PasswordField = ({ rules, containerClassName, ...props }: Props) => {
    const [showPasswodrd, toggle] = useToggle(false);
    const [t] = useTranslation();
    const tooltip = useTooltip();

    return (
        <div className={cx('Wrapper')} ref={tooltip.setPopperReference}>
            <TextField
                onFocus={rules && tooltip.show}
                leftControl={<IconLock />}
                onBlur={rules && tooltip.hide}
                type={showPasswodrd ? 'text' : 'password'}
                containerClassName={cx('Component', containerClassName)}
                rightControl={
                    <button
                        type="button"
                        style={{ padding: 0, display: 'flex' }}
                        // we have to save focus and cursor position
                        onClick={() => process.nextTick(toggle)}
                    >
                        {showPasswodrd ? <IconEyeCrossed /> : <IconEye />}
                    </button>
                }
                {...props}
            />
            {rules && (
                <Tooltip
                    className={cx('Tooltip')}
                    {...tooltip}
                    isActive={tooltip.isActive && !rules.isValid}
                    popperOptions={(options) => ({
                        ...options,
                        placement: 'bottom-start'
                    })}
                >
                    <p className={cx('Rules')}>
                        <span className={cx('Rule', rules.minPasswordLength && 'Rule_correct')}>
                            {rules.minPasswordLength ? <IconCheck /> : <IconClose />}
                            {t('passwordRules.minPasswordLength')}
                        </span>
                        <span className={cx('Rule', rules.lettersPassword && 'Rule_correct')}>
                            {rules.lettersPassword ? <IconCheck /> : <IconClose />}
                            {t('passwordRules.lettersPassword')}
                        </span>
                        <span className={cx('Rule', rules.digitsPassword && 'Rule_correct')}>
                            {rules.digitsPassword ? <IconCheck /> : <IconClose />}

                            {t('passwordRules.digitsPassword')}
                        </span>
                    </p>
                </Tooltip>
            )}
        </div>
    );
};

export default PasswordField;
