import {
    ApplicationSortEnum,
    AptStatus,
    useManagerApplicationsQuery,
    useManagerApplicationsWsSubscription
} from 'apollo/generted';
import { IconInbox } from 'components/Icons';
import Button from 'components/UIKit/Button';
import Counter from 'components/UIKit/Counter';
import { URL_MAP } from 'constant';
import ModalContacts, { Props as ModalContactsProps } from 'containers/Modals/Contacts';
import { useModal } from 'helpers';
import formatDate from 'helpers/formatDate';
import update from 'immutability-helper';
import React, { Fragment, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route } from 'react-router';
import Apartments from '../components/Apartments';
import Card from '../components/Card';
import { Section, SectionHeader, SectionHeaderPanel, SectionMain, SectionTitle } from '../components/Section';
import SectionSort from '../components/SectionSort';
import SectionTabs from '../components/SectionTabs';
import { useManageContext } from '../context';

const Incoming = memo(() => {
    const [t] = useTranslation();
    const [sort, setSort] = useState(ApplicationSortEnum.UpdatedForRdDesc);
    const { moderationsNum, reworksNum } = useManageContext()?.city?.notaryUpdates ?? {};
    return (
        <Section>
            <SectionHeader withTabs>
                <SectionTitle>{t('pageManage.incoming.title')}</SectionTitle>
                <SectionHeaderPanel>
                    <SectionTabs
                        options={[
                            {
                                exact: true,
                                to: URL_MAP.manage.incoming.index,
                                children: (
                                    <Fragment>
                                        {t('pageManage.incoming.moderation.title')}

                                        {!!moderationsNum && <Counter>{moderationsNum}</Counter>}
                                    </Fragment>
                                )
                            },
                            {
                                to: URL_MAP.manage.incoming.rework,
                                children: (
                                    <Fragment>
                                        {t('pageManage.incoming.rework.title')}

                                        {!!reworksNum && <Counter>{reworksNum}</Counter>}
                                    </Fragment>
                                )
                            }
                        ]}
                    />

                    <SectionSort
                        options={[
                            {
                                isActive: sort === ApplicationSortEnum.UpdatedForRdDesc,
                                label: t(`pageManage.incoming.sort.${ApplicationSortEnum.UpdatedForRdDesc}`),
                                onClick: () => setSort(ApplicationSortEnum.UpdatedForRdDesc)
                            },
                            {
                                isActive: sort === ApplicationSortEnum.UpdatedForRd,
                                label: t(`pageManage.incoming.sort.${ApplicationSortEnum.UpdatedForRd}`),
                                onClick: () => setSort(ApplicationSortEnum.UpdatedForRd)
                            }
                        ]}
                    />
                </SectionHeaderPanel>
            </SectionHeader>

            <SectionMain>
                <Route exact path={URL_MAP.manage.incoming.index} render={() => <Moderation sort={sort} />} />
                <Route path={URL_MAP.manage.incoming.rework} render={() => <ReworkApartments sort={sort} />} />
            </SectionMain>
        </Section>
    );
});

const Moderation = ({ sort }: { sort: ApplicationSortEnum }) => {
    const cityId = useManageContext().city?.id!;
    const [offset, setOffset] = useState(0);
    const modalContacts = useModal<ModalContactsProps>();
    const [t] = useTranslation();
    const STEP = 10;

    const {
        data: apartmentsData,
        loading,
        error,
        ...apartmentsQuery
    } = useManagerApplicationsQuery({
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        variables: {
            first: STEP,
            offset,
            sort: [sort],
            cityId,
            statuses: [AptStatus.Moderation]
        }
    });

    /** is any loading now */
    const apartments = apartmentsData?.applications?.apartments;
    const total = apartmentsData?.applications?.pageInfo.total || 0;
    const page = offset! / STEP;

    const onChangePage = (page: number) => {
        window.scrollTo({ top: 0 });
        setOffset(page * STEP);
    };

    useManagerApplicationsWsSubscription({
        skip: !!error,
        variables: {
            cityId
        },
        onSubscriptionData: ({ subscriptionData: { data } }) => {
            apartmentsQuery.updateQuery((queryData) => {
                const { apt: target, oldStatus } = data?.RDUpdates ?? {};
                if (!target) return queryData;

                const targetId = target.id;
                const needToRemove = target.status !== AptStatus.Moderation;
                const isNewApartment = target.status === AptStatus.Moderation && oldStatus !== AptStatus.Moderation;
                const apartments = queryData.applications?.apartments ?? [];

                if (needToRemove) {
                    /** Close modals */
                    if (modalContacts.payload?.type === 'applicant' && modalContacts.payload.aptId === targetId) {
                        modalContacts.close();
                    }

                    const filteredApartments = apartments.filter((a) => a?.id !== targetId);
                    if (!filteredApartments.length) {
                        if (page === 0) {
                            apartmentsQuery.refetch();
                        } else {
                            onChangePage((page ?? 1) - 1);
                        }
                        return queryData;
                    }

                    return update(queryData, {
                        applications: {
                            apartments: () => filteredApartments,
                            pageInfo: {
                                total: (total) => total - 1
                            }
                        }
                    });
                } else if (isNewApartment && page === 0 && apartments.every((apt) => apt?.id !== targetId)) {
                    return update(queryData, {
                        applications: {
                            apartments: () => {
                                const newApartments = [target, ...apartments];
                                if (newApartments.length > STEP) newApartments.length = STEP;
                                return newApartments;
                            },
                            pageInfo: {
                                total: (total) => total + 1
                            }
                        }
                    });
                }

                return queryData;
            });
        }
    });

    return (
        <Apartments
            loading={loading}
            onClickReload={() => apartmentsQuery.refetch()}
            error={error?.message}
            isEmpty={!apartments?.length}
            page={page}
            pageCount={Math.ceil(total / STEP)}
            empty={{
                icon: <IconInbox />,
                title: t('pageManage.incoming.moderation.empty.title'),
                text: t('pageManage.incoming.moderation.empty.text')
            }}
            onChangePage={onChangePage}
        >
            {apartments?.map((apartment) => {
                if (!apartment) return null;

                return (
                    <Card
                        data={apartment}
                        key={apartment.id}
                        to={URL_MAP.reviewApartment(apartment.id)}
                        params={[
                            {
                                label: t('pageManage.incoming.moderation.card.params.updated'),
                                value: formatDate(apartment.updatedForRd)
                            }
                        ]}
                        actions={
                            <Fragment>
                                <Button color="primary" tag="link" to={URL_MAP.reviewApartment(apartment.id)}>
                                    {t('pageManage.incoming.moderation.card.actions.check')}
                                </Button>
                                <Button
                                    onClick={() =>
                                        modalContacts.open({
                                            aptId: apartment.id,
                                            type: 'applicant'
                                        })
                                    }
                                    color="tertiary"
                                >
                                    {t('pageManage.incoming.moderation.card.actions.contact')}
                                </Button>
                            </Fragment>
                        }
                    />
                );
            })}

            <ModalContacts {...modalContacts} {...modalContacts.payload!} />
        </Apartments>
    );
};

const ReworkApartments = ({ sort }: { sort: ApplicationSortEnum }) => {
    const cityId = useManageContext().city?.id!;
    const [offset, setOffset] = useState(0);
    const modalContacts = useModal<ModalContactsProps>();
    const [t] = useTranslation();
    const STEP = 10;

    const {
        data: apartmentsData,
        loading,
        error,
        ...apartmentsQuery
    } = useManagerApplicationsQuery({
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        variables: {
            first: STEP,
            offset,
            sort: [sort],
            cityId,
            statuses: [AptStatus.Rework]
        }
    });

    /** is any loading now */
    const apartments = apartmentsData?.applications?.apartments;
    const total = apartmentsData?.applications?.pageInfo.total || 0;
    const page = offset! / STEP;

    const onChangePage = (page: number) => {
        window.scrollTo({ top: 0 });
        setOffset(page * STEP);
    };

    useManagerApplicationsWsSubscription({
        skip: !!error,
        variables: {
            cityId
        },
        onSubscriptionData: ({ subscriptionData: { data } }) => {
            apartmentsQuery.updateQuery((queryData) => {
                const { apt: target, oldStatus } = data?.RDUpdates ?? {};
                if (!target) return queryData;

                const targetId = target.id;
                const needToRemove = target.status !== AptStatus.Rework;
                const isNewApartment = target.status === AptStatus.Rework && oldStatus !== AptStatus.Rework;
                const apartments = queryData.applications?.apartments ?? [];

                if (needToRemove) {
                    /** Close modals */
                    if (modalContacts.payload?.type === 'applicant' && modalContacts.payload.aptId === targetId) {
                        modalContacts.close();
                    }

                    const filteredApartments = apartments.filter((a) => a?.id !== targetId);
                    if (!filteredApartments.length) {
                        if (page === 0) {
                            apartmentsQuery.refetch();
                        } else {
                            onChangePage((page ?? 1) - 1);
                        }
                        return queryData;
                    }

                    return update(queryData, {
                        applications: {
                            apartments: () => filteredApartments,
                            pageInfo: {
                                total: (total) => total - 1
                            }
                        }
                    });
                } else if (isNewApartment && page === 0 && apartments.every((apt) => apt?.id !== targetId)) {
                    return update(queryData, {
                        applications: {
                            apartments: () => {
                                const newApartments = [target, ...apartments];
                                if (newApartments.length > STEP) newApartments.length = STEP;
                                return newApartments;
                            },
                            pageInfo: {
                                total: (total) => total + 1
                            }
                        }
                    });
                }

                return queryData;
            });
        }
    });

    return (
        <Apartments
            loading={loading}
            onClickReload={() => apartmentsQuery.refetch()}
            error={error?.message}
            isEmpty={!apartments?.length}
            page={page}
            pageCount={Math.ceil(total / STEP)}
            empty={{
                icon: <IconInbox />,
                title: t('pageManage.incoming.rework.empty.title'),
                text: t('pageManage.incoming.rework.empty.text')
            }}
            onChangePage={onChangePage}
        >
            {apartments?.map((apartment) => {
                if (!apartment) return null;

                return (
                    <Card
                        data={apartment}
                        key={apartment.id}
                        to={URL_MAP.reviewApartment(apartment.id)}
                        params={[
                            {
                                label: t('pageManage.incoming.rework.card.params.updated'),
                                value: formatDate(apartment.updatedForRd)
                            }
                        ]}
                        actions={
                            <Fragment>
                                <Button color="primary" tag="link" to={URL_MAP.reviewApartment(apartment.id)}>
                                    {t('pageManage.incoming.rework.card.actions.check')}
                                </Button>
                                <Button
                                    onClick={() =>
                                        modalContacts.open({
                                            aptId: apartment.id,
                                            type: 'applicant'
                                        })
                                    }
                                    color="tertiary"
                                >
                                    {t('pageManage.incoming.rework.card.actions.contact')}
                                </Button>
                            </Fragment>
                        }
                    />
                );
            })}
            <ModalContacts {...modalContacts} {...modalContacts.payload!} />
        </Apartments>
    );
};

export default Incoming;
