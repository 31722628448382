import {
    ApartmentNode,
    CityNode,
    useContactsManagerQuery,
    useContactsApartmentOwnerQuery,
    useContactsApartmentWinnerQuery,
    useContactsApartmentTenantQuery,
    ContactsApartmentOwnerQuery,
    ContactsApartmentWinnerQuery,
    ContactsApartmentTenantQuery,
    ContactsManagerQuery,
    useContactsApartmentBuyerQuery,
    ContactsApartmentBuyerQuery,
    BuyAptRequestNode
} from 'apollo/generted';
import classNames from 'classnames/bind';
import ScanQRSection from 'components/ScanQRSection';
import Contacts from 'components/UIKit/Contacts';
import Modal, { ModalBody, ModalHeader, ModalProps } from 'components/UIKit/Modal';
import ErrorScreen from 'components/UIKit/Modal/ErrorScreen';
import ModalLoadingScreen from 'components/UIKit/Modal/LoadingScreen';
import { getErrorData, getErrorI18nText } from 'helpers';
import { Fragment, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

export type Props =
    | { type: 'manager'; cityId: CityNode['id'] }
    | { type: 'buyer'; buyRequestId: BuyAptRequestNode['id'] }
    | {
          type: 'winner' | 'owner' | 'applicant' | 'tenant';
          aptId: ApartmentNode['id'];
      };

const ModalContacts = ({ isOpen, close, ...props }: ModalProps & Props) => {
    const [t] = useTranslation();

    return (
        <Modal isOpen={isOpen} close={close}>
            <ModalHeader>{t('modalContacts.title', { context: props.type })}</ModalHeader>
            <Main {...props} />
        </Modal>
    );
};

const Main = (props: Props) => {
    const cityId = props.type === 'manager' ? props.cityId : null;
    const aptId = props.type !== 'manager' && props.type !== 'buyer' ? props.aptId : null;
    const buyRequestId = props.type === 'buyer' ? props.buyRequestId : null;

    const managerQuery = useContactsManagerQuery({
        variables: { id: cityId! },
        skip: props.type !== 'manager'
    });
    const ownerQuery = useContactsApartmentOwnerQuery({
        variables: { id: aptId! },
        skip: props.type !== 'owner' && props.type !== 'applicant'
    });
    const winnerQuery = useContactsApartmentWinnerQuery({
        variables: { id: aptId! },
        skip: props.type !== 'winner'
    });
    const tenantQuery = useContactsApartmentTenantQuery({
        variables: { id: aptId! },
        skip: props.type !== 'tenant'
    });
    const buyerQuery = useContactsApartmentBuyerQuery({
        variables: { id: buyRequestId! },
        skip: props.type !== 'buyer'
    });

    const query = {
        error: managerQuery.error || ownerQuery.error || winnerQuery.error || tenantQuery.error || buyerQuery.error,
        loading:
            managerQuery.loading ||
            ownerQuery.loading ||
            winnerQuery.loading ||
            tenantQuery.loading ||
            buyerQuery.loading,
        refetch:
            managerQuery.refetch ||
            ownerQuery.refetch ||
            winnerQuery.refetch ||
            tenantQuery.refetch ||
            buyerQuery.refetch,
        data: managerQuery.data || ownerQuery.data || winnerQuery.data || tenantQuery.data || buyerQuery.data
    };

    const [t] = useTranslation();
    const error = query.error && getErrorI18nText(t, getErrorData(query.error).message);

    const { chatLink, eosAccount, phone, email } = useMemo(() => {
        if (!query.data)
            return {
                chatLink: '',
                eosAccount: '',
                phone: '',
                email: ''
            };

        if (props.type === 'owner' || props.type === 'applicant') {
            const data = (query.data as ContactsApartmentOwnerQuery)?.apartment?.user;
            return {
                chatLink: data?.cryptochatDeepLink,
                eosAccount: data?.eosAccount,
                phone: data?.phone,
                email: data?.email
            };
        } else if (props.type === 'winner') {
            const data = (query.data as ContactsApartmentWinnerQuery)?.apartment?.latestAuction?.winner;
            return {
                chatLink: data?.cryptochatDeepLink,
                eosAccount: data?.eosAccount,
                phone: data?.phone,
                email: data?.email
            };
        } else if (props.type === 'tenant') {
            const data = (query.data as ContactsApartmentTenantQuery)?.apartment?.actualRental?.tenant;
            return {
                chatLink: data?.cryptochatDeepLink,
                eosAccount: data?.eosAccount,
                phone: data?.phone,
                email: data?.email
            };
        } else if (props.type === 'buyer') {
            const data = (query.data as ContactsApartmentBuyerQuery)?.buyRequest?.buyer;
            return {
                chatLink: data?.cryptochatDeepLink,
                eosAccount: data?.eosAccount,
                phone: data?.phone,
                email: data?.email
            };
        } else {
            const data = (query.data as ContactsManagerQuery)?.city;
            return {
                chatLink: data?.notaryChatLink,
                eosAccount: data?.notaryEosAccount,
                phone: data?.notaryContactPhone,
                email: data?.notaryContactEmail
            };
        }
    }, [props.type, query.data]);

    return (
        <ModalBody>
            {query.loading ? (
                <ModalLoadingScreen />
            ) : error ? (
                <ErrorScreen isStatic text={error} resetError={() => query.refetch()} />
            ) : (
                <Fragment>
                    <Contacts className={cx('Contacts')} eosAccount={eosAccount} phone={phone} email={email} />
                    <ScanQRSection
                        qrData={chatLink!}
                        deepLink={chatLink!}
                        deepLinkText={t('modalContacts.btnDeepLink')}
                        text={
                            <Trans i18nKey="modalContacts.text">
                                <b />
                            </Trans>
                        }
                    />
                </Fragment>
            )}
        </ModalBody>
    );
};

export default ModalContacts;
