import React from 'react';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { useApartmentContetsPaymentDetailsQuery } from 'apollo/generted';
import useMeQuery from 'helpers/useMeQuery';
import { getErrorData, getErrorI18nText } from 'helpers';
import { useCopy, useStore } from 'helpers/hooks';
import { useModal } from 'helpers';
import { open as openSignModalAction } from 'store/SignModal/actions';
import { PAYCASH_LANDING } from 'constant';
import { IconInformationButton, IconWarningNew } from 'components/Icons';
import Modal, { ModalBody, ModalHeader, ModalProps } from 'components/UIKit/Modal';
import ModalErrorScreen from 'components/UIKit/Modal/ErrorScreen';
import ModalLoadingScreen from 'components/UIKit/Modal/LoadingScreen';
import TabSwitcher from 'components/UIKit/TabSwitcher';
import ButtonLink from 'components/UIKit/ButtonLink';
import Button from 'components/UIKit/Button';
import Alert from 'components/UIKit/Alert';
import ScanQRSection from 'components/ScanQRSection';
import ModalChangeEosAccount from '../ChangeEosAccount';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

export type Props = {
    id: number;
};

type Tab = 'qr' | 'requisites';

function ModalApartmentContest({ isOpen, close, ...props }: Props & ModalProps) {
    const [t] = useTranslation();

    return (
        <>
            <Modal isOpen={isOpen} close={close}>
                <ModalHeader>{t(`modalApartmentContest.title`)}</ModalHeader>
                <Main {...props} />
            </Modal>
        </>
    );
}

const Param = ({ label, copyValue, value }: { value: React.ReactNode; label: React.ReactNode; copyValue?: string }) => {
    const [t] = useTranslation();
    const copy = useCopy();

    return (
        <p className={cx('Param')}>
            {copyValue && (
                <ButtonLink
                    tag="button"
                    fontSize="small"
                    fontWeight="bold"
                    className={cx('ParamBtnCopy')}
                    onClick={() => copy(copyValue, t('global.copied'), t('error.globalError'))}
                >
                    {t('global.copy')}
                </ButtonLink>
            )}
            <span className={cx('ParamLabel')}>{label}</span>
            <b className={cx('ParamValue')}>{value}</b>
        </p>
    );
};

const Main = React.memo(({ id }: Props) => {
    const [t] = useTranslation();
    const isAuth = useStore((s) => s.Base.isAuth);
    const dispatch = useDispatch();
    const modalChangeEosAccount = useModal(false);

    const [tab, setTab] = React.useState<Tab>('qr');
    const [agree, setAgree] = React.useState(false);

    const hasEosAccount = !!useMeQuery().data?.me?.eosAccount;
    const [accountAgree, setAccountAgree] = React.useState(isAuth);
    const [eosAccountAgree, setEosAccountAgree] = React.useState(!isAuth || hasEosAccount);

    const query = useApartmentContetsPaymentDetailsQuery({ variables: { id } });
    const { paymentDeepLink, paymentInfo, paymentJson } = query.data?.apartment?.latestAuction ?? {};

    const { platformAccount, token, paymentAfterTax, memo, smartContract } = paymentInfo ?? {};
    const error = query.error && getErrorI18nText(t, getErrorData(query.error).message);

    const openSignModal = React.useCallback(() => dispatch(openSignModalAction()), [dispatch]);

    // Update state of agree screens
    React.useEffect(() => {
        setAccountAgree(isAuth);
        setEosAccountAgree(!isAuth || hasEosAccount);
    }, [isAuth, hasEosAccount]);

    if (query.loading) {
        return (
            <ModalBody>
                <ModalLoadingScreen />
            </ModalBody>
        );
    }

    if (error) {
        return (
            <ModalBody>
                <ModalErrorScreen text={error} resetError={() => query.refetch()} />
            </ModalBody>
        );
    }

    return (
        <div className={cx('Container')}>
            <TabSwitcher<Tab>
                onChange={setTab}
                activeType={tab}
                options={[
                    { type: 'qr', children: t('modalApartmentContest.type.qr') },
                    {
                        type: 'requisites',
                        children: t('modalApartmentContest.type.requisites')
                    }
                ]}
            />
            <ModalBody>
                {tab === 'qr' && (
                    <ScanQRSection
                        qrData={paymentJson!}
                        deepLinkText={t('modalApartmentContest.btnDeepLink')}
                        noAppLink={PAYCASH_LANDING}
                        deepLink={paymentDeepLink ?? ''}
                        text={
                            <Trans i18nKey="modalApartmentContest.qr">
                                <b>.</b>
                            </Trans>
                        }
                    />
                )}

                {tab === 'requisites' && (
                    <>
                        <p className={cx('Text')}>
                            <Trans i18nKey="modalApartmentContest.requisites">
                                <b>.</b>
                            </Trans>
                        </p>

                        <Alert className={cx('Alert')}>
                            {t('modalApartmentContest.requisites', { context: 'hint' })}
                        </Alert>

                        <div className={cx('ParamGroup')}>
                            <Param
                                label={t('modalApartmentContest.params.receiver')}
                                value={platformAccount}
                                copyValue={platformAccount || ''}
                            />
                            <Param
                                label={t('modalApartmentContest.params.amount')}
                                value={`${paymentAfterTax} ${token} (${smartContract})`}
                                copyValue={paymentAfterTax || ''}
                            />
                            <Param label={t('modalApartmentContest.params.memo')} value={memo} copyValue={memo || ''} />
                        </div>

                        {!agree && (
                            <div className={cx('Agreement')}>
                                <div className={cx('AgreementContent')}>
                                    <IconWarningNew className={cx('AgreementIcon', 'red')} />
                                    <h6>{t('modalApartmentContest.agreement.title')}</h6>
                                    <p>{t('modalApartmentContest.agreement.text')}</p>
                                </div>

                                <div className={cx('AgreementBtnGroup')}>
                                    <Button color="primary" onClick={() => setAgree(true)}>
                                        {t('modalApartmentContest.agreement.btn')}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </>
                )}

                <ModalChangeEosAccount isOpen={modalChangeEosAccount.isOpen} close={modalChangeEosAccount.close} />
            </ModalBody>

            {!accountAgree && (
                <div className={cx('Agreement')}>
                    <div className={cx('AgreementContent')}>
                        <IconInformationButton className={cx('AgreementIcon', 'grey')} />
                        <h6>{t('modalApartmentContest.guest.title')}</h6>

                        <p>{t('modalApartmentContest.guest.text')}</p>
                    </div>

                    <div className={cx('AgreementBtnGroup')}>
                        <Button color="primary" onClick={openSignModal}>
                            {t('modalApartmentContest.guest.btn')}
                        </Button>
                        <ButtonLink
                            fontSize="normal"
                            fontWeight="bold"
                            className={cx('AgreementBtn_center')}
                            onClick={() => setAccountAgree(true)}
                        >
                            {t('modalApartmentContest.guest.btnSkip')}
                        </ButtonLink>
                    </div>
                </div>
            )}

            {!eosAccountAgree && (
                <div className={cx('Agreement')}>
                    <div className={cx('AgreementContent')}>
                        <IconInformationButton className={cx('AgreementIcon', 'grey')} />
                        <h6>{t('modalApartmentContest.eos.title')}</h6>

                        <p>{t('modalApartmentContest.eos.text')}</p>
                    </div>

                    <div className={cx('AgreementBtnGroup')}>
                        <Button color="primary" onClick={modalChangeEosAccount.open}>
                            {t('modalApartmentContest.eos.btn')}
                        </Button>
                        <ButtonLink
                            fontSize="normal"
                            fontWeight="bold"
                            className={cx('AgreementBtn_center')}
                            onClick={() => setEosAccountAgree(true)}
                        >
                            {t('modalApartmentContest.eos.btnSkip')}
                        </ButtonLink>
                    </div>
                </div>
            )}
        </div>
    );
});

export default ModalApartmentContest;
