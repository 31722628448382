import React from 'react';
import { usePopper } from 'react-popper';
import classNames from 'classnames/bind';
import { useIsTouchableDevice } from 'helpers/hooks';
import { TooltipProps, PopperOptions } from './Tooltip.d';
import styles from './Tooltip.module.scss';

const cx = classNames.bind(styles);

export const useTooltip = () => {
    const [popperReference, setPopperReference] = React.useState<HTMLElement | null>(null);
    const [isActive, setIsActive] = React.useState(false);
    const isTouchable = useIsTouchableDevice();

    React.useEffect(() => {
        if (isTouchable && isActive) {
            const mousedownListener = (e: MouseEvent) => {
                if (popperReference && e.target && !popperReference.contains(e.target as Node)) {
                    document.removeEventListener('mousedown', mousedownListener);
                    setIsActive(false);
                }
            };

            document.addEventListener('mousedown', mousedownListener);

            return () => {
                document.removeEventListener('mousedown', mousedownListener);
            };
        }
    }, [isActive, popperReference, isTouchable]);

    return {
        setPopperReference,
        popperReference,
        isActive,
        setShow: setIsActive,
        show: () => setIsActive(true),
        hide: () => setIsActive(false)
    };
};

/**
 * @todo fix bug with invalid position of tooltip on mount, and then return animation
 */
const Tooltip = ({ popperReference, popperOptions, children, className = '', isActive }: TooltipProps) => {
    const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(null);
    const [arrowElement, setArrowElement] = React.useState<HTMLElement | null>(null);

    const defaultConfig: PopperOptions = {
        placement: 'bottom',
        modifiers: [
            { name: 'preventOverflow' },
            { name: 'arrow', options: { element: arrowElement } },
            {
                name: 'offset',
                options: {
                    offset: [0, 10]
                }
            }
        ]
    };

    const { styles: popperStyles, attributes: popperAttributes } = usePopper(
        popperReference,
        popperElement,
        popperOptions?.(defaultConfig) ?? defaultConfig
    );

    return (
        <div
            style={popperStyles.popper}
            {...popperAttributes.popper}
            ref={setPopperElement}
            className={cx('Tooltip', className, isActive && 'show')}
        >
            <div className={cx('Content')}>{children}</div>
            <span ref={setArrowElement} style={popperStyles.arrow} className={cx('TooltipArrow')} />
        </div>
    );
};

export default Tooltip;
