import {
    ApartmentNode,
    useConfirmReadinessAsWinnerMutation,
    useContestWinnerAgreementParamsQuery
} from 'apollo/generted';
import classNames from 'classnames/bind';
import Modal, {
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalProps,
    ModalText,
    useModalContext
} from 'components/UIKit/Modal';
import React, { Fragment, memo, useState } from 'react';
import Button from 'components/UIKit/Button';
import CheckboxField from 'components/UIKit/CheckboxField';
import ScanQRSection from 'components/ScanQRSection';
import { Trans, useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { addSpacesForThousands, getErrorData, getErrorI18nText } from 'helpers';
import ModalLoadingScreen from 'components/UIKit/Modal/LoadingScreen';
import ModalErrorScreen from 'components/UIKit/Modal/ErrorScreen';
import { useToast } from 'helpers/hooks';

const cx = classNames.bind(styles);

export type Props = {
    aptId?: ApartmentNode['id'];
};

const ModalContestWinnerAgreement = memo(({ isOpen, close, ...props }: Props & ModalProps) => {
    const [t] = useTranslation();
    return (
        <Modal close={close} isOpen={isOpen}>
            <ModalHeader>{t('modalContestWinnerAgreement.title')}</ModalHeader>
            <Main {...props} />
        </Modal>
    );
});

const Main = memo(({ aptId }: Props) => {
    const [t] = useTranslation();
    const { close } = useModalContext();
    const [agree, setAgree] = useState(false);
    const [formError, setFormError] = useState('');
    const [step, setStep] = useState<'agree' | 'code'>('agree');
    const toast = useToast();

    const [confirmReadinessAsWinnerMutation, { loading: loadingMutation }] = useConfirmReadinessAsWinnerMutation();

    const { loading, error, data, refetch } = useContestWinnerAgreementParamsQuery({
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        variables: {
            id: aptId!
        }
    });
    const queryErrorText = error && getErrorI18nText(t, getErrorData(error).message);

    if (loading)
        return (
            <ModalBody>
                <ModalLoadingScreen />
            </ModalBody>
        );

    if (queryErrorText)
        return (
            <ModalBody>
                <ModalErrorScreen isStatic text={queryErrorText} resetError={() => refetch()} />
            </ModalBody>
        );

    const { apartment, aptExpenses } = data ?? {};
    const currency = apartment?.communalCurrency ?? '';
    const deepLink = apartment?.tokenizationInfo?.winnerSignDeepLink;

    const onClickSubmit = () => {
        if (apartment?.isTokenized) {
            confirmReadinessAsWinnerMutation({
                variables: {
                    input: {
                        aptId: aptId!
                    }
                }
            })
                .then(({ data }) => {
                    const confirmReadinessAsWinner = data?.confirmReadinessAsWinner!;
                    if (confirmReadinessAsWinner.__typename === 'ConfirmReadinessAsWinnerSuccess') {
                        toast.success(t('modalContestWinnerAgreement.successMsg'));
                        close();
                    } else {
                        setFormError(
                            t([
                                `modalContestWinnerAgreement.error.${confirmReadinessAsWinner?.__typename}`,
                                getErrorI18nText(t, confirmReadinessAsWinner?.errorMessage)
                            ])
                        );
                    }
                })
                .catch((e) => {
                    setFormError(getErrorI18nText(t, getErrorData(e).message));
                });
        } else {
            setStep('code');
        }
    };

    const descriptionText = t('modalContestWinnerAgreement.description', {
        returnObjects: true
    }) as string[];

    if (step === 'code') {
        return (
            <ModalBody>
                <ScanQRSection
                    qrData={deepLink!}
                    deepLink={deepLink!}
                    deepLinkText={t('modalContestWinnerAgreement.btnSubmit')}
                    text={
                        <Trans i18nKey="modalContestWinnerAgreement.qr">
                            <b />
                        </Trans>
                    }
                />
            </ModalBody>
        );
    } else {
        return (
            <Fragment>
                <ModalBody>
                    <ModalContent withBorder>
                        {descriptionText.map((description, i) => (
                            <ModalText key={i}>{description}</ModalText>
                        ))}

                        {apartment?.totalExpenses && (
                            <ul className={cx('Details')}>
                                {apartment?.communalPayment && (
                                    <li>
                                        <span>{t('apartmentStats.communalPayment.label')}</span>
                                        <span>
                                            {addSpacesForThousands(apartment?.communalPayment)} {currency}
                                        </span>
                                    </li>
                                )}

                                {aptExpenses?.map((item) => (
                                    <li key={item?.expense.id}>
                                        <span>{item?.expense.name}</span>
                                        <span>
                                            {addSpacesForThousands(item?.value?.toString())} {currency}
                                        </span>
                                    </li>
                                ))}

                                <li className={cx('big')}>
                                    <span>{t('modalContestWinnerAgreement.totalExpenses')}</span>
                                    <span>
                                        {addSpacesForThousands(apartment?.totalExpenses)} {currency}
                                    </span>
                                </li>
                            </ul>
                        )}
                    </ModalContent>
                    <CheckboxField
                        label={t('modalContestWinnerAgreement.agree')}
                        checked={agree}
                        onChange={(e) => setAgree(e.target.checked)}
                    />

                    <Button
                        type="button"
                        loading={loadingMutation}
                        disabled={!agree}
                        color="primary"
                        onClick={onClickSubmit}
                    >
                        {t('modalContestWinnerAgreement.btnSubmit', {
                            context: apartment?.isTokenized ? '' : 'showQr'
                        })}
                    </Button>
                </ModalBody>

                <ModalErrorScreen text={formError} resetError={() => setFormError('')} />
            </Fragment>
        );
    }
});

export default ModalContestWinnerAgreement;
