import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import BtnFavorite, { Props as BtnFavoriteProps } from './BtnFavorite';
import BtnShare, { Props as BtnShareProps } from './BtnShare';
const cx = classNames.bind(styles);

type Props = BtnShareProps & BtnFavoriteProps & { className?: string };

const UserActions = ({ className = '', id, address, isFavorite, photo }: Props) => {
    return (
        <div className={cx('Component', className)}>
            <BtnShare id={id} address={address} photo={photo} />
            <BtnFavorite id={id} isFavorite={isFavorite} />
        </div>
    );
};

export default UserActions;
