/**
 * New password validator
 * Helps on registration page and change password page
 *
 * Main rules:
 * - min length is 8 symbols
 * - min one letter
 * - min one digit
 *
 * @param p - password string
 */
// TODO: Change export
// eslint-disable-next-line import/no-anonymous-default-export
export default (p: string) => {
    const minPasswordLength = !!p && p.toString().length >= 8;
    const lettersPassword = !!p && /[а-яёa-z]/i.test(p.toString());
    const digitsPassword = !!p && /\d/.test(p.toString());

    return {
        minPasswordLength,
        lettersPassword,
        digitsPassword,
        isValid: minPasswordLength && lettersPassword && digitsPassword
    };
};
