import { WalletName } from 'apollo/generted';
import classNames from 'classnames/bind';
import Button from 'components/UIKit/Button';
import ButtonLink from 'components/UIKit/ButtonLink';
import { ModalBody, ModalFooter, ModalHeader } from 'components/UIKit/Modal';
import { EOS_WALLET_LIST } from 'constant';
import WalletAuthScreen from 'containers/Forms/WalletAuthScreen';
import DesktopWalletAuthForm from 'containers/Forms/WalletAuthScreen/Desktop';
import MobileWalletAuthForm from 'containers/Forms/WalletAuthScreen/Mobile';
import { isMobilePlatform } from 'helpers';
import React, { Fragment, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { OnAuth } from '..';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

const ApplicationAuthScreen = ({
    onClickBtnAlternate,
    onAuth,
    onError
}: {
    onClickBtnAlternate(): void;
    onAuth: OnAuth;
    onError(v: string): void;
}) => {
    const [t] = useTranslation();
    const isMobile = useMemo(isMobilePlatform, []);
    const [showWalletQR, setShowWalletQR] = useState<null | WalletName>(null);

    if (showWalletQR)
        return (
            <Fragment>
                <ModalHeader onClickBack={() => setShowWalletQR(null)}>
                    {EOS_WALLET_LIST.find(({ type }) => type === showWalletQR)?.label} QR
                </ModalHeader>

                <ModalBody>
                    <WalletAuthScreen
                        description={
                            <Trans i18nKey="modalSign.applicationAuthScreen.text">
                                <b />
                            </Trans>
                        }
                        wallet={showWalletQR}
                        onError={onError}
                        onAuth={onAuth}
                    />
                </ModalBody>
            </Fragment>
        );

    if (isMobile) {
        return (
            <Fragment>
                <ModalHeader>{t('modalSign.applicationAuthScreen.title', { context: 'mobile' })}</ModalHeader>

                <ModalBody>
                    <p className={cx('Description')}>{t('modalSign.applicationAuthScreen.description')}</p>

                    <MobileWalletAuthForm onError={onError} onAuth={onAuth} onClickShowQR={setShowWalletQR} />

                    <div className={cx('OrDivider')}>{t('modalSign.orDivider')}</div>

                    <Button onClick={onClickBtnAlternate} type="button" color="tertiary-grey">
                        {t('modalSign.applicationAuthScreen.btnAlternate')}
                    </Button>
                </ModalBody>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <ModalHeader>{t('modalSign.applicationAuthScreen.title')}</ModalHeader>

            <DesktopWalletAuthForm
                description={
                    <Trans i18nKey="modalSign.applicationAuthScreen.text">
                        <b />
                    </Trans>
                }
                onAuth={onAuth}
                onError={onError}
            />

            <ModalFooter className={cx('ModalFooter')}>
                <ButtonLink fontWeight="bold" fontSize="big" onClick={onClickBtnAlternate} type="button">
                    {t('modalSign.applicationAuthScreen.btnAlternate')}
                </ButtonLink>
            </ModalFooter>
        </Fragment>
    );
};

export default ApplicationAuthScreen;
