import { useVerifyCodeMutation } from 'apollo/generted';
import classNames from 'classnames/bind';
import Button from 'components/UIKit/Button';
import { OnAuth } from 'containers/Modals/Sign';
import { constructAuthHeader, getErrorData, getErrorI18nText } from 'helpers';
import { useFormField } from 'helpers/hooks';
import React, { memo, ReactNode, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import CodeInput from './CodeInput';
import styles from './index.module.scss';
import ResendCodeBtn from './ResendCodeBtn';

const cx = classNames.bind(styles);

type Props = {
    className?: string;
    description?: ReactNode;
    verifyToken: string;
    /** @default true
     * if `false` passed - hide resend button
     */
    hasAvailableCode?: boolean;
    onSuccess: OnAuth;
    onSuccessResendCode(verifyToken: string): void;
    onUnexpectedError?(v: string): void;
    onBlock: (msg?: string) => void;
};

/**
 * Form, that allow to verify user by code
 * Based on verifyCode mutation
 */
const FormCodeVerification = memo(
    ({
        description,
        onSuccess,
        verifyToken,
        onBlock,
        className = '',
        onSuccessResendCode,
        onUnexpectedError
    }: Props) => {
        const codeField = useFormField('');
        const [t] = useTranslation();
        const [verifyCodeMutation, { loading: loadingVerifyCode }] = useVerifyCodeMutation();
        const inputFieldRef = useRef<HTMLDivElement>(null);
        const formRef = useRef<HTMLFormElement>(null);

        async function onSubmit(e?: React.FormEvent) {
            e?.preventDefault();
            codeField.changeError('');

            verifyCodeMutation({
                context: verifyToken ? { headers: constructAuthHeader(verifyToken) } : undefined,
                variables: { input: { code: codeField.value } }
            })
                .then(({ data }) => {
                    const verifyCode = data?.verifyCode;

                    if (verifyCode && verifyCode.__typename === 'VerifyCodeSuccess') {
                        onSuccess(verifyCode.token);
                    } else {
                        const { __typename, errorMessage = '' } = verifyCode ?? {};

                        if (__typename === 'InvalidVerifyCodeError' || __typename === 'VerifyCodeExpiredError') {
                            codeField.changeError(t([`formCodeVerification.error.${__typename}`, errorMessage]));
                        } else if (__typename === 'VerifyCodeNoCheckAttemptsError') {
                            (onUnexpectedError ?? codeField.changeError)(
                                t([`formCodeVerification.error.${__typename}`, getErrorI18nText(t, errorMessage)])
                            );
                        } else {
                            (onUnexpectedError ?? codeField.changeError)(
                                t([`formCodeVerification.error.${__typename}`, getErrorI18nText(t, errorMessage)])
                            );
                        }
                    }
                })
                .catch((e) => {
                    (onUnexpectedError ?? codeField.changeError)(getErrorI18nText(t, getErrorData(e).message));
                });
        }

        const handleSuccessResendCode = () => {
            const input = formRef.current?.querySelectorAll('input')[0] ?? null;

            codeField.changeError('');
            codeField.change('');
            input?.focus();

            return onSuccessResendCode;
        };

        useEffect(() => {
            if (!loadingVerifyCode) {
                const inputs = inputFieldRef.current?.querySelectorAll('input') ?? [];
                const targetInput = inputs[codeField.value.length ? inputs.length - 1 : 0];

                targetInput?.focus();
                targetInput?.select();
            }
            // TODO: Fix exhaustive deps
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [loadingVerifyCode]);

        return (
            <form className={cx('Form', className)} onSubmit={onSubmit} ref={formRef}>
                {description && <p className={cx('Description')}>{description}</p>}
                <div className={cx('Field')}>
                    <label>{t('formCodeVerification.field')}</label>
                    <CodeInput
                        name="code"
                        inputMode="numeric"
                        error={!!codeField.error}
                        value={codeField.value}
                        fields={6}
                        onChange={codeField.change}
                    />

                    {codeField.error && <p className={cx('FieldError')}>{codeField.error}</p>}
                </div>

                <Button
                    color="primary"
                    className={cx('BtnSubmit')}
                    loading={loadingVerifyCode}
                    disabled={codeField.value.length < 6}
                    type="submit"
                >
                    {t('formCodeVerification.submitBtn')}
                </Button>

                <ResendCodeBtn
                    onSuccess={handleSuccessResendCode}
                    onUnexpectedError={onUnexpectedError}
                    verificationToken={verifyToken}
                    onBlock={onBlock}
                    // stopTrigger={loadingVerifyCode}
                />
            </form>
        );
    }
);

export default FormCodeVerification;
