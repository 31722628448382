import { ApartmentNode, AptReviewNode, AuctionNode, LockNode, LockReason, Maybe, RentalNode } from 'apollo/generted';
import classNames from 'classnames/bind';
import { ApartmentData } from 'components/ApartmentCard/ApartmentCard.d';
import { useConstructApartmentStatus, Data } from 'helpers/useConstructApartmentStatus';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

type Props = Pick<ApartmentData, 'mode'> & {
    className?: string;
    data?: Data &
        Pick<ApartmentNode, 'isTokenized'> & {
            latestAuction?: Maybe<Pick<AuctionNode, 'hasMyBid' | 'isIAmWinner' | 'isWinnerAgree'>>;
            actualRental?: Maybe<Pick<RentalNode, 'isIAmTenant' | 'isExpired'>>;
            review?: Maybe<Pick<AptReviewNode, 'errorsCount'>>;
            lock?: Maybe<Pick<LockNode, 'reasonType' | 'reason'>>;
        };
};

const StatusDescription = ({ className = '', mode, data }: Props) => {
    const { isContestFinished, inContest, isRented, isWinnerSelectionInProgress, hasNoWinners, isSold, isLocked } =
        useConstructApartmentStatus(data!);
    const [t] = useTranslation();

    const { hasMyBid, isIAmWinner, isWinnerAgree } = data?.latestAuction ?? {};
    const { isIAmTenant, isExpired } = data?.actualRental ?? {};
    const { reasonType, reason } = data?.lock ?? {};
    const isOwner = mode === 'OWNER';
    const isPublic = mode === 'PUBLIC';

    const lockReasonDescription =
        reasonType === LockReason.Custom ? reason : t(`apartmentStats.lock.reason.${reasonType}`);

    return (
        <p className={cx('Description', className)}>
            {!isLocked
                ? t(`apartmentStats.status.${data?.status!}_description`, {
                      count: isOwner ? data?.review?.errorsCount ?? 0 : undefined,
                      context:
                          isPublic && inContest && !hasMyBid
                              ? 'guest'
                              : isWinnerSelectionInProgress
                              ? 'waiting'
                              : isPublic && hasNoWinners
                              ? 'hasNoWinners'
                              : isOwner && (inContest || isContestFinished)
                              ? 'owner'
                              : !isOwner && isContestFinished && isIAmWinner
                              ? !isWinnerAgree
                                  ? 'isWaitingWinnerAgree'
                                  : data?.isTokenized
                                  ? 'isWaitingWinnerPayment'
                                  : 'isWaitingTokenization'
                              : isRented && isIAmTenant && isExpired
                              ? 'expired'
                              : isSold && isIAmTenant
                              ? 'tenant'
                              : ''
                  })
                : lockReasonDescription}
        </p>
    );
};

export default StatusDescription;
