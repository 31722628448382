import { useMeWsSubscription } from 'apollo/generted';
import { useStore } from 'helpers/hooks';
import { memo } from 'react';

const Subscribes = memo(() => {
    const { isAuth } = useStore((s) => s.Base);

    useMeWsSubscription({
        skip: !isAuth
    });

    return null;
});

export default Subscribes;
