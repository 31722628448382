import React, { PropsWithChildren, ReactNode } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

const Info = ({
    className = '',
    title,
    children
}: PropsWithChildren<{
    className?: string;
    title?: ReactNode;
}>) => {
    return (
        <section className={cx('Info', className)}>
            <h6 className={cx('InfoTitle')}>{title}</h6>

            {children}
        </section>
    );
};

export default Info;
