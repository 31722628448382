import {
    CityNode,
    Lang,
    ManagerDocumentFragment,
    useAddRegionDocumentMutation,
    useUpdateRegionDocumentMutation
} from 'apollo/generted';
import classNames from 'classnames/bind';
import { IconAngleDown } from 'components/Icons';
import Button from 'components/UIKit/Button';
import ButtonLink from 'components/UIKit/ButtonLink';
import Modal, { ModalBody, ModalHeader, ModalProps, useModalContext } from 'components/UIKit/Modal';
import ModalErrorScreen from 'components/UIKit/Modal/ErrorScreen';
import TextField from 'components/UIKit/TextField';
import { getErrorData, getErrorI18nText } from 'helpers';
import { useDropdown, useToast } from 'helpers/hooks';
import validator from 'helpers/validator';
import update from 'immutability-helper';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

export type Props = {
    city: Pick<CityNode, 'id' | 'locales'>;
    document?: ManagerDocumentFragment;
    onSuccess(v: ManagerDocumentFragment): void;
};

const ModalRegionDocuments = ({ city, onSuccess, document, ...props }: Props & ModalProps) => {
    return (
        <Modal {...props}>
            <Main city={city} onSuccess={onSuccess} document={document} />
        </Modal>
    );
};

type FieldProps = {
    language: Lang;
    value: string;
    error?: string;
    onChange(v: string): void;
    onClickRemove?: () => void;
    onChangeLanguage?: (lang: Lang) => void;
    isLanguageLocked?: boolean;
    leftLanguages?: Lang[];
};

const Field = ({
    language,
    value,
    isLanguageLocked,
    onClickRemove,
    onChange,
    error,
    leftLanguages,
    onChangeLanguage
}: FieldProps) => {
    const id = useMemo(() => Math.random().toString(), []);
    const [t] = useTranslation();
    const dropdown = useDropdown();
    const disabledLanguageControl = isLanguageLocked || !leftLanguages?.length;

    return (
        <div className={cx('FieldWrapper')}>
            <div className={cx('FieldLabelRow')}>
                <label htmlFor={id}>{t('modalRegionDocuments.fieldLabel')}</label>

                {onClickRemove && (
                    <ButtonLink type="button" onClick={onClickRemove}>
                        {t('modalRegionDocuments.btnRemove')}
                    </ButtonLink>
                )}
            </div>
            <div className={cx('Field')}>
                <div ref={dropdown.setRef} className={cx('FieldLanguage')}>
                    <button
                        onClick={dropdown.toggle}
                        className={cx('FieldLanguageControl')}
                        disabled={disabledLanguageControl}
                    >
                        <span>{language}</span>
                        {!disabledLanguageControl && <IconAngleDown />}
                    </button>
                    {!disabledLanguageControl && (
                        <div
                            ref={dropdown.setPopperRef}
                            {...dropdown.popper.attributes.popper}
                            style={dropdown.popper.styles.popper}
                            className={cx('FieldLanguageContent', { open: dropdown.open })}
                        >
                            {leftLanguages?.map((language) => (
                                <button key={language} onClick={() => onChangeLanguage?.(language)}>
                                    {language}
                                </button>
                            ))}
                        </div>
                    )}
                </div>

                <TextField
                    id={id}
                    error={error}
                    value={value}
                    maxLength={150}
                    onChange={(e) => onChange(validator(e, value))}
                    containerClassName={cx('TextFieldContainer')}
                    fieldClassName={cx('TextField')}
                />
            </div>
        </div>
    );
};

type FieldValue = {
    language: Lang;
    value: string;
    isLanguageLocked: boolean;
};

const Main = ({ city, onSuccess, document }: Props) => {
    const { close } = useModalContext();
    const [t] = useTranslation();
    const [fieldValues, setFieldValues] = useState<FieldValue[]>([]);
    const [updateRegionDocumentMutation, { loading: loadingUpdate }] = useUpdateRegionDocumentMutation();
    const [addRegionDocumentMutation, { loading: loadingAdd }] = useAddRegionDocumentMutation();
    const [formError, setFormError] = useState('');

    const toast = useToast();

    const loading = loadingUpdate || loadingAdd;

    useEffect(() => {
        const cityLocaleFields =
            city.locales?.map((language) => ({
                language: language!,
                value: document ? document.locales?.find((locale) => locale?.lang === language)?.name ?? '' : '',
                isLanguageLocked: true
            })) ?? [];

        const additionalLocales =
            document?.locales
                ?.filter((locale) => !city.locales?.find((cityLocale) => cityLocale === locale?.lang!))
                .map((locale) => ({
                    language: locale!.lang!,
                    value: locale!.name!,
                    isLanguageLocked: false
                })) ?? [];

        setFieldValues([...cityLocaleFields, ...additionalLocales]);
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const disabled = fieldValues.every(({ value }) => !value) || loading;

    const leftLanguages = Object.values(Lang).filter(
        (language) => !fieldValues.some((field) => field.language === language)
    );

    const onChangeValue = (newValue: string, index: number) => {
        setFieldValues(
            update(fieldValues, {
                [index]: {
                    value: () => newValue
                }
            })
        );
    };

    const onChangeLanguage = (newLang: Lang, index: number) => {
        setFieldValues(
            update(fieldValues, {
                [index]: {
                    language: () => newLang
                }
            })
        );
    };

    const removeField = (index: number) => {
        setFieldValues(fieldValues.filter((_, i) => i !== index));
    };

    const addNewField = () => {
        setFieldValues([...fieldValues, { language: leftLanguages[0], value: '', isLanguageLocked: false }]);
    };

    const onSubmit = () => {
        if (document) {
            updateRegionDocumentMutation({
                variables: {
                    input: {
                        regionDocumentId: document.id,
                        locales: fieldValues
                            .filter(({ value }) => !!value)
                            .map((field) => ({
                                lang: field.language,
                                name: field.value
                            }))
                    }
                }
            })
                .then(({ data }) => {
                    const updateRegionDocument = data?.updateRegionDocument! ?? {};
                    if (updateRegionDocument.__typename === 'UpdateRegionDocumentSuccess') {
                        toast.success(t('modalRegionDocuments.successMsg', { context: 'edit' }));
                        onSuccess(updateRegionDocument.regionDocument);
                        close();
                    } else {
                        setFormError(
                            t([
                                `modalRegionDocuments.error.${updateRegionDocument?.__typename}`,
                                getErrorI18nText(t, updateRegionDocument?.errorMessage)
                            ])
                        );
                    }
                })
                .catch((e) => {
                    setFormError(getErrorI18nText(t, getErrorData(e).message));
                });
        } else {
            addRegionDocumentMutation({
                variables: {
                    input: {
                        cityId: city.id!,
                        locales: fieldValues
                            .filter(({ value }) => !!value)
                            .map((field) => ({
                                lang: field.language,
                                name: field.value
                            }))
                    }
                }
            })
                .then(({ data }) => {
                    const addRegionDocument = data?.addRegionDocument! ?? {};
                    if (addRegionDocument.__typename === 'AddRegionDocumentSuccess') {
                        toast.success(t('modalRegionDocuments.successMsg', { context: 'add' }));
                        onSuccess(addRegionDocument.regionDocument);
                        close();
                    } else {
                        setFormError(
                            t([
                                `modalRegionDocuments.error.${addRegionDocument?.__typename}`,
                                getErrorI18nText(t, addRegionDocument?.errorMessage)
                            ])
                        );
                    }
                })
                .catch((e) => {
                    setFormError(getErrorI18nText(t, getErrorData(e).message));
                });
        }
    };

    return (
        <Fragment>
            <ModalHeader>
                {t('modalRegionDocuments.title', {
                    context: document ? 'edit' : 'add'
                })}
            </ModalHeader>
            <ModalBody>
                <p className={cx('Description')}>{t('modalRegionDocuments.description')}</p>

                <div className={cx('FieldGroup')}>
                    {fieldValues.map(({ language, value, isLanguageLocked }, i) => {
                        return (
                            <Field
                                language={language!}
                                key={language}
                                value={value}
                                onChange={(value) => onChangeValue(value, i)}
                                isLanguageLocked={isLanguageLocked}
                                leftLanguages={leftLanguages}
                                onChangeLanguage={(lang) => onChangeLanguage(lang, i)}
                                onClickRemove={isLanguageLocked ? undefined : () => removeField(i)}
                            />
                        );
                    })}
                </div>

                <div className={cx('BtnGroup')}>
                    {leftLanguages.length > 0 && (
                        <Button onClick={addNewField} color="tertiary">
                            {t('modalRegionDocuments.btnAddLanguage')}
                        </Button>
                    )}
                    <Button onClick={onSubmit} type="button" loading={loading} color="primary" disabled={disabled}>
                        {t('modalRegionDocuments.btnSubmit')}
                    </Button>
                </div>
            </ModalBody>
            <ModalErrorScreen text={formError} resetError={() => setFormError('')} />
        </Fragment>
    );
};

export default ModalRegionDocuments;
