import { useStore } from './hooks';
import { useDispatch } from 'react-redux';
import React, { useCallback } from 'react';
import { open as openSignModalAction } from 'store/SignModal/actions';

export const useCheckRequiredAuth = () => {
    const isAuth = useStore((s) => s.Base.isAuth);
    const dispatch = useDispatch();

    return useCallback(
        (e: React.MouseEvent) => {
            if (!isAuth) {
                e.preventDefault();
                dispatch(openSignModalAction());
            }
        },
        [dispatch, isAuth]
    );
};

// TODO: Change export
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const isAuth = useStore((s) => s.Base.isAuth);
    const dispatch = useDispatch();

    return useCallback(
        (callback) => {
            if (!isAuth) {
                dispatch(openSignModalAction());
            } else {
                callback();
            }
        },
        [dispatch, isAuth]
    );
};
