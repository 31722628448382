import { useConnectByWalletMutation, useConnectByWalletWsSubscription, WalletName } from 'apollo/generted';
import classNames from 'classnames/bind';
import { IconQrCode } from 'components/Icons';
import Button from 'components/UIKit/Button';
import { EOS_WALLET_LIST } from 'constant';
import { OnAuth } from 'containers/Modals/Sign';
import { getErrorData, getErrorI18nText } from 'helpers';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

const MobileWalletAuthForm = ({
    onError,
    onClickShowQR,
    onAuth,
    className = ''
}: {
    onError: (v: string) => void;
    onAuth: OnAuth;
    onClickShowQR(wallet: WalletName): void;
    className?: string;
}) => {
    const [t] = useTranslation();
    const [connectByWalletMutation, paycashAppAuthResult] = useConnectByWalletMutation();

    const [paycashVerificationCode, setPaycashVerificationCode] = useState('');

    /**
     * After generating QR code and verificationCode
     * awaiting response from server
     */
    useConnectByWalletWsSubscription({
        skip: !paycashVerificationCode,
        variables: { verificationCode: paycashVerificationCode },
        onSubscriptionData: ({ subscriptionData: { data } }) => {
            const connectByWallet = data?.connectByWallet! ?? {};

            if (connectByWallet.__typename === 'WalletConnectionSuccess') {
                onAuth(connectByWallet.token!);
            } else {
                const { errorMessage = '', __typename } = connectByWallet;

                onError(t([`formEosAuth.error.${__typename}`, getErrorI18nText(t, errorMessage)]));
            }
        }
    });

    /**
     * Generate QR code and verificationCode for selected `wallet` type
     */
    const conntectByPaycash = () => {
        setPaycashVerificationCode('');
        connectByWalletMutation({
            variables: { input: { walletName: WalletName.Paycash } }
        })
            .then(({ data }) => {
                const connectByWallet = data?.connectByWallet! ?? {};
                if (connectByWallet.__typename === 'ConnectByWalletSuccess') {
                    setPaycashVerificationCode(connectByWallet.verificationCode || '');
                    window.location.assign(connectByWallet.deepLink!);
                } else {
                    onError(
                        t([
                            `formEosAuth.error.${connectByWallet?.__typename}`,
                            getErrorI18nText(t, connectByWallet?.errorMessage)
                        ])
                    );
                }
            })
            .catch((e) => onError(getErrorI18nText(t, getErrorData(e).message)));
    };

    return (
        <div className={cx('MobileForm', className)}>
            {EOS_WALLET_LIST.map(({ label, type, logo }) => {
                if (type === WalletName.Paycash) {
                    return (
                        <div key={type} className={cx('MobileFormBtnGroup')}>
                            <Button
                                type="button"
                                color="secondary-grey"
                                className={cx('fluid')}
                                onClick={conntectByPaycash}
                                loading={paycashAppAuthResult.loading}
                            >
                                <img className={cx('WalletLogo')} src={logo} alt="" />
                                {label}
                            </Button>

                            <Button
                                type="button"
                                onClick={() => onClickShowQR(type)}
                                color="secondary-grey"
                                iconRight={<IconQrCode />}
                            />
                        </div>
                    );
                }

                return (
                    <Button color="tertiary-grey" key={type} onClick={() => onClickShowQR(type)} type="button">
                        <img className={cx('WalletLogo')} src={logo} alt="" />
                        {label} QR
                    </Button>
                );
            })}
        </div>
    );
};

export default MobileWalletAuthForm;
