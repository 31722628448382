import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { useApartmentSignTokenizationParamsQuery } from 'apollo/generted';
import { getErrorI18nText, getErrorData } from 'helpers';
import useMeQuery from 'helpers/useMeQuery';
import { useConstructAddress } from 'helpers/useParsedApartmentData';
import Modal, {
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalProps,
    ModalText,
    ModalTextParam
} from 'components/UIKit/Modal';
import Button from 'components/UIKit/Button';
import ModalErrorScreen from 'components/UIKit/Modal/ErrorScreen';
import ModalLoadingScreen from 'components/UIKit/Modal/LoadingScreen';
import CheckboxField from 'components/UIKit/CheckboxField';
import { IconCheckmark, IconError } from 'components/Icons';
import ScanQRSection from 'components/ScanQRSection';
import { ApartmentSignTokenizationProps } from './ApartmentSignTokenization.d';
import styles from './ApartmentSignTokenization.module.scss';

const cx = classNames.bind(styles);

const Main = ({ type, aptId }: ApartmentSignTokenizationProps) => {
    const [t] = useTranslation();
    const [agree, setAgree] = React.useState(false);
    const [showQr, setShowQr] = React.useState(false);
    const constructAddress = useConstructAddress();
    const myEosAccount = useMeQuery().data?.me?.eosAccount;
    const query = useApartmentSignTokenizationParamsQuery({
        variables: { id: aptId },
        fetchPolicy: 'cache-and-network'
    });

    const isNotaryView = type === 'notary';
    const meText = t('modalApartmentSignTokenization.me');
    const apartment = query.data?.apartment;
    const notaryEos = apartment?.city?.notaryEosAccount;
    const winnerEos = apartment?.latestAuction?.winnerAccount;
    const ownerEos = apartment?.ownerAccount;

    const { isSignedByOwner, isSignedByWinner, isSignedByNotary, ownerSignDeepLink, notarySignDeepLink } =
        apartment?.tokenizationInfo ?? {};

    const accounts = [
        {
            account: ownerEos,
            isSigned: isSignedByOwner,
            label: t('modalApartmentSignTokenization.owner')
        },
        {
            account: winnerEos,
            isSigned: isSignedByWinner,
            label: t('modalApartmentSignTokenization.winner')
        },
        {
            account: notaryEos,
            isSigned: isSignedByNotary,
            label: t('modalApartmentSignTokenization.notary')
        }
    ];

    const showSignBtn = (type === 'owner' && !isSignedByOwner) || (type === 'notary' && !isSignedByNotary);
    const signDeepLink = type === 'owner' ? ownerSignDeepLink : notarySignDeepLink;

    React.useEffect(() => {
        if (showQr) {
            if ((isSignedByOwner && type === 'owner') || (isSignedByNotary && type === 'notary')) setShowQr(false);
        }
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showQr, isSignedByOwner, isSignedByWinner, isSignedByNotary]);

    if (showQr)
        return (
            <ModalBody>
                <ScanQRSection
                    qrData={signDeepLink!}
                    deepLink={signDeepLink!}
                    deepLinkText={t('modalApartmentSignTokenization.btnDeepLink')}
                    text={
                        <Trans i18nKey="modalApartmentSignTokenization.qr">
                            <b />
                        </Trans>
                    }
                />
            </ModalBody>
        );

    const error = query.error && getErrorI18nText(t, getErrorData(query.error).message);

    return (
        <ModalBody>
            {query.loading ? (
                <ModalLoadingScreen />
            ) : error ? (
                <ModalErrorScreen isStatic text={error} resetError={() => query.refetch()} />
            ) : (
                <>
                    <ModalContent withBorder>
                        {!isNotaryView && <ModalText>{t('modalApartmentSignTokenization.description')}</ModalText>}

                        {isNotaryView && (
                            <>
                                <ModalTextParam
                                    label={t('apartmentStats.address.label')}
                                    value={constructAddress(apartment)}
                                />
                                <ModalTextParam label={t('apartmentStats.realId.label')} value={apartment?.realId} />
                                <ModalTextParam
                                    label={t('apartmentStats.emission.label')}
                                    value={`${Number(apartment?.emissionVolume?.amount)} ${
                                        apartment?.emissionVolume?.token
                                    }`}
                                />
                                <ModalTextParam
                                    label={t('apartmentStats.fee.label')}
                                    value={`${Number(apartment?.emissionVolume?.fee)} ${
                                        apartment?.emissionVolume?.token
                                    }`}
                                />
                            </>
                        )}

                        <ModalText className={cx('mb-s')}>{t('modalApartmentSignTokenization.listTitle')}</ModalText>

                        {accounts.map(({ account, label, isSigned }) => {
                            return (
                                <p className={cx('Param')} key={label}>
                                    {isSigned ? (
                                        <IconCheckmark className={cx('Icon')} />
                                    ) : (
                                        <IconError className={cx('Icon')} />
                                    )}
                                    <b>{[myEosAccount === account && meText, label].filter(Boolean).join(' - ')}</b>
                                    <span>({account})</span>
                                </p>
                            );
                        })}
                    </ModalContent>

                    {showSignBtn ? (
                        <>
                            {isNotaryView && (
                                <CheckboxField
                                    label={t('modalApartmentSignTokenization.agreeDataNotary')}
                                    checked={agree}
                                    onChange={(e) => setAgree(e.target.checked)}
                                />
                            )}

                            <Button
                                type="button"
                                disabled={isNotaryView && !agree}
                                onClick={() => setShowQr(true)}
                                color="primary"
                            >
                                {t('modalApartmentSignTokenization.btnTokenize')}
                            </Button>
                        </>
                    ) : null}
                </>
            )}
        </ModalBody>
    );
};

const ModalApartmentSignTokenization = ({ isOpen, close, ...props }: ModalProps & ApartmentSignTokenizationProps) => {
    const [t] = useTranslation();

    return (
        <Modal isOpen={isOpen} close={close}>
            <ModalHeader>{t('modalApartmentSignTokenization.title')}</ModalHeader>
            <Main {...props} />
        </Modal>
    );
};

export default ModalApartmentSignTokenization;
