import React, { ReactNode } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

const TabSwitcher = <T extends string>({
    options,
    onChange,
    activeType,
    className = ''
}: {
    className?: string;
    onChange(t: T): void;
    activeType: T;
    options: { children: ReactNode; type: T }[];
}) => {
    return (
        <div className={cx('Switcher', className)}>
            {options.map(({ children, type }) => {
                return (
                    <button
                        key={type}
                        className={cx(type === activeType && 'active')}
                        type="button"
                        onClick={() => onChange(type)}
                    >
                        <span tabIndex={-1}>{children}</span>
                    </button>
                );
            })}
        </div>
    );
};

export default TabSwitcher;
