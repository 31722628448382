import update from 'immutability-helper';
import { Actions } from './actions';
import ActionTypes from './actionTypes';
import { LOCAL_STORAGE_KEY_TOKEN_AUTH } from 'constant/index';

export type ErrorStatus = 500 | 404 | 503 | null;

export const initialState = {
    /**
     * difference between user's time (in device) and time in the server  (in ms)
     * if > 0 -- user's time is less, than server time
     * if < 0 -- user's time is more, than server time
     */
    realtimeOffset: 0,
    error: null as ErrorStatus,
    isAuth: !!window.localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN_AUTH)
};

const reducer = (state = initialState, action: Actions) => {
    switch (action.type) {
        case ActionTypes.SET_AUTH:
            return update(state, { isAuth: () => action.payload });
        case ActionTypes.SET_ERROR_STATUS:
            return update(state, { error: () => action.payload });
        case ActionTypes.SET_REALTIME_OFFSET:
            return { ...state, realtimeOffset: action.payload };
        default:
            return state;
    }
};
export default reducer;
