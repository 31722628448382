import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

const Counter: React.FC<JSX.IntrinsicElements['span']> = ({ className = '', ...props }) => {
    return <span className={cx('Component', className)} {...props} />;
};

export default Counter;
