import React, { memo } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import useMeQuery from 'helpers/useMeQuery';
import Preloader from 'components/Preloader';
import { URL_MAP } from 'constant';

const PrivateRoute = memo((routerProps: RouteProps) => {
    const { data, loading } = useMeQuery();

    if (loading) return <Preloader />;

    if (!data) return <Redirect to={URL_MAP.search} />;

    return <Route {...routerProps} />;
});

export default PrivateRoute;
