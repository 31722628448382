import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { URL_MAP } from 'constant';
import { IconAngleLeft } from 'components/Icons';

const cx = classNames.bind(styles);

export const Section: React.FC<{ className?: string }> = ({ className = '', ...props }) => {
    return <section className={cx('Component', className)} {...props} />;
};

export const SectionHeader: React.FC<{
    className?: string;
    withTabs?: boolean;
}> = ({ className = '', withTabs, ...props }) => {
    return <header className={cx('Header', className, { withTabs })} {...props} />;
};

export const SectionTitle: React.FC<{ className?: string; to?: string }> = ({
    className = '',
    to = URL_MAP.manage.index,
    ...props
}) => {
    return (
        <div className={cx('TitleWrapper')}>
            <Link className={cx('BtnBack')} to={to}>
                <IconAngleLeft />
            </Link>
            {/* TODO: Add children content */}
            {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
            <h1 className={cx('Title', className)} {...props} />
            <span />
        </div>
    );
};

export const SectionMain: React.FC<{ className?: string }> = ({ className = '', ...props }) => {
    return <main className={cx('Main', className)} {...props} />;
};

export const SectionHeaderPanel: React.FC<{ className?: string }> = ({ className = '', ...props }) => {
    return <div className={cx('HeaderPanel', className)} {...props} />;
};
