import { LoginType, useChangeLoginMutation } from 'apollo/generted';
import classNames from 'classnames/bind';
import Modal, { ModalBody, ModalHeader, ModalProps, useModalContext } from 'components/UIKit/Modal';
import ModalErrorScreen from 'components/UIKit/Modal/ErrorScreen';
import TextField from 'components/UIKit/TextField';
import FormCodeVerification from 'containers/Forms/CodeVerification';
import Button from 'components/UIKit/Button';
import { getErrorData, getErrorI18nText } from 'helpers';
import { useFormField, useToast } from 'helpers/hooks';
import React, { FormEvent, Fragment, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

export type Props = {
    type: LoginType.Phone | LoginType.Email;
};

const ModalEditLogin = memo(({ isOpen, close, ...props }: Props & ModalProps) => {
    return (
        <Modal close={close} isOpen={isOpen}>
            <Main {...props} />
        </Modal>
    );
});

const Main = memo(({ type }: Props) => {
    const isEmailLoginType = type === LoginType.Email;
    const [t] = useTranslation();
    const { close } = useModalContext();
    const login = useFormField('');
    const toast = useToast();
    const [formError, setFormError] = useState('');
    const [verifyToken, setVerifyToken] = useState('');
    const [step, setStep] = useState<'login' | 'code'>('login');

    const [changeLoginMutation, { loading: loadingChangeLogin }] = useChangeLoginMutation();

    const onBlock = (errorMessage = '') => {
        setStep('login');
        setFormError(t(['modalEditLogin.error.NoResendAttemptsError', errorMessage]));
    };

    const onSubmit = (e?: FormEvent<HTMLFormElement>) => {
        e?.preventDefault();
        setFormError('');
        login.changeError('');

        changeLoginMutation({
            variables: {
                input: {
                    newPhone: isEmailLoginType ? undefined : login.value,
                    newEmail: isEmailLoginType ? login.value : undefined
                }
            }
        })
            .then(({ data }) => {
                const changeLogin = data?.changeLogin!;
                if (changeLogin.__typename === 'ChangeLoginSuccess') {
                    setVerifyToken(changeLogin.verifyToken);
                    setStep('code');
                } else {
                    const { __typename, errorMessage = '' } = changeLogin ?? {};
                    if (__typename === 'InvalidEmailError') {
                        login.changeError(t('modalEditLogin.error.InvalidEmailError'));
                    } else if (__typename === 'InvalidPhoneError') {
                        login.changeError(t('modalEditLogin.error.InvalidPhoneError'));
                    } else if (__typename === 'UserAlreadyExistsError') {
                        login.changeError(t('modalEditLogin.error.UserAlreadyExistsError'));
                    } else {
                        setFormError(t([`modalRefuseToWin.error.${__typename}`, getErrorI18nText(t, errorMessage)]));
                    }
                }
            })
            .catch((e) => {
                setFormError(getErrorI18nText(t, getErrorData(e).message));
            });
    };

    if (step === 'code') {
        return (
            <Fragment>
                <ModalHeader>{t(`modalEditLogin.title`, { context: type })}</ModalHeader>
                <ModalBody>
                    <FormCodeVerification
                        hasAvailableCode
                        verifyToken={verifyToken}
                        onSuccessResendCode={setVerifyToken}
                        onSuccess={() => {
                            toast.success(t(`modalEditLogin.successMsg`, { context: type }));
                            close();
                        }}
                        description={t(`modalEditLogin.description`, {
                            context: 'code',
                            address: login.value
                        })}
                        onUnexpectedError={setFormError}
                        onBlock={onBlock}
                    />
                </ModalBody>
                <ModalErrorScreen text={formError} resetError={() => setFormError('')} />
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                <ModalHeader>{t(`modalEditLogin.title`, { context: type })}</ModalHeader>
                <ModalBody>
                    <form onSubmit={onSubmit}>
                        <p className={cx('Description')}>{t(`modalEditLogin.description`, { context: type })}</p>

                        <TextField
                            value={login.value}
                            error={login.error}
                            label={t(`modalEditLogin.field`, { context: type })}
                            onChange={(e) => login.change(e.target.value)}
                        />

                        <Button
                            loading={loadingChangeLogin}
                            disabled={!login.value.length || (isEmailLoginType && !login.value.includes('@'))}
                            type="submit"
                            color="primary"
                            className={cx('BtnSubmit')}
                        >
                            {t('modalEditLogin.btnSubmit')}
                        </Button>
                    </form>
                </ModalBody>

                <ModalErrorScreen text={formError} resetError={() => setFormError('')} />
            </Fragment>
        );
    }
});

export default ModalEditLogin;
