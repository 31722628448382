import React, { memo, useEffect, useRef } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import LoaderJson from 'assets/lottie/loader.json';
import LottiePlayer, { AnimationItem } from 'lottie-web';
const cx = classNames.bind(styles);

const Loader = memo(({ className = '', size = 16 }: { className?: string; size?: number | null }) => {
    const ref = useRef<HTMLDivElement>(null);
    const animationRef = useRef<AnimationItem | null>(null);

    useEffect(() => {
        if (!ref.current || animationRef.current) return;
        animationRef.current = LottiePlayer.loadAnimation({
            container: ref.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: LoaderJson,
            rendererSettings: {
                preserveAspectRatio: 'xMinYMin slice'
            }
        });
    }, []);

    return <span style={{ width: size === null ? undefined : size }} className={cx('Loader', className)} ref={ref} />;
});

export default Loader;
