import Modal, {
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalProps,
    ModalTextParam,
    useModalContext
} from 'components/UIKit/Modal';
import TextField from 'components/UIKit/TextField';
import Button from 'components/UIKit/Button';
import { getErrorData, getErrorI18nText } from 'helpers';
import CheckboxField from 'components/UIKit/CheckboxField';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStartEvictionAsNotaryMutation } from 'apollo/generted';
import { useFormField, useToast } from 'helpers/hooks';
import validator from 'helpers/validator';
import ModalErrorScreen from 'components/UIKit/Modal/ErrorScreen';

export type Props = {
    id: number;
    address: string;
    tenantAccount: string;
    onSuccess: () => void;
};

const ModalStartEvictionAsManager = ({ id, address, tenantAccount, onSuccess, ...props }: ModalProps & Props) => {
    return (
        <Modal {...props}>
            <Main address={address} id={id} tenantAccount={tenantAccount} onSuccess={onSuccess} />
        </Modal>
    );
};

const Main = ({ id, address, tenantAccount, onSuccess }: Props) => {
    const [t] = useTranslation();
    const { close } = useModalContext();
    const [agree, setAgree] = useState(false);
    const [error, setError] = useState('');
    const reason = useFormField('');
    const toast = useToast();

    const [startEvictionMutation, { loading }] = useStartEvictionAsNotaryMutation();

    const startEviction = () => {
        startEvictionMutation({
            variables: {
                input: {
                    aptId: id,
                    reason: reason.value
                }
            }
        })
            .then(({ data }) => {
                if (data?.startEvictionAsNotary?.__typename === 'StartEvictionSuccess') {
                    toast.success(t('modalStartEvictionAsManager.successMsg'));
                    onSuccess();
                    close();
                } else {
                    setError(
                        t([
                            `modalStartEvictionAsManager.error.${data?.startEvictionAsNotary?.__typename}`,
                            getErrorI18nText(t, data?.startEvictionAsNotary?.errorMessage ?? '')
                        ])
                    );
                }
            })
            .catch((e) => {
                setError(getErrorI18nText(t, getErrorData(e).message));
            });
    };

    return (
        <Fragment>
            <ModalHeader>{t('modalStartEvictionAsManager.title')}</ModalHeader>
            <ModalBody>
                <ModalContent withBorder>
                    <ModalTextParam label={t('apartmentStats.address.label')} value={address} />
                    <ModalTextParam label={t('modalStartEvictionAsManager.user')} value={tenantAccount} />

                    <TextField
                        value={reason.value}
                        error={reason.error}
                        onChange={(e) => reason.change(validator(e, reason.value))}
                        maxLength={300}
                        label={t('modalStartEvictionAsManager.field')}
                    />
                </ModalContent>
                <CheckboxField
                    label={t('modalStartEvictionAsManager.agree')}
                    checked={agree}
                    onChange={(e) => setAgree(e.target.checked)}
                />
                <Button
                    color="primary"
                    disabled={!agree || !reason.value}
                    onClick={startEviction}
                    loading={loading}
                    type="button"
                >
                    {t('modalStartEvictionAsManager.btnSubmit')}
                </Button>
            </ModalBody>
            <ModalErrorScreen text={error} resetError={() => setError('')} />
        </Fragment>
    );
};

export default ModalStartEvictionAsManager;
