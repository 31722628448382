import React, { ReactNode } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import Address, { Props as AddressProps } from 'components/Apartment/Address';
import BaseStats, { Props as BaseStatsProps } from 'components/ApartmentCard/BaseStats';
import {
    ApartmentNode,
    BuyAptRequestNode,
    Maybe,
    useMarkApartmentAsViewedMutation,
    useMarkBuyRequestAsViewedMutation
} from 'apollo/generted';
import { Link } from 'react-router-dom';
import { useIsTouchableDevice } from 'helpers/hooks';
import { getErrorData, useGlobalError } from 'helpers';
import { useTranslation } from 'react-i18next';
const cx = classNames.bind(styles);

export type Props = {
    // link url
    to: string;

    params: ({ label: ReactNode; value: ReactNode } | null)[];
    actions: ReactNode;
    status?: ReactNode;

    buyRequest?: Pick<BuyAptRequestNode, 'id' | 'isNewForRd'>;

    data?: Maybe<AddressProps & BaseStatsProps & Pick<ApartmentNode, 'id' | 'photos' | 'isNewForRd'>>;
};

const Card = ({ status, actions, params, to, data, buyRequest }: Props) => {
    const isTouchable = useIsTouchableDevice();
    const onGlobalError = useGlobalError();

    const [t] = useTranslation();
    const isBuyRequest = !!buyRequest;
    const isNewForRd = isBuyRequest ? buyRequest!.isNewForRd : data?.isNewForRd;

    const [markApartmentAsViewedMutation, { loading: markApartmentAsViewedLoading }] =
        useMarkApartmentAsViewedMutation();
    const [markBuyRequestAsViewedMutation, { loading: markBuyRequestAsViewedLoading }] =
        useMarkBuyRequestAsViewedMutation();

    const markAsViewedLoading = markApartmentAsViewedLoading || markBuyRequestAsViewedLoading;

    const markAsViewed = () => {
        if (markAsViewedLoading || !data) return;

        if (isBuyRequest) {
            markBuyRequestAsViewedMutation({
                variables: {
                    input: {
                        buyRequestId: buyRequest!.id
                    }
                },
                optimisticResponse: {
                    __typename: 'Mutations',
                    markBuyRequestAsViewed: {
                        __typename: 'MarkBuyRequestAsViewedSuccess',
                        buyRequest: {
                            id: data.id,
                            __typename: 'BuyAptRequestNode',
                            isNewForRd: false
                        }
                    }
                }
            })
                .then(({ data }) => {
                    if (data?.markBuyRequestAsViewed?.__typename !== 'MarkBuyRequestAsViewedSuccess') {
                        onGlobalError(
                            t([
                                `markAsViewed.error.${data?.markBuyRequestAsViewed?.__typename}`,
                                data?.markBuyRequestAsViewed?.errorMessage ?? ''
                            ])
                        );
                    }
                })
                .catch((e) => {
                    onGlobalError(getErrorData(e).message);
                });
        } else {
            markApartmentAsViewedMutation({
                variables: {
                    input: {
                        aptId: data.id
                    }
                },
                optimisticResponse: {
                    __typename: 'Mutations',
                    markApartmentAsViewed: {
                        __typename: 'MarkApartmentAsViewedSuccess',
                        apt: {
                            id: data.id,
                            __typename: 'ApartmentNode',
                            isNewForRd: false
                        }
                    }
                }
            })
                .then(({ data }) => {
                    if (data?.markApartmentAsViewed?.__typename !== 'MarkApartmentAsViewedSuccess') {
                        onGlobalError(
                            t([
                                `markAsViewed.error.${data?.markApartmentAsViewed?.__typename}`,
                                data?.markApartmentAsViewed?.errorMessage ?? ''
                            ])
                        );
                    }
                })
                .catch((e) => {
                    onGlobalError(getErrorData(e).message);
                });
        }
    };

    return (
        <div
            onMouseEnter={isNewForRd && !isTouchable ? markAsViewed : undefined}
            onClick={isNewForRd && isTouchable ? markAsViewed : undefined}
            className={cx('Component')}
        >
            <Link className={cx('BaseLink')} to={to} />

            <div className={cx('Photo')}>
                <img
                    src={data?.photos?.[0]?.link ?? ''}
                    alt=""
                    onError={(e) => e.currentTarget.classList.add(cx('hide'))}
                />
                <BaseStats {...data} />
            </div>

            <div className={cx('Main')}>
                <div>
                    {status}
                    <Address {...data} className={cx('Address', { mark: isNewForRd })} />
                </div>

                <ul className={cx('ParamList')}>
                    {params.map((param, i) => {
                        if (!param) return null;

                        const { value, label } = param;
                        return (
                            <li key={i}>
                                {label}
                                <span>{value}</span>
                            </li>
                        );
                    })}
                </ul>

                {actions && <div className={cx('Actions')}>{actions}</div>}
            </div>
        </div>
    );
};

export default Card;
