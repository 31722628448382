import React from 'react';
import { Trans } from 'react-i18next';
import classNames from 'classnames/bind';
import { AptStatus } from 'apollo/generted';
import { addSpacesForThousands, useModal } from 'helpers';
import ModalApartmentExpenses from 'containers/Modals/ApartmentExpenses';
import { ExpensesProps } from './Expenses.d';
import styles from './Expenses.module.scss';

const cx = classNames.bind(styles);

const Expenses = ({ communalCurrency, totalExpenses, isIAmWinner, id, status, className = '' }: ExpensesProps) => {
    const modal = useModal(false);

    if (totalExpenses && (status === AptStatus.Contest || (status === AptStatus.ContestFinished && isIAmWinner)))
        return (
            <>
                <p className={cx('Component', className)}>
                    <span className={cx('Label')}>
                        <Trans i18nKey={`apartmentStats.totalExpenses.label${isIAmWinner ? '_winner' : ''}`}>
                            <b />
                        </Trans>
                    </span>

                    <button type="button" className={cx('Value')} onClick={modal.open}>
                        {addSpacesForThousands(totalExpenses)} {communalCurrency}
                    </button>
                </p>
                <ModalApartmentExpenses {...modal} aptId={id} />
            </>
        );

    return null;
};

export default Expenses;
