import { useSignInMutation } from 'apollo/generted';
import classNames from 'classnames/bind';
import { IconAccount } from 'components/Icons';
import Button from 'components/UIKit/Button';
import ButtonLink from 'components/UIKit/ButtonLink';
import PasswordField from 'components/UIKit/PasswordField';
import TextField from 'components/UIKit/TextField';
import { getErrorData, getErrorI18nText } from 'helpers';
import { useFormField } from 'helpers/hooks';
import React, { FormEvent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { OnAuth } from '..';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

type Props = {
    onAuth: OnAuth;
    onError(v: string): void;
    onClickForgotBtn(): void;
};

const FormLogin = memo(({ onAuth, onError, onClickForgotBtn }: Props) => {
    const [t] = useTranslation();
    const passwordField = useFormField('');
    const loginField = useFormField('');
    const [signInMutation, { loading }] = useSignInMutation();
    const disabled = !passwordField.value || !loginField.value;

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const isEmailLoginType = loginField.value.includes('@');

        passwordField.changeError('');
        loginField.changeError('');

        signInMutation({
            variables: {
                input: {
                    password: passwordField.value,
                    phone: isEmailLoginType ? undefined : loginField.value,
                    email: isEmailLoginType ? loginField.value : undefined
                }
            }
        })
            .then(({ data }) => {
                const signin = data?.signin!;
                if (signin.__typename === 'SigninSuccess') {
                    onAuth(signin.token);
                } else {
                    const { __typename, errorMessage = '' } = signin ?? {};
                    if (__typename === 'InvalidCredentialsError') {
                        passwordField.changeError(t([`modalSign.formLogin.errors.${__typename}`, errorMessage]));
                        loginField.changeError(' ');
                    } else if (__typename === 'LoginIsEmptyError') {
                        loginField.changeError(t([`modalSign.formLogin.errors.${__typename}`, errorMessage]));
                    } else {
                        onError(t([`modalSign.formLogin.errors.${__typename}`, getErrorI18nText(t, errorMessage)]));
                    }
                }
            })
            .catch((e) => {
                onError(getErrorI18nText(t, getErrorData(e).message));
            });
    };

    return (
        <form onSubmit={onSubmit}>
            <TextField
                leftControl={<IconAccount />}
                value={loginField.value}
                onChange={(e) => loginField.change(e.target.value)}
                error={loginField.error}
                label={t('modalSign.formLogin.fieldLogin')}
            />
            <PasswordField
                value={passwordField.value}
                onChange={(e) => passwordField.change(e.target.value)}
                error={passwordField.error}
                label={t('modalSign.formLogin.fieldPassword')}
            />

            <div className={cx('BtnGroup')}>
                <Button disabled={disabled} loading={loading} type="submit" color="primary">
                    {t('modalSign.formLogin.btnSubmit')}
                </Button>
                <ButtonLink
                    type="button"
                    fontWeight="bold"
                    className={cx('BtnForgotPassword')}
                    onClick={onClickForgotBtn}
                >
                    {t('modalSign.formLogin.btnFormForgotPassword')}
                </ButtonLink>
            </div>
        </form>
    );
});

export default FormLogin;
