import TextField, { Props as TextFieldProps } from 'components/UIKit/TextField';
import { useDropdown } from 'helpers/hooks';
import React, { memo } from 'react';
import { createPortal } from 'react-dom';
import Calendar, { Props as CalendarProps } from './Calendar';

export type Props = Omit<TextFieldProps, 'value' | 'onChange'> &
    Pick<CalendarProps, 'maxDate' | 'minDate'> & {
        value: CalendarProps['selected'];
        onChange: CalendarProps['setSelected'];
        calendarPortal?: HTMLElement | null;
    };

const DatePicker = memo(({ onFocus, value, onChange, minDate, maxDate, calendarPortal, ...props }: Props) => {
    const dropdown = useDropdown({ popperMaxWidth: true });
    const inputValue = value?.toFormat('D') || '';

    const calendar = (
        <Calendar
            selected={value}
            setSelected={(v) => {
                onChange(v);
                dropdown.setOpen(false);
            }}
            maxDate={maxDate}
            minDate={minDate}
            componentProps={{
                ref: dropdown.setPopperRef,
                style: dropdown.popper.styles.popper,
                ...dropdown.popper.attributes.popper
            }}
        />
    );

    return (
        <TextField
            /**
             * If element is interactive - open dropdown and set cursor to the end
             */
            onFocus={(e) => {
                if (!props.readOnly && !props.disabled && !dropdown.open) {
                    const caretPos = inputValue.length ?? 0;
                    e.target.setSelectionRange(caretPos, caretPos);
                    dropdown.setOpen(true);
                }
                onFocus?.(e);
            }}
            readOnly
            value={inputValue}
            containerRef={dropdown.setRef}
            {...props}
        >
            {dropdown.open && (calendarPortal ? createPortal(calendar, calendarPortal) : calendar)}
        </TextField>
    );
});
export default DatePicker;
