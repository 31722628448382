import classNames from 'classnames/bind';
import { IconCheckmark } from 'components/Icons';
import Modal from 'components/UIKit/Modal';
import ModalErrorScreen from 'components/UIKit/Modal/ErrorScreen';
import { useStore } from 'helpers/hooks';
import * as sign from 'helpers/sign';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setAuth } from 'store/Base/actions';
import { close as closeAction } from 'store/SignModal/actions';
import ApplicationAuthScreen from './ApplicationAuthScreen';
import styles from './index.module.scss';
import LoginAuthScreen from './LoginAuthScreen';
const cx = classNames.bind(styles);

export type OnAuth = (token: string) => void;

type Method = 'application' | 'login';
const DEFAULT_METHOD = 'application';

const ModalSign = memo(() => {
    const [method, setMethod] = useState<Method>(DEFAULT_METHOD);
    const isOpen = useStore((s) => s.SignModal.isOpen);
    const [isSuccess, setIsSuccess] = useState(false);
    const [formError, setFormError] = useState('');
    const dispatch = useDispatch();
    const close = useCallback(() => dispatch(closeAction()), [dispatch]);
    const setUserAuth = useCallback(() => dispatch(setAuth(true)), [dispatch]);

    const onAuth: OnAuth = (token) => {
        setIsSuccess(true);
        sign.signIn(token);
        setUserAuth();
        setTimeout(close, 1500);
    };

    useEffect(() => {
        if (!isOpen) {
            setIsSuccess(false);
            setFormError('');
            setMethod(DEFAULT_METHOD);
        }
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} close={close}>
            {method === 'application' && (
                <ApplicationAuthScreen
                    onClickBtnAlternate={() => setMethod('login')}
                    onAuth={onAuth}
                    onError={setFormError}
                />
            )}
            {method === 'login' && (
                <LoginAuthScreen
                    onClickBtnAlternate={() => setMethod('application')}
                    onAuth={onAuth}
                    onError={setFormError}
                />
            )}
            {isSuccess && <SuccessScreen />}
            <ModalErrorScreen resetError={() => setFormError('')} text={formError} />
        </Modal>
    );
});

const SuccessScreen = () => {
    const [t] = useTranslation();
    return (
        <div className={cx('SuccessScreen')}>
            <IconCheckmark />
            <span>{t('modalSign.successMsg')}</span>
        </div>
    );
};

export default ModalSign;
