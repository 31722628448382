import 'core-js/es/map';
import 'core-js/es/set';
import 'raf/polyfill';
import 'url-search-params-polyfill';
import ReactDOM from 'react-dom';
import 'scss/index.scss';
import App from 'containers/App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
import i18n from './i18n';
import { Suspense } from 'react';
import Preloader, { PreloaderProvider } from 'components/Preloader';

i18n();

async function loadPolyfills() {
    if (!('scrollBehavior' in document.documentElement.style)) await import('scroll-behavior-polyfill');

    if (typeof window.IntersectionObserver === 'undefined') {
        await import('intersection-observer');
    }
}

loadPolyfills();

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://5c3c356603ac46e8815845133fa97b5e@bugs.list.family/5',
        release: process.env.REACT_APP_VERSION,
        environment: process.env.REACT_APP_ENV
    });
}
const SuspenseLoader = () => <Preloader />;

ReactDOM.render(
    <PreloaderProvider>
        <Suspense fallback={<SuspenseLoader />}>
            <App />
        </Suspense>
    </PreloaderProvider>,
    document.getElementById('root')
);

serviceWorker.unregister();
