import { ApartmentNode, useUnblockAptMutation } from 'apollo/generted';
import Button from 'components/UIKit/Button';
import Modal, {
    ModalBody,
    ModalBtnGroup,
    ModalContent,
    ModalHeader,
    ModalProps,
    ModalText,
    useModalContext
} from 'components/UIKit/Modal';
import ModalErrorScreen from 'components/UIKit/Modal/ErrorScreen';
import { getErrorData, getErrorI18nText } from 'helpers';
import { useToast } from 'helpers/hooks';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type Props = Pick<ApartmentNode, 'id'> & {
    onUnblock(): void;
};

const ModalUnblockApartment = ({ id, onUnblock, ...props }: ModalProps & Props) => {
    return (
        <Modal {...props}>
            <Main id={id} onUnblock={onUnblock} />
        </Modal>
    );
};

const Main = ({ id, onUnblock }: Props) => {
    const [t] = useTranslation();
    const { close } = useModalContext();
    const [error, setError] = useState('');
    const [unblockAptMutation, { loading }] = useUnblockAptMutation();
    const toast = useToast();

    const unblockApt = () => {
        unblockAptMutation({
            variables: {
                input: {
                    aptId: id
                }
            }
        })
            .then(({ data }) => {
                if (data?.unblockApt?.__typename === 'UnblockAptSuccess') {
                    toast.success(t('modalUnblockApartment.successMsg'));
                    onUnblock();
                    close();
                } else {
                    setError(
                        t([
                            `modalUnblockApartment.error.${data?.unblockApt?.__typename}`,
                            getErrorI18nText(t, data?.unblockApt?.errorMessage)
                        ])
                    );
                }
            })
            .catch((e) => {
                setError(getErrorI18nText(t, getErrorData(e).message));
            });
    };

    return (
        <Fragment>
            <ModalHeader>{t('modalUnblockApartment.title')}</ModalHeader>

            <ModalBody>
                <ModalContent>
                    <ModalText>{t('modalUnblockApartment.description')}</ModalText>
                </ModalContent>

                <ModalBtnGroup>
                    <Button type="button" color="tertiary" onClick={close}>
                        {t('modalUnblockApartment.btnCancel')}
                    </Button>

                    <Button loading={loading} type="button" color="primary" onClick={unblockApt}>
                        {t('modalUnblockApartment.btnSubmit')}
                    </Button>
                </ModalBtnGroup>
            </ModalBody>
            <ModalErrorScreen text={error} resetError={() => setError('')} />
        </Fragment>
    );
};

export default ModalUnblockApartment;
