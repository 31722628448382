const formatDate = (value: string | null, dateOnly = false, showMs?: boolean) => {
    if (!value) return '-';

    const format = (v: number, maxLength = 2) => v.toString().padStart(maxLength, '0');

    const date = new Date(value + 'Z');

    if (isNaN(date.getTime())) return 'Invalid Date';

    const dateString = `${format(date.getDate())}.${format(date.getMonth() + 1)}.${date.getFullYear()}`;

    const timeString = dateOnly
        ? ''
        : ` / ${format(date.getHours())}:${format(date.getMinutes())}:${format(date.getSeconds())}${
              showMs ? `.${format(date.getMilliseconds(), 3)}` : ''
          }`;

    return `${dateString}${timeString}`;
};

export default formatDate;
