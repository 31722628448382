import { ManagerDocumentFragment, ManagerExpenseFragment, useManagerRegionSettingsQuery } from 'apollo/generted';
import classNames from 'classnames/bind';
import update from 'immutability-helper';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Section, SectionHeader, SectionMain, SectionTitle } from '../components/Section';
import { useManageContext } from '../context';
import Contact from './Contact';
import AdditionalPayments from './AdditionalPayments';
import Documents from './Documents';
import styles from './index.module.scss';
import { IconWarningNew } from 'components/Icons';
import Loader from 'components/Loader';
import Button from 'components/UIKit/Button';
import { getErrorI18nText } from 'helpers';
const cx = classNames.bind(styles);

const RegionSettings = () => {
    const [t] = useTranslation();
    const { city } = useManageContext();
    const query = useManagerRegionSettingsQuery({ variables: { id: city?.id! } });

    const onRemoveExpense = (id: number) => {
        query.updateQuery((currentData) => {
            return update(currentData, {
                regionExpenses: (arr) => arr?.filter((item) => item?.id !== id)
            });
        });
    };

    const onAddExpense = (expense: ManagerExpenseFragment) => {
        query.updateQuery((currentData) => {
            return update(currentData, {
                regionExpenses: (arr) => {
                    const hasExpense = arr?.some((item) => item?.id === expense.id);

                    if (hasExpense) {
                        return arr;
                    } else {
                        return [...(arr ?? []), expense];
                    }
                }
            });
        });
    };

    const onRemoveDocument = (id: number) => {
        query.updateQuery((currentData) => {
            return update(currentData, {
                regionDocuments: (arr) => arr?.filter((item) => item?.id !== id)
            });
        });
    };

    const onAddDocument = (document: ManagerDocumentFragment) => {
        query.updateQuery((currentData) => {
            return update(currentData, {
                regionDocuments: (arr) => {
                    const hasDocument = arr?.some((item) => item?.id === document.id);

                    if (hasDocument) {
                        return arr;
                    } else {
                        return [...(arr ?? []), document];
                    }
                }
            });
        });
    };

    return (
        <Section>
            <SectionHeader className={cx('Header')}>
                <SectionTitle>{t('pageManage.regionSettings.title')}</SectionTitle>
            </SectionHeader>

            <SectionMain className={cx('Main')}>
                {query.loading ? (
                    <div className={cx('LoadingScreen')}>
                        <Loader size={64} />
                    </div>
                ) : query.error ? (
                    <div className={cx('ErrorScreen')}>
                        <IconWarningNew />
                        <h6>{getErrorI18nText(t, query.error?.message)}</h6>
                        <Button onClick={() => query.refetch()} color="primary">
                            {t('global.reload')}
                        </Button>
                    </div>
                ) : (
                    <Fragment>
                        <Contact city={query.data?.city!} />
                        <Documents
                            onRemove={onRemoveDocument}
                            onAdd={onAddDocument}
                            regionDocuments={query.data?.regionDocuments}
                            city={query.data?.city!}
                        />
                        <AdditionalPayments
                            onRemove={onRemoveExpense}
                            onAdd={onAddExpense}
                            regionExpenses={query.data?.regionExpenses}
                            city={query.data?.city!}
                        />
                    </Fragment>
                )}
            </SectionMain>
        </Section>
    );
};

export default RegionSettings;
