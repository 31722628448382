import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { URL_MAP } from 'constant';
import { usePixelRatio } from 'helpers';
import ButtonLink from 'components/UIKit/ButtonLink';

const cx = classNames.bind(styles);

const Footer = () => {
    const [t] = useTranslation();
    const pixelRatio = usePixelRatio();

    return (
        <footer className={cx('Component')}>
            <div className={cx('Container')}>
                <div className={cx('LogoContainer')}>
                    <Link to={URL_MAP.search}>
                        <img alt="LiST" src={require(`assets/images/logo@${pixelRatio}x.png`).default} />
                    </Link>
                    <p className={cx('Copyright')}>© {new Date().getFullYear()}</p>
                </div>
                <div className={cx('Main')}>
                    <nav className={cx('Nav')}>
                        <h6>{t('footer.nav.aboutUs.title')}</h6>

                        <ButtonLink
                            tag="navlink"
                            fontWeight="medium"
                            fontSize="normal"
                            color="black"
                            to={URL_MAP.landing}
                            exact
                        >
                            {t('footer.nav.aboutUs.aboutProject')}
                        </ButtonLink>
                        <ButtonLink
                            tag="navlink"
                            fontWeight="medium"
                            fontSize="normal"
                            color="black"
                            to={URL_MAP.search}
                        >
                            {t('footer.nav.aboutUs.search')}
                        </ButtonLink>
                        <ButtonLink
                            tag="navlink"
                            fontWeight="medium"
                            fontSize="normal"
                            color="black"
                            to={URL_MAP.contacts}
                        >
                            {t('footer.nav.aboutUs.contacts')}
                        </ButtonLink>
                        <ButtonLink
                            tag="navlink"
                            fontWeight="medium"
                            fontSize="normal"
                            color="black"
                            to={URL_MAP.regions}
                        >
                            {t('footer.nav.aboutUs.regions')}
                        </ButtonLink>
                        <ButtonLink
                            tag="navlink"
                            fontWeight="medium"
                            fontSize="normal"
                            color="black"
                            to={URL_MAP.docs.index}
                        >
                            {t('footer.nav.aboutUs.docs')}
                        </ButtonLink>
                        <ButtonLink
                            tag="navlink"
                            fontWeight="medium"
                            fontSize="normal"
                            color="black"
                            to={URL_MAP.statistics}
                        >
                            {t('footer.nav.aboutUs.statistics')}
                        </ButtonLink>
                    </nav>
                    <nav className={cx('Nav')}>
                        <h6>{t('footer.nav.help.title')}</h6>
                        <ButtonLink tag="navlink" fontWeight="medium" fontSize="normal" color="black" to={URL_MAP.faq}>
                            {t('footer.nav.help.faq')}
                        </ButtonLink>
                        <ButtonLink
                            tag="navlink"
                            fontWeight="medium"
                            fontSize="normal"
                            color="black"
                            to={URL_MAP.explore}
                        >
                            {t('footer.nav.help.explore')}
                        </ButtonLink>
                        <ButtonLink
                            tag="navlink"
                            fontWeight="medium"
                            fontSize="normal"
                            color="black"
                            to={URL_MAP.help.contestParticipance}
                        >
                            {t('footer.nav.help.contestParticipance')}
                        </ButtonLink>
                        <ButtonLink
                            tag="navlink"
                            fontWeight="medium"
                            fontSize="normal"
                            color="black"
                            to={URL_MAP.help.contestResultValidation}
                        >
                            {t('footer.nav.help.contestResultValidation')}
                        </ButtonLink>
                        <ButtonLink
                            tag="navlink"
                            fontWeight="medium"
                            fontSize="normal"
                            color="black"
                            to={URL_MAP.help.tokenizationProcess}
                        >
                            {t('footer.nav.help.tokenizationProcess')}
                        </ButtonLink>
                        <ButtonLink
                            tag="navlink"
                            fontWeight="medium"
                            fontSize="normal"
                            color="black"
                            to={URL_MAP.help.addApartmentProcess}
                        >
                            {t('footer.nav.help.addApartmentProcess')}
                        </ButtonLink>
                    </nav>
                    <div className={cx('TextGroup')}>
                        <p className={cx('Text')}>{t('footer.text.0')}</p>
                        <p className={cx('Text')}>{t('footer.text.1')}</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
