import React from 'react';
import { useTranslation } from 'react-i18next';
import { Data, useConstructApartmentStatus } from 'helpers/useConstructApartmentStatus';
import UIKitStatus from 'components/UIKit/Status';
import { ApartmentData } from 'components/ApartmentCard/ApartmentCard.d';

type NameProps = Pick<ApartmentData, 'mode'> & {
    className?: string;
    data?: Data;
};

const Status = ({ className = '', mode, data }: NameProps) => {
    const {
        isContestFinished,
        inContest,
        isLocked,
        isRework,
        inRDModeration,
        isDraft,
        isWaitingContest,
        isWinnerSelectionInProgress,
        isSold
    } = useConstructApartmentStatus(data);
    const [t] = useTranslation();
    const isOwner = mode === 'OWNER';
    const isPublic = mode === 'PUBLIC';

    return (
        <UIKitStatus
            className={className}
            color={
                isWaitingContest || isDraft
                    ? 'yellow'
                    : isLocked || isRework
                    ? 'red'
                    : isWinnerSelectionInProgress || (isOwner && inContest)
                    ? 'orange'
                    : (isPublic && inContest) || (isOwner && (inRDModeration || isContestFinished))
                    ? 'green'
                    : isContestFinished
                    ? 'grue'
                    : isSold
                    ? 'blue'
                    : 'orange-light'
            }
        >
            {t(`apartmentStats.status.${data?.status!}`, {
                context: isLocked
                    ? 'lock'
                    : isWinnerSelectionInProgress
                    ? 'waiting'
                    : isOwner && (inContest || isContestFinished)
                    ? 'owner'
                    : undefined
            })}
        </UIKitStatus>
    );
};

export default Status;
