import Modal, {
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalProps,
    ModalTextParam,
    useModalContext
} from 'components/UIKit/Modal';
import Button from 'components/UIKit/Button';
import { Fragment, memo, useState } from 'react';
import Alert from 'components/UIKit/Alert';
import CheckboxField from 'components/UIKit/CheckboxField';
import { useTranslation } from 'react-i18next';
import { BuyAptRequestNode, useCancelBuyRequestMutation, useCancelBuyoutParamsQuery } from 'apollo/generted';
import ModalErrorScreen from 'components/UIKit/Modal/ErrorScreen';
import ModalLoadingScreen from 'components/UIKit/Modal/LoadingScreen';
import { getErrorI18nText, getErrorData } from 'helpers';
import { useConstructAddress } from 'helpers/useParsedApartmentData';
import { useToast } from 'helpers/hooks';

export type Props = {
    buyRequestId: BuyAptRequestNode['id'];
};

const ModalCancelBuyout = ({ isOpen, close, buyRequestId }: ModalProps & Props) => {
    const [t] = useTranslation();
    return (
        <Modal isOpen={isOpen} close={close}>
            <ModalHeader>{t('modalCancelBuyout.title')}</ModalHeader>
            <Main buyRequestId={buyRequestId} />
        </Modal>
    );
};
const Main = memo<Props>(({ buyRequestId }) => {
    const [t] = useTranslation();
    const { close } = useModalContext();
    const [agree, setAgree] = useState(false);
    const toast = useToast();
    const constructAddress = useConstructAddress();
    const [formError, setFormError] = useState('');

    const [cancelBuyRequestMutation, { loading }] = useCancelBuyRequestMutation();

    const query = useCancelBuyoutParamsQuery({
        variables: {
            buyRequestId
        }
    });

    const errorText = query.error && getErrorI18nText(t, getErrorData(query.error).message);

    const { feeAmount, tokenId, changeAmount, apt: apartment } = query.data?.buyRequest ?? {};

    const onSubmit = () => {
        cancelBuyRequestMutation({
            variables: {
                input: {
                    buyRequestId
                }
            }
        })
            .then(({ data }) => {
                if (data?.cancelBuyRequest?.__typename === 'CancelBuyRequestSuccess') {
                    toast.success(t('modalCancelBuyout.successMsg'));
                    close();
                } else {
                    setFormError(
                        t([
                            `modalCancelBuyout.error.${data?.cancelBuyRequest?.__typename}`,
                            getErrorI18nText(t, data?.cancelBuyRequest?.errorMessage)
                        ])
                    );
                }
            })
            .catch((e) => {
                setFormError(getErrorI18nText(t, getErrorData(e).message));
            });
    };

    if (query.loading)
        return (
            <ModalBody>
                <ModalLoadingScreen />
            </ModalBody>
        );

    if (errorText)
        return (
            <ModalBody>
                <ModalErrorScreen isStatic text={errorText} resetError={() => query.refetch()} />
            </ModalBody>
        );

    return (
        <Fragment>
            <ModalBody>
                <ModalContent withBorder>
                    <Alert>
                        {t(`modalCancelBuyout.alert`, {
                            amount: feeAmount,
                            token: tokenId
                        })}
                    </Alert>

                    <ModalTextParam label={t('apartmentStats.address.label')} value={constructAddress(apartment)} />

                    <ModalTextParam label={t('modalCancelBuyout.refundText')} value={`${changeAmount} ${tokenId}`} />
                </ModalContent>

                <CheckboxField
                    label={t('modalCancelBuyout.agree')}
                    checked={agree}
                    onChange={(e) => setAgree(e.target.checked)}
                />

                <Button type="button" disabled={!agree} color="primary" loading={loading} onClick={onSubmit}>
                    {t('modalCancelBuyout.btnSubmit')}
                </Button>
            </ModalBody>
            <ModalErrorScreen text={formError} resetError={() => setFormError('')} />
        </Fragment>
    );
});

export default ModalCancelBuyout;
