import {
    AptStatus,
    RentalSortEnum,
    useManagerEvictionsQuery,
    useManagerEvictionsWsSubscription
} from 'apollo/generted';
import classNames from 'classnames/bind';
import { IconEviction } from 'components/Icons';
import Button from 'components/UIKit/Button';
import Counter from 'components/UIKit/Counter';
import { URL_MAP } from 'constant';
import ModalConfirmEviction, { Props as ModalConfirmEvictionProps } from 'containers/Modals/ConfirmEviction';
import ModalContacts, { Props as ModalContactsProps } from 'containers/Modals/Contacts';
import { useModal, getPassedDays } from 'helpers';
import formatDate from 'helpers/formatDate';
import update from 'immutability-helper';
import React, { Fragment, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Apartments from '../components/Apartments';
import Card from '../components/Card';
import { Section, SectionHeader, SectionMain, SectionTitle } from '../components/Section';
import SectionSort from '../components/SectionSort';
import { useManageContext } from '../context';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

const Evictions = memo(() => {
    const [t] = useTranslation();
    const [sort, setSort] = useState(RentalSortEnum.UpdatedForRdDesc);
    const { evictionsNum } = useManageContext()?.city?.notaryUpdates ?? {};

    return (
        <Section className={cx('Section')}>
            <SectionHeader>
                <SectionTitle>
                    {t('pageManage.evictions.title')}
                    {!!evictionsNum && <Counter>{evictionsNum}</Counter>}
                </SectionTitle>

                <SectionSort
                    controlClassName={cx('SortControl')}
                    options={[
                        {
                            isActive: sort === RentalSortEnum.UpdatedForRdDesc,
                            label: t(`pageManage.evictions.sort.${RentalSortEnum.UpdatedForRdDesc}`),
                            onClick: () => setSort(RentalSortEnum.UpdatedForRdDesc)
                        },
                        {
                            isActive: sort === RentalSortEnum.UpdatedForRd,
                            label: t(`pageManage.evictions.sort.${RentalSortEnum.UpdatedForRd}`),
                            onClick: () => setSort(RentalSortEnum.UpdatedForRd)
                        }
                    ]}
                />
            </SectionHeader>

            <SectionMain>
                <Main sort={sort} />
            </SectionMain>
        </Section>
    );
});

const Main = ({ sort }: { sort: RentalSortEnum }) => {
    const cityId = useManageContext().city?.id!;
    const [offset, setOffset] = useState(0);
    const modalConfirmEviction = useModal<ModalConfirmEvictionProps>();
    const modalContact = useModal<ModalContactsProps>();
    const [t] = useTranslation();
    const STEP = 10;

    const {
        data: apartmentsData,
        loading,
        error,
        ...apartmentsQuery
    } = useManagerEvictionsQuery({
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        variables: {
            first: STEP,
            offset,
            sort: [sort],
            cityId
        }
    });

    /** is any loading now */
    const apartments = apartmentsData?.rentals?.apartments;
    const total = apartmentsData?.rentals?.pageInfo.total || 0;
    const page = offset! / STEP;

    const onChangePage = (page: number) => {
        window.scrollTo({ top: 0 });
        setOffset(page * STEP);
    };

    useManagerEvictionsWsSubscription({
        skip: !!error,
        variables: {
            cityId
        },
        onSubscriptionData: ({ subscriptionData: { data } }) => {
            apartmentsQuery.updateQuery((queryData) => {
                const { apt: target, oldStatus } = data?.RDUpdates ?? {};
                if (!target) return queryData;

                const targetId = target.id;
                const needToRemove = target.status !== AptStatus.Eviction;
                const isNewApartment = target.status === AptStatus.Eviction && oldStatus !== AptStatus.Eviction;
                const apartments = queryData.rentals?.apartments ?? [];

                if (needToRemove) {
                    /** Close modals */
                    if (modalContact.payload?.type === 'tenant' && modalContact.payload.aptId === targetId) {
                        modalContact.close();
                    }

                    if (modalConfirmEviction.payload?.id === targetId) {
                        modalConfirmEviction.close();
                    }

                    const filteredApartments = apartments.filter((a) => a?.id !== targetId);
                    if (!filteredApartments.length) {
                        if (page === 0) {
                            apartmentsQuery.refetch();
                        } else {
                            onChangePage((page ?? 1) - 1);
                        }
                        return queryData;
                    }

                    return update(queryData, {
                        rentals: {
                            apartments: () => filteredApartments,
                            pageInfo: {
                                total: (total) => total - 1
                            }
                        }
                    });
                } else if (isNewApartment && page === 0 && apartments.every((apt) => apt?.id !== targetId)) {
                    return update(queryData, {
                        rentals: {
                            apartments: () => {
                                const newApartments = [target, ...apartments];
                                if (newApartments.length > STEP) newApartments.length = STEP;
                                return newApartments;
                            },
                            pageInfo: {
                                total: (total) => total + 1
                            }
                        }
                    });
                }

                return queryData;
            });
        }
    });

    const removeApt = (aptId: number) => {
        apartmentsQuery.updateQuery((currentData) => {
            return update(currentData, {
                rentals: {
                    apartments: (arr) => arr.filter((item) => item?.id !== aptId)
                }
            });
        });
    };

    return (
        <Apartments
            loading={loading}
            onClickReload={() => apartmentsQuery.refetch()}
            error={error?.message}
            isEmpty={!apartments?.length}
            page={page}
            pageCount={Math.ceil(total / STEP)}
            empty={{
                icon: <IconEviction />,
                title: t('pageManage.evictions.empty.title'),
                text: t('pageManage.evictions.empty.text')
            }}
            onChangePage={onChangePage}
        >
            {apartments?.map((apartment) => {
                if (!apartment) return null;
                const { tenantAccount, evictionReason, isEvictionStartedByTenant, evictionStart } =
                    apartment.actualRental ?? {};

                return (
                    <Card
                        data={apartment}
                        key={apartment.id}
                        to={URL_MAP.apartment(apartment.id)}
                        params={[
                            {
                                label: t('pageManage.evictions.card.params.evictionStart'),
                                value: formatDate(evictionStart, true)
                            },
                            evictionStart
                                ? {
                                      label: t('pageManage.evictions.card.params.evictionDuration'),
                                      value: t('global.days', {
                                          count: getPassedDays(new Date(evictionStart + 'z'))
                                      })
                                  }
                                : null,
                            {
                                label: t('pageManage.evictions.card.params.tenantAccount'),
                                value: tenantAccount
                            },
                            {
                                label: t('apartmentStats.evictionReason.label'),
                                value: isEvictionStartedByTenant
                                    ? t('apartmentStats.evictionReason.startedByTenant')
                                    : evictionReason
                            }
                        ]}
                        actions={
                            <Fragment>
                                <Button
                                    onClick={() =>
                                        modalConfirmEviction.open({
                                            id: apartment.id,
                                            onSuccess: () => removeApt(apartment.id)
                                        })
                                    }
                                    color="secondary"
                                    type="button"
                                >
                                    {t('pageManage.evictions.card.actions.confirmEviction')}
                                </Button>
                                <Button
                                    onClick={() => modalContact.open({ aptId: apartment.id, type: 'tenant' })}
                                    color="tertiary"
                                    type="button"
                                >
                                    {t('pageManage.evictions.card.actions.contact')}
                                </Button>
                            </Fragment>
                        }
                    />
                );
            })}

            <ModalContacts {...modalContact} {...modalContact.payload!} />
            <ModalConfirmEviction {...modalConfirmEviction} {...modalConfirmEviction.payload!} />
        </Apartments>
    );
};

export default Evictions;
