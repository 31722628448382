import React from 'react';
import update from 'immutability-helper';
import { useTranslation } from 'react-i18next';
import { Route } from 'react-router-dom';
import classNames from 'classnames/bind';
import {
    AptStatus,
    AuctionSortEnum,
    useManagerAuctionsActiveWsSubscription,
    useManagerAuctionsFinishedWsSubscription,
    useManagerAuctionsQuery
} from 'apollo/generted';
import { useModal } from 'helpers';
import formatDate from 'helpers/formatDate';
import { useManageContext } from '../context';
import { URL_MAP } from 'constant';
import Button from 'components/UIKit/Button';
import Counter from 'components/UIKit/Counter';
import Status from 'components/UIKit/Status';
import { IconTrophy } from 'components/Icons';
import Timer from 'components/Timer';
import ModalAddRental, { Props as ModalAddRentalProps } from 'containers/Modals/AddRental';
import ModalApartmentEmission from 'containers/Modals/ApartmentEmission/ApartmentEmission';
import { ModalApartmentEmissionProps } from 'containers/Modals/ApartmentEmission/ApartmentEmission.d';
import ModalApartmentSignTokenization from 'containers/Modals/ApartmentSignTokenization/ApartmentSignTokenization';
import { ApartmentSignTokenizationProps } from 'containers/Modals/ApartmentSignTokenization/ApartmentSignTokenization.d';
import ModalAuctionParticipants, { ModalAuctionParticipantsProps } from 'containers/Modals/AuctionParticipants';
import ModalCancelContestAsNotary, {
    Props as ModalCancelAuctionAsNotaryProps
} from 'containers/Modals/CancelContestAsNotary';
import ModalContacts, { Props as ModalContactProps } from 'containers/Modals/Contacts';
import Apartments from '../components/Apartments';
import Card from '../components/Card';
import { Section, SectionHeader, SectionHeaderPanel, SectionMain, SectionTitle } from '../components/Section';
import SectionSort from '../components/SectionSort';
import SectionTabs from '../components/SectionTabs';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

const Active = ({ sort }: { sort: AuctionSortEnum }) => {
    const cityId = useManageContext().city?.id!;
    const [offset, setOffset] = React.useState(0);
    const modalContactOwner = useModal<ModalContactProps>();
    const modalCancelAuctionAsNotary = useModal<ModalCancelAuctionAsNotaryProps>();
    const modalAuctionParticipants = useModal<ModalAuctionParticipantsProps>();
    const [t] = useTranslation();
    const STEP = 10;

    const {
        data: apartmentsData,
        loading,
        error,
        ...apartmentsQuery
    } = useManagerAuctionsQuery({
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        variables: {
            first: STEP,
            offset,
            sort: [sort],
            cityId,
            isFinished: false
        }
    });

    /** is any loading now */
    const apartments = apartmentsData?.auctions?.apartments;
    const total = apartmentsData?.auctions?.pageInfo.total || 0;
    const page = offset! / STEP;

    const onChangePage = (page: number) => {
        window.scrollTo({ top: 0 });
        setOffset(page * STEP);
    };

    useManagerAuctionsActiveWsSubscription({
        skip: !!error,
        variables: {
            cityId
        },
        onSubscriptionData: ({ subscriptionData: { data } }) => {
            apartmentsQuery.updateQuery((queryData) => {
                const { apt: target, oldStatus } = data?.RDUpdates ?? {};
                if (!target) return queryData;

                const targetId = target.id;
                const needToRemove = target.status !== AptStatus.Contest;
                const isNewApartment = target.status === AptStatus.Contest && oldStatus !== AptStatus.Contest;
                const apartments = queryData.auctions?.apartments ?? [];

                if (needToRemove) {
                    /**
                     * Close all modals for this apartment
                     */
                    if (modalCancelAuctionAsNotary.payload?.id === targetId) {
                        modalCancelAuctionAsNotary.close();
                    }
                    if (modalAuctionParticipants.payload?.id === targetId) {
                        modalAuctionParticipants.close();
                    }
                    if (
                        modalContactOwner.payload?.type === 'applicant' &&
                        modalContactOwner.payload.aptId === targetId
                    ) {
                        modalContactOwner.close();
                    }

                    const filteredApartments = apartments.filter((a) => a?.id !== targetId);
                    if (!filteredApartments.length) {
                        if (page === 0) {
                            apartmentsQuery.refetch();
                        } else {
                            onChangePage((page ?? 1) - 1);
                        }
                        return queryData;
                    }

                    return update(queryData, {
                        auctions: {
                            apartments: () => filteredApartments,
                            pageInfo: {
                                total: (total) => total - 1
                            }
                        }
                    });
                } else if (isNewApartment && page === 0 && apartments.every((apt) => apt?.id !== targetId)) {
                    return update(queryData, {
                        auctions: {
                            apartments: () => {
                                const newApartments = [target, ...apartments];
                                if (newApartments.length > STEP) newApartments.length = STEP;
                                return newApartments;
                            },
                            pageInfo: {
                                total: (total) => total + 1
                            }
                        }
                    });
                }

                return queryData;
            });
        }
    });

    const onCancelAuction = (aptId: number) => {
        apartmentsQuery.updateQuery((queryData) => {
            return update(queryData, {
                auctions: {
                    apartments: (arr) => arr?.filter((apt) => apt?.id !== aptId)
                }
            });
        });
    };

    return (
        <Apartments
            loading={loading}
            onClickReload={() => apartmentsQuery.refetch()}
            error={error?.message}
            isEmpty={!apartments?.length}
            page={page}
            pageCount={Math.ceil(total / STEP)}
            empty={{
                icon: <IconTrophy />,
                title: t('pageManage.contest.active.empty.title'),
                text: t('pageManage.contest.active.empty.text')
            }}
            onChangePage={onChangePage}
        >
            {apartments?.map((apartment) => {
                if (!apartment) return null;
                return (
                    <Card
                        data={apartment}
                        key={apartment.id}
                        to={URL_MAP.apartment(apartment.id)}
                        params={[
                            {
                                label: t('apartmentStats.timer.label'),
                                value:
                                    apartment.latestAuction?.endOfActivePhase !== null ? (
                                        <Timer endTime={apartment.latestAuction?.endOfActivePhase} />
                                    ) : (
                                        '3д 00ч 00м 00c'
                                    )
                            },
                            {
                                label: t('pageManage.contest.active.card.params.bids'),
                                value: (
                                    <button
                                        className={cx('BtnLink')}
                                        onClick={() => modalAuctionParticipants.open({ id: apartment.id })}
                                    >
                                        {apartment.latestAuction?.bidsNumber?.active}
                                    </button>
                                )
                            }
                        ]}
                        actions={
                            <>
                                {!apartment.isTokenized && (
                                    <Button
                                        onClick={() =>
                                            modalContactOwner.open({
                                                aptId: apartment.id,
                                                type: 'applicant'
                                            })
                                        }
                                        color="primary"
                                    >
                                        {t('pageManage.contest.active.card.actions.contact')}
                                    </Button>
                                )}
                                <Button
                                    onClick={() =>
                                        modalCancelAuctionAsNotary.open({
                                            id: apartment.id,
                                            onSuccess: () => onCancelAuction(apartment.id)
                                        })
                                    }
                                    color="tertiary"
                                >
                                    {t('pageManage.contest.active.card.actions.cancel')}
                                </Button>
                            </>
                        }
                    />
                );
            })}

            <ModalCancelContestAsNotary {...modalCancelAuctionAsNotary} {...modalCancelAuctionAsNotary.payload!} />
            <ModalContacts {...modalContactOwner} {...modalContactOwner.payload!} />
            <ModalAuctionParticipants {...modalAuctionParticipants} {...modalAuctionParticipants.payload!} />
        </Apartments>
    );
};

const Finished = ({ sort }: { sort: AuctionSortEnum }) => {
    const cityId = useManageContext().city?.id!;
    const [offset, setOffset] = React.useState(0);
    const modalContacts = useModal<ModalContactProps>();
    const modalApartmentSignTokenization = useModal<ApartmentSignTokenizationProps>();
    const modalApartmentEmission = useModal<ModalApartmentEmissionProps>();
    const modalCancelAuctionAsNotary = useModal<ModalCancelAuctionAsNotaryProps>();
    const modalAddRental = useModal<ModalAddRentalProps>();
    const [t] = useTranslation();
    const STEP = 10;

    const {
        data: apartmentsData,
        loading,
        error,
        ...apartmentsQuery
    } = useManagerAuctionsQuery({
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        variables: {
            first: STEP,
            offset,
            sort: [sort],
            cityId,
            isFinished: true
        }
    });

    /** is any loading now */
    const apartments = apartmentsData?.auctions?.apartments;
    const total = apartmentsData?.auctions?.pageInfo.total || 0;
    const page = offset! / STEP;

    const onChangePage = (page: number) => {
        window.scrollTo({ top: 0 });
        setOffset(page * STEP);
    };

    const removeApartmentFromList = (aptId: number) => {
        apartmentsQuery.updateQuery((queryData) => {
            return update(queryData, {
                auctions: {
                    apartments: (arr) => arr?.filter((apt) => apt?.id !== aptId)
                }
            });
        });
    };

    useManagerAuctionsFinishedWsSubscription({
        skip: !!error,
        variables: {
            cityId
        },
        onSubscriptionData: ({ subscriptionData: { data } }) => {
            apartmentsQuery.updateQuery((queryData) => {
                const { apt: target, oldStatus } = data?.RDUpdates ?? {};
                if (!target) return queryData;

                const targetId = target.id;
                const needToRemove = target.status !== AptStatus.ContestFinished;
                const isNewApartment =
                    target.status === AptStatus.ContestFinished && oldStatus !== AptStatus.ContestFinished;
                const apartments = queryData.auctions?.apartments ?? [];

                /** Handle updates for sign */
                if (!needToRemove) {
                    if (
                        modalApartmentSignTokenization.payload?.aptId === targetId &&
                        target.tokenizationInfo?.isSignedByNotary
                    ) {
                        modalApartmentSignTokenization.close();
                    }

                    if (modalApartmentEmission.payload?.aptId === targetId && target.isTokenized) {
                        modalApartmentEmission.close();
                    }
                }

                if (needToRemove) {
                    /**
                     * Close all modals for this apartment
                     */
                    if (modalCancelAuctionAsNotary.payload?.id === targetId) {
                        modalCancelAuctionAsNotary.close();
                    }

                    if (modalContacts.payload?.type === 'applicant' && modalContacts.payload.aptId === targetId) {
                        modalContacts.close();
                    }

                    if (modalContacts.payload?.type === 'winner' && modalContacts.payload.aptId === targetId) {
                        modalContacts.close();
                    }

                    if (modalApartmentSignTokenization.payload?.aptId === targetId) {
                        modalApartmentSignTokenization.close();
                    }

                    if (modalApartmentEmission.payload?.aptId === targetId) {
                        modalApartmentEmission.close();
                    }

                    if (modalAddRental.payload?.aptId === targetId) {
                        modalAddRental.close();
                    }

                    const filteredApartments = apartments.filter((a) => a?.id !== targetId);

                    if (!filteredApartments.length) {
                        if (page === 0) {
                            apartmentsQuery.refetch();
                        } else {
                            onChangePage((page ?? 1) - 1);
                        }
                        return queryData;
                    }

                    return update(queryData, {
                        auctions: {
                            apartments: () => filteredApartments,
                            pageInfo: {
                                total: (total) => total - 1
                            }
                        }
                    });
                } else if (isNewApartment && page === 0 && apartments.every((apt) => apt?.id !== targetId)) {
                    return update(queryData, {
                        auctions: {
                            apartments: () => {
                                const newApartments = [target, ...apartments];
                                if (newApartments.length > STEP) newApartments.length = STEP;
                                return newApartments;
                            },
                            pageInfo: {
                                total: (total) => total + 1
                            }
                        }
                    });
                }

                return queryData;
            });
        }
    });

    return (
        <Apartments
            loading={loading}
            onClickReload={() => apartmentsQuery.refetch()}
            error={error?.message}
            isEmpty={!apartments?.length}
            page={page}
            pageCount={Math.ceil(total / STEP)}
            empty={{
                icon: <IconTrophy />,
                title: t('pageManage.contest.finished.empty.title'),
                text: t('pageManage.contest.finished.empty.text')
            }}
            onChangePage={onChangePage}
        >
            {apartments?.map((apartment) => {
                if (!apartment) return null;
                const { isTokenized, latestAuction, tokenizationInfo } = apartment;
                const hasAllSigns = !!(tokenizationInfo?.isSignedByNotary && tokenizationInfo.isSignedByOwner);
                const isWaitingWinner = !latestAuction?.isWinnerAgree;
                const isWaitingSign = !!latestAuction?.isWinnerAgree && !isTokenized && !hasAllSigns;
                const isWaitingEmission = !!latestAuction?.isWinnerAgree && !isTokenized && hasAllSigns;
                const isWaitingPayment = !!latestAuction?.isWinnerAgree && isTokenized;
                const isWinnerSelectionInProgress = latestAuction?.isWinnerSelectionInProgress;
                return (
                    <Card
                        data={apartment}
                        key={apartment.id}
                        to={URL_MAP.apartment(apartment.id)}
                        status={
                            <Status
                                className={cx('Status')}
                                color={
                                    isWinnerSelectionInProgress
                                        ? 'grue'
                                        : isWaitingWinner
                                        ? 'orange'
                                        : isWaitingSign
                                        ? 'blue-light'
                                        : isWaitingEmission
                                        ? 'blue'
                                        : 'green'
                                }
                            >
                                {t(
                                    `pageManage.contest.finished.card.status.${
                                        isWinnerSelectionInProgress
                                            ? 'WINNER_SELECTION'
                                            : isWaitingWinner
                                            ? 'WAITING_WINNER'
                                            : isWaitingSign
                                            ? 'WAITING_SIGN'
                                            : isWaitingEmission
                                            ? 'WAITING_EMISSION'
                                            : isWaitingPayment
                                            ? 'WAITING_PAYMENT'
                                            : ''
                                    }`
                                )}
                            </Status>
                        }
                        params={[
                            !isWinnerSelectionInProgress
                                ? {
                                      label: t('pageManage.contest.finished.card.params.winner', {
                                          context: isWaitingWinner
                                              ? latestAuction?.winnerRank === 0
                                                  ? 'first'
                                                  : latestAuction?.winnerRank === 1
                                                  ? 'second'
                                                  : 'third'
                                              : ''
                                      }),
                                      value: latestAuction?.winnerAccount
                                  }
                                : null,

                            !isWinnerSelectionInProgress && (isWaitingWinner || isWaitingPayment)
                                ? {
                                      label: t('pageManage.contest.finished.card.params.timeLeft'),
                                      value: (
                                          <Timer
                                              endTime={
                                                  latestAuction?.isWinnerAgree
                                                      ? latestAuction?.deadlineToPayCommunal
                                                      : latestAuction?.deadlineForWinner
                                              }
                                          />
                                      )
                                  }
                                : null,
                            {
                                label: t('pageManage.contest.finished.card.params.endOfActivePhase'),
                                value: formatDate(latestAuction?.endOfActivePhase)
                            }
                        ]}
                        actions={
                            !isWinnerSelectionInProgress && (
                                <>
                                    {isWaitingSign && (
                                        <Button
                                            onClick={() =>
                                                modalApartmentSignTokenization.open({
                                                    aptId: apartment.id,
                                                    type: 'notary'
                                                })
                                            }
                                            color="primary"
                                        >
                                            {t('pageManage.contest.finished.card.actions.sign')}
                                        </Button>
                                    )}
                                    {isWaitingEmission && (
                                        <Button
                                            onClick={() =>
                                                modalApartmentEmission.open({
                                                    aptId: apartment.id
                                                })
                                            }
                                            color="primary"
                                        >
                                            {t('pageManage.contest.finished.card.actions.emission')}
                                        </Button>
                                    )}
                                    {isWaitingPayment && (
                                        <Button
                                            onClick={() =>
                                                modalAddRental.open({
                                                    aptId: apartment.id,
                                                    onSuccess: () => removeApartmentFromList(apartment.id)
                                                })
                                            }
                                            color="primary"
                                        >
                                            {t('pageManage.contest.finished.card.actions.addRental')}
                                        </Button>
                                    )}

                                    {!isTokenized && (
                                        <Button
                                            onClick={() =>
                                                modalContacts.open({
                                                    aptId: apartment.id,
                                                    type: 'owner'
                                                })
                                            }
                                            color="secondary"
                                        >
                                            {t('pageManage.contest.finished.card.actions.contactOwner')}
                                        </Button>
                                    )}

                                    <Button
                                        onClick={() =>
                                            modalContacts.open({
                                                aptId: apartment.id,
                                                type: 'winner'
                                            })
                                        }
                                        color="secondary"
                                    >
                                        {t('pageManage.contest.finished.card.actions.contactWinner')}
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            modalCancelAuctionAsNotary.open({
                                                id: apartment.id,
                                                onSuccess: () => removeApartmentFromList(apartment.id)
                                            })
                                        }
                                        color="tertiary"
                                    >
                                        {t('pageManage.contest.finished.card.actions.cancel')}
                                    </Button>
                                </>
                            )
                        }
                    />
                );
            })}

            <ModalContacts {...modalContacts} {...modalContacts.payload!} />
            <ModalApartmentEmission {...modalApartmentEmission} {...modalApartmentEmission.payload!} />
            <ModalApartmentSignTokenization
                {...modalApartmentSignTokenization}
                {...modalApartmentSignTokenization.payload!}
            />
            <ModalAddRental {...modalAddRental} {...modalAddRental.payload!} />
            <ModalCancelContestAsNotary {...modalCancelAuctionAsNotary} {...modalCancelAuctionAsNotary.payload!} />
        </Apartments>
    );
};
const Contest = () => {
    const [t] = useTranslation();
    const [sort, setSort] = React.useState(AuctionSortEnum.UpdatedForRdDesc);
    const { activeContestsNum, finishedContestsNum } = useManageContext()?.city?.notaryUpdates ?? {};

    return (
        <Section>
            <SectionHeader withTabs>
                <SectionTitle>{t('pageManage.contest.title')}</SectionTitle>
                <SectionHeaderPanel>
                    <SectionTabs
                        options={[
                            {
                                exact: true,
                                to: URL_MAP.manage.contests.index,
                                children: (
                                    <>
                                        {t('pageManage.contest.active.title')}
                                        {!!activeContestsNum && <Counter>{activeContestsNum}</Counter>}
                                    </>
                                )
                            },
                            {
                                to: URL_MAP.manage.contests.finished,
                                children: (
                                    <>
                                        {t('pageManage.contest.finished.title')}
                                        {!!finishedContestsNum && <Counter>{finishedContestsNum}</Counter>}
                                    </>
                                )
                            }
                        ]}
                    />

                    <SectionSort
                        options={[
                            {
                                isActive: sort === AuctionSortEnum.UpdatedForRdDesc,
                                label: t(`pageManage.contest.sort.${AuctionSortEnum.UpdatedForRdDesc}`),
                                onClick: () => setSort(AuctionSortEnum.UpdatedForRdDesc)
                            },
                            {
                                isActive: sort === AuctionSortEnum.UpdatedForRd,
                                label: t(`pageManage.contest.sort.${AuctionSortEnum.UpdatedForRd}`),
                                onClick: () => setSort(AuctionSortEnum.UpdatedForRd)
                            }
                        ]}
                    />
                </SectionHeaderPanel>
            </SectionHeader>

            <SectionMain>
                <Route exact path={URL_MAP.manage.contests.index} render={() => <Active sort={sort} />} />
                <Route path={URL_MAP.manage.contests.finished} render={() => <Finished sort={sort} />} />
            </SectionMain>
        </Section>
    );
};

export default React.memo(Contest);
