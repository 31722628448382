import { useChangePasswordMutation } from 'apollo/generted';
import useMeQuery from 'helpers/useMeQuery';
import classNames from 'classnames/bind';
import Modal, { ModalBody, ModalHeader, ModalProps, useModalContext } from 'components/UIKit/Modal';
import ModalErrorScreen from 'components/UIKit/Modal/ErrorScreen';
import PasswordField from 'components/UIKit/PasswordField';
import Button from 'components/UIKit/Button';
import { getErrorData, getErrorI18nText } from 'helpers';
import { useFormField, useToast } from 'helpers/hooks';
import validatePassword from 'helpers/validatePassword';
import React, { FormEvent, Fragment, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

const ModalChangePassword = memo((props: ModalProps) => {
    return (
        <Modal {...props}>
            <Main />
        </Modal>
    );
});

const Main = memo(() => {
    const [t] = useTranslation();
    const oldPassword = useFormField('');
    const newPassword = useFormField('');
    const repeatPassword = useFormField('');
    const toast = useToast();
    const { close } = useModalContext();
    const [formError, setFormError] = useState('');
    const hasPassword = useMeQuery().data?.me?.hasPassword;
    const type = hasPassword ? 'edit' : 'add';

    const [changePasswordMutation, { loading }] = useChangePasswordMutation();

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setFormError('');
        oldPassword.changeError('');
        repeatPassword.changeError('');
        newPassword.changeError('');

        if (repeatPassword.value !== newPassword.value) {
            return repeatPassword.changeError(t('modalChangePassword.error.PasswordsDoesnotMatch'));
        }

        changePasswordMutation({
            variables: {
                input: {
                    newPassword: newPassword.value,
                    oldPassword: hasPassword ? oldPassword.value : undefined
                }
            }
        })
            .then(({ data }) => {
                const changePassword = data?.changePassword!;
                if (changePassword.__typename === 'ChangePasswordSuccess') {
                    toast.success(
                        t(`modalChangePassword.successMsg`, {
                            context: type
                        })
                    );
                    close();
                } else {
                    const { errorMessage = '', __typename } = changePassword ?? {};
                    if (__typename === 'InvalidCredentialsError') {
                        oldPassword.changeError(t(['modalChangePassword.error.InvalidCredentialsError', errorMessage]));
                    } else if (__typename === 'InvalidPasswordError') {
                        newPassword.changeError(t(['modalChangePassword.error.InvalidPasswordError', errorMessage]));
                    } else {
                        setFormError(
                            t([
                                `modalChangePassword.error.${changePassword?.__typename}`,
                                getErrorI18nText(t, changePassword?.errorMessage)
                            ])
                        );
                    }
                }
            })
            .catch((e) => {
                setFormError(getErrorI18nText(t, getErrorData(e).message));
            });
    };

    const passwordValidation = validatePassword(newPassword.value);

    const disabled = !newPassword.value || !repeatPassword.value || (hasPassword && !oldPassword.value);

    return (
        <Fragment>
            <ModalHeader>{t(`modalChangePassword.title`, { context: type })}</ModalHeader>
            <ModalBody>
                <form onSubmit={onSubmit}>
                    {hasPassword && (
                        <PasswordField
                            label={t('modalChangePassword.password', { context: 'old' })}
                            value={oldPassword.value}
                            error={oldPassword.error}
                            onChange={(e) => oldPassword.change(e.target.value)}
                        />
                    )}
                    <PasswordField
                        label={t('modalChangePassword.password', { context: 'new' })}
                        value={newPassword.value}
                        error={newPassword.error}
                        onChange={(e) => newPassword.change(e.target.value)}
                        rules={passwordValidation}
                    />
                    <PasswordField
                        label={t('modalChangePassword.password', { context: 'repeat' })}
                        value={repeatPassword.value}
                        error={repeatPassword.error}
                        onChange={(e) => repeatPassword.change(e.target.value)}
                    />
                    <Button
                        disabled={disabled}
                        loading={loading}
                        type="submit"
                        className={cx('BtnSubmit')}
                        color="primary"
                    >
                        {t('modalChangePassword.btnSubmit')}
                    </Button>
                </form>
            </ModalBody>
            <ModalErrorScreen resetError={() => setFormError('')} text={formError} />
        </Fragment>
    );
});

export default ModalChangePassword;
