import { ApartmentNode, useUpdateAptFavoritesMutation } from 'apollo/generted';
import { IconHeart, IconHeartActive } from 'components/Icons';
import Button from 'components/UIKit/Button';
import { getErrorData, useGlobalError } from 'helpers';
import useCallWithCheckAuth from 'helpers/useCallWithCheckAuth';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

export type Props = Pick<ApartmentNode, 'id' | 'isFavorite'> & {
    className?: string;
};

const BtnFavorite = ({ id, isFavorite, className }: Props) => {
    const callWithCheckAuth = useCallWithCheckAuth();
    const onGlobalError = useGlobalError();
    const [t] = useTranslation();
    const [updateAptFavoritesMutation] = useUpdateAptFavoritesMutation();

    const toggle = () => {
        updateAptFavoritesMutation({
            variables: {
                input: {
                    aptId: id
                }
            },
            optimisticResponse: {
                __typename: 'Mutations',
                updateAptFavorites: {
                    __typename: 'UpdateAptFavoriteSuccess',
                    apt: {
                        __typename: 'ApartmentNode',
                        id,
                        isFavorite: !isFavorite ?? null
                    }
                }
            }
        })
            .then(({ data }) => {
                if (data?.updateAptFavorites?.__typename !== 'UpdateAptFavoriteSuccess') {
                    onGlobalError(
                        t([
                            `updateAptFavorites.error.${data?.updateAptFavorites?.__typename}`,
                            data?.updateAptFavorites?.errorMessage ?? ''
                        ])
                    );
                }
            })
            .catch((e) => {
                onGlobalError(getErrorData(e).message);
            });
    };

    const onClick = () => callWithCheckAuth(toggle);

    return (
        <Button
            type="button"
            onClick={onClick}
            className={cx('Component', className)}
            round
            color="white-shadow"
            iconLeft={isFavorite ? <IconHeartActive className={cx('IconActive')} /> : <IconHeart />}
        />
    );
};

export default BtnFavorite;
