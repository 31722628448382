import { ConstructAddressVariables, useConstructAddress } from 'helpers/useParsedApartmentData';
import React from 'react';

export type Props = ConstructAddressVariables & {
    className?: string;
    hideCityName?: boolean;
};

const Address = ({ className, hideCityName, ...props }: Props) => {
    const constructAddress = useConstructAddress();

    return <address className={className}>{constructAddress(props, { hideCityName })}</address>;
};

export default Address;
