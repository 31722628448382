import ModalShare, { Props as ModalShareProps } from 'containers/Modals/Share';
import { useModal } from 'helpers';

import { IconSharing } from 'components/Icons';
import React from 'react';
import Button from 'components/UIKit/Button';

export type Props = ModalShareProps;

const BtnShare = (props: Props) => {
    const modal = useModal(false);

    return (
        <>
            <Button type="button" color="white-shadow" onClick={modal.open} round iconLeft={<IconSharing />} />
            <ModalShare {...modal} {...props} />
        </>
    );
};

export default BtnShare;
