import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AptStatus, useBurnListsMutation, useManagerBuyoutAgreementParamsQuery } from 'apollo/generted';
import { useToast } from 'helpers/hooks';
import { useConstructAddress } from 'helpers/useParsedApartmentData';
import { getErrorI18nText, getErrorData } from 'helpers';
import formatDate from 'helpers/formatDate';
import Alert from 'components/UIKit/Alert';
import Modal, {
    ModalBody,
    ModalBtnGroup,
    ModalContent,
    ModalHeader,
    ModalProps,
    ModalTextParam,
    useModalContext
} from 'components/UIKit/Modal';
import Button from 'components/UIKit/Button';
import ModalLoadingScreen from 'components/UIKit/Modal/LoadingScreen';
import ModalErrorScreen from 'components/UIKit/Modal/ErrorScreen';
import CheckboxField from 'components/UIKit/CheckboxField';
import ScanQRSection from 'components/ScanQRSection';
import { ManagerBuyoutAgreementProps } from './ManagerBuyoutAgreement.d';

const ModalManagerBuyoutAgreement = ({
    buyRequestId,
    isFinishing,
    onSuccessFinish,
    ...props
}: ModalProps & ManagerBuyoutAgreementProps) => {
    const [t] = useTranslation();

    return (
        <Modal {...props}>
            <ModalHeader>
                {t('modalManagerBuyoutAgreement.title', {
                    context: isFinishing ? 'isFinishing' : ''
                })}
            </ModalHeader>
            <Main buyRequestId={buyRequestId} isFinishing={isFinishing} onSuccessFinish={onSuccessFinish} />
        </Modal>
    );
};

const Main = ({ buyRequestId, isFinishing, onSuccessFinish }: ManagerBuyoutAgreementProps) => {
    const query = useManagerBuyoutAgreementParamsQuery({ variables: { buyRequestId } });
    const constructAddress = useConstructAddress();
    const [t] = useTranslation();
    const { close } = useModalContext();
    const toast = useToast();
    const [burnListsMutation, { loading }] = useBurnListsMutation();

    const [showQr, setShowQr] = React.useState(false);
    const [agree, setAgree] = React.useState(false);
    const [formError, setFormError] = React.useState('');

    const onClickSubmit = () => {
        if (isFinishing) {
            burnListsMutation({ variables: { input: { buyRequestId } } })
                .then(({ data }) => {
                    if (data?.burnLists?.__typename === 'BurnListsSuccess') {
                        toast.success(t('modalManagerBuyoutAgreement.successMsg'));
                        onSuccessFinish?.();
                        close();
                    } else {
                        setFormError(
                            t([
                                `modalManagerBuyoutAgreement.error.${data?.burnLists?.__typename}`,
                                getErrorI18nText(t, data?.burnLists?.errorMessage)
                            ])
                        );
                    }
                })
                .catch((e) => {
                    setFormError(getErrorI18nText(t, getErrorData(e).message));
                });
        } else {
            setShowQr(true);
        }
    };

    if (query.loading)
        return (
            <ModalBody>
                <ModalLoadingScreen />
            </ModalBody>
        );

    const error = query.error && getErrorI18nText(t, getErrorData(query.error).message);

    if (error)
        return (
            <ModalBody>
                <ModalErrorScreen isStatic text={error} resetError={() => query.refetch()} />
            </ModalBody>
        );

    const buyRequest = query.data?.buyRequest;
    const { status, actualRental, m2 } = buyRequest?.apt ?? {};
    const deepLink = buyRequest?.rdApproveDeeplink ?? '';

    return (
        <>
            {showQr ? (
                <ModalBody>
                    <ScanQRSection
                        qrData={deepLink}
                        deepLink={deepLink}
                        text={
                            <Trans i18nKey="modalManagerBuyoutAgreement.qrText">
                                <b />
                            </Trans>
                        }
                    />
                </ModalBody>
            ) : (
                <ModalBody>
                    <ModalContent withBorder>
                        {status === AptStatus.Rented && (
                            <Alert>
                                {t('modalManagerBuyoutAgreement.rentalAlert', {
                                    account: actualRental?.tenantAccount
                                })}
                            </Alert>
                        )}
                        <ModalTextParam
                            label={t('apartmentStats.address.label')}
                            value={constructAddress(buyRequest?.apt)}
                        />
                        <ModalTextParam
                            label={t('modalManagerBuyoutAgreement.params.applicant')}
                            value={buyRequest?.buyerAccount}
                        />
                        <ModalTextParam
                            label={t('modalManagerBuyoutAgreement.params.date')}
                            value={formatDate(buyRequest?.transferTime)}
                        />

                        {/* TODO: Find what is `isFinishing` do */}
                        {/*{isFinishing && (*/}
                        {/*    <ModalTextParam*/}
                        {/*        label={t('modalManagerBuyoutAgreement.params.retire')}*/}
                        {/*        value={`${buyRequest?.changeAmount} ${buyRequest?.tokenId}`}*/}
                        {/*    />*/}
                        {/*)}*/}
                        {/*{!isFinishing && (*/}
                        {/*    <ModalTextParam*/}
                        {/*        label={t('apartmentStats.area.total')}*/}
                        {/*        value={`${m2} ${t('apartmentStats.area.unit')}`}*/}
                        {/*    />*/}
                        {/*)}*/}

                        <ModalTextParam
                            label={t('apartmentStats.area.total')}
                            value={`${m2} ${t('apartmentStats.area.unit')}`}
                        />
                        <ModalTextParam
                            label={t('modalManagerBuyoutAgreement.params.amount')}
                            value={`${buyRequest?.changeAmount} ${buyRequest?.tokenId}`}
                        />
                        <ModalTextParam
                            label={t('modalManagerBuyoutAgreement.params.fee')}
                            value={`${buyRequest?.feeAmount} ${buyRequest?.tokenId}`}
                        />
                    </ModalContent>

                    <CheckboxField
                        label={t('modalManagerBuyoutAgreement.agree')}
                        checked={agree}
                        onChange={(e) => setAgree(e.target.checked)}
                    />

                    <ModalBtnGroup>
                        <Button color="primary" disabled={!agree} onClick={onClickSubmit} loading={loading}>
                            {t('modalManagerBuyoutAgreement.btnSubmit', {
                                context: isFinishing ? 'isFinishing' : ''
                            })}
                        </Button>
                    </ModalBtnGroup>
                </ModalBody>
            )}

            <ModalErrorScreen text={formError} resetError={() => setFormError('')} />
        </>
    );
};

export default ModalManagerBuyoutAgreement;
