import { ApartmentNode, AptStatus, AuctionNode, Maybe } from 'apollo/generted';

export type Data = Pick<ApartmentNode, 'status'> & {
    latestAuction?: Maybe<Pick<AuctionNode, 'winnerAccount' | 'isWinnerSelectionInProgress' | 'hasMyBid'>>;
};

export const useConstructApartmentStatus = (apartment?: Data) => {
    const { status, latestAuction } = apartment ?? {};
    const { winnerAccount, isWinnerSelectionInProgress, hasMyBid } = latestAuction ?? {};
    /** alias for statuses */
    const isContestFinished = status === AptStatus.ContestFinished;
    const inContest = status === AptStatus.Contest;
    const isRework = status === AptStatus.Rework;
    const isRented = status === AptStatus.Rented;
    const isDraft = status === AptStatus.Draft;
    const isLocked = status === AptStatus.Locked;
    const isEviction = status === AptStatus.Eviction;
    const isWaitingContest = status === AptStatus.WaitingContest;
    const inRDModeration = status === AptStatus.Moderation;
    const isSold = status === AptStatus.Sold;

    /** alias for modes */
    const hasNoWinners = isContestFinished && !isWinnerSelectionInProgress && !winnerAccount;
    const isGuestAuctionView = inContest && !hasMyBid;

    return {
        hasNoWinners,
        isWinnerSelectionInProgress,
        isGuestAuctionView,
        //
        isLocked,
        isWaitingContest,
        inContest,
        isContestFinished,
        isRework,
        isRented,
        isDraft,
        inRDModeration,
        isEviction,
        isSold
    };
};
