import { WalletName } from 'apollo/generted';

export const HELP_EMAIL = 'info@list.family';

export const MAP_ACCESS_TOKEN = 'AIzaSyDoR0Qp4gHacEklPg7wfimjCDI-vFlf8mM';

export const IOS_APP_LINK = 'https://apps.apple.com/app/paycash/id1557721025';
export const ANDROID_APP_LINK = 'https://play.google.com/store/apps/details?id=online.paycash.app';

export const PAYCASHSWAP_TOKENS = 'https://paycashswap.com/tokens';
export const PAYCASH_LANDING = 'https://paycash.app';
export const PAYCASH_LANDING_TOKEN = 'https://paycash.app/tokens';
// TODO: Why not used?
// export const VIDEO_TUTORIAL_CREATE_WALLET = 'https://youtu.be/x3B-d-DDlow';
// export const VIDEO_TUTORIAL_BUY_CRYPTO = 'https://youtu.be/oezWWrRCbwg';

export const LOCAL_STORAGE_KEY_TOKEN_AUTH = 'token';
export const LOCAL_STORAGE_LNG_KEY = 'defaultLng';
export const LOCAL_STORAGE_SELECTED_REGIONS_LIST = 'selectedRegionsList';

type ID = string | number | null;
/** Global site URL map */
const profileBase = '/profile';
const manageBase = '/manage';
export const URL_MAP = {
    landing: '/',
    docs: {
        index: '/documentation',
        charter: '/documentation/charter',
        requirementsForApartments: '/documentation/requirements-for-apartments',
        other: '/documentation/other'
    },
    regions: '/regions',
    contacts: '/contacts',

    search: '/search',

    explore: '/explore',

    statistics: `/statistics`,

    apartment: (id: ID = ':id') => `/apartment/${id}`,
    validateContest: (id: ID = ':id') => `/apartment/${id}/validate-contest`,

    addApartment: '/add-apartment',
    editApartment: (id: ID = ':id') => `/edit-apartment/${id}`,

    reviewApartment: (id: ID = ':id') => `/review-apartment/${id}`,

    faq: '/faq',

    help: {
        index: '/help',
        contestParticipance: '/help/contest-participance',
        contestResultValidation: '/help/contest-result-validation',
        tokenizationProcess: '/help/tokenization-process',
        addApartmentProcess: '/help/add-apartment-process',
        buyoutProcess: '/help/buyout-process',
        createBuyoutRequest: '/help/create-buyout-request'
    },

    profile: {
        index: profileBase,
        profileSettings: `${profileBase}/settings`,
        notificationSettings: `${profileBase}/notifications`,
        safety: `${profileBase}/safety`,
        myFavorites: `${profileBase}/my-favorites`,
        myApartments: `${profileBase}/my-apartments`,
        myRentals: `${profileBase}/my-rentals`,
        buyout: `${profileBase}/buyout`
    },

    manage: {
        index: manageBase,
        incoming: {
            index: `${manageBase}/incoming`,
            rework: `${manageBase}/incoming/rework`
        },
        contests: {
            index: `${manageBase}/contests`,
            finished: `${manageBase}/contests/finished`
        },
        get rentals() {
            return `${this.index}/rentals`;
        },
        get evictions() {
            return `${this.index}/evictions`;
        },
        get waitingContest() {
            return `${this.index}/waiting-contest`;
        },
        buyout: {
            index: `${manageBase}/buyout`,
            finished: `${manageBase}/buyout/finished`
        },
        get lockedApartments() {
            return `${this.index}/locked-apartments`;
        },
        get regionSettings() {
            return `${this.index}/region-settings`;
        }
    }
};

// SIZE BREAKPOINTS
export const BREAKPOINT_PHONE = 576;
export const BREAKPOINT_LANDSCAPE = 768;
export const BREAKPOINT_TABLET = 1024;
export const BREAKPOINT_IPAD = 1280;
export const BREAKPOINT_LAPTOP = 1440;

// Input patterns
export const PATTERN_EXPENSES = '[1-9]\\d{0,8}';

export const EOS_WALLET_LIST = [
    {
        type: WalletName.Paycash,
        logo: require('assets/images/logo_paycash.svg').default,
        homeLink: 'https://paycash.app',
        label: 'PayCash'
    },
    {
        type: WalletName.Anchor,
        logo: require('assets/images/logo_anchor.svg').default,
        homeLink: 'https://greymass.com/anchor/',
        label: 'Anchor'
    }
] as const;
