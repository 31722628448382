import {
    ApartmentNode,
    CityNode,
    RegionExpensesQuery,
    useStartAuctionMutation,
    useStartAuctionParamsQuery
} from 'apollo/generted';
import classNames from 'classnames/bind';
import Button from 'components/UIKit/Button';
import CheckboxField from 'components/UIKit/CheckboxField';
import Modal, {
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalProps,
    ModalText,
    useModalContext
} from 'components/UIKit/Modal';
import ModalErrorScreen from 'components/UIKit/Modal/ErrorScreen';
import ModalLoadingScreen from 'components/UIKit/Modal/LoadingScreen';
import TextField from 'components/UIKit/TextField';
import { PATTERN_EXPENSES } from 'constant';
import { addSpacesForThousands, getErrorData, getErrorI18nText } from 'helpers';
import { useFormField, useToast } from 'helpers/hooks';
import validator from 'helpers/validator';
import update from 'immutability-helper';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

export type Props = {
    aptId: ApartmentNode['id'];
    cityId: CityNode['id'];
    onSuccess?(): void;
};

const getNumber = (v: string) => parseInt(v || '0');

const ModalStartAuction = ({ cityId, aptId, onSuccess, ...props }: Props & ModalProps) => {
    return (
        <Modal {...props}>
            <Main aptId={aptId} cityId={cityId} onSuccess={onSuccess} />
        </Modal>
    );
};

type ExpenseFields = {
    value: string;
    expense: NonNullable<RegionExpensesQuery['regionExpenses']>[number];
}[];

const Main = ({ aptId, cityId, onSuccess }: Props) => {
    const [t] = useTranslation();
    const { close } = useModalContext();
    const [agree, setAgree] = useState(false);
    const [error, setError] = useState('');
    const [startAuctionMutation, { loading }] = useStartAuctionMutation();
    const communalPayment = useFormField('');
    const [expenseFields, setExpenseFields] = useState<ExpenseFields>();
    const startAuctionParamsQuery = useStartAuctionParamsQuery({
        variables: { cityId: cityId!, aptId: aptId! }
    });

    const toast = useToast();

    const { apartment, aptExpenses, regionExpenses } = startAuctionParamsQuery.data ?? {};
    const currency = apartment?.communalCurrency ?? '';

    const submit = () => {
        startAuctionMutation({
            variables: {
                input: {
                    aptId,
                    communalPayment: communalPayment.value,
                    expenses:
                        expenseFields?.map(({ value, expense }) => ({
                            expenseId: expense?.id!,
                            value
                        })) ?? []
                }
            }
        })
            .then(({ data }) => {
                const startAuction = data?.startAuction!;
                if (startAuction.__typename === 'StartAuctionSuccess') {
                    toast.success(t('modalStartAuction.successMsg'));
                    onSuccess?.();
                    close();
                } else {
                    setError(
                        t([
                            `modalStartAuction.error.${startAuction?.__typename}`,
                            getErrorI18nText(t, startAuction?.errorMessage)
                        ])
                    );
                }
            })
            .catch((e) => {
                setError(getErrorI18nText(t, getErrorData(e).message));
            });
    };

    useEffect(() => {
        if (startAuctionParamsQuery.data) {
            communalPayment.change(apartment?.communalPayment ?? '');
            setExpenseFields(
                regionExpenses?.map((expense) => {
                    return {
                        expense,
                        value:
                            aptExpenses
                                ?.find((aptExpense) => aptExpense?.expense.id === expense?.id)
                                ?.value?.toString() ?? ''
                    };
                }) ?? []
            );
        }
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startAuctionParamsQuery.data]);

    const totalPayments = useMemo(() => {
        return (
            getNumber(communalPayment.value) +
            (expenseFields?.reduce((sum, item) => sum + getNumber(item.value), 0) ?? 0)
        );
    }, [communalPayment.value, expenseFields]);

    const onChangeExpenseField = (value: string, i: number) => {
        setExpenseFields(
            update(expenseFields, {
                [i]: {
                    value: () => value
                }
            })
        );
    };

    const disabledSubmitBtn = expenseFields?.some((exp) => !exp.value) || !agree || !communalPayment.value;

    return (
        <Fragment>
            <ModalHeader>{t('modalStartAuction.title')}</ModalHeader>
            <ModalBody>
                {startAuctionParamsQuery.loading ? (
                    <ModalLoadingScreen />
                ) : (
                    <Fragment>
                        <ModalContent>
                            <ModalText>{t('modalStartAuction.text')}</ModalText>

                            <div className={cx('Field')}>
                                <label className={cx('FieldLabel')} htmlFor="communalPayment">
                                    {t('apartmentStats.communalPayment.label')}
                                </label>

                                <TextField
                                    value={communalPayment.value}
                                    error={communalPayment.error}
                                    id="communalPayment"
                                    pattern={PATTERN_EXPENSES}
                                    label={t('modalStartAuction.expenseField')}
                                    rightControl={currency}
                                    onChange={(e) => communalPayment.change(validator(e, communalPayment.value))}
                                />
                            </div>
                            {expenseFields?.map(({ value, expense }, i) => {
                                const id = expense?.id.toString();
                                return (
                                    <div className={cx('Field')} key={id}>
                                        <label className={cx('FieldLabel')} htmlFor={id}>
                                            {expense?.name}
                                        </label>

                                        <TextField
                                            value={value}
                                            id={id}
                                            pattern={PATTERN_EXPENSES}
                                            label={t('modalStartAuction.expenseField')}
                                            rightControl={currency}
                                            onChange={(e) => onChangeExpenseField(validator(e, value), i)}
                                        />
                                    </div>
                                );
                            })}

                            <ul className={cx('Details')}>
                                <li>
                                    <span>{t('apartmentStats.communalPayment.label')}</span>
                                    <span>
                                        {addSpacesForThousands(getNumber(communalPayment.value))} {currency}
                                    </span>
                                </li>

                                {expenseFields?.map(({ value, expense }) => {
                                    return (
                                        <li key={expense?.id}>
                                            <span>{expense?.name}</span>
                                            <span>
                                                {addSpacesForThousands(getNumber(value))} {currency}
                                            </span>
                                        </li>
                                    );
                                })}

                                <li className={cx('total')}>
                                    <span>{t('modalStartAuction.totalPaymentDetails')}</span>
                                    <span>
                                        {addSpacesForThousands(totalPayments.toString())} {currency}
                                    </span>
                                </li>
                            </ul>
                        </ModalContent>

                        <CheckboxField
                            checked={agree}
                            label={t('modalStartAuction.agree')}
                            onChange={(e) => setAgree(e.target.checked)}
                        />

                        <Button
                            disabled={disabledSubmitBtn}
                            loading={loading}
                            type="button"
                            color="primary"
                            onClick={submit}
                        >
                            {t('modalStartAuction.btnSubmit')}
                        </Button>

                        <ModalErrorScreen text={error} resetError={() => setError('')} />
                    </Fragment>
                )}
            </ModalBody>
        </Fragment>
    );
};

export default ModalStartAuction;
