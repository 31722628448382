import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { IconEos, IconMail, IconMobile } from 'components/Icons';
import { useTranslation } from 'react-i18next';
import ProfileParam from '../ProfileParam';
const cx = classNames.bind(styles);

type Props = {
    className?: string;
    phone?: string | null;
    email?: string | null;
    eosAccount?: string | null;
};

const Contacts = ({ className = '', phone, email, eosAccount }: Props) => {
    const [t] = useTranslation();

    return (
        <ul className={cx('Component', className)}>
            {phone && (
                <ProfileParam
                    tag="li"
                    icon={<IconMobile />}
                    label={t('global.contacts.phone')}
                    value={<a href={`tel:${phone}`}>{phone}</a>}
                />
            )}
            {email && (
                <ProfileParam
                    tag="li"
                    icon={<IconMail />}
                    label={t('global.contacts.email')}
                    value={<a href={`mailto:${email}`}>{email}</a>}
                />
            )}
            {eosAccount && (
                <ProfileParam tag="li" icon={<IconEos />} label={t('global.contacts.eosAccount')} value={eosAccount} />
            )}
        </ul>
    );
};

export default Contacts;
