import Status from './Status';
import StatusDescription from './StatusDescription';
import Address from './Address';
import * as Actions from './Actions';
import Price from './Price/Price';
import Expenses from './Expenses/Expenses';
import StatusParams from './StatusParams';
import UserActions from './UserActions';
import EvictionReason from './EvictionReason';

const Apartment = {
    Status,
    StatusDescription,
    StatusParams,
    Address,
    Actions,
    Price,
    Expenses,
    UserActions,
    EvictionReason
};

export default Apartment;
