import { useConfirmEvictionMutation } from 'apollo/generted';
import Button from 'components/UIKit/Button';
import CheckboxField from 'components/UIKit/CheckboxField';
import Modal, {
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalProps,
    ModalText,
    useModalContext
} from 'components/UIKit/Modal';
import ModalErrorScreen from 'components/UIKit/Modal/ErrorScreen';
import { getErrorData, getErrorI18nText } from 'helpers';
import { useToast } from 'helpers/hooks';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type Props = {
    id: number;
    onSuccess?(): void;
};

const ModalConfirmEviction = ({ id, onSuccess, ...props }: ModalProps & Props) => {
    return (
        <Modal {...props}>
            <Main id={id} onSuccess={onSuccess} />
        </Modal>
    );
};

const Main = ({ id, onSuccess }: Props) => {
    const [t] = useTranslation();
    const { close } = useModalContext();
    const [formError, setFormError] = useState('');
    const [agree, setAgree] = useState(false);
    const [confirmEvictionMutation, { loading }] = useConfirmEvictionMutation();
    const toast = useToast();

    const confirmEviction = () => {
        confirmEvictionMutation({ variables: { input: { aptId: id } } })
            .then(({ data }) => {
                const confirmEviction = data?.confirmEviction! ?? {};
                if (confirmEviction.__typename === 'ConfirmEvictionSuccess') {
                    toast.success(t('modalConfirmEviction.successMsg'));
                    onSuccess?.();
                    close();
                } else {
                    setFormError(
                        t([
                            `modalConfirmEviction.error.${confirmEviction.__typename}`,
                            getErrorI18nText(t, confirmEviction.errorMessage)
                        ])
                    );
                }
            })
            .catch((e) => {
                setFormError(getErrorI18nText(t, getErrorData(e).message));
            });
    };

    return (
        <Fragment>
            <ModalHeader>{t('modalConfirmEviction.title')}</ModalHeader>
            <ModalBody>
                <ModalContent withBorder>
                    <ModalText>{t('modalConfirmEviction.description')}</ModalText>
                </ModalContent>
                <CheckboxField
                    label={t('modalConfirmEviction.agree')}
                    checked={agree}
                    onChange={(e) => setAgree(e.target.checked)}
                />

                <Button color="primary" loading={loading} disabled={!agree} onClick={confirmEviction} type="button">
                    {t('modalConfirmEviction.btnSubmit')}
                </Button>

                <ModalErrorScreen resetError={() => setFormError('')} text={formError} />
            </ModalBody>
        </Fragment>
    );
};

export default ModalConfirmEviction;
