import { NotaryUpdatesFragment, useCitiesManagedByMeQuery, useCityNotaryUpdatesWsSubscription } from 'apollo/generted';
import classNames from 'classnames/bind';
import {
    IconAngleDown,
    IconFolderWithLabel,
    IconInbox,
    IconRepeat,
    IconSetting,
    IconTrophy,
    IconWarningNew,
    IconEviction,
    IconBlockFlats,
    IconHome
} from 'components/Icons';
import Loader from 'components/Loader';
import CityFlag from 'components/UIKit/CityFlag';
import { BREAKPOINT_TABLET, URL_MAP } from 'constant';
import { useDropdown, useWidthCondition } from 'helpers/hooks';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import Button from 'components/UIKit/Button';
import { ManageContext, useManageContext } from './context';
import Incoming from './Incoming';
import styles from './index.module.scss';
import WaitingContest from './WaitingContest';
import RegionSettings from './RegionSettings';
import LockedApartments from './LockedApartments';
import Contest from './Contest';
import Rentals from './Rentals';
import Evictions from './Evictions';
import Counter from 'components/UIKit/Counter';
import { getErrorI18nText, getErrorData } from 'helpers';
import NavBar, { NavBarLink } from 'components/UIKit/NavBar';
import Buyout from './Buyout';
const cx = classNames.bind(styles);

const CITY_KEY = 'selectedManageCityId';
const setStoredCity = (id: number | string) => localStorage.setItem(CITY_KEY, id.toString());
const getStoredCity = () => localStorage.getItem(CITY_KEY);

export const NAV = [
    {
        url: URL_MAP.manage.incoming.index,
        label: 'pageManage.sidebar.nav.incoming',
        icon: IconInbox,
        getNotificationCounter: (notifactions: NotaryUpdatesFragment) =>
            notifactions.moderationsNum + notifactions.reworksNum
    },
    {
        url: URL_MAP.manage.contests.index,
        label: 'pageManage.sidebar.nav.contest',
        icon: IconTrophy,
        getNotificationCounter: (notifactions: NotaryUpdatesFragment) =>
            notifactions.activeContestsNum + notifactions.finishedContestsNum
    },
    {
        url: URL_MAP.manage.rentals,
        label: 'pageManage.sidebar.nav.rentals',
        icon: IconFolderWithLabel,
        getNotificationCounter: (notifactions: NotaryUpdatesFragment) => notifactions.rentalsNum
    },
    {
        url: URL_MAP.manage.evictions,
        label: 'pageManage.sidebar.nav.evictions',
        icon: IconEviction,
        getNotificationCounter: (notifactions: NotaryUpdatesFragment) => notifactions.evictionsNum
    },
    {
        url: URL_MAP.manage.waitingContest,
        label: 'pageManage.sidebar.nav.waitingContest',
        icon: IconRepeat,
        getNotificationCounter: (notifactions: NotaryUpdatesFragment) => notifactions.waitingContestsNum
    },
    {
        url: URL_MAP.manage.buyout.index,
        label: 'pageManage.sidebar.nav.buyout',
        icon: IconHome,
        getNotificationCounter: (notifactions: NotaryUpdatesFragment) =>
            notifactions.activeBuyRequestsNum + notifactions.finishedBuyRequestsNum
    },
    {
        url: URL_MAP.manage.lockedApartments,
        label: 'pageManage.sidebar.nav.lockedApartments',
        icon: IconBlockFlats,
        getNotificationCounter: (notifactions: NotaryUpdatesFragment) => notifactions.lockedNum
    },
    {
        url: URL_MAP.manage.regionSettings,
        label: 'pageManage.sidebar.nav.regionSettings',
        icon: IconSetting
    }
];

const Sidebar = () => {
    const [t] = useTranslation();
    const { city, cityList, changeCity } = useManageContext();
    const isTabletOrLess = useWidthCondition((w) => w < BREAKPOINT_TABLET);
    const dropdown = useDropdown();

    const hasManyCities = cityList.length > 1;

    return (
        <aside className={cx('Sidebar')}>
            <div ref={dropdown.setRef} className={cx('Region')}>
                <button
                    type="button"
                    onClick={hasManyCities ? dropdown.toggle : undefined}
                    className={cx('RegionControl', { readOnly: !hasManyCities })}
                >
                    <i className={cx('RegionControlFlag')}>
                        <CityFlag countryCode={city?.countryCode!} />
                    </i>

                    <span className={cx('RegionControlName')}>
                        {t('pageManage.region')}
                        <b>{city?.name}</b>
                    </span>
                    {hasManyCities && (
                        <IconAngleDown
                            className={cx('RegionControlIcon', {
                                rotate: dropdown.open
                            })}
                        />
                    )}
                </button>
                <div
                    ref={dropdown.setPopperRef}
                    style={dropdown.popper.styles.popper}
                    {...dropdown.popper.attributes.popper}
                    className={cx('RegionContent', dropdown.open && 'open')}
                >
                    {cityList?.map((c) => {
                        if (!c) return null;
                        return (
                            <button
                                key={c.id}
                                type="button"
                                onClick={() => {
                                    changeCity(c.id);
                                    dropdown.toggle();
                                }}
                                className={cx(c.id === city?.id && 'active')}
                            >
                                <CityFlag countryCode={c?.countryCode} />
                                {c.name}
                            </button>
                        );
                    })}
                </div>
            </div>

            {isTabletOrLess ? (
                <nav className={cx('SidebarMobileNav')}>
                    {NAV.map(({ label, icon: Icon, url, getNotificationCounter }) => {
                        const notifications =
                            city?.notaryUpdates &&
                            getNotificationCounter &&
                            getNotificationCounter(city?.notaryUpdates);
                        return (
                            <Link key={url} to={url}>
                                <i>
                                    <Icon />
                                </i>
                                <span>{t(label)}</span>
                                {!!notifications && (
                                    <Counter className={cx('SidebarNavCounter')}>{notifications}</Counter>
                                )}
                            </Link>
                        );
                    })}
                </nav>
            ) : (
                <NavBar className={cx('SidebarNav')}>
                    {NAV.map(({ label, icon: Icon, url, getNotificationCounter }) => {
                        const notifications =
                            city?.notaryUpdates &&
                            getNotificationCounter &&
                            getNotificationCounter(city?.notaryUpdates);
                        return (
                            <NavBarLink
                                tag="navlink"
                                key={url}
                                icon={<Icon />}
                                to={url}
                                label={t(label)}
                                rightControl={!!notifications && <Counter>{notifications}</Counter>}
                            />
                        );
                    })}
                </NavBar>
            )}
        </aside>
    );
};

const ManageIndex = () => {
    const [t] = useTranslation();
    const isTabletOrLess = useWidthCondition((w) => w < BREAKPOINT_TABLET);

    if (!isTabletOrLess) return <Redirect to={URL_MAP.manage.incoming.index} />;

    return (
        <main className={cx('IndexPage')}>
            <h1 className={cx('Title')}>{t('pageManage.title')}</h1>
            <Sidebar />
        </main>
    );
};

const ManagePages = () => {
    const isTabletOrLess = useWidthCondition((w) => w < BREAKPOINT_TABLET);
    return (
        <main className={cx('Component')}>
            <div className={cx('Container', 'container')}>
                {!isTabletOrLess && <Sidebar />}
                <div className={cx('Content')}>
                    <Switch>
                        <Route exact path={URL_MAP.manage.index} component={ManageIndex} />
                        <Route path={URL_MAP.manage.incoming.index} component={Incoming} />
                        <Route path={URL_MAP.manage.contests.index} component={Contest} />
                        <Route path={URL_MAP.manage.rentals} component={Rentals} />
                        <Route path={URL_MAP.manage.evictions} component={Evictions} />
                        <Route path={URL_MAP.manage.waitingContest} component={WaitingContest} />
                        <Route path={URL_MAP.manage.buyout.index} component={Buyout} />
                        <Route path={URL_MAP.manage.lockedApartments} component={LockedApartments} />
                        <Route path={URL_MAP.manage.regionSettings} component={RegionSettings} />
                    </Switch>
                </div>
            </div>
        </main>
    );
};

// const CitySubscription = (variables: { cityId: number }) => {
// 	useCityWsSubscription({ variables })
// 	return null
// }

const PageManage = memo(() => {
    const [t] = useTranslation();
    const storedCityId = getStoredCity();
    const [currentCityId, setCurrentCityId] = useState<null | number>(
        storedCityId !== null ? Number(storedCityId) : null
    );

    const citiesManagedByMeQuery = useCitiesManagedByMeQuery({
        fetchPolicy: 'network-only'
    });
    const cityList = citiesManagedByMeQuery.data?.me?.citiesManagedByMe ?? [];
    const currentCity = cityList.find((c) => c && c.id === currentCityId);

    useCityNotaryUpdatesWsSubscription({
        variables: {
            cityId: currentCityId!
        },
        skip: currentCityId === null
    });

    useEffect(() => {
        if (cityList.length > 0) {
            if (currentCityId === null || !cityList.map((c) => c?.id).includes(currentCityId)) {
                changeCity(cityList[0]?.id!);
            }
        }
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityList.length]);

    if (citiesManagedByMeQuery.loading)
        return (
            <div className={cx('LoadingScreen')}>
                <Loader />
            </div>
        );

    if (citiesManagedByMeQuery.error)
        return (
            <main className={cx('ErrorScreen')}>
                <IconWarningNew />
                <p>{getErrorI18nText(t, getErrorData(citiesManagedByMeQuery.error).message)}</p>
                <Button onClick={() => citiesManagedByMeQuery.refetch()} color="primary">
                    {t('pageManage.errorScreen.btn')}
                </Button>
            </main>
        );

    if (!cityList.length) return <Redirect to={URL_MAP.profile.index} />;

    const changeCity = (cityId: number) => {
        setCurrentCityId(cityId);
        setStoredCity(cityId);
    };

    return (
        <ManageContext.Provider value={{ city: currentCity!, changeCity, cityList }}>
            <Switch>
                <Route exact path={URL_MAP.manage.index} component={ManageIndex} />
                <Route path="*" component={ManagePages} />
            </Switch>
        </ManageContext.Provider>
    );
});

export default PageManage;
