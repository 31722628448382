import React, { useEffect, useMemo, useRef, useState } from 'react';
import { toast, Toast, ToastId } from 'react-toastify';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/reducers';
import { usePopper } from 'react-popper';
import maxSize from 'popper-max-size-modifier';
import merge from 'lodash/merge';
import * as clipboard from 'clipboard-polyfill/text';
import { LOCAL_STORAGE_LNG_KEY } from 'constant';
import { Languages } from 'i18n';
import { useTranslation } from 'react-i18next';
// TODO: Why not used?
// import validator from './validator';

export const useToast = () => {
    const toastRef = useRef<ToastId>('');
    return new Proxy(toast, {
        get(target, prop: keyof Toast) {
            const PROXY_KEYS = ['info', 'success', 'error', 'warn'] as const;
            if (PROXY_KEYS.includes(prop as any)) {
                const toastFn = target[prop as typeof PROXY_KEYS[number]];
                const proxy: typeof toastFn = (content, options?) => {
                    toast.dismiss(toastRef.current);
                    toastRef.current = toastFn(content, options);
                    return toastRef.current;
                };

                return proxy;
            }
            return target[prop];
        }
    });
};

export function useToggle(initialState: boolean) {
    const [value, setState] = useState<boolean>(initialState);

    function toogle() {
        setState(!value);
    }

    return [value, toogle, setState] as const;
}

export type UseFormField<T> = {
    value: T;
    error: string;
    change: (v: T, er?: string) => void;
    changeError: React.Dispatch<React.SetStateAction<string>>;
    valid: boolean | null;
    validating: boolean;
    changeValid: React.Dispatch<React.SetStateAction<boolean | null>>;
    changeValidating: React.Dispatch<React.SetStateAction<boolean>>;
};

export function useFormField<T>(initialValue: T, withResetErrorOnChange = true) {
    const [value, setValue] = useState(initialValue);
    const [error, setError] = useState('');
    const [valid, setValid] = useState<null | boolean>(null);
    const [validating, setValidating] = useState(false);

    return {
        value,
        error,
        change: (v: T, error = '') => {
            setValue(v);
            if (withResetErrorOnChange || error) setError(error);
        },
        // TODO: Why not used?
        // changeWithValidation: (event: ChangeEvent<HTMLInputElement>, error = '') => {
        //     // TODO improve type validation
        //     setValue(validator(event, value as unknown as string) as unknown as T);
        //     if (withResetErrorOnChange || error) setError(error);
        // },
        changeError: setError,
        valid,
        validating,
        changeValid: setValid,
        changeValidating: setValidating
    };
}

type UseDropdown = {
    onClose?(): void;
    /** @default false */
    popperMaxWidth?: boolean;
    popperOptions?: Parameters<typeof usePopper>[2];
};

/**
 *
 * @description
 * Helpful hook to craete fully contoled dropdown component
 * 1) pass `ref` to dropdown wrapper
 * 2) use `toggle` or `setOpen` actions to control visibility
 * 3) use `open` flag to display or hide dropdown's content
 * 4) for better content position use `popperRef` to select
 * dropdown content and `popper` object to pass correct position
 * @example
 * const dropdown = useDropdown()
 *
 * <div ref={dropdown.setRef} >
 *     <button onClick={dropdown.toggle}>toggle content</div>
 *
 *     {dropdown.open && (
 *         <div
 *             ref={dropdown.setPopperRef}
 *             style={dropdown.popper.styles.popper}
 *             {...dropdown.popper.attributes.popper}
 *         >
 *             content
 *         </div>
 *     )}
 * </div>
 */
export function useDropdown({ onClose, popperOptions, popperMaxWidth = false }: UseDropdown = {}) {
    const [open, setOpen] = useState(false);
    const [ref, setRef] = useState<HTMLDivElement | null>(null);
    // @todo check reqirement of using state
    const [popperRef, setPopperRef] = useState<HTMLUListElement | HTMLDivElement | null>(null);

    const defaultPopperOptions: Parameters<typeof usePopper>[2] = useMemo(
        () => ({
            placement: 'bottom-start',

            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 12]
                    }
                },

                maxSize,
                {
                    name: 'applyMaxSize',
                    enabled: true,
                    phase: 'beforeWrite' as const,
                    requires: ['maxSize'],
                    fn({ state }) {
                        state.styles.popper = {
                            ...state.styles.popper,
                            maxHeight: `${state.modifiersData.maxSize.height - 8}px`,
                            maxWidth: popperMaxWidth ? `${state.rects.reference.width}px` : undefined
                        };
                    }
                }
            ]
        }),
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const popper = usePopper(ref, popperRef, merge(defaultPopperOptions, popperOptions));

    const toggle = () => {
        popper.forceUpdate?.();
        setOpen(!open);
    };

    // @todo find solution to fix wrong initial position of popper
    // const popperInstanseRef = useRef(popper)
    // popperInstanseRef.current = popper
    // useEffect(() => {
    // 	setInterval(() => {
    // 		popperInstanseRef.current.forceUpdate?.()
    // 	}, 500)
    // }, [])

    useEffect(() => {
        const mousedownListener = (e: MouseEvent) => {
            if (e.target && !ref?.contains(e.target as Node) && !popperRef?.contains(e.target as Node)) {
                document.removeEventListener('mousedown', mousedownListener);
                setOpen(false);
            }
        };

        const keydownListener = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                setOpen(false);
                e.preventDefault();
                e.stopPropagation();
            }
        };

        if (open) {
            document.addEventListener('mousedown', mousedownListener);
            document.addEventListener('keydown', keydownListener);

            return () => {
                document.removeEventListener('keydown', keydownListener);
                document.removeEventListener('mousedown', mousedownListener);
            };
        } else {
            onClose?.();
        }
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, ref, popperRef]);

    return {
        open,
        toggle,
        ref,
        setRef,
        popperRef,
        setPopperRef,
        setOpen,
        popper
    };
}

export function useStore<TSelected = unknown>(
    selector: (state: ApplicationState) => TSelected,
    equalityFn?: (left: TSelected, right: TSelected) => boolean
) {
    return useSelector(selector, equalityFn);
}

/**
 * @param timeout time in seconds
 * @return left time in seconds, stop and reset methods
 */
export const useTimeout = (timeout: number) => {
    const [timeLeft, setTimeLeft] = useState(timeout);

    useEffect(() => {
        if (timeLeft > 0) {
            const intervalId = setInterval(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [timeLeft]);
    return {
        timeLeft,
        stop: () => setTimeLeft(0),
        reset: () => setTimeLeft(timeout)
    };
};

export const useWidthCondition = (conditionFn: (width: number) => boolean) => {
    const check = () => conditionFn(window.innerWidth);

    const [condition, setCondition] = useState(check());

    useEffect(() => {
        const handleResize = () => {
            setCondition(check);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return condition;
};

// TODO: Why not used?
// /**
//  * Helper to control need we close sidebar on click/tap overlay or sidebar directly
//  * @param close will fire on direct click on overlay
//  *
//  * @example
//  * const overlayClickHandler = useOverlayClickHandler(close)
//  *
//  * <div {...overlayClickHandler.overlayProps} className='overlay'>
//  *     <div {...overlayClickHandler.componentProps}>
//  *         content
//  *     </div>
//  * </div>
//  */
// export const useOverlayClickHandler = (close?: () => void) => {
//     const shouldCloseRef = useRef<null | boolean>(null);
//
//     const setShouldClose = (v: null | boolean) => {
//         shouldCloseRef.current = v;
//     };
//
//     const handleOverlayOnClick = () => {
//         if (shouldCloseRef.current === null) {
//             setShouldClose(true);
//         }
//
//         if (shouldCloseRef.current) {
//             close?.();
//         }
//
//         setShouldClose(null);
//     };
//
//     return {
//         overlayProps: { onClick: handleOverlayOnClick },
//         componentProps: {
//             onMouseDown: () => setShouldClose(false),
//             onMouseUp: () => setShouldClose(false),
//             onClick: () => setShouldClose(false)
//         }
//     };
// };

export const useCopy = () => {
    const toast = useToast();
    return (text: string, success: string, error: string, onCopied?: () => void) => {
        clipboard
            .writeText(text)
            .then(() => {
                toast.success(success);
                if (onCopied) {
                    onCopied();
                }
            })
            .catch(() => {
                toast.error(error);
            });
    };
};

export const useIsTouchableDevice = () =>
    useMemo(() => {
        return 'ontouchstart' in window || window.navigator.maxTouchPoints > 0 || window.navigator.msMaxTouchPoints > 0;
    }, []);

export const useChangeClientLanguage = () => {
    const { i18n } = useTranslation();

    return (lng: Languages) => {
        i18n.changeLanguage(lng);
        localStorage.setItem(LOCAL_STORAGE_LNG_KEY, lng);
    };
};
