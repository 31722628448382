import React, { memo, ReactNode, useMemo } from 'react';
import QR from 'components/QR';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { Trans, useTranslation } from 'react-i18next';
import Button from 'components/UIKit/Button';
import { isMobilePlatform } from 'helpers';
import { PAYCASH_LANDING } from 'constant';
import ButtonLink from 'components/UIKit/ButtonLink';
const cx = classNames.bind(styles);

type Props = {
    qrData: string;
    deepLink?: string;
    deepLinkText?: ReactNode;
    text?: ReactNode;
    noAppLink?: string;
};

const ScanQRSection = memo(({ deepLink, text, qrData, noAppLink = PAYCASH_LANDING, deepLinkText }: Props) => {
    const [t] = useTranslation();
    const isMobile = useMemo(isMobilePlatform, []);

    return (
        <>
            <div className={cx('Component')}>
                {text && <p className={cx('Text')}>{text}</p>}
                <QR className={cx('QR')} data={qrData} />
                {noAppLink ? (
                    <p className={cx('Text')}>
                        <Trans i18nKey="global.noAppDownload">
                            <ButtonLink
                                tag="a"
                                target="_blank"
                                fontWeight="bold"
                                rel="noopener noreferrer"
                                href={noAppLink}
                            />
                        </Trans>
                    </p>
                ) : (
                    <div />
                )}
            </div>
            {isMobile && !!deepLink && (
                <Button color="primary" className={cx('BtnDeepLink')} tag="a" target="_blank" href={deepLink}>
                    {deepLinkText || t('global.goToApp')}
                </Button>
            )}
        </>
    );
});

export default ScanQRSection;
