import React, { memo, ReactNode } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { LinkProps, Link } from 'react-router-dom';
import Loader from 'components/Loader';
const cx = classNames.bind(styles);

export const COLORS = [
    'primary',
    'dark',
    'secondary',
    'secondary-grey',
    'tertiary',
    'tertiary-grey',
    'white-shadow'
] as const;

export const SIZES = ['big', 'normal', 'small'] as const;

export type ButtonProps = {
    /** @default button*/
    tag?: 'button';
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export type ButtonPropsLink = { tag: 'link' } & LinkProps;

export type ButtonPropsAnchor = { tag: 'a' } & React.AnchorHTMLAttributes<HTMLAnchorElement>;

export type Props = {
    /**
     * Render loading icon
     */
    loading?: boolean;
    /**
     * Type of border radius
     */
    round?: boolean;
    /**
     * Available color schemas
     */
    color: typeof COLORS[number];
    size?: typeof SIZES[number];
    /**
     * Highlight button as hovered
     */
    isActive?: boolean;

    iconRight?: ReactNode;
    iconLeft?: ReactNode;
} & (ButtonProps | ButtonPropsLink | ButtonPropsAnchor);

const Button = memo(
    ({
        loading,
        round,
        color,
        size = 'normal',
        isActive,
        className,
        tag = 'button',
        children,
        iconRight,
        iconLeft,
        ...rest
    }: Props) => {
        const props = {
            className: cx('Btn', className, {
                [color]: true,
                [size]: true,
                active: isActive,
                loading,
                round,
                iconOnly: !children && ((iconRight && !iconLeft) || (iconLeft && !iconRight))
            }),
            disabled: loading || (rest as ButtonProps).disabled,
            children: (
                <span tabIndex={-1}>
                    {loading ? (
                        <Loader className={cx('Loader')} size={18} />
                    ) : (
                        <>
                            {iconLeft && <span className={cx('Icon', 'left')}>{iconLeft}</span>}
                            {children}
                            {iconRight && <span className={cx('Icon', 'right')}>{iconRight}</span>}
                        </>
                    )}
                </span>
            )
        };

        if (tag === 'a') {
            // TODO: Add anchor
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            return <a {...props} {...(rest as ButtonPropsAnchor)} />;
        } else if (tag === 'link') {
            return <Link {...props} {...(rest as ButtonPropsLink)} />;
        } else {
            return <button {...props} {...(rest as ButtonProps)} />;
        }
    }
);
export default Button;
