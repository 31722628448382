import { RelationToApt, useMyApartmentsQuery } from 'apollo/generted';
import classNames from 'classnames/bind';
import ApartmentCard from 'components/ApartmentCard/ApartmentCard';
import { IconHeartActive, IconWarningNew } from 'components/Icons';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination';
import { BREAKPOINT_IPAD, BREAKPOINT_TABLET, URL_MAP } from 'constant';
import Button from 'components/UIKit/Button';
import { useWidthCondition } from 'helpers/hooks';
import update from 'immutability-helper';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { Section, SectionHeader, SectionTitle, SectionMain } from 'containers/Pages/Manage/components/Section';
import { getErrorI18nText, getErrorData } from 'helpers';
const cx = classNames.bind(styles);

const STEP = 10;

const MyFavorites = () => {
    const [t] = useTranslation();
    const [offset, setOffset] = useState(0);
    const isIpadOrLess = useWidthCondition((w) => w < BREAKPOINT_IPAD);
    const isTabletOrLess = useWidthCondition((w) => w < BREAKPOINT_TABLET);

    const {
        data: apartmentsData,
        loading,
        error,
        ...apartmentsQuery
    } = useMyApartmentsQuery({
        fetchPolicy: 'network-only',
        variables: {
            relationType: RelationToApt.Favorite,
            first: STEP,
            offset
        }
    });

    const apartments = apartmentsData?.myApartments?.apartments;
    const total = apartmentsData?.myApartments?.pageInfo?.total || 0;

    const removeApartment = (aptId: number) => {
        apartmentsQuery.updateQuery((currentResult) =>
            update(currentResult, {
                myApartments: {
                    apartments: (apartments) => apartments.filter((ap) => ap?.id !== aptId) ?? [],
                    pageInfo: {
                        total: (total) => total - 1
                    }
                }
            })
        );
    };

    return (
        <Section className={cx('Component')}>
            <SectionHeader>
                <SectionTitle to={URL_MAP.profile.index}>{t('pageProfile.myFavorites.title')}</SectionTitle>
            </SectionHeader>

            <SectionMain>
                {loading ? (
                    <div className={cx('Loader')}>
                        <Loader size={46} />
                    </div>
                ) : error ? (
                    <div className={cx('ErrorScreen')}>
                        <IconWarningNew />
                        <h6>{error && getErrorI18nText(t, getErrorData(error).message)}</h6>
                        <Button onClick={() => apartmentsQuery.refetch()} color="secondary">
                            {t('pageProfile.myFavorites.errorScreen.btn')}
                        </Button>
                    </div>
                ) : apartments?.length ? (
                    <Fragment>
                        <div className={cx('ApartmentsList')}>
                            {apartments.map((apartment) => {
                                if (!apartment) return null;

                                return (
                                    <ApartmentCard
                                        cardView={
                                            !isIpadOrLess ? 'row-small' : isTabletOrLess ? undefined : 'column-big'
                                        }
                                        data={apartment}
                                        key={apartment.id}
                                        mode="PUBLIC"
                                        onNeedToRemove={() => removeApartment(apartment.id)}
                                    />
                                );
                            })}
                        </div>

                        <Pagination
                            className={cx('Pagination')}
                            page={offset! / STEP}
                            pageCount={Math.ceil(total / STEP)}
                            onChangePage={(page) => {
                                window.scrollTo({ top: 0 });
                                setOffset(page * STEP);
                            }}
                        />
                    </Fragment>
                ) : (
                    <div className={cx('EmptyScreen')}>
                        <IconHeartActive />
                        <h6>{t('pageProfile.myFavorites.emptyScreen.title')}</h6>
                        <p>{t('pageProfile.myFavorites.emptyScreen.text')}</p>

                        <Button tag="link" to={URL_MAP.search} color="primary">
                            {t('pageProfile.myFavorites.emptyScreen.btn')}
                        </Button>
                    </div>
                )}
            </SectionMain>
        </Section>
    );
};

export default MyFavorites;
