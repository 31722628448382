import React from 'react';
import ProfileParam from 'components/UIKit/ProfileParam';
import ModalEditRegionContacts, { Props as ModalEditRegionContactsProps } from 'containers/Modals/EditRegionContacts';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { IconEos, IconMail, IconMobile, IconPen } from 'components/Icons';
import { useTranslation } from 'react-i18next';
import { IconPlus } from 'components/Icons';
import { CityNode, LoginType } from 'apollo/generted';
import { useModal } from 'helpers';
import Button from 'components/UIKit/Button';

const cx = classNames.bind(styles);

type Props = {
    city: Pick<CityNode, 'id' | 'notaryEosAccount' | 'notaryContactEmail' | 'notaryContactPhone'>;
};

const Contact = ({ city: { notaryEosAccount, notaryContactEmail, notaryContactPhone, id } }: Props) => {
    const [t] = useTranslation();
    const modalEditRegionContacts = useModal<Omit<ModalEditRegionContactsProps, 'cityId'>>(false);

    return (
        <div className={cx('Block')}>
            <div className={cx('BlockHeader')}>
                <h3 className={cx('BlockTitle')}>{t('pageManage.regionSettings.contacts.title')}</h3>
            </div>
            <p className={cx('BlockDescription')}>{t('pageManage.regionSettings.contacts.description')}</p>

            <div className={cx('ContactParam')}>
                <ProfileParam
                    icon={<IconMail />}
                    iconClassName={cx('ContactParamIcon', !notaryContactEmail && 'empty')}
                    valueClassName={cx('ContactParamValue', !notaryContactEmail && 'empty')}
                    value={notaryContactEmail || t('pageManage.regionSettings.contacts.noData')}
                    label={t('pageManage.regionSettings.contacts.email')}
                />
                <Button
                    color="secondary-grey"
                    round
                    size="small"
                    onClick={() =>
                        modalEditRegionContacts.open({
                            type: LoginType.Email,
                            defaultValue: notaryContactEmail
                        })
                    }
                    iconLeft={!notaryContactEmail ? <IconPlus /> : <IconPen />}
                >
                    {!notaryContactEmail
                        ? t('pageManage.regionSettings.contacts.btnAdd')
                        : t('pageManage.regionSettings.contacts.btnEdit')}
                </Button>
            </div>

            <div className={cx('ContactParam')}>
                <ProfileParam
                    icon={<IconMobile />}
                    iconClassName={cx('ContactParamIcon', !notaryContactPhone && 'empty')}
                    valueClassName={cx('ContactParamValue', !notaryContactPhone && 'empty')}
                    value={notaryContactPhone || t('pageManage.regionSettings.contacts.noData')}
                    label={t('pageManage.regionSettings.contacts.phone')}
                />
                <Button
                    color="secondary-grey"
                    round
                    size="small"
                    onClick={() =>
                        modalEditRegionContacts.open({
                            type: LoginType.Phone,
                            defaultValue: notaryContactPhone
                        })
                    }
                    iconLeft={!notaryContactPhone ? <IconPlus /> : <IconPen />}
                >
                    {!notaryContactPhone
                        ? t('pageManage.regionSettings.contacts.btnAdd')
                        : t('pageManage.regionSettings.contacts.btnEdit')}
                </Button>
            </div>

            <div className={cx('ContactParam')}>
                <ProfileParam
                    icon={<IconEos />}
                    value={notaryEosAccount}
                    label={t('pageManage.regionSettings.contacts.eosAccount')}
                />
            </div>
            <ModalEditRegionContacts {...modalEditRegionContacts} {...modalEditRegionContacts.payload!} cityId={id} />
        </div>
    );
};

export default Contact;
