import { ApartmentNode, DocumentNode } from 'apollo/generted';
import classNames from 'classnames/bind';
import { IconClose, IconFacebook, IconTelegram, IconTwitter, IconVK, IconWhatsapp } from 'components/Icons';
import Sidebar from 'components/Sidebar';
import Button from 'components/UIKit/Button';
import ButtonLink from 'components/UIKit/ButtonLink';
import Modal, { ModalBody, ModalHeader, ModalProps } from 'components/UIKit/Modal';
import TextField from 'components/UIKit/TextField';
import { BREAKPOINT_LANDSCAPE, URL_MAP } from 'constant';
import { useCopy, useWidthCondition } from 'helpers/hooks';
import React, { Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

export type Props = {
    id: ApartmentNode['id'];
    address: string;
    photo?: DocumentNode['link'];
};

const ModalShare = memo(({ isOpen, close, ...props }: Props & ModalProps) => {
    const isLandcsapeOrLess = useWidthCondition((w) => w < BREAKPOINT_LANDSCAPE);
    const [t] = useTranslation();

    if (isLandcsapeOrLess) {
        return (
            <Sidebar className={cx('BottomSheet')} position="bottom" close={close!} isOpen={isOpen}>
                <header className={cx('BottomSheetHeader')}>
                    <h3>{t('modalShare.title')}</h3>
                    <button onClick={close} className={cx('BottomSheetBtnClose')} type="button">
                        <IconClose />
                    </button>
                </header>

                <main className={cx('BottomSheetMain')}>
                    <Content {...props} />
                </main>
            </Sidebar>
        );
    } else {
        return (
            <Modal close={close} isOpen={isOpen}>
                <ModalHeader>{t('modalShare.title')}</ModalHeader>
                <ModalBody>
                    <Content {...props} />
                </ModalBody>
            </Modal>
        );
    }
});

const Content = memo(({ id, address, photo }: Props) => {
    const [t] = useTranslation();
    const copy = useCopy();

    const url = window.location.origin + URL_MAP.apartment(id);

    const encodedAddress = encodeURIComponent(address);

    // https://developer.twitter.com/en/docs/twitter-for-websites
    const twitterLink = `https://twitter.com/intent/tweet/?url=${url}&text=${encodedAddress}`;

    // https://faq.whatsapp.com/iphone/how-to-link-to-whatsapp-from-a-different-app/?lang=ru
    const whatsappLink = `https://wa.me/?text=${url}`;

    // https://dev.vk.com/widgets/share
    const vkLink = `https://vk.com/share.php?url=${url}&title=${encodedAddress}${photo ? `&image=${photo}` : ''}`;

    // https://developers.facebook.com/docs/sharing/webmasters
    const facebookLink = `https://www.facebook.com/sharer.php?u=${url}`;

    // https://core.telegram.org/widgets/share
    const telegramLink = `https://t.me/share/url?url=${url}&text=${encodedAddress}`;

    return (
        <Fragment>
            <div className={cx('SocialBlock')}>
                <Button
                    tag="a"
                    iconRight={<IconTelegram />}
                    href={telegramLink}
                    target="_blank"
                    title="Telegram"
                    color="secondary-grey"
                />
                <Button
                    tag="a"
                    target="_blank"
                    iconRight={<IconWhatsapp />}
                    href={whatsappLink}
                    title="WhatsApp"
                    color="secondary-grey"
                />
                <Button
                    tag="a"
                    iconRight={<IconFacebook />}
                    href={facebookLink}
                    target="_blank"
                    title="Facebook"
                    color="secondary-grey"
                />
                <Button
                    tag="a"
                    iconRight={<IconVK />}
                    href={vkLink}
                    target="_blank"
                    title="VK"
                    color="secondary-grey"
                />
                <Button
                    tag="a"
                    iconRight={<IconTwitter />}
                    href={twitterLink}
                    target="_blank"
                    title="Twitter"
                    color="secondary-grey"
                />
            </div>

            <TextField value={url} readOnly />
            <ButtonLink
                tag="a"
                className={cx('Btn')}
                fontWeight="bold"
                onClick={() => copy(url, t('global.copied'), t('error.globalError'))}
            >
                {t('modalShare.btnCopy')}
            </ButtonLink>
        </Fragment>
    );
});

export default ModalShare;
