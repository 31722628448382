import React, { Fragment, useState } from 'react';
import Modal, {
    ModalHeader,
    ModalProps,
    ModalBody,
    useModalContext,
    ModalContent,
    ModalText
} from 'components/UIKit/Modal';
import { useTranslation } from 'react-i18next';
import Button from 'components/UIKit/Button';
import { AptStatus, useCancelContestAsOwnerMutation, useCancelPublicationMutation } from 'apollo/generted';
import { getErrorData, getErrorI18nText } from 'helpers';
import ModalErrorScreen from 'components/UIKit/Modal/ErrorScreen';
import CheckboxField from 'components/UIKit/CheckboxField';
import { useToast } from 'helpers/hooks';

type Props = {
    id: number;
    status: null | AptStatus;
};

const ModalCancelRequest = ({ id, status, ...props }: ModalProps & Props) => {
    return (
        <Modal {...props}>
            <Main id={id} status={status} />
        </Modal>
    );
};

const Main = ({ id, status }: Props) => {
    const [t] = useTranslation();
    const { close } = useModalContext();
    const [formError, setFormError] = useState('');
    const toast = useToast();
    const [agree, setAgree] = useState(false);
    const [cancelPublicationMutation, { loading: loadingCancelPublication }] = useCancelPublicationMutation();
    const [cancelContestAsOwnerMutation, { loading: loadingCancelContestAsOwner }] = useCancelContestAsOwnerMutation();
    const loading = loadingCancelPublication || loadingCancelContestAsOwner;

    const submit = () => {
        const isCancellingAuction = [AptStatus.Contest, AptStatus.ContestFinished].includes(status!);

        if (isCancellingAuction) {
            cancelContestAsOwnerMutation({ variables: { input: { aptId: id } } })
                .then(({ data }) => {
                    if (data?.cancelContestAsOwner?.__typename === 'CancelContestSuccess') {
                        toast.success(t('modalCancelRequest.successMsg'));
                        close();
                    } else {
                        setFormError(
                            t([
                                `modalCancelRequest.error.${data?.cancelContestAsOwner?.__typename}`,
                                getErrorI18nText(t, data?.cancelContestAsOwner?.errorMessage)
                            ])
                        );
                    }
                })
                .catch((e) => {
                    setFormError(getErrorI18nText(t, getErrorData(e).message));
                });
        } else {
            cancelPublicationMutation({ variables: { input: { aptId: id } } })
                .then(({ data }) => {
                    if (data?.cancelPublication?.__typename === 'CancelPublicationSuccess') {
                        toast.success(t('modalCancelRequest.successMsg'));
                        close();
                    } else {
                        setFormError(
                            t([
                                `modalCancelRequest.error.${data?.cancelPublication?.__typename}`,
                                getErrorI18nText(t, data?.cancelPublication?.errorMessage)
                            ])
                        );
                    }
                })
                .catch((e) => {
                    setFormError(getErrorI18nText(t, getErrorData(e).message));
                });
        }
    };

    return (
        <Fragment>
            <ModalHeader>{t('modalCancelRequest.title')}</ModalHeader>
            <ModalBody>
                <ModalContent withBorder>
                    <ModalText>{t('modalCancelRequest.description')}</ModalText>
                </ModalContent>
                <CheckboxField
                    label={t('modalCancelRequest.agree')}
                    checked={agree}
                    onChange={(e) => setAgree(e.target.checked)}
                />

                <Button color="primary" loading={loading} disabled={!agree} onClick={submit} type="button">
                    {t('modalCancelRequest.btnSubmit')}
                </Button>
            </ModalBody>
            <ModalErrorScreen resetError={() => setFormError('')} text={formError} />
        </Fragment>
    );
};

export default ModalCancelRequest;
