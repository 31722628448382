import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useIsTouchableDevice } from 'helpers/hooks';
import { AptStatus } from 'apollo/generted';
import Tooltip, { useTooltip } from 'components/UIKit/Tooltip/Tooltip';
import { PriceProps } from './Price.d';
import styles from './Price.module.scss';

const cx = classNames.bind(styles);

const Price = ({ paymentInfo, className, hasMyBid, status, isNew }: PriceProps) => {
    const [t] = useTranslation();
    const tooltip = useTooltip();
    const price = Number(paymentInfo?.payment ?? 500);
    const isTouchable = useIsTouchableDevice();

    if (hasMyBid || status !== AptStatus.Contest) return null;

    return (
        <div className={cx('Component', className)}>
            <span className={cx('Label')}>{t('apartmentStats.deposit.label')}</span>

            <button
                type="button"
                ref={tooltip.setPopperReference}
                className={cx('Value', { active: tooltip.isActive })}
                onMouseEnter={!isTouchable ? tooltip.show : undefined}
                onMouseLeave={!isTouchable ? tooltip.hide : undefined}
                onClick={isTouchable ? () => tooltip.setShow(!tooltip.isActive) : undefined}
            >
                {price} {paymentInfo?.token}
            </button>

            <Tooltip {...tooltip}>
                {isNew && <div style={{ marginBottom: '1rem' }}>{t('apartmentStats.deposit.hint.newRegion')}</div>}
                {t('apartmentStats.deposit.hint.text')}
            </Tooltip>
        </div>
    );
};

export default Price;
