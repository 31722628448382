import PreloaderJson from 'assets/lottie/preloader.json';
import classNames from 'classnames/bind';
import LottiePlayer, { AnimationItem } from 'lottie-web';
import React, { createContext, FC, memo, useContext, useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

type PreloaderContextType = {
    show(id: string): void;
    hide(id: string): void;
};
const PreloaderContext = createContext({} as PreloaderContextType);
export const PreloaderProvider: FC = ({ children }) => {
    const ref = useRef<HTMLDivElement>(null);
    const animationRef = useRef<AnimationItem>();
    /** need to toogle classname for preloader */
    const [needToShow, setNeedToShow] = useState(false);

    // List of Preload instanses
    const requestedByRef = useRef<{ [key: string]: boolean }>({});
    // need to toogle animation state
    const lastNeedToShowRef = useRef(false);
    // Update animation timeout id
    const updateAnimationStateRef = useRef<undefined | NodeJS.Timeout>();

    const show: PreloaderContextType['show'] = (id) => {
        requestedByRef.current = { ...requestedByRef.current, [id]: true };
        updateAnimationState();
    };

    const hide: PreloaderContextType['hide'] = (id) => {
        delete requestedByRef.current[id];
        updateAnimationState();
    };

    /**
     * In order to omit useless redrawings - wait while all updates be fired
     */
    const updateAnimationState = () => {
        clearTimeout(updateAnimationStateRef.current!);
        updateAnimationStateRef.current = setTimeout(() => {
            /**
             * Show preloader if there is at least one request
             */
            const needToShow = Object.keys(requestedByRef.current).length > 0;

            /**
             * Work with AnimateItem only if state changed
             */
            if (needToShow !== lastNeedToShowRef.current) {
                setNeedToShow(needToShow);
                if (needToShow) {
                    animationRef.current?.goToAndPlay(0);
                } else {
                    animationRef.current?.stop();
                }
            }

            lastNeedToShowRef.current = needToShow;
        });
    };

    /** Create Animated item */
    useEffect(() => {
        animationRef.current = LottiePlayer.loadAnimation({
            container: ref.current!,
            renderer: 'svg',
            loop: true,
            autoplay: false,
            animationData: PreloaderJson,
            rendererSettings: {
                preserveAspectRatio: 'xMinYMin slice'
            }
        });
    }, []);

    return (
        <PreloaderContext.Provider value={{ show, hide }}>
            <div className={cx('Component', { show: needToShow })}>
                <span className={cx('Loader')} ref={ref} />
            </div>
            {children}
        </PreloaderContext.Provider>
    );
};

const Preloader = memo<{ id?: string }>((props) => {
    const context = useContext(PreloaderContext);

    useEffect(() => {
        const id = props.id ?? Math.random().toString();
        context.show(id);

        return () => {
            context.hide(id);
        };
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
});

export default Preloader;
