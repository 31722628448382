import React, { ReactNode } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { NavLink } from 'react-router-dom';
const cx = classNames.bind(styles);

const SectionTabs = ({
    options,
    className = ''
}: {
    className?: string;
    options: { children: ReactNode; to: string; exact?: boolean }[];
}) => {
    return (
        <div className={cx('Component', className)}>
            {options.map(({ children, to, exact }) => {
                return (
                    <NavLink key={to} to={to} exact={exact} activeClassName={cx('active')}>
                        {children}
                    </NavLink>
                );
            })}
        </div>
    );
};

export default SectionTabs;
