import React, { ReactNode } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

const Switcher = ({
    isActive,
    className = '',
    toggle,
    label
}: {
    isActive: boolean;
    toggle(): void;
    className?: string;
    label?: ReactNode;
}) => {
    return (
        <button type="button" onClick={toggle} className={cx('Component', className)}>
            <span className={cx('Switch', isActive && 'active')} />
            {label && <span className={cx('Label')}>{label}</span>}
        </button>
    );
};

export default Switcher;
