import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { useResendCodeMutation } from 'apollo/generted';
import { IconClock } from 'components/Icons';
import ButtonLink from 'components/UIKit/ButtonLink';
import { useGlobalError, constructAuthHeader, getErrorData, getErrorI18nText } from 'helpers';
import { useTimeout, useToast } from 'helpers/hooks';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

const TIMER_TIME = 60;

const ResendCodeBtn = ({
    onBlock,
    // stopTrigger,
    onUnexpectedError,
    onSuccess,
    verificationToken
}: {
    onSuccess(token: string): void;
    onUnexpectedError?(msg: string): void;
    onBlock: () => void;
    stopTrigger?: boolean;
    verificationToken?: string | null;
}) => {
    const [t] = useTranslation();
    const [resendCodeMutation, { loading }] = useResendCodeMutation();
    const resendTimer = useTimeout(TIMER_TIME);
    const toast = useToast();
    const onGlobalError = useGlobalError();

    /** on any try to send code we have to stop timer */
    // useEffect(() => {
    // if (stopTrigger) resendTimer.stop();
    // TODO: Fix exhaustive deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [stopTrigger]);

    const onError = (errorToShow: string) => {
        if (onUnexpectedError) {
            onUnexpectedError(errorToShow);
        } else {
            onGlobalError(errorToShow, toast);
        }
    };

    const onClickResend = () => {
        resendCodeMutation({
            context: verificationToken ? { headers: constructAuthHeader(verificationToken) } : undefined
        })
            .then(({ data }) => {
                const resendCode = data?.resendCode!;
                if (resendCode.__typename === 'ResendCodeSuccess') {
                    resendTimer.reset();
                    onSuccess(resendCode.verifyToken);
                    toast.success(t('formCodeVerification.resendSuccessMsg'));
                } else if (resendCode.__typename === 'TrySendCodeTooEarlyError') {
                    const { __typename } = resendCode ?? {};
                    onError(t([`formCodeVerification.error.${__typename}`, getErrorI18nText(t)]));
                } else {
                    const { __typename, errorMessage = '' } = resendCode ?? {};

                    console.log('RESEND CODE ELSE');

                    if (__typename === 'NoResendAttemptsError') {
                        onBlock();
                    } else {
                        onError(t([`formCodeVerification.error.${__typename}`, getErrorI18nText(t, errorMessage)]));
                    }
                }
            })
            .catch((e) => {
                onError(getErrorI18nText(t, getErrorData(e).message));
            });
    };

    if (resendTimer.timeLeft <= 0)
        return (
            <div className={cx('ResendBtnWrapper')}>
                {t('formCodeVerification.resendBtn_description')}
                <ButtonLink fontWeight="bold" disabled={loading} type="button" onClick={onClickResend}>
                    {t('formCodeVerification.resendBtn')}
                </ButtonLink>
            </div>
        );

    const minutes = parseInt(resendTimer.timeLeft / 60 + '');
    const seconds = parseInt((resendTimer.timeLeft % 60) + '');

    return (
        <p className={cx('Timer')}>
            {t('formCodeVerification.resendTimer')}
            <b className={cx('TimerTime')}>
                <IconClock /> {minutes}:{seconds.toString().padStart(2, '0')}
            </b>
        </p>
    );
};

export default ResendCodeBtn;
