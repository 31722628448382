import React from 'react';
import { Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import update from 'immutability-helper';
import {
    AuctionSortEnum,
    BuyAptRequestNode,
    BuyStatus,
    Event,
    useManagerBuyRequestsQuery,
    useManagerBuyRequestsWsSubscription
} from 'apollo/generted';
import { URL_MAP } from 'constant';
import { useModal } from 'helpers';
import formatDate from 'helpers/formatDate';
import { useManageContext } from '../context';
import { IconHome } from 'components/Icons';
import Button from 'components/UIKit/Button';
import Counter from 'components/UIKit/Counter';
import Status from 'components/UIKit/Status';
import ModalContacts, { Props as ModalContactProps } from 'containers/Modals/Contacts';
import ModalManagerBuyoutAgreement from 'containers/Modals/ManagerBuyoutAgreement/ManagerBuyoutAgreement';
import { Section, SectionHeader, SectionHeaderPanel, SectionMain, SectionTitle } from '../components/Section';
import Apartments from '../components/Apartments';
import Card from '../components/Card';
import SectionSort from '../components/SectionSort';
import SectionTabs from '../components/SectionTabs';
import { ManagerBuyoutAgreementProps } from 'containers/Modals/ManagerBuyoutAgreement/ManagerBuyoutAgreement.d';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

const Buyout = () => {
    const [t] = useTranslation();
    const [sort, setSort] = React.useState(AuctionSortEnum.UpdatedForRdDesc);
    const { activeBuyRequestsNum, finishedBuyRequestsNum } = useManageContext()?.city?.notaryUpdates ?? {};

    return (
        <Section>
            <SectionHeader withTabs>
                <SectionTitle>{t('pageManage.buyout.title')}</SectionTitle>
                <SectionHeaderPanel>
                    <SectionTabs
                        options={[
                            {
                                exact: true,
                                to: URL_MAP.manage.buyout.index,
                                children: (
                                    <>
                                        {t('pageManage.buyout.active.title')}
                                        {!!activeBuyRequestsNum && <Counter>{activeBuyRequestsNum}</Counter>}
                                    </>
                                )
                            },
                            {
                                to: URL_MAP.manage.buyout.finished,
                                children: (
                                    <>
                                        {t('pageManage.buyout.finished.title')}
                                        {!!finishedBuyRequestsNum && <Counter>{finishedBuyRequestsNum}</Counter>}
                                    </>
                                )
                            }
                        ]}
                    />

                    <SectionSort
                        options={[
                            {
                                isActive: sort === AuctionSortEnum.UpdatedForRdDesc,
                                label: t(`pageManage.buyout.sort.${AuctionSortEnum.UpdatedForRdDesc}`),
                                onClick: () => setSort(AuctionSortEnum.UpdatedForRdDesc)
                            },
                            {
                                isActive: sort === AuctionSortEnum.UpdatedForRd,
                                label: t(`pageManage.buyout.sort.${AuctionSortEnum.UpdatedForRd}`),
                                onClick: () => setSort(AuctionSortEnum.UpdatedForRd)
                            }
                        ]}
                    />
                </SectionHeaderPanel>
            </SectionHeader>

            <SectionMain>
                <Route exact path={URL_MAP.manage.buyout.index} render={() => <Active sort={sort} />} />
                <Route path={URL_MAP.manage.buyout.finished} render={() => <Finished sort={sort} />} />
            </SectionMain>
        </Section>
    );
};

const Active = ({ sort }: { sort: AuctionSortEnum }) => {
    const cityId = useManageContext().city?.id!;
    const [offset, setOffset] = React.useState(0);
    const modalContact = useModal<ModalContactProps>();
    const modalManagerBuyoutAgreement = useModal<ManagerBuyoutAgreementProps>();
    const [t] = useTranslation();
    const STEP = 10;

    const {
        data: apartmentsData,
        loading,
        error,
        ...apartmentsQuery
    } = useManagerBuyRequestsQuery({
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        variables: {
            first: STEP,
            offset,
            // sort: [sort],
            cityId,
            isActive: true
        }
    });

    /** is any loading now */
    const buyRequests = apartmentsData?.buyRequests?.buyRequests;
    const total = apartmentsData?.buyRequests?.pageInfo.total || 0;
    const page = offset! / STEP;

    const onChangePage = (page: number) => {
        window.scrollTo({ top: 0 });
        setOffset(page * STEP);
    };

    useManagerBuyRequestsWsSubscription({
        skip: !!error,
        variables: {
            cityId
        },
        onSubscriptionData: ({ subscriptionData: { data } }) => {
            apartmentsQuery.updateQuery((queryData) => {
                const { buyRequest: target, event } = data?.RDBuyRequests ?? {};

                if (!target) return queryData;

                const targetId = target.id;
                const needToRemove = target.status !== BuyStatus.InProgress;
                const isNew = event === Event.Add;
                const buyRequests = queryData.buyRequests?.buyRequests ?? [];

                /**
                 * Close all modals for this apartment
                 */
                if (needToRemove || event === Event.Update) {
                    if (
                        needToRemove &&
                        modalContact.payload?.type === 'buyer' &&
                        targetId === modalContact.payload.buyRequestId
                    ) {
                        modalContact.close();
                    }

                    if (
                        modalManagerBuyoutAgreement.payload?.buyRequestId === targetId &&
                        (needToRemove || (!modalManagerBuyoutAgreement.payload.isFinishing && target.isApprovedByRd))
                    ) {
                        modalManagerBuyoutAgreement.close();
                    }
                }

                if (needToRemove) {
                    const filteredBuyRequests = buyRequests.filter((a) => a?.id !== targetId);

                    if (!filteredBuyRequests.length) {
                        if (page === 0) {
                            apartmentsQuery.refetch();
                        } else {
                            onChangePage((page ?? 1) - 1);
                        }

                        return queryData;
                    }

                    return update(queryData, {
                        buyRequests: {
                            buyRequests: () => filteredBuyRequests,
                            pageInfo: {
                                total: (total) => total - 1
                            }
                        }
                    });
                } else if (isNew && page === 0 && buyRequests.every((apt) => apt?.id !== targetId)) {
                    return update(queryData, {
                        buyRequests: {
                            buyRequests: () => {
                                const newBuyRequests = [target, ...buyRequests];

                                if (newBuyRequests.length > STEP) newBuyRequests.length = STEP;

                                return newBuyRequests;
                            },
                            pageInfo: {
                                total: (total) => total + 1
                            }
                        }
                    });
                }

                return queryData;
            });
        }
    });

    const onSuccessFinish = (buyRequestId: BuyAptRequestNode['id']) => {
        apartmentsQuery.updateQuery((queryData) => {
            return update(queryData, {
                buyRequests: {
                    buyRequests: (arr) => arr?.filter((buyRequest) => buyRequest?.id !== buyRequestId)
                }
            });
        });
    };

    return (
        <Apartments
            loading={loading}
            onClickReload={() => apartmentsQuery.refetch()}
            error={error?.message}
            isEmpty={!buyRequests?.length}
            page={page}
            pageCount={Math.ceil(total / STEP)}
            empty={{
                icon: <IconHome />,
                title: t('pageManage.buyout.active.empty.title'),
                text: t('pageManage.buyout.active.empty.text')
            }}
            onChangePage={onChangePage}
        >
            {buyRequests?.map((buyRequest) => {
                if (!buyRequest) return null;

                const tokenName = buyRequest.tokenId;

                return (
                    <Card
                        data={buyRequest.apt! ?? {}}
                        buyRequest={buyRequest}
                        key={buyRequest.id}
                        to={URL_MAP.apartment(buyRequest.apt?.id)}
                        status={
                            <Status className={cx('Status')} color={buyRequest.isApprovedByBuyer ? 'green' : 'orange'}>
                                {t(
                                    `pageManage.buyout.active.card.status.${
                                        !buyRequest.isApprovedByBuyer
                                            ? 'WAITING_WINNER'
                                            : !buyRequest.isApprovedByRd
                                            ? 'WAITING_MANAGER'
                                            : 'WAITING_FINISH'
                                    }`
                                )}
                            </Status>
                        }
                        params={[
                            {
                                label: t('pageManage.buyout.active.card.params.user'),
                                value: buyRequest.buyerAccount
                            },
                            {
                                label: t('pageManage.buyout.active.card.params.created'),
                                value: formatDate(buyRequest.transferTime)
                            },
                            {
                                label: t('pageManage.buyout.active.card.params.paid'),
                                value: `${buyRequest.transferAmount} ${tokenName}`
                            },
                            {
                                label: t('pageManage.buyout.active.card.params.fee'),
                                value: `${buyRequest.feeAmount} ${tokenName}`
                            },
                            {
                                label: t('pageManage.buyout.active.card.params.refund'),
                                value: `${buyRequest.returnedAmount} ${tokenName}`
                            }
                        ]}
                        actions={
                            <>
                                <Button
                                    onClick={() =>
                                        modalManagerBuyoutAgreement.open({
                                            buyRequestId: buyRequest.id,
                                            onSuccessFinish: () => onSuccessFinish(buyRequest.id),
                                            isFinishing: !!buyRequest.isApprovedByRd
                                        })
                                    }
                                    disabled={!buyRequest.isApprovedByBuyer}
                                    color="primary"
                                >
                                    {t(
                                        `pageManage.buyout.active.card.actions.${
                                            buyRequest.isApprovedByRd ? 'finish' : 'approve'
                                        }`
                                    )}
                                </Button>
                                <Button
                                    onClick={() =>
                                        modalContact.open({
                                            buyRequestId: buyRequest.id,
                                            type: 'buyer'
                                        })
                                    }
                                    color="secondary"
                                >
                                    {t('pageManage.buyout.active.card.actions.contact')}
                                </Button>
                            </>
                        }
                    />
                );
            })}

            <ModalManagerBuyoutAgreement {...modalManagerBuyoutAgreement} {...modalManagerBuyoutAgreement.payload!} />
            <ModalContacts {...modalContact} {...modalContact.payload!} />
        </Apartments>
    );
};

const Finished = ({ sort }: { sort: AuctionSortEnum }) => {
    const cityId = useManageContext().city?.id!;
    const [offset, setOffset] = React.useState(0);
    const [t] = useTranslation();
    const STEP = 10;

    const {
        data: apartmentsData,
        loading,
        error,
        ...apartmentsQuery
    } = useManagerBuyRequestsQuery({
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        variables: {
            first: STEP,
            offset,
            // sort: [sort],
            cityId,
            isActive: false
        }
    });

    /** is any loading now */
    const buyRequests = apartmentsData?.buyRequests?.buyRequests;
    const total = apartmentsData?.buyRequests?.pageInfo.total || 0;
    const page = offset! / STEP;

    const onChangePage = (page: number) => {
        window.scrollTo({ top: 0 });
        setOffset(page * STEP);
    };

    useManagerBuyRequestsWsSubscription({
        skip: !!error,
        variables: {
            cityId
        },
        onSubscriptionData: ({ subscriptionData: { data } }) => {
            apartmentsQuery.updateQuery((queryData) => {
                const { buyRequest: target, event } = data?.RDBuyRequests ?? {};
                if (!target) return queryData;

                const targetId = target.id;
                const isNew = event === Event.Update;
                const buyRequests = queryData.buyRequests?.buyRequests ?? [];

                if (isNew && page === 0 && buyRequests.every((apt) => apt?.id !== targetId)) {
                    return update(queryData, {
                        buyRequests: {
                            buyRequests: () => {
                                const newBuyRequests = [target, ...buyRequests];
                                if (newBuyRequests.length > STEP) newBuyRequests.length = STEP;
                                return newBuyRequests;
                            },
                            pageInfo: {
                                total: (total) => total + 1
                            }
                        }
                    });
                }

                return queryData;
            });
        }
    });

    return (
        <Apartments
            loading={loading}
            onClickReload={() => apartmentsQuery.refetch()}
            error={error?.message}
            isEmpty={!buyRequests?.length}
            page={page}
            pageCount={Math.ceil(total / STEP)}
            empty={{
                icon: <IconHome />,
                title: t('pageManage.buyout.finished.empty.title'),
                text: t('pageManage.buyout.finished.empty.text')
            }}
            onChangePage={onChangePage}
        >
            {buyRequests?.map((buyRequest) => {
                if (!buyRequest) return null;

                const actualRental = buyRequest.apt?.actualRental;
                const isSold = buyRequest.status === BuyStatus.Finished;
                const tokenName = buyRequest.tokenId;

                return (
                    <Card
                        data={buyRequest.apt}
                        buyRequest={buyRequest}
                        key={buyRequest.id}
                        to={URL_MAP.apartment(buyRequest.apt?.id)}
                        status={
                            <Status className={cx('Status')} color={isSold ? 'green' : 'orange'}>
                                {t(`pageManage.buyout.finished.card.status.${buyRequest.status}`)}
                            </Status>
                        }
                        params={[
                            {
                                label: t('pageManage.buyout.finished.card.params.user'),
                                value: buyRequest.buyerAccount
                            },
                            {
                                label: t('pageManage.buyout.finished.card.params.created'),
                                value: formatDate(buyRequest.transferTime)
                            },
                            {
                                label: t(
                                    `pageManage.buyout.finished.card.params.${isSold ? 'buyoutDate' : 'refuseDate'}`
                                ),
                                value: formatDate(buyRequest.endTime)
                            },
                            {
                                label: t('pageManage.buyout.finished.card.params.paid'),
                                value: `${buyRequest.transferAmount} ${tokenName}`
                            },
                            {
                                label: t('pageManage.buyout.finished.card.params.fee'),
                                value: `${buyRequest.feeAmount} ${tokenName}`
                            },
                            {
                                label: t('pageManage.buyout.finished.card.params.refund'),
                                value: `${buyRequest.returnedAmount} ${tokenName}`
                            },
                            isSold && actualRental
                                ? {
                                      label: t('pageManage.buyout.finished.card.params.tenant'),
                                      value: actualRental.tenantAccount
                                  }
                                : null,
                            isSold && actualRental
                                ? {
                                      label: t('apartmentStats.license.label'),
                                      value: formatDate(actualRental.expirationDate)
                                  }
                                : null
                        ]}
                        actions={[]}
                    />
                );
            })}
        </Apartments>
    );
};

export default React.memo(Buyout);
