import { ApartmentNode, useApartmentExpensesQuery } from 'apollo/generted';
import classNames from 'classnames/bind';
import Modal, { ModalBody, ModalHeader, ModalProps } from 'components/UIKit/Modal';
import ModalErrorScreen from 'components/UIKit/Modal/ErrorScreen';
import ModalLoadingScreen from 'components/UIKit/Modal/LoadingScreen';
import { addSpacesForThousands, getErrorData, getErrorI18nText } from 'helpers';
import React, { Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

export type Props = {
    aptId?: ApartmentNode['id'];
};

const ModalApartmentExpenses = memo(({ isOpen, close, ...props }: Props & ModalProps) => {
    const [t] = useTranslation();
    return (
        <Modal close={close} isOpen={isOpen}>
            <ModalHeader>{t('modalApartmentExpenses.title')}</ModalHeader>
            <Main {...props} />
        </Modal>
    );
});

const Main = memo(({ aptId }: Props) => {
    const [t] = useTranslation();
    const query = useApartmentExpensesQuery({ variables: { id: aptId! } });
    const error = query.error && getErrorI18nText(t, getErrorData(query.error).message);

    const { apartment, aptExpenses } = query.data ?? {};
    const currency = apartment?.communalCurrency ?? '';

    return (
        <ModalBody>
            {query.loading ? (
                <ModalLoadingScreen />
            ) : error ? (
                <ModalErrorScreen isStatic text={error} resetError={() => query.refetch()} />
            ) : (
                <Fragment>
                    <p className={cx('Description')}>{t('modalApartmentExpenses.description')}</p>
                    <ul className={cx('Details')}>
                        {apartment?.totalExpenses && (
                            <li className={cx('big')}>
                                <span>{t('modalApartmentExpenses.total')}</span>
                                <span>
                                    {addSpacesForThousands(apartment?.totalExpenses)} {currency}
                                </span>
                            </li>
                        )}
                        {apartment?.communalPayment && (
                            <li>
                                <span>{t('apartmentStats.communalPayment.label')}</span>
                                <span>
                                    {addSpacesForThousands(apartment?.communalPayment)} {currency}
                                </span>
                            </li>
                        )}

                        {aptExpenses?.map((item) => (
                            <li key={item?.expense.id}>
                                <span>{item?.expense.name}</span>
                                <span>
                                    {addSpacesForThousands(item?.value?.toString())} {currency}
                                </span>
                            </li>
                        ))}
                    </ul>
                </Fragment>
            )}
        </ModalBody>
    );
});

export default ModalApartmentExpenses;
