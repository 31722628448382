import React, { Fragment, useState } from 'react';
import Modal, {
    ModalHeader,
    ModalProps,
    ModalBody,
    useModalContext,
    ModalContent,
    ModalText,
    ModalTextParam
} from 'components/UIKit/Modal';
import { useTranslation } from 'react-i18next';
import Button from 'components/UIKit/Button';
import { useRefuseToWinMutation } from 'apollo/generted';
import { getErrorData, getErrorI18nText } from 'helpers';
import ModalErrorScreen from 'components/UIKit/Modal/ErrorScreen';
import CheckboxField from 'components/UIKit/CheckboxField';
import { useToast } from 'helpers/hooks';

type Props = {
    id: number;
    address: string;
    isWinnerAgree: boolean;
};

const ModalRefuseToWin = ({ id, address, isWinnerAgree, ...props }: ModalProps & Props) => {
    return (
        <Modal {...props}>
            <Main address={address} id={id} isWinnerAgree={isWinnerAgree} />
        </Modal>
    );
};

const Main = ({ id, address, isWinnerAgree }: Props) => {
    const [t] = useTranslation();
    const { close } = useModalContext();
    const [formError, setFormError] = useState('');
    const [agree, setAgree] = useState(false);
    const toast = useToast();
    const [refuseToWinMutation, { loading }] = useRefuseToWinMutation();

    const refuseToWin = () => {
        refuseToWinMutation({ variables: { input: { aptId: id } } })
            .then(({ data }) => {
                if (data?.refuseToWin?.__typename === 'RefuseToWinSuccess') {
                    toast.success(t('modalRefuseToWin.successMsg'));
                    close();
                } else {
                    setFormError(
                        t([
                            `modalRefuseToWin.error.${data?.refuseToWin?.__typename}`,
                            getErrorI18nText(t, data?.refuseToWin?.errorMessage)
                        ])
                    );
                }
            })
            .catch((e) => {
                setFormError(getErrorI18nText(t, getErrorData(e).message));
            });
    };

    return (
        <Fragment>
            <ModalHeader>{t('modalRefuseToWin.title')}</ModalHeader>
            <ModalBody>
                <ModalContent withBorder>
                    <ModalText>{t('modalRefuseToWin.description')}</ModalText>
                    {isWinnerAgree && (
                        <ModalText>{t('modalRefuseToWin.description', { context: 'isWinnerAgree' })}</ModalText>
                    )}

                    <ModalTextParam label={t('apartmentStats.address.label')} value={address} />
                </ModalContent>
                <CheckboxField
                    label={t('modalRefuseToWin.agree')}
                    checked={agree}
                    onChange={(e) => setAgree(e.target.checked)}
                />

                <Button color="primary" loading={loading} disabled={!agree} onClick={refuseToWin} type="button">
                    {t('modalRefuseToWin.btnSubmit')}
                </Button>
            </ModalBody>
            <ModalErrorScreen resetError={() => setFormError('')} text={formError} />
        </Fragment>
    );
};

export default ModalRefuseToWin;
