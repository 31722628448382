import { combineReducers } from 'redux';
import Base from './Base/reducer';
import SignModal from './SignModal/reducer';

const rootReducer = combineReducers({
    Base,
    SignModal
});

export type ApplicationState = {
    Base: ReturnType<typeof Base>;
    SignModal: ReturnType<typeof SignModal>;
};

export default rootReducer;
