import { ApartmentNode, AptStatus, Maybe, RentalNode } from 'apollo/generted';
import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
const cx = classNames.bind(styles);

type Props = Pick<ApartmentNode, 'status'> & {
    actualRental?: Maybe<Pick<RentalNode, 'isIAmTenant' | 'isEvictionStartedByTenant' | 'evictionReason'>>;
};

const EvictionReason = ({ status, actualRental }: Props) => {
    const [t] = useTranslation();
    const { isIAmTenant, isEvictionStartedByTenant, evictionReason } = actualRental ?? {};
    if (status !== AptStatus.Eviction || !isIAmTenant) return null;

    return (
        <p className={cx('Component')}>
            <b>{t('apartmentStats.evictionReason.label')}</b>

            <span>
                {isEvictionStartedByTenant ? t('apartmentStats.evictionReason.startedByTenant') : evictionReason}
            </span>
        </p>
    );
};

export default EvictionReason;
