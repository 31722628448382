import classNames from 'classnames/bind';
import React, { memo } from 'react';
import { Link, LinkProps, NavLink, NavLinkProps } from 'react-router-dom';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

// TODO: Add link component type support
export type ButtonProps = {
    /** @default button*/
    tag?: 'button';
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export type ButtonPropsLink = { tag: 'link' } & LinkProps;
export type ButtonPropsNavLink = { tag: 'navlink' } & NavLinkProps;

export type ButtonPropsAnchor = { tag: 'a' } & React.AnchorHTMLAttributes<HTMLAnchorElement>;

export type Props = (ButtonProps | ButtonPropsLink | ButtonPropsNavLink | ButtonPropsAnchor) & {
    /** @default primary */
    color?: 'primary' | 'black';
    /** @default normal */
    fontSize?: 'small' | 'normal' | 'big';
    /** @default medium */
    fontWeight?: 'regular' | 'medium' | 'bold';
};

const ButtonLink = memo(
    ({
        className,
        tag = 'button',
        children,
        color = 'primary',
        fontSize = 'normal',
        fontWeight = 'medium',
        ...rest
    }: Props) => {
        const props = {
            className: cx('Btn', className, color, fontSize, fontWeight),
            children: <span tabIndex={-1}>{children}</span>
        };

        if (tag === 'a') {
            // TODO: Add anchor
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            return <a {...props} {...(rest as ButtonPropsAnchor)} />;
        } else if (tag === 'link') {
            return <Link {...props} {...(rest as ButtonPropsLink)} />;
        } else if (tag === 'navlink') {
            return <NavLink {...props} activeClassName={cx('active')} {...(rest as ButtonPropsNavLink)} />;
        } else {
            return <button {...props} {...(rest as ButtonProps)} />;
        }
    }
);
export default ButtonLink;
