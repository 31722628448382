import classNames from 'classnames/bind';
import { IconNoWiFi } from 'components/Icons';
import Modal, { ModalBody } from 'components/UIKit/Modal';
import { useModal } from 'helpers';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

const NetworkController = () => {
    const modal = useModal(false);
    const [t] = useTranslation();

    useEffect(() => {
        const updateOnlineStatus = () => {
            navigator.onLine ? modal.close() : modal.open();
        };

        updateOnlineStatus();

        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);

        return () => {
            window.removeEventListener('online', updateOnlineStatus);
            window.removeEventListener('offline', updateOnlineStatus);
        };
        // TODO: Fix exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Modal closable={false} isOpen={modal.isOpen}>
            <ModalBody className={cx('ModalBody')}>
                <div className={cx('Component')}>
                    <IconNoWiFi />
                    <h2>{t('networkController.title')}</h2>
                    <p>{t('networkController.text')}</p>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default NetworkController;
