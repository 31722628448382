import classNames from 'classnames/bind';
import {
    IconAngleDownBig,
    IconAngleRight,
    IconBlockchain,
    IconDocument,
    IconHeartActive,
    IconInformationButton,
    IconKey,
    IconMap,
    IconPhone,
    IconPlus,
    IconStatistics,
    IconTrophy
} from 'components/Icons';
import Button from 'components/UIKit/Button';
import { BREAKPOINT_IPAD, BREAKPOINT_LANDSCAPE, BREAKPOINT_PHONE, BREAKPOINT_TABLET, URL_MAP } from 'constant';
import { usePixelRatio } from 'helpers';
import { useIsTouchableDevice, useWidthCondition } from 'helpers/hooks';
import { useCheckRequiredAuth } from 'helpers/useCallWithCheckAuth';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation } from 'react-router-dom';
import NavContentLink from './components/NavContentLink';
import styles from './index.module.scss';
import LanguageSwitcher from './LanguageSwitcher';
import Notifications from './Notifications';
import Sidemenu from './Sidemenu';
import UserDropdown from './UserDropdown';
const cx = classNames.bind(styles);

const Header = ({ isFluid = false }) => {
    const [t] = useTranslation();
    const headerRef = useRef<HTMLDivElement>(null);
    const dividerRef = useRef<HTMLDivElement>(null);
    const pixelRatio = usePixelRatio();
    const { pathname } = useLocation();
    const isFavoritePage = pathname === URL_MAP.profile.myFavorites;
    const showAddBtnText = useWidthCondition(
        (w) => w >= BREAKPOINT_IPAD || (w < BREAKPOINT_TABLET && w >= BREAKPOINT_LANDSCAPE)
    );
    const checkRequiredAuth = useCheckRequiredAuth();
    const isPhone = useWidthCondition((w) => w < BREAKPOINT_PHONE);
    const isTabletOrLess = useWidthCondition((w) => w < BREAKPOINT_TABLET);

    const isApartmentFormPage =
        pathname.startsWith(URL_MAP.addApartment) || pathname.startsWith(URL_MAP.editApartment(''));

    const isExplorePage = pathname === URL_MAP.explore;
    const isReviewApartmentPage = pathname.startsWith(URL_MAP.reviewApartment(''));

    const isFluidHeader = isFluid || isExplorePage || isReviewApartmentPage || isApartmentFormPage;

    /** Placeholder for header */
    useEffect(() => {
        const update = () => {
            if (dividerRef.current) {
                if (headerRef.current?.style?.display === 'none') {
                    headerRef.current.style.display = '';
                }
                dividerRef.current!.style.height = `${headerRef.current?.offsetHeight ?? 0}px`;
            }
        };
        update();

        window.addEventListener('resize', update);

        return () => window.removeEventListener('resize', update);
    }, []);

    return (
        <>
            <div style={{ flexShrink: 0 }} ref={dividerRef} />

            <header ref={headerRef} className={cx('Component')}>
                <div className={cx('Container', isFluidHeader && 'fluid')}>
                    <div className={cx('LeftSide')}>
                        {isTabletOrLess && <Sidemenu />}
                        <Link to={URL_MAP.search} className={cx('LogoLink')}>
                            <img alt="LiST" src={require(`assets/images/logo@${pixelRatio}x.png`).default} />
                        </Link>
                        {!isPhone && <LanguageSwitcher />}
                    </div>

                    {!isTabletOrLess && (
                        <div className={cx('CenterSide')}>
                            <Nav />
                        </div>
                    )}

                    <div className={cx('RightSide')}>
                        {!isApartmentFormPage && (
                            <Button
                                tag="link"
                                color="secondary-grey"
                                size="small"
                                onClick={checkRequiredAuth}
                                to={URL_MAP.addApartment}
                                iconLeft={<IconPlus />}
                            >
                                {showAddBtnText && t('header.nav.addApartment')}
                            </Button>
                        )}

                        <Notifications />
                        {!isFavoritePage && (
                            <Button
                                size="small"
                                color="tertiary-grey"
                                onClick={checkRequiredAuth}
                                tag="link"
                                to={URL_MAP.profile.myFavorites}
                                iconRight={<IconHeartActive />}
                            />
                        )}
                        <UserDropdown />
                    </div>
                </div>
            </header>
        </>
    );
};

const Nav = () => {
    const [t] = useTranslation();
    const { pathname } = useLocation();
    const isTouchable = useIsTouchableDevice();
    const [hovered, setHovered] = useState('');

    const isActiveAboutLinksGroup =
        [URL_MAP.contacts, URL_MAP.statistics, URL_MAP.regions].includes(pathname) ||
        pathname === URL_MAP.landing ||
        pathname.startsWith(URL_MAP.docs.index);

    const isActiveHelpLinksGroup = [URL_MAP.faq].includes(pathname) || pathname.startsWith(URL_MAP.help.index);

    const contructNavItemHoverControl = (id: string) => {
        return {
            onClick: isTouchable ? () => setHovered(hovered === id ? '' : id) : undefined,
            onMouseLeave: isTouchable ? undefined : () => setHovered(''),
            onMouseEnter: isTouchable ? undefined : () => setHovered(id),
            'data-hovered': id === hovered || undefined
        };
    };

    useEffect(() => setHovered(''), [pathname]);

    return (
        <nav data-hovered={hovered || undefined} className={cx('Nav')}>
            <div {...contructNavItemHoverControl('about')} className={cx('NavItem')}>
                <span
                    className={cx('NavItemControl', {
                        active: isActiveAboutLinksGroup
                    })}
                >
                    {t('header.nav.about.label')}
                    <IconAngleDownBig />
                </span>
                <div className={cx('NavItemContent')}>
                    <div className={cx('AboutLinks')}>
                        <NavContentLink
                            icon={<IconInformationButton />}
                            label={t('header.nav.about.links.aboutProject')}
                            to={URL_MAP.landing}
                            exact
                        />
                        <NavContentLink
                            icon={<IconDocument />}
                            label={t('header.nav.about.links.docs')}
                            to={URL_MAP.docs.index}
                        />
                        <NavContentLink
                            icon={<IconPhone />}
                            label={t('header.nav.about.links.contacts')}
                            to={URL_MAP.contacts}
                        />
                        <NavContentLink
                            icon={<IconStatistics />}
                            label={t('header.nav.about.links.statistics')}
                            to={URL_MAP.statistics}
                        />
                        <NavContentLink
                            icon={<IconMap />}
                            label={t('header.nav.about.links.regions')}
                            to={URL_MAP.regions}
                        />
                    </div>
                </div>
            </div>
            <div {...contructNavItemHoverControl('search')} className={cx('NavItem')}>
                <NavLink activeClassName={cx('active')} className={cx('NavItemControl')} to={URL_MAP.search}>
                    {t('header.nav.search')}
                </NavLink>
            </div>
            <div {...contructNavItemHoverControl('help')} className={cx('NavItem')}>
                <span
                    className={cx('NavItemControl', {
                        active: isActiveHelpLinksGroup
                    })}
                >
                    {t('header.nav.help.label')}
                    <IconAngleDownBig />
                </span>
                <div className={cx('NavItemContent', 'HelpNavContent')}>
                    <div className={cx('HelpLinks')}>
                        <NavContentLink
                            icon={<IconKey />}
                            label={t('header.nav.help.links.contestParticipance')}
                            to={URL_MAP.help.contestParticipance}
                        />
                        <NavContentLink
                            icon={<IconTrophy />}
                            label={t('header.nav.help.links.contestResultValidation')}
                            to={URL_MAP.help.contestResultValidation}
                        />
                        <NavContentLink
                            icon={<IconBlockchain />}
                            label={t('header.nav.help.links.tokenizationProcess')}
                            to={URL_MAP.help.tokenizationProcess}
                        />
                    </div>
                    <div className={cx('HelpAlternate')}>
                        <p>{t('header.nav.help.text')}</p>
                        <NavLink
                            className={cx('HelpAlternateLink')}
                            to={URL_MAP.help.index}
                            activeClassName={cx('active')}
                        >
                            <IconAngleRight />
                            {t('header.nav.help.links.articles')}
                        </NavLink>
                        <NavLink className={cx('HelpAlternateLink')} to={URL_MAP.faq} activeClassName={cx('active')}>
                            <IconAngleRight />
                            {t('header.nav.help.links.faq')}
                        </NavLink>
                    </div>
                </div>
            </div>
            <div {...contructNavItemHoverControl('explore')} className={cx('NavItem')}>
                <NavLink activeClassName={cx('active')} className={cx('NavItemControl')} to={URL_MAP.explore}>
                    {t('header.nav.checkAddress')}
                </NavLink>
            </div>
        </nav>
    );
};

export default Header;
