import { ApolloProvider } from '@apollo/client';
import client from 'apollo/';
import Footer from 'components/Footer';
import Header from 'components/Header';
import { URL_MAP } from 'constant/';
import ModalSign from 'containers/Modals/Sign';
import React, { createContext, lazy, memo, PropsWithChildren, useContext, useEffect, useRef } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import store from 'store/';
import AppInitializer from './Helpers/AppInitializer';
import BrowserValidator from './Helpers/BrowserValidator';
import ErrorBoundary from './Helpers/ErrorBoundary';
import NetworkController from './Helpers/NetworkController';
import PrivateRoute from './Helpers/PrivateRoute';
import Subscribes from './Helpers/Subscribes';
import VersionController from './Helpers/VersionController';
import PageError from './Pages/Error';

const PageDocs = lazy(() => import('containers/Pages/Docs'));
const PageManage = lazy(() => import('containers/Pages/Manage'));
const PageLanding = lazy(() => import('containers/Pages/Landing'));
const PageSearch = lazy(() => import('containers/Pages/Search'));
const PageApartment = lazy(() => import('./Pages/Apartment'));
const PageValidateContest = lazy(() => import('./Pages/ValidateContest'));
const PageAddApartment = lazy(() => import('./Pages/AddApartment'));
const PageProfile = lazy(() => import('./Pages/Profile'));
const PageReviewApartment = lazy(() => import('./Pages/ReviewApartment'));
const PageExplore = lazy(() => import('./Pages/Explore'));
const PageContacts = lazy(() => import('./Pages/Contacts'));
const PageRegions = lazy(() => import('./Pages/Regions'));
const PageStatistics = lazy(() => import('./Pages/Statistics'));
const PageFaq = lazy(() => import('./Pages/Faq/Faq'));
const PageHelp = lazy(() => import('./Pages/Help'));

const PageTracking = memo(() => {
    const { pathname } = useLocation();

    useEffect(() => {
        document.getElementsByTagName('html')[0]?.scrollTo(0, 0);
    }, [pathname]);

    return null;
});

type AppContextType = {
    initialPath: React.MutableRefObject<string>;
};

const AppContext = createContext({} as AppContextType);

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }: PropsWithChildren<{}>) => {
    const history = useHistory();
    const initialPath = useRef(history.location.pathname);

    return <AppContext.Provider value={{ initialPath }}>{children}</AppContext.Provider>;
};

const SEO = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.ym?.(86162859, 'hit', window.location.href);
    }, [pathname]);

    return null;
};

const CSSHelpers = () => {
    /**
     * @description
     * 100vh workaround for mobile browsers and tablets
     * @bug
     * We have problem now with resize event.
     * As because it fires many times - UI have some lags on resize
     */
    useEffect(() => {
        const updateSize = () => {
            const size = Math.max(
                /**
                 * This value does not update after removing bottom bar on mobile
                 */
                document.documentElement.clientHeight || 0,
                /**
                 * Safari has bug with this value after pulling down page
                 */
                window.innerHeight || 0
            );

            document.documentElement.style.setProperty('--app-height', `${size}px`);
        };

        updateSize();
        window.addEventListener('resize', updateSize);

        return () => {
            window.removeEventListener('resize', updateSize);
        };
    }, []);
    return null;
};

const App = () => {
    return (
        <BrowserValidator>
            <ApolloProvider client={client}>
                <Provider store={store}>
                    <BrowserRouter>
                        <AppProvider>
                            <CSSHelpers />
                            <PageTracking />
                            <ErrorBoundary>
                                <VersionController>
                                    {/* Render header inside PageSearch to control fluid state */}
                                    <Route
                                        path="*"
                                        render={({ location: { pathname } }) => {
                                            const isSearchPage = pathname.startsWith(URL_MAP.search);
                                            if (isSearchPage) return null;

                                            return <Header />;
                                        }}
                                    />

                                    <AppInitializer>
                                        <Subscribes />

                                        <Switch>
                                            <Route exact path={URL_MAP.landing} component={PageLanding} />
                                            <Route path={URL_MAP.help.index} component={PageHelp} />
                                            <Route path={URL_MAP.docs.index} component={PageDocs} />
                                            <Route path={URL_MAP.regions} component={PageRegions} />
                                            <Route path={URL_MAP.contacts} component={PageContacts} />
                                            <Route path={URL_MAP.explore} component={PageExplore} />
                                            <Route path={URL_MAP.search} component={PageSearch} />
                                            <Route path={URL_MAP.statistics} component={PageStatistics} />
                                            <PrivateRoute path={URL_MAP.profile.index} component={PageProfile} />
                                            <PrivateRoute path={URL_MAP.manage.index} component={PageManage} />
                                            <Route exact path={URL_MAP.apartment()} component={PageApartment} />
                                            <Route path={URL_MAP.validateContest()} component={PageValidateContest} />
                                            <Route path={URL_MAP.faq} component={PageFaq} />
                                            <PrivateRoute
                                                path={[URL_MAP.addApartment, URL_MAP.editApartment()]}
                                                component={PageAddApartment}
                                            />
                                            <PrivateRoute
                                                path={[URL_MAP.reviewApartment()]}
                                                component={PageReviewApartment}
                                            />
                                            <Route path="*" component={PageError} />
                                        </Switch>
                                        <Route
                                            path="*"
                                            render={({ location: { pathname } }) => {
                                                const isReviewFormPage = pathname.startsWith(
                                                    URL_MAP.reviewApartment('')
                                                );

                                                const isApartmentFormPage =
                                                    pathname.startsWith(URL_MAP.addApartment) ||
                                                    pathname.startsWith(URL_MAP.editApartment(''));

                                                if (isApartmentFormPage || isReviewFormPage) return null;

                                                return <Footer />;
                                            }}
                                        />
                                        <ToastContainer
                                            position="top-center"
                                            autoClose={5000}
                                            closeButton={false}
                                            hideProgressBar
                                        />
                                        <ModalSign />
                                        <NetworkController />
                                    </AppInitializer>
                                    <SEO />
                                </VersionController>
                            </ErrorBoundary>
                        </AppProvider>
                    </BrowserRouter>
                </Provider>
            </ApolloProvider>
        </BrowserValidator>
    );
};

export default App;
