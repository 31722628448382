import classNames from 'classnames/bind';
import { IconAngleDown } from 'components/Icons';
import TextField, { Props as TextFieldProps } from 'components/UIKit/TextField';
import { useDropdown } from 'helpers/hooks';
import React, { memo, ReactNode, useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

export type Option = {
    element: ReactNode;
    closeOnClick?: boolean;
    key?: string | number;
};

export type Props = TextFieldProps & {
    options: Option[];
    onClose?: () => void;
    optionsListClassName?: string;
    withAngleIcon?: boolean;
};

/**
 * @description
 * Custom lowlevel select field (building block). Based on `<TextField />`
 * Main purpose - build any highlevel components: select, multiselect, search.
 *
 * @features
 * - list navigation by `ArrowUp` & `ArrowDown`
 * - on press `Enter` fire `click` event for hovered list item
 * - list position based on `popper.js`
 *
 * @important Here are a few rules you must follow in order
 * to get the component working properly:
 * - pass unique `key` props to options
 * - pass interactive element in options (buttons, labels with binding to input, inputs only)
 * - every option must have `onClick`, that fires on select
 */
const DropdownField = memo(
    ({
        options,
        onFocus,
        onKeyDown,
        onClose,
        rightControl,
        withAngleIcon,
        optionsListClassName = '',
        ...props
    }: Props) => {
        /* eslint-disable react-hooks/rules-of-hooks */
        const dropdown = useDropdown({ onClose });
        const [hoveredIndex, setHoveredIndex] = useState<null | number>(null);
        const hoveredItemRef = useRef<null | HTMLLIElement>(null);

        const focusInput = () => dropdown.ref?.querySelector('input')?.focus();

        // add navigation into the list
        const _onKeyDown = dropdown.open
            ? (e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                      e.preventDefault();
                      e.stopPropagation();
                      const isDown = e.key === 'ArrowDown';
                      const isUp = e.key === 'ArrowUp';
                      const lastIndex = options.length - 1;
                      let newIndex = hoveredIndex;

                      if (isUp) {
                          if (newIndex === null || newIndex === 0) {
                              newIndex = lastIndex;
                          } else {
                              newIndex -= 1;
                          }
                      } else if (isDown) {
                          if (newIndex === null || newIndex === lastIndex) {
                              newIndex = 0;
                          } else {
                              newIndex += 1;
                          }
                      }

                      setHoveredIndex(newIndex);
                      process.nextTick(() => hoveredItemRef.current?.scrollIntoView(false));
                  } else if (e.key === 'Enter') {
                      if (hoveredIndex !== null) {
                          if (options[hoveredIndex].closeOnClick) {
                              dropdown.setOpen(false);
                          }
                          (hoveredItemRef.current?.children[0] as any)?.click();
                      }
                  }

                  onKeyDown?.(e);
              }
            : onKeyDown;

        /**
         * Reset hovered element
         */
        useEffect(() => {
            setHoveredIndex(null);
        }, [dropdown.open, options.length]);

        /**
         * Handle displaying of options list
         */
        useEffect(() => {
            if (!dropdown.open) return;

            /**
             * If next active (focused) element is outside dropdown - close dropdown
             */
            const listener = () => {
                if (!dropdown.ref?.contains(document.activeElement)) {
                    dropdown.setOpen(false);
                }
            };

            document.addEventListener('focus', listener, true);
            return () => {
                document.removeEventListener('focus', listener, true);
            };
            // TODO: Fix exhaustive deps
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [dropdown.open]);

        return (
            <TextField
                /**
                 * If element is interactive - open dropdown and set cursor to the end
                 */
                onFocus={(e) => {
                    if (!props.readOnly && !props.disabled && !dropdown.open) {
                        const caretPos = props.value?.toString().length ?? 0;
                        e.target.setSelectionRange(caretPos, caretPos);
                        dropdown.setOpen(true);
                    }
                    onFocus?.(e);
                }}
                onKeyDown={_onKeyDown}
                containerRef={dropdown.setRef}
                rightControl={
                    <>
                        {rightControl}
                        {withAngleIcon && <IconAngleDown className={cx('Icon', { rotate: dropdown.open })} />}
                    </>
                }
                {...props}
            >
                {dropdown.open && !!options.length && (
                    <ul
                        className={cx('Dropdown', optionsListClassName)}
                        ref={dropdown.setPopperRef}
                        style={dropdown.popper.styles.popper}
                        {...dropdown.popper.attributes.popper}
                    >
                        {options.map(({ closeOnClick = true, element, key }, i) => (
                            <li
                                onClick={() => {
                                    if (closeOnClick) {
                                        dropdown.setOpen(false);
                                    } else {
                                        focusInput();
                                    }
                                }}
                                ref={hoveredIndex === i ? hoveredItemRef : undefined}
                                key={`${key ?? i}-${i}`}
                                onMouseEnter={() => setHoveredIndex(i)}
                                className={cx({ hover: hoveredIndex === i })}
                            >
                                {element}
                            </li>
                        ))}
                    </ul>
                )}

                {props.children}
            </TextField>
        );
    }
);
export default DropdownField;
