import classNames from 'classnames/bind';
import {
    IconClose,
    IconDocument,
    IconHome,
    IconInformationButton,
    IconMap,
    IconPhone,
    IconPin,
    IconQuestionButton,
    IconSidebar,
    IconStatistics
} from 'components/Icons';
import Sidebar from 'components/Sidebar';
import ButtonLink from 'components/UIKit/ButtonLink';
import { BREAKPOINT_PHONE, URL_MAP } from 'constant';
import { useModal, usePixelRatio } from 'helpers';
import { useWidthCondition } from 'helpers/hooks';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import NavContentLink from '../components/NavContentLink';
import LanguageSwitcher from '../LanguageSwitcher';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

const Sidemenu = () => {
    const controller = useModal();
    const [t] = useTranslation();
    const { pathname } = useLocation();
    const isPhone = useWidthCondition((w) => w < BREAKPOINT_PHONE);
    const pixelRatio = usePixelRatio();
    // TODO: Fix exhaustive deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(controller.close, [pathname]);

    return (
        <>
            <button onClick={controller.open} className={cx('Control')}>
                <IconSidebar />
            </button>

            <Sidebar {...controller}>
                <header className={cx('Header')}>
                    <div className={cx('HeaderMain')}>
                        <button onClick={controller.close} className={cx('BtnClose')}>
                            <IconClose />
                        </button>

                        <Link to={URL_MAP.search} className={cx('LogoLink')}>
                            <img alt="LiST" src={require(`assets/images/logo@${pixelRatio}x.png`).default} />
                        </Link>
                    </div>

                    {isPhone && <LanguageSwitcher />}
                </header>

                <main className={cx('Main')}>
                    <nav className={cx('Nav')}>
                        <NavContentLink
                            icon={<IconInformationButton />}
                            to={URL_MAP.landing}
                            exact
                            label={t('header.nav.about.links.aboutProject')}
                        />
                        <NavContentLink icon={<IconHome />} to={URL_MAP.search} label={t('header.nav.search')} />
                        <NavContentLink
                            icon={<IconPhone />}
                            to={URL_MAP.contacts}
                            label={t('header.nav.about.links.contacts')}
                        />
                        <NavContentLink
                            icon={<IconMap />}
                            to={URL_MAP.regions}
                            label={t('header.nav.about.links.regions')}
                        />

                        <NavContentLink
                            icon={<IconDocument />}
                            to={URL_MAP.docs.index}
                            label={t('header.nav.about.links.docs')}
                        />
                        <NavContentLink
                            icon={<IconStatistics />}
                            to={URL_MAP.statistics}
                            label={t('header.nav.about.links.statistics')}
                        />
                        <NavContentLink
                            icon={<IconInformationButton />}
                            to={URL_MAP.help.index}
                            label={t('header.nav.help.links.articles')}
                        />
                        <NavContentLink
                            icon={<IconQuestionButton />}
                            to={URL_MAP.faq}
                            label={t('header.nav.help.links.faq')}
                        />
                        <NavContentLink icon={<IconPin />} to={URL_MAP.explore} label={t('header.nav.checkAddress')} />
                    </nav>

                    <nav className={cx('Subnav')}>
                        <ButtonLink
                            fontWeight="medium"
                            fontSize="normal"
                            color="black"
                            tag="navlink"
                            to={URL_MAP.help.contestParticipance}
                        >
                            <span>{t('header.nav.help.links.contestParticipance')}</span>
                        </ButtonLink>
                        <ButtonLink
                            fontWeight="medium"
                            fontSize="normal"
                            color="black"
                            tag="navlink"
                            to={URL_MAP.help.contestResultValidation}
                        >
                            <span>{t('header.nav.help.links.contestResultValidation')}</span>
                        </ButtonLink>
                        <ButtonLink
                            fontWeight="medium"
                            fontSize="normal"
                            color="black"
                            tag="navlink"
                            to={URL_MAP.help.tokenizationProcess}
                        >
                            <span>{t('header.nav.help.links.tokenizationProcess')}</span>
                        </ButtonLink>
                    </nav>
                </main>
            </Sidebar>
        </>
    );
};
export default Sidemenu;
