import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

const NavContentLink = ({
    icon,
    label,
    exact = false,
    to
}: {
    icon: ReactNode;
    label: ReactNode;
    exact?: boolean;
    to: string;
}) => {
    return (
        <NavLink exact={exact} activeClassName={cx('active')} className={cx('Component')} to={to}>
            <i>{icon}</i>
            <span>{label}</span>
        </NavLink>
    );
};
export default NavContentLink;
