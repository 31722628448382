import { ApartmentNode } from 'apollo/generted';
import classNames from 'classnames/bind';
import { IconIncreaseSize, IconRooms, IconStairs } from 'components/Icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

export type Props = Pick<ApartmentNode, 'isStudio' | 'rooms' | 'm2' | 'floor'> & { className?: string };

const BaseStats = ({ isStudio, rooms, m2, floor, className = '' }: Props) => {
    const [t] = useTranslation();

    return (
        <ul className={cx('Component', className)}>
            {(isStudio || rooms) && (
                <li>
                    <IconRooms />
                    <span>{isStudio ? t('apartmentStats.rooms.isStudio') : rooms}</span>
                </li>
            )}

            <li>
                <IconIncreaseSize />

                <span>
                    {m2} {t('apartmentStats.area.unit')}
                </span>
            </li>

            {floor && (
                <li>
                    <IconStairs /> <span>{floor}</span>
                </li>
            )}
        </ul>
    );
};

export default BaseStats;
