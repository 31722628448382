import { ApartmentNode, Maybe } from 'apollo/generted';
import classNames from 'classnames/bind';
import Address, { Props as AddressProps } from 'components/Apartment/Address';
import BaseStats, { Props as BaseStatsProps } from 'components/ApartmentCard/BaseStats';
import Alert from 'components/UIKit/Alert';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

export type Props = {
    // link url
    to?: string;

    params: ({ label: ReactNode; value: ReactNode } | null)[];
    actions: ReactNode;
    status?: ReactNode;
    alert?: ReactNode;

    data?: Maybe<AddressProps & BaseStatsProps & Pick<ApartmentNode, 'id' | 'photos'>>;
};

const Card = ({ status, actions, params, to, data, alert }: Props) => {
    const coverPhoto = data?.photos?.[0]?.link ?? '';

    return (
        <div className={cx('Component')}>
            {to && <Link className={cx('BaseLink')} to={to} />}

            <div className={cx('Photo')}>
                <img src={coverPhoto} alt="" key={coverPhoto} onError={(e) => (e.currentTarget.style.opacity = '0')} />
                {data && <BaseStats {...data} />}
            </div>

            <div className={cx('Main')}>
                <div>
                    {status}
                    <Address {...data} className={cx('Address')} />
                </div>

                <ul className={cx('ParamList')}>
                    {params.map((param, i) => {
                        if (!param) return null;

                        const { value, label } = param;
                        return (
                            <li key={i}>
                                {label}
                                <span>{value}</span>
                            </li>
                        );
                    })}
                </ul>

                {alert && <Alert>{alert}</Alert>}

                {actions && <div className={cx('Actions')}>{actions}</div>}
            </div>
        </div>
    );
};

export default Card;
