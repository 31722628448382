import { useMeQuery } from 'apollo/generted';
import classNames from 'classnames/bind';
import {
    IconAccount,
    IconHeartActive,
    IconHome,
    IconKey,
    IconLogout,
    IconRing,
    IconSecurityOn
} from 'components/Icons';
import NavBar, { NavBarLink } from 'components/UIKit/NavBar';
import { BREAKPOINT_TABLET, URL_MAP } from 'constant';
import { useWidthCondition } from 'helpers/hooks';
import { signOut } from 'helpers/sign';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import styles from './index.module.scss';
import DashboardMyApartments from './MyApartments/MyApartments';
import DashboardMyFavorites from './MyFavorites';
import DashboardMyRentals from './MyRentals';
import DashboardBuyout from './Buyout';
import NotificationSettings from './NotificationSettings';
import ProfileSettings from './Profile';
import SecureSettings from './Secure';

const cx = classNames.bind(styles);

export const NAV = [
    {
        exact: true,
        url: URL_MAP.profile.profileSettings,
        icon: IconAccount,
        label: 'pageProfile.sidebar.nav.profile'
    },
    {
        url: URL_MAP.profile.safety,
        icon: IconSecurityOn,
        label: 'pageProfile.sidebar.nav.safety'
    },
    {
        url: URL_MAP.profile.notificationSettings,
        icon: IconRing,
        label: 'pageProfile.sidebar.nav.notificationSettings'
    },
    {
        url: URL_MAP.profile.myFavorites,
        icon: IconHeartActive,
        label: 'pageProfile.sidebar.nav.myFavorites'
    },
    {
        url: URL_MAP.profile.myApartments,
        icon: IconHome,
        label: 'pageProfile.sidebar.nav.myApartments'
    },
    {
        url: URL_MAP.profile.myRentals,
        icon: IconKey,
        label: 'pageProfile.sidebar.nav.myRentals'
    },
    {
        url: URL_MAP.profile.buyout,
        icon: IconHome,
        label: 'pageProfile.sidebar.nav.buyout'
    }
];

const Sidebar = () => {
    const [t] = useTranslation();
    const { data } = useMeQuery();
    const isTabletOrLess = useWidthCondition((w) => w < BREAKPOINT_TABLET);
    const me = data?.me;
    const myEosAccount = me?.eosAccount;
    const login = me ? myEosAccount || me.phone || me.email : '';

    return (
        <aside className={cx('Sidebar')}>
            <div className={cx('SidebarAccount')}>
                <div className={cx('SidebarAccountMain')}>
                    <i className={cx('SidebarAccountIcon')}>
                        <IconAccount />
                    </i>

                    <span className={cx('SidebarAccountName')}>
                        {t('pageProfile.account')}
                        <b>{login}</b>
                    </span>
                </div>

                <button className={cx('BtnLogout')} onClick={() => signOut()}>
                    <IconLogout />
                </button>
            </div>

            {isTabletOrLess ? (
                <nav className={cx('SidebarMobileNav')}>
                    {NAV.map(({ label, icon: Icon, url }) => {
                        return (
                            <Link key={url} to={url}>
                                <i>
                                    <Icon />
                                </i>
                                <span>{t(label)}</span>
                            </Link>
                        );
                    })}
                </nav>
            ) : (
                <NavBar className={cx('SidebarNav')}>
                    {NAV.map(({ label, icon: Icon, url }) => (
                        <NavBarLink tag="navlink" key={url} icon={<Icon />} to={url} label={t(label)} />
                    ))}
                </NavBar>
            )}
        </aside>
    );
};

const ProfileIndex = () => {
    const [t] = useTranslation();
    const isTabletOrLess = useWidthCondition((w) => w < BREAKPOINT_TABLET);

    if (!isTabletOrLess) return <Redirect to={URL_MAP.profile.profileSettings} />;

    return (
        <main className={cx('IndexPage')}>
            <h1 className={cx('Title')}>{t('pageProfile.title')}</h1>
            <Sidebar />
        </main>
    );
};

const ProfilePages = () => {
    const isTabletOrLess = useWidthCondition((w) => w < BREAKPOINT_TABLET);
    return (
        <main className={cx('Component')}>
            <div className={cx('Container', 'container')}>
                {!isTabletOrLess && <Sidebar />}
                <div className={cx('Content')}>
                    <Switch>
                        <Route exact path={URL_MAP.profile.index} component={ProfileIndex} />
                        <Route path={URL_MAP.profile.profileSettings} component={ProfileSettings} />
                        <Route path={URL_MAP.profile.safety} component={SecureSettings} />
                        <Route path={URL_MAP.profile.notificationSettings} component={NotificationSettings} />
                        <Route path={URL_MAP.profile.myApartments} component={DashboardMyApartments} />
                        <Route path={URL_MAP.profile.myFavorites} component={DashboardMyFavorites} />
                        <Route path={URL_MAP.profile.myRentals} component={DashboardMyRentals} />
                        <Route path={URL_MAP.profile.buyout} component={DashboardBuyout} />
                    </Switch>
                </div>
            </div>
        </main>
    );
};

const PageProfile = memo(() => {
    return (
        <Switch>
            <Route exact path={URL_MAP.profile.index} component={ProfileIndex} />
            <Route path="*" component={ProfilePages} />
        </Switch>
    );
});

export default PageProfile;
