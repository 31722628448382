import { WalletName } from 'apollo/generted';
import useMeQuery from 'helpers/useMeQuery';
import classNames from 'classnames/bind';
import Modal, { ModalBody, ModalHeader, ModalProps, useModalContext } from 'components/UIKit/Modal';
import ModalErrorScreen from 'components/UIKit/Modal/ErrorScreen';
import { isMobilePlatform } from 'helpers';
import React, { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import WalletAuthScreen from 'containers/Forms/WalletAuthScreen';
import DesktopWalletAuthForm from 'containers/Forms/WalletAuthScreen/Desktop';
import MobileWalletAuthForm from 'containers/Forms/WalletAuthScreen/Mobile';
import styles from './index.module.scss';
import { useToast } from 'helpers/hooks';
import { EOS_WALLET_LIST } from 'constant';

const cx = classNames.bind(styles);

const ModalChangeEosAccount = (props: ModalProps) => {
    return (
        <Modal {...props}>
            <Main />
        </Modal>
    );
};

const Main = () => {
    const hasEosAccount = !!useMeQuery().data!.me?.eosAccount;
    const [t] = useTranslation();
    const { close } = useModalContext();
    const [formError, setFormError] = useState('');
    const isMobile = useMemo(isMobilePlatform, []);
    const toast = useToast();
    const [showWalletQR, setShowWalletQR] = useState<null | WalletName>(null);

    const onSuccess = () => {
        toast.success(
            t(`modalChangeEosAccount.successMsg`, {
                context: hasEosAccount ? 'edit' : 'add'
            })
        );
        close();
    };

    if (showWalletQR)
        return (
            <Fragment>
                <ModalHeader onClickBack={() => setShowWalletQR(null)}>
                    {EOS_WALLET_LIST.find(({ type }) => type === showWalletQR)?.label} QR
                </ModalHeader>

                <ModalBody>
                    <WalletAuthScreen
                        description={t('modalChangeEosAccount.description', {
                            context: hasEosAccount ? 'edit' : 'add'
                        })}
                        wallet={WalletName.Anchor}
                        onError={setFormError}
                        onAuth={onSuccess}
                    />
                </ModalBody>
                <ModalErrorScreen text={formError} resetError={() => setFormError('')} />
            </Fragment>
        );

    return (
        <Fragment>
            <ModalHeader>{t('modalChangeEosAccount.title')}</ModalHeader>

            {isMobile ? (
                <ModalBody>
                    <p className={cx('Description')}>{t('modalChangeEosAccount.description', { context: 'phone' })}</p>

                    <MobileWalletAuthForm onAuth={onSuccess} onError={setFormError} onClickShowQR={setShowWalletQR} />
                </ModalBody>
            ) : (
                <DesktopWalletAuthForm
                    description={t('modalChangeEosAccount.description', {
                        context: hasEosAccount ? 'edit' : 'add'
                    })}
                    onAuth={onSuccess}
                    onError={setFormError}
                />
            )}
            <ModalErrorScreen text={formError} resetError={() => setFormError('')} />
        </Fragment>
    );
};

export default ModalChangeEosAccount;
