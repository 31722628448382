import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LOCAL_STORAGE_LNG_KEY } from '../constant';
import resourcesToBackend from 'i18next-resources-to-backend';

/** Language ids */
export const LANGUAGES = ['ru', 'en'];
/** Union of CLIENT languages */
export type Languages = typeof LANGUAGES[number];

export const DEFAULT_CLIENT_LNG: Languages = 'en';
/** Try to get the most appropriative language  */
export const getDefaultLng = () => {
    // TODO: Remove any
    let language = [window.localStorage.getItem(LOCAL_STORAGE_LNG_KEY), navigator.language, navigator.userLanguage]
        .filter(Boolean)[0]
        ?.slice(0, 2)
        .toLowerCase();

    if (!LANGUAGES.includes(language as any)) {
        language = DEFAULT_CLIENT_LNG;
    }

    return language;
};

const initI18next = () => {
    return i18next
        .use(initReactI18next)
        .use(
            resourcesToBackend((language, namespace, callback) => {
                import(`./${language}/index.ts`)
                    .then(({ default: resources }) => {
                        callback(null, resources);
                    })
                    .catch((error) => {
                        callback(error, null);
                    });
            })
        )
        .init({
            lng: getDefaultLng(),
            interpolation: {
                escapeValue: false
            }
        })
        .then(() => {
            // https://github.com/locize/translation-check
            if (process.env.NODE_ENV === 'development') {
                const button = document.createElement('button');
                document.body.append(button);
                button.textContent = 'Check translations';
                button.style.position = 'fixed';
                button.style.bottom = '0px';
                button.style.right = '0px';
                button.style.background = 'white';
                button.style.border = '1px solid black';
                button.onclick = () => {
                    require('translation-check').showTranslations(i18next);
                };
            }
        });
};

export default initI18next;
