import classNames from 'classnames/bind';
import { memo, useMemo } from 'react';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

type Props = {
    size: number;
    strokeWidth: number;
    /**
     * value in [0,1] range
     */
    progress: number;
    className?: string;
};

const ProgressBar = memo<Props>(({ size, strokeWidth, progress, className }) => {
    const { center, radius, circumference } = useMemo(() => {
        const center = size / 2;
        const radius = center - strokeWidth / 2;
        return {
            center,
            radius,
            circumference: 2 * Math.PI * radius
        };
    }, [size, strokeWidth]);

    const offset = useMemo(() => circumference - Math.min(progress, 1) * circumference, [progress, circumference]);

    return (
        <svg className={cx('Component', className)} width={size} height={size}>
            <circle
                cx={center}
                cy={center}
                r={radius}
                strokeWidth={strokeWidth}
                strokeDasharray={circumference}
                strokeDashoffset={offset || 0}
            />
        </svg>
    );
});

export default ProgressBar;
