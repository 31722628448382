import React, { memo, ReactNode, useMemo, useRef } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { IconCheck } from 'components/Icons';
const cx = classNames.bind(styles);

export type Props = {
    label?: ReactNode;
    error?: ReactNode;
    className?: string;
    labelClassName?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const Checkbox = (props: React.InputHTMLAttributes<HTMLInputElement>) => {
    return (
        <span
            className={cx('InputWrapper', {
                checked: props.checked,
                disabled: props.disabled
            })}
        >
            <input {...props} className={cx('Input')} type="checkbox" />
            <span className={cx('InputCheckmark')}>
                <IconCheck />
            </span>
        </span>
    );
};

const CheckboxField = ({ label, error, className = '', labelClassName = '', ...props }: Props) => {
    const labelRef = useRef<null | HTMLLabelElement>(null);
    const labelId = useMemo(() => `lbl-${Math.random()}`, []);

    return (
        <div className={cx('Component', className)}>
            <label
                ref={labelRef}
                id={labelId}
                className={cx('Field', {
                    error,
                    checked: props.checked,
                    disabled: props.disabled
                })}
            >
                <Checkbox {...props} />

                {label && <span className={cx('Label', labelClassName)}>{label}</span>}
            </label>
            {error && <p className={cx('FieldError')}>{error}</p>}
        </div>
    );
};
export default memo(CheckboxField);
