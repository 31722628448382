import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /**
     * The `DateTime` scalar type represents a DateTime
     * value as specified by
     * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
     */
    DateTime: any;
    /** The `Decimal` scalar type represents a python Decimal. */
    Decimal: any;
    /**
     * Allows use of a JSON String for input / output from the GraphQL schema.
     *
     * Use of this type is *not recommended* as you lose the benefits of having a defined, static
     * schema (one of the key benefits of GraphQL).
     */
    JSONString: any;
};

export type ActionNode = {
    __typename: 'ActionNode';
    id: Scalars['Int'];
    created?: Maybe<Scalars['DateTime']>;
    isIrreversible?: Maybe<Scalars['Boolean']>;
    isReversed?: Maybe<Scalars['Boolean']>;
};

export type ActionNotAllowedForAptStatusError = {
    __typename: 'ActionNotAllowedForAptStatusError';
    errorMessage: Scalars['String'];
    allowedStatuses?: Maybe<Array<Maybe<AptStatus>>>;
};

export type AddApartmentConfig = {
    __typename: 'AddApartmentConfig';
    minPhotosQuantity?: Maybe<Scalars['Int']>;
    /** List with 2 elements */
    minPhotoResolution?: Maybe<Array<Maybe<Scalars['Int']>>>;
    /** In Mb */
    maxUploadFileSize?: Maybe<Scalars['Decimal']>;
};

export type AddExpenseInput = {
    cityId: Scalars['Int'];
    locales: Array<Maybe<LocalNameInput>>;
};

export type AddExpenseOutput = AddExpenseSuccess | HasNoPermissionError | CityNotFoundError;

export type AddExpenseSuccess = {
    __typename: 'AddExpenseSuccess';
    expense: ExpenseNode;
};

export type AddPushNotification = {
    __typename: 'AddPushNotification';
    ok?: Maybe<Scalars['Boolean']>;
};

export type AddRegionDocumentInput = {
    cityId: Scalars['Int'];
    locales: Array<Maybe<LocalNameInput>>;
};

export type AddRegionDocumentOutput = AddRegionDocumentSuccess | HasNoPermissionError | CityNotFoundError;

export type AddRegionDocumentSuccess = {
    __typename: 'AddRegionDocumentSuccess';
    regionDocument: RegionDocumentNode;
};

export type AddRentalInput = {
    aptId: Scalars['Int'];
    price?: Maybe<Scalars['String']>;
    startDate?: Maybe<Scalars['DateTime']>;
};

export type AddRentalOutput =
    | AddRentalSuccess
    | AptNotFoundError
    | HasNoPermissionError
    | ActionNotAllowedForAptStatusError
    | RentalCanNotBeExtendedError;

export type AddRentalSuccess = {
    __typename: 'AddRentalSuccess';
    apt: ApartmentNode;
};

export type AddressAlreadyInAreaError = {
    __typename: 'AddressAlreadyInAreaError';
    errorMessage: Scalars['String'];
};

export type AddressIsBlockedError = {
    __typename: 'AddressIsBlockedError';
    errorMessage: Scalars['String'];
    lockInfo?: Maybe<LockNode>;
};

export type AddressIsNotFullError = {
    __typename: 'AddressIsNotFullError';
    errorMessage: Scalars['String'];
};

export type ApartmentAlreadyExistsError = {
    __typename: 'ApartmentAlreadyExistsError';
    errorMessage: Scalars['String'];
};

export type ApartmentFilterInput = {
    houseTypes?: Maybe<Array<Maybe<HouseType>>>;
    buildingYear?: Maybe<RangeInt>;
    hasGas?: Maybe<Scalars['Boolean']>;
    parking?: Maybe<ParkingInput>;
    subways?: Maybe<Array<Maybe<SubwaysInput>>>;
    placeId?: Maybe<Scalars['Int']>;
    floorsInEntrance?: Maybe<RangeInt>;
    elevator?: Maybe<ElevatorInput>;
    hasSecurity?: Maybe<Scalars['Boolean']>;
    hasConcierge?: Maybe<Scalars['Boolean']>;
    hasIntercom?: Maybe<Scalars['Boolean']>;
    hasVideoSurveillance?: Maybe<Scalars['Boolean']>;
    hasGarbageChute?: Maybe<Scalars['Boolean']>;
    /** Limited to public apartments only */
    statuses?: Maybe<Array<Maybe<AptStatus>>>;
    publicOnly?: Maybe<Scalars['Boolean']>;
    isTokenized?: Maybe<Scalars['Boolean']>;
    floor?: Maybe<FloorInput>;
    square?: Maybe<SquareInput>;
    rooms?: Maybe<RoomsInput>;
    ceilingHeight?: Maybe<RangeInt>;
    windows?: Maybe<WindowsInput>;
    baths?: Maybe<BathsInput>;
    balconies?: Maybe<BalconiesInput>;
    districts?: Maybe<Array<Maybe<Scalars['Int']>>>;
    cityId?: Maybe<Scalars['Int']>;
    citiesIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
    address?: Maybe<Scalars['String']>;
    housingComplex?: Maybe<Scalars['String']>;
};

export type ApartmentListNode = {
    __typename: 'ApartmentListNode';
    pageInfo: PageInfo;
    apartments: Array<Maybe<ApartmentNode>>;
};

export type ApartmentNode = {
    __typename: 'ApartmentNode';
    id: Scalars['Int'];
    realId?: Maybe<Scalars['String']>;
    m2?: Maybe<Scalars['Float']>;
    placeId?: Maybe<Scalars['Int']>;
    place?: Maybe<PlaceNode>;
    cityId: Scalars['Int'];
    city: CityNode;
    number?: Maybe<Scalars['Int']>;
    refundAccount?: Maybe<Scalars['String']>;
    emissionVolume?: Maybe<VolumeStruct>;
    communalPayment?: Maybe<Scalars['String']>;
    totalExpenses?: Maybe<Scalars['String']>;
    communalCurrency?: Maybe<Scalars['String']>;
    photos?: Maybe<Array<Maybe<DocumentNode>>>;
    documents?: Maybe<Array<Maybe<DocumentNode>>>;
    isPublic: Scalars['Boolean'];
    description?: Maybe<Scalars['String']>;
    status?: Maybe<AptStatus>;
    aptType?: Maybe<AptType>;
    floor?: Maybe<Scalars['Int']>;
    rooms?: Maybe<Scalars['Int']>;
    isStudio?: Maybe<Scalars['Boolean']>;
    livingM2?: Maybe<Scalars['Float']>;
    kitchenM2?: Maybe<Scalars['Float']>;
    ceilingHeight?: Maybe<Scalars['Float']>;
    hasYardWindows?: Maybe<Scalars['Boolean']>;
    hasStreetWindows?: Maybe<Scalars['Boolean']>;
    combinedBaths?: Maybe<Scalars['Boolean']>;
    dividedBaths?: Maybe<Scalars['Boolean']>;
    tenure?: Maybe<AptTenure>;
    balconies?: Maybe<AptBalconies>;
    hasLoggia?: Maybe<Scalars['Boolean']>;
    numberOfFloors?: Maybe<Scalars['Int']>;
    hasElevator?: Maybe<Scalars['Boolean']>;
    hasCargoElevator?: Maybe<Scalars['Boolean']>;
    hasGarbageChute?: Maybe<Scalars['Boolean']>;
    hasSecurity?: Maybe<Scalars['Boolean']>;
    hasConcierge?: Maybe<Scalars['Boolean']>;
    hasIntercom?: Maybe<Scalars['Boolean']>;
    hasVideoSurveillance?: Maybe<Scalars['Boolean']>;
    entranceNumber?: Maybe<Scalars['Int']>;
    houseType?: Maybe<HouseType>;
    buildingYear?: Maybe<Scalars['Int']>;
    hasGas?: Maybe<Scalars['Boolean']>;
    hasGroundParking?: Maybe<Scalars['Boolean']>;
    hasUndergroundParking?: Maybe<Scalars['Boolean']>;
    hasMultilevelParking?: Maybe<Scalars['Boolean']>;
    housingComplex?: Maybe<Scalars['String']>;
    subways?: Maybe<Array<Maybe<RoadToSubwayNode>>>;
    ownerAccount?: Maybe<Scalars['String']>;
    user?: Maybe<UserNode>;
    isIAmOwner: Scalars['Boolean'];
    isFavorite?: Maybe<Scalars['Boolean']>;
    review?: Maybe<AptReviewNode>;
    auctionsHistory?: Maybe<Array<Maybe<AuctionNode>>>;
    latestAuction?: Maybe<AuctionNode>;
    isTokenized?: Maybe<Scalars['Boolean']>;
    tokenizationInfo?: Maybe<TokenizationStruct>;
    rentalsHistory?: Maybe<Array<Maybe<RentalNode>>>;
    actualRental?: Maybe<RentalNode>;
    lock?: Maybe<LockNode>;
    isNewForRd?: Maybe<Scalars['Boolean']>;
    updatedForRd?: Maybe<Scalars['DateTime']>;
    isDeleted?: Maybe<Scalars['Boolean']>;
    isIAmNotary: Scalars['Boolean'];
    chatWithOwnerLink?: Maybe<Scalars['String']>;
    buyRequestId?: Maybe<Scalars['Int']>;
    buyRequest?: Maybe<BuyAptRequestNode>;
    supportedTokens?: Maybe<Array<Maybe<Scalars['String']>>>;
    defaultToken?: Maybe<Scalars['String']>;
};

export type ApartmentNodePhotosArgs = {
    size?: Maybe<ThumbnailSize>;
};

export enum ApartmentSortEnum {
    Address = 'ADDRESS',
    AddressDesc = 'ADDRESS_DESC',
    Status = 'STATUS',
    StatusDesc = 'STATUS_DESC',
    M2 = 'M2',
    M2Desc = 'M2_DESC',
    IsNewForRd = 'IS_NEW_FOR_RD',
    IsNewForRdDesc = 'IS_NEW_FOR_RD_DESC',
    UpdatedForRd = 'UPDATED_FOR_RD',
    UpdatedForRdDesc = 'UPDATED_FOR_RD_DESC'
}

export enum ApplicationSortEnum {
    UpdatedForRd = 'UPDATED_FOR_RD',
    UpdatedForRdDesc = 'UPDATED_FOR_RD_DESC'
}

/** An enumeration. */
export enum AptBalconies {
    One = 'ONE',
    Two = 'TWO',
    ThreeOrMore = 'THREE_OR_MORE',
    Zero = 'ZERO'
}

export type AptDetails = {
    housingComplex?: Maybe<Scalars['String']>;
    entranceNumber?: Maybe<Scalars['Int']>;
    subways?: Maybe<Array<Maybe<SubwaysInput>>>;
    houseType?: Maybe<HouseType>;
    buildingYear?: Maybe<Scalars['Int']>;
    numberOfFloors?: Maybe<Scalars['Int']>;
    hasGroundParking?: Maybe<Scalars['Boolean']>;
    hasUndergroundParking?: Maybe<Scalars['Boolean']>;
    hasMultilevelParking?: Maybe<Scalars['Boolean']>;
    hasElevator?: Maybe<Scalars['Boolean']>;
    hasCargoElevator?: Maybe<Scalars['Boolean']>;
    hasGas?: Maybe<Scalars['Boolean']>;
    hasGarbageChute?: Maybe<Scalars['Boolean']>;
    hasSecurity?: Maybe<Scalars['Boolean']>;
    hasConcierge?: Maybe<Scalars['Boolean']>;
    hasIntercom?: Maybe<Scalars['Boolean']>;
    hasVideoSurveillance?: Maybe<Scalars['Boolean']>;
    aptType?: Maybe<AptType>;
    floor?: Maybe<Scalars['Int']>;
    rooms?: Maybe<Scalars['Int']>;
    isStudio?: Maybe<Scalars['Boolean']>;
    livingM2?: Maybe<Scalars['Float']>;
    kitchenM2?: Maybe<Scalars['Float']>;
    ceilingHeight?: Maybe<Scalars['Float']>;
    hasYardWindows?: Maybe<Scalars['Boolean']>;
    hasStreetWindows?: Maybe<Scalars['Boolean']>;
    combinedBaths?: Maybe<Scalars['Boolean']>;
    dividedBaths?: Maybe<Scalars['Boolean']>;
    tenure?: Maybe<AptTenure>;
    balconies?: Maybe<AptBalconies>;
    hasLoggia?: Maybe<Scalars['Boolean']>;
    description?: Maybe<Scalars['String']>;
};

export type AptExpenseNode = {
    __typename: 'AptExpenseNode';
    expense: ExpenseNode;
    value: Scalars['String'];
};

export type AptExpensesInput = {
    expenseId: Scalars['Int'];
    value: Scalars['String'];
};

export type AptMainInfo = {
    realId?: Maybe<Scalars['String']>;
    /** Our id of place(not from googlemap */
    placeId: Scalars['Int'];
    aptNumber: Scalars['Int'];
    m2?: Maybe<Scalars['Float']>;
    refundAccount?: Maybe<Scalars['String']>;
    communalPayment?: Maybe<Scalars['String']>;
};

export type AptNotFoundError = {
    __typename: 'AptNotFoundError';
    errorMessage: Scalars['String'];
};

export type AptNotReviewedError = {
    __typename: 'AptNotReviewedError';
    errorMessage: Scalars['String'];
};

export type AptNotTokenizedError = {
    __typename: 'AptNotTokenizedError';
    errorMessage: Scalars['String'];
};

export type AptRdSubscriptionEvent = {
    __typename: 'AptRDSubscriptionEvent';
    oldStatus: AptStatus;
    apt: ApartmentNode;
    event: Event;
};

export type AptReviewInput = {
    comment?: Maybe<Scalars['String']>;
    fields?: Maybe<FieldErrorsInput>;
};

export type AptReviewNode = {
    __typename: 'AptReviewNode';
    id: Scalars['Int'];
    comment?: Maybe<Scalars['String']>;
    errorsCount?: Maybe<Scalars['Int']>;
    fields?: Maybe<FieldErrorsNode>;
};

/** An enumeration. */
export enum AptStatus {
    /** Not published */
    Draft = 'DRAFT',
    /** Application is in moderation */
    Moderation = 'MODERATION',
    /** The application is being finalized */
    Rework = 'REWORK',
    /** Active stage of contest, accepting deposits */
    Contest = 'CONTEST',
    /** Contest completed. Winner election, paper work */
    ContestFinished = 'CONTEST_FINISHED',
    /** Apartment is rented */
    Rented = 'RENTED',
    /** Rent is end. Apartment is waiting for new contest */
    WaitingContest = 'WAITING_CONTEST',
    /** Sold from platform */
    Sold = 'SOLD',
    /** Apartment is locked */
    Locked = 'LOCKED',
    /** Apartment is rented, but eviction started */
    Eviction = 'EVICTION'
}

export type AptSubscriptionEvent = {
    __typename: 'AptSubscriptionEvent';
    apt: ApartmentNode;
    event: Event;
};

/** An enumeration. */
export enum AptTenure {
    UpToThree = 'UP_TO_THREE',
    ThreeToFive = 'THREE_TO_FIVE',
    MoreThanFive = 'MORE_THAN_FIVE'
}

/** An enumeration. */
export enum AptType {
    Secondary = 'SECONDARY',
    New = 'NEW'
}

export type AuctionNode = {
    __typename: 'AuctionNode';
    id: Scalars['Int'];
    aptId?: Maybe<Scalars['String']>;
    apt?: Maybe<ApartmentNode>;
    price?: Maybe<Scalars['String']>;
    tokenId?: Maybe<Scalars['String']>;
    token?: Maybe<TokenNode>;
    startDate?: Maybe<Scalars['DateTime']>;
    endDate?: Maybe<Scalars['DateTime']>;
    endOfActivePhase?: Maybe<Scalars['DateTime']>;
    transactions?: Maybe<Array<Maybe<TransactionNode>>>;
    hasMyBid?: Maybe<Scalars['Boolean']>;
    myBid?: Maybe<TransactionNode>;
    hasAccountBid?: Maybe<Scalars['Boolean']>;
    accountBid?: Maybe<Scalars['Boolean']>;
    paymentInfo?: Maybe<AuctionPaymentsStruct>;
    paymentDeepLink?: Maybe<Scalars['String']>;
    paymentJson?: Maybe<Scalars['String']>;
    isWinnerSelectionInProgress?: Maybe<Scalars['Boolean']>;
    winner?: Maybe<UserNode>;
    isIAmWinner: Scalars['Boolean'];
    winnerAccount?: Maybe<Scalars['String']>;
    winnerRank?: Maybe<Scalars['Int']>;
    isWinnerAgree?: Maybe<Scalars['Boolean']>;
    winnerChatLink?: Maybe<Scalars['String']>;
    deadlineForWinner?: Maybe<Scalars['DateTime']>;
    deadlineToPayCommunal?: Maybe<Scalars['DateTime']>;
    notaryAccount?: Maybe<Scalars['String']>;
    notary?: Maybe<UserNode>;
    ownerAccount?: Maybe<Scalars['String']>;
    owner?: Maybe<UserNode>;
    isFinished?: Maybe<Scalars['Boolean']>;
    cancelBidParams?: Maybe<CancelBidStruct>;
    bidsNumber?: Maybe<BidsNumber>;
    updatedForRd?: Maybe<Scalars['DateTime']>;
};

export type AuctionNodeHasAccountBidArgs = {
    account: Scalars['String'];
};

export type AuctionNodeAccountBidArgs = {
    account: Scalars['String'];
};

export type AuctionPaymentsStruct = {
    __typename: 'AuctionPaymentsStruct';
    /** Amount to send */
    payment: Scalars['String'];
    /** Amount that wiil be received */
    paymentAfterTax?: Maybe<Scalars['String']>;
    paymentFee?: Maybe<Scalars['String']>;
    memo?: Maybe<Scalars['String']>;
    token: Scalars['String'];
    smartContract: Scalars['String'];
    platformAccount: Scalars['String'];
};

export enum AuctionSortEnum {
    Address = 'ADDRESS',
    AddressDesc = 'ADDRESS_DESC',
    Status = 'STATUS',
    StatusDesc = 'STATUS_DESC',
    StartOfAuction = 'START_OF_AUCTION',
    StartOfAuctionDesc = 'START_OF_AUCTION_DESC',
    EndOfAuction = 'END_OF_AUCTION',
    EndOfAuctionDesc = 'END_OF_AUCTION_DESC',
    UpdatedForRd = 'UPDATED_FOR_RD',
    UpdatedForRdDesc = 'UPDATED_FOR_RD_DESC'
}

/** An enumeration. */
export enum AuthStep {
    Finish = 'FINISH',
    CodeVerification = 'CODE_VERIFICATION'
}

export type BalconiesInput = {
    count?: Maybe<Array<Maybe<AptBalconies>>>;
    hasLoggia?: Maybe<Scalars['Boolean']>;
};

export type BathsInput = {
    divided?: Maybe<Scalars['Boolean']>;
    combined?: Maybe<Scalars['Boolean']>;
};

export type BidsNumber = {
    __typename: 'BidsNumber';
    active: Scalars['Int'];
    cancelled: Scalars['Int'];
};

export type BindUserWithCity = {
    __typename: 'BindUserWithCity';
    me?: Maybe<UserNode>;
};

export type BlockAptInput = {
    aptId: Scalars['Int'];
    reason: Scalars['String'];
    /** Lock perion in seconds. `Null` for lock forever */
    period?: Maybe<Scalars['Int']>;
};

export type BlockAptOutput =
    | BlockAptSuccess
    | HasNoPermissionError
    | AptNotFoundError
    | ActionNotAllowedForAptStatusError
    | AddressIsBlockedError
    | InvalidBlockPeriodError;

export type BlockAptSuccess = {
    __typename: 'BlockAptSuccess';
    apt: ApartmentNode;
};

export type BurnListsOutput =
    | BurnListsSuccess
    | HasNoPermissionError
    | BuyRequestNotFoundError
    | BuyRequestCancelledError
    | BuyRequestNotApprovedError
    | BuyRequestExecutedError;

export type BurnListsSuccess = {
    __typename: 'BurnListsSuccess';
    buyRequest?: Maybe<BuyAptRequestNode>;
};

export type BuyAptPaymentStruct = {
    __typename: 'BuyAptPaymentStruct';
    /** Amount to send */
    amountToPay: Scalars['String'];
    token: Scalars['String'];
    smartContract: Scalars['String'];
    platformAccount: Scalars['String'];
    paymentParams: Scalars['String'];
    deeplink: Scalars['String'];
};

export type BuyAptRequestNode = {
    __typename: 'BuyAptRequestNode';
    id: Scalars['Int'];
    buyerAccount: Scalars['String'];
    buyer?: Maybe<UserNode>;
    transferTime: Scalars['DateTime'];
    transferAmount: Scalars['String'];
    returnedAmount?: Maybe<Scalars['String']>;
    changeAmount?: Maybe<Scalars['String']>;
    feeAmount?: Maybe<Scalars['String']>;
    tokenId?: Maybe<Scalars['String']>;
    token?: Maybe<TokenNode>;
    aptId?: Maybe<Scalars['Int']>;
    apt?: Maybe<ApartmentNode>;
    status?: Maybe<BuyStatus>;
    isApprovedByBuyer?: Maybe<Scalars['Boolean']>;
    buyerApproveDeeplink?: Maybe<Scalars['String']>;
    isApprovedByRd?: Maybe<Scalars['Boolean']>;
    rdApproveDeeplink?: Maybe<Scalars['String']>;
    endTime?: Maybe<Scalars['DateTime']>;
    isNewForRd?: Maybe<Scalars['Boolean']>;
};

export type BuyRequestCancelledError = {
    __typename: 'BuyRequestCancelledError';
    errorMessage: Scalars['String'];
};

export type BuyRequestConfig = {
    __typename: 'BuyRequestConfig';
    feePercent: Scalars['String'];
    minTransferVolume: Scalars['String'];
};

export type BuyRequestExecutedError = {
    __typename: 'BuyRequestExecutedError';
    errorMessage: Scalars['String'];
};

export type BuyRequestListNode = {
    __typename: 'BuyRequestListNode';
    pageInfo: PageInfo;
    buyRequests: Array<Maybe<BuyAptRequestNode>>;
};

export type BuyRequestNotApprovedError = {
    __typename: 'BuyRequestNotApprovedError';
    errorMessage: Scalars['String'];
    isApprovedBuyBuyer?: Maybe<Scalars['Boolean']>;
    isApprovedBuyRd?: Maybe<Scalars['Boolean']>;
};

export type BuyRequestNotFoundError = {
    __typename: 'BuyRequestNotFoundError';
    errorMessage: Scalars['String'];
};

export enum BuyRequestSortEnum {
    TransferTime = 'TRANSFER_TIME',
    TransferTimeDesc = 'TRANSFER_TIME_DESC',
    EndTime = 'END_TIME',
    EndTimeDesc = 'END_TIME_DESC'
}

export type BuyRequestSubscriptionEvent = {
    __typename: 'BuyRequestSubscriptionEvent';
    buyRequest: BuyAptRequestNode;
    event: Event;
};

/** An enumeration. */
export enum BuyStatus {
    InProgress = 'IN_PROGRESS',
    Finished = 'FINISHED',
    Cancelled = 'CANCELLED',
    Failed = 'FAILED'
}

export type CancelBidStruct = {
    __typename: 'CancelBidStruct';
    amount?: Maybe<Scalars['String']>;
    symbol?: Maybe<Scalars['String']>;
    to?: Maybe<Scalars['String']>;
    memo?: Maybe<Scalars['String']>;
    contract?: Maybe<Scalars['String']>;
    action?: Maybe<Scalars['String']>;
};

export type CancelBuyRequestInput = {
    buyRequestId: Scalars['Int'];
};

export type CancelBuyRequestOutput =
    | CancelBuyRequestSuccess
    | HasNoPermissionError
    | BuyRequestNotFoundError
    | BuyRequestCancelledError
    | BuyRequestExecutedError;

export type CancelBuyRequestSuccess = {
    __typename: 'CancelBuyRequestSuccess';
    buyRequest?: Maybe<BuyAptRequestNode>;
};

export type CancelContestInput = {
    aptId: Scalars['Int'];
};

export type CancelContestOutput =
    | CancelContestSuccess
    | AptNotFoundError
    | HasNoPermissionError
    | ActionNotAllowedForAptStatusError;

export type CancelContestSuccess = {
    __typename: 'CancelContestSuccess';
    apt: ApartmentNode;
};

export type CancelPublicationInput = {
    aptId: Scalars['Int'];
};

export type CancelPublicationOutput =
    | CancelPublicationSuccess
    | AptNotFoundError
    | HasNoPermissionError
    | ActionNotAllowedForAptStatusError;

export type CancelPublicationSuccess = {
    __typename: 'CancelPublicationSuccess';
    apt: ApartmentNode;
};

export type CantDeleteLoginError = {
    __typename: 'CantDeleteLoginError';
    errorMessage: Scalars['String'];
};

export type ChangeLoginInput = {
    newPhone?: Maybe<Scalars['String']>;
    newEmail?: Maybe<Scalars['String']>;
};

export type ChangeLoginOutput =
    | ChangeLoginSuccess
    | InvalidCredentialsError
    | InvalidPhoneError
    | InvalidEmailError
    | UserAlreadyExistsError
    | InvalidParamsError;

export type ChangeLoginSuccess = {
    __typename: 'ChangeLoginSuccess';
    verifyToken: Scalars['String'];
};

export type ChangePasswordInput = {
    oldPassword?: Maybe<Scalars['String']>;
    newPassword: Scalars['String'];
};

export type ChangePasswordOutput = ChangePasswordSuccess | InvalidPasswordError | InvalidCredentialsError;

export type ChangePasswordSuccess = {
    __typename: 'ChangePasswordSuccess';
    me: UserNode;
};

export type ChannelNode = {
    __typename: 'ChannelNode';
    isSmsEnabled?: Maybe<Scalars['Boolean']>;
    isEmailEnabled?: Maybe<Scalars['Boolean']>;
};

export type CheckPasswordInput = {
    password: Scalars['String'];
};

export type CheckPasswordOutput = CheckPasswordSuccess | InvalidCredentialsError;

export type CheckPasswordSuccess = {
    __typename: 'CheckPasswordSuccess';
    me: UserNode;
};

export type CityFilterInput = {
    hasTokenized?: Maybe<Scalars['Boolean']>;
    hasInAuction?: Maybe<Scalars['Boolean']>;
    hasPublic?: Maybe<Scalars['Boolean']>;
    countryId?: Maybe<Scalars['Int']>;
    availableOnly?: Maybe<Scalars['Boolean']>;
};

export type CityNode = {
    __typename: 'CityNode';
    id: Scalars['Int'];
    name?: Maybe<Scalars['String']>;
    population?: Maybe<Scalars['Int']>;
    centerLon?: Maybe<Scalars['String']>;
    centerLat?: Maybe<Scalars['String']>;
    m2?: Maybe<Scalars['String']>;
    radius?: Maybe<Scalars['Int']>;
    tokenId?: Maybe<Scalars['String']>;
    token?: Maybe<TokenNode>;
    countryId: Scalars['Int'];
    country?: Maybe<CountryNode>;
    subways?: Maybe<Array<Maybe<SubwayNode>>>;
    hasApartments?: Maybe<Scalars['Boolean']>;
    hasTokenizedApartments?: Maybe<Scalars['Boolean']>;
    notary?: Maybe<UserNode>;
    notaryEosAccount?: Maybe<Scalars['String']>;
    notaryContactEmail?: Maybe<Scalars['String']>;
    notaryContactPhone?: Maybe<Scalars['String']>;
    notaryChatLink?: Maybe<Scalars['String']>;
    myApartmentsCount?: Maybe<Scalars['Int']>;
    polygon?: Maybe<Scalars['JSONString']>;
    notaryUpdates?: Maybe<NotaryUpdates>;
    /** Is available for adding an apartment */
    isAvailable?: Maybe<Scalars['Boolean']>;
    countryCode: Scalars['String'];
    locales?: Maybe<Array<Maybe<Lang>>>;
    /** Whether it is old cities (Kyiv, Moscow, Chelyabynsk, St.Petersburg) or new ones */
    isNew?: Maybe<Scalars['Boolean']>;
};

export type CityNodeMyApartmentsCountArgs = {
    relationType: RelationToApt;
};

export type CityNotFoundError = {
    __typename: 'CityNotFoundError';
    errorMessage: Scalars['String'];
};

export enum CitySortEnum {
    CountryName = 'COUNTRY_NAME',
    CountryNameDesc = 'COUNTRY_NAME_DESC',
    CityName = 'CITY_NAME',
    CityNameDesc = 'CITY_NAME_DESC'
}

export type CityTokenizationShareNode = {
    __typename: 'CityTokenizationShareNode';
    city?: Maybe<CityNode>;
    share?: Maybe<Scalars['String']>;
    tokenizedM2?: Maybe<Scalars['String']>;
    statisticDetails?: Maybe<StatisticNode>;
};

export type CommonStatisticNode = {
    __typename: 'CommonStatisticNode';
    totalTokenizedM2?: Maybe<Scalars['String']>;
    statisticDetails: StatisticNode;
    citiesShares?: Maybe<Array<Maybe<CityTokenizationShareNode>>>;
};

export type ConclusionOnAddress = {
    __typename: 'ConclusionOnAddress';
    /** If this address not already in use */
    isAvailable?: Maybe<Scalars['Boolean']>;
    isLocked?: Maybe<Scalars['Boolean']>;
    lock?: Maybe<LockNode>;
};

export type ConfirmEvictionInput = {
    aptId: Scalars['Int'];
};

export type ConfirmEvictionOutput =
    | ConfirmEvictionSuccess
    | HasNoPermissionError
    | AptNotFoundError
    | ActionNotAllowedForAptStatusError
    | EvictionNotStartedError;

export type ConfirmEvictionSuccess = {
    __typename: 'ConfirmEvictionSuccess';
    apt: ApartmentNode;
};

export type ConfirmPasswordResetInput = {
    password: Scalars['String'];
};

export type ConfirmPasswordResetOutput =
    | ConfirmPasswordResetSuccess
    | InvalidJwtError
    | InvalidVerifyCodeError
    | UserNotFoundError
    | InvalidPasswordError;

export type ConfirmPasswordResetSuccess = {
    __typename: 'ConfirmPasswordResetSuccess';
    me?: Maybe<UserNode>;
    token: Scalars['String'];
};

export type ConfirmReadinessAsWinnerInput = {
    aptId: Scalars['Int'];
};

export type ConfirmReadinessAsWinnerOutput =
    | ConfirmReadinessAsWinnerSuccess
    | HasNoPermissionError
    | AptNotFoundError
    | AptNotTokenizedError
    | ActionNotAllowedForAptStatusError;

export type ConfirmReadinessAsWinnerSuccess = {
    __typename: 'ConfirmReadinessAsWinnerSuccess';
    apt: ApartmentNode;
};

export type ConfirmationFailedError = {
    __typename: 'ConfirmationFailedError';
    errorMessage: Scalars['String'];
};

export type ConnectByWalletInput = {
    walletName: WalletName;
};

export type ConnectByWalletOutput = ConnectByWalletSuccess | DeeplinkNotGeneratedError;

export type ConnectByWalletSuccess = {
    __typename: 'ConnectByWalletSuccess';
    qrData?: Maybe<Scalars['String']>;
    deepLink?: Maybe<Scalars['String']>;
    verificationCode: Scalars['String'];
};

export type ContestCheckDataNode = {
    __typename: 'ContestCheckDataNode';
    seedBlock: Scalars['String'];
    testScript: Scalars['String'];
};

export type ContestPaymentNotFoundError = {
    __typename: 'ContestPaymentNotFoundError';
    errorMessage: Scalars['String'];
};

export type CountryFilterInput = {
    hasTokenized?: Maybe<Scalars['Boolean']>;
    hasInAuction?: Maybe<Scalars['Boolean']>;
    hasPublic?: Maybe<Scalars['Boolean']>;
};

export type CountryNode = {
    __typename: 'CountryNode';
    id: Scalars['Int'];
    name?: Maybe<Scalars['String']>;
    citiesIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
    currencyCode?: Maybe<Scalars['String']>;
};

export type CountryNodeCitiesIdsArgs = {
    filters?: Maybe<CountryFilterInput>;
};

export enum CountrySortEnum {
    CountryName = 'COUNTRY_NAME',
    CountryNameDesc = 'COUNTRY_NAME_DESC'
}

export type DeeplinkNotGeneratedError = {
    __typename: 'DeeplinkNotGeneratedError';
    errorMessage: Scalars['String'];
};

export type DeleteAccountSuccess = {
    __typename: 'DeleteAccountSuccess';
    ok: Scalars['Boolean'];
};

export type DeleteApartmentInput = {
    aptId: Scalars['Int'];
};

export type DeleteApartmentOutput =
    | DeleteApartmentSuccess
    | AptNotFoundError
    | HasNoPermissionError
    | ActionNotAllowedForAptStatusError;

export type DeleteApartmentSuccess = {
    __typename: 'DeleteApartmentSuccess';
    deletedApt: ApartmentNode;
};

export type DeleteDocumentInput = {
    docId: Scalars['Int'];
};

export type DeleteDocumentOutput =
    | DeleteDocumentSuccess
    | AptNotFoundError
    | DocumentNotFoundError
    | HasNoPermissionError;

export type DeleteDocumentSuccess = {
    __typename: 'DeleteDocumentSuccess';
    deletedDocument?: Maybe<DocumentNode>;
};

export type DeleteExpenseInput = {
    expenseId: Scalars['Int'];
};

export type DeleteExpenseOutput = DeleteExpenseSuccess | HasNoPermissionError | ExpenseNotFoundError;

export type DeleteExpenseSuccess = {
    __typename: 'DeleteExpenseSuccess';
    ok: Scalars['Boolean'];
};

export type DeleteRegionDocumentInput = {
    regionDocumentId: Scalars['Int'];
};

export type DeleteRegionDocumentOutput =
    | DeleteRegionDocumentSuccess
    | HasNoPermissionError
    | RegionDocumentNotFoundError;

export type DeleteRegionDocumentSuccess = {
    __typename: 'DeleteRegionDocumentSuccess';
    ok: Scalars['Boolean'];
};

/** An enumeration. */
export enum DeviceType {
    Android = 'Android',
    Ios = 'IOS',
    Web = 'Web'
}

export type DocumentNode = {
    __typename: 'DocumentNode';
    id?: Maybe<Scalars['Int']>;
    link?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    thumbName?: Maybe<Scalars['String']>;
    originalName?: Maybe<Scalars['String']>;
    docType?: Maybe<DocumentType>;
    objectType?: Maybe<ObjType>;
};

export type DocumentNodeLinkArgs = {
    size?: Maybe<ThumbnailSize>;
};

export type DocumentNotFoundError = {
    __typename: 'DocumentNotFoundError';
    errorMessage: Scalars['String'];
    id?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum DocumentType {
    /** Photo */
    Photo = 'PHOTO',
    /** Document */
    Document = 'DOCUMENT'
}

export type EosInheritanceNode = {
    __typename: 'EOSInheritanceNode';
    /** The user, who will share his property */
    ownerUsername?: Maybe<Scalars['String']>;
    /** The user, who will inherit the owners's property */
    inheritorUsername?: Maybe<Scalars['String']>;
    shareDatetime?: Maybe<Scalars['DateTime']>;
    allowedToShare?: Maybe<Scalars['Boolean']>;
    smartContract?: Maybe<Scalars['String']>;
    /** Share in percents. i.e. 50.0 */
    share?: Maybe<Scalars['String']>;
};

export type EosNode = {
    __typename: 'EOSNode';
    /** Internal ID */
    id?: Maybe<Scalars['String']>;
    /** URL */
    endpoint?: Maybe<Scalars['String']>;
    /** Country in 3 symbols */
    country?: Maybe<Scalars['String']>;
    /** Example: +0000 or +3000 or -8000 */
    timezone?: Maybe<Scalars['String']>;
    locale?: Maybe<Scalars['String']>;
};

export type ElevatorInput = {
    simple?: Maybe<Scalars['Boolean']>;
    cargo?: Maybe<Scalars['Boolean']>;
};

export type EosAccountAlreadyTakenError = {
    __typename: 'EosAccountAlreadyTakenError';
    errorMessage: Scalars['String'];
};

export type EosAccountNotExistsError = {
    __typename: 'EosAccountNotExistsError';
    errorMessage: Scalars['String'];
    value?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum Event {
    Add = 'ADD',
    Update = 'UPDATE',
    Delete = 'DELETE'
}

export type EvictionAlreadyStartedError = {
    __typename: 'EvictionAlreadyStartedError';
    errorMessage: Scalars['String'];
};

export type EvictionNotStartedError = {
    __typename: 'EvictionNotStartedError';
    errorMessage: Scalars['String'];
};

export type ExpenseNode = {
    __typename: 'ExpenseNode';
    id: Scalars['Int'];
    name: Scalars['String'];
    locales?: Maybe<Array<Maybe<LocalNameNode>>>;
};

export type ExpenseNotFoundError = {
    __typename: 'ExpenseNotFoundError';
    errorMessage: Scalars['String'];
};

export type FieldErrorsInput = {
    realId?: Maybe<Scalars['String']>;
    address?: Maybe<Scalars['String']>;
    housingComplex?: Maybe<Scalars['String']>;
    entranceNumber?: Maybe<Scalars['String']>;
    aptNumber?: Maybe<Scalars['String']>;
    subway?: Maybe<Scalars['String']>;
    houseType?: Maybe<Scalars['String']>;
    buildingYear?: Maybe<Scalars['String']>;
    entranceFloors?: Maybe<Scalars['String']>;
    aptFloor?: Maybe<Scalars['String']>;
    parking?: Maybe<Scalars['String']>;
    elevators?: Maybe<Scalars['String']>;
    infra?: Maybe<Scalars['String']>;
    aptType?: Maybe<Scalars['String']>;
    tenure?: Maybe<Scalars['String']>;
    rooms?: Maybe<Scalars['String']>;
    m2?: Maybe<Scalars['String']>;
    livingM2?: Maybe<Scalars['String']>;
    kitchenM2?: Maybe<Scalars['String']>;
    communalPayment?: Maybe<Scalars['String']>;
    ceilingHeight?: Maybe<Scalars['String']>;
    bathroom?: Maybe<Scalars['String']>;
    windows?: Maybe<Scalars['String']>;
    balconies?: Maybe<Scalars['String']>;
    aptDescription?: Maybe<Scalars['String']>;
    photos?: Maybe<Scalars['String']>;
    documents?: Maybe<Scalars['String']>;
    bindedEosAccount?: Maybe<Scalars['String']>;
    refundAccount?: Maybe<Scalars['String']>;
};

export type FieldErrorsNode = {
    __typename: 'FieldErrorsNode';
    realId?: Maybe<FieldStatusStruct>;
    address?: Maybe<FieldStatusStruct>;
    housingComplex?: Maybe<FieldStatusStruct>;
    entranceNumber?: Maybe<FieldStatusStruct>;
    aptNumber?: Maybe<FieldStatusStruct>;
    subway?: Maybe<FieldStatusStruct>;
    houseType?: Maybe<FieldStatusStruct>;
    buildingYear?: Maybe<FieldStatusStruct>;
    entranceFloors?: Maybe<FieldStatusStruct>;
    aptFloor?: Maybe<FieldStatusStruct>;
    parking?: Maybe<FieldStatusStruct>;
    elevators?: Maybe<FieldStatusStruct>;
    infra?: Maybe<FieldStatusStruct>;
    aptType?: Maybe<FieldStatusStruct>;
    tenure?: Maybe<FieldStatusStruct>;
    rooms?: Maybe<FieldStatusStruct>;
    m2?: Maybe<FieldStatusStruct>;
    livingM2?: Maybe<FieldStatusStruct>;
    kitchenM2?: Maybe<FieldStatusStruct>;
    communalPayment?: Maybe<FieldStatusStruct>;
    ceilingHeight?: Maybe<FieldStatusStruct>;
    bathroom?: Maybe<FieldStatusStruct>;
    windows?: Maybe<FieldStatusStruct>;
    balconies?: Maybe<FieldStatusStruct>;
    aptDescription?: Maybe<FieldStatusStruct>;
    photos?: Maybe<FieldStatusStruct>;
    documents?: Maybe<FieldStatusStruct>;
    bindedEosAccount?: Maybe<FieldStatusStruct>;
    refundAccount?: Maybe<FieldStatusStruct>;
};

export type FieldStatusStruct = {
    __typename: 'FieldStatusStruct';
    isOk?: Maybe<Scalars['Boolean']>;
    errorDescription?: Maybe<Scalars['String']>;
};

export type FloorInput = {
    from?: Maybe<Scalars['Int']>;
    to?: Maybe<Scalars['Int']>;
    notFirst?: Maybe<Scalars['Boolean']>;
    notLast?: Maybe<Scalars['Boolean']>;
};

export type GMapAddressStruct = {
    __typename: 'GMapAddressStruct';
    address?: Maybe<Scalars['String']>;
    placeId?: Maybe<Scalars['String']>;
    isFull?: Maybe<Scalars['Boolean']>;
    fullAddress?: Maybe<Scalars['String']>;
};

export type HasNoPermissionError = {
    __typename: 'HasNoPermissionError';
    errorMessage: Scalars['String'];
};

export type HouseNode = {
    __typename: 'HouseNode';
    placeId?: Maybe<Scalars['Int']>;
    lon?: Maybe<Scalars['String']>;
    lat?: Maybe<Scalars['String']>;
    numberOfApts?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum HouseType {
    Blocky = 'BLOCKY',
    Panel = 'PANEL',
    Brick = 'BRICK',
    Monolithic = 'MONOLITHIC',
    BrickMonolithic = 'BRICK_MONOLITHIC'
}

export type InvalidBlockPeriodError = {
    __typename: 'InvalidBlockPeriodError';
    errorMessage: Scalars['String'];
};

export type InvalidCommunalPaymentError = {
    __typename: 'InvalidCommunalPaymentError';
    errorMessage: Scalars['String'];
};

export type InvalidCredentialsError = {
    __typename: 'InvalidCredentialsError';
    errorMessage: Scalars['String'];
};

export type InvalidEmailError = {
    __typename: 'InvalidEmailError';
    errorMessage: Scalars['String'];
};

export type InvalidExpenseValueError = {
    __typename: 'InvalidExpenseValueError';
    errorMessage: Scalars['String'];
};

export type InvalidExpensesListError = {
    __typename: 'InvalidExpensesListError';
    errorMessage: Scalars['String'];
};

export type InvalidJwtError = {
    __typename: 'InvalidJWTError';
    errorMessage: Scalars['String'];
};

export type InvalidParamsError = {
    __typename: 'InvalidParamsError';
    errorMessage: Scalars['String'];
    paramName?: Maybe<Scalars['String']>;
};

export type InvalidPasswordError = {
    __typename: 'InvalidPasswordError';
    errorMessage: Scalars['String'];
};

export type InvalidPhoneError = {
    __typename: 'InvalidPhoneError';
    errorMessage: Scalars['String'];
};

export type InvalidVerifyCodeError = {
    __typename: 'InvalidVerifyCodeError';
    errorMessage: Scalars['String'];
    hasResendAttempts: Scalars['Boolean'];
};

/** An enumeration. */
export enum Lang {
    Ru = 'RU',
    En = 'EN'
}

export type LocalNameInput = {
    lang: Lang;
    name: Scalars['String'];
};

export type LocalNameNode = {
    __typename: 'LocalNameNode';
    lang: Lang;
    name: Scalars['String'];
};

export type LockNode = {
    __typename: 'LockNode';
    reason?: Maybe<Scalars['String']>;
    reasonType?: Maybe<LockReason>;
    startLockDate?: Maybe<Scalars['DateTime']>;
    lockEndDate?: Maybe<Scalars['DateTime']>;
    lockedBySystem?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum LockReason {
    Custom = 'CUSTOM',
    ParticipantsRefused = 'PARTICIPANTS_REFUSED',
    WinnersRefused = 'WINNERS_REFUSED'
}

export enum LockedSortEnum {
    LockStartDate = 'LOCK_START_DATE',
    LockStartDateDesc = 'LOCK_START_DATE_DESC',
    LockEndDate = 'LOCK_END_DATE',
    LockEndDateDesc = 'LOCK_END_DATE_DESC'
}

export type LoginIsEmptyError = {
    __typename: 'LoginIsEmptyError';
    errorMessage: Scalars['String'];
};

/** An enumeration. */
export enum LoginType {
    Email = 'EMAIL',
    Phone = 'PHONE',
    EosAccount = 'EOS_ACCOUNT'
}

export type MarkAllNotificationsAsReadOutput = MarkAllNotificationsAsReadSuccess;

export type MarkAllNotificationsAsReadSuccess = {
    __typename: 'MarkAllNotificationsAsReadSuccess';
    me: UserNode;
};

export type MarkApartmentAsViewedInput = {
    aptId: Scalars['Int'];
};

export type MarkApartmentAsViewedOutput = MarkApartmentAsViewedSuccess | AptNotFoundError | HasNoPermissionError;

export type MarkApartmentAsViewedSuccess = {
    __typename: 'MarkApartmentAsViewedSuccess';
    apt: ApartmentNode;
};

export type MarkBuyRequestAsViewedInput = {
    buyRequestId: Scalars['Int'];
};

export type MarkBuyRequestAsViewedOutput =
    | MarkBuyRequestAsViewedSuccess
    | BuyRequestNotFoundError
    | HasNoPermissionError;

export type MarkBuyRequestAsViewedSuccess = {
    __typename: 'MarkBuyRequestAsViewedSuccess';
    buyRequest: BuyAptRequestNode;
};

export type MarkNotificationAsReadInput = {
    id: Scalars['String'];
};

export type MarkNotificationAsReadOutput =
    | MarkNotificationAsReadSuccess
    | HasNoPermissionError
    | NotificationNotFoundError;

export type MarkNotificationAsReadSuccess = {
    __typename: 'MarkNotificationAsReadSuccess';
    me: UserNode;
    notification?: Maybe<NotifyHistoryNode>;
};

export type MissedRequiredFieldError = {
    __typename: 'MissedRequiredFieldError';
    errorMessage: Scalars['String'];
};

export type Mutations = {
    __typename: 'Mutations';
    updateNotifySettings?: Maybe<UpdateNotifySettingsOutput>;
    /** Used in notifications history to mark notification as read */
    markNotificationAsRead?: Maybe<MarkNotificationAsReadOutput>;
    /**
     * Used in notifications history to mark all notifications
     * for this user as read
     */
    markAllNotificationsAsRead?: Maybe<MarkAllNotificationsAsReadOutput>;
    deleteDocument?: Maybe<DeleteDocumentOutput>;
    saveApartment?: Maybe<SaveApartmentOutput>;
    publishApartment?: Maybe<PublishApartmentOutput>;
    cancelPublication?: Maybe<CancelPublicationOutput>;
    deleteApartment?: Maybe<DeleteApartmentOutput>;
    updateAptFavorites?: Maybe<UpdateAptFavoriteOutput>;
    withdrawFromContest?: Maybe<WithdrawFromContestOutput>;
    reviewApartment?: Maybe<ReviewApartmentOutput>;
    resolveReview?: Maybe<ResolveReviewOutput>;
    cancelContestAsNotary?: Maybe<CancelContestOutput>;
    cancelContestAsOwner?: Maybe<CancelContestOutput>;
    addRental?: Maybe<AddRentalOutput>;
    refuseToWin?: Maybe<RefuseToWinOutput>;
    reportAddressCheckError?: Maybe<ReportAddressCheckErrorOutput>;
    reportMisCoordsError?: Maybe<ReportMisCoordsErrorOutput>;
    blockApt?: Maybe<BlockAptOutput>;
    unblockApt?: Maybe<UnblockAptOutput>;
    markApartmentAsViewed?: Maybe<MarkApartmentAsViewedOutput>;
    markBuyRequestAsViewed?: Maybe<MarkBuyRequestAsViewedOutput>;
    addExpense?: Maybe<AddExpenseOutput>;
    updateExpense?: Maybe<UpdateExpenseOutput>;
    deleteExpense?: Maybe<DeleteExpenseOutput>;
    addRegionDocument?: Maybe<AddRegionDocumentOutput>;
    updateRegionDocument?: Maybe<UpdateRegionDocumentOutput>;
    deleteRegionDocument?: Maybe<DeleteRegionDocumentOutput>;
    startAuction?: Maybe<StartAuctionOutput>;
    updateAptRealId?: Maybe<UpdateAptRealIdOutput>;
    confirmReadinessAsWinner?: Maybe<ConfirmReadinessAsWinnerOutput>;
    startEvictionAsNotary?: Maybe<StartEvictionOutput>;
    startEvictionAsTenant?: Maybe<StartEvictionOutput>;
    confirmEviction?: Maybe<ConfirmEvictionOutput>;
    updatePhotosOrder?: Maybe<UpdatePhotosOrderOutput>;
    cancelBuyRequest?: Maybe<CancelBuyRequestOutput>;
    burnLists?: Maybe<BurnListsOutput>;
    addMeChatPushNotification?: Maybe<AddPushNotification>;
    addPushNotificationDeviceToken?: Maybe<AddPushNotification>;
    removeMeChatPushNotification?: Maybe<RemovePushNotification>;
    removePushNotificationDeviceToken?: Maybe<RemovePushNotification>;
    signup?: Maybe<SignupOutput>;
    signin?: Maybe<SigninOutput>;
    resetPassword?: Maybe<ResetPasswordOutput>;
    confirmPasswordReset?: Maybe<ConfirmPasswordResetOutput>;
    verifyCode?: Maybe<VerifyCodeOutput>;
    resendCode?: Maybe<ResendCodeOutput>;
    refreshToken?: Maybe<RefreshTokenOutput>;
    checkPassword?: Maybe<CheckPasswordOutput>;
    changeLogin?: Maybe<ChangeLoginOutput>;
    changePassword?: Maybe<ChangePasswordOutput>;
    bindUserWithCity?: Maybe<BindUserWithCity>;
    removeLogin?: Maybe<RemoveLoginOutput>;
    setLanguage?: Maybe<SetLanguageOutput>;
    connectByWallet?: Maybe<ConnectByWalletOutput>;
    deleteAccount?: Maybe<Output>;
    updateRdContacts?: Maybe<UpdateRdContactsOutput>;
};

export type MutationsUpdateNotifySettingsArgs = {
    input: UpdateNotifySettingsInput;
};

export type MutationsMarkNotificationAsReadArgs = {
    input: MarkNotificationAsReadInput;
};

export type MutationsDeleteDocumentArgs = {
    input: DeleteDocumentInput;
};

export type MutationsSaveApartmentArgs = {
    input: SaveApartmentInput;
};

export type MutationsPublishApartmentArgs = {
    input: PublishApartmentInput;
};

export type MutationsCancelPublicationArgs = {
    input: CancelPublicationInput;
};

export type MutationsDeleteApartmentArgs = {
    input: DeleteApartmentInput;
};

export type MutationsUpdateAptFavoritesArgs = {
    input: UpdateAptFavoriteInput;
};

export type MutationsWithdrawFromContestArgs = {
    input: WithdrawFromContestInput;
};

export type MutationsReviewApartmentArgs = {
    input: ReviewApartmentInput;
};

export type MutationsResolveReviewArgs = {
    input: ReviewApartmentInput;
};

export type MutationsCancelContestAsNotaryArgs = {
    input: CancelContestInput;
};

export type MutationsCancelContestAsOwnerArgs = {
    input: CancelContestInput;
};

export type MutationsAddRentalArgs = {
    input: AddRentalInput;
};

export type MutationsRefuseToWinArgs = {
    input: RefuseToWinInput;
};

export type MutationsReportAddressCheckErrorArgs = {
    input: ReportAddressCheckErrorInput;
};

export type MutationsReportMisCoordsErrorArgs = {
    input: ReportMisCoordsErrorInput;
};

export type MutationsBlockAptArgs = {
    input: BlockAptInput;
};

export type MutationsUnblockAptArgs = {
    input: UnblockAptInput;
};

export type MutationsMarkApartmentAsViewedArgs = {
    input: MarkApartmentAsViewedInput;
};

export type MutationsMarkBuyRequestAsViewedArgs = {
    input: MarkBuyRequestAsViewedInput;
};

export type MutationsAddExpenseArgs = {
    input: AddExpenseInput;
};

export type MutationsUpdateExpenseArgs = {
    input: UpdateExpenseInput;
};

export type MutationsDeleteExpenseArgs = {
    input: DeleteExpenseInput;
};

export type MutationsAddRegionDocumentArgs = {
    input: AddRegionDocumentInput;
};

export type MutationsUpdateRegionDocumentArgs = {
    input: UpdateRegionDocumentInput;
};

export type MutationsDeleteRegionDocumentArgs = {
    input: DeleteRegionDocumentInput;
};

export type MutationsStartAuctionArgs = {
    input: StartAuctionInput;
};

export type MutationsUpdateAptRealIdArgs = {
    input: UpdateAptRealIdInput;
};

export type MutationsConfirmReadinessAsWinnerArgs = {
    input: ConfirmReadinessAsWinnerInput;
};

export type MutationsStartEvictionAsNotaryArgs = {
    input: StartEvictionInput;
};

export type MutationsStartEvictionAsTenantArgs = {
    input: StartEvictionInput;
};

export type MutationsConfirmEvictionArgs = {
    input: ConfirmEvictionInput;
};

export type MutationsUpdatePhotosOrderArgs = {
    input: UpdatePhotosOrderInput;
};

export type MutationsCancelBuyRequestArgs = {
    input: CancelBuyRequestInput;
};

export type MutationsBurnListsArgs = {
    input: CancelBuyRequestInput;
};

export type MutationsAddMeChatPushNotificationArgs = {
    deviceToken: Scalars['String'];
    deviceType: DeviceType;
    eosAccounts: Array<Maybe<Scalars['String']>>;
    langCode: Lang;
};

export type MutationsAddPushNotificationDeviceTokenArgs = {
    deviceToken: Scalars['String'];
    deviceType: DeviceType;
    eosAccounts: Array<Maybe<Scalars['String']>>;
    langCode: Lang;
};

export type MutationsRemoveMeChatPushNotificationArgs = {
    deviceToken: Scalars['String'];
    eosAccounts: Array<Maybe<Scalars['String']>>;
};

export type MutationsRemovePushNotificationDeviceTokenArgs = {
    deviceToken: Scalars['String'];
    eosAccounts: Array<Maybe<Scalars['String']>>;
};

export type MutationsSignupArgs = {
    input: SignupInput;
};

export type MutationsSigninArgs = {
    input: SigninInput;
};

export type MutationsResetPasswordArgs = {
    input: ResetPasswordInput;
};

export type MutationsConfirmPasswordResetArgs = {
    input: ConfirmPasswordResetInput;
};

export type MutationsVerifyCodeArgs = {
    input: VerifyCodeInput;
};

export type MutationsCheckPasswordArgs = {
    input: CheckPasswordInput;
};

export type MutationsChangeLoginArgs = {
    input: ChangeLoginInput;
};

export type MutationsChangePasswordArgs = {
    input: ChangePasswordInput;
};

export type MutationsBindUserWithCityArgs = {
    cityId: Scalars['Int'];
};

export type MutationsRemoveLoginArgs = {
    input: RemoveLoginInput;
};

export type MutationsSetLanguageArgs = {
    input: SetLanguageInput;
};

export type MutationsConnectByWalletArgs = {
    input: ConnectByWalletInput;
};

export type MutationsUpdateRdContactsArgs = {
    input: UpdateRdContactsInput;
};

export type NoResendAttemptsError = {
    __typename: 'NoResendAttemptsError';
    errorMessage: Scalars['String'];
};

export type NotaryUpdates = {
    __typename: 'NotaryUpdates';
    moderationsNum: Scalars['Int'];
    reworksNum: Scalars['Int'];
    activeContestsNum: Scalars['Int'];
    finishedContestsNum: Scalars['Int'];
    rentalsNum: Scalars['Int'];
    waitingContestsNum: Scalars['Int'];
    evictionsNum: Scalars['Int'];
    lockedNum: Scalars['Int'];
    activeBuyRequestsNum: Scalars['Int'];
    finishedBuyRequestsNum: Scalars['Int'];
};

export type NotificationNotFoundError = {
    __typename: 'NotificationNotFoundError';
    errorMessage: Scalars['String'];
};

/** An enumeration. */
export enum NotificationType {
    AuctionStarted = 'AUCTION_STARTED',
    ModerationFailed = 'MODERATION_FAILED',
    ModerationSuccessed = 'MODERATION_SUCCESSED',
    ReviewAuctionStageStartedOwner = 'REVIEW_AUCTION_STAGE_STARTED_OWNER',
    AuctionFinishedOwner = 'AUCTION_FINISHED_OWNER',
    IssuedTokensForApt = 'ISSUED_TOKENS_FOR_APT',
    AllRefusedToWin = 'ALL_REFUSED_TO_WIN',
    AuctionBidAccepted = 'AUCTION_BID_ACCEPTED',
    AuctionBidCancelled = 'AUCTION_BID_CANCELLED',
    AuctionFinishedUser = 'AUCTION_FINISHED_USER',
    AuctionFinishedWinner = 'AUCTION_FINISHED_WINNER',
    ReviewAuctionStageStartedUser = 'REVIEW_AUCTION_STAGE_STARTED_USER',
    WinnerReElected = 'WINNER_RE_ELECTED',
    RentalStarted = 'RENTAL_STARTED',
    RentalExtended = 'RENTAL_EXTENDED',
    RentalIsAboutEnd = 'RENTAL_IS_ABOUT_END',
    RentalEnded = 'RENTAL_ENDED',
    WinnerInactive = 'WINNER_INACTIVE'
}

/** An enumeration. */
export enum NotifyCategory {
    AptStatus = 'APT_STATUS'
}

export type NotifyHistoryNode = {
    __typename: 'NotifyHistoryNode';
    id?: Maybe<Scalars['String']>;
    created?: Maybe<Scalars['DateTime']>;
    notificationType?: Maybe<NotificationType>;
    text?: Maybe<Scalars['String']>;
    cityName?: Maybe<Scalars['String']>;
    address?: Maybe<Scalars['String']>;
    isNew?: Maybe<Scalars['Boolean']>;
    aptId?: Maybe<Scalars['Int']>;
    emmissionTxid?: Maybe<Scalars['String']>;
};

export type NotifySettingsInput = {
    category: NotifyCategory;
    isSmsEnabled?: Maybe<Scalars['Boolean']>;
    isEmailEnabled?: Maybe<Scalars['Boolean']>;
};

export type NotifySettingsNode = {
    __typename: 'NotifySettingsNode';
    aptStatus?: Maybe<ChannelNode>;
};

/** An enumeration. */
export enum ObjType {
    Apartment = 'APARTMENT'
}

export type OutOfPolygonError = {
    __typename: 'OutOfPolygonError';
    errorMessage: Scalars['String'];
};

export type Output = DeleteAccountSuccess;

export type PageInfo = {
    __typename: 'PageInfo';
    total: Scalars['Int'];
};

export type ParkingInput = {
    ground?: Maybe<Scalars['Boolean']>;
    underground?: Maybe<Scalars['Boolean']>;
    multilevel?: Maybe<Scalars['Boolean']>;
};

export type PlaceNode = {
    __typename: 'PlaceNode';
    id: Scalars['Int'];
    gmapPlaceId: Scalars['String'];
    address: Scalars['String'];
    lon?: Maybe<Scalars['String']>;
    lat?: Maybe<Scalars['String']>;
    /** In meters */
    distanceToPolygon?: Maybe<Scalars['Int']>;
    isInsidePolygon?: Maybe<Scalars['Boolean']>;
    canBeAccepted?: Maybe<Scalars['Boolean']>;
    coordsMayBeWrong?: Maybe<Scalars['Boolean']>;
    city?: Maybe<CityNode>;
};

export type PlaceNotFoundError = {
    __typename: 'PlaceNotFoundError';
    errorMessage: Scalars['String'];
};

export type PublishApartmentInput = {
    aptId: Scalars['Int'];
};

export type PublishApartmentOutput =
    | PublishApartmentSuccess
    | AptNotFoundError
    | HasNoPermissionError
    | ActionNotAllowedForAptStatusError
    | AddressIsBlockedError
    | ApartmentAlreadyExistsError
    | MissedRequiredFieldError;

export type PublishApartmentSuccess = {
    __typename: 'PublishApartmentSuccess';
    apt: ApartmentNode;
};

export type Query = {
    __typename: 'Query';
    notificationsNumber?: Maybe<Scalars['Int']>;
    mobileAppDeeplink?: Maybe<Scalars['String']>;
    suitableAddresses: Array<Maybe<GMapAddressStruct>>;
    ourSuitableAddresses: Array<Maybe<PlaceNode>>;
    place?: Maybe<PlaceNode>;
    realtime?: Maybe<Scalars['DateTime']>;
    transferFee?: Maybe<Scalars['String']>;
    /** Time of rental in seconds */
    rentalTime?: Maybe<Scalars['Int']>;
    addApartmentConfig?: Maybe<AddApartmentConfig>;
    buyRequestConfig?: Maybe<BuyRequestConfig>;
    houses?: Maybe<Array<Maybe<HouseNode>>>;
    apartments: ApartmentListNode;
    myApartments?: Maybe<ApartmentListNode>;
    apartment?: Maybe<ApartmentNode>;
    /** Show apartments for RDs "applications" page */
    applications?: Maybe<ApartmentListNode>;
    /** Show Apartments that are in an auction or with a just-completed auction */
    auctions?: Maybe<ApartmentListNode>;
    /** Show Apartments that are or was in rental */
    rentals?: Maybe<ApartmentListNode>;
    /** Show Apartments that are waiting contest */
    waitingContestApts?: Maybe<ApartmentListNode>;
    /** Show apartments that was blocked */
    lockedApts?: Maybe<ApartmentListNode>;
    /** Show all buy requests */
    buyRequests?: Maybe<BuyRequestListNode>;
    /** Show users buy requests */
    myBuyRequests?: Maybe<BuyRequestListNode>;
    buyRequest?: Maybe<BuyAptRequestNode>;
    rentalExpirationAfterRenew?: Maybe<Scalars['DateTime']>;
    contestCheck: ContestCheckDataNode;
    countries?: Maybe<Array<Maybe<CountryNode>>>;
    cities?: Maybe<Array<Maybe<CityNode>>>;
    myCities?: Maybe<Array<Maybe<CityNode>>>;
    citiesManagedByUser?: Maybe<Array<Maybe<CityNode>>>;
    city?: Maybe<CityNode>;
    tokens?: Maybe<Array<Maybe<TokenNode>>>;
    cryptocashes?: Maybe<Array<Maybe<TokenNode>>>;
    checkAddress?: Maybe<ConclusionOnAddress>;
    commonStatistic: CommonStatisticNode;
    regionExpenses?: Maybe<Array<Maybe<ExpenseNode>>>;
    aptExpenses?: Maybe<Array<Maybe<AptExpenseNode>>>;
    regionDocuments?: Maybe<Array<Maybe<RegionDocumentNode>>>;
    buyAptParams?: Maybe<BuyAptPaymentStruct>;
    reverseInheritance?: Maybe<Array<Maybe<EosInheritanceNode>>>;
    eosNodes?: Maybe<Array<Maybe<EosNode>>>;
    me?: Maybe<UserNode>;
    isPasswordCorrect?: Maybe<Scalars['Boolean']>;
    currentLanguage: Lang;
    eosAccountExists?: Maybe<Scalars['Boolean']>;
};

export type QueryNotificationsNumberArgs = {
    newOnly?: Maybe<Scalars['Boolean']>;
};

export type QuerySuitableAddressesArgs = {
    address: Scalars['String'];
    cityId?: Maybe<Scalars['Int']>;
    first?: Maybe<Scalars['String']>;
    fingerprint?: Maybe<Scalars['String']>;
};

export type QueryOurSuitableAddressesArgs = {
    address: Scalars['String'];
    cityId?: Maybe<Scalars['Int']>;
};

export type QueryPlaceArgs = {
    id?: Maybe<Scalars['Int']>;
    gmapPlaceId?: Maybe<Scalars['String']>;
    cityId?: Maybe<Scalars['Int']>;
    fingerprint?: Maybe<Scalars['String']>;
};

export type QueryHousesArgs = {
    filters?: Maybe<ApartmentFilterInput>;
    favorites?: Maybe<Scalars['Boolean']>;
};

export type QueryApartmentsArgs = {
    sort?: Maybe<Array<Maybe<ApartmentSortEnum>>>;
    filters?: Maybe<ApartmentFilterInput>;
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    favorites?: Maybe<Scalars['Boolean']>;
};

export type QueryMyApartmentsArgs = {
    relationType: RelationToApt;
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type QueryApartmentArgs = {
    id: Scalars['Int'];
    lang?: Maybe<Lang>;
};

export type QueryApplicationsArgs = {
    cityId: Scalars['Int'];
    statuses?: Maybe<Array<Maybe<AptStatus>>>;
    sort?: Maybe<Array<Maybe<ApplicationSortEnum>>>;
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type QueryAuctionsArgs = {
    cityId: Scalars['Int'];
    sort?: Maybe<Array<Maybe<AuctionSortEnum>>>;
    isFinished?: Maybe<Scalars['Boolean']>;
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type QueryRentalsArgs = {
    cityId: Scalars['Int'];
    sort?: Maybe<Array<Maybe<RentalSortEnum>>>;
    isEvictionStarted: Scalars['Boolean'];
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type QueryWaitingContestAptsArgs = {
    cityId: Scalars['Int'];
    sort?: Maybe<Array<Maybe<WaitingContestSortEnum>>>;
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type QueryLockedAptsArgs = {
    cityId: Scalars['Int'];
    sort?: Maybe<Array<Maybe<LockedSortEnum>>>;
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type QueryBuyRequestsArgs = {
    cityId: Scalars['Int'];
    isActive?: Maybe<Scalars['Boolean']>;
    sort?: Maybe<Array<Maybe<BuyRequestSortEnum>>>;
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type QueryMyBuyRequestsArgs = {
    cityId?: Maybe<Scalars['Int']>;
    isActive?: Maybe<Scalars['Boolean']>;
    sort?: Maybe<Array<Maybe<BuyRequestSortEnum>>>;
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type QueryBuyRequestArgs = {
    id: Scalars['Int'];
};

export type QueryRentalExpirationAfterRenewArgs = {
    curentExpiration?: Maybe<Scalars['DateTime']>;
};

export type QueryContestCheckArgs = {
    aptId: Scalars['Int'];
};

export type QueryCountriesArgs = {
    filters?: Maybe<CountryFilterInput>;
    sort?: Maybe<Array<Maybe<CountrySortEnum>>>;
    lang?: Maybe<Lang>;
};

export type QueryCitiesArgs = {
    filters?: Maybe<CityFilterInput>;
    sort?: Maybe<Array<Maybe<CitySortEnum>>>;
    lang?: Maybe<Lang>;
};

export type QueryMyCitiesArgs = {
    relationType: RelationToApt;
};

export type QueryCitiesManagedByUserArgs = {
    eosAccount: Scalars['String'];
};

export type QueryCityArgs = {
    id: Scalars['Int'];
};

export type QueryCheckAddressArgs = {
    aptNumber: Scalars['Int'];
    placeId: Scalars['Int'];
};

export type QueryRegionExpensesArgs = {
    cityId: Scalars['Int'];
};

export type QueryAptExpensesArgs = {
    aptId: Scalars['Int'];
};

export type QueryRegionDocumentsArgs = {
    cityId: Scalars['Int'];
};

export type QueryBuyAptParamsArgs = {
    amountToPay: Scalars['String'];
    tokenId: Scalars['String'];
};

export type QueryReverseInheritanceArgs = {
    heirsUsernames?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryIsPasswordCorrectArgs = {
    password: Scalars['String'];
};

export type QueryEosAccountExistsArgs = {
    eosAccount: Scalars['String'];
};

export type RangeFloat = {
    from?: Maybe<Scalars['Float']>;
    to?: Maybe<Scalars['Float']>;
};

export type RangeInt = {
    from?: Maybe<Scalars['Int']>;
    to?: Maybe<Scalars['Int']>;
};

export type RefreshTokenOutput = RefreshTokenSuccess;

export type RefreshTokenSuccess = {
    __typename: 'RefreshTokenSuccess';
    me?: Maybe<UserNode>;
    token: Scalars['String'];
};

export type RefuseToWinInput = {
    aptId: Scalars['Int'];
};

export type RefuseToWinOutput =
    | RefuseToWinSuccess
    | AptNotFoundError
    | HasNoPermissionError
    | ActionNotAllowedForAptStatusError;

export type RefuseToWinSuccess = {
    __typename: 'RefuseToWinSuccess';
    apt: ApartmentNode;
};

export type RegionDocumentNode = {
    __typename: 'RegionDocumentNode';
    id: Scalars['Int'];
    name: Scalars['String'];
    locales?: Maybe<Array<Maybe<LocalNameNode>>>;
};

export type RegionDocumentNotFoundError = {
    __typename: 'RegionDocumentNotFoundError';
    errorMessage: Scalars['String'];
};

/** An enumeration. */
export enum RelationToApt {
    Own = 'OWN',
    Favorite = 'FAVORITE',
    Rental = 'RENTAL'
}

export type RemoveLoginInput = {
    loginType: LoginType;
};

export type RemoveLoginOutput = RemoveLoginSuccess | CantDeleteLoginError;

export type RemoveLoginSuccess = {
    __typename: 'RemoveLoginSuccess';
    me?: Maybe<UserNode>;
};

export type RemovePushNotification = {
    __typename: 'RemovePushNotification';
    ok?: Maybe<Scalars['Boolean']>;
};

export type RentalCanNotBeExtendedError = {
    __typename: 'RentalCanNotBeExtendedError';
    errorMessage: Scalars['String'];
};

export type RentalNode = {
    __typename: 'RentalNode';
    id: Scalars['Int'];
    apt?: Maybe<ApartmentNode>;
    startDate?: Maybe<Scalars['DateTime']>;
    expirationDate?: Maybe<Scalars['DateTime']>;
    isExpired?: Maybe<Scalars['Boolean']>;
    tenantAccount?: Maybe<Scalars['String']>;
    tenant?: Maybe<UserNode>;
    isIAmTenant: Scalars['Boolean'];
    tenantChatLink?: Maybe<Scalars['String']>;
    notary?: Maybe<UserNode>;
    price?: Maybe<Scalars['String']>;
    /** Rental can be extended */
    canBeExtended?: Maybe<Scalars['Boolean']>;
    expirationAfterExtend?: Maybe<Scalars['DateTime']>;
    renewStartTime?: Maybe<Scalars['DateTime']>;
    isEvictionStarted: Scalars['Boolean'];
    evictionStart?: Maybe<Scalars['DateTime']>;
    isEvictionStartedByTenant: Scalars['Boolean'];
    evictionReason?: Maybe<Scalars['String']>;
};

export enum RentalSortEnum {
    Address = 'ADDRESS',
    AddressDesc = 'ADDRESS_DESC',
    M2 = 'M2',
    M2Desc = 'M2_DESC',
    ExpirationDate = 'EXPIRATION_DATE',
    ExpirationDateDesc = 'EXPIRATION_DATE_DESC',
    UpdatedForRd = 'UPDATED_FOR_RD',
    UpdatedForRdDesc = 'UPDATED_FOR_RD_DESC'
}

export type ReportAddressCheckErrorInput = {
    placeId: Scalars['Int'];
};

export type ReportAddressCheckErrorOutput =
    | ReportAddressCheckErrorSuccess
    | PlaceNotFoundError
    | UserNotFoundError
    | AddressAlreadyInAreaError
    | ReportAlreadyRegisteredError;

export type ReportAddressCheckErrorSuccess = {
    __typename: 'ReportAddressCheckErrorSuccess';
    ok: Scalars['Boolean'];
};

export type ReportAlreadyRegisteredError = {
    __typename: 'ReportAlreadyRegisteredError';
    errorMessage: Scalars['String'];
};

export type ReportMisCoordsErrorInput = {
    placeId: Scalars['Int'];
};

export type ReportMisCoordsErrorOutput =
    | ReportMisCoordsErrorSuccess
    | PlaceNotFoundError
    | ReportAlreadyRegisteredError;

export type ReportMisCoordsErrorSuccess = {
    __typename: 'ReportMisCoordsErrorSuccess';
    ok: Scalars['Boolean'];
};

export type ResendCodeOutput = ResendCodeSuccess | InvalidJwtError | NoResendAttemptsError | TrySendCodeTooEarlyError;

export type ResendCodeSuccess = {
    __typename: 'ResendCodeSuccess';
    verifyToken: Scalars['String'];
    attemptsLeft: Scalars['Int'];
};

export type ResetPasswordInput = {
    phone?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
};

export type ResetPasswordOutput =
    | ResetPasswordSuccess
    | LoginIsEmptyError
    | UserNotFoundError
    | NoResendAttemptsError
    | InvalidPhoneError
    | InvalidEmailError;

export type ResetPasswordSuccess = {
    __typename: 'ResetPasswordSuccess';
    me?: Maybe<UserNode>;
    nextStep: AuthStep;
    verifyToken: Scalars['String'];
};

export type ResolveReviewOutput =
    | ResolveReviewSuccess
    | AptNotFoundError
    | HasNoPermissionError
    | ActionNotAllowedForAptStatusError
    | ReviewNotFoundError;

export type ResolveReviewSuccess = {
    __typename: 'ResolveReviewSuccess';
    apt: ApartmentNode;
};

export type ReviewApartmentInput = {
    aptId: Scalars['Int'];
    review: AptReviewInput;
};

export type ReviewApartmentOutput =
    | ReviewApartmentSuccess
    | AptNotFoundError
    | HasNoPermissionError
    | ActionNotAllowedForAptStatusError;

export type ReviewApartmentSuccess = {
    __typename: 'ReviewApartmentSuccess';
    apt: ApartmentNode;
};

export type ReviewNotFoundError = {
    __typename: 'ReviewNotFoundError';
    errorMessage: Scalars['String'];
};

/** An enumeration. */
export enum RoadTime {
    Min_5 = 'MIN_5',
    Min_10 = 'MIN_10',
    Min_15 = 'MIN_15'
}

export type RoadToSubwayNode = {
    __typename: 'RoadToSubwayNode';
    id: Scalars['Int'];
    way?: Maybe<WayToGet>;
    minutes?: Maybe<RoadTime>;
    aptId?: Maybe<Scalars['Int']>;
    apartment?: Maybe<ApartmentNode>;
    subwayId?: Maybe<Scalars['Int']>;
    subway?: Maybe<SubwayNode>;
};

export type RoomsInput = {
    isStudio?: Maybe<Scalars['Boolean']>;
    numberIn?: Maybe<Array<Maybe<Scalars['Int']>>>;
    numberRange?: Maybe<RangeInt>;
};

export type SaveApartmentInput = {
    id?: Maybe<Scalars['Int']>;
    mainInfo: AptMainInfo;
    details?: Maybe<AptDetails>;
};

export type SaveApartmentOutput =
    | SaveApartmentSuccess
    | AptNotFoundError
    | HasNoPermissionError
    | OutOfPolygonError
    | PlaceNotFoundError
    | AddressIsNotFullError
    | AddressIsBlockedError
    | EosAccountNotExistsError
    | InvalidCommunalPaymentError;

export type SaveApartmentSuccess = {
    __typename: 'SaveApartmentSuccess';
    apartment: ApartmentNode;
};

export type SetLanguageInput = {
    lang: Lang;
};

export type SetLanguageOutput = SetLanguageSuccess;

export type SetLanguageSuccess = {
    __typename: 'SetLanguageSuccess';
    ok: Scalars['Boolean'];
};

export type SigninInput = {
    phone?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    password: Scalars['String'];
};

export type SigninOutput = SigninSuccess | LoginIsEmptyError | InvalidCredentialsError;

export type SigninSuccess = {
    __typename: 'SigninSuccess';
    me?: Maybe<UserNode>;
    nextStep: AuthStep;
    token: Scalars['String'];
};

export type SignupInput = {
    phone?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    password: Scalars['String'];
};

export type SignupOutput =
    | SignupSuccess
    | LoginIsEmptyError
    | UserAlreadyExistsError
    | InvalidEmailError
    | InvalidPhoneError
    | InvalidPasswordError
    | NoResendAttemptsError;

export type SignupSuccess = {
    __typename: 'SignupSuccess';
    me?: Maybe<UserNode>;
    nextStep: AuthStep;
    verifyToken: Scalars['String'];
};

export type SquareInput = {
    total?: Maybe<RangeFloat>;
    living?: Maybe<RangeFloat>;
    kitchen?: Maybe<RangeFloat>;
};

export type StartAuctionInput = {
    aptId: Scalars['Int'];
    communalPayment?: Maybe<Scalars['String']>;
    expenses: Array<Maybe<AptExpensesInput>>;
};

export type StartAuctionOutput =
    | StartAuctionSuccess
    | HasNoPermissionError
    | AptNotFoundError
    | AptNotReviewedError
    | ActionNotAllowedForAptStatusError
    | InvalidExpenseValueError
    | ExpenseNotFoundError
    | InvalidExpensesListError
    | InvalidCommunalPaymentError;

export type StartAuctionSuccess = {
    __typename: 'StartAuctionSuccess';
    apt: ApartmentNode;
};

export type StartEvictionInput = {
    aptId: Scalars['Int'];
    reason?: Maybe<Scalars['String']>;
};

export type StartEvictionOutput =
    | StartEvictionSuccess
    | HasNoPermissionError
    | AptNotFoundError
    | ActionNotAllowedForAptStatusError
    | EvictionAlreadyStartedError
    | InvalidParamsError;

export type StartEvictionSuccess = {
    __typename: 'StartEvictionSuccess';
    apt: ApartmentNode;
};

export type StatisticNode = {
    __typename: 'StatisticNode';
    lastTokenizationTimestamp?: Maybe<Scalars['DateTime']>;
    tokenizedM2?: Maybe<Scalars['String']>;
    inContestAptsNumber?: Maybe<Scalars['Int']>;
    tokenizedAptsNumber?: Maybe<Scalars['Int']>;
    inTokenizationAptsNumber?: Maybe<Scalars['Int']>;
    inRentAptsNumber?: Maybe<Scalars['Int']>;
};

export type Subscription = {
    __typename: 'Subscription';
    notification?: Maybe<NotifyHistoryNode>;
    newNotification?: Maybe<NotifyHistoryNode>;
    hyperionHealth?: Maybe<Scalars['Boolean']>;
    RDUpdates?: Maybe<AptRdSubscriptionEvent>;
    apartment?: Maybe<AptSubscriptionEvent>;
    city?: Maybe<CityNode>;
    myBuyRequests?: Maybe<BuyRequestSubscriptionEvent>;
    RDBuyRequests?: Maybe<BuyRequestSubscriptionEvent>;
    me?: Maybe<UserNode>;
    connectByWallet?: Maybe<WalletConnectiontUpdate>;
};

export type SubscriptionNotificationArgs = {
    id: Scalars['String'];
};

export type SubscriptionRdUpdatesArgs = {
    cityId: Scalars['Int'];
};

export type SubscriptionApartmentArgs = {
    aptId: Scalars['Int'];
};

export type SubscriptionCityArgs = {
    cityId: Scalars['Int'];
};

export type SubscriptionRdBuyRequestsArgs = {
    cityId: Scalars['Int'];
};

export type SubscriptionConnectByWalletArgs = {
    verificationCode: Scalars['String'];
};

export type SubwayNode = {
    __typename: 'SubwayNode';
    id: Scalars['Int'];
    name?: Maybe<Scalars['String']>;
    cityId?: Maybe<Scalars['Int']>;
    city?: Maybe<CityNode>;
};

export type SubwaysInput = {
    subwayId: Scalars['Int'];
    way: WayToGet;
    minutes: RoadTime;
};

/** An enumeration. */
export enum ThumbnailSize {
    Size_320x180 = 'SIZE_320x180',
    Size_1920x1080 = 'SIZE_1920x1080',
    Size_981x402 = 'SIZE_981x402',
    Size_1125x540 = 'SIZE_1125x540'
}

export type TokenNode = {
    __typename: 'TokenNode';
    name: Scalars['String'];
    isAvailable?: Maybe<Scalars['Boolean']>;
    smartContract: Scalars['String'];
    blocksIoLink: Scalars['String'];
};

export type TokenizationSignStruct = {
    __typename: 'TokenizationSignStruct';
    aptId?: Maybe<Scalars['Int']>;
    symbol?: Maybe<Array<Maybe<Scalars['String']>>>;
    contract?: Maybe<Scalars['String']>;
    action?: Maybe<Scalars['String']>;
};

export type TokenizationStruct = {
    __typename: 'TokenizationStruct';
    isSignedByNotary?: Maybe<Scalars['Boolean']>;
    isSignedByOwner?: Maybe<Scalars['Boolean']>;
    isSignedByWinner?: Maybe<Scalars['Boolean']>;
    notarySignDeepLink?: Maybe<Scalars['String']>;
    ownerSignDeepLink?: Maybe<Scalars['String']>;
    winnerSignDeepLink?: Maybe<Scalars['String']>;
    emissionDeepLink?: Maybe<Scalars['String']>;
    signParams?: Maybe<TokenizationSignStruct>;
};

export type TransactionNode = {
    __typename: 'TransactionNode';
    id?: Maybe<Scalars['String']>;
    status?: Maybe<TxnStatus>;
    created?: Maybe<Scalars['DateTime']>;
    amount?: Maybe<Scalars['String']>;
    token?: Maybe<Scalars['String']>;
    sender?: Maybe<Scalars['String']>;
    receiver?: Maybe<Scalars['String']>;
    txnType?: Maybe<TxnType>;
    isWinner?: Maybe<Scalars['Boolean']>;
    auctionCode?: Maybe<Scalars['String']>;
    rank?: Maybe<Scalars['Int']>;
    action?: Maybe<ActionNode>;
};

export type TrySendCodeTooEarlyError = {
    __typename: 'TrySendCodeTooEarlyError';
    errorMessage: Scalars['String'];
};

/** An enumeration. */
export enum TxnStatus {
    Initiated = 'INITIATED',
    Sended = 'SENDED',
    Received = 'RECEIVED',
    Accepted = 'ACCEPTED',
    Declined = 'DECLINED',
    Cancelled = 'CANCELLED',
    Failed = 'FAILED'
}

/** An enumeration. */
export enum TxnType {
    Rent = 'RENT',
    Payback = 'PAYBACK',
    AccountConfirm = 'ACCOUNT_CONFIRM',
    CancelRequest = 'CANCEL_REQUEST',
    Buy = 'BUY',
    BuyFee = 'BUY_FEE'
}

export type UnblockAptInput = {
    aptId: Scalars['Int'];
};

export type UnblockAptOutput =
    | UnblockAptSuccess
    | HasNoPermissionError
    | AptNotFoundError
    | ActionNotAllowedForAptStatusError;

export type UnblockAptSuccess = {
    __typename: 'UnblockAptSuccess';
    apt: ApartmentNode;
};

export type UpdateAptFavoriteInput = {
    aptId: Scalars['Int'];
};

export type UpdateAptFavoriteOutput = UpdateAptFavoriteSuccess | AptNotFoundError;

export type UpdateAptFavoriteSuccess = {
    __typename: 'UpdateAptFavoriteSuccess';
    apt: ApartmentNode;
};

export type UpdateAptRealIdInput = {
    aptId: Scalars['Int'];
    realId: Scalars['String'];
};

export type UpdateAptRealIdOutput =
    | UpdateAptRealIdSuccess
    | HasNoPermissionError
    | AptNotFoundError
    | ActionNotAllowedForAptStatusError;

export type UpdateAptRealIdSuccess = {
    __typename: 'UpdateAptRealIdSuccess';
    apt: ApartmentNode;
};

export type UpdateExpenseInput = {
    expenseId: Scalars['Int'];
    locales: Array<Maybe<LocalNameInput>>;
};

export type UpdateExpenseOutput = UpdateExpenseSuccess | HasNoPermissionError | ExpenseNotFoundError;

export type UpdateExpenseSuccess = {
    __typename: 'UpdateExpenseSuccess';
    expense: ExpenseNode;
};

export type UpdateNotifySettingsInput = {
    settings?: Maybe<Array<Maybe<NotifySettingsInput>>>;
};

export type UpdateNotifySettingsOutput = UpdateNotifySettingsSuccess;

export type UpdateNotifySettingsSuccess = {
    __typename: 'UpdateNotifySettingsSuccess';
    me: UserNode;
};

export type UpdatePhotosOrderInput = {
    aptId: Scalars['Int'];
    photosIds: Array<Maybe<Scalars['Int']>>;
};

export type UpdatePhotosOrderOutput =
    | UpdatePhotosOrderSuccess
    | HasNoPermissionError
    | AptNotFoundError
    | ActionNotAllowedForAptStatusError
    | DocumentNotFoundError;

export type UpdatePhotosOrderSuccess = {
    __typename: 'UpdatePhotosOrderSuccess';
    apt: ApartmentNode;
};

export type UpdateRdContactsInput = {
    cityId: Scalars['Int'];
    phone?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
};

export type UpdateRdContactsOutput =
    | UpdateRdContactsSuccess
    | InvalidPhoneError
    | InvalidEmailError
    | CityNotFoundError
    | HasNoPermissionError;

export type UpdateRdContactsSuccess = {
    __typename: 'UpdateRdContactsSuccess';
    city: CityNode;
};

export type UpdateRegionDocumentInput = {
    regionDocumentId: Scalars['Int'];
    locales: Array<Maybe<LocalNameInput>>;
};

export type UpdateRegionDocumentOutput =
    | UpdateRegionDocumentSuccess
    | HasNoPermissionError
    | RegionDocumentNotFoundError;

export type UpdateRegionDocumentSuccess = {
    __typename: 'UpdateRegionDocumentSuccess';
    regionDocument: RegionDocumentNode;
};

export type UserAlreadyExistsError = {
    __typename: 'UserAlreadyExistsError';
    errorMessage: Scalars['String'];
};

export type UserNode = {
    __typename: 'UserNode';
    id?: Maybe<Scalars['Int']>;
    email?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    eosAccount?: Maybe<Scalars['String']>;
    language?: Maybe<Lang>;
    notifySettings?: Maybe<NotifySettingsNode>;
    notifyHistory?: Maybe<Array<Maybe<NotifyHistoryNode>>>;
    hasNewNotifications?: Maybe<Scalars['Boolean']>;
    countNewNotifications?: Maybe<Scalars['Int']>;
    isNotary?: Maybe<Scalars['Boolean']>;
    citiesManagedByMe?: Maybe<Array<Maybe<CityNode>>>;
    cryptochatDeepLink?: Maybe<Scalars['String']>;
    hasPassword: Scalars['Boolean'];
    rdUpdates?: Maybe<NotaryUpdates>;
};

export type UserNodeNotifyHistoryArgs = {
    newOnly?: Maybe<Scalars['Boolean']>;
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type UserNotFoundError = {
    __typename: 'UserNotFoundError';
    errorMessage: Scalars['String'];
};

export type VerifyCodeExpiredError = {
    __typename: 'VerifyCodeExpiredError';
    errorMessage: Scalars['String'];
    hasResendAttempts: Scalars['Boolean'];
};

export type VerifyCodeInput = {
    code: Scalars['String'];
};

export type VerifyCodeNoCheckAttemptsError = {
    __typename: 'VerifyCodeNoCheckAttemptsError';
    errorMessage: Scalars['String'];
    hasResendAttempts: Scalars['Boolean'];
};

export type VerifyCodeOutput =
    | VerifyCodeSuccess
    | InvalidJwtError
    | InvalidVerifyCodeError
    | VerifyCodeExpiredError
    | UserAlreadyExistsError
    | VerifyCodeNoCheckAttemptsError;

export type VerifyCodeSuccess = {
    __typename: 'VerifyCodeSuccess';
    me?: Maybe<UserNode>;
    token: Scalars['String'];
};

export type VolumeStruct = {
    __typename: 'VolumeStruct';
    amount?: Maybe<Scalars['String']>;
    fee?: Maybe<Scalars['String']>;
    token?: Maybe<Scalars['String']>;
};

export enum WaitingContestSortEnum {
    UpdatedForRd = 'UPDATED_FOR_RD',
    UpdatedForRdDesc = 'UPDATED_FOR_RD_DESC'
}

export type WalletConnectionSuccess = {
    __typename: 'WalletConnectionSuccess';
    me: UserNode;
    token?: Maybe<Scalars['String']>;
};

export type WalletConnectiontUpdate =
    | WalletConnectionSuccess
    | UserNotFoundError
    | ConfirmationFailedError
    | WalletLinkOutdatedError
    | EosAccountAlreadyTakenError;

export type WalletLinkOutdatedError = {
    __typename: 'WalletLinkOutdatedError';
    errorMessage: Scalars['String'];
};

/** An enumeration. */
export enum WalletName {
    Paycash = 'PAYCASH',
    Malinka = 'MALINKA',
    Kalyna = 'KALYNA',
    Anchor = 'ANCHOR',
    TokenPoket = 'TOKEN_POKET'
}

/** An enumeration. */
export enum WayToGet {
    OnFoot = 'ON_FOOT',
    ByTransport = 'BY_TRANSPORT',
    ByCar = 'BY_CAR'
}

export type WindowsInput = {
    toYard?: Maybe<Scalars['Boolean']>;
    toStreet?: Maybe<Scalars['Boolean']>;
};

export type WithdrawFromContestInput = {
    aptId: Scalars['Int'];
};

export type WithdrawFromContestOutput =
    | WithdrawFromContestSuccess
    | AptNotFoundError
    | HasNoPermissionError
    | ActionNotAllowedForAptStatusError
    | ContestPaymentNotFoundError;

export type WithdrawFromContestSuccess = {
    __typename: 'WithdrawFromContestSuccess';
    apt: ApartmentNode;
};

export type ApartmentLockFragment = { __typename: 'LockNode' } & Pick<
    LockNode,
    'reason' | 'reasonType' | 'startLockDate' | 'lockEndDate'
>;

export type FieldStatusFragment = { __typename: 'FieldStatusStruct' } & Pick<
    FieldStatusStruct,
    'isOk' | 'errorDescription'
>;

export type ApartmentReviewFragment = { __typename: 'AptReviewNode' } & Pick<
    AptReviewNode,
    'comment' | 'errorsCount'
> & {
        fields?: Maybe<
            { __typename: 'FieldErrorsNode' } & {
                address?: Maybe<{ __typename: 'FieldStatusStruct' } & FieldStatusFragment>;
                realId?: Maybe<{ __typename: 'FieldStatusStruct' } & FieldStatusFragment>;
                housingComplex?: Maybe<{ __typename: 'FieldStatusStruct' } & FieldStatusFragment>;
                entranceNumber?: Maybe<{ __typename: 'FieldStatusStruct' } & FieldStatusFragment>;
                aptNumber?: Maybe<{ __typename: 'FieldStatusStruct' } & FieldStatusFragment>;
                subway?: Maybe<{ __typename: 'FieldStatusStruct' } & FieldStatusFragment>;
                houseType?: Maybe<{ __typename: 'FieldStatusStruct' } & FieldStatusFragment>;
                buildingYear?: Maybe<{ __typename: 'FieldStatusStruct' } & FieldStatusFragment>;
                entranceFloors?: Maybe<{ __typename: 'FieldStatusStruct' } & FieldStatusFragment>;
                aptFloor?: Maybe<{ __typename: 'FieldStatusStruct' } & FieldStatusFragment>;
                parking?: Maybe<{ __typename: 'FieldStatusStruct' } & FieldStatusFragment>;
                elevators?: Maybe<{ __typename: 'FieldStatusStruct' } & FieldStatusFragment>;
                infra?: Maybe<{ __typename: 'FieldStatusStruct' } & FieldStatusFragment>;
                aptType?: Maybe<{ __typename: 'FieldStatusStruct' } & FieldStatusFragment>;
                tenure?: Maybe<{ __typename: 'FieldStatusStruct' } & FieldStatusFragment>;
                rooms?: Maybe<{ __typename: 'FieldStatusStruct' } & FieldStatusFragment>;
                m2?: Maybe<{ __typename: 'FieldStatusStruct' } & FieldStatusFragment>;
                livingM2?: Maybe<{ __typename: 'FieldStatusStruct' } & FieldStatusFragment>;
                kitchenM2?: Maybe<{ __typename: 'FieldStatusStruct' } & FieldStatusFragment>;
                ceilingHeight?: Maybe<{ __typename: 'FieldStatusStruct' } & FieldStatusFragment>;
                bathroom?: Maybe<{ __typename: 'FieldStatusStruct' } & FieldStatusFragment>;
                windows?: Maybe<{ __typename: 'FieldStatusStruct' } & FieldStatusFragment>;
                balconies?: Maybe<{ __typename: 'FieldStatusStruct' } & FieldStatusFragment>;
                aptDescription?: Maybe<{ __typename: 'FieldStatusStruct' } & FieldStatusFragment>;
                photos?: Maybe<{ __typename: 'FieldStatusStruct' } & FieldStatusFragment>;
                documents?: Maybe<{ __typename: 'FieldStatusStruct' } & FieldStatusFragment>;
                communalPayment?: Maybe<{ __typename: 'FieldStatusStruct' } & FieldStatusFragment>;
            }
        >;
    };

export type EditApartmentDataFragment = { __typename: 'ApartmentNode' } & Pick<
    ApartmentNode,
    | 'id'
    | 'realId'
    | 'description'
    | 'status'
    | 'aptType'
    | 'floor'
    | 'number'
    | 'rooms'
    | 'isStudio'
    | 'm2'
    | 'livingM2'
    | 'kitchenM2'
    | 'ceilingHeight'
    | 'hasYardWindows'
    | 'hasStreetWindows'
    | 'combinedBaths'
    | 'dividedBaths'
    | 'tenure'
    | 'balconies'
    | 'hasLoggia'
    | 'refundAccount'
    | 'numberOfFloors'
    | 'hasElevator'
    | 'hasCargoElevator'
    | 'hasGarbageChute'
    | 'hasSecurity'
    | 'hasConcierge'
    | 'hasIntercom'
    | 'hasVideoSurveillance'
    | 'entranceNumber'
    | 'hasGas'
    | 'hasGroundParking'
    | 'hasUndergroundParking'
    | 'hasMultilevelParking'
    | 'houseType'
    | 'buildingYear'
    | 'housingComplex'
    | 'communalPayment'
    | 'isTokenized'
    | 'ownerAccount'
    | 'isIAmOwner'
    | 'isIAmNotary'
> & {
        place?: Maybe<
            { __typename: 'PlaceNode' } & Pick<
                PlaceNode,
                'id' | 'address' | 'lon' | 'lat' | 'coordsMayBeWrong' | 'isInsidePolygon'
            >
        >;
        city: { __typename: 'CityNode' } & Pick<CityNode, 'id' | 'centerLat' | 'centerLon' | 'name'> & {
                country?: Maybe<{ __typename: 'CountryNode' } & Pick<CountryNode, 'id' | 'name' | 'currencyCode'>>;
            };
        subways?: Maybe<
            Array<
                Maybe<
                    { __typename: 'RoadToSubwayNode' } & Pick<RoadToSubwayNode, 'id' | 'way' | 'minutes'> & {
                            subway?: Maybe<{ __typename: 'SubwayNode' } & Pick<SubwayNode, 'id' | 'name'>>;
                        }
                >
            >
        >;
        photos?: Maybe<
            Array<
                Maybe<
                    { __typename: 'DocumentNode' } & Pick<DocumentNode, 'id' | 'link' | 'originalName'> & {
                            preview: DocumentNode['link'];
                        }
                >
            >
        >;
        documents?: Maybe<
            Array<Maybe<{ __typename: 'DocumentNode' } & Pick<DocumentNode, 'id' | 'link' | 'name' | 'originalName'>>>
        >;
        review?: Maybe<{ __typename: 'AptReviewNode' } & ApartmentReviewFragment>;
    };

export type ManagerApartmentSharableFragment = { __typename: 'ApartmentNode' } & Pick<
    ApartmentNode,
    | 'id'
    | 'floor'
    | 'rooms'
    | 'isStudio'
    | 'm2'
    | 'status'
    | 'numberOfFloors'
    | 'isNewForRd'
    | 'updatedForRd'
    | 'number'
> & {
        place?: Maybe<{ __typename: 'PlaceNode' } & Pick<PlaceNode, 'id' | 'address'>>;
        city: { __typename: 'CityNode' } & Pick<CityNode, 'id' | 'name'>;
        photos?: Maybe<Array<Maybe<{ __typename: 'DocumentNode' } & Pick<DocumentNode, 'id' | 'link'>>>>;
    };

export type ManagerApplicationsApartmentFragment = { __typename: 'ApartmentNode' } & ManagerApartmentSharableFragment;

export type ManagerAuctionActiveApartmentFragment = { __typename: 'ApartmentNode' } & Pick<
    ApartmentNode,
    'isTokenized'
> & {
        latestAuction?: Maybe<
            { __typename: 'AuctionNode' } & Pick<AuctionNode, 'id' | 'endOfActivePhase'> & {
                    bidsNumber?: Maybe<{ __typename: 'BidsNumber' } & Pick<BidsNumber, 'active'>>;
                }
        >;
    } & ManagerApartmentSharableFragment;

export type ManagerAuctionFinishedApartmentFragment = { __typename: 'ApartmentNode' } & Pick<
    ApartmentNode,
    'isTokenized'
> & {
        tokenizationInfo?: Maybe<
            { __typename: 'TokenizationStruct' } & Pick<TokenizationStruct, 'isSignedByNotary' | 'isSignedByOwner'>
        >;
        latestAuction?: Maybe<
            { __typename: 'AuctionNode' } & Pick<
                AuctionNode,
                | 'id'
                | 'endOfActivePhase'
                | 'isWinnerSelectionInProgress'
                | 'winnerAccount'
                | 'winnerRank'
                | 'isWinnerAgree'
                | 'deadlineForWinner'
                | 'deadlineToPayCommunal'
            >
        >;
    } & ManagerApartmentSharableFragment;

export type ManagerBuyRequestFragment = { __typename: 'BuyAptRequestNode' } & Pick<
    BuyAptRequestNode,
    | 'id'
    | 'isNewForRd'
    | 'buyerAccount'
    | 'transferTime'
    | 'transferAmount'
    | 'returnedAmount'
    | 'changeAmount'
    | 'feeAmount'
    | 'status'
    | 'tokenId'
    | 'isApprovedByBuyer'
    | 'isApprovedByRd'
    | 'endTime'
> & {
        apt?: Maybe<
            { __typename: 'ApartmentNode' } & {
                actualRental?: Maybe<
                    { __typename: 'RentalNode' } & Pick<
                        RentalNode,
                        'id' | 'tenantAccount' | 'expirationDate' | 'isExpired'
                    >
                >;
            } & ManagerApartmentSharableFragment
        >;
    };

export type ManagerDocumentFragment = { __typename: 'RegionDocumentNode' } & Pick<RegionDocumentNode, 'id'> & {
        locales?: Maybe<Array<Maybe<{ __typename: 'LocalNameNode' } & Pick<LocalNameNode, 'name' | 'lang'>>>>;
    };

export type ManagerEvictionsApartmentFragment = { __typename: 'ApartmentNode' } & Pick<
    ApartmentNode,
    'communalCurrency'
> & {
        actualRental?: Maybe<
            { __typename: 'RentalNode' } & Pick<
                RentalNode,
                'id' | 'evictionStart' | 'isEvictionStartedByTenant' | 'evictionReason' | 'tenantAccount'
            >
        >;
    } & ManagerApartmentSharableFragment;

export type ManagerExpenseFragment = { __typename: 'ExpenseNode' } & Pick<ExpenseNode, 'id'> & {
        locales?: Maybe<Array<Maybe<{ __typename: 'LocalNameNode' } & Pick<LocalNameNode, 'name' | 'lang'>>>>;
    };

export type ManagerLockedAptsFragment = { __typename: 'ApartmentNode' } & {
    lock?: Maybe<{ __typename: 'LockNode' } & ApartmentLockFragment>;
} & ManagerApartmentSharableFragment;

export type ManagerRentalsApartmentFragment = { __typename: 'ApartmentNode' } & Pick<
    ApartmentNode,
    'communalCurrency'
> & {
        actualRental?: Maybe<
            { __typename: 'RentalNode' } & Pick<
                RentalNode,
                'id' | 'expirationDate' | 'isExpired' | 'tenantAccount' | 'price' | 'canBeExtended'
            >
        >;
    } & ManagerApartmentSharableFragment;

export type ManagerWaitingContestApartmentFragment = { __typename: 'ApartmentNode' } & ManagerApartmentSharableFragment;

export type ChannelNodeFragment = { __typename: 'ChannelNode' } & Pick<ChannelNode, 'isSmsEnabled' | 'isEmailEnabled'>;

export type MeNotificationSettingsFragmentFragment = { __typename: 'UserNode' } & Pick<UserNode, 'id'> & {
        notifySettings?: Maybe<
            { __typename: 'NotifySettingsNode' } & {
                aptStatus?: Maybe<{ __typename: 'ChannelNode' } & ChannelNodeFragment>;
            }
        >;
    };

export type MyBuyRequestFragment = { __typename: 'BuyAptRequestNode' } & Pick<
    BuyAptRequestNode,
    | 'id'
    | 'transferTime'
    | 'transferAmount'
    | 'returnedAmount'
    | 'feeAmount'
    | 'tokenId'
    | 'status'
    | 'isApprovedByBuyer'
    | 'isApprovedByRd'
    | 'endTime'
> & {
        apt?: Maybe<
            { __typename: 'ApartmentNode' } & Pick<
                ApartmentNode,
                'id' | 'floor' | 'rooms' | 'isStudio' | 'm2' | 'status' | 'numberOfFloors' | 'number'
            > & {
                    place?: Maybe<{ __typename: 'PlaceNode' } & Pick<PlaceNode, 'id' | 'address'>>;
                    city: { __typename: 'CityNode' } & Pick<CityNode, 'id' | 'name'>;
                    photos?: Maybe<Array<Maybe<{ __typename: 'DocumentNode' } & Pick<DocumentNode, 'id' | 'link'>>>>;
                    actualRental?: Maybe<
                        { __typename: 'RentalNode' } & Pick<
                            RentalNode,
                            'id' | 'tenantAccount' | 'expirationDate' | 'isExpired'
                        >
                    >;
                }
        >;
    };

export type NotaryUpdatesFragment = { __typename: 'NotaryUpdates' } & Pick<
    NotaryUpdates,
    | 'moderationsNum'
    | 'reworksNum'
    | 'activeContestsNum'
    | 'finishedContestsNum'
    | 'rentalsNum'
    | 'waitingContestsNum'
    | 'evictionsNum'
    | 'lockedNum'
    | 'activeBuyRequestsNum'
    | 'finishedBuyRequestsNum'
>;

export type NotifyHistoryNodeFragment = { __typename: 'NotifyHistoryNode' } & Pick<
    NotifyHistoryNode,
    'id' | 'created' | 'notificationType' | 'text' | 'isNew' | 'aptId' | 'emmissionTxid'
>;

export type PublicApartmentListNodeFragment = { __typename: 'ApartmentNode' } & Pick<
    ApartmentNode,
    | 'id'
    | 'status'
    | 'isFavorite'
    | 'isPublic'
    | 'm2'
    | 'rooms'
    | 'isStudio'
    | 'floor'
    | 'number'
    | 'isTokenized'
    | 'communalCurrency'
    | 'totalExpenses'
> & {
        place?: Maybe<{ __typename: 'PlaceNode' } & Pick<PlaceNode, 'id' | 'lat' | 'lon' | 'address'>>;
        city: { __typename: 'CityNode' } & Pick<CityNode, 'id' | 'name' | 'isNew'>;
        photos?: Maybe<Array<Maybe<{ __typename: 'DocumentNode' } & Pick<DocumentNode, 'id' | 'link'>>>>;
        lock?: Maybe<{ __typename: 'LockNode' } & Pick<LockNode, 'reason' | 'reasonType' | 'lockEndDate'>>;
        buyRequest?: Maybe<
            { __typename: 'BuyAptRequestNode' } & Pick<
                BuyAptRequestNode,
                'id' | 'endTime' | 'buyerAccount' | 'feeAmount'
            >
        >;
        actualRental?: Maybe<
            { __typename: 'RentalNode' } & Pick<
                RentalNode,
                | 'id'
                | 'expirationDate'
                | 'canBeExtended'
                | 'isIAmTenant'
                | 'isExpired'
                | 'isEvictionStartedByTenant'
                | 'evictionReason'
            >
        >;
        latestAuction?: Maybe<
            { __typename: 'AuctionNode' } & Pick<
                AuctionNode,
                | 'id'
                | 'endOfActivePhase'
                | 'winnerAccount'
                | 'isWinnerSelectionInProgress'
                | 'hasMyBid'
                | 'isWinnerAgree'
                | 'isIAmWinner'
                | 'startDate'
                | 'deadlineForWinner'
                | 'deadlineToPayCommunal'
            > & {
                    bidsNumber?: Maybe<{ __typename: 'BidsNumber' } & Pick<BidsNumber, 'active' | 'cancelled'>>;
                    paymentInfo?: Maybe<
                        { __typename: 'AuctionPaymentsStruct' } & Pick<AuctionPaymentsStruct, 'payment' | 'token'>
                    >;
                }
        >;
        tokenizationInfo?: Maybe<
            { __typename: 'TokenizationStruct' } & Pick<
                TokenizationStruct,
                'isSignedByNotary' | 'isSignedByOwner' | 'isSignedByWinner'
            >
        >;
    };

export type UserFragment = { __typename: 'UserNode' } & Pick<
    UserNode,
    'id' | 'email' | 'phone' | 'eosAccount' | 'isNotary' | 'hasPassword' | 'hasNewNotifications'
>;

export type AddExpenseMutationVariables = Exact<{
    input: AddExpenseInput;
}>;

export type AddExpenseMutation = { __typename: 'Mutations' } & {
    addExpense?: Maybe<
        | ({ __typename: 'AddExpenseSuccess' } & { expense: { __typename: 'ExpenseNode' } & ManagerExpenseFragment })
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
        | ({ __typename: 'CityNotFoundError' } & Pick<CityNotFoundError, 'errorMessage'>)
    >;
};

export type AddRegionDocumentMutationVariables = Exact<{
    input: AddRegionDocumentInput;
}>;

export type AddRegionDocumentMutation = { __typename: 'Mutations' } & {
    addRegionDocument?: Maybe<
        | ({ __typename: 'AddRegionDocumentSuccess' } & {
              regionDocument: { __typename: 'RegionDocumentNode' } & ManagerDocumentFragment;
          })
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
        | ({ __typename: 'CityNotFoundError' } & Pick<CityNotFoundError, 'errorMessage'>)
    >;
};

export type AddRentalMutationVariables = Exact<{
    input: AddRentalInput;
}>;

export type AddRentalMutation = { __typename: 'Mutations' } & {
    addRental?: Maybe<
        | ({ __typename: 'AddRentalSuccess' } & {
              apt: { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id' | 'status'> & {
                      actualRental?: Maybe<
                          { __typename: 'RentalNode' } & Pick<
                              RentalNode,
                              'id' | 'price' | 'expirationDate' | 'canBeExtended' | 'tenantAccount' | 'tenantChatLink'
                          >
                      >;
                  };
          })
        | ({ __typename: 'AptNotFoundError' } & Pick<AptNotFoundError, 'errorMessage'>)
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
        | ({ __typename: 'ActionNotAllowedForAptStatusError' } & Pick<
              ActionNotAllowedForAptStatusError,
              'errorMessage'
          >)
        | ({ __typename: 'RentalCanNotBeExtendedError' } & Pick<RentalCanNotBeExtendedError, 'errorMessage'>)
    >;
};

export type BlockAptMutationVariables = Exact<{
    input: BlockAptInput;
}>;

export type BlockAptMutation = { __typename: 'Mutations' } & {
    blockApt?: Maybe<
        | ({ __typename: 'BlockAptSuccess' } & { apt: { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id'> })
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
        | ({ __typename: 'AptNotFoundError' } & Pick<AptNotFoundError, 'errorMessage'>)
        | ({ __typename: 'ActionNotAllowedForAptStatusError' } & Pick<
              ActionNotAllowedForAptStatusError,
              'errorMessage'
          >)
        | ({ __typename: 'AddressIsBlockedError' } & Pick<AddressIsBlockedError, 'errorMessage'>)
        | ({ __typename: 'InvalidBlockPeriodError' } & Pick<InvalidBlockPeriodError, 'errorMessage'>)
    >;
};

export type BurnListsMutationVariables = Exact<{
    input: CancelBuyRequestInput;
}>;

export type BurnListsMutation = { __typename: 'Mutations' } & {
    burnLists?: Maybe<
        | ({ __typename: 'BurnListsSuccess' } & {
              buyRequest?: Maybe<{ __typename: 'BuyAptRequestNode' } & Pick<BuyAptRequestNode, 'id' | 'status'>>;
          })
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
        | ({ __typename: 'BuyRequestNotFoundError' } & Pick<BuyRequestNotFoundError, 'errorMessage'>)
        | ({ __typename: 'BuyRequestCancelledError' } & Pick<BuyRequestCancelledError, 'errorMessage'>)
        | ({ __typename: 'BuyRequestNotApprovedError' } & Pick<BuyRequestNotApprovedError, 'errorMessage'>)
        | ({ __typename: 'BuyRequestExecutedError' } & Pick<BuyRequestExecutedError, 'errorMessage'>)
    >;
};

export type CancelBuyRequestMutationVariables = Exact<{
    input: CancelBuyRequestInput;
}>;

export type CancelBuyRequestMutation = { __typename: 'Mutations' } & {
    cancelBuyRequest?: Maybe<
        | ({ __typename: 'CancelBuyRequestSuccess' } & {
              buyRequest?: Maybe<
                  { __typename: 'BuyAptRequestNode' } & Pick<BuyAptRequestNode, 'id' | 'status' | 'endTime'>
              >;
          })
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
        | ({ __typename: 'BuyRequestNotFoundError' } & Pick<BuyRequestNotFoundError, 'errorMessage'>)
        | ({ __typename: 'BuyRequestCancelledError' } & Pick<BuyRequestCancelledError, 'errorMessage'>)
        | ({ __typename: 'BuyRequestExecutedError' } & Pick<BuyRequestExecutedError, 'errorMessage'>)
    >;
};

export type CancelContestAsNotaryMutationVariables = Exact<{
    input: CancelContestInput;
}>;

export type CancelContestAsNotaryMutation = { __typename: 'Mutations' } & {
    cancelContestAsNotary?: Maybe<
        | ({ __typename: 'CancelContestSuccess' } & {
              apt: { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id' | 'status'>;
          })
        | ({ __typename: 'AptNotFoundError' } & Pick<AptNotFoundError, 'errorMessage'>)
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
        | ({ __typename: 'ActionNotAllowedForAptStatusError' } & Pick<
              ActionNotAllowedForAptStatusError,
              'errorMessage' | 'allowedStatuses'
          >)
    >;
};

export type CancelContestAsOwnerMutationVariables = Exact<{
    input: CancelContestInput;
}>;

export type CancelContestAsOwnerMutation = { __typename: 'Mutations' } & {
    cancelContestAsOwner?: Maybe<
        | ({ __typename: 'CancelContestSuccess' } & {
              apt: { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id' | 'status'>;
          })
        | ({ __typename: 'AptNotFoundError' } & Pick<AptNotFoundError, 'errorMessage'>)
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
        | ({ __typename: 'ActionNotAllowedForAptStatusError' } & Pick<
              ActionNotAllowedForAptStatusError,
              'errorMessage' | 'allowedStatuses'
          >)
    >;
};

export type CancelPublicationMutationVariables = Exact<{
    input: CancelPublicationInput;
}>;

export type CancelPublicationMutation = { __typename: 'Mutations' } & {
    cancelPublication?: Maybe<
        | ({ __typename: 'CancelPublicationSuccess' } & {
              apt: { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id' | 'status'>;
          })
        | ({ __typename: 'AptNotFoundError' } & Pick<AptNotFoundError, 'errorMessage'>)
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
        | ({ __typename: 'ActionNotAllowedForAptStatusError' } & Pick<
              ActionNotAllowedForAptStatusError,
              'errorMessage'
          >)
    >;
};

export type ChangeLoginMutationVariables = Exact<{
    input: ChangeLoginInput;
}>;

export type ChangeLoginMutation = { __typename: 'Mutations' } & {
    changeLogin?: Maybe<
        | ({ __typename: 'ChangeLoginSuccess' } & Pick<ChangeLoginSuccess, 'verifyToken'>)
        | ({ __typename: 'InvalidCredentialsError' } & Pick<InvalidCredentialsError, 'errorMessage'>)
        | ({ __typename: 'InvalidPhoneError' } & Pick<InvalidPhoneError, 'errorMessage'>)
        | ({ __typename: 'InvalidEmailError' } & Pick<InvalidEmailError, 'errorMessage'>)
        | ({ __typename: 'UserAlreadyExistsError' } & Pick<UserAlreadyExistsError, 'errorMessage'>)
        | ({ __typename: 'InvalidParamsError' } & Pick<InvalidParamsError, 'errorMessage'>)
    >;
};

export type ChangePasswordMutationVariables = Exact<{
    input: ChangePasswordInput;
}>;

export type ChangePasswordMutation = { __typename: 'Mutations' } & {
    changePassword?: Maybe<
        | ({ __typename: 'ChangePasswordSuccess' } & {
              me: { __typename: 'UserNode' } & Pick<UserNode, 'id' | 'hasPassword'>;
          })
        | ({ __typename: 'InvalidPasswordError' } & Pick<InvalidPasswordError, 'errorMessage'>)
        | ({ __typename: 'InvalidCredentialsError' } & Pick<InvalidCredentialsError, 'errorMessage'>)
    >;
};

export type ConfirmEvictionMutationVariables = Exact<{
    input: ConfirmEvictionInput;
}>;

export type ConfirmEvictionMutation = { __typename: 'Mutations' } & {
    confirmEviction?: Maybe<
        | ({ __typename: 'ConfirmEvictionSuccess' } & {
              apt: { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id' | 'status'>;
          })
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
        | ({ __typename: 'AptNotFoundError' } & Pick<AptNotFoundError, 'errorMessage'>)
        | ({ __typename: 'ActionNotAllowedForAptStatusError' } & Pick<
              ActionNotAllowedForAptStatusError,
              'errorMessage'
          >)
        | ({ __typename: 'EvictionNotStartedError' } & Pick<EvictionNotStartedError, 'errorMessage'>)
    >;
};

export type ConfirmPasswordResetMutationVariables = Exact<{
    input: ConfirmPasswordResetInput;
}>;

export type ConfirmPasswordResetMutation = { __typename: 'Mutations' } & {
    confirmPasswordReset?: Maybe<
        | ({ __typename: 'ConfirmPasswordResetSuccess' } & Pick<ConfirmPasswordResetSuccess, 'token'>)
        | ({ __typename: 'InvalidJWTError' } & Pick<InvalidJwtError, 'errorMessage'>)
        | ({ __typename: 'InvalidVerifyCodeError' } & Pick<InvalidVerifyCodeError, 'errorMessage'>)
        | ({ __typename: 'UserNotFoundError' } & Pick<UserNotFoundError, 'errorMessage'>)
        | ({ __typename: 'InvalidPasswordError' } & Pick<InvalidPasswordError, 'errorMessage'>)
    >;
};

export type ConfirmReadinessAsWinnerMutationVariables = Exact<{
    input: ConfirmReadinessAsWinnerInput;
}>;

export type ConfirmReadinessAsWinnerMutation = { __typename: 'Mutations' } & {
    confirmReadinessAsWinner?: Maybe<
        | ({ __typename: 'ConfirmReadinessAsWinnerSuccess' } & {
              apt: { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id'> & {
                      latestAuction?: Maybe<{ __typename: 'AuctionNode' } & Pick<AuctionNode, 'id' | 'isWinnerAgree'>>;
                  };
          })
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
        | ({ __typename: 'AptNotFoundError' } & Pick<AptNotFoundError, 'errorMessage'>)
        | ({ __typename: 'AptNotTokenizedError' } & Pick<AptNotTokenizedError, 'errorMessage'>)
        | ({ __typename: 'ActionNotAllowedForAptStatusError' } & Pick<
              ActionNotAllowedForAptStatusError,
              'errorMessage'
          >)
    >;
};

export type ConnectByWalletMutationVariables = Exact<{
    input: ConnectByWalletInput;
}>;

export type ConnectByWalletMutation = { __typename: 'Mutations' } & {
    connectByWallet?: Maybe<
        | ({ __typename: 'ConnectByWalletSuccess' } & Pick<
              ConnectByWalletSuccess,
              'qrData' | 'deepLink' | 'verificationCode'
          >)
        | ({ __typename: 'DeeplinkNotGeneratedError' } & Pick<DeeplinkNotGeneratedError, 'errorMessage'>)
    >;
};

export type DeleteAccountMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteAccountMutation = { __typename: 'Mutations' } & {
    deleteAccount?: Maybe<{ __typename: 'DeleteAccountSuccess' } & Pick<DeleteAccountSuccess, 'ok'>>;
};

export type DeleteApartmentMutationVariables = Exact<{
    input: DeleteApartmentInput;
}>;

export type DeleteApartmentMutation = { __typename: 'Mutations' } & {
    deleteApartment?: Maybe<
        | ({ __typename: 'DeleteApartmentSuccess' } & {
              deletedApt: { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id'>;
          })
        | ({ __typename: 'AptNotFoundError' } & Pick<AptNotFoundError, 'errorMessage'>)
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
        | ({ __typename: 'ActionNotAllowedForAptStatusError' } & Pick<
              ActionNotAllowedForAptStatusError,
              'errorMessage'
          >)
    >;
};

export type DeleteDocumentMutationVariables = Exact<{
    input: DeleteDocumentInput;
}>;

export type DeleteDocumentMutation = { __typename: 'Mutations' } & {
    deleteDocument?: Maybe<
        | { __typename: 'DeleteDocumentSuccess' }
        | ({ __typename: 'AptNotFoundError' } & Pick<AptNotFoundError, 'errorMessage'>)
        | ({ __typename: 'DocumentNotFoundError' } & Pick<DocumentNotFoundError, 'errorMessage'>)
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
    >;
};

export type DeleteExpenseMutationVariables = Exact<{
    input: DeleteExpenseInput;
}>;

export type DeleteExpenseMutation = { __typename: 'Mutations' } & {
    deleteExpense?: Maybe<
        | ({ __typename: 'DeleteExpenseSuccess' } & Pick<DeleteExpenseSuccess, 'ok'>)
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
        | ({ __typename: 'ExpenseNotFoundError' } & Pick<ExpenseNotFoundError, 'errorMessage'>)
    >;
};

export type DeleteRegionDocumentMutationVariables = Exact<{
    input: DeleteRegionDocumentInput;
}>;

export type DeleteRegionDocumentMutation = { __typename: 'Mutations' } & {
    deleteRegionDocument?: Maybe<
        | ({ __typename: 'DeleteRegionDocumentSuccess' } & Pick<DeleteRegionDocumentSuccess, 'ok'>)
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
        | ({ __typename: 'RegionDocumentNotFoundError' } & Pick<RegionDocumentNotFoundError, 'errorMessage'>)
    >;
};

export type MarkAllNotificationsAsReadMutationVariables = Exact<{ [key: string]: never }>;

export type MarkAllNotificationsAsReadMutation = { __typename: 'Mutations' } & {
    markAllNotificationsAsRead?: Maybe<
        { __typename: 'MarkAllNotificationsAsReadSuccess' } & {
            me: { __typename: 'UserNode' } & Pick<UserNode, 'id' | 'countNewNotifications'>;
        }
    >;
};

export type MarkApartmentAsViewedMutationVariables = Exact<{
    input: MarkApartmentAsViewedInput;
}>;

export type MarkApartmentAsViewedMutation = { __typename: 'Mutations' } & {
    markApartmentAsViewed?: Maybe<
        | ({ __typename: 'MarkApartmentAsViewedSuccess' } & {
              apt: { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id' | 'isNewForRd'>;
          })
        | ({ __typename: 'AptNotFoundError' } & Pick<AptNotFoundError, 'errorMessage'>)
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
    >;
};

export type MarkBuyRequestAsViewedMutationVariables = Exact<{
    input: MarkBuyRequestAsViewedInput;
}>;

export type MarkBuyRequestAsViewedMutation = { __typename: 'Mutations' } & {
    markBuyRequestAsViewed?: Maybe<
        | ({ __typename: 'MarkBuyRequestAsViewedSuccess' } & {
              buyRequest: { __typename: 'BuyAptRequestNode' } & Pick<BuyAptRequestNode, 'id' | 'isNewForRd'>;
          })
        | ({ __typename: 'BuyRequestNotFoundError' } & Pick<BuyRequestNotFoundError, 'errorMessage'>)
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
    >;
};

export type MarkNotificationAsReadMutationVariables = Exact<{
    input: MarkNotificationAsReadInput;
}>;

export type MarkNotificationAsReadMutation = { __typename: 'Mutations' } & {
    markNotificationAsRead?: Maybe<
        | ({ __typename: 'MarkNotificationAsReadSuccess' } & {
              notification?: Maybe<{ __typename: 'NotifyHistoryNode' } & Pick<NotifyHistoryNode, 'id' | 'isNew'>>;
              me: { __typename: 'UserNode' } & Pick<UserNode, 'id' | 'countNewNotifications'>;
          })
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
        | ({ __typename: 'NotificationNotFoundError' } & Pick<NotificationNotFoundError, 'errorMessage'>)
    >;
};

export type PublishApartmentAptDataFragment = { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id' | 'status'> & {
        emissionVolume?: Maybe<{ __typename: 'VolumeStruct' } & Pick<VolumeStruct, 'amount' | 'token' | 'fee'>>;
    };

export type PublishApartmentMutationVariables = Exact<{
    input: PublishApartmentInput;
}>;

export type PublishApartmentMutation = { __typename: 'Mutations' } & {
    publishApartment?: Maybe<
        | ({ __typename: 'PublishApartmentSuccess' } & {
              apt: { __typename: 'ApartmentNode' } & PublishApartmentAptDataFragment;
          })
        | ({ __typename: 'AptNotFoundError' } & Pick<AptNotFoundError, 'errorMessage'>)
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
        | ({ __typename: 'ActionNotAllowedForAptStatusError' } & Pick<
              ActionNotAllowedForAptStatusError,
              'errorMessage'
          >)
        | ({ __typename: 'AddressIsBlockedError' } & Pick<AddressIsBlockedError, 'errorMessage'>)
        | ({ __typename: 'ApartmentAlreadyExistsError' } & Pick<ApartmentAlreadyExistsError, 'errorMessage'>)
        | ({ __typename: 'MissedRequiredFieldError' } & Pick<MissedRequiredFieldError, 'errorMessage'>)
    >;
};

export type RefuseToWinMutationVariables = Exact<{
    input: RefuseToWinInput;
}>;

export type RefuseToWinMutation = { __typename: 'Mutations' } & {
    refuseToWin?: Maybe<
        | ({ __typename: 'RefuseToWinSuccess' } & {
              apt: { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id' | 'status'> & {
                      latestAuction?: Maybe<{ __typename: 'AuctionNode' } & Pick<AuctionNode, 'id' | 'winnerAccount'>>;
                  };
          })
        | ({ __typename: 'AptNotFoundError' } & Pick<AptNotFoundError, 'errorMessage'>)
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
        | ({ __typename: 'ActionNotAllowedForAptStatusError' } & Pick<
              ActionNotAllowedForAptStatusError,
              'errorMessage'
          >)
    >;
};

export type RemoveLoginMutationVariables = Exact<{
    input: RemoveLoginInput;
}>;

export type RemoveLoginMutation = { __typename: 'Mutations' } & {
    removeLogin?: Maybe<
        | ({ __typename: 'RemoveLoginSuccess' } & {
              me?: Maybe<{ __typename: 'UserNode' } & Pick<UserNode, 'id' | 'email' | 'phone' | 'eosAccount'>>;
          })
        | ({ __typename: 'CantDeleteLoginError' } & Pick<CantDeleteLoginError, 'errorMessage'>)
    >;
};

export type ReportAddressCheckErrorMutationVariables = Exact<{
    input: ReportAddressCheckErrorInput;
}>;

export type ReportAddressCheckErrorMutation = { __typename: 'Mutations' } & {
    reportAddressCheckError?: Maybe<
        | ({ __typename: 'ReportAddressCheckErrorSuccess' } & Pick<ReportAddressCheckErrorSuccess, 'ok'>)
        | ({ __typename: 'PlaceNotFoundError' } & Pick<PlaceNotFoundError, 'errorMessage'>)
        | ({ __typename: 'UserNotFoundError' } & Pick<UserNotFoundError, 'errorMessage'>)
        | ({ __typename: 'AddressAlreadyInAreaError' } & Pick<AddressAlreadyInAreaError, 'errorMessage'>)
        | ({ __typename: 'ReportAlreadyRegisteredError' } & Pick<ReportAlreadyRegisteredError, 'errorMessage'>)
    >;
};

export type ReportMisCoordsErrorMutationVariables = Exact<{
    input: ReportMisCoordsErrorInput;
}>;

export type ReportMisCoordsErrorMutation = { __typename: 'Mutations' } & {
    reportMisCoordsError?: Maybe<
        | ({ __typename: 'ReportMisCoordsErrorSuccess' } & Pick<ReportMisCoordsErrorSuccess, 'ok'>)
        | ({ __typename: 'PlaceNotFoundError' } & Pick<PlaceNotFoundError, 'errorMessage'>)
        | ({ __typename: 'ReportAlreadyRegisteredError' } & Pick<ReportAlreadyRegisteredError, 'errorMessage'>)
    >;
};

export type ResendCodeMutationVariables = Exact<{ [key: string]: never }>;

export type ResendCodeMutation = { __typename: 'Mutations' } & {
    resendCode?: Maybe<
        | ({ __typename: 'ResendCodeSuccess' } & Pick<ResendCodeSuccess, 'verifyToken'>)
        | ({ __typename: 'InvalidJWTError' } & Pick<InvalidJwtError, 'errorMessage'>)
        | ({ __typename: 'NoResendAttemptsError' } & Pick<NoResendAttemptsError, 'errorMessage'>)
        | { __typename: 'TrySendCodeTooEarlyError' }
    >;
};

export type ResetPasswordMutationVariables = Exact<{
    input: ResetPasswordInput;
}>;

export type ResetPasswordMutation = { __typename: 'Mutations' } & {
    resetPassword?: Maybe<
        | ({ __typename: 'ResetPasswordSuccess' } & Pick<ResetPasswordSuccess, 'verifyToken' | 'nextStep'>)
        | ({ __typename: 'LoginIsEmptyError' } & Pick<LoginIsEmptyError, 'errorMessage'>)
        | ({ __typename: 'UserNotFoundError' } & Pick<UserNotFoundError, 'errorMessage'>)
        | ({ __typename: 'NoResendAttemptsError' } & Pick<NoResendAttemptsError, 'errorMessage'>)
        | ({ __typename: 'InvalidPhoneError' } & Pick<InvalidPhoneError, 'errorMessage'>)
        | ({ __typename: 'InvalidEmailError' } & Pick<InvalidEmailError, 'errorMessage'>)
    >;
};

export type ResolveReviewMutationVariables = Exact<{
    input: ReviewApartmentInput;
}>;

export type ResolveReviewMutation = { __typename: 'Mutations' } & {
    resolveReview?: Maybe<
        | ({ __typename: 'ResolveReviewSuccess' } & {
              apt: { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id'> & {
                      review?: Maybe<{ __typename: 'AptReviewNode' } & ApartmentReviewFragment>;
                  };
          })
        | ({ __typename: 'AptNotFoundError' } & Pick<AptNotFoundError, 'errorMessage'>)
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
        | ({ __typename: 'ActionNotAllowedForAptStatusError' } & Pick<
              ActionNotAllowedForAptStatusError,
              'errorMessage'
          >)
        | ({ __typename: 'ReviewNotFoundError' } & Pick<ReviewNotFoundError, 'errorMessage'>)
    >;
};

export type ReviewApartmentMutationVariables = Exact<{
    input: ReviewApartmentInput;
}>;

export type ReviewApartmentMutation = { __typename: 'Mutations' } & {
    reviewApartment?: Maybe<
        | ({ __typename: 'ReviewApartmentSuccess' } & {
              apt: { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id' | 'status'>;
          })
        | ({ __typename: 'AptNotFoundError' } & Pick<AptNotFoundError, 'errorMessage'>)
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
        | ({ __typename: 'ActionNotAllowedForAptStatusError' } & Pick<
              ActionNotAllowedForAptStatusError,
              'errorMessage'
          >)
    >;
};

export type SaveApartmentMutationVariables = Exact<{
    input: SaveApartmentInput;
}>;

export type SaveApartmentMutation = { __typename: 'Mutations' } & {
    saveApartment?: Maybe<
        | ({ __typename: 'SaveApartmentSuccess' } & {
              apartment: { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id' | 'status'>;
          })
        | ({ __typename: 'AptNotFoundError' } & Pick<AptNotFoundError, 'errorMessage'>)
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
        | ({ __typename: 'OutOfPolygonError' } & Pick<OutOfPolygonError, 'errorMessage'>)
        | ({ __typename: 'PlaceNotFoundError' } & Pick<PlaceNotFoundError, 'errorMessage'>)
        | ({ __typename: 'AddressIsNotFullError' } & Pick<AddressIsNotFullError, 'errorMessage'>)
        | ({ __typename: 'AddressIsBlockedError' } & Pick<AddressIsBlockedError, 'errorMessage'>)
        | ({ __typename: 'EosAccountNotExistsError' } & Pick<EosAccountNotExistsError, 'errorMessage'>)
        | ({ __typename: 'InvalidCommunalPaymentError' } & Pick<InvalidCommunalPaymentError, 'errorMessage'>)
    >;
};

export type SetLanguageMutationVariables = Exact<{
    input: SetLanguageInput;
}>;

export type SetLanguageMutation = { __typename: 'Mutations' } & {
    setLanguage?: Maybe<{ __typename: 'SetLanguageSuccess' } & Pick<SetLanguageSuccess, 'ok'>>;
};

export type SignInMutationVariables = Exact<{
    input: SigninInput;
}>;

export type SignInMutation = { __typename: 'Mutations' } & {
    signin?: Maybe<
        | ({ __typename: 'SigninSuccess' } & Pick<SigninSuccess, 'token'>)
        | ({ __typename: 'LoginIsEmptyError' } & Pick<LoginIsEmptyError, 'errorMessage'>)
        | ({ __typename: 'InvalidCredentialsError' } & Pick<InvalidCredentialsError, 'errorMessage'>)
    >;
};

export type SignupMutationVariables = Exact<{
    input: SignupInput;
}>;

export type SignupMutation = { __typename: 'Mutations' } & {
    signup?: Maybe<
        | ({ __typename: 'SignupSuccess' } & Pick<SignupSuccess, 'verifyToken' | 'nextStep'>)
        | ({ __typename: 'LoginIsEmptyError' } & Pick<LoginIsEmptyError, 'errorMessage'>)
        | ({ __typename: 'UserAlreadyExistsError' } & Pick<UserAlreadyExistsError, 'errorMessage'>)
        | ({ __typename: 'InvalidEmailError' } & Pick<InvalidEmailError, 'errorMessage'>)
        | ({ __typename: 'InvalidPhoneError' } & Pick<InvalidPhoneError, 'errorMessage'>)
        | ({ __typename: 'InvalidPasswordError' } & Pick<InvalidPasswordError, 'errorMessage'>)
        | ({ __typename: 'NoResendAttemptsError' } & Pick<NoResendAttemptsError, 'errorMessage'>)
    >;
};

export type StartAuctionMutationVariables = Exact<{
    input: StartAuctionInput;
}>;

export type StartAuctionMutation = { __typename: 'Mutations' } & {
    startAuction?: Maybe<
        | ({ __typename: 'StartAuctionSuccess' } & { apt: { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id'> })
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
        | ({ __typename: 'AptNotFoundError' } & Pick<AptNotFoundError, 'errorMessage'>)
        | ({ __typename: 'AptNotReviewedError' } & Pick<AptNotReviewedError, 'errorMessage'>)
        | ({ __typename: 'ActionNotAllowedForAptStatusError' } & Pick<
              ActionNotAllowedForAptStatusError,
              'errorMessage'
          >)
        | ({ __typename: 'InvalidExpenseValueError' } & Pick<InvalidExpenseValueError, 'errorMessage'>)
        | ({ __typename: 'ExpenseNotFoundError' } & Pick<ExpenseNotFoundError, 'errorMessage'>)
        | ({ __typename: 'InvalidExpensesListError' } & Pick<InvalidExpensesListError, 'errorMessage'>)
        | ({ __typename: 'InvalidCommunalPaymentError' } & Pick<InvalidCommunalPaymentError, 'errorMessage'>)
    >;
};

export type StartEvictionAsNotaryMutationVariables = Exact<{
    input: StartEvictionInput;
}>;

export type StartEvictionAsNotaryMutation = { __typename: 'Mutations' } & {
    startEvictionAsNotary?: Maybe<
        | ({ __typename: 'StartEvictionSuccess' } & {
              apt: { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id' | 'status'>;
          })
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
        | ({ __typename: 'AptNotFoundError' } & Pick<AptNotFoundError, 'errorMessage'>)
        | ({ __typename: 'ActionNotAllowedForAptStatusError' } & Pick<
              ActionNotAllowedForAptStatusError,
              'errorMessage'
          >)
        | ({ __typename: 'EvictionAlreadyStartedError' } & Pick<EvictionAlreadyStartedError, 'errorMessage'>)
        | ({ __typename: 'InvalidParamsError' } & Pick<InvalidParamsError, 'errorMessage'>)
    >;
};

export type StartEvictionAsTenantMutationVariables = Exact<{
    input: StartEvictionInput;
}>;

export type StartEvictionAsTenantMutation = { __typename: 'Mutations' } & {
    startEvictionAsTenant?: Maybe<
        | ({ __typename: 'StartEvictionSuccess' } & {
              apt: { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id' | 'status'>;
          })
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
        | ({ __typename: 'AptNotFoundError' } & Pick<AptNotFoundError, 'errorMessage'>)
        | ({ __typename: 'ActionNotAllowedForAptStatusError' } & Pick<
              ActionNotAllowedForAptStatusError,
              'errorMessage'
          >)
        | ({ __typename: 'EvictionAlreadyStartedError' } & Pick<EvictionAlreadyStartedError, 'errorMessage'>)
        | ({ __typename: 'InvalidParamsError' } & Pick<InvalidParamsError, 'errorMessage'>)
    >;
};

export type UnblockAptMutationVariables = Exact<{
    input: UnblockAptInput;
}>;

export type UnblockAptMutation = { __typename: 'Mutations' } & {
    unblockApt?: Maybe<
        | ({ __typename: 'UnblockAptSuccess' } & { apt: { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id'> })
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
        | ({ __typename: 'AptNotFoundError' } & Pick<AptNotFoundError, 'errorMessage'>)
        | ({ __typename: 'ActionNotAllowedForAptStatusError' } & Pick<
              ActionNotAllowedForAptStatusError,
              'errorMessage'
          >)
    >;
};

export type UpdateAptFavoritesMutationVariables = Exact<{
    input: UpdateAptFavoriteInput;
}>;

export type UpdateAptFavoritesMutation = { __typename: 'Mutations' } & {
    updateAptFavorites?: Maybe<
        | ({ __typename: 'UpdateAptFavoriteSuccess' } & {
              apt: { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id' | 'isFavorite'>;
          })
        | ({ __typename: 'AptNotFoundError' } & Pick<AptNotFoundError, 'errorMessage'>)
    >;
};

export type UpdateAptRealIdMutationVariables = Exact<{
    input: UpdateAptRealIdInput;
}>;

export type UpdateAptRealIdMutation = { __typename: 'Mutations' } & {
    updateAptRealId?: Maybe<
        | ({ __typename: 'UpdateAptRealIdSuccess' } & {
              apt: { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id' | 'realId'>;
          })
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
        | ({ __typename: 'AptNotFoundError' } & Pick<AptNotFoundError, 'errorMessage'>)
        | ({ __typename: 'ActionNotAllowedForAptStatusError' } & Pick<
              ActionNotAllowedForAptStatusError,
              'errorMessage'
          >)
    >;
};

export type UpdateExpenseMutationVariables = Exact<{
    input: UpdateExpenseInput;
}>;

export type UpdateExpenseMutation = { __typename: 'Mutations' } & {
    updateExpense?: Maybe<
        | ({ __typename: 'UpdateExpenseSuccess' } & { expense: { __typename: 'ExpenseNode' } & ManagerExpenseFragment })
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
        | ({ __typename: 'ExpenseNotFoundError' } & Pick<ExpenseNotFoundError, 'errorMessage'>)
    >;
};

export type UpdateNotifySettingsMutationVariables = Exact<{
    input: UpdateNotifySettingsInput;
}>;

export type UpdateNotifySettingsMutation = { __typename: 'Mutations' } & {
    updateNotifySettings?: Maybe<
        { __typename: 'UpdateNotifySettingsSuccess' } & {
            me: { __typename: 'UserNode' } & MeNotificationSettingsFragmentFragment;
        }
    >;
};

export type UpdatePhotosOrderMutationVariables = Exact<{
    input: UpdatePhotosOrderInput;
}>;

export type UpdatePhotosOrderMutation = { __typename: 'Mutations' } & {
    updatePhotosOrder?: Maybe<
        | { __typename: 'UpdatePhotosOrderSuccess' }
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
        | ({ __typename: 'AptNotFoundError' } & Pick<AptNotFoundError, 'errorMessage'>)
        | ({ __typename: 'ActionNotAllowedForAptStatusError' } & Pick<
              ActionNotAllowedForAptStatusError,
              'errorMessage'
          >)
        | ({ __typename: 'DocumentNotFoundError' } & Pick<DocumentNotFoundError, 'errorMessage'>)
    >;
};

export type UpdateRdContactsMutationVariables = Exact<{
    input: UpdateRdContactsInput;
}>;

export type UpdateRdContactsMutation = { __typename: 'Mutations' } & {
    updateRdContacts?: Maybe<
        | ({ __typename: 'UpdateRdContactsSuccess' } & {
              city: { __typename: 'CityNode' } & Pick<CityNode, 'id' | 'notaryContactEmail' | 'notaryContactPhone'>;
          })
        | ({ __typename: 'InvalidPhoneError' } & Pick<InvalidPhoneError, 'errorMessage'>)
        | ({ __typename: 'InvalidEmailError' } & Pick<InvalidEmailError, 'errorMessage'>)
        | ({ __typename: 'CityNotFoundError' } & Pick<CityNotFoundError, 'errorMessage'>)
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
    >;
};

export type UpdateRegionDocumentMutationVariables = Exact<{
    input: UpdateRegionDocumentInput;
}>;

export type UpdateRegionDocumentMutation = { __typename: 'Mutations' } & {
    updateRegionDocument?: Maybe<
        | ({ __typename: 'UpdateRegionDocumentSuccess' } & {
              regionDocument: { __typename: 'RegionDocumentNode' } & ManagerDocumentFragment;
          })
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
        | ({ __typename: 'RegionDocumentNotFoundError' } & Pick<RegionDocumentNotFoundError, 'errorMessage'>)
    >;
};

export type VerifyCodeMutationVariables = Exact<{
    input: VerifyCodeInput;
}>;

export type VerifyCodeMutation = { __typename: 'Mutations' } & {
    verifyCode?: Maybe<
        | ({ __typename: 'VerifyCodeSuccess' } & Pick<VerifyCodeSuccess, 'token'> & {
                  me?: Maybe<{ __typename: 'UserNode' } & Pick<UserNode, 'id' | 'email' | 'phone' | 'eosAccount'>>;
              })
        | ({ __typename: 'InvalidJWTError' } & Pick<InvalidJwtError, 'errorMessage'>)
        | ({ __typename: 'InvalidVerifyCodeError' } & Pick<InvalidVerifyCodeError, 'errorMessage'>)
        | ({ __typename: 'VerifyCodeExpiredError' } & Pick<VerifyCodeExpiredError, 'errorMessage'>)
        | ({ __typename: 'UserAlreadyExistsError' } & Pick<UserAlreadyExistsError, 'errorMessage'>)
        | ({ __typename: 'VerifyCodeNoCheckAttemptsError' } & Pick<VerifyCodeNoCheckAttemptsError, 'errorMessage'>)
    >;
};

export type WithdrawFromContestMutationVariables = Exact<{
    input: WithdrawFromContestInput;
}>;

export type WithdrawFromContestMutation = { __typename: 'Mutations' } & {
    withdrawFromContest?: Maybe<
        | ({ __typename: 'WithdrawFromContestSuccess' } & {
              apt: { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id' | 'status' | 'number'> & {
                      latestAuction?: Maybe<{ __typename: 'AuctionNode' } & Pick<AuctionNode, 'id' | 'hasMyBid'>>;
                  };
          })
        | ({ __typename: 'AptNotFoundError' } & Pick<AptNotFoundError, 'errorMessage'>)
        | ({ __typename: 'HasNoPermissionError' } & Pick<HasNoPermissionError, 'errorMessage'>)
        | ({ __typename: 'ActionNotAllowedForAptStatusError' } & Pick<
              ActionNotAllowedForAptStatusError,
              'errorMessage'
          >)
        | ({ __typename: 'ContestPaymentNotFoundError' } & Pick<ContestPaymentNotFoundError, 'errorMessage'>)
    >;
};

export type AddApartmentConfigQueryVariables = Exact<{ [key: string]: never }>;

export type AddApartmentConfigQuery = { __typename: 'Query' } & {
    addApartmentConfig?: Maybe<
        { __typename: 'AddApartmentConfig' } & Pick<AddApartmentConfig, 'minPhotosQuantity' | 'maxUploadFileSize'>
    >;
};

export type AddBuyoutRequestParamsQueryVariables = Exact<{ [key: string]: never }>;

export type AddBuyoutRequestParamsQuery = { __typename: 'Query' } & {
    cities?: Maybe<
        Array<
            Maybe<
                { __typename: 'CityNode' } & Pick<CityNode, 'id' | 'name'> & {
                        token?: Maybe<{ __typename: 'TokenNode' } & Pick<TokenNode, 'name'>>;
                    }
            >
        >
    >;
    buyRequestConfig?: Maybe<
        { __typename: 'BuyRequestConfig' } & Pick<BuyRequestConfig, 'feePercent' | 'minTransferVolume'>
    >;
};

export type ApartmentQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type ApartmentQuery = { __typename: 'Query' } & {
    apartment?: Maybe<
        { __typename: 'ApartmentNode' } & Pick<
            ApartmentNode,
            | 'id'
            | 'realId'
            | 'description'
            | 'status'
            | 'isFavorite'
            | 'floor'
            | 'rooms'
            | 'isStudio'
            | 'm2'
            | 'livingM2'
            | 'kitchenM2'
            | 'isPublic'
            | 'ceilingHeight'
            | 'hasYardWindows'
            | 'hasStreetWindows'
            | 'combinedBaths'
            | 'dividedBaths'
            | 'balconies'
            | 'hasLoggia'
            | 'number'
            | 'numberOfFloors'
            | 'hasElevator'
            | 'hasCargoElevator'
            | 'hasGarbageChute'
            | 'hasSecurity'
            | 'hasConcierge'
            | 'hasIntercom'
            | 'hasVideoSurveillance'
            | 'houseType'
            | 'buildingYear'
            | 'hasGas'
            | 'hasGroundParking'
            | 'hasUndergroundParking'
            | 'hasMultilevelParking'
            | 'tenure'
            | 'aptType'
            | 'ownerAccount'
            | 'refundAccount'
            | 'isIAmOwner'
            | 'isIAmNotary'
            | 'communalCurrency'
            | 'totalExpenses'
            | 'housingComplex'
            | 'isTokenized'
        > & {
                buyRequest?: Maybe<
                    { __typename: 'BuyAptRequestNode' } & Pick<BuyAptRequestNode, 'id' | 'endTime' | 'buyerAccount'>
                >;
                documents?: Maybe<
                    Array<
                        Maybe<
                            { __typename: 'DocumentNode' } & Pick<DocumentNode, 'id' | 'link' | 'name' | 'originalName'>
                        >
                    >
                >;
                place?: Maybe<{ __typename: 'PlaceNode' } & Pick<PlaceNode, 'id' | 'address' | 'lon' | 'lat'>>;
                city: { __typename: 'CityNode' } & Pick<CityNode, 'id' | 'name' | 'isNew'> & {
                        country?: Maybe<{ __typename: 'CountryNode' } & Pick<CountryNode, 'id' | 'name'>>;
                    };
                subways?: Maybe<
                    Array<
                        Maybe<
                            { __typename: 'RoadToSubwayNode' } & Pick<RoadToSubwayNode, 'id' | 'way' | 'minutes'> & {
                                    subway?: Maybe<{ __typename: 'SubwayNode' } & Pick<SubwayNode, 'id' | 'name'>>;
                                }
                        >
                    >
                >;
                actualRental?: Maybe<
                    { __typename: 'RentalNode' } & Pick<
                        RentalNode,
                        | 'id'
                        | 'expirationDate'
                        | 'isExpired'
                        | 'canBeExtended'
                        | 'isIAmTenant'
                        | 'isEvictionStartedByTenant'
                        | 'evictionReason'
                    >
                >;
                tokenizationInfo?: Maybe<
                    { __typename: 'TokenizationStruct' } & Pick<
                        TokenizationStruct,
                        'isSignedByOwner' | 'isSignedByWinner' | 'isSignedByNotary'
                    >
                >;
                latestAuction?: Maybe<
                    { __typename: 'AuctionNode' } & Pick<
                        AuctionNode,
                        | 'id'
                        | 'winnerAccount'
                        | 'hasMyBid'
                        | 'isIAmWinner'
                        | 'isWinnerAgree'
                        | 'deadlineForWinner'
                        | 'deadlineToPayCommunal'
                        | 'startDate'
                        | 'endOfActivePhase'
                        | 'isWinnerSelectionInProgress'
                    > & {
                            bidsNumber?: Maybe<{ __typename: 'BidsNumber' } & Pick<BidsNumber, 'active' | 'cancelled'>>;
                            paymentInfo?: Maybe<
                                { __typename: 'AuctionPaymentsStruct' } & Pick<
                                    AuctionPaymentsStruct,
                                    'payment' | 'token'
                                >
                            >;
                        }
                >;
                lock?: Maybe<{ __typename: 'LockNode' } & Pick<LockNode, 'reason' | 'reasonType' | 'lockEndDate'>>;
                photos?: Maybe<Array<Maybe<{ __typename: 'DocumentNode' } & Pick<DocumentNode, 'id' | 'link'>>>>;
            }
    >;
};

export type ApartmentsQueryVariables = Exact<{
    filters?: Maybe<ApartmentFilterInput>;
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    favorites?: Maybe<Scalars['Boolean']>;
    sort?: Maybe<Array<Maybe<ApartmentSortEnum>> | Maybe<ApartmentSortEnum>>;
    onlyTotalCount?: Maybe<Scalars['Boolean']>;
}>;

export type ApartmentsQuery = { __typename: 'Query' } & {
    apartments: { __typename: 'ApartmentListNode' } & {
        pageInfo: { __typename: 'PageInfo' } & Pick<PageInfo, 'total'>;
        apartments?: Maybe<Array<Maybe<{ __typename: 'ApartmentNode' } & PublicApartmentListNodeFragment>>>;
    };
};

export type ApartmentAddRentalParamsQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type ApartmentAddRentalParamsQuery = { __typename: 'Query' } & {
    apartment?: Maybe<
        { __typename: 'ApartmentNode' } & Pick<
            ApartmentNode,
            'id' | 'communalPayment' | 'communalCurrency' | 'totalExpenses' | 'number'
        > & {
                latestAuction?: Maybe<
                    { __typename: 'AuctionNode' } & Pick<AuctionNode, 'id' | 'winnerAccount' | 'endDate'>
                >;
                actualRental?: Maybe<
                    { __typename: 'RentalNode' } & Pick<
                        RentalNode,
                        | 'id'
                        | 'tenantAccount'
                        | 'price'
                        | 'startDate'
                        | 'expirationDate'
                        | 'expirationAfterExtend'
                        | 'renewStartTime'
                    >
                >;
                place?: Maybe<{ __typename: 'PlaceNode' } & Pick<PlaceNode, 'id' | 'address'>>;
                city: { __typename: 'CityNode' } & Pick<CityNode, 'id' | 'name'>;
            }
    >;
    aptExpenses?: Maybe<
        Array<
            Maybe<
                { __typename: 'AptExpenseNode' } & Pick<AptExpenseNode, 'value'> & {
                        expense: { __typename: 'ExpenseNode' } & Pick<ExpenseNode, 'id' | 'name'>;
                    }
            >
        >
    >;
};

export type ApartmentAutocompleteQueryVariables = Exact<{
    filters?: Maybe<ApartmentFilterInput>;
}>;

export type ApartmentAutocompleteQuery = { __typename: 'Query' } & {
    apartments: { __typename: 'ApartmentListNode' } & {
        apartments: Array<
            Maybe<
                { __typename: 'ApartmentNode' } & Pick<
                    ApartmentNode,
                    | 'id'
                    | 'houseType'
                    | 'buildingYear'
                    | 'housingComplex'
                    | 'numberOfFloors'
                    | 'hasElevator'
                    | 'hasCargoElevator'
                    | 'hasGarbageChute'
                    | 'hasSecurity'
                    | 'hasConcierge'
                    | 'hasIntercom'
                    | 'hasVideoSurveillance'
                    | 'hasGas'
                    | 'hasGroundParking'
                    | 'hasUndergroundParking'
                    | 'hasMultilevelParking'
                >
            >
        >;
    };
};

export type ApartmentContetsPaymentDetailsQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type ApartmentContetsPaymentDetailsQuery = { __typename: 'Query' } & {
    apartment?: Maybe<
        { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id'> & {
                latestAuction?: Maybe<
                    { __typename: 'AuctionNode' } & Pick<AuctionNode, 'id' | 'paymentDeepLink' | 'paymentJson'> & {
                            paymentInfo?: Maybe<
                                { __typename: 'AuctionPaymentsStruct' } & Pick<
                                    AuctionPaymentsStruct,
                                    | 'payment'
                                    | 'paymentAfterTax'
                                    | 'memo'
                                    | 'smartContract'
                                    | 'token'
                                    | 'platformAccount'
                                >
                            >;
                        }
                >;
            }
    >;
};

export type ApartmentEmissionParamsQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type ApartmentEmissionParamsQuery = { __typename: 'Query' } & {
    apartment?: Maybe<
        { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id' | 'realId' | 'number'> & {
                emissionVolume?: Maybe<{ __typename: 'VolumeStruct' } & Pick<VolumeStruct, 'amount' | 'token' | 'fee'>>;
                buyRequest?: Maybe<
                    { __typename: 'BuyAptRequestNode' } & Pick<BuyAptRequestNode, 'tokenId' | 'feeAmount'>
                >;
                place?: Maybe<{ __typename: 'PlaceNode' } & Pick<PlaceNode, 'id' | 'address'>>;
                city: { __typename: 'CityNode' } & Pick<CityNode, 'id' | 'name'>;
                tokenizationInfo?: Maybe<
                    { __typename: 'TokenizationStruct' } & Pick<TokenizationStruct, 'emissionDeepLink'>
                >;
            }
    >;
};

export type ApartmentExpensesQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type ApartmentExpensesQuery = { __typename: 'Query' } & {
    apartment?: Maybe<
        { __typename: 'ApartmentNode' } & Pick<
            ApartmentNode,
            'id' | 'communalCurrency' | 'communalPayment' | 'totalExpenses'
        >
    >;
    aptExpenses?: Maybe<
        Array<
            Maybe<
                { __typename: 'AptExpenseNode' } & Pick<AptExpenseNode, 'value'> & {
                        expense: { __typename: 'ExpenseNode' } & Pick<ExpenseNode, 'id' | 'name'>;
                    }
            >
        >
    >;
};

export type ApartmentRealIdQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type ApartmentRealIdQuery = { __typename: 'Query' } & {
    apartment?: Maybe<{ __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id' | 'realId'>>;
};

export type ApartmentSignTokenizationParamsQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type ApartmentSignTokenizationParamsQuery = { __typename: 'Query' } & {
    apartment?: Maybe<
        { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id' | 'ownerAccount' | 'realId' | 'number'> & {
                buyRequest?: Maybe<
                    { __typename: 'BuyAptRequestNode' } & Pick<BuyAptRequestNode, 'tokenId' | 'feeAmount'>
                >;
                emissionVolume?: Maybe<{ __typename: 'VolumeStruct' } & Pick<VolumeStruct, 'amount' | 'token' | 'fee'>>;
                place?: Maybe<{ __typename: 'PlaceNode' } & Pick<PlaceNode, 'id' | 'address'>>;
                city: { __typename: 'CityNode' } & Pick<CityNode, 'id' | 'name' | 'notaryEosAccount'>;
                latestAuction?: Maybe<{ __typename: 'AuctionNode' } & Pick<AuctionNode, 'id' | 'winnerAccount'>>;
                tokenizationInfo?: Maybe<
                    { __typename: 'TokenizationStruct' } & Pick<
                        TokenizationStruct,
                        | 'isSignedByOwner'
                        | 'isSignedByWinner'
                        | 'isSignedByNotary'
                        | 'winnerSignDeepLink'
                        | 'ownerSignDeepLink'
                        | 'notarySignDeepLink'
                    >
                >;
            }
    >;
};

export type AuctionParticipantsQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type AuctionParticipantsQuery = { __typename: 'Query' } & {
    apartment?: Maybe<
        { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id' | 'status' | 'isTokenized'> & {
                latestAuction?: Maybe<
                    { __typename: 'AuctionNode' } & Pick<
                        AuctionNode,
                        | 'id'
                        | 'endOfActivePhase'
                        | 'winnerAccount'
                        | 'winnerRank'
                        | 'isWinnerSelectionInProgress'
                        | 'isIAmWinner'
                        | 'startDate'
                    > & {
                            bidsNumber?: Maybe<{ __typename: 'BidsNumber' } & Pick<BidsNumber, 'active' | 'cancelled'>>;
                            transactions?: Maybe<
                                Array<
                                    Maybe<
                                        { __typename: 'TransactionNode' } & Pick<
                                            TransactionNode,
                                            'id' | 'isWinner' | 'sender'
                                        >
                                    >
                                >
                            >;
                        }
                >;
            }
    >;
};

export type AvailableCitiesQueryVariables = Exact<{ [key: string]: never }>;

export type AvailableCitiesQuery = { __typename: 'Query' } & {
    cities?: Maybe<
        Array<
            Maybe<
                { __typename: 'CityNode' } & Pick<
                    CityNode,
                    'id' | 'name' | 'centerLon' | 'centerLat' | 'countryCode'
                > & {
                        country?: Maybe<
                            { __typename: 'CountryNode' } & Pick<CountryNode, 'id' | 'name' | 'currencyCode'>
                        >;
                    }
            >
        >
    >;
};

export type BuyAptParamsQueryVariables = Exact<{
    amountToPay: Scalars['String'];
    tokenId: Scalars['String'];
}>;

export type BuyAptParamsQuery = { __typename: 'Query' } & {
    buyAptParams?: Maybe<
        { __typename: 'BuyAptPaymentStruct' } & Pick<BuyAptPaymentStruct, 'paymentParams' | 'deeplink'>
    >;
};

export type CancelBuyoutParamsQueryVariables = Exact<{
    buyRequestId: Scalars['Int'];
}>;

export type CancelBuyoutParamsQuery = { __typename: 'Query' } & {
    buyRequest?: Maybe<
        { __typename: 'BuyAptRequestNode' } & Pick<
            BuyAptRequestNode,
            'id' | 'changeAmount' | 'feeAmount' | 'tokenId'
        > & {
                apt?: Maybe<
                    { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id' | 'number'> & {
                            place?: Maybe<
                                { __typename: 'PlaceNode' } & Pick<PlaceNode, 'id' | 'address'> & {
                                        city?: Maybe<{ __typename: 'CityNode' } & Pick<CityNode, 'id' | 'name'>>;
                                    }
                            >;
                        }
                >;
            }
    >;
};

export type CheckAddressQueryVariables = Exact<{
    aptNumber: Scalars['Int'];
    placeId: Scalars['Int'];
}>;

export type CheckAddressQuery = { __typename: 'Query' } & {
    checkAddress?: Maybe<
        { __typename: 'ConclusionOnAddress' } & Pick<ConclusionOnAddress, 'isAvailable'> & {
                lock?: Maybe<{ __typename: 'LockNode' } & ApartmentLockFragment>;
            }
    >;
};

export type CitiesQueryVariables = Exact<{
    filters?: Maybe<CityFilterInput>;
    sort?: Maybe<Array<Maybe<CitySortEnum>> | Maybe<CitySortEnum>>;
}>;

export type CitiesQuery = { __typename: 'Query' } & {
    cities?: Maybe<
        Array<
            Maybe<
                { __typename: 'CityNode' } & Pick<
                    CityNode,
                    'id' | 'name' | 'countryCode' | 'isAvailable' | 'hasApartments'
                > & { country?: Maybe<{ __typename: 'CountryNode' } & Pick<CountryNode, 'id' | 'name'>> }
            >
        >
    >;
};

export type CitiesManagedByMeQueryVariables = Exact<{ [key: string]: never }>;

export type CitiesManagedByMeQuery = { __typename: 'Query' } & {
    me?: Maybe<
        { __typename: 'UserNode' } & Pick<UserNode, 'id'> & {
                citiesManagedByMe?: Maybe<
                    Array<
                        Maybe<
                            { __typename: 'CityNode' } & Pick<CityNode, 'id' | 'name' | 'countryCode'> & {
                                    country?: Maybe<{ __typename: 'CountryNode' } & Pick<CountryNode, 'id' | 'name'>>;
                                    notaryUpdates?: Maybe<{ __typename: 'NotaryUpdates' } & NotaryUpdatesFragment>;
                                }
                        >
                    >
                >;
            }
    >;
};

export type CityPolygonQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type CityPolygonQuery = { __typename: 'Query' } & {
    city?: Maybe<{ __typename: 'CityNode' } & Pick<CityNode, 'id' | 'polygon'>>;
};

export type StatisticNodeFragment = { __typename: 'StatisticNode' } & Pick<
    StatisticNode,
    | 'lastTokenizationTimestamp'
    | 'tokenizedM2'
    | 'tokenizedAptsNumber'
    | 'inTokenizationAptsNumber'
    | 'inContestAptsNumber'
    | 'inRentAptsNumber'
>;

export type CityTokenizationShareNodeFragment = { __typename: 'CityTokenizationShareNode' } & Pick<
    CityTokenizationShareNode,
    'share' | 'tokenizedM2'
> & {
        city?: Maybe<
            { __typename: 'CityNode' } & Pick<CityNode, 'id' | 'name'> & {
                    country?: Maybe<{ __typename: 'CountryNode' } & Pick<CountryNode, 'name'>>;
                    token?: Maybe<{ __typename: 'TokenNode' } & Pick<TokenNode, 'name' | 'blocksIoLink'>>;
                }
        >;
        statisticDetails?: Maybe<{ __typename: 'StatisticNode' } & StatisticNodeFragment>;
    };

export type CommonStatisticQueryVariables = Exact<{ [key: string]: never }>;

export type CommonStatisticQuery = { __typename: 'Query' } & {
    commonStatistic: { __typename: 'CommonStatisticNode' } & Pick<CommonStatisticNode, 'totalTokenizedM2'> & {
            statisticDetails: { __typename: 'StatisticNode' } & StatisticNodeFragment;
            citiesShares?: Maybe<
                Array<Maybe<{ __typename: 'CityTokenizationShareNode' } & CityTokenizationShareNodeFragment>>
            >;
        };
};

export type ConfirmBuyoutParamsQueryVariables = Exact<{
    buyRequestId: Scalars['Int'];
}>;

export type ConfirmBuyoutParamsQuery = { __typename: 'Query' } & {
    buyRequest?: Maybe<
        { __typename: 'BuyAptRequestNode' } & Pick<
            BuyAptRequestNode,
            'id' | 'tokenId' | 'buyerApproveDeeplink' | 'changeAmount'
        > & {
                apt?: Maybe<
                    { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id' | 'status' | 'number'> & {
                            place?: Maybe<
                                { __typename: 'PlaceNode' } & Pick<PlaceNode, 'id' | 'address'> & {
                                        city?: Maybe<{ __typename: 'CityNode' } & Pick<CityNode, 'id' | 'name'>>;
                                    }
                            >;
                            actualRental?: Maybe<
                                { __typename: 'RentalNode' } & Pick<RentalNode, 'id' | 'expirationDate'>
                            >;
                        }
                >;
            }
    >;
};

export type ContactsApartmentBuyerQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type ContactsApartmentBuyerQuery = { __typename: 'Query' } & {
    buyRequest?: Maybe<
        { __typename: 'BuyAptRequestNode' } & Pick<BuyAptRequestNode, 'id'> & {
                buyer?: Maybe<
                    { __typename: 'UserNode' } & Pick<
                        UserNode,
                        'id' | 'email' | 'phone' | 'eosAccount' | 'cryptochatDeepLink'
                    >
                >;
            }
    >;
};

export type ContactsApartmentOwnerQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type ContactsApartmentOwnerQuery = { __typename: 'Query' } & {
    apartment?: Maybe<
        { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id'> & {
                user?: Maybe<
                    { __typename: 'UserNode' } & Pick<
                        UserNode,
                        'id' | 'email' | 'phone' | 'eosAccount' | 'cryptochatDeepLink'
                    >
                >;
            }
    >;
};

export type ContactsApartmentTenantQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type ContactsApartmentTenantQuery = { __typename: 'Query' } & {
    apartment?: Maybe<
        { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id'> & {
                actualRental?: Maybe<
                    { __typename: 'RentalNode' } & Pick<RentalNode, 'id'> & {
                            tenant?: Maybe<
                                { __typename: 'UserNode' } & Pick<
                                    UserNode,
                                    'id' | 'email' | 'phone' | 'eosAccount' | 'cryptochatDeepLink'
                                >
                            >;
                        }
                >;
            }
    >;
};

export type ContactsApartmentWinnerQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type ContactsApartmentWinnerQuery = { __typename: 'Query' } & {
    apartment?: Maybe<
        { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id'> & {
                latestAuction?: Maybe<
                    { __typename: 'AuctionNode' } & {
                        winner?: Maybe<
                            { __typename: 'UserNode' } & Pick<
                                UserNode,
                                'id' | 'email' | 'phone' | 'eosAccount' | 'cryptochatDeepLink'
                            >
                        >;
                    }
                >;
            }
    >;
};

export type ContactsManagerQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type ContactsManagerQuery = { __typename: 'Query' } & {
    city?: Maybe<
        { __typename: 'CityNode' } & Pick<
            CityNode,
            'id' | 'notaryEosAccount' | 'notaryContactEmail' | 'notaryContactPhone' | 'notaryChatLink'
        >
    >;
};

export type ContactCitiesQueryVariables = Exact<{ [key: string]: never }>;

export type ContactCitiesQuery = { __typename: 'Query' } & {
    cities?: Maybe<
        Array<Maybe<{ __typename: 'CityNode' } & Pick<CityNode, 'id' | 'name' | 'countryCode' | 'notaryEosAccount'>>>
    >;
};

export type ContestWinnerAgreementParamsQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type ContestWinnerAgreementParamsQuery = { __typename: 'Query' } & {
    apartment?: Maybe<
        { __typename: 'ApartmentNode' } & Pick<
            ApartmentNode,
            'id' | 'communalCurrency' | 'communalPayment' | 'totalExpenses' | 'isTokenized'
        > & {
                tokenizationInfo?: Maybe<
                    { __typename: 'TokenizationStruct' } & Pick<TokenizationStruct, 'winnerSignDeepLink'>
                >;
            }
    >;
    aptExpenses?: Maybe<
        Array<
            Maybe<
                { __typename: 'AptExpenseNode' } & Pick<AptExpenseNode, 'value'> & {
                        expense: { __typename: 'ExpenseNode' } & Pick<ExpenseNode, 'id' | 'name'>;
                    }
            >
        >
    >;
};

export type EditableApartmentQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type EditableApartmentQuery = { __typename: 'Query' } & {
    apartment?: Maybe<{ __typename: 'ApartmentNode' } & EditApartmentDataFragment>;
};

export type EosAccountExistsQueryVariables = Exact<{
    eosAccount: Scalars['String'];
}>;

export type EosAccountExistsQuery = { __typename: 'Query' } & Pick<Query, 'eosAccountExists'>;

export type ExploreCitiesQueryVariables = Exact<{ [key: string]: never }>;

export type ExploreCitiesQuery = { __typename: 'Query' } & {
    cities?: Maybe<
        Array<
            Maybe<
                { __typename: 'CityNode' } & Pick<
                    CityNode,
                    'id' | 'name' | 'centerLon' | 'centerLat' | 'isAvailable' | 'countryCode'
                >
            >
        >
    >;
};

export type HousesCoordinatesQueryVariables = Exact<{
    filters?: Maybe<ApartmentFilterInput>;
    favorites?: Maybe<Scalars['Boolean']>;
}>;

export type HousesCoordinatesQuery = { __typename: 'Query' } & {
    houses?: Maybe<
        Array<Maybe<{ __typename: 'HouseNode' } & Pick<HouseNode, 'lon' | 'lat' | 'numberOfApts' | 'placeId'>>>
    >;
};

export type ManagerApplicationsQueryVariables = Exact<{
    cityId: Scalars['Int'];
    statuses?: Maybe<Array<Maybe<AptStatus>> | Maybe<AptStatus>>;
    sort?: Maybe<Array<Maybe<ApplicationSortEnum>> | Maybe<ApplicationSortEnum>>;
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
}>;

export type ManagerApplicationsQuery = { __typename: 'Query' } & {
    applications?: Maybe<
        { __typename: 'ApartmentListNode' } & {
            pageInfo: { __typename: 'PageInfo' } & Pick<PageInfo, 'total'>;
            apartments: Array<Maybe<{ __typename: 'ApartmentNode' } & ManagerApplicationsApartmentFragment>>;
        }
    >;
};

export type ManagerAuctionsQueryVariables = Exact<{
    isFinished: Scalars['Boolean'];
    cityId: Scalars['Int'];
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<Array<Maybe<AuctionSortEnum>> | Maybe<AuctionSortEnum>>;
}>;

export type ManagerAuctionsQuery = { __typename: 'Query' } & {
    auctions?: Maybe<
        { __typename: 'ApartmentListNode' } & {
            pageInfo: { __typename: 'PageInfo' } & Pick<PageInfo, 'total'>;
            apartments?: Maybe<
                Array<
                    Maybe<
                        { __typename: 'ApartmentNode' } & ManagerAuctionActiveApartmentFragment &
                            ManagerAuctionFinishedApartmentFragment
                    >
                >
            >;
        }
    >;
};

export type ManagerBuyoutAgreementParamsQueryVariables = Exact<{
    buyRequestId: Scalars['Int'];
}>;

export type ManagerBuyoutAgreementParamsQuery = { __typename: 'Query' } & {
    buyRequest?: Maybe<
        { __typename: 'BuyAptRequestNode' } & Pick<
            BuyAptRequestNode,
            'id' | 'buyerAccount' | 'transferTime' | 'tokenId' | 'changeAmount' | 'rdApproveDeeplink' | 'feeAmount'
        > & {
                apt?: Maybe<
                    { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id' | 'number' | 'm2' | 'status'> & {
                            place?: Maybe<
                                { __typename: 'PlaceNode' } & Pick<PlaceNode, 'id' | 'address'> & {
                                        city?: Maybe<{ __typename: 'CityNode' } & Pick<CityNode, 'id' | 'name'>>;
                                    }
                            >;
                            actualRental?: Maybe<
                                { __typename: 'RentalNode' } & Pick<RentalNode, 'id' | 'tenantAccount'>
                            >;
                        }
                >;
            }
    >;
};

export type ManagerBuyRequestsQueryVariables = Exact<{
    cityId: Scalars['Int'];
    isActive?: Maybe<Scalars['Boolean']>;
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
}>;

export type ManagerBuyRequestsQuery = { __typename: 'Query' } & {
    buyRequests?: Maybe<
        { __typename: 'BuyRequestListNode' } & {
            pageInfo: { __typename: 'PageInfo' } & Pick<PageInfo, 'total'>;
            buyRequests: Array<Maybe<{ __typename: 'BuyAptRequestNode' } & ManagerBuyRequestFragment>>;
        }
    >;
};

export type ManagerEvictionsQueryVariables = Exact<{
    cityId: Scalars['Int'];
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<Array<Maybe<RentalSortEnum>> | Maybe<RentalSortEnum>>;
}>;

export type ManagerEvictionsQuery = { __typename: 'Query' } & {
    rentals?: Maybe<
        { __typename: 'ApartmentListNode' } & {
            pageInfo: { __typename: 'PageInfo' } & Pick<PageInfo, 'total'>;
            apartments: Array<Maybe<{ __typename: 'ApartmentNode' } & ManagerEvictionsApartmentFragment>>;
        }
    >;
};

export type ManagerLockedAptsQueryVariables = Exact<{
    cityId: Scalars['Int'];
    sort?: Maybe<Array<Maybe<LockedSortEnum>> | Maybe<LockedSortEnum>>;
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
}>;

export type ManagerLockedAptsQuery = { __typename: 'Query' } & {
    lockedApts?: Maybe<
        { __typename: 'ApartmentListNode' } & {
            pageInfo: { __typename: 'PageInfo' } & Pick<PageInfo, 'total'>;
            apartments: Array<Maybe<{ __typename: 'ApartmentNode' } & ManagerLockedAptsFragment>>;
        }
    >;
};

export type ManagerRegionSettingsQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type ManagerRegionSettingsQuery = { __typename: 'Query' } & {
    city?: Maybe<
        { __typename: 'CityNode' } & Pick<
            CityNode,
            'id' | 'locales' | 'notaryEosAccount' | 'notaryContactEmail' | 'notaryContactPhone'
        >
    >;
    regionExpenses?: Maybe<Array<Maybe<{ __typename: 'ExpenseNode' } & ManagerExpenseFragment>>>;
    regionDocuments?: Maybe<Array<Maybe<{ __typename: 'RegionDocumentNode' } & ManagerDocumentFragment>>>;
};

export type ManagerRentalsQueryVariables = Exact<{
    cityId: Scalars['Int'];
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<Array<Maybe<RentalSortEnum>> | Maybe<RentalSortEnum>>;
}>;

export type ManagerRentalsQuery = { __typename: 'Query' } & {
    rentals?: Maybe<
        { __typename: 'ApartmentListNode' } & {
            pageInfo: { __typename: 'PageInfo' } & Pick<PageInfo, 'total'>;
            apartments: Array<Maybe<{ __typename: 'ApartmentNode' } & ManagerRentalsApartmentFragment>>;
        }
    >;
};

export type ManagerWaitingContestAptsQueryVariables = Exact<{
    cityId: Scalars['Int'];
    sort?: Maybe<Array<Maybe<WaitingContestSortEnum>> | Maybe<WaitingContestSortEnum>>;
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
}>;

export type ManagerWaitingContestAptsQuery = { __typename: 'Query' } & {
    waitingContestApts?: Maybe<
        { __typename: 'ApartmentListNode' } & {
            pageInfo: { __typename: 'PageInfo' } & Pick<PageInfo, 'total'>;
            apartments: Array<Maybe<{ __typename: 'ApartmentNode' } & ManagerWaitingContestApartmentFragment>>;
        }
    >;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = { __typename: 'Query' } & {
    me?: Maybe<
        { __typename: 'UserNode' } & Pick<
            UserNode,
            'id' | 'email' | 'phone' | 'eosAccount' | 'isNotary' | 'hasPassword' | 'countNewNotifications'
        > & { rdUpdates?: Maybe<{ __typename: 'NotaryUpdates' } & NotaryUpdatesFragment> }
    >;
};

export type MeNotifyHistoryQueryVariables = Exact<{
    newOnly?: Maybe<Scalars['Boolean']>;
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
}>;

export type MeNotifyHistoryQuery = { __typename: 'Query' } & Pick<Query, 'notificationsNumber'> & {
        me?: Maybe<
            { __typename: 'UserNode' } & Pick<UserNode, 'id' | 'countNewNotifications'> & {
                    notifyHistory?: Maybe<
                        Array<Maybe<{ __typename: 'NotifyHistoryNode' } & NotifyHistoryNodeFragment>>
                    >;
                }
        >;
    };

export type MeNotifySettingsQueryVariables = Exact<{ [key: string]: never }>;

export type MeNotifySettingsQuery = { __typename: 'Query' } & {
    me?: Maybe<{ __typename: 'UserNode' } & MeNotificationSettingsFragmentFragment>;
};

export type MyApartmentsQueryVariables = Exact<{
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    relationType: RelationToApt;
    isOwn?: Maybe<Scalars['Boolean']>;
}>;

export type MyApartmentsQuery = { __typename: 'Query' } & {
    myApartments?: Maybe<
        { __typename: 'ApartmentListNode' } & {
            pageInfo: { __typename: 'PageInfo' } & Pick<PageInfo, 'total'>;
            apartments: Array<
                Maybe<
                    { __typename: 'ApartmentNode' } & {
                        emissionVolume?: Maybe<
                            { __typename: 'VolumeStruct' } & Pick<VolumeStruct, 'amount' | 'token' | 'fee'>
                        >;
                        review?: Maybe<{ __typename: 'AptReviewNode' } & Pick<AptReviewNode, 'errorsCount'>>;
                    } & PublicApartmentListNodeFragment
                >
            >;
        }
    >;
};

export type MyBuyRequestsQueryVariables = Exact<{
    cityId?: Maybe<Scalars['Int']>;
    isActive?: Maybe<Scalars['Boolean']>;
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
}>;

export type MyBuyRequestsQuery = { __typename: 'Query' } & {
    myBuyRequests?: Maybe<
        { __typename: 'BuyRequestListNode' } & {
            pageInfo: { __typename: 'PageInfo' } & Pick<PageInfo, 'total'>;
            buyRequests: Array<Maybe<{ __typename: 'BuyAptRequestNode' } & MyBuyRequestFragment>>;
        }
    >;
};

export type OurSuitableAddressesQueryVariables = Exact<{
    address: Scalars['String'];
    cityId?: Maybe<Scalars['Int']>;
}>;

export type OurSuitableAddressesQuery = { __typename: 'Query' } & {
    ourSuitableAddresses: Array<Maybe<{ __typename: 'PlaceNode' } & Pick<PlaceNode, 'id' | 'address'>>>;
};

export type PlaceQueryVariables = Exact<{
    id?: Maybe<Scalars['Int']>;
    gmapPlaceId?: Maybe<Scalars['String']>;
    cityId?: Maybe<Scalars['Int']>;
    fingerprint?: Maybe<Scalars['String']>;
}>;

export type PlaceQuery = { __typename: 'Query' } & {
    place?: Maybe<
        { __typename: 'PlaceNode' } & Pick<
            PlaceNode,
            'id' | 'address' | 'isInsidePolygon' | 'canBeAccepted' | 'lon' | 'lat'
        >
    >;
};

export type RegionDocumentsQueryVariables = Exact<{
    cityId: Scalars['Int'];
}>;

export type RegionDocumentsQuery = { __typename: 'Query' } & {
    regionDocuments?: Maybe<
        Array<Maybe<{ __typename: 'RegionDocumentNode' } & Pick<RegionDocumentNode, 'id' | 'name'>>>
    >;
};

export type RegionExpensesQueryVariables = Exact<{
    cityId: Scalars['Int'];
}>;

export type RegionExpensesQuery = { __typename: 'Query' } & {
    regionExpenses?: Maybe<Array<Maybe<{ __typename: 'ExpenseNode' } & Pick<ExpenseNode, 'id' | 'name'>>>>;
};

export type RentalExpirationAfterRenewQueryVariables = Exact<{
    curentExpiration?: Maybe<Scalars['DateTime']>;
}>;

export type RentalExpirationAfterRenewQuery = { __typename: 'Query' } & Pick<Query, 'rentalExpirationAfterRenew'>;

export type SearchCitiesQueryVariables = Exact<{ [key: string]: never }>;

export type SearchCitiesQuery = { __typename: 'Query' } & {
    cities?: Maybe<
        Array<
            Maybe<
                { __typename: 'CityNode' } & Pick<
                    CityNode,
                    'id' | 'name' | 'centerLon' | 'centerLat' | 'countryCode'
                > & {
                        country?: Maybe<
                            { __typename: 'CountryNode' } & Pick<CountryNode, 'id' | 'name' | 'currencyCode'>
                        >;
                    }
            >
        >
    >;
};

export type StartAuctionParamsQueryVariables = Exact<{
    aptId: Scalars['Int'];
    cityId: Scalars['Int'];
}>;

export type StartAuctionParamsQuery = { __typename: 'Query' } & {
    apartment?: Maybe<
        { __typename: 'ApartmentNode' } & Pick<
            ApartmentNode,
            'id' | 'communalCurrency' | 'communalPayment' | 'totalExpenses'
        >
    >;
    aptExpenses?: Maybe<
        Array<
            Maybe<
                { __typename: 'AptExpenseNode' } & Pick<AptExpenseNode, 'value'> & {
                        expense: { __typename: 'ExpenseNode' } & Pick<ExpenseNode, 'id' | 'name'>;
                    }
            >
        >
    >;
    regionExpenses?: Maybe<Array<Maybe<{ __typename: 'ExpenseNode' } & Pick<ExpenseNode, 'id' | 'name'>>>>;
};

export type SubwaysQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type SubwaysQuery = { __typename: 'Query' } & {
    city?: Maybe<
        { __typename: 'CityNode' } & Pick<CityNode, 'id'> & {
                subways?: Maybe<Array<Maybe<{ __typename: 'SubwayNode' } & Pick<SubwayNode, 'id' | 'name'>>>>;
            }
    >;
};

export type SuitableAddressesQueryVariables = Exact<{
    address: Scalars['String'];
    first?: Maybe<Scalars['String']>;
    cityId?: Maybe<Scalars['Int']>;
}>;

export type SuitableAddressesQuery = { __typename: 'Query' } & {
    suitableAddresses: Array<
        Maybe<{ __typename: 'GMapAddressStruct' } & Pick<GMapAddressStruct, 'address' | 'placeId' | 'isFull'>>
    >;
};

export type SystemQueryVariables = Exact<{
    isAuth: Scalars['Boolean'];
}>;

export type SystemQuery = { __typename: 'Query' } & Pick<Query, 'realtime' | 'currentLanguage'> & {
        me?: Maybe<{ __typename: 'UserNode' } & Pick<UserNode, 'id' | 'language'>>;
    };

export type ValidateContestQueryVariables = Exact<{
    aptId: Scalars['Int'];
}>;

export type ValidateContestQuery = { __typename: 'Query' } & {
    contestCheck: { __typename: 'ContestCheckDataNode' } & Pick<ContestCheckDataNode, 'seedBlock' | 'testScript'>;
    apartment?: Maybe<
        { __typename: 'ApartmentNode' } & Pick<ApartmentNode, 'id' | 'status'> & {
                place?: Maybe<{ __typename: 'PlaceNode' } & Pick<PlaceNode, 'id' | 'address'>>;
                city: { __typename: 'CityNode' } & Pick<CityNode, 'id' | 'name'>;
                photos?: Maybe<Array<Maybe<{ __typename: 'DocumentNode' } & Pick<DocumentNode, 'id' | 'link'>>>>;
                latestAuction?: Maybe<
                    { __typename: 'AuctionNode' } & Pick<
                        AuctionNode,
                        'id' | 'endOfActivePhase' | 'winnerAccount' | 'isWinnerSelectionInProgress' | 'isIAmWinner'
                    > & { bidsNumber?: Maybe<{ __typename: 'BidsNumber' } & Pick<BidsNumber, 'active' | 'cancelled'>> }
                >;
            }
    >;
};

export type ApartmentWsSubscriptionVariables = Exact<{
    aptId: Scalars['Int'];
}>;

export type ApartmentWsSubscription = { __typename: 'Subscription' } & {
    apartment?: Maybe<
        { __typename: 'AptSubscriptionEvent' } & Pick<AptSubscriptionEvent, 'event'> & {
                apt: { __typename: 'ApartmentNode' } & PublicApartmentListNodeFragment;
            }
    >;
};

export type CityNotaryUpdatesWsSubscriptionVariables = Exact<{
    cityId: Scalars['Int'];
}>;

export type CityNotaryUpdatesWsSubscription = { __typename: 'Subscription' } & {
    city?: Maybe<
        { __typename: 'CityNode' } & Pick<CityNode, 'id'> & {
                notaryUpdates?: Maybe<{ __typename: 'NotaryUpdates' } & NotaryUpdatesFragment>;
            }
    >;
};

export type ConnectByWalletWsSubscriptionVariables = Exact<{
    verificationCode: Scalars['String'];
}>;

export type ConnectByWalletWsSubscription = { __typename: 'Subscription' } & {
    connectByWallet?: Maybe<
        | ({ __typename: 'WalletConnectionSuccess' } & Pick<WalletConnectionSuccess, 'token'>)
        | ({ __typename: 'UserNotFoundError' } & Pick<UserNotFoundError, 'errorMessage'>)
        | ({ __typename: 'ConfirmationFailedError' } & Pick<ConfirmationFailedError, 'errorMessage'>)
        | ({ __typename: 'WalletLinkOutdatedError' } & Pick<WalletLinkOutdatedError, 'errorMessage'>)
        | ({ __typename: 'EosAccountAlreadyTakenError' } & Pick<EosAccountAlreadyTakenError, 'errorMessage'>)
    >;
};

export type ManagerApplicationsWsSubscriptionVariables = Exact<{
    cityId: Scalars['Int'];
}>;

export type ManagerApplicationsWsSubscription = { __typename: 'Subscription' } & {
    RDUpdates?: Maybe<
        { __typename: 'AptRDSubscriptionEvent' } & Pick<AptRdSubscriptionEvent, 'oldStatus'> & {
                apt: { __typename: 'ApartmentNode' } & ManagerApplicationsApartmentFragment;
            }
    >;
};

export type ManagerAuctionsActiveWsSubscriptionVariables = Exact<{
    cityId: Scalars['Int'];
}>;

export type ManagerAuctionsActiveWsSubscription = { __typename: 'Subscription' } & {
    RDUpdates?: Maybe<
        { __typename: 'AptRDSubscriptionEvent' } & Pick<AptRdSubscriptionEvent, 'oldStatus'> & {
                apt: { __typename: 'ApartmentNode' } & ManagerAuctionActiveApartmentFragment;
            }
    >;
};

export type ManagerAuctionsFinishedWsSubscriptionVariables = Exact<{
    cityId: Scalars['Int'];
}>;

export type ManagerAuctionsFinishedWsSubscription = { __typename: 'Subscription' } & {
    RDUpdates?: Maybe<
        { __typename: 'AptRDSubscriptionEvent' } & Pick<AptRdSubscriptionEvent, 'oldStatus'> & {
                apt: { __typename: 'ApartmentNode' } & ManagerAuctionFinishedApartmentFragment;
            }
    >;
};

export type ManagerBuyRequestsWsSubscriptionVariables = Exact<{
    cityId: Scalars['Int'];
}>;

export type ManagerBuyRequestsWsSubscription = { __typename: 'Subscription' } & {
    RDBuyRequests?: Maybe<
        { __typename: 'BuyRequestSubscriptionEvent' } & Pick<BuyRequestSubscriptionEvent, 'event'> & {
                buyRequest: { __typename: 'BuyAptRequestNode' } & ManagerBuyRequestFragment;
            }
    >;
};

export type ManagerEvictionsWsSubscriptionVariables = Exact<{
    cityId: Scalars['Int'];
}>;

export type ManagerEvictionsWsSubscription = { __typename: 'Subscription' } & {
    RDUpdates?: Maybe<
        { __typename: 'AptRDSubscriptionEvent' } & Pick<AptRdSubscriptionEvent, 'oldStatus'> & {
                apt: { __typename: 'ApartmentNode' } & ManagerEvictionsApartmentFragment;
            }
    >;
};

export type ManagerLockedAptsWsSubscriptionVariables = Exact<{
    cityId: Scalars['Int'];
}>;

export type ManagerLockedAptsWsSubscription = { __typename: 'Subscription' } & {
    RDUpdates?: Maybe<
        { __typename: 'AptRDSubscriptionEvent' } & Pick<AptRdSubscriptionEvent, 'oldStatus'> & {
                apt: { __typename: 'ApartmentNode' } & ManagerLockedAptsFragment;
            }
    >;
};

export type ManagerRentalsWsSubscriptionVariables = Exact<{
    cityId: Scalars['Int'];
}>;

export type ManagerRentalsWsSubscription = { __typename: 'Subscription' } & {
    RDUpdates?: Maybe<
        { __typename: 'AptRDSubscriptionEvent' } & Pick<AptRdSubscriptionEvent, 'oldStatus'> & {
                apt: { __typename: 'ApartmentNode' } & ManagerRentalsApartmentFragment;
            }
    >;
};

export type ManagerWaitingContestAptsWsSubscriptionVariables = Exact<{
    cityId: Scalars['Int'];
}>;

export type ManagerWaitingContestAptsWsSubscription = { __typename: 'Subscription' } & {
    RDUpdates?: Maybe<
        { __typename: 'AptRDSubscriptionEvent' } & Pick<AptRdSubscriptionEvent, 'oldStatus'> & {
                apt: { __typename: 'ApartmentNode' } & ManagerWaitingContestApartmentFragment;
            }
    >;
};

export type MeWsSubscriptionVariables = Exact<{ [key: string]: never }>;

export type MeWsSubscription = { __typename: 'Subscription' } & {
    me?: Maybe<
        { __typename: 'UserNode' } & Pick<
            UserNode,
            'id' | 'language' | 'eosAccount' | 'isNotary' | 'countNewNotifications'
        > & { rdUpdates?: Maybe<{ __typename: 'NotaryUpdates' } & NotaryUpdatesFragment> }
    >;
};

export type MyBuyRequestsWsSubscriptionVariables = Exact<{ [key: string]: never }>;

export type MyBuyRequestsWsSubscription = { __typename: 'Subscription' } & {
    myBuyRequests?: Maybe<
        { __typename: 'BuyRequestSubscriptionEvent' } & Pick<BuyRequestSubscriptionEvent, 'event'> & {
                buyRequest: { __typename: 'BuyAptRequestNode' } & MyBuyRequestFragment;
            }
    >;
};

export type NewNotificationWsSubscriptionVariables = Exact<{ [key: string]: never }>;

export type NewNotificationWsSubscription = { __typename: 'Subscription' } & {
    newNotification?: Maybe<{ __typename: 'NotifyHistoryNode' } & NotifyHistoryNodeFragment>;
};

export type NotificationWsSubscriptionVariables = Exact<{
    id: Scalars['String'];
}>;

export type NotificationWsSubscription = { __typename: 'Subscription' } & {
    notification?: Maybe<{ __typename: 'NotifyHistoryNode' } & Pick<NotifyHistoryNode, 'id' | 'isNew'>>;
};

export const FieldStatusFragmentDoc = gql`
    fragment FieldStatus on FieldStatusStruct {
        isOk
        errorDescription
    }
`;
export const ApartmentReviewFragmentDoc = gql`
    fragment ApartmentReview on AptReviewNode {
        comment
        errorsCount
        fields {
            address {
                ...FieldStatus
            }
            realId {
                ...FieldStatus
            }
            housingComplex {
                ...FieldStatus
            }
            entranceNumber {
                ...FieldStatus
            }
            aptNumber {
                ...FieldStatus
            }
            subway {
                ...FieldStatus
            }
            houseType {
                ...FieldStatus
            }
            buildingYear {
                ...FieldStatus
            }
            entranceFloors {
                ...FieldStatus
            }
            aptFloor {
                ...FieldStatus
            }
            parking {
                ...FieldStatus
            }
            elevators {
                ...FieldStatus
            }
            infra {
                ...FieldStatus
            }
            aptType {
                ...FieldStatus
            }
            tenure {
                ...FieldStatus
            }
            rooms {
                ...FieldStatus
            }
            m2 {
                ...FieldStatus
            }
            livingM2 {
                ...FieldStatus
            }
            kitchenM2 {
                ...FieldStatus
            }
            ceilingHeight {
                ...FieldStatus
            }
            bathroom {
                ...FieldStatus
            }
            windows {
                ...FieldStatus
            }
            balconies {
                ...FieldStatus
            }
            aptDescription {
                ...FieldStatus
            }
            photos {
                ...FieldStatus
            }
            documents {
                ...FieldStatus
            }
            communalPayment {
                ...FieldStatus
            }
        }
    }
    ${FieldStatusFragmentDoc}
`;
export const EditApartmentDataFragmentDoc = gql`
    fragment EditApartmentData on ApartmentNode {
        id
        realId
        description
        status
        aptType
        floor
        number
        rooms
        isStudio
        m2
        livingM2
        kitchenM2
        ceilingHeight
        hasYardWindows
        hasStreetWindows
        combinedBaths
        dividedBaths
        tenure
        balconies
        hasLoggia
        refundAccount
        numberOfFloors
        hasElevator
        hasCargoElevator
        hasGarbageChute
        hasSecurity
        hasConcierge
        hasIntercom
        hasVideoSurveillance
        entranceNumber
        hasGas
        hasGroundParking
        hasUndergroundParking
        hasMultilevelParking
        houseType
        buildingYear
        housingComplex
        communalPayment
        isTokenized
        ownerAccount
        isIAmOwner
        isIAmNotary
        place {
            id
            address
            lon
            lat
            coordsMayBeWrong
            isInsidePolygon
        }
        city {
            id
            centerLat
            centerLon
            name
            country {
                id
                name
                currencyCode
            }
        }
        subways {
            id
            way
            minutes
            subway {
                id
                name
            }
        }
        photos {
            id
            link(size: SIZE_1920x1080)
            preview: link(size: SIZE_320x180)
            originalName
        }
        documents {
            id
            link
            name
            originalName
        }
        review {
            ...ApartmentReview
        }
    }
    ${ApartmentReviewFragmentDoc}
`;
export const ManagerApartmentSharableFragmentDoc = gql`
    fragment ManagerApartmentSharable on ApartmentNode {
        id
        floor
        rooms
        isStudio
        m2
        status
        numberOfFloors
        isNewForRd
        updatedForRd
        number
        place {
            id
            address
        }
        city {
            id
            name
        }
        photos {
            id
            link(size: SIZE_1125x540)
        }
    }
`;
export const ManagerApplicationsApartmentFragmentDoc = gql`
    fragment ManagerApplicationsApartment on ApartmentNode {
        ...ManagerApartmentSharable
    }
    ${ManagerApartmentSharableFragmentDoc}
`;
export const ManagerAuctionActiveApartmentFragmentDoc = gql`
    fragment ManagerAuctionActiveApartment on ApartmentNode {
        ...ManagerApartmentSharable
        isTokenized
        latestAuction {
            id
            endOfActivePhase
            bidsNumber {
                active
            }
        }
    }
    ${ManagerApartmentSharableFragmentDoc}
`;
export const ManagerAuctionFinishedApartmentFragmentDoc = gql`
    fragment ManagerAuctionFinishedApartment on ApartmentNode {
        ...ManagerApartmentSharable
        isTokenized
        tokenizationInfo {
            isSignedByNotary
            isSignedByOwner
        }
        latestAuction {
            id
            endOfActivePhase
            isWinnerSelectionInProgress
            winnerAccount
            winnerRank
            isWinnerAgree
            deadlineForWinner
            deadlineToPayCommunal
        }
    }
    ${ManagerApartmentSharableFragmentDoc}
`;
export const ManagerBuyRequestFragmentDoc = gql`
    fragment ManagerBuyRequest on BuyAptRequestNode {
        id
        isNewForRd
        buyerAccount
        transferTime
        transferAmount
        returnedAmount
        changeAmount
        feeAmount
        status
        tokenId
        isApprovedByBuyer
        isApprovedByRd
        endTime
        apt {
            ...ManagerApartmentSharable
            actualRental {
                id
                tenantAccount
                expirationDate
                isExpired
            }
        }
    }
    ${ManagerApartmentSharableFragmentDoc}
`;
export const ManagerDocumentFragmentDoc = gql`
    fragment ManagerDocument on RegionDocumentNode {
        id
        locales {
            name
            lang
        }
    }
`;
export const ManagerEvictionsApartmentFragmentDoc = gql`
    fragment ManagerEvictionsApartment on ApartmentNode {
        ...ManagerApartmentSharable
        communalCurrency
        actualRental {
            id
            evictionStart
            isEvictionStartedByTenant
            evictionReason
            tenantAccount
        }
    }
    ${ManagerApartmentSharableFragmentDoc}
`;
export const ManagerExpenseFragmentDoc = gql`
    fragment ManagerExpense on ExpenseNode {
        id
        locales {
            name
            lang
        }
    }
`;
export const ApartmentLockFragmentDoc = gql`
    fragment ApartmentLock on LockNode {
        reason
        reasonType
        startLockDate
        lockEndDate
    }
`;
export const ManagerLockedAptsFragmentDoc = gql`
    fragment ManagerLockedApts on ApartmentNode {
        ...ManagerApartmentSharable
        lock {
            ...ApartmentLock
        }
    }
    ${ManagerApartmentSharableFragmentDoc}
    ${ApartmentLockFragmentDoc}
`;
export const ManagerRentalsApartmentFragmentDoc = gql`
    fragment ManagerRentalsApartment on ApartmentNode {
        ...ManagerApartmentSharable
        communalCurrency
        actualRental {
            id
            expirationDate
            isExpired
            tenantAccount
            price
            canBeExtended
        }
    }
    ${ManagerApartmentSharableFragmentDoc}
`;
export const ManagerWaitingContestApartmentFragmentDoc = gql`
    fragment ManagerWaitingContestApartment on ApartmentNode {
        ...ManagerApartmentSharable
    }
    ${ManagerApartmentSharableFragmentDoc}
`;
export const ChannelNodeFragmentDoc = gql`
    fragment ChannelNode on ChannelNode {
        isSmsEnabled
        isEmailEnabled
    }
`;
export const MeNotificationSettingsFragmentFragmentDoc = gql`
    fragment MeNotificationSettingsFragment on UserNode {
        id
        notifySettings {
            aptStatus {
                ...ChannelNode
            }
        }
    }
    ${ChannelNodeFragmentDoc}
`;
export const MyBuyRequestFragmentDoc = gql`
    fragment MyBuyRequest on BuyAptRequestNode {
        id
        transferTime
        transferAmount
        returnedAmount
        feeAmount
        tokenId
        status
        isApprovedByBuyer
        isApprovedByRd
        endTime
        apt {
            id
            floor
            rooms
            isStudio
            m2
            status
            numberOfFloors
            number
            place {
                id
                address
            }
            city {
                id
                name
            }
            photos {
                id
                link(size: SIZE_1125x540)
            }
            actualRental {
                id
                tenantAccount
                expirationDate
                isExpired
            }
        }
    }
`;
export const NotaryUpdatesFragmentDoc = gql`
    fragment NotaryUpdates on NotaryUpdates {
        moderationsNum
        reworksNum
        activeContestsNum
        finishedContestsNum
        rentalsNum
        waitingContestsNum
        evictionsNum
        lockedNum
        activeBuyRequestsNum
        finishedBuyRequestsNum
    }
`;
export const NotifyHistoryNodeFragmentDoc = gql`
    fragment NotifyHistoryNode on NotifyHistoryNode {
        id
        created
        notificationType
        text
        isNew
        aptId
        emmissionTxid
    }
`;
export const PublicApartmentListNodeFragmentDoc = gql`
    fragment PublicApartmentListNode on ApartmentNode {
        id
        status
        isFavorite
        isPublic
        m2
        rooms
        isStudio
        floor
        number
        place {
            id
            lat
            lon
            address
        }
        city {
            id
            name
            isNew
        }
        photos {
            id
            link(size: SIZE_1125x540)
        }
        lock {
            reason
            reasonType
            lockEndDate
        }
        buyRequest {
            id
            endTime
            buyerAccount
            feeAmount
        }
        actualRental {
            id
            expirationDate
            canBeExtended
            isIAmTenant
            isExpired
            isEvictionStartedByTenant
            evictionReason
        }
        latestAuction {
            id
            endOfActivePhase
            winnerAccount
            isWinnerSelectionInProgress
            hasMyBid
            isWinnerAgree
            isIAmWinner
            startDate
            deadlineForWinner
            deadlineToPayCommunal
            bidsNumber {
                active
                cancelled
            }
            paymentInfo {
                payment
                token
            }
        }
        isTokenized
        communalCurrency
        totalExpenses
        tokenizationInfo {
            isSignedByNotary
            isSignedByOwner
            isSignedByWinner
        }
    }
`;
export const UserFragmentDoc = gql`
    fragment User on UserNode {
        id
        email
        phone
        eosAccount
        isNotary
        hasPassword
        hasNewNotifications
    }
`;
export const PublishApartmentAptDataFragmentDoc = gql`
    fragment PublishApartmentAptData on ApartmentNode {
        id
        status
        emissionVolume {
            amount
            token
            fee
        }
    }
`;
export const StatisticNodeFragmentDoc = gql`
    fragment StatisticNode on StatisticNode {
        lastTokenizationTimestamp
        tokenizedM2
        tokenizedAptsNumber
        inTokenizationAptsNumber
        inContestAptsNumber
        inRentAptsNumber
    }
`;
export const CityTokenizationShareNodeFragmentDoc = gql`
    fragment CityTokenizationShareNode on CityTokenizationShareNode {
        city {
            id
            name
            country {
                name
            }
            token {
                name
                blocksIoLink
            }
        }
        share
        tokenizedM2
        statisticDetails {
            ...StatisticNode
        }
    }
    ${StatisticNodeFragmentDoc}
`;
export const AddExpenseDocument = gql`
    mutation AddExpense($input: AddExpenseInput!) {
        addExpense(input: $input) {
            ... on AddExpenseSuccess {
                expense {
                    ...ManagerExpense
                }
            }
            ... on HasNoPermissionError {
                errorMessage
            }
            ... on CityNotFoundError {
                errorMessage
            }
        }
    }
    ${ManagerExpenseFragmentDoc}
`;
export type AddExpenseMutationFn = Apollo.MutationFunction<AddExpenseMutation, AddExpenseMutationVariables>;

/**
 * __useAddExpenseMutation__
 *
 * To run a mutation, you first call `useAddExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addExpenseMutation, { data, loading, error }] = useAddExpenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddExpenseMutation(
    baseOptions?: Apollo.MutationHookOptions<AddExpenseMutation, AddExpenseMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<AddExpenseMutation, AddExpenseMutationVariables>(AddExpenseDocument, options);
}
export type AddExpenseMutationHookResult = ReturnType<typeof useAddExpenseMutation>;
export type AddExpenseMutationResult = Apollo.MutationResult<AddExpenseMutation>;
export type AddExpenseMutationOptions = Apollo.BaseMutationOptions<AddExpenseMutation, AddExpenseMutationVariables>;
export const AddRegionDocumentDocument = gql`
    mutation AddRegionDocument($input: AddRegionDocumentInput!) {
        addRegionDocument(input: $input) {
            ... on AddRegionDocumentSuccess {
                regionDocument {
                    ...ManagerDocument
                }
            }
            ... on HasNoPermissionError {
                errorMessage
            }
            ... on CityNotFoundError {
                errorMessage
            }
        }
    }
    ${ManagerDocumentFragmentDoc}
`;
export type AddRegionDocumentMutationFn = Apollo.MutationFunction<
    AddRegionDocumentMutation,
    AddRegionDocumentMutationVariables
>;

/**
 * __useAddRegionDocumentMutation__
 *
 * To run a mutation, you first call `useAddRegionDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRegionDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRegionDocumentMutation, { data, loading, error }] = useAddRegionDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRegionDocumentMutation(
    baseOptions?: Apollo.MutationHookOptions<AddRegionDocumentMutation, AddRegionDocumentMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<AddRegionDocumentMutation, AddRegionDocumentMutationVariables>(
        AddRegionDocumentDocument,
        options
    );
}
export type AddRegionDocumentMutationHookResult = ReturnType<typeof useAddRegionDocumentMutation>;
export type AddRegionDocumentMutationResult = Apollo.MutationResult<AddRegionDocumentMutation>;
export type AddRegionDocumentMutationOptions = Apollo.BaseMutationOptions<
    AddRegionDocumentMutation,
    AddRegionDocumentMutationVariables
>;
export const AddRentalDocument = gql`
    mutation AddRental($input: AddRentalInput!) {
        addRental(input: $input) {
            ... on AddRentalSuccess {
                apt {
                    id
                    status
                    actualRental {
                        id
                        price
                        expirationDate
                        canBeExtended
                        tenantAccount
                        tenantChatLink
                    }
                }
            }
            ... on AptNotFoundError {
                errorMessage
            }
            ... on HasNoPermissionError {
                errorMessage
            }
            ... on ActionNotAllowedForAptStatusError {
                errorMessage
            }
            ... on RentalCanNotBeExtendedError {
                errorMessage
            }
        }
    }
`;
export type AddRentalMutationFn = Apollo.MutationFunction<AddRentalMutation, AddRentalMutationVariables>;

/**
 * __useAddRentalMutation__
 *
 * To run a mutation, you first call `useAddRentalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRentalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRentalMutation, { data, loading, error }] = useAddRentalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRentalMutation(
    baseOptions?: Apollo.MutationHookOptions<AddRentalMutation, AddRentalMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<AddRentalMutation, AddRentalMutationVariables>(AddRentalDocument, options);
}
export type AddRentalMutationHookResult = ReturnType<typeof useAddRentalMutation>;
export type AddRentalMutationResult = Apollo.MutationResult<AddRentalMutation>;
export type AddRentalMutationOptions = Apollo.BaseMutationOptions<AddRentalMutation, AddRentalMutationVariables>;
export const BlockAptDocument = gql`
    mutation BlockApt($input: BlockAptInput!) {
        blockApt(input: $input) {
            ... on BlockAptSuccess {
                apt {
                    id
                }
            }
            ... on HasNoPermissionError {
                errorMessage
            }
            ... on AptNotFoundError {
                errorMessage
            }
            ... on ActionNotAllowedForAptStatusError {
                errorMessage
            }
            ... on AddressIsBlockedError {
                errorMessage
            }
            ... on InvalidBlockPeriodError {
                errorMessage
            }
        }
    }
`;
export type BlockAptMutationFn = Apollo.MutationFunction<BlockAptMutation, BlockAptMutationVariables>;

/**
 * __useBlockAptMutation__
 *
 * To run a mutation, you first call `useBlockAptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockAptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockAptMutation, { data, loading, error }] = useBlockAptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBlockAptMutation(
    baseOptions?: Apollo.MutationHookOptions<BlockAptMutation, BlockAptMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<BlockAptMutation, BlockAptMutationVariables>(BlockAptDocument, options);
}
export type BlockAptMutationHookResult = ReturnType<typeof useBlockAptMutation>;
export type BlockAptMutationResult = Apollo.MutationResult<BlockAptMutation>;
export type BlockAptMutationOptions = Apollo.BaseMutationOptions<BlockAptMutation, BlockAptMutationVariables>;
export const BurnListsDocument = gql`
    mutation BurnLists($input: CancelBuyRequestInput!) {
        burnLists(input: $input) {
            ... on BurnListsSuccess {
                buyRequest {
                    id
                    status
                }
            }
            ... on HasNoPermissionError {
                errorMessage
            }
            ... on BuyRequestNotFoundError {
                errorMessage
            }
            ... on BuyRequestCancelledError {
                errorMessage
            }
            ... on BuyRequestNotApprovedError {
                errorMessage
            }
            ... on BuyRequestExecutedError {
                errorMessage
            }
        }
    }
`;
export type BurnListsMutationFn = Apollo.MutationFunction<BurnListsMutation, BurnListsMutationVariables>;

/**
 * __useBurnListsMutation__
 *
 * To run a mutation, you first call `useBurnListsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBurnListsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [burnListsMutation, { data, loading, error }] = useBurnListsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBurnListsMutation(
    baseOptions?: Apollo.MutationHookOptions<BurnListsMutation, BurnListsMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<BurnListsMutation, BurnListsMutationVariables>(BurnListsDocument, options);
}
export type BurnListsMutationHookResult = ReturnType<typeof useBurnListsMutation>;
export type BurnListsMutationResult = Apollo.MutationResult<BurnListsMutation>;
export type BurnListsMutationOptions = Apollo.BaseMutationOptions<BurnListsMutation, BurnListsMutationVariables>;
export const CancelBuyRequestDocument = gql`
    mutation CancelBuyRequest($input: CancelBuyRequestInput!) {
        cancelBuyRequest(input: $input) {
            ... on CancelBuyRequestSuccess {
                buyRequest {
                    id
                    status
                    endTime
                }
            }
            ... on HasNoPermissionError {
                errorMessage
            }
            ... on BuyRequestNotFoundError {
                errorMessage
            }
            ... on BuyRequestCancelledError {
                errorMessage
            }
            ... on BuyRequestExecutedError {
                errorMessage
            }
        }
    }
`;
export type CancelBuyRequestMutationFn = Apollo.MutationFunction<
    CancelBuyRequestMutation,
    CancelBuyRequestMutationVariables
>;

/**
 * __useCancelBuyRequestMutation__
 *
 * To run a mutation, you first call `useCancelBuyRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelBuyRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelBuyRequestMutation, { data, loading, error }] = useCancelBuyRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelBuyRequestMutation(
    baseOptions?: Apollo.MutationHookOptions<CancelBuyRequestMutation, CancelBuyRequestMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CancelBuyRequestMutation, CancelBuyRequestMutationVariables>(
        CancelBuyRequestDocument,
        options
    );
}
export type CancelBuyRequestMutationHookResult = ReturnType<typeof useCancelBuyRequestMutation>;
export type CancelBuyRequestMutationResult = Apollo.MutationResult<CancelBuyRequestMutation>;
export type CancelBuyRequestMutationOptions = Apollo.BaseMutationOptions<
    CancelBuyRequestMutation,
    CancelBuyRequestMutationVariables
>;
export const CancelContestAsNotaryDocument = gql`
    mutation CancelContestAsNotary($input: CancelContestInput!) {
        cancelContestAsNotary(input: $input) {
            ... on CancelContestSuccess {
                apt {
                    id
                    status
                }
            }
            ... on AptNotFoundError {
                errorMessage
            }
            ... on HasNoPermissionError {
                errorMessage
            }
            ... on ActionNotAllowedForAptStatusError {
                errorMessage
                allowedStatuses
            }
        }
    }
`;
export type CancelContestAsNotaryMutationFn = Apollo.MutationFunction<
    CancelContestAsNotaryMutation,
    CancelContestAsNotaryMutationVariables
>;

/**
 * __useCancelContestAsNotaryMutation__
 *
 * To run a mutation, you first call `useCancelContestAsNotaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelContestAsNotaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelContestAsNotaryMutation, { data, loading, error }] = useCancelContestAsNotaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelContestAsNotaryMutation(
    baseOptions?: Apollo.MutationHookOptions<CancelContestAsNotaryMutation, CancelContestAsNotaryMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CancelContestAsNotaryMutation, CancelContestAsNotaryMutationVariables>(
        CancelContestAsNotaryDocument,
        options
    );
}
export type CancelContestAsNotaryMutationHookResult = ReturnType<typeof useCancelContestAsNotaryMutation>;
export type CancelContestAsNotaryMutationResult = Apollo.MutationResult<CancelContestAsNotaryMutation>;
export type CancelContestAsNotaryMutationOptions = Apollo.BaseMutationOptions<
    CancelContestAsNotaryMutation,
    CancelContestAsNotaryMutationVariables
>;
export const CancelContestAsOwnerDocument = gql`
    mutation CancelContestAsOwner($input: CancelContestInput!) {
        cancelContestAsOwner(input: $input) {
            ... on CancelContestSuccess {
                apt {
                    id
                    status
                }
            }
            ... on AptNotFoundError {
                errorMessage
            }
            ... on HasNoPermissionError {
                errorMessage
            }
            ... on ActionNotAllowedForAptStatusError {
                errorMessage
                allowedStatuses
            }
        }
    }
`;
export type CancelContestAsOwnerMutationFn = Apollo.MutationFunction<
    CancelContestAsOwnerMutation,
    CancelContestAsOwnerMutationVariables
>;

/**
 * __useCancelContestAsOwnerMutation__
 *
 * To run a mutation, you first call `useCancelContestAsOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelContestAsOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelContestAsOwnerMutation, { data, loading, error }] = useCancelContestAsOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelContestAsOwnerMutation(
    baseOptions?: Apollo.MutationHookOptions<CancelContestAsOwnerMutation, CancelContestAsOwnerMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CancelContestAsOwnerMutation, CancelContestAsOwnerMutationVariables>(
        CancelContestAsOwnerDocument,
        options
    );
}
export type CancelContestAsOwnerMutationHookResult = ReturnType<typeof useCancelContestAsOwnerMutation>;
export type CancelContestAsOwnerMutationResult = Apollo.MutationResult<CancelContestAsOwnerMutation>;
export type CancelContestAsOwnerMutationOptions = Apollo.BaseMutationOptions<
    CancelContestAsOwnerMutation,
    CancelContestAsOwnerMutationVariables
>;
export const CancelPublicationDocument = gql`
    mutation CancelPublication($input: CancelPublicationInput!) {
        cancelPublication(input: $input) {
            ... on CancelPublicationSuccess {
                apt {
                    id
                    status
                }
            }
            ... on AptNotFoundError {
                errorMessage
            }
            ... on HasNoPermissionError {
                errorMessage
            }
            ... on ActionNotAllowedForAptStatusError {
                errorMessage
            }
        }
    }
`;
export type CancelPublicationMutationFn = Apollo.MutationFunction<
    CancelPublicationMutation,
    CancelPublicationMutationVariables
>;

/**
 * __useCancelPublicationMutation__
 *
 * To run a mutation, you first call `useCancelPublicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPublicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPublicationMutation, { data, loading, error }] = useCancelPublicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelPublicationMutation(
    baseOptions?: Apollo.MutationHookOptions<CancelPublicationMutation, CancelPublicationMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CancelPublicationMutation, CancelPublicationMutationVariables>(
        CancelPublicationDocument,
        options
    );
}
export type CancelPublicationMutationHookResult = ReturnType<typeof useCancelPublicationMutation>;
export type CancelPublicationMutationResult = Apollo.MutationResult<CancelPublicationMutation>;
export type CancelPublicationMutationOptions = Apollo.BaseMutationOptions<
    CancelPublicationMutation,
    CancelPublicationMutationVariables
>;
export const ChangeLoginDocument = gql`
    mutation ChangeLogin($input: ChangeLoginInput!) {
        changeLogin(input: $input) {
            ... on ChangeLoginSuccess {
                verifyToken
            }
            ... on InvalidCredentialsError {
                errorMessage
            }
            ... on InvalidPhoneError {
                errorMessage
            }
            ... on InvalidEmailError {
                errorMessage
            }
            ... on UserAlreadyExistsError {
                errorMessage
            }
            ... on InvalidParamsError {
                errorMessage
            }
        }
    }
`;
export type ChangeLoginMutationFn = Apollo.MutationFunction<ChangeLoginMutation, ChangeLoginMutationVariables>;

/**
 * __useChangeLoginMutation__
 *
 * To run a mutation, you first call `useChangeLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeLoginMutation, { data, loading, error }] = useChangeLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeLoginMutation(
    baseOptions?: Apollo.MutationHookOptions<ChangeLoginMutation, ChangeLoginMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ChangeLoginMutation, ChangeLoginMutationVariables>(ChangeLoginDocument, options);
}
export type ChangeLoginMutationHookResult = ReturnType<typeof useChangeLoginMutation>;
export type ChangeLoginMutationResult = Apollo.MutationResult<ChangeLoginMutation>;
export type ChangeLoginMutationOptions = Apollo.BaseMutationOptions<ChangeLoginMutation, ChangeLoginMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($input: ChangePasswordInput!) {
        changePassword(input: $input) {
            ... on ChangePasswordSuccess {
                me {
                    id
                    hasPassword
                }
            }
            ... on InvalidPasswordError {
                errorMessage
            }
            ... on InvalidCredentialsError {
                errorMessage
            }
        }
    }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(
    baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
>;
export const ConfirmEvictionDocument = gql`
    mutation ConfirmEviction($input: ConfirmEvictionInput!) {
        confirmEviction(input: $input) {
            ... on ConfirmEvictionSuccess {
                apt {
                    id
                    status
                }
            }
            ... on HasNoPermissionError {
                errorMessage
            }
            ... on AptNotFoundError {
                errorMessage
            }
            ... on ActionNotAllowedForAptStatusError {
                errorMessage
            }
            ... on EvictionNotStartedError {
                errorMessage
            }
        }
    }
`;
export type ConfirmEvictionMutationFn = Apollo.MutationFunction<
    ConfirmEvictionMutation,
    ConfirmEvictionMutationVariables
>;

/**
 * __useConfirmEvictionMutation__
 *
 * To run a mutation, you first call `useConfirmEvictionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEvictionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEvictionMutation, { data, loading, error }] = useConfirmEvictionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmEvictionMutation(
    baseOptions?: Apollo.MutationHookOptions<ConfirmEvictionMutation, ConfirmEvictionMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ConfirmEvictionMutation, ConfirmEvictionMutationVariables>(
        ConfirmEvictionDocument,
        options
    );
}
export type ConfirmEvictionMutationHookResult = ReturnType<typeof useConfirmEvictionMutation>;
export type ConfirmEvictionMutationResult = Apollo.MutationResult<ConfirmEvictionMutation>;
export type ConfirmEvictionMutationOptions = Apollo.BaseMutationOptions<
    ConfirmEvictionMutation,
    ConfirmEvictionMutationVariables
>;
export const ConfirmPasswordResetDocument = gql`
    mutation ConfirmPasswordReset($input: ConfirmPasswordResetInput!) {
        confirmPasswordReset(input: $input) {
            ... on ConfirmPasswordResetSuccess {
                token
            }
            ... on InvalidJWTError {
                errorMessage
            }
            ... on InvalidVerifyCodeError {
                errorMessage
            }
            ... on UserNotFoundError {
                errorMessage
            }
            ... on InvalidPasswordError {
                errorMessage
            }
        }
    }
`;
export type ConfirmPasswordResetMutationFn = Apollo.MutationFunction<
    ConfirmPasswordResetMutation,
    ConfirmPasswordResetMutationVariables
>;

/**
 * __useConfirmPasswordResetMutation__
 *
 * To run a mutation, you first call `useConfirmPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmPasswordResetMutation, { data, loading, error }] = useConfirmPasswordResetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmPasswordResetMutation(
    baseOptions?: Apollo.MutationHookOptions<ConfirmPasswordResetMutation, ConfirmPasswordResetMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ConfirmPasswordResetMutation, ConfirmPasswordResetMutationVariables>(
        ConfirmPasswordResetDocument,
        options
    );
}
export type ConfirmPasswordResetMutationHookResult = ReturnType<typeof useConfirmPasswordResetMutation>;
export type ConfirmPasswordResetMutationResult = Apollo.MutationResult<ConfirmPasswordResetMutation>;
export type ConfirmPasswordResetMutationOptions = Apollo.BaseMutationOptions<
    ConfirmPasswordResetMutation,
    ConfirmPasswordResetMutationVariables
>;
export const ConfirmReadinessAsWinnerDocument = gql`
    mutation ConfirmReadinessAsWinner($input: ConfirmReadinessAsWinnerInput!) {
        confirmReadinessAsWinner(input: $input) {
            ... on ConfirmReadinessAsWinnerSuccess {
                apt {
                    id
                    latestAuction {
                        id
                        isWinnerAgree
                    }
                }
            }
            ... on HasNoPermissionError {
                errorMessage
            }
            ... on AptNotFoundError {
                errorMessage
            }
            ... on AptNotTokenizedError {
                errorMessage
            }
            ... on ActionNotAllowedForAptStatusError {
                errorMessage
            }
        }
    }
`;
export type ConfirmReadinessAsWinnerMutationFn = Apollo.MutationFunction<
    ConfirmReadinessAsWinnerMutation,
    ConfirmReadinessAsWinnerMutationVariables
>;

/**
 * __useConfirmReadinessAsWinnerMutation__
 *
 * To run a mutation, you first call `useConfirmReadinessAsWinnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmReadinessAsWinnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmReadinessAsWinnerMutation, { data, loading, error }] = useConfirmReadinessAsWinnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmReadinessAsWinnerMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ConfirmReadinessAsWinnerMutation,
        ConfirmReadinessAsWinnerMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ConfirmReadinessAsWinnerMutation, ConfirmReadinessAsWinnerMutationVariables>(
        ConfirmReadinessAsWinnerDocument,
        options
    );
}
export type ConfirmReadinessAsWinnerMutationHookResult = ReturnType<typeof useConfirmReadinessAsWinnerMutation>;
export type ConfirmReadinessAsWinnerMutationResult = Apollo.MutationResult<ConfirmReadinessAsWinnerMutation>;
export type ConfirmReadinessAsWinnerMutationOptions = Apollo.BaseMutationOptions<
    ConfirmReadinessAsWinnerMutation,
    ConfirmReadinessAsWinnerMutationVariables
>;
export const ConnectByWalletDocument = gql`
    mutation ConnectByWallet($input: ConnectByWalletInput!) {
        connectByWallet(input: $input) {
            ... on ConnectByWalletSuccess {
                qrData
                deepLink
                verificationCode
            }
            ... on DeeplinkNotGeneratedError {
                errorMessage
            }
        }
    }
`;
export type ConnectByWalletMutationFn = Apollo.MutationFunction<
    ConnectByWalletMutation,
    ConnectByWalletMutationVariables
>;

/**
 * __useConnectByWalletMutation__
 *
 * To run a mutation, you first call `useConnectByWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectByWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectByWalletMutation, { data, loading, error }] = useConnectByWalletMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConnectByWalletMutation(
    baseOptions?: Apollo.MutationHookOptions<ConnectByWalletMutation, ConnectByWalletMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ConnectByWalletMutation, ConnectByWalletMutationVariables>(
        ConnectByWalletDocument,
        options
    );
}
export type ConnectByWalletMutationHookResult = ReturnType<typeof useConnectByWalletMutation>;
export type ConnectByWalletMutationResult = Apollo.MutationResult<ConnectByWalletMutation>;
export type ConnectByWalletMutationOptions = Apollo.BaseMutationOptions<
    ConnectByWalletMutation,
    ConnectByWalletMutationVariables
>;
export const DeleteAccountDocument = gql`
    mutation DeleteAccount {
        deleteAccount {
            ... on DeleteAccountSuccess {
                ok
            }
        }
    }
`;
export type DeleteAccountMutationFn = Apollo.MutationFunction<DeleteAccountMutation, DeleteAccountMutationVariables>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteAccountMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument, options);
}
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<
    DeleteAccountMutation,
    DeleteAccountMutationVariables
>;
export const DeleteApartmentDocument = gql`
    mutation DeleteApartment($input: DeleteApartmentInput!) {
        deleteApartment(input: $input) {
            ... on DeleteApartmentSuccess {
                deletedApt {
                    id
                }
            }
            ... on AptNotFoundError {
                errorMessage
            }
            ... on HasNoPermissionError {
                errorMessage
            }
            ... on ActionNotAllowedForAptStatusError {
                errorMessage
            }
        }
    }
`;
export type DeleteApartmentMutationFn = Apollo.MutationFunction<
    DeleteApartmentMutation,
    DeleteApartmentMutationVariables
>;

/**
 * __useDeleteApartmentMutation__
 *
 * To run a mutation, you first call `useDeleteApartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApartmentMutation, { data, loading, error }] = useDeleteApartmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteApartmentMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteApartmentMutation, DeleteApartmentMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteApartmentMutation, DeleteApartmentMutationVariables>(
        DeleteApartmentDocument,
        options
    );
}
export type DeleteApartmentMutationHookResult = ReturnType<typeof useDeleteApartmentMutation>;
export type DeleteApartmentMutationResult = Apollo.MutationResult<DeleteApartmentMutation>;
export type DeleteApartmentMutationOptions = Apollo.BaseMutationOptions<
    DeleteApartmentMutation,
    DeleteApartmentMutationVariables
>;
export const DeleteDocumentDocument = gql`
    mutation DeleteDocument($input: DeleteDocumentInput!) {
        deleteDocument(input: $input) {
            ... on DeleteDocumentSuccess {
                __typename
            }
            ... on AptNotFoundError {
                errorMessage
            }
            ... on DocumentNotFoundError {
                errorMessage
            }
            ... on HasNoPermissionError {
                errorMessage
            }
        }
    }
`;
export type DeleteDocumentMutationFn = Apollo.MutationFunction<DeleteDocumentMutation, DeleteDocumentMutationVariables>;

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDocumentMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteDocumentMutation, DeleteDocumentMutationVariables>(DeleteDocumentDocument, options);
}
export type DeleteDocumentMutationHookResult = ReturnType<typeof useDeleteDocumentMutation>;
export type DeleteDocumentMutationResult = Apollo.MutationResult<DeleteDocumentMutation>;
export type DeleteDocumentMutationOptions = Apollo.BaseMutationOptions<
    DeleteDocumentMutation,
    DeleteDocumentMutationVariables
>;
export const DeleteExpenseDocument = gql`
    mutation DeleteExpense($input: DeleteExpenseInput!) {
        deleteExpense(input: $input) {
            ... on DeleteExpenseSuccess {
                ok
            }
            ... on HasNoPermissionError {
                errorMessage
            }
            ... on ExpenseNotFoundError {
                errorMessage
            }
        }
    }
`;
export type DeleteExpenseMutationFn = Apollo.MutationFunction<DeleteExpenseMutation, DeleteExpenseMutationVariables>;

/**
 * __useDeleteExpenseMutation__
 *
 * To run a mutation, you first call `useDeleteExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExpenseMutation, { data, loading, error }] = useDeleteExpenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteExpenseMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteExpenseMutation, DeleteExpenseMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteExpenseMutation, DeleteExpenseMutationVariables>(DeleteExpenseDocument, options);
}
export type DeleteExpenseMutationHookResult = ReturnType<typeof useDeleteExpenseMutation>;
export type DeleteExpenseMutationResult = Apollo.MutationResult<DeleteExpenseMutation>;
export type DeleteExpenseMutationOptions = Apollo.BaseMutationOptions<
    DeleteExpenseMutation,
    DeleteExpenseMutationVariables
>;
export const DeleteRegionDocumentDocument = gql`
    mutation DeleteRegionDocument($input: DeleteRegionDocumentInput!) {
        deleteRegionDocument(input: $input) {
            ... on DeleteRegionDocumentSuccess {
                ok
            }
            ... on HasNoPermissionError {
                errorMessage
            }
            ... on RegionDocumentNotFoundError {
                errorMessage
            }
        }
    }
`;
export type DeleteRegionDocumentMutationFn = Apollo.MutationFunction<
    DeleteRegionDocumentMutation,
    DeleteRegionDocumentMutationVariables
>;

/**
 * __useDeleteRegionDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteRegionDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRegionDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRegionDocumentMutation, { data, loading, error }] = useDeleteRegionDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRegionDocumentMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteRegionDocumentMutation, DeleteRegionDocumentMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteRegionDocumentMutation, DeleteRegionDocumentMutationVariables>(
        DeleteRegionDocumentDocument,
        options
    );
}
export type DeleteRegionDocumentMutationHookResult = ReturnType<typeof useDeleteRegionDocumentMutation>;
export type DeleteRegionDocumentMutationResult = Apollo.MutationResult<DeleteRegionDocumentMutation>;
export type DeleteRegionDocumentMutationOptions = Apollo.BaseMutationOptions<
    DeleteRegionDocumentMutation,
    DeleteRegionDocumentMutationVariables
>;
export const MarkAllNotificationsAsReadDocument = gql`
    mutation MarkAllNotificationsAsRead {
        markAllNotificationsAsRead {
            ... on MarkAllNotificationsAsReadSuccess {
                me {
                    id
                    countNewNotifications
                }
            }
        }
    }
`;
export type MarkAllNotificationsAsReadMutationFn = Apollo.MutationFunction<
    MarkAllNotificationsAsReadMutation,
    MarkAllNotificationsAsReadMutationVariables
>;

/**
 * __useMarkAllNotificationsAsReadMutation__
 *
 * To run a mutation, you first call `useMarkAllNotificationsAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllNotificationsAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllNotificationsAsReadMutation, { data, loading, error }] = useMarkAllNotificationsAsReadMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkAllNotificationsAsReadMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MarkAllNotificationsAsReadMutation,
        MarkAllNotificationsAsReadMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<MarkAllNotificationsAsReadMutation, MarkAllNotificationsAsReadMutationVariables>(
        MarkAllNotificationsAsReadDocument,
        options
    );
}
export type MarkAllNotificationsAsReadMutationHookResult = ReturnType<typeof useMarkAllNotificationsAsReadMutation>;
export type MarkAllNotificationsAsReadMutationResult = Apollo.MutationResult<MarkAllNotificationsAsReadMutation>;
export type MarkAllNotificationsAsReadMutationOptions = Apollo.BaseMutationOptions<
    MarkAllNotificationsAsReadMutation,
    MarkAllNotificationsAsReadMutationVariables
>;
export const MarkApartmentAsViewedDocument = gql`
    mutation MarkApartmentAsViewed($input: MarkApartmentAsViewedInput!) {
        markApartmentAsViewed(input: $input) {
            ... on MarkApartmentAsViewedSuccess {
                apt {
                    id
                    isNewForRd
                }
            }
            ... on AptNotFoundError {
                errorMessage
            }
            ... on HasNoPermissionError {
                errorMessage
            }
        }
    }
`;
export type MarkApartmentAsViewedMutationFn = Apollo.MutationFunction<
    MarkApartmentAsViewedMutation,
    MarkApartmentAsViewedMutationVariables
>;

/**
 * __useMarkApartmentAsViewedMutation__
 *
 * To run a mutation, you first call `useMarkApartmentAsViewedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkApartmentAsViewedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markApartmentAsViewedMutation, { data, loading, error }] = useMarkApartmentAsViewedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkApartmentAsViewedMutation(
    baseOptions?: Apollo.MutationHookOptions<MarkApartmentAsViewedMutation, MarkApartmentAsViewedMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<MarkApartmentAsViewedMutation, MarkApartmentAsViewedMutationVariables>(
        MarkApartmentAsViewedDocument,
        options
    );
}
export type MarkApartmentAsViewedMutationHookResult = ReturnType<typeof useMarkApartmentAsViewedMutation>;
export type MarkApartmentAsViewedMutationResult = Apollo.MutationResult<MarkApartmentAsViewedMutation>;
export type MarkApartmentAsViewedMutationOptions = Apollo.BaseMutationOptions<
    MarkApartmentAsViewedMutation,
    MarkApartmentAsViewedMutationVariables
>;
export const MarkBuyRequestAsViewedDocument = gql`
    mutation MarkBuyRequestAsViewed($input: MarkBuyRequestAsViewedInput!) {
        markBuyRequestAsViewed(input: $input) {
            ... on MarkBuyRequestAsViewedSuccess {
                buyRequest {
                    id
                    isNewForRd
                }
            }
            ... on BuyRequestNotFoundError {
                errorMessage
            }
            ... on HasNoPermissionError {
                errorMessage
            }
        }
    }
`;
export type MarkBuyRequestAsViewedMutationFn = Apollo.MutationFunction<
    MarkBuyRequestAsViewedMutation,
    MarkBuyRequestAsViewedMutationVariables
>;

/**
 * __useMarkBuyRequestAsViewedMutation__
 *
 * To run a mutation, you first call `useMarkBuyRequestAsViewedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkBuyRequestAsViewedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markBuyRequestAsViewedMutation, { data, loading, error }] = useMarkBuyRequestAsViewedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkBuyRequestAsViewedMutation(
    baseOptions?: Apollo.MutationHookOptions<MarkBuyRequestAsViewedMutation, MarkBuyRequestAsViewedMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<MarkBuyRequestAsViewedMutation, MarkBuyRequestAsViewedMutationVariables>(
        MarkBuyRequestAsViewedDocument,
        options
    );
}
export type MarkBuyRequestAsViewedMutationHookResult = ReturnType<typeof useMarkBuyRequestAsViewedMutation>;
export type MarkBuyRequestAsViewedMutationResult = Apollo.MutationResult<MarkBuyRequestAsViewedMutation>;
export type MarkBuyRequestAsViewedMutationOptions = Apollo.BaseMutationOptions<
    MarkBuyRequestAsViewedMutation,
    MarkBuyRequestAsViewedMutationVariables
>;
export const MarkNotificationAsReadDocument = gql`
    mutation MarkNotificationAsRead($input: MarkNotificationAsReadInput!) {
        markNotificationAsRead(input: $input) {
            ... on MarkNotificationAsReadSuccess {
                notification {
                    id
                    isNew
                }
                me {
                    id
                    countNewNotifications
                }
            }
            ... on HasNoPermissionError {
                errorMessage
            }
            ... on NotificationNotFoundError {
                errorMessage
            }
        }
    }
`;
export type MarkNotificationAsReadMutationFn = Apollo.MutationFunction<
    MarkNotificationAsReadMutation,
    MarkNotificationAsReadMutationVariables
>;

/**
 * __useMarkNotificationAsReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationAsReadMutation, { data, loading, error }] = useMarkNotificationAsReadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkNotificationAsReadMutation(
    baseOptions?: Apollo.MutationHookOptions<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>(
        MarkNotificationAsReadDocument,
        options
    );
}
export type MarkNotificationAsReadMutationHookResult = ReturnType<typeof useMarkNotificationAsReadMutation>;
export type MarkNotificationAsReadMutationResult = Apollo.MutationResult<MarkNotificationAsReadMutation>;
export type MarkNotificationAsReadMutationOptions = Apollo.BaseMutationOptions<
    MarkNotificationAsReadMutation,
    MarkNotificationAsReadMutationVariables
>;
export const PublishApartmentDocument = gql`
    mutation PublishApartment($input: PublishApartmentInput!) {
        publishApartment(input: $input) {
            ... on PublishApartmentSuccess {
                apt {
                    ...PublishApartmentAptData
                }
            }
            ... on AptNotFoundError {
                errorMessage
            }
            ... on HasNoPermissionError {
                errorMessage
            }
            ... on ActionNotAllowedForAptStatusError {
                errorMessage
            }
            ... on AddressIsBlockedError {
                errorMessage
            }
            ... on ApartmentAlreadyExistsError {
                errorMessage
            }
            ... on MissedRequiredFieldError {
                errorMessage
            }
        }
    }
    ${PublishApartmentAptDataFragmentDoc}
`;
export type PublishApartmentMutationFn = Apollo.MutationFunction<
    PublishApartmentMutation,
    PublishApartmentMutationVariables
>;

/**
 * __usePublishApartmentMutation__
 *
 * To run a mutation, you first call `usePublishApartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishApartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishApartmentMutation, { data, loading, error }] = usePublishApartmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishApartmentMutation(
    baseOptions?: Apollo.MutationHookOptions<PublishApartmentMutation, PublishApartmentMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<PublishApartmentMutation, PublishApartmentMutationVariables>(
        PublishApartmentDocument,
        options
    );
}
export type PublishApartmentMutationHookResult = ReturnType<typeof usePublishApartmentMutation>;
export type PublishApartmentMutationResult = Apollo.MutationResult<PublishApartmentMutation>;
export type PublishApartmentMutationOptions = Apollo.BaseMutationOptions<
    PublishApartmentMutation,
    PublishApartmentMutationVariables
>;
export const RefuseToWinDocument = gql`
    mutation RefuseToWin($input: RefuseToWinInput!) {
        refuseToWin(input: $input) {
            ... on RefuseToWinSuccess {
                apt {
                    id
                    status
                    latestAuction {
                        id
                        winnerAccount
                    }
                }
            }
            ... on AptNotFoundError {
                errorMessage
            }
            ... on HasNoPermissionError {
                errorMessage
            }
            ... on ActionNotAllowedForAptStatusError {
                errorMessage
            }
        }
    }
`;
export type RefuseToWinMutationFn = Apollo.MutationFunction<RefuseToWinMutation, RefuseToWinMutationVariables>;

/**
 * __useRefuseToWinMutation__
 *
 * To run a mutation, you first call `useRefuseToWinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefuseToWinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refuseToWinMutation, { data, loading, error }] = useRefuseToWinMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefuseToWinMutation(
    baseOptions?: Apollo.MutationHookOptions<RefuseToWinMutation, RefuseToWinMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<RefuseToWinMutation, RefuseToWinMutationVariables>(RefuseToWinDocument, options);
}
export type RefuseToWinMutationHookResult = ReturnType<typeof useRefuseToWinMutation>;
export type RefuseToWinMutationResult = Apollo.MutationResult<RefuseToWinMutation>;
export type RefuseToWinMutationOptions = Apollo.BaseMutationOptions<RefuseToWinMutation, RefuseToWinMutationVariables>;
export const RemoveLoginDocument = gql`
    mutation RemoveLogin($input: RemoveLoginInput!) {
        removeLogin(input: $input) {
            ... on RemoveLoginSuccess {
                me {
                    id
                    email
                    phone
                    eosAccount
                }
            }
            ... on CantDeleteLoginError {
                errorMessage
            }
        }
    }
`;
export type RemoveLoginMutationFn = Apollo.MutationFunction<RemoveLoginMutation, RemoveLoginMutationVariables>;

/**
 * __useRemoveLoginMutation__
 *
 * To run a mutation, you first call `useRemoveLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLoginMutation, { data, loading, error }] = useRemoveLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveLoginMutation(
    baseOptions?: Apollo.MutationHookOptions<RemoveLoginMutation, RemoveLoginMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<RemoveLoginMutation, RemoveLoginMutationVariables>(RemoveLoginDocument, options);
}
export type RemoveLoginMutationHookResult = ReturnType<typeof useRemoveLoginMutation>;
export type RemoveLoginMutationResult = Apollo.MutationResult<RemoveLoginMutation>;
export type RemoveLoginMutationOptions = Apollo.BaseMutationOptions<RemoveLoginMutation, RemoveLoginMutationVariables>;
export const ReportAddressCheckErrorDocument = gql`
    mutation ReportAddressCheckError($input: ReportAddressCheckErrorInput!) {
        reportAddressCheckError(input: $input) {
            ... on ReportAddressCheckErrorSuccess {
                ok
            }
            ... on PlaceNotFoundError {
                errorMessage
            }
            ... on UserNotFoundError {
                errorMessage
            }
            ... on AddressAlreadyInAreaError {
                errorMessage
            }
            ... on ReportAlreadyRegisteredError {
                errorMessage
            }
        }
    }
`;
export type ReportAddressCheckErrorMutationFn = Apollo.MutationFunction<
    ReportAddressCheckErrorMutation,
    ReportAddressCheckErrorMutationVariables
>;

/**
 * __useReportAddressCheckErrorMutation__
 *
 * To run a mutation, you first call `useReportAddressCheckErrorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportAddressCheckErrorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportAddressCheckErrorMutation, { data, loading, error }] = useReportAddressCheckErrorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportAddressCheckErrorMutation(
    baseOptions?: Apollo.MutationHookOptions<ReportAddressCheckErrorMutation, ReportAddressCheckErrorMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ReportAddressCheckErrorMutation, ReportAddressCheckErrorMutationVariables>(
        ReportAddressCheckErrorDocument,
        options
    );
}
export type ReportAddressCheckErrorMutationHookResult = ReturnType<typeof useReportAddressCheckErrorMutation>;
export type ReportAddressCheckErrorMutationResult = Apollo.MutationResult<ReportAddressCheckErrorMutation>;
export type ReportAddressCheckErrorMutationOptions = Apollo.BaseMutationOptions<
    ReportAddressCheckErrorMutation,
    ReportAddressCheckErrorMutationVariables
>;
export const ReportMisCoordsErrorDocument = gql`
    mutation ReportMisCoordsError($input: ReportMisCoordsErrorInput!) {
        reportMisCoordsError(input: $input) {
            ... on ReportMisCoordsErrorSuccess {
                ok
            }
            ... on PlaceNotFoundError {
                errorMessage
            }
            ... on ReportAlreadyRegisteredError {
                errorMessage
            }
        }
    }
`;
export type ReportMisCoordsErrorMutationFn = Apollo.MutationFunction<
    ReportMisCoordsErrorMutation,
    ReportMisCoordsErrorMutationVariables
>;

/**
 * __useReportMisCoordsErrorMutation__
 *
 * To run a mutation, you first call `useReportMisCoordsErrorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportMisCoordsErrorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportMisCoordsErrorMutation, { data, loading, error }] = useReportMisCoordsErrorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportMisCoordsErrorMutation(
    baseOptions?: Apollo.MutationHookOptions<ReportMisCoordsErrorMutation, ReportMisCoordsErrorMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ReportMisCoordsErrorMutation, ReportMisCoordsErrorMutationVariables>(
        ReportMisCoordsErrorDocument,
        options
    );
}
export type ReportMisCoordsErrorMutationHookResult = ReturnType<typeof useReportMisCoordsErrorMutation>;
export type ReportMisCoordsErrorMutationResult = Apollo.MutationResult<ReportMisCoordsErrorMutation>;
export type ReportMisCoordsErrorMutationOptions = Apollo.BaseMutationOptions<
    ReportMisCoordsErrorMutation,
    ReportMisCoordsErrorMutationVariables
>;
export const ResendCodeDocument = gql`
    mutation ResendCode {
        resendCode {
            ... on ResendCodeSuccess {
                verifyToken
            }
            ... on InvalidJWTError {
                errorMessage
            }
            ... on NoResendAttemptsError {
                errorMessage
            }
        }
    }
`;
export type ResendCodeMutationFn = Apollo.MutationFunction<ResendCodeMutation, ResendCodeMutationVariables>;

/**
 * __useResendCodeMutation__
 *
 * To run a mutation, you first call `useResendCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendCodeMutation, { data, loading, error }] = useResendCodeMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendCodeMutation(
    baseOptions?: Apollo.MutationHookOptions<ResendCodeMutation, ResendCodeMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ResendCodeMutation, ResendCodeMutationVariables>(ResendCodeDocument, options);
}
export type ResendCodeMutationHookResult = ReturnType<typeof useResendCodeMutation>;
export type ResendCodeMutationResult = Apollo.MutationResult<ResendCodeMutation>;
export type ResendCodeMutationOptions = Apollo.BaseMutationOptions<ResendCodeMutation, ResendCodeMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
        resetPassword(input: $input) {
            ... on ResetPasswordSuccess {
                verifyToken
                nextStep
            }
            ... on LoginIsEmptyError {
                errorMessage
            }
            ... on UserNotFoundError {
                errorMessage
            }
            ... on NoResendAttemptsError {
                errorMessage
            }
            ... on InvalidPhoneError {
                errorMessage
            }
            ... on InvalidEmailError {
                errorMessage
            }
        }
    }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(
    baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
>;
export const ResolveReviewDocument = gql`
    mutation ResolveReview($input: ReviewApartmentInput!) {
        resolveReview(input: $input) {
            ... on ResolveReviewSuccess {
                apt {
                    id
                    review {
                        ...ApartmentReview
                    }
                }
            }
            ... on AptNotFoundError {
                errorMessage
            }
            ... on HasNoPermissionError {
                errorMessage
            }
            ... on ActionNotAllowedForAptStatusError {
                errorMessage
            }
            ... on ReviewNotFoundError {
                errorMessage
            }
        }
    }
    ${ApartmentReviewFragmentDoc}
`;
export type ResolveReviewMutationFn = Apollo.MutationFunction<ResolveReviewMutation, ResolveReviewMutationVariables>;

/**
 * __useResolveReviewMutation__
 *
 * To run a mutation, you first call `useResolveReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveReviewMutation, { data, loading, error }] = useResolveReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResolveReviewMutation(
    baseOptions?: Apollo.MutationHookOptions<ResolveReviewMutation, ResolveReviewMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ResolveReviewMutation, ResolveReviewMutationVariables>(ResolveReviewDocument, options);
}
export type ResolveReviewMutationHookResult = ReturnType<typeof useResolveReviewMutation>;
export type ResolveReviewMutationResult = Apollo.MutationResult<ResolveReviewMutation>;
export type ResolveReviewMutationOptions = Apollo.BaseMutationOptions<
    ResolveReviewMutation,
    ResolveReviewMutationVariables
>;
export const ReviewApartmentDocument = gql`
    mutation ReviewApartment($input: ReviewApartmentInput!) {
        reviewApartment(input: $input) {
            ... on ReviewApartmentSuccess {
                apt {
                    id
                    status
                }
            }
            ... on AptNotFoundError {
                errorMessage
            }
            ... on HasNoPermissionError {
                errorMessage
            }
            ... on ActionNotAllowedForAptStatusError {
                errorMessage
            }
        }
    }
`;
export type ReviewApartmentMutationFn = Apollo.MutationFunction<
    ReviewApartmentMutation,
    ReviewApartmentMutationVariables
>;

/**
 * __useReviewApartmentMutation__
 *
 * To run a mutation, you first call `useReviewApartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewApartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewApartmentMutation, { data, loading, error }] = useReviewApartmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewApartmentMutation(
    baseOptions?: Apollo.MutationHookOptions<ReviewApartmentMutation, ReviewApartmentMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ReviewApartmentMutation, ReviewApartmentMutationVariables>(
        ReviewApartmentDocument,
        options
    );
}
export type ReviewApartmentMutationHookResult = ReturnType<typeof useReviewApartmentMutation>;
export type ReviewApartmentMutationResult = Apollo.MutationResult<ReviewApartmentMutation>;
export type ReviewApartmentMutationOptions = Apollo.BaseMutationOptions<
    ReviewApartmentMutation,
    ReviewApartmentMutationVariables
>;
export const SaveApartmentDocument = gql`
    mutation SaveApartment($input: SaveApartmentInput!) {
        saveApartment(input: $input) {
            ... on SaveApartmentSuccess {
                apartment {
                    id
                    status
                }
            }
            ... on AptNotFoundError {
                errorMessage
            }
            ... on HasNoPermissionError {
                errorMessage
            }
            ... on OutOfPolygonError {
                errorMessage
            }
            ... on PlaceNotFoundError {
                errorMessage
            }
            ... on AddressIsNotFullError {
                errorMessage
            }
            ... on AddressIsBlockedError {
                errorMessage
            }
            ... on EosAccountNotExistsError {
                errorMessage
            }
            ... on InvalidCommunalPaymentError {
                errorMessage
            }
        }
    }
`;
export type SaveApartmentMutationFn = Apollo.MutationFunction<SaveApartmentMutation, SaveApartmentMutationVariables>;

/**
 * __useSaveApartmentMutation__
 *
 * To run a mutation, you first call `useSaveApartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveApartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveApartmentMutation, { data, loading, error }] = useSaveApartmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveApartmentMutation(
    baseOptions?: Apollo.MutationHookOptions<SaveApartmentMutation, SaveApartmentMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<SaveApartmentMutation, SaveApartmentMutationVariables>(SaveApartmentDocument, options);
}
export type SaveApartmentMutationHookResult = ReturnType<typeof useSaveApartmentMutation>;
export type SaveApartmentMutationResult = Apollo.MutationResult<SaveApartmentMutation>;
export type SaveApartmentMutationOptions = Apollo.BaseMutationOptions<
    SaveApartmentMutation,
    SaveApartmentMutationVariables
>;
export const SetLanguageDocument = gql`
    mutation SetLanguage($input: SetLanguageInput!) {
        setLanguage(input: $input) {
            ... on SetLanguageSuccess {
                ok
            }
        }
    }
`;
export type SetLanguageMutationFn = Apollo.MutationFunction<SetLanguageMutation, SetLanguageMutationVariables>;

/**
 * __useSetLanguageMutation__
 *
 * To run a mutation, you first call `useSetLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLanguageMutation, { data, loading, error }] = useSetLanguageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetLanguageMutation(
    baseOptions?: Apollo.MutationHookOptions<SetLanguageMutation, SetLanguageMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<SetLanguageMutation, SetLanguageMutationVariables>(SetLanguageDocument, options);
}
export type SetLanguageMutationHookResult = ReturnType<typeof useSetLanguageMutation>;
export type SetLanguageMutationResult = Apollo.MutationResult<SetLanguageMutation>;
export type SetLanguageMutationOptions = Apollo.BaseMutationOptions<SetLanguageMutation, SetLanguageMutationVariables>;
export const SignInDocument = gql`
    mutation SignIn($input: SigninInput!) {
        signin(input: $input) {
            ... on SigninSuccess {
                token
            }
            ... on LoginIsEmptyError {
                errorMessage
            }
            ... on InvalidCredentialsError {
                errorMessage
            }
        }
    }
`;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
}
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const SignupDocument = gql`
    mutation Signup($input: SignupInput!) {
        signup(input: $input) {
            ... on SignupSuccess {
                verifyToken
                nextStep
            }
            ... on LoginIsEmptyError {
                errorMessage
            }
            ... on UserAlreadyExistsError {
                errorMessage
            }
            ... on InvalidEmailError {
                errorMessage
            }
            ... on InvalidPhoneError {
                errorMessage
            }
            ... on InvalidPasswordError {
                errorMessage
            }
            ... on NoResendAttemptsError {
                errorMessage
            }
        }
    }
`;
export type SignupMutationFn = Apollo.MutationFunction<SignupMutation, SignupMutationVariables>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: Apollo.MutationHookOptions<SignupMutation, SignupMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, options);
}
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<SignupMutation, SignupMutationVariables>;
export const StartAuctionDocument = gql`
    mutation StartAuction($input: StartAuctionInput!) {
        startAuction(input: $input) {
            ... on StartAuctionSuccess {
                apt {
                    id
                }
            }
            ... on HasNoPermissionError {
                errorMessage
            }
            ... on AptNotFoundError {
                errorMessage
            }
            ... on AptNotReviewedError {
                errorMessage
            }
            ... on ActionNotAllowedForAptStatusError {
                errorMessage
            }
            ... on InvalidExpenseValueError {
                errorMessage
            }
            ... on ExpenseNotFoundError {
                errorMessage
            }
            ... on InvalidExpensesListError {
                errorMessage
            }
            ... on InvalidCommunalPaymentError {
                errorMessage
            }
        }
    }
`;
export type StartAuctionMutationFn = Apollo.MutationFunction<StartAuctionMutation, StartAuctionMutationVariables>;

/**
 * __useStartAuctionMutation__
 *
 * To run a mutation, you first call `useStartAuctionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartAuctionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startAuctionMutation, { data, loading, error }] = useStartAuctionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartAuctionMutation(
    baseOptions?: Apollo.MutationHookOptions<StartAuctionMutation, StartAuctionMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<StartAuctionMutation, StartAuctionMutationVariables>(StartAuctionDocument, options);
}
export type StartAuctionMutationHookResult = ReturnType<typeof useStartAuctionMutation>;
export type StartAuctionMutationResult = Apollo.MutationResult<StartAuctionMutation>;
export type StartAuctionMutationOptions = Apollo.BaseMutationOptions<
    StartAuctionMutation,
    StartAuctionMutationVariables
>;
export const StartEvictionAsNotaryDocument = gql`
    mutation StartEvictionAsNotary($input: StartEvictionInput!) {
        startEvictionAsNotary(input: $input) {
            ... on StartEvictionSuccess {
                apt {
                    id
                    status
                }
            }
            ... on HasNoPermissionError {
                errorMessage
            }
            ... on AptNotFoundError {
                errorMessage
            }
            ... on ActionNotAllowedForAptStatusError {
                errorMessage
            }
            ... on EvictionAlreadyStartedError {
                errorMessage
            }
            ... on InvalidParamsError {
                errorMessage
            }
        }
    }
`;
export type StartEvictionAsNotaryMutationFn = Apollo.MutationFunction<
    StartEvictionAsNotaryMutation,
    StartEvictionAsNotaryMutationVariables
>;

/**
 * __useStartEvictionAsNotaryMutation__
 *
 * To run a mutation, you first call `useStartEvictionAsNotaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartEvictionAsNotaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startEvictionAsNotaryMutation, { data, loading, error }] = useStartEvictionAsNotaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartEvictionAsNotaryMutation(
    baseOptions?: Apollo.MutationHookOptions<StartEvictionAsNotaryMutation, StartEvictionAsNotaryMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<StartEvictionAsNotaryMutation, StartEvictionAsNotaryMutationVariables>(
        StartEvictionAsNotaryDocument,
        options
    );
}
export type StartEvictionAsNotaryMutationHookResult = ReturnType<typeof useStartEvictionAsNotaryMutation>;
export type StartEvictionAsNotaryMutationResult = Apollo.MutationResult<StartEvictionAsNotaryMutation>;
export type StartEvictionAsNotaryMutationOptions = Apollo.BaseMutationOptions<
    StartEvictionAsNotaryMutation,
    StartEvictionAsNotaryMutationVariables
>;
export const StartEvictionAsTenantDocument = gql`
    mutation StartEvictionAsTenant($input: StartEvictionInput!) {
        startEvictionAsTenant(input: $input) {
            ... on StartEvictionSuccess {
                apt {
                    id
                    status
                }
            }
            ... on HasNoPermissionError {
                errorMessage
            }
            ... on AptNotFoundError {
                errorMessage
            }
            ... on ActionNotAllowedForAptStatusError {
                errorMessage
            }
            ... on EvictionAlreadyStartedError {
                errorMessage
            }
            ... on InvalidParamsError {
                errorMessage
            }
        }
    }
`;
export type StartEvictionAsTenantMutationFn = Apollo.MutationFunction<
    StartEvictionAsTenantMutation,
    StartEvictionAsTenantMutationVariables
>;

/**
 * __useStartEvictionAsTenantMutation__
 *
 * To run a mutation, you first call `useStartEvictionAsTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartEvictionAsTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startEvictionAsTenantMutation, { data, loading, error }] = useStartEvictionAsTenantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartEvictionAsTenantMutation(
    baseOptions?: Apollo.MutationHookOptions<StartEvictionAsTenantMutation, StartEvictionAsTenantMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<StartEvictionAsTenantMutation, StartEvictionAsTenantMutationVariables>(
        StartEvictionAsTenantDocument,
        options
    );
}
export type StartEvictionAsTenantMutationHookResult = ReturnType<typeof useStartEvictionAsTenantMutation>;
export type StartEvictionAsTenantMutationResult = Apollo.MutationResult<StartEvictionAsTenantMutation>;
export type StartEvictionAsTenantMutationOptions = Apollo.BaseMutationOptions<
    StartEvictionAsTenantMutation,
    StartEvictionAsTenantMutationVariables
>;
export const UnblockAptDocument = gql`
    mutation UnblockApt($input: UnblockAptInput!) {
        unblockApt(input: $input) {
            ... on UnblockAptSuccess {
                apt {
                    id
                }
            }
            ... on HasNoPermissionError {
                errorMessage
            }
            ... on AptNotFoundError {
                errorMessage
            }
            ... on ActionNotAllowedForAptStatusError {
                errorMessage
            }
        }
    }
`;
export type UnblockAptMutationFn = Apollo.MutationFunction<UnblockAptMutation, UnblockAptMutationVariables>;

/**
 * __useUnblockAptMutation__
 *
 * To run a mutation, you first call `useUnblockAptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnblockAptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unblockAptMutation, { data, loading, error }] = useUnblockAptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnblockAptMutation(
    baseOptions?: Apollo.MutationHookOptions<UnblockAptMutation, UnblockAptMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UnblockAptMutation, UnblockAptMutationVariables>(UnblockAptDocument, options);
}
export type UnblockAptMutationHookResult = ReturnType<typeof useUnblockAptMutation>;
export type UnblockAptMutationResult = Apollo.MutationResult<UnblockAptMutation>;
export type UnblockAptMutationOptions = Apollo.BaseMutationOptions<UnblockAptMutation, UnblockAptMutationVariables>;
export const UpdateAptFavoritesDocument = gql`
    mutation UpdateAptFavorites($input: UpdateAptFavoriteInput!) {
        updateAptFavorites(input: $input) {
            ... on UpdateAptFavoriteSuccess {
                apt {
                    id
                    isFavorite
                }
            }
            ... on AptNotFoundError {
                errorMessage
            }
        }
    }
`;
export type UpdateAptFavoritesMutationFn = Apollo.MutationFunction<
    UpdateAptFavoritesMutation,
    UpdateAptFavoritesMutationVariables
>;

/**
 * __useUpdateAptFavoritesMutation__
 *
 * To run a mutation, you first call `useUpdateAptFavoritesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAptFavoritesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAptFavoritesMutation, { data, loading, error }] = useUpdateAptFavoritesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAptFavoritesMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateAptFavoritesMutation, UpdateAptFavoritesMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateAptFavoritesMutation, UpdateAptFavoritesMutationVariables>(
        UpdateAptFavoritesDocument,
        options
    );
}
export type UpdateAptFavoritesMutationHookResult = ReturnType<typeof useUpdateAptFavoritesMutation>;
export type UpdateAptFavoritesMutationResult = Apollo.MutationResult<UpdateAptFavoritesMutation>;
export type UpdateAptFavoritesMutationOptions = Apollo.BaseMutationOptions<
    UpdateAptFavoritesMutation,
    UpdateAptFavoritesMutationVariables
>;
export const UpdateAptRealIdDocument = gql`
    mutation UpdateAptRealId($input: UpdateAptRealIdInput!) {
        updateAptRealId(input: $input) {
            ... on UpdateAptRealIdSuccess {
                apt {
                    id
                    realId
                }
            }
            ... on HasNoPermissionError {
                errorMessage
            }
            ... on AptNotFoundError {
                errorMessage
            }
            ... on ActionNotAllowedForAptStatusError {
                errorMessage
            }
        }
    }
`;
export type UpdateAptRealIdMutationFn = Apollo.MutationFunction<
    UpdateAptRealIdMutation,
    UpdateAptRealIdMutationVariables
>;

/**
 * __useUpdateAptRealIdMutation__
 *
 * To run a mutation, you first call `useUpdateAptRealIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAptRealIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAptRealIdMutation, { data, loading, error }] = useUpdateAptRealIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAptRealIdMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateAptRealIdMutation, UpdateAptRealIdMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateAptRealIdMutation, UpdateAptRealIdMutationVariables>(
        UpdateAptRealIdDocument,
        options
    );
}
export type UpdateAptRealIdMutationHookResult = ReturnType<typeof useUpdateAptRealIdMutation>;
export type UpdateAptRealIdMutationResult = Apollo.MutationResult<UpdateAptRealIdMutation>;
export type UpdateAptRealIdMutationOptions = Apollo.BaseMutationOptions<
    UpdateAptRealIdMutation,
    UpdateAptRealIdMutationVariables
>;
export const UpdateExpenseDocument = gql`
    mutation UpdateExpense($input: UpdateExpenseInput!) {
        updateExpense(input: $input) {
            ... on UpdateExpenseSuccess {
                expense {
                    ...ManagerExpense
                }
            }
            ... on HasNoPermissionError {
                errorMessage
            }
            ... on ExpenseNotFoundError {
                errorMessage
            }
        }
    }
    ${ManagerExpenseFragmentDoc}
`;
export type UpdateExpenseMutationFn = Apollo.MutationFunction<UpdateExpenseMutation, UpdateExpenseMutationVariables>;

/**
 * __useUpdateExpenseMutation__
 *
 * To run a mutation, you first call `useUpdateExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExpenseMutation, { data, loading, error }] = useUpdateExpenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExpenseMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateExpenseMutation, UpdateExpenseMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateExpenseMutation, UpdateExpenseMutationVariables>(UpdateExpenseDocument, options);
}
export type UpdateExpenseMutationHookResult = ReturnType<typeof useUpdateExpenseMutation>;
export type UpdateExpenseMutationResult = Apollo.MutationResult<UpdateExpenseMutation>;
export type UpdateExpenseMutationOptions = Apollo.BaseMutationOptions<
    UpdateExpenseMutation,
    UpdateExpenseMutationVariables
>;
export const UpdateNotifySettingsDocument = gql`
    mutation UpdateNotifySettings($input: UpdateNotifySettingsInput!) {
        updateNotifySettings(input: $input) {
            ... on UpdateNotifySettingsSuccess {
                me {
                    ...MeNotificationSettingsFragment
                }
            }
        }
    }
    ${MeNotificationSettingsFragmentFragmentDoc}
`;
export type UpdateNotifySettingsMutationFn = Apollo.MutationFunction<
    UpdateNotifySettingsMutation,
    UpdateNotifySettingsMutationVariables
>;

/**
 * __useUpdateNotifySettingsMutation__
 *
 * To run a mutation, you first call `useUpdateNotifySettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotifySettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotifySettingsMutation, { data, loading, error }] = useUpdateNotifySettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNotifySettingsMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateNotifySettingsMutation, UpdateNotifySettingsMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateNotifySettingsMutation, UpdateNotifySettingsMutationVariables>(
        UpdateNotifySettingsDocument,
        options
    );
}
export type UpdateNotifySettingsMutationHookResult = ReturnType<typeof useUpdateNotifySettingsMutation>;
export type UpdateNotifySettingsMutationResult = Apollo.MutationResult<UpdateNotifySettingsMutation>;
export type UpdateNotifySettingsMutationOptions = Apollo.BaseMutationOptions<
    UpdateNotifySettingsMutation,
    UpdateNotifySettingsMutationVariables
>;
export const UpdatePhotosOrderDocument = gql`
    mutation UpdatePhotosOrder($input: UpdatePhotosOrderInput!) {
        updatePhotosOrder(input: $input) {
            ... on UpdatePhotosOrderSuccess {
                __typename
            }
            ... on HasNoPermissionError {
                errorMessage
            }
            ... on AptNotFoundError {
                errorMessage
            }
            ... on ActionNotAllowedForAptStatusError {
                errorMessage
            }
            ... on DocumentNotFoundError {
                errorMessage
            }
        }
    }
`;
export type UpdatePhotosOrderMutationFn = Apollo.MutationFunction<
    UpdatePhotosOrderMutation,
    UpdatePhotosOrderMutationVariables
>;

/**
 * __useUpdatePhotosOrderMutation__
 *
 * To run a mutation, you first call `useUpdatePhotosOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhotosOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhotosOrderMutation, { data, loading, error }] = useUpdatePhotosOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePhotosOrderMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdatePhotosOrderMutation, UpdatePhotosOrderMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdatePhotosOrderMutation, UpdatePhotosOrderMutationVariables>(
        UpdatePhotosOrderDocument,
        options
    );
}
export type UpdatePhotosOrderMutationHookResult = ReturnType<typeof useUpdatePhotosOrderMutation>;
export type UpdatePhotosOrderMutationResult = Apollo.MutationResult<UpdatePhotosOrderMutation>;
export type UpdatePhotosOrderMutationOptions = Apollo.BaseMutationOptions<
    UpdatePhotosOrderMutation,
    UpdatePhotosOrderMutationVariables
>;
export const UpdateRdContactsDocument = gql`
    mutation UpdateRdContacts($input: UpdateRdContactsInput!) {
        updateRdContacts(input: $input) {
            ... on UpdateRdContactsSuccess {
                city {
                    id
                    notaryContactEmail
                    notaryContactPhone
                }
            }
            ... on InvalidPhoneError {
                errorMessage
            }
            ... on InvalidEmailError {
                errorMessage
            }
            ... on CityNotFoundError {
                errorMessage
            }
            ... on HasNoPermissionError {
                errorMessage
            }
        }
    }
`;
export type UpdateRdContactsMutationFn = Apollo.MutationFunction<
    UpdateRdContactsMutation,
    UpdateRdContactsMutationVariables
>;

/**
 * __useUpdateRdContactsMutation__
 *
 * To run a mutation, you first call `useUpdateRdContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRdContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRdContactsMutation, { data, loading, error }] = useUpdateRdContactsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRdContactsMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateRdContactsMutation, UpdateRdContactsMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateRdContactsMutation, UpdateRdContactsMutationVariables>(
        UpdateRdContactsDocument,
        options
    );
}
export type UpdateRdContactsMutationHookResult = ReturnType<typeof useUpdateRdContactsMutation>;
export type UpdateRdContactsMutationResult = Apollo.MutationResult<UpdateRdContactsMutation>;
export type UpdateRdContactsMutationOptions = Apollo.BaseMutationOptions<
    UpdateRdContactsMutation,
    UpdateRdContactsMutationVariables
>;
export const UpdateRegionDocumentDocument = gql`
    mutation UpdateRegionDocument($input: UpdateRegionDocumentInput!) {
        updateRegionDocument(input: $input) {
            ... on UpdateRegionDocumentSuccess {
                regionDocument {
                    ...ManagerDocument
                }
            }
            ... on HasNoPermissionError {
                errorMessage
            }
            ... on RegionDocumentNotFoundError {
                errorMessage
            }
        }
    }
    ${ManagerDocumentFragmentDoc}
`;
export type UpdateRegionDocumentMutationFn = Apollo.MutationFunction<
    UpdateRegionDocumentMutation,
    UpdateRegionDocumentMutationVariables
>;

/**
 * __useUpdateRegionDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateRegionDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRegionDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRegionDocumentMutation, { data, loading, error }] = useUpdateRegionDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRegionDocumentMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateRegionDocumentMutation, UpdateRegionDocumentMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateRegionDocumentMutation, UpdateRegionDocumentMutationVariables>(
        UpdateRegionDocumentDocument,
        options
    );
}
export type UpdateRegionDocumentMutationHookResult = ReturnType<typeof useUpdateRegionDocumentMutation>;
export type UpdateRegionDocumentMutationResult = Apollo.MutationResult<UpdateRegionDocumentMutation>;
export type UpdateRegionDocumentMutationOptions = Apollo.BaseMutationOptions<
    UpdateRegionDocumentMutation,
    UpdateRegionDocumentMutationVariables
>;
export const VerifyCodeDocument = gql`
    mutation VerifyCode($input: VerifyCodeInput!) {
        verifyCode(input: $input) {
            ... on VerifyCodeSuccess {
                token
                me {
                    id
                    email
                    phone
                    eosAccount
                }
            }
            ... on InvalidJWTError {
                errorMessage
            }
            ... on InvalidVerifyCodeError {
                errorMessage
            }
            ... on VerifyCodeExpiredError {
                errorMessage
            }
            ... on UserAlreadyExistsError {
                errorMessage
            }
            ... on VerifyCodeNoCheckAttemptsError {
                errorMessage
            }
        }
    }
`;
export type VerifyCodeMutationFn = Apollo.MutationFunction<VerifyCodeMutation, VerifyCodeMutationVariables>;

/**
 * __useVerifyCodeMutation__
 *
 * To run a mutation, you first call `useVerifyCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyCodeMutation, { data, loading, error }] = useVerifyCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyCodeMutation(
    baseOptions?: Apollo.MutationHookOptions<VerifyCodeMutation, VerifyCodeMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<VerifyCodeMutation, VerifyCodeMutationVariables>(VerifyCodeDocument, options);
}
export type VerifyCodeMutationHookResult = ReturnType<typeof useVerifyCodeMutation>;
export type VerifyCodeMutationResult = Apollo.MutationResult<VerifyCodeMutation>;
export type VerifyCodeMutationOptions = Apollo.BaseMutationOptions<VerifyCodeMutation, VerifyCodeMutationVariables>;
export const WithdrawFromContestDocument = gql`
    mutation WithdrawFromContest($input: WithdrawFromContestInput!) {
        withdrawFromContest(input: $input) {
            ... on WithdrawFromContestSuccess {
                apt {
                    id
                    status
                    number
                    latestAuction {
                        id
                        hasMyBid
                    }
                }
            }
            ... on AptNotFoundError {
                errorMessage
            }
            ... on HasNoPermissionError {
                errorMessage
            }
            ... on ActionNotAllowedForAptStatusError {
                errorMessage
            }
            ... on ContestPaymentNotFoundError {
                errorMessage
            }
        }
    }
`;
export type WithdrawFromContestMutationFn = Apollo.MutationFunction<
    WithdrawFromContestMutation,
    WithdrawFromContestMutationVariables
>;

/**
 * __useWithdrawFromContestMutation__
 *
 * To run a mutation, you first call `useWithdrawFromContestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawFromContestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawFromContestMutation, { data, loading, error }] = useWithdrawFromContestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWithdrawFromContestMutation(
    baseOptions?: Apollo.MutationHookOptions<WithdrawFromContestMutation, WithdrawFromContestMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<WithdrawFromContestMutation, WithdrawFromContestMutationVariables>(
        WithdrawFromContestDocument,
        options
    );
}
export type WithdrawFromContestMutationHookResult = ReturnType<typeof useWithdrawFromContestMutation>;
export type WithdrawFromContestMutationResult = Apollo.MutationResult<WithdrawFromContestMutation>;
export type WithdrawFromContestMutationOptions = Apollo.BaseMutationOptions<
    WithdrawFromContestMutation,
    WithdrawFromContestMutationVariables
>;
export const AddApartmentConfigDocument = gql`
    query AddApartmentConfig {
        addApartmentConfig {
            minPhotosQuantity
            maxUploadFileSize
        }
    }
`;

/**
 * __useAddApartmentConfigQuery__
 *
 * To run a query within a React component, call `useAddApartmentConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddApartmentConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddApartmentConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useAddApartmentConfigQuery(
    baseOptions?: Apollo.QueryHookOptions<AddApartmentConfigQuery, AddApartmentConfigQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<AddApartmentConfigQuery, AddApartmentConfigQueryVariables>(
        AddApartmentConfigDocument,
        options
    );
}
export function useAddApartmentConfigLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<AddApartmentConfigQuery, AddApartmentConfigQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<AddApartmentConfigQuery, AddApartmentConfigQueryVariables>(
        AddApartmentConfigDocument,
        options
    );
}
export type AddApartmentConfigQueryHookResult = ReturnType<typeof useAddApartmentConfigQuery>;
export type AddApartmentConfigLazyQueryHookResult = ReturnType<typeof useAddApartmentConfigLazyQuery>;
export type AddApartmentConfigQueryResult = Apollo.QueryResult<
    AddApartmentConfigQuery,
    AddApartmentConfigQueryVariables
>;
export const AddBuyoutRequestParamsDocument = gql`
    query AddBuyoutRequestParams {
        cities(filters: { hasTokenized: true }) {
            id
            name
            token {
                name
            }
        }
        buyRequestConfig {
            feePercent
            minTransferVolume
        }
    }
`;

/**
 * __useAddBuyoutRequestParamsQuery__
 *
 * To run a query within a React component, call `useAddBuyoutRequestParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddBuyoutRequestParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddBuyoutRequestParamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAddBuyoutRequestParamsQuery(
    baseOptions?: Apollo.QueryHookOptions<AddBuyoutRequestParamsQuery, AddBuyoutRequestParamsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<AddBuyoutRequestParamsQuery, AddBuyoutRequestParamsQueryVariables>(
        AddBuyoutRequestParamsDocument,
        options
    );
}
export function useAddBuyoutRequestParamsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<AddBuyoutRequestParamsQuery, AddBuyoutRequestParamsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<AddBuyoutRequestParamsQuery, AddBuyoutRequestParamsQueryVariables>(
        AddBuyoutRequestParamsDocument,
        options
    );
}
export type AddBuyoutRequestParamsQueryHookResult = ReturnType<typeof useAddBuyoutRequestParamsQuery>;
export type AddBuyoutRequestParamsLazyQueryHookResult = ReturnType<typeof useAddBuyoutRequestParamsLazyQuery>;
export type AddBuyoutRequestParamsQueryResult = Apollo.QueryResult<
    AddBuyoutRequestParamsQuery,
    AddBuyoutRequestParamsQueryVariables
>;
export const ApartmentDocument = gql`
    query Apartment($id: Int!) {
        apartment(id: $id) {
            id
            realId
            description
            status
            isFavorite
            floor
            rooms
            isStudio
            m2
            livingM2
            kitchenM2
            isPublic
            ceilingHeight
            hasYardWindows
            hasStreetWindows
            combinedBaths
            dividedBaths
            balconies
            hasLoggia
            number
            numberOfFloors
            hasElevator
            hasCargoElevator
            hasGarbageChute
            hasSecurity
            hasConcierge
            hasIntercom
            hasVideoSurveillance
            houseType
            buildingYear
            hasGas
            hasGroundParking
            hasUndergroundParking
            hasMultilevelParking
            tenure
            aptType
            ownerAccount
            refundAccount
            isIAmOwner
            isIAmNotary
            communalCurrency
            totalExpenses
            buyRequest {
                id
                endTime
                buyerAccount
            }
            documents {
                id
                link
                name
                originalName
            }
            place {
                id
                address
                lon
                lat
            }
            city {
                id
                name
                isNew
                country {
                    id
                    name
                }
            }
            subways {
                id
                way
                minutes
                subway {
                    id
                    name
                }
            }
            housingComplex
            actualRental {
                id
                expirationDate
                isExpired
                canBeExtended
                isIAmTenant
                isEvictionStartedByTenant
                evictionReason
            }
            isTokenized
            tokenizationInfo {
                isSignedByOwner
                isSignedByWinner
                isSignedByNotary
            }
            latestAuction {
                id
                winnerAccount
                hasMyBid
                isIAmWinner
                isWinnerAgree
                deadlineForWinner
                deadlineToPayCommunal
                startDate
                endOfActivePhase
                isWinnerSelectionInProgress
                bidsNumber {
                    active
                    cancelled
                }
                paymentInfo {
                    payment
                    token
                }
            }
            lock {
                reason
                reasonType
                lockEndDate
            }
            photos {
                id
                link(size: SIZE_1920x1080)
            }
        }
    }
`;

/**
 * __useApartmentQuery__
 *
 * To run a query within a React component, call `useApartmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useApartmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApartmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApartmentQuery(baseOptions: Apollo.QueryHookOptions<ApartmentQuery, ApartmentQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ApartmentQuery, ApartmentQueryVariables>(ApartmentDocument, options);
}
export function useApartmentLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ApartmentQuery, ApartmentQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ApartmentQuery, ApartmentQueryVariables>(ApartmentDocument, options);
}
export type ApartmentQueryHookResult = ReturnType<typeof useApartmentQuery>;
export type ApartmentLazyQueryHookResult = ReturnType<typeof useApartmentLazyQuery>;
export type ApartmentQueryResult = Apollo.QueryResult<ApartmentQuery, ApartmentQueryVariables>;
export const ApartmentsDocument = gql`
    query Apartments(
        $filters: ApartmentFilterInput
        $first: Int
        $offset: Int
        $favorites: Boolean = false
        $sort: [ApartmentSortEnum]
        $onlyTotalCount: Boolean = false
    ) {
        apartments(filters: $filters, first: $first, offset: $offset, favorites: $favorites, sort: $sort) {
            pageInfo {
                total
            }
            apartments @skip(if: $onlyTotalCount) {
                ...PublicApartmentListNode
            }
        }
    }
    ${PublicApartmentListNodeFragmentDoc}
`;

/**
 * __useApartmentsQuery__
 *
 * To run a query within a React component, call `useApartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApartmentsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      favorites: // value for 'favorites'
 *      sort: // value for 'sort'
 *      onlyTotalCount: // value for 'onlyTotalCount'
 *   },
 * });
 */
export function useApartmentsQuery(baseOptions?: Apollo.QueryHookOptions<ApartmentsQuery, ApartmentsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ApartmentsQuery, ApartmentsQueryVariables>(ApartmentsDocument, options);
}
export function useApartmentsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ApartmentsQuery, ApartmentsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ApartmentsQuery, ApartmentsQueryVariables>(ApartmentsDocument, options);
}
export type ApartmentsQueryHookResult = ReturnType<typeof useApartmentsQuery>;
export type ApartmentsLazyQueryHookResult = ReturnType<typeof useApartmentsLazyQuery>;
export type ApartmentsQueryResult = Apollo.QueryResult<ApartmentsQuery, ApartmentsQueryVariables>;
export const ApartmentAddRentalParamsDocument = gql`
    query ApartmentAddRentalParams($id: Int!) {
        apartment(id: $id) {
            id
            communalPayment
            communalCurrency
            totalExpenses
            number
            latestAuction {
                id
                winnerAccount
                endDate
            }
            actualRental {
                id
                tenantAccount
                price
                startDate
                expirationDate
                expirationAfterExtend
                renewStartTime
            }
            place {
                id
                address
            }
            city {
                id
                name
            }
        }
        aptExpenses(aptId: $id) {
            expense {
                id
                name
            }
            value
        }
    }
`;

/**
 * __useApartmentAddRentalParamsQuery__
 *
 * To run a query within a React component, call `useApartmentAddRentalParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApartmentAddRentalParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApartmentAddRentalParamsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApartmentAddRentalParamsQuery(
    baseOptions: Apollo.QueryHookOptions<ApartmentAddRentalParamsQuery, ApartmentAddRentalParamsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ApartmentAddRentalParamsQuery, ApartmentAddRentalParamsQueryVariables>(
        ApartmentAddRentalParamsDocument,
        options
    );
}
export function useApartmentAddRentalParamsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ApartmentAddRentalParamsQuery, ApartmentAddRentalParamsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ApartmentAddRentalParamsQuery, ApartmentAddRentalParamsQueryVariables>(
        ApartmentAddRentalParamsDocument,
        options
    );
}
export type ApartmentAddRentalParamsQueryHookResult = ReturnType<typeof useApartmentAddRentalParamsQuery>;
export type ApartmentAddRentalParamsLazyQueryHookResult = ReturnType<typeof useApartmentAddRentalParamsLazyQuery>;
export type ApartmentAddRentalParamsQueryResult = Apollo.QueryResult<
    ApartmentAddRentalParamsQuery,
    ApartmentAddRentalParamsQueryVariables
>;
export const ApartmentAutocompleteDocument = gql`
    query ApartmentAutocomplete($filters: ApartmentFilterInput) {
        apartments(filters: $filters, first: 1) {
            apartments {
                id
                houseType
                buildingYear
                housingComplex
                numberOfFloors
                hasElevator
                hasCargoElevator
                hasGarbageChute
                hasSecurity
                hasConcierge
                hasIntercom
                hasVideoSurveillance
                hasGas
                hasGroundParking
                hasUndergroundParking
                hasMultilevelParking
            }
        }
    }
`;

/**
 * __useApartmentAutocompleteQuery__
 *
 * To run a query within a React component, call `useApartmentAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useApartmentAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApartmentAutocompleteQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useApartmentAutocompleteQuery(
    baseOptions?: Apollo.QueryHookOptions<ApartmentAutocompleteQuery, ApartmentAutocompleteQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ApartmentAutocompleteQuery, ApartmentAutocompleteQueryVariables>(
        ApartmentAutocompleteDocument,
        options
    );
}
export function useApartmentAutocompleteLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ApartmentAutocompleteQuery, ApartmentAutocompleteQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ApartmentAutocompleteQuery, ApartmentAutocompleteQueryVariables>(
        ApartmentAutocompleteDocument,
        options
    );
}
export type ApartmentAutocompleteQueryHookResult = ReturnType<typeof useApartmentAutocompleteQuery>;
export type ApartmentAutocompleteLazyQueryHookResult = ReturnType<typeof useApartmentAutocompleteLazyQuery>;
export type ApartmentAutocompleteQueryResult = Apollo.QueryResult<
    ApartmentAutocompleteQuery,
    ApartmentAutocompleteQueryVariables
>;
export const ApartmentContetsPaymentDetailsDocument = gql`
    query ApartmentContetsPaymentDetails($id: Int!) {
        apartment(id: $id) {
            id
            latestAuction {
                id
                paymentDeepLink
                paymentJson
                paymentInfo {
                    payment
                    paymentAfterTax
                    memo
                    smartContract
                    token
                    platformAccount
                }
            }
        }
    }
`;

/**
 * __useApartmentContetsPaymentDetailsQuery__
 *
 * To run a query within a React component, call `useApartmentContetsPaymentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApartmentContetsPaymentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApartmentContetsPaymentDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApartmentContetsPaymentDetailsQuery(
    baseOptions: Apollo.QueryHookOptions<
        ApartmentContetsPaymentDetailsQuery,
        ApartmentContetsPaymentDetailsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ApartmentContetsPaymentDetailsQuery, ApartmentContetsPaymentDetailsQueryVariables>(
        ApartmentContetsPaymentDetailsDocument,
        options
    );
}
export function useApartmentContetsPaymentDetailsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ApartmentContetsPaymentDetailsQuery,
        ApartmentContetsPaymentDetailsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ApartmentContetsPaymentDetailsQuery, ApartmentContetsPaymentDetailsQueryVariables>(
        ApartmentContetsPaymentDetailsDocument,
        options
    );
}
export type ApartmentContetsPaymentDetailsQueryHookResult = ReturnType<typeof useApartmentContetsPaymentDetailsQuery>;
export type ApartmentContetsPaymentDetailsLazyQueryHookResult = ReturnType<
    typeof useApartmentContetsPaymentDetailsLazyQuery
>;
export type ApartmentContetsPaymentDetailsQueryResult = Apollo.QueryResult<
    ApartmentContetsPaymentDetailsQuery,
    ApartmentContetsPaymentDetailsQueryVariables
>;
export const ApartmentEmissionParamsDocument = gql`
    query ApartmentEmissionParams($id: Int!) {
        apartment(id: $id) {
            id
            realId
            number
            emissionVolume {
                amount
                token
                fee
            }
            buyRequest {
                tokenId
                feeAmount
            }
            place {
                id
                address
            }
            city {
                id
                name
            }
            tokenizationInfo {
                emissionDeepLink
            }
        }
    }
`;

/**
 * __useApartmentEmissionParamsQuery__
 *
 * To run a query within a React component, call `useApartmentEmissionParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApartmentEmissionParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApartmentEmissionParamsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApartmentEmissionParamsQuery(
    baseOptions: Apollo.QueryHookOptions<ApartmentEmissionParamsQuery, ApartmentEmissionParamsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ApartmentEmissionParamsQuery, ApartmentEmissionParamsQueryVariables>(
        ApartmentEmissionParamsDocument,
        options
    );
}
export function useApartmentEmissionParamsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ApartmentEmissionParamsQuery, ApartmentEmissionParamsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ApartmentEmissionParamsQuery, ApartmentEmissionParamsQueryVariables>(
        ApartmentEmissionParamsDocument,
        options
    );
}
export type ApartmentEmissionParamsQueryHookResult = ReturnType<typeof useApartmentEmissionParamsQuery>;
export type ApartmentEmissionParamsLazyQueryHookResult = ReturnType<typeof useApartmentEmissionParamsLazyQuery>;
export type ApartmentEmissionParamsQueryResult = Apollo.QueryResult<
    ApartmentEmissionParamsQuery,
    ApartmentEmissionParamsQueryVariables
>;
export const ApartmentExpensesDocument = gql`
    query ApartmentExpenses($id: Int!) {
        apartment(id: $id) {
            id
            communalCurrency
            communalPayment
            totalExpenses
        }
        aptExpenses(aptId: $id) {
            value
            expense {
                id
                name
            }
        }
    }
`;

/**
 * __useApartmentExpensesQuery__
 *
 * To run a query within a React component, call `useApartmentExpensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useApartmentExpensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApartmentExpensesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApartmentExpensesQuery(
    baseOptions: Apollo.QueryHookOptions<ApartmentExpensesQuery, ApartmentExpensesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ApartmentExpensesQuery, ApartmentExpensesQueryVariables>(ApartmentExpensesDocument, options);
}
export function useApartmentExpensesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ApartmentExpensesQuery, ApartmentExpensesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ApartmentExpensesQuery, ApartmentExpensesQueryVariables>(
        ApartmentExpensesDocument,
        options
    );
}
export type ApartmentExpensesQueryHookResult = ReturnType<typeof useApartmentExpensesQuery>;
export type ApartmentExpensesLazyQueryHookResult = ReturnType<typeof useApartmentExpensesLazyQuery>;
export type ApartmentExpensesQueryResult = Apollo.QueryResult<ApartmentExpensesQuery, ApartmentExpensesQueryVariables>;
export const ApartmentRealIdDocument = gql`
    query ApartmentRealId($id: Int!) {
        apartment(id: $id) {
            id
            realId
        }
    }
`;

/**
 * __useApartmentRealIdQuery__
 *
 * To run a query within a React component, call `useApartmentRealIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useApartmentRealIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApartmentRealIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApartmentRealIdQuery(
    baseOptions: Apollo.QueryHookOptions<ApartmentRealIdQuery, ApartmentRealIdQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ApartmentRealIdQuery, ApartmentRealIdQueryVariables>(ApartmentRealIdDocument, options);
}
export function useApartmentRealIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ApartmentRealIdQuery, ApartmentRealIdQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ApartmentRealIdQuery, ApartmentRealIdQueryVariables>(ApartmentRealIdDocument, options);
}
export type ApartmentRealIdQueryHookResult = ReturnType<typeof useApartmentRealIdQuery>;
export type ApartmentRealIdLazyQueryHookResult = ReturnType<typeof useApartmentRealIdLazyQuery>;
export type ApartmentRealIdQueryResult = Apollo.QueryResult<ApartmentRealIdQuery, ApartmentRealIdQueryVariables>;
export const ApartmentSignTokenizationParamsDocument = gql`
    query ApartmentSignTokenizationParams($id: Int!) {
        apartment(id: $id) {
            id
            ownerAccount
            realId
            number
            buyRequest {
                tokenId
                feeAmount
            }
            emissionVolume {
                amount
                token
                fee
            }
            place {
                id
                address
            }
            city {
                id
                name
                notaryEosAccount
            }
            latestAuction {
                id
                winnerAccount
            }
            tokenizationInfo {
                isSignedByOwner
                isSignedByWinner
                isSignedByNotary
                winnerSignDeepLink
                ownerSignDeepLink
                notarySignDeepLink
            }
        }
    }
`;

/**
 * __useApartmentSignTokenizationParamsQuery__
 *
 * To run a query within a React component, call `useApartmentSignTokenizationParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApartmentSignTokenizationParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApartmentSignTokenizationParamsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApartmentSignTokenizationParamsQuery(
    baseOptions: Apollo.QueryHookOptions<
        ApartmentSignTokenizationParamsQuery,
        ApartmentSignTokenizationParamsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ApartmentSignTokenizationParamsQuery, ApartmentSignTokenizationParamsQueryVariables>(
        ApartmentSignTokenizationParamsDocument,
        options
    );
}
export function useApartmentSignTokenizationParamsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ApartmentSignTokenizationParamsQuery,
        ApartmentSignTokenizationParamsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ApartmentSignTokenizationParamsQuery, ApartmentSignTokenizationParamsQueryVariables>(
        ApartmentSignTokenizationParamsDocument,
        options
    );
}
export type ApartmentSignTokenizationParamsQueryHookResult = ReturnType<typeof useApartmentSignTokenizationParamsQuery>;
export type ApartmentSignTokenizationParamsLazyQueryHookResult = ReturnType<
    typeof useApartmentSignTokenizationParamsLazyQuery
>;
export type ApartmentSignTokenizationParamsQueryResult = Apollo.QueryResult<
    ApartmentSignTokenizationParamsQuery,
    ApartmentSignTokenizationParamsQueryVariables
>;
export const AuctionParticipantsDocument = gql`
    query AuctionParticipants($id: Int!) {
        apartment(id: $id) {
            id
            status
            isTokenized
            latestAuction {
                id
                endOfActivePhase
                winnerAccount
                winnerRank
                isWinnerSelectionInProgress
                isIAmWinner
                startDate
                bidsNumber {
                    active
                    cancelled
                }
                transactions {
                    id
                    isWinner
                    sender
                }
            }
        }
    }
`;

/**
 * __useAuctionParticipantsQuery__
 *
 * To run a query within a React component, call `useAuctionParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuctionParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuctionParticipantsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAuctionParticipantsQuery(
    baseOptions: Apollo.QueryHookOptions<AuctionParticipantsQuery, AuctionParticipantsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<AuctionParticipantsQuery, AuctionParticipantsQueryVariables>(
        AuctionParticipantsDocument,
        options
    );
}
export function useAuctionParticipantsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<AuctionParticipantsQuery, AuctionParticipantsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<AuctionParticipantsQuery, AuctionParticipantsQueryVariables>(
        AuctionParticipantsDocument,
        options
    );
}
export type AuctionParticipantsQueryHookResult = ReturnType<typeof useAuctionParticipantsQuery>;
export type AuctionParticipantsLazyQueryHookResult = ReturnType<typeof useAuctionParticipantsLazyQuery>;
export type AuctionParticipantsQueryResult = Apollo.QueryResult<
    AuctionParticipantsQuery,
    AuctionParticipantsQueryVariables
>;
export const AvailableCitiesDocument = gql`
    query AvailableCities {
        cities(filters: { availableOnly: true }, sort: [COUNTRY_NAME, CITY_NAME]) {
            id
            name
            centerLon
            centerLat
            country {
                id
                name
                currencyCode
            }
            countryCode
        }
    }
`;

/**
 * __useAvailableCitiesQuery__
 *
 * To run a query within a React component, call `useAvailableCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableCitiesQuery(
    baseOptions?: Apollo.QueryHookOptions<AvailableCitiesQuery, AvailableCitiesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<AvailableCitiesQuery, AvailableCitiesQueryVariables>(AvailableCitiesDocument, options);
}
export function useAvailableCitiesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<AvailableCitiesQuery, AvailableCitiesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<AvailableCitiesQuery, AvailableCitiesQueryVariables>(AvailableCitiesDocument, options);
}
export type AvailableCitiesQueryHookResult = ReturnType<typeof useAvailableCitiesQuery>;
export type AvailableCitiesLazyQueryHookResult = ReturnType<typeof useAvailableCitiesLazyQuery>;
export type AvailableCitiesQueryResult = Apollo.QueryResult<AvailableCitiesQuery, AvailableCitiesQueryVariables>;
export const BuyAptParamsDocument = gql`
    query BuyAptParams($amountToPay: String!, $tokenId: String!) {
        buyAptParams(amountToPay: $amountToPay, tokenId: $tokenId) {
            paymentParams
            deeplink
        }
    }
`;

/**
 * __useBuyAptParamsQuery__
 *
 * To run a query within a React component, call `useBuyAptParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyAptParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyAptParamsQuery({
 *   variables: {
 *      amountToPay: // value for 'amountToPay'
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useBuyAptParamsQuery(
    baseOptions: Apollo.QueryHookOptions<BuyAptParamsQuery, BuyAptParamsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<BuyAptParamsQuery, BuyAptParamsQueryVariables>(BuyAptParamsDocument, options);
}
export function useBuyAptParamsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<BuyAptParamsQuery, BuyAptParamsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<BuyAptParamsQuery, BuyAptParamsQueryVariables>(BuyAptParamsDocument, options);
}
export type BuyAptParamsQueryHookResult = ReturnType<typeof useBuyAptParamsQuery>;
export type BuyAptParamsLazyQueryHookResult = ReturnType<typeof useBuyAptParamsLazyQuery>;
export type BuyAptParamsQueryResult = Apollo.QueryResult<BuyAptParamsQuery, BuyAptParamsQueryVariables>;
export const CancelBuyoutParamsDocument = gql`
    query CancelBuyoutParams($buyRequestId: Int!) {
        buyRequest(id: $buyRequestId) {
            id
            changeAmount
            feeAmount
            tokenId
            apt {
                id
                number
                place {
                    id
                    address
                    city {
                        id
                        name
                    }
                }
            }
        }
    }
`;

/**
 * __useCancelBuyoutParamsQuery__
 *
 * To run a query within a React component, call `useCancelBuyoutParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCancelBuyoutParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCancelBuyoutParamsQuery({
 *   variables: {
 *      buyRequestId: // value for 'buyRequestId'
 *   },
 * });
 */
export function useCancelBuyoutParamsQuery(
    baseOptions: Apollo.QueryHookOptions<CancelBuyoutParamsQuery, CancelBuyoutParamsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CancelBuyoutParamsQuery, CancelBuyoutParamsQueryVariables>(
        CancelBuyoutParamsDocument,
        options
    );
}
export function useCancelBuyoutParamsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CancelBuyoutParamsQuery, CancelBuyoutParamsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CancelBuyoutParamsQuery, CancelBuyoutParamsQueryVariables>(
        CancelBuyoutParamsDocument,
        options
    );
}
export type CancelBuyoutParamsQueryHookResult = ReturnType<typeof useCancelBuyoutParamsQuery>;
export type CancelBuyoutParamsLazyQueryHookResult = ReturnType<typeof useCancelBuyoutParamsLazyQuery>;
export type CancelBuyoutParamsQueryResult = Apollo.QueryResult<
    CancelBuyoutParamsQuery,
    CancelBuyoutParamsQueryVariables
>;
export const CheckAddressDocument = gql`
    query CheckAddress($aptNumber: Int!, $placeId: Int!) {
        checkAddress(aptNumber: $aptNumber, placeId: $placeId) {
            isAvailable
            lock {
                ...ApartmentLock
            }
        }
    }
    ${ApartmentLockFragmentDoc}
`;

/**
 * __useCheckAddressQuery__
 *
 * To run a query within a React component, call `useCheckAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckAddressQuery({
 *   variables: {
 *      aptNumber: // value for 'aptNumber'
 *      placeId: // value for 'placeId'
 *   },
 * });
 */
export function useCheckAddressQuery(
    baseOptions: Apollo.QueryHookOptions<CheckAddressQuery, CheckAddressQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CheckAddressQuery, CheckAddressQueryVariables>(CheckAddressDocument, options);
}
export function useCheckAddressLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CheckAddressQuery, CheckAddressQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CheckAddressQuery, CheckAddressQueryVariables>(CheckAddressDocument, options);
}
export type CheckAddressQueryHookResult = ReturnType<typeof useCheckAddressQuery>;
export type CheckAddressLazyQueryHookResult = ReturnType<typeof useCheckAddressLazyQuery>;
export type CheckAddressQueryResult = Apollo.QueryResult<CheckAddressQuery, CheckAddressQueryVariables>;
export const CitiesDocument = gql`
    query Cities($filters: CityFilterInput, $sort: [CitySortEnum]) {
        cities(filters: $filters, sort: $sort) {
            id
            name
            countryCode
            isAvailable
            hasApartments
            country {
                id
                name
            }
        }
    }
`;

/**
 * __useCitiesQuery__
 *
 * To run a query within a React component, call `useCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCitiesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useCitiesQuery(baseOptions?: Apollo.QueryHookOptions<CitiesQuery, CitiesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CitiesQuery, CitiesQueryVariables>(CitiesDocument, options);
}
export function useCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CitiesQuery, CitiesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CitiesQuery, CitiesQueryVariables>(CitiesDocument, options);
}
export type CitiesQueryHookResult = ReturnType<typeof useCitiesQuery>;
export type CitiesLazyQueryHookResult = ReturnType<typeof useCitiesLazyQuery>;
export type CitiesQueryResult = Apollo.QueryResult<CitiesQuery, CitiesQueryVariables>;
export const CitiesManagedByMeDocument = gql`
    query CitiesManagedByMe {
        me {
            id
            citiesManagedByMe {
                id
                name
                country {
                    id
                    name
                }
                countryCode
                notaryUpdates {
                    ...NotaryUpdates
                }
            }
        }
    }
    ${NotaryUpdatesFragmentDoc}
`;

/**
 * __useCitiesManagedByMeQuery__
 *
 * To run a query within a React component, call `useCitiesManagedByMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCitiesManagedByMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCitiesManagedByMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useCitiesManagedByMeQuery(
    baseOptions?: Apollo.QueryHookOptions<CitiesManagedByMeQuery, CitiesManagedByMeQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CitiesManagedByMeQuery, CitiesManagedByMeQueryVariables>(CitiesManagedByMeDocument, options);
}
export function useCitiesManagedByMeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CitiesManagedByMeQuery, CitiesManagedByMeQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CitiesManagedByMeQuery, CitiesManagedByMeQueryVariables>(
        CitiesManagedByMeDocument,
        options
    );
}
export type CitiesManagedByMeQueryHookResult = ReturnType<typeof useCitiesManagedByMeQuery>;
export type CitiesManagedByMeLazyQueryHookResult = ReturnType<typeof useCitiesManagedByMeLazyQuery>;
export type CitiesManagedByMeQueryResult = Apollo.QueryResult<CitiesManagedByMeQuery, CitiesManagedByMeQueryVariables>;
export const CityPolygonDocument = gql`
    query CityPolygon($id: Int!) {
        city(id: $id) {
            id
            polygon
        }
    }
`;

/**
 * __useCityPolygonQuery__
 *
 * To run a query within a React component, call `useCityPolygonQuery` and pass it any options that fit your needs.
 * When your component renders, `useCityPolygonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCityPolygonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCityPolygonQuery(baseOptions: Apollo.QueryHookOptions<CityPolygonQuery, CityPolygonQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CityPolygonQuery, CityPolygonQueryVariables>(CityPolygonDocument, options);
}
export function useCityPolygonLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CityPolygonQuery, CityPolygonQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CityPolygonQuery, CityPolygonQueryVariables>(CityPolygonDocument, options);
}
export type CityPolygonQueryHookResult = ReturnType<typeof useCityPolygonQuery>;
export type CityPolygonLazyQueryHookResult = ReturnType<typeof useCityPolygonLazyQuery>;
export type CityPolygonQueryResult = Apollo.QueryResult<CityPolygonQuery, CityPolygonQueryVariables>;
export const CommonStatisticDocument = gql`
    query CommonStatistic {
        commonStatistic {
            statisticDetails {
                ...StatisticNode
            }
            totalTokenizedM2
            citiesShares {
                ...CityTokenizationShareNode
            }
        }
    }
    ${StatisticNodeFragmentDoc}
    ${CityTokenizationShareNodeFragmentDoc}
`;

/**
 * __useCommonStatisticQuery__
 *
 * To run a query within a React component, call `useCommonStatisticQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommonStatisticQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommonStatisticQuery({
 *   variables: {
 *   },
 * });
 */
export function useCommonStatisticQuery(
    baseOptions?: Apollo.QueryHookOptions<CommonStatisticQuery, CommonStatisticQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CommonStatisticQuery, CommonStatisticQueryVariables>(CommonStatisticDocument, options);
}
export function useCommonStatisticLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CommonStatisticQuery, CommonStatisticQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CommonStatisticQuery, CommonStatisticQueryVariables>(CommonStatisticDocument, options);
}
export type CommonStatisticQueryHookResult = ReturnType<typeof useCommonStatisticQuery>;
export type CommonStatisticLazyQueryHookResult = ReturnType<typeof useCommonStatisticLazyQuery>;
export type CommonStatisticQueryResult = Apollo.QueryResult<CommonStatisticQuery, CommonStatisticQueryVariables>;
export const ConfirmBuyoutParamsDocument = gql`
    query ConfirmBuyoutParams($buyRequestId: Int!) {
        buyRequest(id: $buyRequestId) {
            id
            tokenId
            buyerApproveDeeplink
            changeAmount
            apt {
                id
                status
                number
                place {
                    id
                    address
                    city {
                        id
                        name
                    }
                }
                actualRental {
                    id
                    expirationDate
                }
            }
        }
    }
`;

/**
 * __useConfirmBuyoutParamsQuery__
 *
 * To run a query within a React component, call `useConfirmBuyoutParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfirmBuyoutParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfirmBuyoutParamsQuery({
 *   variables: {
 *      buyRequestId: // value for 'buyRequestId'
 *   },
 * });
 */
export function useConfirmBuyoutParamsQuery(
    baseOptions: Apollo.QueryHookOptions<ConfirmBuyoutParamsQuery, ConfirmBuyoutParamsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ConfirmBuyoutParamsQuery, ConfirmBuyoutParamsQueryVariables>(
        ConfirmBuyoutParamsDocument,
        options
    );
}
export function useConfirmBuyoutParamsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ConfirmBuyoutParamsQuery, ConfirmBuyoutParamsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ConfirmBuyoutParamsQuery, ConfirmBuyoutParamsQueryVariables>(
        ConfirmBuyoutParamsDocument,
        options
    );
}
export type ConfirmBuyoutParamsQueryHookResult = ReturnType<typeof useConfirmBuyoutParamsQuery>;
export type ConfirmBuyoutParamsLazyQueryHookResult = ReturnType<typeof useConfirmBuyoutParamsLazyQuery>;
export type ConfirmBuyoutParamsQueryResult = Apollo.QueryResult<
    ConfirmBuyoutParamsQuery,
    ConfirmBuyoutParamsQueryVariables
>;
export const ContactsApartmentBuyerDocument = gql`
    query ContactsApartmentBuyer($id: Int!) {
        buyRequest(id: $id) {
            id
            buyer {
                id
                email
                phone
                eosAccount
                cryptochatDeepLink
            }
        }
    }
`;

/**
 * __useContactsApartmentBuyerQuery__
 *
 * To run a query within a React component, call `useContactsApartmentBuyerQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsApartmentBuyerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsApartmentBuyerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContactsApartmentBuyerQuery(
    baseOptions: Apollo.QueryHookOptions<ContactsApartmentBuyerQuery, ContactsApartmentBuyerQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ContactsApartmentBuyerQuery, ContactsApartmentBuyerQueryVariables>(
        ContactsApartmentBuyerDocument,
        options
    );
}
export function useContactsApartmentBuyerLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ContactsApartmentBuyerQuery, ContactsApartmentBuyerQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ContactsApartmentBuyerQuery, ContactsApartmentBuyerQueryVariables>(
        ContactsApartmentBuyerDocument,
        options
    );
}
export type ContactsApartmentBuyerQueryHookResult = ReturnType<typeof useContactsApartmentBuyerQuery>;
export type ContactsApartmentBuyerLazyQueryHookResult = ReturnType<typeof useContactsApartmentBuyerLazyQuery>;
export type ContactsApartmentBuyerQueryResult = Apollo.QueryResult<
    ContactsApartmentBuyerQuery,
    ContactsApartmentBuyerQueryVariables
>;
export const ContactsApartmentOwnerDocument = gql`
    query ContactsApartmentOwner($id: Int!) {
        apartment(id: $id) {
            id
            user {
                id
                email
                phone
                eosAccount
                cryptochatDeepLink
            }
        }
    }
`;

/**
 * __useContactsApartmentOwnerQuery__
 *
 * To run a query within a React component, call `useContactsApartmentOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsApartmentOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsApartmentOwnerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContactsApartmentOwnerQuery(
    baseOptions: Apollo.QueryHookOptions<ContactsApartmentOwnerQuery, ContactsApartmentOwnerQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ContactsApartmentOwnerQuery, ContactsApartmentOwnerQueryVariables>(
        ContactsApartmentOwnerDocument,
        options
    );
}
export function useContactsApartmentOwnerLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ContactsApartmentOwnerQuery, ContactsApartmentOwnerQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ContactsApartmentOwnerQuery, ContactsApartmentOwnerQueryVariables>(
        ContactsApartmentOwnerDocument,
        options
    );
}
export type ContactsApartmentOwnerQueryHookResult = ReturnType<typeof useContactsApartmentOwnerQuery>;
export type ContactsApartmentOwnerLazyQueryHookResult = ReturnType<typeof useContactsApartmentOwnerLazyQuery>;
export type ContactsApartmentOwnerQueryResult = Apollo.QueryResult<
    ContactsApartmentOwnerQuery,
    ContactsApartmentOwnerQueryVariables
>;
export const ContactsApartmentTenantDocument = gql`
    query ContactsApartmentTenant($id: Int!) {
        apartment(id: $id) {
            id
            actualRental {
                id
                tenant {
                    id
                    email
                    phone
                    eosAccount
                    cryptochatDeepLink
                }
            }
        }
    }
`;

/**
 * __useContactsApartmentTenantQuery__
 *
 * To run a query within a React component, call `useContactsApartmentTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsApartmentTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsApartmentTenantQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContactsApartmentTenantQuery(
    baseOptions: Apollo.QueryHookOptions<ContactsApartmentTenantQuery, ContactsApartmentTenantQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ContactsApartmentTenantQuery, ContactsApartmentTenantQueryVariables>(
        ContactsApartmentTenantDocument,
        options
    );
}
export function useContactsApartmentTenantLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ContactsApartmentTenantQuery, ContactsApartmentTenantQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ContactsApartmentTenantQuery, ContactsApartmentTenantQueryVariables>(
        ContactsApartmentTenantDocument,
        options
    );
}
export type ContactsApartmentTenantQueryHookResult = ReturnType<typeof useContactsApartmentTenantQuery>;
export type ContactsApartmentTenantLazyQueryHookResult = ReturnType<typeof useContactsApartmentTenantLazyQuery>;
export type ContactsApartmentTenantQueryResult = Apollo.QueryResult<
    ContactsApartmentTenantQuery,
    ContactsApartmentTenantQueryVariables
>;
export const ContactsApartmentWinnerDocument = gql`
    query ContactsApartmentWinner($id: Int!) {
        apartment(id: $id) {
            id
            latestAuction {
                winner {
                    id
                    email
                    phone
                    eosAccount
                    cryptochatDeepLink
                }
            }
        }
    }
`;

/**
 * __useContactsApartmentWinnerQuery__
 *
 * To run a query within a React component, call `useContactsApartmentWinnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsApartmentWinnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsApartmentWinnerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContactsApartmentWinnerQuery(
    baseOptions: Apollo.QueryHookOptions<ContactsApartmentWinnerQuery, ContactsApartmentWinnerQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ContactsApartmentWinnerQuery, ContactsApartmentWinnerQueryVariables>(
        ContactsApartmentWinnerDocument,
        options
    );
}
export function useContactsApartmentWinnerLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ContactsApartmentWinnerQuery, ContactsApartmentWinnerQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ContactsApartmentWinnerQuery, ContactsApartmentWinnerQueryVariables>(
        ContactsApartmentWinnerDocument,
        options
    );
}
export type ContactsApartmentWinnerQueryHookResult = ReturnType<typeof useContactsApartmentWinnerQuery>;
export type ContactsApartmentWinnerLazyQueryHookResult = ReturnType<typeof useContactsApartmentWinnerLazyQuery>;
export type ContactsApartmentWinnerQueryResult = Apollo.QueryResult<
    ContactsApartmentWinnerQuery,
    ContactsApartmentWinnerQueryVariables
>;
export const ContactsManagerDocument = gql`
    query ContactsManager($id: Int!) {
        city(id: $id) {
            id
            notaryEosAccount
            notaryContactEmail
            notaryContactPhone
            notaryChatLink
        }
    }
`;

/**
 * __useContactsManagerQuery__
 *
 * To run a query within a React component, call `useContactsManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsManagerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContactsManagerQuery(
    baseOptions: Apollo.QueryHookOptions<ContactsManagerQuery, ContactsManagerQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ContactsManagerQuery, ContactsManagerQueryVariables>(ContactsManagerDocument, options);
}
export function useContactsManagerLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ContactsManagerQuery, ContactsManagerQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ContactsManagerQuery, ContactsManagerQueryVariables>(ContactsManagerDocument, options);
}
export type ContactsManagerQueryHookResult = ReturnType<typeof useContactsManagerQuery>;
export type ContactsManagerLazyQueryHookResult = ReturnType<typeof useContactsManagerLazyQuery>;
export type ContactsManagerQueryResult = Apollo.QueryResult<ContactsManagerQuery, ContactsManagerQueryVariables>;
export const ContactCitiesDocument = gql`
    query ContactCities {
        cities(filters: { availableOnly: true }, sort: [COUNTRY_NAME, CITY_NAME]) {
            id
            name
            countryCode
            notaryEosAccount
        }
    }
`;

/**
 * __useContactCitiesQuery__
 *
 * To run a query within a React component, call `useContactCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useContactCitiesQuery(
    baseOptions?: Apollo.QueryHookOptions<ContactCitiesQuery, ContactCitiesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ContactCitiesQuery, ContactCitiesQueryVariables>(ContactCitiesDocument, options);
}
export function useContactCitiesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ContactCitiesQuery, ContactCitiesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ContactCitiesQuery, ContactCitiesQueryVariables>(ContactCitiesDocument, options);
}
export type ContactCitiesQueryHookResult = ReturnType<typeof useContactCitiesQuery>;
export type ContactCitiesLazyQueryHookResult = ReturnType<typeof useContactCitiesLazyQuery>;
export type ContactCitiesQueryResult = Apollo.QueryResult<ContactCitiesQuery, ContactCitiesQueryVariables>;
export const ContestWinnerAgreementParamsDocument = gql`
    query ContestWinnerAgreementParams($id: Int!) {
        apartment(id: $id) {
            id
            communalCurrency
            communalPayment
            totalExpenses
            isTokenized
            tokenizationInfo {
                winnerSignDeepLink
            }
        }
        aptExpenses(aptId: $id) {
            value
            expense {
                id
                name
            }
        }
    }
`;

/**
 * __useContestWinnerAgreementParamsQuery__
 *
 * To run a query within a React component, call `useContestWinnerAgreementParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContestWinnerAgreementParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContestWinnerAgreementParamsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContestWinnerAgreementParamsQuery(
    baseOptions: Apollo.QueryHookOptions<ContestWinnerAgreementParamsQuery, ContestWinnerAgreementParamsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ContestWinnerAgreementParamsQuery, ContestWinnerAgreementParamsQueryVariables>(
        ContestWinnerAgreementParamsDocument,
        options
    );
}
export function useContestWinnerAgreementParamsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ContestWinnerAgreementParamsQuery,
        ContestWinnerAgreementParamsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ContestWinnerAgreementParamsQuery, ContestWinnerAgreementParamsQueryVariables>(
        ContestWinnerAgreementParamsDocument,
        options
    );
}
export type ContestWinnerAgreementParamsQueryHookResult = ReturnType<typeof useContestWinnerAgreementParamsQuery>;
export type ContestWinnerAgreementParamsLazyQueryHookResult = ReturnType<
    typeof useContestWinnerAgreementParamsLazyQuery
>;
export type ContestWinnerAgreementParamsQueryResult = Apollo.QueryResult<
    ContestWinnerAgreementParamsQuery,
    ContestWinnerAgreementParamsQueryVariables
>;
export const EditableApartmentDocument = gql`
    query EditableApartment($id: Int!) {
        apartment(id: $id) {
            ...EditApartmentData
        }
    }
    ${EditApartmentDataFragmentDoc}
`;

/**
 * __useEditableApartmentQuery__
 *
 * To run a query within a React component, call `useEditableApartmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditableApartmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditableApartmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditableApartmentQuery(
    baseOptions: Apollo.QueryHookOptions<EditableApartmentQuery, EditableApartmentQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<EditableApartmentQuery, EditableApartmentQueryVariables>(EditableApartmentDocument, options);
}
export function useEditableApartmentLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<EditableApartmentQuery, EditableApartmentQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<EditableApartmentQuery, EditableApartmentQueryVariables>(
        EditableApartmentDocument,
        options
    );
}
export type EditableApartmentQueryHookResult = ReturnType<typeof useEditableApartmentQuery>;
export type EditableApartmentLazyQueryHookResult = ReturnType<typeof useEditableApartmentLazyQuery>;
export type EditableApartmentQueryResult = Apollo.QueryResult<EditableApartmentQuery, EditableApartmentQueryVariables>;
export const EosAccountExistsDocument = gql`
    query EosAccountExists($eosAccount: String!) {
        eosAccountExists(eosAccount: $eosAccount)
    }
`;

/**
 * __useEosAccountExistsQuery__
 *
 * To run a query within a React component, call `useEosAccountExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEosAccountExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEosAccountExistsQuery({
 *   variables: {
 *      eosAccount: // value for 'eosAccount'
 *   },
 * });
 */
export function useEosAccountExistsQuery(
    baseOptions: Apollo.QueryHookOptions<EosAccountExistsQuery, EosAccountExistsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<EosAccountExistsQuery, EosAccountExistsQueryVariables>(EosAccountExistsDocument, options);
}
export function useEosAccountExistsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<EosAccountExistsQuery, EosAccountExistsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<EosAccountExistsQuery, EosAccountExistsQueryVariables>(
        EosAccountExistsDocument,
        options
    );
}
export type EosAccountExistsQueryHookResult = ReturnType<typeof useEosAccountExistsQuery>;
export type EosAccountExistsLazyQueryHookResult = ReturnType<typeof useEosAccountExistsLazyQuery>;
export type EosAccountExistsQueryResult = Apollo.QueryResult<EosAccountExistsQuery, EosAccountExistsQueryVariables>;
export const ExploreCitiesDocument = gql`
    query ExploreCities {
        cities(sort: [CITY_NAME]) {
            id
            name
            centerLon
            centerLat
            isAvailable
            countryCode
        }
    }
`;

/**
 * __useExploreCitiesQuery__
 *
 * To run a query within a React component, call `useExploreCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExploreCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExploreCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useExploreCitiesQuery(
    baseOptions?: Apollo.QueryHookOptions<ExploreCitiesQuery, ExploreCitiesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ExploreCitiesQuery, ExploreCitiesQueryVariables>(ExploreCitiesDocument, options);
}
export function useExploreCitiesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ExploreCitiesQuery, ExploreCitiesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ExploreCitiesQuery, ExploreCitiesQueryVariables>(ExploreCitiesDocument, options);
}
export type ExploreCitiesQueryHookResult = ReturnType<typeof useExploreCitiesQuery>;
export type ExploreCitiesLazyQueryHookResult = ReturnType<typeof useExploreCitiesLazyQuery>;
export type ExploreCitiesQueryResult = Apollo.QueryResult<ExploreCitiesQuery, ExploreCitiesQueryVariables>;
export const HousesCoordinatesDocument = gql`
    query HousesCoordinates($filters: ApartmentFilterInput, $favorites: Boolean = false) {
        houses(filters: $filters, favorites: $favorites) {
            lon
            lat
            numberOfApts
            placeId
        }
    }
`;

/**
 * __useHousesCoordinatesQuery__
 *
 * To run a query within a React component, call `useHousesCoordinatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHousesCoordinatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHousesCoordinatesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      favorites: // value for 'favorites'
 *   },
 * });
 */
export function useHousesCoordinatesQuery(
    baseOptions?: Apollo.QueryHookOptions<HousesCoordinatesQuery, HousesCoordinatesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<HousesCoordinatesQuery, HousesCoordinatesQueryVariables>(HousesCoordinatesDocument, options);
}
export function useHousesCoordinatesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<HousesCoordinatesQuery, HousesCoordinatesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<HousesCoordinatesQuery, HousesCoordinatesQueryVariables>(
        HousesCoordinatesDocument,
        options
    );
}
export type HousesCoordinatesQueryHookResult = ReturnType<typeof useHousesCoordinatesQuery>;
export type HousesCoordinatesLazyQueryHookResult = ReturnType<typeof useHousesCoordinatesLazyQuery>;
export type HousesCoordinatesQueryResult = Apollo.QueryResult<HousesCoordinatesQuery, HousesCoordinatesQueryVariables>;
export const ManagerApplicationsDocument = gql`
    query ManagerApplications(
        $cityId: Int!
        $statuses: [AptStatus]
        $sort: [ApplicationSortEnum]
        $first: Int
        $offset: Int
    ) {
        applications(cityId: $cityId, statuses: $statuses, sort: $sort, first: $first, offset: $offset) {
            pageInfo {
                total
            }
            apartments {
                ...ManagerApplicationsApartment
            }
        }
    }
    ${ManagerApplicationsApartmentFragmentDoc}
`;

/**
 * __useManagerApplicationsQuery__
 *
 * To run a query within a React component, call `useManagerApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagerApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagerApplicationsQuery({
 *   variables: {
 *      cityId: // value for 'cityId'
 *      statuses: // value for 'statuses'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useManagerApplicationsQuery(
    baseOptions: Apollo.QueryHookOptions<ManagerApplicationsQuery, ManagerApplicationsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ManagerApplicationsQuery, ManagerApplicationsQueryVariables>(
        ManagerApplicationsDocument,
        options
    );
}
export function useManagerApplicationsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ManagerApplicationsQuery, ManagerApplicationsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ManagerApplicationsQuery, ManagerApplicationsQueryVariables>(
        ManagerApplicationsDocument,
        options
    );
}
export type ManagerApplicationsQueryHookResult = ReturnType<typeof useManagerApplicationsQuery>;
export type ManagerApplicationsLazyQueryHookResult = ReturnType<typeof useManagerApplicationsLazyQuery>;
export type ManagerApplicationsQueryResult = Apollo.QueryResult<
    ManagerApplicationsQuery,
    ManagerApplicationsQueryVariables
>;
export const ManagerAuctionsDocument = gql`
    query ManagerAuctions($isFinished: Boolean!, $cityId: Int!, $first: Int, $offset: Int, $sort: [AuctionSortEnum]) {
        auctions(isFinished: $isFinished, first: $first, cityId: $cityId, offset: $offset, sort: $sort) {
            pageInfo {
                total
            }
            apartments @skip(if: $isFinished) {
                ...ManagerAuctionActiveApartment
                ...ManagerAuctionFinishedApartment
            }
            apartments @include(if: $isFinished) {
                ...ManagerAuctionFinishedApartment
            }
        }
    }
    ${ManagerAuctionActiveApartmentFragmentDoc}
    ${ManagerAuctionFinishedApartmentFragmentDoc}
`;

/**
 * __useManagerAuctionsQuery__
 *
 * To run a query within a React component, call `useManagerAuctionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagerAuctionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagerAuctionsQuery({
 *   variables: {
 *      isFinished: // value for 'isFinished'
 *      cityId: // value for 'cityId'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useManagerAuctionsQuery(
    baseOptions: Apollo.QueryHookOptions<ManagerAuctionsQuery, ManagerAuctionsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ManagerAuctionsQuery, ManagerAuctionsQueryVariables>(ManagerAuctionsDocument, options);
}
export function useManagerAuctionsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ManagerAuctionsQuery, ManagerAuctionsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ManagerAuctionsQuery, ManagerAuctionsQueryVariables>(ManagerAuctionsDocument, options);
}
export type ManagerAuctionsQueryHookResult = ReturnType<typeof useManagerAuctionsQuery>;
export type ManagerAuctionsLazyQueryHookResult = ReturnType<typeof useManagerAuctionsLazyQuery>;
export type ManagerAuctionsQueryResult = Apollo.QueryResult<ManagerAuctionsQuery, ManagerAuctionsQueryVariables>;
export const ManagerBuyoutAgreementParamsDocument = gql`
    query ManagerBuyoutAgreementParams($buyRequestId: Int!) {
        buyRequest(id: $buyRequestId) {
            id
            buyerAccount
            transferTime
            tokenId
            changeAmount
            rdApproveDeeplink
            feeAmount
            apt {
                id
                number
                m2
                status
                place {
                    id
                    address
                    city {
                        id
                        name
                    }
                }
                actualRental {
                    id
                    tenantAccount
                }
            }
        }
    }
`;

/**
 * __useManagerBuyoutAgreementParamsQuery__
 *
 * To run a query within a React component, call `useManagerBuyoutAgreementParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagerBuyoutAgreementParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagerBuyoutAgreementParamsQuery({
 *   variables: {
 *      buyRequestId: // value for 'buyRequestId'
 *   },
 * });
 */
export function useManagerBuyoutAgreementParamsQuery(
    baseOptions: Apollo.QueryHookOptions<ManagerBuyoutAgreementParamsQuery, ManagerBuyoutAgreementParamsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ManagerBuyoutAgreementParamsQuery, ManagerBuyoutAgreementParamsQueryVariables>(
        ManagerBuyoutAgreementParamsDocument,
        options
    );
}
export function useManagerBuyoutAgreementParamsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ManagerBuyoutAgreementParamsQuery,
        ManagerBuyoutAgreementParamsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ManagerBuyoutAgreementParamsQuery, ManagerBuyoutAgreementParamsQueryVariables>(
        ManagerBuyoutAgreementParamsDocument,
        options
    );
}
export type ManagerBuyoutAgreementParamsQueryHookResult = ReturnType<typeof useManagerBuyoutAgreementParamsQuery>;
export type ManagerBuyoutAgreementParamsLazyQueryHookResult = ReturnType<
    typeof useManagerBuyoutAgreementParamsLazyQuery
>;
export type ManagerBuyoutAgreementParamsQueryResult = Apollo.QueryResult<
    ManagerBuyoutAgreementParamsQuery,
    ManagerBuyoutAgreementParamsQueryVariables
>;
export const ManagerBuyRequestsDocument = gql`
    query ManagerBuyRequests($cityId: Int!, $isActive: Boolean, $first: Int, $offset: Int) {
        buyRequests(cityId: $cityId, isActive: $isActive, first: $first, offset: $offset) {
            pageInfo {
                total
            }
            buyRequests {
                ...ManagerBuyRequest
            }
        }
    }
    ${ManagerBuyRequestFragmentDoc}
`;

/**
 * __useManagerBuyRequestsQuery__
 *
 * To run a query within a React component, call `useManagerBuyRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagerBuyRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagerBuyRequestsQuery({
 *   variables: {
 *      cityId: // value for 'cityId'
 *      isActive: // value for 'isActive'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useManagerBuyRequestsQuery(
    baseOptions: Apollo.QueryHookOptions<ManagerBuyRequestsQuery, ManagerBuyRequestsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ManagerBuyRequestsQuery, ManagerBuyRequestsQueryVariables>(
        ManagerBuyRequestsDocument,
        options
    );
}
export function useManagerBuyRequestsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ManagerBuyRequestsQuery, ManagerBuyRequestsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ManagerBuyRequestsQuery, ManagerBuyRequestsQueryVariables>(
        ManagerBuyRequestsDocument,
        options
    );
}
export type ManagerBuyRequestsQueryHookResult = ReturnType<typeof useManagerBuyRequestsQuery>;
export type ManagerBuyRequestsLazyQueryHookResult = ReturnType<typeof useManagerBuyRequestsLazyQuery>;
export type ManagerBuyRequestsQueryResult = Apollo.QueryResult<
    ManagerBuyRequestsQuery,
    ManagerBuyRequestsQueryVariables
>;
export const ManagerEvictionsDocument = gql`
    query ManagerEvictions($cityId: Int!, $first: Int, $offset: Int, $sort: [RentalSortEnum]) {
        rentals(first: $first, cityId: $cityId, offset: $offset, sort: $sort, isEvictionStarted: true) {
            pageInfo {
                total
            }
            apartments {
                ...ManagerEvictionsApartment
            }
        }
    }
    ${ManagerEvictionsApartmentFragmentDoc}
`;

/**
 * __useManagerEvictionsQuery__
 *
 * To run a query within a React component, call `useManagerEvictionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagerEvictionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagerEvictionsQuery({
 *   variables: {
 *      cityId: // value for 'cityId'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useManagerEvictionsQuery(
    baseOptions: Apollo.QueryHookOptions<ManagerEvictionsQuery, ManagerEvictionsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ManagerEvictionsQuery, ManagerEvictionsQueryVariables>(ManagerEvictionsDocument, options);
}
export function useManagerEvictionsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ManagerEvictionsQuery, ManagerEvictionsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ManagerEvictionsQuery, ManagerEvictionsQueryVariables>(
        ManagerEvictionsDocument,
        options
    );
}
export type ManagerEvictionsQueryHookResult = ReturnType<typeof useManagerEvictionsQuery>;
export type ManagerEvictionsLazyQueryHookResult = ReturnType<typeof useManagerEvictionsLazyQuery>;
export type ManagerEvictionsQueryResult = Apollo.QueryResult<ManagerEvictionsQuery, ManagerEvictionsQueryVariables>;
export const ManagerLockedAptsDocument = gql`
    query ManagerLockedApts($cityId: Int!, $sort: [LockedSortEnum], $first: Int, $offset: Int) {
        lockedApts(cityId: $cityId, sort: $sort, first: $first, offset: $offset) {
            pageInfo {
                total
            }
            apartments {
                ...ManagerLockedApts
            }
        }
    }
    ${ManagerLockedAptsFragmentDoc}
`;

/**
 * __useManagerLockedAptsQuery__
 *
 * To run a query within a React component, call `useManagerLockedAptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagerLockedAptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagerLockedAptsQuery({
 *   variables: {
 *      cityId: // value for 'cityId'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useManagerLockedAptsQuery(
    baseOptions: Apollo.QueryHookOptions<ManagerLockedAptsQuery, ManagerLockedAptsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ManagerLockedAptsQuery, ManagerLockedAptsQueryVariables>(ManagerLockedAptsDocument, options);
}
export function useManagerLockedAptsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ManagerLockedAptsQuery, ManagerLockedAptsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ManagerLockedAptsQuery, ManagerLockedAptsQueryVariables>(
        ManagerLockedAptsDocument,
        options
    );
}
export type ManagerLockedAptsQueryHookResult = ReturnType<typeof useManagerLockedAptsQuery>;
export type ManagerLockedAptsLazyQueryHookResult = ReturnType<typeof useManagerLockedAptsLazyQuery>;
export type ManagerLockedAptsQueryResult = Apollo.QueryResult<ManagerLockedAptsQuery, ManagerLockedAptsQueryVariables>;
export const ManagerRegionSettingsDocument = gql`
    query ManagerRegionSettings($id: Int!) {
        city(id: $id) {
            id
            locales
            notaryEosAccount
            notaryContactEmail
            notaryContactPhone
        }
        regionExpenses(cityId: $id) {
            ...ManagerExpense
        }
        regionDocuments(cityId: $id) {
            ...ManagerDocument
        }
    }
    ${ManagerExpenseFragmentDoc}
    ${ManagerDocumentFragmentDoc}
`;

/**
 * __useManagerRegionSettingsQuery__
 *
 * To run a query within a React component, call `useManagerRegionSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagerRegionSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagerRegionSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useManagerRegionSettingsQuery(
    baseOptions: Apollo.QueryHookOptions<ManagerRegionSettingsQuery, ManagerRegionSettingsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ManagerRegionSettingsQuery, ManagerRegionSettingsQueryVariables>(
        ManagerRegionSettingsDocument,
        options
    );
}
export function useManagerRegionSettingsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ManagerRegionSettingsQuery, ManagerRegionSettingsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ManagerRegionSettingsQuery, ManagerRegionSettingsQueryVariables>(
        ManagerRegionSettingsDocument,
        options
    );
}
export type ManagerRegionSettingsQueryHookResult = ReturnType<typeof useManagerRegionSettingsQuery>;
export type ManagerRegionSettingsLazyQueryHookResult = ReturnType<typeof useManagerRegionSettingsLazyQuery>;
export type ManagerRegionSettingsQueryResult = Apollo.QueryResult<
    ManagerRegionSettingsQuery,
    ManagerRegionSettingsQueryVariables
>;
export const ManagerRentalsDocument = gql`
    query ManagerRentals($cityId: Int!, $first: Int, $offset: Int, $sort: [RentalSortEnum]) {
        rentals(first: $first, cityId: $cityId, offset: $offset, sort: $sort, isEvictionStarted: false) {
            pageInfo {
                total
            }
            apartments {
                ...ManagerRentalsApartment
            }
        }
    }
    ${ManagerRentalsApartmentFragmentDoc}
`;

/**
 * __useManagerRentalsQuery__
 *
 * To run a query within a React component, call `useManagerRentalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagerRentalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagerRentalsQuery({
 *   variables: {
 *      cityId: // value for 'cityId'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useManagerRentalsQuery(
    baseOptions: Apollo.QueryHookOptions<ManagerRentalsQuery, ManagerRentalsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ManagerRentalsQuery, ManagerRentalsQueryVariables>(ManagerRentalsDocument, options);
}
export function useManagerRentalsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ManagerRentalsQuery, ManagerRentalsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ManagerRentalsQuery, ManagerRentalsQueryVariables>(ManagerRentalsDocument, options);
}
export type ManagerRentalsQueryHookResult = ReturnType<typeof useManagerRentalsQuery>;
export type ManagerRentalsLazyQueryHookResult = ReturnType<typeof useManagerRentalsLazyQuery>;
export type ManagerRentalsQueryResult = Apollo.QueryResult<ManagerRentalsQuery, ManagerRentalsQueryVariables>;
export const ManagerWaitingContestAptsDocument = gql`
    query ManagerWaitingContestApts($cityId: Int!, $sort: [WaitingContestSortEnum], $first: Int, $offset: Int) {
        waitingContestApts(first: $first, cityId: $cityId, offset: $offset, sort: $sort) {
            pageInfo {
                total
            }
            apartments {
                ...ManagerWaitingContestApartment
            }
        }
    }
    ${ManagerWaitingContestApartmentFragmentDoc}
`;

/**
 * __useManagerWaitingContestAptsQuery__
 *
 * To run a query within a React component, call `useManagerWaitingContestAptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagerWaitingContestAptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagerWaitingContestAptsQuery({
 *   variables: {
 *      cityId: // value for 'cityId'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useManagerWaitingContestAptsQuery(
    baseOptions: Apollo.QueryHookOptions<ManagerWaitingContestAptsQuery, ManagerWaitingContestAptsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ManagerWaitingContestAptsQuery, ManagerWaitingContestAptsQueryVariables>(
        ManagerWaitingContestAptsDocument,
        options
    );
}
export function useManagerWaitingContestAptsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ManagerWaitingContestAptsQuery, ManagerWaitingContestAptsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ManagerWaitingContestAptsQuery, ManagerWaitingContestAptsQueryVariables>(
        ManagerWaitingContestAptsDocument,
        options
    );
}
export type ManagerWaitingContestAptsQueryHookResult = ReturnType<typeof useManagerWaitingContestAptsQuery>;
export type ManagerWaitingContestAptsLazyQueryHookResult = ReturnType<typeof useManagerWaitingContestAptsLazyQuery>;
export type ManagerWaitingContestAptsQueryResult = Apollo.QueryResult<
    ManagerWaitingContestAptsQuery,
    ManagerWaitingContestAptsQueryVariables
>;
export const MeDocument = gql`
    query Me {
        me {
            id
            email
            phone
            eosAccount
            isNotary
            hasPassword
            countNewNotifications
            rdUpdates {
                ...NotaryUpdates
            }
        }
    }
    ${NotaryUpdatesFragmentDoc}
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MeNotifyHistoryDocument = gql`
    query MeNotifyHistory($newOnly: Boolean, $first: Int, $offset: Int) {
        me {
            id
            countNewNotifications
            notifyHistory(newOnly: $newOnly, first: $first, offset: $offset) {
                ...NotifyHistoryNode
            }
        }
        notificationsNumber(newOnly: $newOnly)
    }
    ${NotifyHistoryNodeFragmentDoc}
`;

/**
 * __useMeNotifyHistoryQuery__
 *
 * To run a query within a React component, call `useMeNotifyHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeNotifyHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeNotifyHistoryQuery({
 *   variables: {
 *      newOnly: // value for 'newOnly'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useMeNotifyHistoryQuery(
    baseOptions?: Apollo.QueryHookOptions<MeNotifyHistoryQuery, MeNotifyHistoryQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<MeNotifyHistoryQuery, MeNotifyHistoryQueryVariables>(MeNotifyHistoryDocument, options);
}
export function useMeNotifyHistoryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<MeNotifyHistoryQuery, MeNotifyHistoryQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<MeNotifyHistoryQuery, MeNotifyHistoryQueryVariables>(MeNotifyHistoryDocument, options);
}
export type MeNotifyHistoryQueryHookResult = ReturnType<typeof useMeNotifyHistoryQuery>;
export type MeNotifyHistoryLazyQueryHookResult = ReturnType<typeof useMeNotifyHistoryLazyQuery>;
export type MeNotifyHistoryQueryResult = Apollo.QueryResult<MeNotifyHistoryQuery, MeNotifyHistoryQueryVariables>;
export const MeNotifySettingsDocument = gql`
    query MeNotifySettings {
        me {
            ...MeNotificationSettingsFragment
        }
    }
    ${MeNotificationSettingsFragmentFragmentDoc}
`;

/**
 * __useMeNotifySettingsQuery__
 *
 * To run a query within a React component, call `useMeNotifySettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeNotifySettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeNotifySettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeNotifySettingsQuery(
    baseOptions?: Apollo.QueryHookOptions<MeNotifySettingsQuery, MeNotifySettingsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<MeNotifySettingsQuery, MeNotifySettingsQueryVariables>(MeNotifySettingsDocument, options);
}
export function useMeNotifySettingsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<MeNotifySettingsQuery, MeNotifySettingsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<MeNotifySettingsQuery, MeNotifySettingsQueryVariables>(
        MeNotifySettingsDocument,
        options
    );
}
export type MeNotifySettingsQueryHookResult = ReturnType<typeof useMeNotifySettingsQuery>;
export type MeNotifySettingsLazyQueryHookResult = ReturnType<typeof useMeNotifySettingsLazyQuery>;
export type MeNotifySettingsQueryResult = Apollo.QueryResult<MeNotifySettingsQuery, MeNotifySettingsQueryVariables>;
export const MyApartmentsDocument = gql`
    query MyApartments($first: Int, $offset: Int, $relationType: RelationToApt!, $isOwn: Boolean = false) {
        myApartments(first: $first, relationType: $relationType, offset: $offset) {
            pageInfo {
                total
            }
            apartments {
                ...PublicApartmentListNode
                emissionVolume @include(if: $isOwn) {
                    amount
                    token
                    fee
                }
                review @include(if: $isOwn) {
                    errorsCount
                }
            }
        }
    }
    ${PublicApartmentListNodeFragmentDoc}
`;

/**
 * __useMyApartmentsQuery__
 *
 * To run a query within a React component, call `useMyApartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyApartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyApartmentsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      relationType: // value for 'relationType'
 *      isOwn: // value for 'isOwn'
 *   },
 * });
 */
export function useMyApartmentsQuery(
    baseOptions: Apollo.QueryHookOptions<MyApartmentsQuery, MyApartmentsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<MyApartmentsQuery, MyApartmentsQueryVariables>(MyApartmentsDocument, options);
}
export function useMyApartmentsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<MyApartmentsQuery, MyApartmentsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<MyApartmentsQuery, MyApartmentsQueryVariables>(MyApartmentsDocument, options);
}
export type MyApartmentsQueryHookResult = ReturnType<typeof useMyApartmentsQuery>;
export type MyApartmentsLazyQueryHookResult = ReturnType<typeof useMyApartmentsLazyQuery>;
export type MyApartmentsQueryResult = Apollo.QueryResult<MyApartmentsQuery, MyApartmentsQueryVariables>;
export const MyBuyRequestsDocument = gql`
    query MyBuyRequests($cityId: Int, $isActive: Boolean, $first: Int, $offset: Int) {
        myBuyRequests(cityId: $cityId, isActive: $isActive, first: $first, offset: $offset) {
            pageInfo {
                total
            }
            buyRequests {
                ...MyBuyRequest
            }
        }
    }
    ${MyBuyRequestFragmentDoc}
`;

/**
 * __useMyBuyRequestsQuery__
 *
 * To run a query within a React component, call `useMyBuyRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyBuyRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyBuyRequestsQuery({
 *   variables: {
 *      cityId: // value for 'cityId'
 *      isActive: // value for 'isActive'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useMyBuyRequestsQuery(
    baseOptions?: Apollo.QueryHookOptions<MyBuyRequestsQuery, MyBuyRequestsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<MyBuyRequestsQuery, MyBuyRequestsQueryVariables>(MyBuyRequestsDocument, options);
}
export function useMyBuyRequestsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<MyBuyRequestsQuery, MyBuyRequestsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<MyBuyRequestsQuery, MyBuyRequestsQueryVariables>(MyBuyRequestsDocument, options);
}
export type MyBuyRequestsQueryHookResult = ReturnType<typeof useMyBuyRequestsQuery>;
export type MyBuyRequestsLazyQueryHookResult = ReturnType<typeof useMyBuyRequestsLazyQuery>;
export type MyBuyRequestsQueryResult = Apollo.QueryResult<MyBuyRequestsQuery, MyBuyRequestsQueryVariables>;
export const OurSuitableAddressesDocument = gql`
    query OurSuitableAddresses($address: String!, $cityId: Int) {
        ourSuitableAddresses(address: $address, cityId: $cityId) {
            id
            address
        }
    }
`;

/**
 * __useOurSuitableAddressesQuery__
 *
 * To run a query within a React component, call `useOurSuitableAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOurSuitableAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOurSuitableAddressesQuery({
 *   variables: {
 *      address: // value for 'address'
 *      cityId: // value for 'cityId'
 *   },
 * });
 */
export function useOurSuitableAddressesQuery(
    baseOptions: Apollo.QueryHookOptions<OurSuitableAddressesQuery, OurSuitableAddressesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<OurSuitableAddressesQuery, OurSuitableAddressesQueryVariables>(
        OurSuitableAddressesDocument,
        options
    );
}
export function useOurSuitableAddressesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<OurSuitableAddressesQuery, OurSuitableAddressesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<OurSuitableAddressesQuery, OurSuitableAddressesQueryVariables>(
        OurSuitableAddressesDocument,
        options
    );
}
export type OurSuitableAddressesQueryHookResult = ReturnType<typeof useOurSuitableAddressesQuery>;
export type OurSuitableAddressesLazyQueryHookResult = ReturnType<typeof useOurSuitableAddressesLazyQuery>;
export type OurSuitableAddressesQueryResult = Apollo.QueryResult<
    OurSuitableAddressesQuery,
    OurSuitableAddressesQueryVariables
>;
export const PlaceDocument = gql`
    query Place($id: Int, $gmapPlaceId: String, $cityId: Int, $fingerprint: String) {
        place(id: $id, gmapPlaceId: $gmapPlaceId, cityId: $cityId, fingerprint: $fingerprint) {
            id
            address
            isInsidePolygon
            canBeAccepted
            lon
            lat
        }
    }
`;

/**
 * __usePlaceQuery__
 *
 * To run a query within a React component, call `usePlaceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaceQuery({
 *   variables: {
 *      id: // value for 'id'
 *      gmapPlaceId: // value for 'gmapPlaceId'
 *      cityId: // value for 'cityId'
 *      fingerprint: // value for 'fingerprint'
 *   },
 * });
 */
export function usePlaceQuery(baseOptions?: Apollo.QueryHookOptions<PlaceQuery, PlaceQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PlaceQuery, PlaceQueryVariables>(PlaceDocument, options);
}
export function usePlaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlaceQuery, PlaceQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PlaceQuery, PlaceQueryVariables>(PlaceDocument, options);
}
export type PlaceQueryHookResult = ReturnType<typeof usePlaceQuery>;
export type PlaceLazyQueryHookResult = ReturnType<typeof usePlaceLazyQuery>;
export type PlaceQueryResult = Apollo.QueryResult<PlaceQuery, PlaceQueryVariables>;
export const RegionDocumentsDocument = gql`
    query RegionDocuments($cityId: Int!) {
        regionDocuments(cityId: $cityId) {
            id
            name
        }
    }
`;

/**
 * __useRegionDocumentsQuery__
 *
 * To run a query within a React component, call `useRegionDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionDocumentsQuery({
 *   variables: {
 *      cityId: // value for 'cityId'
 *   },
 * });
 */
export function useRegionDocumentsQuery(
    baseOptions: Apollo.QueryHookOptions<RegionDocumentsQuery, RegionDocumentsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<RegionDocumentsQuery, RegionDocumentsQueryVariables>(RegionDocumentsDocument, options);
}
export function useRegionDocumentsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<RegionDocumentsQuery, RegionDocumentsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<RegionDocumentsQuery, RegionDocumentsQueryVariables>(RegionDocumentsDocument, options);
}
export type RegionDocumentsQueryHookResult = ReturnType<typeof useRegionDocumentsQuery>;
export type RegionDocumentsLazyQueryHookResult = ReturnType<typeof useRegionDocumentsLazyQuery>;
export type RegionDocumentsQueryResult = Apollo.QueryResult<RegionDocumentsQuery, RegionDocumentsQueryVariables>;
export const RegionExpensesDocument = gql`
    query RegionExpenses($cityId: Int!) {
        regionExpenses(cityId: $cityId) {
            id
            name
        }
    }
`;

/**
 * __useRegionExpensesQuery__
 *
 * To run a query within a React component, call `useRegionExpensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionExpensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionExpensesQuery({
 *   variables: {
 *      cityId: // value for 'cityId'
 *   },
 * });
 */
export function useRegionExpensesQuery(
    baseOptions: Apollo.QueryHookOptions<RegionExpensesQuery, RegionExpensesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<RegionExpensesQuery, RegionExpensesQueryVariables>(RegionExpensesDocument, options);
}
export function useRegionExpensesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<RegionExpensesQuery, RegionExpensesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<RegionExpensesQuery, RegionExpensesQueryVariables>(RegionExpensesDocument, options);
}
export type RegionExpensesQueryHookResult = ReturnType<typeof useRegionExpensesQuery>;
export type RegionExpensesLazyQueryHookResult = ReturnType<typeof useRegionExpensesLazyQuery>;
export type RegionExpensesQueryResult = Apollo.QueryResult<RegionExpensesQuery, RegionExpensesQueryVariables>;
export const RentalExpirationAfterRenewDocument = gql`
    query RentalExpirationAfterRenew($curentExpiration: DateTime) {
        rentalExpirationAfterRenew(curentExpiration: $curentExpiration)
    }
`;

/**
 * __useRentalExpirationAfterRenewQuery__
 *
 * To run a query within a React component, call `useRentalExpirationAfterRenewQuery` and pass it any options that fit your needs.
 * When your component renders, `useRentalExpirationAfterRenewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRentalExpirationAfterRenewQuery({
 *   variables: {
 *      curentExpiration: // value for 'curentExpiration'
 *   },
 * });
 */
export function useRentalExpirationAfterRenewQuery(
    baseOptions?: Apollo.QueryHookOptions<RentalExpirationAfterRenewQuery, RentalExpirationAfterRenewQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<RentalExpirationAfterRenewQuery, RentalExpirationAfterRenewQueryVariables>(
        RentalExpirationAfterRenewDocument,
        options
    );
}
export function useRentalExpirationAfterRenewLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<RentalExpirationAfterRenewQuery, RentalExpirationAfterRenewQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<RentalExpirationAfterRenewQuery, RentalExpirationAfterRenewQueryVariables>(
        RentalExpirationAfterRenewDocument,
        options
    );
}
export type RentalExpirationAfterRenewQueryHookResult = ReturnType<typeof useRentalExpirationAfterRenewQuery>;
export type RentalExpirationAfterRenewLazyQueryHookResult = ReturnType<typeof useRentalExpirationAfterRenewLazyQuery>;
export type RentalExpirationAfterRenewQueryResult = Apollo.QueryResult<
    RentalExpirationAfterRenewQuery,
    RentalExpirationAfterRenewQueryVariables
>;
export const SearchCitiesDocument = gql`
    query SearchCities {
        cities(filters: { hasPublic: true }, sort: [COUNTRY_NAME, CITY_NAME]) {
            id
            name
            centerLon
            centerLat
            country {
                id
                name
                currencyCode
            }
            countryCode
        }
    }
`;

/**
 * __useSearchCitiesQuery__
 *
 * To run a query within a React component, call `useSearchCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchCitiesQuery(
    baseOptions?: Apollo.QueryHookOptions<SearchCitiesQuery, SearchCitiesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SearchCitiesQuery, SearchCitiesQueryVariables>(SearchCitiesDocument, options);
}
export function useSearchCitiesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<SearchCitiesQuery, SearchCitiesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SearchCitiesQuery, SearchCitiesQueryVariables>(SearchCitiesDocument, options);
}
export type SearchCitiesQueryHookResult = ReturnType<typeof useSearchCitiesQuery>;
export type SearchCitiesLazyQueryHookResult = ReturnType<typeof useSearchCitiesLazyQuery>;
export type SearchCitiesQueryResult = Apollo.QueryResult<SearchCitiesQuery, SearchCitiesQueryVariables>;
export const StartAuctionParamsDocument = gql`
    query StartAuctionParams($aptId: Int!, $cityId: Int!) {
        apartment(id: $aptId) {
            id
            communalCurrency
            communalPayment
            totalExpenses
        }
        aptExpenses(aptId: $aptId) {
            value
            expense {
                id
                name
            }
        }
        regionExpenses(cityId: $cityId) {
            id
            name
        }
    }
`;

/**
 * __useStartAuctionParamsQuery__
 *
 * To run a query within a React component, call `useStartAuctionParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStartAuctionParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStartAuctionParamsQuery({
 *   variables: {
 *      aptId: // value for 'aptId'
 *      cityId: // value for 'cityId'
 *   },
 * });
 */
export function useStartAuctionParamsQuery(
    baseOptions: Apollo.QueryHookOptions<StartAuctionParamsQuery, StartAuctionParamsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<StartAuctionParamsQuery, StartAuctionParamsQueryVariables>(
        StartAuctionParamsDocument,
        options
    );
}
export function useStartAuctionParamsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<StartAuctionParamsQuery, StartAuctionParamsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<StartAuctionParamsQuery, StartAuctionParamsQueryVariables>(
        StartAuctionParamsDocument,
        options
    );
}
export type StartAuctionParamsQueryHookResult = ReturnType<typeof useStartAuctionParamsQuery>;
export type StartAuctionParamsLazyQueryHookResult = ReturnType<typeof useStartAuctionParamsLazyQuery>;
export type StartAuctionParamsQueryResult = Apollo.QueryResult<
    StartAuctionParamsQuery,
    StartAuctionParamsQueryVariables
>;
export const SubwaysDocument = gql`
    query Subways($id: Int!) {
        city(id: $id) {
            id
            subways {
                id
                name
            }
        }
    }
`;

/**
 * __useSubwaysQuery__
 *
 * To run a query within a React component, call `useSubwaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubwaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubwaysQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubwaysQuery(baseOptions: Apollo.QueryHookOptions<SubwaysQuery, SubwaysQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SubwaysQuery, SubwaysQueryVariables>(SubwaysDocument, options);
}
export function useSubwaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubwaysQuery, SubwaysQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SubwaysQuery, SubwaysQueryVariables>(SubwaysDocument, options);
}
export type SubwaysQueryHookResult = ReturnType<typeof useSubwaysQuery>;
export type SubwaysLazyQueryHookResult = ReturnType<typeof useSubwaysLazyQuery>;
export type SubwaysQueryResult = Apollo.QueryResult<SubwaysQuery, SubwaysQueryVariables>;
export const SuitableAddressesDocument = gql`
    query SuitableAddresses($address: String!, $first: String, $cityId: Int) {
        suitableAddresses(address: $address, first: $first, cityId: $cityId) {
            address
            placeId
            isFull
        }
    }
`;

/**
 * __useSuitableAddressesQuery__
 *
 * To run a query within a React component, call `useSuitableAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuitableAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuitableAddressesQuery({
 *   variables: {
 *      address: // value for 'address'
 *      first: // value for 'first'
 *      cityId: // value for 'cityId'
 *   },
 * });
 */
export function useSuitableAddressesQuery(
    baseOptions: Apollo.QueryHookOptions<SuitableAddressesQuery, SuitableAddressesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SuitableAddressesQuery, SuitableAddressesQueryVariables>(SuitableAddressesDocument, options);
}
export function useSuitableAddressesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<SuitableAddressesQuery, SuitableAddressesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SuitableAddressesQuery, SuitableAddressesQueryVariables>(
        SuitableAddressesDocument,
        options
    );
}
export type SuitableAddressesQueryHookResult = ReturnType<typeof useSuitableAddressesQuery>;
export type SuitableAddressesLazyQueryHookResult = ReturnType<typeof useSuitableAddressesLazyQuery>;
export type SuitableAddressesQueryResult = Apollo.QueryResult<SuitableAddressesQuery, SuitableAddressesQueryVariables>;
export const SystemDocument = gql`
    query System($isAuth: Boolean!) {
        realtime
        currentLanguage
        me @include(if: $isAuth) {
            id
            language
        }
    }
`;

/**
 * __useSystemQuery__
 *
 * To run a query within a React component, call `useSystemQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemQuery({
 *   variables: {
 *      isAuth: // value for 'isAuth'
 *   },
 * });
 */
export function useSystemQuery(baseOptions: Apollo.QueryHookOptions<SystemQuery, SystemQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SystemQuery, SystemQueryVariables>(SystemDocument, options);
}
export function useSystemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SystemQuery, SystemQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SystemQuery, SystemQueryVariables>(SystemDocument, options);
}
export type SystemQueryHookResult = ReturnType<typeof useSystemQuery>;
export type SystemLazyQueryHookResult = ReturnType<typeof useSystemLazyQuery>;
export type SystemQueryResult = Apollo.QueryResult<SystemQuery, SystemQueryVariables>;
export const ValidateContestDocument = gql`
    query ValidateContest($aptId: Int!) {
        contestCheck(aptId: $aptId) {
            seedBlock
            testScript
        }
        apartment(id: $aptId) {
            id
            status
            place {
                id
                address
            }
            city {
                id
                name
            }
            photos {
                id
                link(size: SIZE_1125x540)
            }
            latestAuction {
                id
                endOfActivePhase
                winnerAccount
                isWinnerSelectionInProgress
                isIAmWinner
                bidsNumber {
                    active
                    cancelled
                }
            }
        }
    }
`;

/**
 * __useValidateContestQuery__
 *
 * To run a query within a React component, call `useValidateContestQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateContestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateContestQuery({
 *   variables: {
 *      aptId: // value for 'aptId'
 *   },
 * });
 */
export function useValidateContestQuery(
    baseOptions: Apollo.QueryHookOptions<ValidateContestQuery, ValidateContestQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ValidateContestQuery, ValidateContestQueryVariables>(ValidateContestDocument, options);
}
export function useValidateContestLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ValidateContestQuery, ValidateContestQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ValidateContestQuery, ValidateContestQueryVariables>(ValidateContestDocument, options);
}
export type ValidateContestQueryHookResult = ReturnType<typeof useValidateContestQuery>;
export type ValidateContestLazyQueryHookResult = ReturnType<typeof useValidateContestLazyQuery>;
export type ValidateContestQueryResult = Apollo.QueryResult<ValidateContestQuery, ValidateContestQueryVariables>;
export const ApartmentWsDocument = gql`
    subscription ApartmentWS($aptId: Int!) {
        apartment(aptId: $aptId) {
            event
            apt {
                ...PublicApartmentListNode
            }
        }
    }
    ${PublicApartmentListNodeFragmentDoc}
`;

/**
 * __useApartmentWsSubscription__
 *
 * To run a query within a React component, call `useApartmentWsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useApartmentWsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApartmentWsSubscription({
 *   variables: {
 *      aptId: // value for 'aptId'
 *   },
 * });
 */
export function useApartmentWsSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<ApartmentWsSubscription, ApartmentWsSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<ApartmentWsSubscription, ApartmentWsSubscriptionVariables>(
        ApartmentWsDocument,
        options
    );
}
export type ApartmentWsSubscriptionHookResult = ReturnType<typeof useApartmentWsSubscription>;
export type ApartmentWsSubscriptionResult = Apollo.SubscriptionResult<ApartmentWsSubscription>;
export const CityNotaryUpdatesWsDocument = gql`
    subscription CityNotaryUpdatesWs($cityId: Int!) {
        city(cityId: $cityId) {
            id
            notaryUpdates {
                ...NotaryUpdates
            }
        }
    }
    ${NotaryUpdatesFragmentDoc}
`;

/**
 * __useCityNotaryUpdatesWsSubscription__
 *
 * To run a query within a React component, call `useCityNotaryUpdatesWsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCityNotaryUpdatesWsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCityNotaryUpdatesWsSubscription({
 *   variables: {
 *      cityId: // value for 'cityId'
 *   },
 * });
 */
export function useCityNotaryUpdatesWsSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        CityNotaryUpdatesWsSubscription,
        CityNotaryUpdatesWsSubscriptionVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<CityNotaryUpdatesWsSubscription, CityNotaryUpdatesWsSubscriptionVariables>(
        CityNotaryUpdatesWsDocument,
        options
    );
}
export type CityNotaryUpdatesWsSubscriptionHookResult = ReturnType<typeof useCityNotaryUpdatesWsSubscription>;
export type CityNotaryUpdatesWsSubscriptionResult = Apollo.SubscriptionResult<CityNotaryUpdatesWsSubscription>;
export const ConnectByWalletWsDocument = gql`
    subscription ConnectByWalletWS($verificationCode: String!) {
        connectByWallet(verificationCode: $verificationCode) {
            ... on WalletConnectionSuccess {
                token
            }
            ... on UserNotFoundError {
                errorMessage
            }
            ... on ConfirmationFailedError {
                errorMessage
            }
            ... on WalletLinkOutdatedError {
                errorMessage
            }
            ... on EosAccountAlreadyTakenError {
                errorMessage
            }
        }
    }
`;

/**
 * __useConnectByWalletWsSubscription__
 *
 * To run a query within a React component, call `useConnectByWalletWsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useConnectByWalletWsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectByWalletWsSubscription({
 *   variables: {
 *      verificationCode: // value for 'verificationCode'
 *   },
 * });
 */
export function useConnectByWalletWsSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<ConnectByWalletWsSubscription, ConnectByWalletWsSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<ConnectByWalletWsSubscription, ConnectByWalletWsSubscriptionVariables>(
        ConnectByWalletWsDocument,
        options
    );
}
export type ConnectByWalletWsSubscriptionHookResult = ReturnType<typeof useConnectByWalletWsSubscription>;
export type ConnectByWalletWsSubscriptionResult = Apollo.SubscriptionResult<ConnectByWalletWsSubscription>;
export const ManagerApplicationsWsDocument = gql`
    subscription ManagerApplicationsWS($cityId: Int!) {
        RDUpdates(cityId: $cityId) {
            oldStatus
            apt {
                ...ManagerApplicationsApartment
            }
        }
    }
    ${ManagerApplicationsApartmentFragmentDoc}
`;

/**
 * __useManagerApplicationsWsSubscription__
 *
 * To run a query within a React component, call `useManagerApplicationsWsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useManagerApplicationsWsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagerApplicationsWsSubscription({
 *   variables: {
 *      cityId: // value for 'cityId'
 *   },
 * });
 */
export function useManagerApplicationsWsSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        ManagerApplicationsWsSubscription,
        ManagerApplicationsWsSubscriptionVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<ManagerApplicationsWsSubscription, ManagerApplicationsWsSubscriptionVariables>(
        ManagerApplicationsWsDocument,
        options
    );
}
export type ManagerApplicationsWsSubscriptionHookResult = ReturnType<typeof useManagerApplicationsWsSubscription>;
export type ManagerApplicationsWsSubscriptionResult = Apollo.SubscriptionResult<ManagerApplicationsWsSubscription>;
export const ManagerAuctionsActiveWsDocument = gql`
    subscription ManagerAuctionsActiveWS($cityId: Int!) {
        RDUpdates(cityId: $cityId) {
            oldStatus
            apt {
                ...ManagerAuctionActiveApartment
            }
        }
    }
    ${ManagerAuctionActiveApartmentFragmentDoc}
`;

/**
 * __useManagerAuctionsActiveWsSubscription__
 *
 * To run a query within a React component, call `useManagerAuctionsActiveWsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useManagerAuctionsActiveWsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagerAuctionsActiveWsSubscription({
 *   variables: {
 *      cityId: // value for 'cityId'
 *   },
 * });
 */
export function useManagerAuctionsActiveWsSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        ManagerAuctionsActiveWsSubscription,
        ManagerAuctionsActiveWsSubscriptionVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<ManagerAuctionsActiveWsSubscription, ManagerAuctionsActiveWsSubscriptionVariables>(
        ManagerAuctionsActiveWsDocument,
        options
    );
}
export type ManagerAuctionsActiveWsSubscriptionHookResult = ReturnType<typeof useManagerAuctionsActiveWsSubscription>;
export type ManagerAuctionsActiveWsSubscriptionResult = Apollo.SubscriptionResult<ManagerAuctionsActiveWsSubscription>;
export const ManagerAuctionsFinishedWsDocument = gql`
    subscription ManagerAuctionsFinishedWS($cityId: Int!) {
        RDUpdates(cityId: $cityId) {
            oldStatus
            apt {
                ...ManagerAuctionFinishedApartment
            }
        }
    }
    ${ManagerAuctionFinishedApartmentFragmentDoc}
`;

/**
 * __useManagerAuctionsFinishedWsSubscription__
 *
 * To run a query within a React component, call `useManagerAuctionsFinishedWsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useManagerAuctionsFinishedWsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagerAuctionsFinishedWsSubscription({
 *   variables: {
 *      cityId: // value for 'cityId'
 *   },
 * });
 */
export function useManagerAuctionsFinishedWsSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        ManagerAuctionsFinishedWsSubscription,
        ManagerAuctionsFinishedWsSubscriptionVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<
        ManagerAuctionsFinishedWsSubscription,
        ManagerAuctionsFinishedWsSubscriptionVariables
    >(ManagerAuctionsFinishedWsDocument, options);
}
export type ManagerAuctionsFinishedWsSubscriptionHookResult = ReturnType<
    typeof useManagerAuctionsFinishedWsSubscription
>;
export type ManagerAuctionsFinishedWsSubscriptionResult =
    Apollo.SubscriptionResult<ManagerAuctionsFinishedWsSubscription>;
export const ManagerBuyRequestsWsDocument = gql`
    subscription ManagerBuyRequestsWS($cityId: Int!) {
        RDBuyRequests(cityId: $cityId) {
            event
            buyRequest {
                ...ManagerBuyRequest
            }
        }
    }
    ${ManagerBuyRequestFragmentDoc}
`;

/**
 * __useManagerBuyRequestsWsSubscription__
 *
 * To run a query within a React component, call `useManagerBuyRequestsWsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useManagerBuyRequestsWsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagerBuyRequestsWsSubscription({
 *   variables: {
 *      cityId: // value for 'cityId'
 *   },
 * });
 */
export function useManagerBuyRequestsWsSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        ManagerBuyRequestsWsSubscription,
        ManagerBuyRequestsWsSubscriptionVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<ManagerBuyRequestsWsSubscription, ManagerBuyRequestsWsSubscriptionVariables>(
        ManagerBuyRequestsWsDocument,
        options
    );
}
export type ManagerBuyRequestsWsSubscriptionHookResult = ReturnType<typeof useManagerBuyRequestsWsSubscription>;
export type ManagerBuyRequestsWsSubscriptionResult = Apollo.SubscriptionResult<ManagerBuyRequestsWsSubscription>;
export const ManagerEvictionsWsDocument = gql`
    subscription ManagerEvictionsWS($cityId: Int!) {
        RDUpdates(cityId: $cityId) {
            oldStatus
            apt {
                ...ManagerEvictionsApartment
            }
        }
    }
    ${ManagerEvictionsApartmentFragmentDoc}
`;

/**
 * __useManagerEvictionsWsSubscription__
 *
 * To run a query within a React component, call `useManagerEvictionsWsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useManagerEvictionsWsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagerEvictionsWsSubscription({
 *   variables: {
 *      cityId: // value for 'cityId'
 *   },
 * });
 */
export function useManagerEvictionsWsSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<ManagerEvictionsWsSubscription, ManagerEvictionsWsSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<ManagerEvictionsWsSubscription, ManagerEvictionsWsSubscriptionVariables>(
        ManagerEvictionsWsDocument,
        options
    );
}
export type ManagerEvictionsWsSubscriptionHookResult = ReturnType<typeof useManagerEvictionsWsSubscription>;
export type ManagerEvictionsWsSubscriptionResult = Apollo.SubscriptionResult<ManagerEvictionsWsSubscription>;
export const ManagerLockedAptsWsDocument = gql`
    subscription ManagerLockedAptsWS($cityId: Int!) {
        RDUpdates(cityId: $cityId) {
            oldStatus
            apt {
                ...ManagerLockedApts
            }
        }
    }
    ${ManagerLockedAptsFragmentDoc}
`;

/**
 * __useManagerLockedAptsWsSubscription__
 *
 * To run a query within a React component, call `useManagerLockedAptsWsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useManagerLockedAptsWsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagerLockedAptsWsSubscription({
 *   variables: {
 *      cityId: // value for 'cityId'
 *   },
 * });
 */
export function useManagerLockedAptsWsSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        ManagerLockedAptsWsSubscription,
        ManagerLockedAptsWsSubscriptionVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<ManagerLockedAptsWsSubscription, ManagerLockedAptsWsSubscriptionVariables>(
        ManagerLockedAptsWsDocument,
        options
    );
}
export type ManagerLockedAptsWsSubscriptionHookResult = ReturnType<typeof useManagerLockedAptsWsSubscription>;
export type ManagerLockedAptsWsSubscriptionResult = Apollo.SubscriptionResult<ManagerLockedAptsWsSubscription>;
export const ManagerRentalsWsDocument = gql`
    subscription ManagerRentalsWS($cityId: Int!) {
        RDUpdates(cityId: $cityId) {
            oldStatus
            apt {
                ...ManagerRentalsApartment
            }
        }
    }
    ${ManagerRentalsApartmentFragmentDoc}
`;

/**
 * __useManagerRentalsWsSubscription__
 *
 * To run a query within a React component, call `useManagerRentalsWsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useManagerRentalsWsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagerRentalsWsSubscription({
 *   variables: {
 *      cityId: // value for 'cityId'
 *   },
 * });
 */
export function useManagerRentalsWsSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<ManagerRentalsWsSubscription, ManagerRentalsWsSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<ManagerRentalsWsSubscription, ManagerRentalsWsSubscriptionVariables>(
        ManagerRentalsWsDocument,
        options
    );
}
export type ManagerRentalsWsSubscriptionHookResult = ReturnType<typeof useManagerRentalsWsSubscription>;
export type ManagerRentalsWsSubscriptionResult = Apollo.SubscriptionResult<ManagerRentalsWsSubscription>;
export const ManagerWaitingContestAptsWsDocument = gql`
    subscription ManagerWaitingContestAptsWS($cityId: Int!) {
        RDUpdates(cityId: $cityId) {
            oldStatus
            apt {
                ...ManagerWaitingContestApartment
            }
        }
    }
    ${ManagerWaitingContestApartmentFragmentDoc}
`;

/**
 * __useManagerWaitingContestAptsWsSubscription__
 *
 * To run a query within a React component, call `useManagerWaitingContestAptsWsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useManagerWaitingContestAptsWsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagerWaitingContestAptsWsSubscription({
 *   variables: {
 *      cityId: // value for 'cityId'
 *   },
 * });
 */
export function useManagerWaitingContestAptsWsSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        ManagerWaitingContestAptsWsSubscription,
        ManagerWaitingContestAptsWsSubscriptionVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<
        ManagerWaitingContestAptsWsSubscription,
        ManagerWaitingContestAptsWsSubscriptionVariables
    >(ManagerWaitingContestAptsWsDocument, options);
}
export type ManagerWaitingContestAptsWsSubscriptionHookResult = ReturnType<
    typeof useManagerWaitingContestAptsWsSubscription
>;
export type ManagerWaitingContestAptsWsSubscriptionResult =
    Apollo.SubscriptionResult<ManagerWaitingContestAptsWsSubscription>;
export const MeWsDocument = gql`
    subscription MeWS {
        me {
            id
            language
            eosAccount
            isNotary
            countNewNotifications
            rdUpdates {
                ...NotaryUpdates
            }
        }
    }
    ${NotaryUpdatesFragmentDoc}
`;

/**
 * __useMeWsSubscription__
 *
 * To run a query within a React component, call `useMeWsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMeWsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeWsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useMeWsSubscription(
    baseOptions?: Apollo.SubscriptionHookOptions<MeWsSubscription, MeWsSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<MeWsSubscription, MeWsSubscriptionVariables>(MeWsDocument, options);
}
export type MeWsSubscriptionHookResult = ReturnType<typeof useMeWsSubscription>;
export type MeWsSubscriptionResult = Apollo.SubscriptionResult<MeWsSubscription>;
export const MyBuyRequestsWsDocument = gql`
    subscription MyBuyRequestsWS {
        myBuyRequests {
            event
            buyRequest {
                ...MyBuyRequest
            }
        }
    }
    ${MyBuyRequestFragmentDoc}
`;

/**
 * __useMyBuyRequestsWsSubscription__
 *
 * To run a query within a React component, call `useMyBuyRequestsWsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMyBuyRequestsWsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyBuyRequestsWsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useMyBuyRequestsWsSubscription(
    baseOptions?: Apollo.SubscriptionHookOptions<MyBuyRequestsWsSubscription, MyBuyRequestsWsSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<MyBuyRequestsWsSubscription, MyBuyRequestsWsSubscriptionVariables>(
        MyBuyRequestsWsDocument,
        options
    );
}
export type MyBuyRequestsWsSubscriptionHookResult = ReturnType<typeof useMyBuyRequestsWsSubscription>;
export type MyBuyRequestsWsSubscriptionResult = Apollo.SubscriptionResult<MyBuyRequestsWsSubscription>;
export const NewNotificationWsDocument = gql`
    subscription NewNotificationWS {
        newNotification {
            ...NotifyHistoryNode
        }
    }
    ${NotifyHistoryNodeFragmentDoc}
`;

/**
 * __useNewNotificationWsSubscription__
 *
 * To run a query within a React component, call `useNewNotificationWsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewNotificationWsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewNotificationWsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewNotificationWsSubscription(
    baseOptions?: Apollo.SubscriptionHookOptions<NewNotificationWsSubscription, NewNotificationWsSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<NewNotificationWsSubscription, NewNotificationWsSubscriptionVariables>(
        NewNotificationWsDocument,
        options
    );
}
export type NewNotificationWsSubscriptionHookResult = ReturnType<typeof useNewNotificationWsSubscription>;
export type NewNotificationWsSubscriptionResult = Apollo.SubscriptionResult<NewNotificationWsSubscription>;
export const NotificationWsDocument = gql`
    subscription NotificationWS($id: String!) {
        notification(id: $id) {
            id
            isNew
        }
    }
`;

/**
 * __useNotificationWsSubscription__
 *
 * To run a query within a React component, call `useNotificationWsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificationWsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationWsSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNotificationWsSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<NotificationWsSubscription, NotificationWsSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<NotificationWsSubscription, NotificationWsSubscriptionVariables>(
        NotificationWsDocument,
        options
    );
}
export type NotificationWsSubscriptionHookResult = ReturnType<typeof useNotificationWsSubscription>;
export type NotificationWsSubscriptionResult = Apollo.SubscriptionResult<NotificationWsSubscription>;

export interface PossibleTypesResultData {
    possibleTypes: {
        [key: string]: string[];
    };
}
const result: PossibleTypesResultData = {
    possibleTypes: {
        AddExpenseOutput: ['AddExpenseSuccess', 'HasNoPermissionError', 'CityNotFoundError'],
        AddRegionDocumentOutput: ['AddRegionDocumentSuccess', 'HasNoPermissionError', 'CityNotFoundError'],
        AddRentalOutput: [
            'AddRentalSuccess',
            'AptNotFoundError',
            'HasNoPermissionError',
            'ActionNotAllowedForAptStatusError',
            'RentalCanNotBeExtendedError'
        ],
        BlockAptOutput: [
            'BlockAptSuccess',
            'HasNoPermissionError',
            'AptNotFoundError',
            'ActionNotAllowedForAptStatusError',
            'AddressIsBlockedError',
            'InvalidBlockPeriodError'
        ],
        BurnListsOutput: [
            'BurnListsSuccess',
            'HasNoPermissionError',
            'BuyRequestNotFoundError',
            'BuyRequestCancelledError',
            'BuyRequestNotApprovedError',
            'BuyRequestExecutedError'
        ],
        CancelBuyRequestOutput: [
            'CancelBuyRequestSuccess',
            'HasNoPermissionError',
            'BuyRequestNotFoundError',
            'BuyRequestCancelledError',
            'BuyRequestExecutedError'
        ],
        CancelContestOutput: [
            'CancelContestSuccess',
            'AptNotFoundError',
            'HasNoPermissionError',
            'ActionNotAllowedForAptStatusError'
        ],
        CancelPublicationOutput: [
            'CancelPublicationSuccess',
            'AptNotFoundError',
            'HasNoPermissionError',
            'ActionNotAllowedForAptStatusError'
        ],
        ChangeLoginOutput: [
            'ChangeLoginSuccess',
            'InvalidCredentialsError',
            'InvalidPhoneError',
            'InvalidEmailError',
            'UserAlreadyExistsError',
            'InvalidParamsError'
        ],
        ChangePasswordOutput: ['ChangePasswordSuccess', 'InvalidPasswordError', 'InvalidCredentialsError'],
        CheckPasswordOutput: ['CheckPasswordSuccess', 'InvalidCredentialsError'],
        ConfirmEvictionOutput: [
            'ConfirmEvictionSuccess',
            'HasNoPermissionError',
            'AptNotFoundError',
            'ActionNotAllowedForAptStatusError',
            'EvictionNotStartedError'
        ],
        ConfirmPasswordResetOutput: [
            'ConfirmPasswordResetSuccess',
            'InvalidJWTError',
            'InvalidVerifyCodeError',
            'UserNotFoundError',
            'InvalidPasswordError'
        ],
        ConfirmReadinessAsWinnerOutput: [
            'ConfirmReadinessAsWinnerSuccess',
            'HasNoPermissionError',
            'AptNotFoundError',
            'AptNotTokenizedError',
            'ActionNotAllowedForAptStatusError'
        ],
        ConnectByWalletOutput: ['ConnectByWalletSuccess', 'DeeplinkNotGeneratedError'],
        DeleteApartmentOutput: [
            'DeleteApartmentSuccess',
            'AptNotFoundError',
            'HasNoPermissionError',
            'ActionNotAllowedForAptStatusError'
        ],
        DeleteDocumentOutput: [
            'DeleteDocumentSuccess',
            'AptNotFoundError',
            'DocumentNotFoundError',
            'HasNoPermissionError'
        ],
        DeleteExpenseOutput: ['DeleteExpenseSuccess', 'HasNoPermissionError', 'ExpenseNotFoundError'],
        DeleteRegionDocumentOutput: [
            'DeleteRegionDocumentSuccess',
            'HasNoPermissionError',
            'RegionDocumentNotFoundError'
        ],
        MarkAllNotificationsAsReadOutput: ['MarkAllNotificationsAsReadSuccess'],
        MarkApartmentAsViewedOutput: ['MarkApartmentAsViewedSuccess', 'AptNotFoundError', 'HasNoPermissionError'],
        MarkBuyRequestAsViewedOutput: [
            'MarkBuyRequestAsViewedSuccess',
            'BuyRequestNotFoundError',
            'HasNoPermissionError'
        ],
        MarkNotificationAsReadOutput: [
            'MarkNotificationAsReadSuccess',
            'HasNoPermissionError',
            'NotificationNotFoundError'
        ],
        Output: ['DeleteAccountSuccess'],
        PublishApartmentOutput: [
            'PublishApartmentSuccess',
            'AptNotFoundError',
            'HasNoPermissionError',
            'ActionNotAllowedForAptStatusError',
            'AddressIsBlockedError',
            'ApartmentAlreadyExistsError',
            'MissedRequiredFieldError'
        ],
        RefreshTokenOutput: ['RefreshTokenSuccess'],
        RefuseToWinOutput: [
            'RefuseToWinSuccess',
            'AptNotFoundError',
            'HasNoPermissionError',
            'ActionNotAllowedForAptStatusError'
        ],
        RemoveLoginOutput: ['RemoveLoginSuccess', 'CantDeleteLoginError'],
        ReportAddressCheckErrorOutput: [
            'ReportAddressCheckErrorSuccess',
            'PlaceNotFoundError',
            'UserNotFoundError',
            'AddressAlreadyInAreaError',
            'ReportAlreadyRegisteredError'
        ],
        ReportMisCoordsErrorOutput: [
            'ReportMisCoordsErrorSuccess',
            'PlaceNotFoundError',
            'ReportAlreadyRegisteredError'
        ],
        ResendCodeOutput: ['ResendCodeSuccess', 'InvalidJWTError', 'NoResendAttemptsError', 'TrySendCodeTooEarlyError'],
        ResetPasswordOutput: [
            'ResetPasswordSuccess',
            'LoginIsEmptyError',
            'UserNotFoundError',
            'NoResendAttemptsError',
            'InvalidPhoneError',
            'InvalidEmailError'
        ],
        ResolveReviewOutput: [
            'ResolveReviewSuccess',
            'AptNotFoundError',
            'HasNoPermissionError',
            'ActionNotAllowedForAptStatusError',
            'ReviewNotFoundError'
        ],
        ReviewApartmentOutput: [
            'ReviewApartmentSuccess',
            'AptNotFoundError',
            'HasNoPermissionError',
            'ActionNotAllowedForAptStatusError'
        ],
        SaveApartmentOutput: [
            'SaveApartmentSuccess',
            'AptNotFoundError',
            'HasNoPermissionError',
            'OutOfPolygonError',
            'PlaceNotFoundError',
            'AddressIsNotFullError',
            'AddressIsBlockedError',
            'EosAccountNotExistsError',
            'InvalidCommunalPaymentError'
        ],
        SetLanguageOutput: ['SetLanguageSuccess'],
        SigninOutput: ['SigninSuccess', 'LoginIsEmptyError', 'InvalidCredentialsError'],
        SignupOutput: [
            'SignupSuccess',
            'LoginIsEmptyError',
            'UserAlreadyExistsError',
            'InvalidEmailError',
            'InvalidPhoneError',
            'InvalidPasswordError',
            'NoResendAttemptsError'
        ],
        StartAuctionOutput: [
            'StartAuctionSuccess',
            'HasNoPermissionError',
            'AptNotFoundError',
            'AptNotReviewedError',
            'ActionNotAllowedForAptStatusError',
            'InvalidExpenseValueError',
            'ExpenseNotFoundError',
            'InvalidExpensesListError',
            'InvalidCommunalPaymentError'
        ],
        StartEvictionOutput: [
            'StartEvictionSuccess',
            'HasNoPermissionError',
            'AptNotFoundError',
            'ActionNotAllowedForAptStatusError',
            'EvictionAlreadyStartedError',
            'InvalidParamsError'
        ],
        UnblockAptOutput: [
            'UnblockAptSuccess',
            'HasNoPermissionError',
            'AptNotFoundError',
            'ActionNotAllowedForAptStatusError'
        ],
        UpdateAptFavoriteOutput: ['UpdateAptFavoriteSuccess', 'AptNotFoundError'],
        UpdateAptRealIdOutput: [
            'UpdateAptRealIdSuccess',
            'HasNoPermissionError',
            'AptNotFoundError',
            'ActionNotAllowedForAptStatusError'
        ],
        UpdateExpenseOutput: ['UpdateExpenseSuccess', 'HasNoPermissionError', 'ExpenseNotFoundError'],
        UpdateNotifySettingsOutput: ['UpdateNotifySettingsSuccess'],
        UpdatePhotosOrderOutput: [
            'UpdatePhotosOrderSuccess',
            'HasNoPermissionError',
            'AptNotFoundError',
            'ActionNotAllowedForAptStatusError',
            'DocumentNotFoundError'
        ],
        UpdateRdContactsOutput: [
            'UpdateRdContactsSuccess',
            'InvalidPhoneError',
            'InvalidEmailError',
            'CityNotFoundError',
            'HasNoPermissionError'
        ],
        UpdateRegionDocumentOutput: [
            'UpdateRegionDocumentSuccess',
            'HasNoPermissionError',
            'RegionDocumentNotFoundError'
        ],
        VerifyCodeOutput: [
            'VerifyCodeSuccess',
            'InvalidJWTError',
            'InvalidVerifyCodeError',
            'VerifyCodeExpiredError',
            'UserAlreadyExistsError',
            'VerifyCodeNoCheckAttemptsError'
        ],
        WalletConnectiontUpdate: [
            'WalletConnectionSuccess',
            'UserNotFoundError',
            'ConfirmationFailedError',
            'WalletLinkOutdatedError',
            'EosAccountAlreadyTakenError'
        ],
        WithdrawFromContestOutput: [
            'WithdrawFromContestSuccess',
            'AptNotFoundError',
            'HasNoPermissionError',
            'ActionNotAllowedForAptStatusError',
            'ContestPaymentNotFoundError'
        ]
    }
};
export default result;
