import React, { ReactNode } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

const Param = ({
    value,
    label,
    icon,
    subValue,
    className = ''
}: {
    value: ReactNode;
    label?: ReactNode;
    subValue?: ReactNode;
    icon: ReactNode;
    className?: string;
}) => {
    if (!value) return null;

    return (
        <p className={cx('Param', className)}>
            <span className={cx('ParamIcon')}>{icon}</span>

            <span>
                {label && <span className={cx('ParamLabel')}>{label}</span>}
                <span className={cx('ParamValue')}>{value}</span>
                {subValue && <span className={cx('ParamSubValue')}>{subValue}</span>}
            </span>
        </p>
    );
};

export default Param;
