import React from 'react';
import { Link } from 'react-router-dom';
import { AptStatus, Event, useApartmentWsSubscription } from 'apollo/generted';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { useConstructApartmentStatus } from 'helpers/useConstructApartmentStatus';
import { useConstructAddress } from 'helpers/useParsedApartmentData';
import { useModal } from 'helpers';
import { useWidthCondition } from 'helpers/hooks';
import { BREAKPOINT_IPAD, BREAKPOINT_LANDSCAPE, BREAKPOINT_TABLET, URL_MAP } from 'constant';
import Apartment from 'components/Apartment';
import Gallery from 'components/Gallery';
import Slider from 'components/Gallery/Slider';
import BaseStats from './BaseStats';
import { ApartmentCardProps } from './ApartmentCard.d';
import styles from './ApartmentCard.module.scss';

const cx = classNames.bind(styles);

const ApartmentCard = ({
    className = '',
    onNeedToRemove,
    cardView,
    onMouseEnter,
    onMouseLeave,
    mode,
    data
}: ApartmentCardProps) => {
    const constructAddress = useConstructAddress();
    const [t] = useTranslation();
    const gallery = useModal<{ initialSlide: number }>();
    const additionalStatus = useConstructApartmentStatus(data);

    // @todo check it
    useApartmentWsSubscription({
        skip: !data,
        variables: {
            aptId: data?.id!
        },
        onSubscriptionData: ({ subscriptionData: { data } }) => {
            if (!data?.apartment?.apt) return;

            const target = data.apartment.apt;
            const { status, isPublic } = target;

            if (
                data.apartment.event === Event.Delete ||
                (mode === 'PUBLIC' && !isPublic) ||
                (mode === 'OWNER' && target.isTokenized) ||
                (mode === 'RENTED' &&
                    ((status !== AptStatus.ContestFinished &&
                        status !== AptStatus.Rented &&
                        status !== AptStatus.Eviction) ||
                        (status === AptStatus.ContestFinished && !target.latestAuction?.isIAmWinner) ||
                        ((status === AptStatus.Rented || status === AptStatus.Eviction) &&
                            !target.actualRental?.isIAmTenant)))
            ) {
                onNeedToRemove?.();
            }
        }
    });

    const apartment = data!;
    /** alias for modes */
    const isOwner = mode === 'OWNER';
    const { emissionVolume, status, actualRental } = apartment ?? {};
    const photos = data?.photos ?? [];
    const apartmentHref = isOwner ? URL_MAP.editApartment(apartment.id) : URL_MAP.apartment(apartment.id);

    const disabledLink = additionalStatus.isLocked;

    const isIpadOrLess = useWidthCondition((w) => w < BREAKPOINT_IPAD);
    const isTabletOrLess = useWidthCondition((w) => w < BREAKPOINT_TABLET);
    const isLandscapeOrLess = useWidthCondition((w) => w < BREAKPOINT_LANDSCAPE);

    if (!cardView) {
        if (isLandscapeOrLess) {
            cardView = 'column-small';
        } else if (isTabletOrLess) {
            cardView = 'column-big';
        } else if (isIpadOrLess) {
            cardView = 'row-small';
        } else {
            cardView = 'row-big';
        }
    }

    const showReceiveAmount =
        isOwner &&
        (additionalStatus.inRDModeration || additionalStatus.inContest || additionalStatus.isContestFinished);

    return (
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={cx('Component', cardView, className)}>
            <Link
                className={cx('BaseLink')}
                onClick={disabledLink ? (e) => e.preventDefault() : undefined}
                to={apartmentHref}
            />

            <div className={cx('Gallery')}>
                <Slider
                    sliderClassName={cx('GallerySlider')}
                    slideClassName={cx('GallerySlide')}
                    photos={photos.length ? photos : [{ __typename: 'DocumentNode' }]}
                />

                <BaseStats {...apartment} />
            </div>

            <div className={cx('Info')}>
                <div className={cx('Main')}>
                    <div className={cx('StatusRow')}>
                        <Apartment.Status data={data} mode={mode} />
                        {!isOwner && (
                            <Apartment.UserActions
                                className={cx('HelpActions')}
                                id={apartment.id}
                                isFavorite={apartment.isFavorite}
                                address={constructAddress(apartment, { hideCityName: false })}
                                photo={apartment?.photos?.[0]?.link}
                            />
                        )}
                    </div>

                    <Apartment.Address className={cx('Address')} {...apartment} />
                    <Apartment.StatusParams
                        apartment={apartment}
                        isOwner={isOwner}
                        className={cx('AdditionalStatGroup')}
                    />
                    <Apartment.StatusDescription data={data} mode={mode} />
                    <Apartment.EvictionReason status={status} actualRental={actualRental} />

                    {showReceiveAmount && (
                        <div className={cx('Receive')}>
                            <div className={cx('ReceiveAmount')}>
                                {t('apartmentCard.ownerReceiveAfterTokenization')}
                                <b>
                                    {Number(emissionVolume?.amount)} {emissionVolume?.token}
                                </b>
                            </div>
                            <div className={cx('ReceiveCommission')}>
                                {t('apartmentCard.fee')}
                                <b>
                                    {Number(emissionVolume?.fee)} {emissionVolume?.token}
                                </b>
                            </div>
                        </div>
                    )}
                </div>
                <div className={cx('ActionsWrapper')}>
                    <div className={cx('Actions')}>
                        {mode === 'OWNER' ? (
                            <Apartment.Actions.OwnerApartmentActions apartment={apartment} />
                        ) : (
                            <Apartment.Actions.PublicApartmentActions apartment={apartment} />
                        )}
                    </div>

                    {mode !== 'OWNER' && (
                        <>
                            <Apartment.Price
                                className={cx('ActionsParam')}
                                hasMyBid={!!apartment.latestAuction?.hasMyBid}
                                status={status!}
                                paymentInfo={apartment.latestAuction?.paymentInfo}
                                isNew={data?.city.isNew}
                            />

                            {!additionalStatus.isGuestAuctionView && (
                                <Apartment.Expenses
                                    className={cx('ActionsParam')}
                                    id={apartment.id}
                                    status={status}
                                    isIAmWinner={apartment.latestAuction?.isIAmWinner}
                                    communalCurrency={apartment.communalCurrency}
                                    totalExpenses={apartment.totalExpenses}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>

            {gallery.isOpen && (
                <Gallery initialSlide={gallery.payload?.initialSlide ?? 0} apartment={apartment} {...gallery} />
            )}
        </div>
    );
};

export default React.memo(ApartmentCard);
