import React, { useEffect, useRef } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import SlickSlider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { IconAngleLeft, IconAngleRight } from 'components/Icons';
import { PhotosType } from '..';
import Button from 'components/UIKit/Button';
const cx = classNames.bind(styles);

const SliderNextArrow = ({ onClick }: { onClick?(): void }) => {
    return (
        <Button
            color="white-shadow"
            size="small"
            round
            className={cx('Arrow', 'next')}
            iconLeft={<IconAngleRight />}
            onClick={onClick}
        />
    );
};

const SliderPrevArrow = ({ onClick }: { onClick?(): void }) => {
    return (
        <Button
            color="white-shadow"
            size="small"
            className={cx('Arrow', 'prev')}
            round
            iconLeft={<IconAngleLeft />}
            onClick={onClick}
        />
    );
};

const Slide = ({ src = '', className = '' }) => {
    return (
        <div className={className}>
            <img src={src} alt="" onError={(e) => (e.currentTarget.style.opacity = '0')} />
        </div>
    );
};

const Slider = ({
    photos,
    activeSlide,
    setActiveSlide,
    sliderClassName = '',
    slideClassName = '',
    openGallery,
    ...sliderProps
}: {
    photos: PhotosType;
    activeSlide?: number;
    setActiveSlide?: (n: number) => void;
    sliderClassName?: string;
    slideClassName?: string;
    openGallery?(v: number): void;
} & Settings) => {
    const ref = useRef<SlickSlider>(null);

    useEffect(() => {
        if (activeSlide === undefined) return;
        setTimeout(() => ref.current?.slickGoTo(activeSlide), 50);
    }, [activeSlide]);

    return (
        <SlickSlider
            prevArrow={<SliderPrevArrow />}
            nextArrow={<SliderNextArrow />}
            dots={false}
            afterChange={(nextIndex) => setActiveSlide?.(nextIndex)}
            className={sliderClassName}
            ref={ref}
            {...sliderProps}
        >
            {photos.map((photo) => {
                if (!photo) return null;

                return <Slide key={photo.id} src={photo.link ?? ''} className={slideClassName} />;
            })}
        </SlickSlider>
    );
};

export default Slider;
