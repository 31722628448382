import { createAction } from 'helpers/createAction';
import ActionTypes from './actionTypes';
import { initialState } from './reducer';

/**
 * Reset all sign modalApartmentSignTokenization state to default
 */
export const reset = () => createAction(ActionTypes.RESET);

/**
 * Open modalApartmentSignTokenization. Pass config
 */
export const open = (payload: Partial<Omit<typeof initialState, 'isOpen'>> = {}) =>
    createAction(ActionTypes.OPEN, payload);

/**
 * Close modalApartmentSignTokenization. Remove entered data
 */
export const close = () => createAction(ActionTypes.CLOSE);

export type Actions = ReturnType<typeof open> | ReturnType<typeof reset> | ReturnType<typeof close>;
