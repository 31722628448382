import classNames from 'classnames/bind';
import { IconRefresh } from 'components/Icons';
import Preloader from 'components/Preloader';
import Button from 'components/UIKit/Button';
import Modal, { ModalBody } from 'components/UIKit/Modal';
import React, { Fragment, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

const url = process.env.REACT_APP_VERSION_CONTROLLER_URL;
const reconnectingLimit = 10;
const reconnectingTimeout = 1000;
const isEnabled = process.env.NODE_ENV !== 'development';
const version = process.env.REACT_APP_VERSION;

const VersionController = ({ children }: PropsWithChildren<{}>) => {
    const [t] = useTranslation();
    /** need to control time, when first message received */
    const isInitMessageReceived = useRef(false);
    const reconnectionCountRef = useRef(0);
    const [loadingVersion, setLoadingVersion] = useState(isEnabled);
    const [hasNewVersion, setHasNewVersion] = useState(false);
    const [updating, setUpdating] = useState(false);

    const updateApp = () => {
        setUpdating(true);
        window.location.reload();
    };

    useEffect(() => {
        if (isEnabled) {
            const connect = () => {
                const socket = new WebSocket(url ?? '');

                /** Reconnecting flow. On error close current connection, show reconnection message */
                socket.onopen = () => {
                    // reset value - we connected successfuly
                    reconnectionCountRef.current = 0;
                };

                socket.onclose = (e) => {
                    setTimeout(() => {
                        connect();
                        // increment count of reconnections in a row
                        reconnectionCountRef.current += 1;
                    }, reconnectingTimeout);
                };

                socket.onerror = async (err) => {
                    if (reconnectingLimit && reconnectingLimit <= reconnectionCountRef.current) {
                        updateApp();
                    } else {
                        socket.close();
                    }
                };

                socket.onmessage = async (event) => {
                    const realVersion = event.data;
                    const isNewVersionReceived = version !== realVersion;

                    setHasNewVersion(isNewVersionReceived);
                    if (isNewVersionReceived) {
                        /**
                         * same as `connected` but not `loaded` state - reload app only
                         */
                        if (!isInitMessageReceived.current) {
                            return updateApp();
                        }
                    }

                    setLoadingVersion(false);
                    isInitMessageReceived.current = true;
                };
            };

            connect();
        }
    }, []);

    if (loadingVersion) return <Preloader />;

    return (
        <Fragment>
            {children}

            <Modal isOpen={hasNewVersion} closable={false}>
                <ModalBody className={cx('ModalBody')}>
                    <div className={cx('Component')}>
                        <IconRefresh />

                        <h2>{t('versionController.title')}</h2>
                        <p>{t('versionController.text')}</p>
                    </div>
                    <Button size="big" loading={updating} color="primary" onClick={updateApp}>
                        {t('versionController.btn')}
                    </Button>
                </ModalBody>
            </Modal>
        </Fragment>
    );
};

export default VersionController;
