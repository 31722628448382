import { useDeleteAccountMutation } from 'apollo/generted';
import classNames from 'classnames/bind';
import Modal, { ModalBody, ModalHeader, ModalProps, useModalContext } from 'components/UIKit/Modal';
import ModalErrorScreen from 'components/UIKit/Modal/ErrorScreen';
import Button from 'components/UIKit/Button';
import Alert from 'components/UIKit/Alert';
import { getErrorData, getErrorI18nText } from 'helpers';
import { signOut } from 'helpers/sign';
import { type } from 'os';
import React, { FormEvent, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { useToast } from 'helpers/hooks';

const cx = classNames.bind(styles);

const ModalRemoveProfile = (props: ModalProps) => {
    return (
        <Modal {...props}>
            <Main />
        </Modal>
    );
};

const Main = () => {
    const { close } = useModalContext();
    const [step, setStep] = useState<'info' | 'question'>('info');
    const [formError, setFormError] = useState('');
    const [t] = useTranslation();
    const toast = useToast();
    const [deleteAccountMutation, { loading }] = useDeleteAccountMutation();

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFormError('');

        if (step === 'info') {
            setStep('question');
        } else if (step === 'question') {
            deleteAccountMutation()
                .then(({ data }) => {
                    const removeLogin = data?.deleteAccount!;
                    if (removeLogin.__typename === 'DeleteAccountSuccess') {
                        toast.success(t('modalRemoveProfile.successMsg'));
                        signOut(true);
                        close();
                    } else {
                        setFormError(
                            t([
                                `modalRemoveProfile.error.${removeLogin?.__typename}`,
                                getErrorI18nText(t, /* removeLogin?.errorMessage */ '')
                            ])
                        );
                    }
                })
                .catch((e) => {
                    setFormError(getErrorI18nText(t, getErrorData(e).message));
                });
        }
    };
    if (step === 'info') {
        return (
            <Fragment>
                <ModalHeader>{t('modalRemoveProfile.title')}</ModalHeader>
                <ModalBody>
                    <form className={cx('Form')} onSubmit={onSubmit}>
                        <div>
                            <p className={cx('Description')}>
                                {t('modalRemoveProfile.description', { context: step })}
                            </p>
                        </div>
                        <div className={cx('BtnGroup')}>
                            <Button type="submit" color="secondary">
                                {t('modalRemoveProfile.btnSubmit', { context: step })}
                            </Button>
                            <Button onClick={close} type="button" color="primary">
                                {t('modalRemoveProfile.btnCancel')}
                            </Button>
                        </div>
                    </form>
                </ModalBody>
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                <ModalHeader>{t('modalRemoveProfile.title', { context: type })}</ModalHeader>
                <ModalBody>
                    <form className={cx('Form')} onSubmit={onSubmit}>
                        <div>
                            <p className={cx('Description')}>
                                {t('modalRemoveProfile.description', { context: step })}
                            </p>
                            <Alert className={cx('Alert')}>{t(`modalRemoveProfile.notification`)}</Alert>
                        </div>
                        <div className={cx('BtnGroup')}>
                            <Button loading={loading} type="submit" color="secondary">
                                {t('modalRemoveProfile.btnSubmit', { context: step })}
                            </Button>
                            <Button onClick={close} type="button" color="primary">
                                {t('modalRemoveProfile.btnCancel')}
                            </Button>
                        </div>
                    </form>
                </ModalBody>
                <ModalErrorScreen text={formError} resetError={() => setFormError('')} />
            </Fragment>
        );
    }
};

export default ModalRemoveProfile;
