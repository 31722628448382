import {
    useMarkAllNotificationsAsReadMutation,
    useMeNotifyHistoryQuery,
    useMeQuery,
    useNewNotificationWsSubscription
} from 'apollo/generted';
import classNames from 'classnames/bind';
import { IconCheck, IconClose, IconRing, IconRingCrossed, IconWarningNew } from 'components/Icons';
import Loader from 'components/Loader';
import Sidebar from 'components/Sidebar';
import Button from 'components/UIKit/Button';
import Counter from 'components/UIKit/Counter';
import { ModalHeaderBtn } from 'components/UIKit/Modal';
import { getErrorData, getErrorI18nText, useModal } from 'helpers';
import { useStore, useToast } from 'helpers/hooks';
import update from 'immutability-helper';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import styles from './index.module.scss';
import NotificationCard from './NotificationCard';
const cx = classNames.bind(styles);

const STEP = 10;

const Notifications = () => {
    const toast = useToast();
    const [t] = useTranslation();
    const sidebar = useModal();
    const loadingMoreRef = useRef(false);
    const [contentElement, setContentElement] = useState<HTMLElement | null>();
    const { isAuth } = useStore((s) => s.Base);
    const { countNewNotifications } = useMeQuery().data?.me ?? {};
    const hasNewNotifications = (countNewNotifications ?? 0) > 0;
    /**
     * On mobile, we don't update `isNew` styles instantly, because
     * user will not see new notifications. But on click `markAllRead`
     * button we have to update styles. We can achive it by updating key,
     * that calls rerender component
     */
    const [markAllReadKey, setMarkAllReadKey] = useState(Math.random());

    const [markAllNotificationsAsReadMutation, { loading: loadingMarkAllNotificationsAsRead }] =
        useMarkAllNotificationsAsReadMutation();

    const notifyHistoryQuery = useMeNotifyHistoryQuery({
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        variables: {
            offset: 0,
            first: STEP * 3
        }
    });

    useNewNotificationWsSubscription({
        skip: Boolean(notifyHistoryQuery.loading || notifyHistoryQuery.error),
        onSubscriptionData: ({ subscriptionData: { data } }) => {
            notifyHistoryQuery.updateQuery((currentData) => {
                const history = currentData?.me?.notifyHistory;
                return history && !history.find((n) => n?.id === data?.newNotification?.id)
                    ? update(currentData, {
                          notificationsNumber: (nn) => (nn ?? 0) + 1,
                          me: {
                              notifyHistory: (history) => {
                                  return [data?.newNotification ?? null, ...(history ?? [])];
                              }
                          }
                      })
                    : currentData;
            });
        }
    });

    if (!isAuth) return null;

    const notifications = notifyHistoryQuery.data?.me?.notifyHistory ?? [];
    /** is any loading now */
    const loaded = notifications.length;
    const total = notifyHistoryQuery.data?.notificationsNumber ?? 0;
    const hasMore = loaded < total;
    const dataReady = !notifyHistoryQuery.loading && !notifyHistoryQuery.error;

    const onClickReadAll = () => {
        markAllNotificationsAsReadMutation()
            .then(({ data }) => {
                if (data?.markAllNotificationsAsRead?.__typename === 'MarkAllNotificationsAsReadSuccess') {
                    toast.success(t('notifications.markAllNotificationsAsRead.successMsg'));
                    notifyHistoryQuery.updateQuery((current) => {
                        setMarkAllReadKey(Math.random());
                        return current.me?.notifyHistory
                            ? update(current, {
                                  me: {
                                      notifyHistory: (nh) => nh?.map((n) => n && { ...n, isNew: false }) ?? []
                                  }
                              })
                            : current;
                    });
                } else {
                    toast.error(getErrorI18nText(t, ''));
                }
            })
            .catch((e) => {
                toast.error(getErrorI18nText(t, getErrorData(e).message));
            });
    };

    const loadMore = () => {
        if (!hasMore || loadingMoreRef.current) return;
        loadingMoreRef.current = true;
        notifyHistoryQuery
            .fetchMore({
                variables: {
                    offset: loaded,
                    first: STEP
                },
                updateQuery: (currentResult, { fetchMoreResult }) => {
                    loadingMoreRef.current = false;

                    return update(currentResult, {
                        me: {
                            notifyHistory: {
                                $push: fetchMoreResult?.me?.notifyHistory ?? []
                            }
                        },
                        notificationsNumber: (nn) => fetchMoreResult?.notificationsNumber ?? nn
                    });
                }
            })
            .catch((e) => {
                loadingMoreRef.current = false;
                toast.error(getErrorI18nText(t, getErrorData(e).message));
            });
    };

    return (
        <>
            <Button
                color="tertiary-grey"
                size="small"
                className={cx('Control', hasNewNotifications && 'hasNew')}
                onClick={sidebar.open}
                iconRight={<IconRing />}
            />

            <Sidebar {...sidebar}>
                <header className={cx('SidebarHeader')}>
                    <h3 className={cx('SidebarTitle')}>
                        {t('notifications.title')}
                        {hasNewNotifications && <Counter>{countNewNotifications}</Counter>}
                    </h3>

                    <div className={cx('SidebarActions')}>
                        {dataReady && notifications.length > 0 && hasNewNotifications && (
                            <ModalHeaderBtn disabled={loadingMarkAllNotificationsAsRead} onClick={onClickReadAll}>
                                <IconCheck />
                            </ModalHeaderBtn>
                        )}

                        <ModalHeaderBtn onClick={sidebar.close}>
                            <IconClose />
                        </ModalHeaderBtn>
                    </div>
                </header>

                <main ref={setContentElement} className={cx('SidebarMain')}>
                    {contentElement &&
                        (notifyHistoryQuery.loading ? (
                            <div className={cx('SidebarLoader')}>
                                <Loader size={48} />
                            </div>
                        ) : notifyHistoryQuery.error ? (
                            <div className={cx('SidebarError')}>
                                <IconWarningNew />

                                <p>
                                    <b>{getErrorI18nText(t, getErrorData(notifyHistoryQuery.error).message)}</b>
                                </p>

                                <Button color="secondary" onClick={() => notifyHistoryQuery.refetch()} type="button">
                                    {t('notifications.btnReload')}
                                </Button>
                            </div>
                        ) : notifications.length ? (
                            <>
                                <InfiniteScroll
                                    className={cx('NotificationList')}
                                    dataLength={notifications.length}
                                    hasMore={hasMore}
                                    loader={
                                        <div key="loader" className={cx('NotificationListLoader')}>
                                            <Loader size={24} />
                                        </div>
                                    }
                                    next={loadMore}
                                    scrollableTarget={contentElement}
                                >
                                    {notifications.map((notification) => {
                                        if (!notification) return null;
                                        return (
                                            <NotificationCard
                                                {...notification}
                                                key={`${notification.id}-${markAllReadKey}`}
                                                onClick={sidebar.close}
                                            />
                                        );
                                    })}
                                </InfiniteScroll>

                                {!notifyHistoryQuery.loading && notifyHistoryQuery.error && (
                                    <div className={cx('NotificationListError')}>
                                        <Button color="primary" type="button" onClick={loadMore}>
                                            {t('notifications.btnReload')}
                                        </Button>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className={cx('SidebarEmpty')}>
                                <IconRingCrossed />
                                <p>
                                    <b>{t('notifications.empty.title')}</b>
                                </p>
                                <p>{t('notifications.empty.text')}</p>
                            </div>
                        ))}
                </main>
            </Sidebar>
        </>
    );
};

export default Notifications;
