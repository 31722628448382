import React, { ReactNode } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import Loader from 'components/Loader';
import { IconWarningNew } from 'components/Icons';
import { getErrorI18nText } from 'helpers';
import Button from 'components/UIKit/Button';
import Pagination from 'components/Pagination';
const cx = classNames.bind(styles);

type Props = {
    loading?: boolean;
    error?: string;
    onClickReload(): void;
    isEmpty?: boolean;
    empty: { icon: ReactNode; title: ReactNode; text: ReactNode };
    page: number;
    pageCount: number;
    onChangePage(p: number): void;
};

const Apartments: React.FC<Props> = ({
    loading,
    error,
    onClickReload,
    isEmpty,
    empty,
    children: apartments,
    page,
    pageCount,
    onChangePage
}) => {
    const [t] = useTranslation();

    return (
        <div className={cx('Component')}>
            {loading ? (
                <div className={cx('Loader')}>
                    <Loader size={46} />
                </div>
            ) : error ? (
                <div className={cx('ErrorScreen')}>
                    <IconWarningNew />
                    <h6>{getErrorI18nText(t, error)}</h6>
                    <Button onClick={onClickReload} color="primary">
                        {t('global.reload')}
                    </Button>
                </div>
            ) : isEmpty ? (
                <div className={cx('EmptyScreen')}>
                    {empty.icon}
                    <h6>{empty.title}</h6>
                    <p>{empty.text}</p>
                </div>
            ) : (
                <>
                    <div className={cx('ApartmentsList')}>{apartments}</div>
                    <Pagination page={page} pageCount={pageCount} onChangePage={onChangePage} />
                </>
            )}
        </div>
    );
};

export default Apartments;
