import { NotifyCategory, useMeNotifySettingsQuery, useMeQuery, useUpdateNotifySettingsMutation } from 'apollo/generted';
import classNames from 'classnames/bind';
import { IconMail } from 'components/Icons';
import Switcher from 'components/UIKit/Switcher';
import { getErrorData, useGlobalError } from 'helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Info from '../components/Info';
import Setting from '../components/Setting';
import styles from './index.module.scss';
import { URL_MAP } from 'constant';
import { Section, SectionHeader, SectionTitle, SectionMain } from 'containers/Pages/Manage/components/Section';

const cx = classNames.bind(styles);

export type ActionType = 'change' | 'add' | 'delete';

const NotificationSettings = () => {
    const [t] = useTranslation();
    const notifySettings = useMeNotifySettingsQuery().data?.me?.notifySettings;
    const [updateNotifySettingsMutation] = useUpdateNotifySettingsMutation();
    const onGlobalError = useGlobalError();
    const me = useMeQuery()?.data?.me;
    const email = me?.email ?? '';

    const hasEmail = !!email;

    const isSmsEnabled = notifySettings?.aptStatus?.isSmsEnabled;
    const isEmailEnabled = notifySettings?.aptStatus?.isEmailEnabled;
    const toggleSetting = (type: 'sms' | 'email') => {
        updateNotifySettingsMutation({
            variables: {
                input: {
                    settings: [
                        {
                            category: NotifyCategory.AptStatus,
                            isSmsEnabled: type === 'sms' ? !isSmsEnabled : isSmsEnabled,
                            isEmailEnabled: type === 'email' ? !isEmailEnabled : isEmailEnabled
                        }
                    ]
                }
            }
        }).catch((e) => onGlobalError(getErrorData(e).message));
    };

    return (
        <Section className={cx('Component')}>
            <SectionHeader>
                <SectionTitle to={URL_MAP.profile.index}>{t('pageProfile.notificationSettings.title')}</SectionTitle>
            </SectionHeader>

            <SectionMain>
                <Setting
                    icon={<IconMail />}
                    className={cx('SettingNotify')}
                    label={t('pageProfile.notificationSettings.settings.EMAIL')}
                    labelEmpty={t('pageProfile.notificationSettings.settings.off')}
                    actions={<Switcher isActive={!!isEmailEnabled} toggle={() => toggleSetting('email')} />}
                    value={isEmailEnabled ? t('pageProfile.notificationSettings.settings.on') : null}
                    alert={!hasEmail && t('pageProfile.notificationSettings.alert.no', { context: 'EMAIL' })}
                />
            </SectionMain>

            <Info title={t('pageProfile.notificationSettings.info.title')}>
                <p>{t('pageProfile.notificationSettings.info.description')}</p>
            </Info>
        </Section>
    );
};

export default NotificationSettings;
